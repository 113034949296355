import React, { createContext, useReducer } from 'react'
import { Switch, useParams, Route, useRouteMatch } from 'react-router'
import SurgicalOrderDashboard from 'presentation/doctor/pages/SurgicalOrdersDashboard'
import SurgicalOrdersPendenciesList from 'presentation/doctor/pages/SurgicalOrdersPendenciesList'
import SurgicalOrdersScheduling from 'presentation/doctor/pages/SurgicalOrdersScheduling'
import SurgicalOrdersStatusList from 'presentation/doctor/pages/SurgicalOrdersStatusList'
import { SurgicalOrderModel } from 'domain/entities/surgical-order-model'
import { PaginarionSnaked } from 'service/protocols/api/api-client'

export const SurgicalOrderDashboardContext = createContext({})

type Types =
  | 'PENDENCY_COUNT'
  | 'PENDENCY_COUNT_FILTERED'
  | 'STATUS_COUNT'
  | 'SCHEDULINGS_COUNT'
  | 'SURGICAL_ORDERS_LIST'
  | 'PAGINATION'

type Payload = any

export type Actions = {
  payload: Payload
  type: Types
  clearList?: boolean
}

export type ContextType = {
  dispatch: (params: Actions) => void
  state: {
    pendencyCountFiltered: {
      IN_PROGRESS: number
      PENDENT: number
      RESOLVEDL: number
    }
    pendencyCount: {
      IN_PROGRESS: number
      PENDENT: number
      RESOLVEDL: number
    }
    statusCount: {
      AUTHORIZED: number
      CANCELLED: number
      CONCLUDED: number
      ORDER_OPEN: number
      UNDER_REVIEW_AT_THE_HOSPITAL: number
      UNDER_REVIEW_AT_THE_INSURANCE: number
      EXPIRES_SOON: number
      EXPIRED: number
      REQUESTED_CANCELLATION: number
      authorization_status: {
        PARTIALLY_AUTHORIZED: number
        DENIED: number
      }
    }
    schedulingsCount: {
      UNSCHEDULED: number
      UNDER_ANALYSIS: number
      SCHEDULED: number
    }
    surgicalOrdersList: SurgicalOrderModel[]
    pageInfo: PaginarionSnaked
  }
}

const reducer = (state: any, action: Actions) => {
  switch (action.type) {
    case 'PENDENCY_COUNT':
      return {
        ...state,
        pendencyCount: action.payload,
        pendencyCountFiltered: action.payload
      }
    case 'PENDENCY_COUNT_FILTERED':
      return { ...state, pendencyCountFiltered: action.payload }
    case 'STATUS_COUNT':
      return { ...state, statusCount: action.payload }
    case 'SCHEDULINGS_COUNT':
      return { ...state, schedulingsCount: action.payload }
    case 'SURGICAL_ORDERS_LIST':
      if (action.clearList) {
        return { ...state, surgicalOrdersList: action.payload }
      } else {
        return {
          ...state,
          surgicalOrdersList: [...state.surgicalOrdersList, ...action.payload]
        }
      }
    case 'PAGINATION':
      return { ...state, pageInfo: action.payload }
    default:
      return state
  }
}

export default function makeSurgicalOrderPage() {
  const { path } = useRouteMatch()
  const [state, dispatch] = useReducer(reducer, {
    pendencyCountFiltered: {
      IN_PROGRESS: 0,
      PENDENT: 0,
      RESOLVEDL: 0
    },
    pendencyCount: {
      IN_PROGRESS: 0,
      PENDENT: 0,
      RESOLVEDL: 0
    },
    statusCount: {
      AUTHORIZED: 0,
      CANCELLED: 0,
      CONCLUDED: 0,
      ORDER_OPEN: 0,
      UNDER_REVIEW_AT_THE_HOSPITAL: 0,
      UNDER_REVIEW_AT_THE_INSURANCE: 0,
      EXPIRES_SOON: 0,
      EXPIRED: 0,
      REQUESTED_CANCELLATION: 0,
      authorization_status: {
        PARTIALLY_AUTHORIZED: 0,
        DENIED: 0
      },

      password_status: {
        EXPIRED: 0,
        EXPIRES_SOON: 0
      }
    },
    schedulingsCount: {
      UNSCHEDULED: 0,
      UNDER_ANALYSIS: 0,
      SCHEDULED: 0
    },
    surgicalOrdersList: [] as SurgicalOrderModel[],
    pageInfo: {} as PaginarionSnaked
  })

  return (
    <>
      <Switch>
        <SurgicalOrderDashboardContext.Provider value={{ state, dispatch }}>
          <Route path={`${path}/:page`} component={Page} />
        </SurgicalOrderDashboardContext.Provider>
      </Switch>
    </>
  )
}

function Page() {
  const { page } = useParams<{ page: string }>()

  switch (page) {
    case 'dashboard':
      return <SurgicalOrderDashboard />
    case 'pendencias':
      return <SurgicalOrdersPendenciesList />
    case 'pedidos':
      return <SurgicalOrdersStatusList />
    case 'agendamento':
      return <SurgicalOrdersScheduling />
    default:
      return <SurgicalOrderDashboard />
  }
}
