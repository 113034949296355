import styled, { css, DefaultTheme } from 'styled-components'

import { TextFieldProps } from '.'

type IconProps = Pick<
  TextFieldProps,
  | 'icon'
  | 'iconColor'
  | 'iconBgColor'
  | 'iconPosition'
  | 'iconIsButton'
  | 'iconPosition'
  | 'keepStyleOnIconIsButton'
>

type WrapperProps = Pick<TextFieldProps, 'disabled'> & { error?: boolean }

type AsteriskProps = Pick<TextFieldProps, 'requiredColor'>

export const LabelWrapper = styled.div`
  display: flex;
`

export const Label = styled.label<TextFieldProps>`
  ${({ theme, labelColor, labelSize, labelWeight }) => css`
    color: ${theme.ds.colors[labelColor!]};
    font-size: ${theme.ds.typography.size?.[labelSize!]};
    font-weight: ${theme.ds.typography.weight?.[labelWeight!]};
    line-height: ${theme.ds.typography.lineHeight.medium};
    cursor: pointer;
    margin-bottom: 8px;
  `}
`

export const LabelIcon = styled.div`
  display: flex;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  margin-left: 4px;
`

export const Asterisk = styled.span<AsteriskProps>`
  ${({ theme, requiredColor }) => css`
    font-size: ${theme.ds.typography.size.small};
    font-weight: ${theme.ds.typography.weight.normal};
    color: ${theme.ds.colors[requiredColor!]};
    cursor: pointer;
  `}
`

export const InputWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  border-radius: ${({ theme }) => theme.ds.typography.border.radius.small};
  ${({ theme }) => wrapperModifiers['normal'](theme)}
`

export const Input = styled.input<TextFieldProps>`
  ${({ theme, icon, iconPosition, bgColor }) => css`
    width: 100%;
    height: 48px;
    padding-right: ${!!icon && iconPosition === 'insideRight'
      ? '56px'
      : '16px'};
    padding-left: ${!!icon && iconPosition === 'insideLeft' ? '56px' : '16px'};
    color: ${theme.ds.colors.neutral900};
    font-family: ${theme.ds.typography.fontFamily};
    font-size: ${theme.ds.typography.size.small};
    line-height: ${theme.ds.typography.lineHeight.medium};
    background-color: ${theme.ds.colors[bgColor!]};
    border: 1px solid ${theme.ds.colors.neutral300};
    border-radius: ${theme.ds.typography.border.radius.small};
    outline: none;

    &::placeholder {
      color: ${theme.ds.colors.neutral500};
      font-weight: ${theme.ds.typography.weight.normal};
      opacity: 1;
    }

    &[type='number']::-webkit-inner-spin-button,
    &[type='number']::-webkit-outer-spin-button {
      opacity: 1;
    }
  `}
`

export const Icon = styled.button<IconProps>`
  ${({
    theme,
    iconPosition,
    iconIsButton,
    iconBgColor,
    disabled,
    keepStyleOnIconIsButton
  }) => {
    const inside =
      iconPosition === 'insideRight' || iconPosition === 'insideLeft'
    const iconLeft =
      iconPosition === 'insideLeft' || iconPosition === 'outsideLeft'

    return css`
      overflow: hidden;
      display: flex;
      justify-content: center;
      align-items: center;
      order: ${iconLeft ? 0 : 1};
      height: 48px;
      width: 48px;
      position: ${inside ? 'absolute' : 'relative'} !important;
      top: ${inside ? '50%' : 'unset'};
      right: ${iconPosition === 'insideRight' ? 0 : 'unset'};
      left: ${iconPosition === 'insideLeft' ? 0 : 'unset'};
      transform: ${inside ? 'translateY(-50%)' : 'unset'};
      cursor: ${(iconIsButton || keepStyleOnIconIsButton) && !disabled
        ? 'pointer'
        : 'default'};
      outline: none;
      color: ${iconIsButton && iconBgColor && inside
        ? theme.ds.colors.white
        : theme.ds.colors.neutral300};
      background: ${iconIsButton && disabled && inside
        ? theme.ds.colors.primary400
        : iconIsButton && iconBgColor && inside
        ? theme.ds.colors[iconBgColor!]
        : 'none'};
      border-top: ${iconIsButton && inside
        ? `1px solid ${theme.ds.colors.neutral300}`
        : 'none'};
      border-right: ${iconIsButton && iconPosition === 'insideRight'
        ? `1px solid ${theme.ds.colors.neutral300}`
        : 'none'};
      border-bottom: ${iconIsButton && inside
        ? `1px solid ${theme.ds.colors.neutral300}`
        : 'none'};
      border-left: ${iconIsButton && iconPosition === 'insideLeft'
        ? `1px solid ${theme.ds.colors.neutral300}`
        : 'none'};
      border-top-right-radius: ${iconPosition === 'insideRight'
        ? theme.ds.typography.border.radius.small
        : 0};
      border-bottom-right-radius: ${iconPosition === 'insideRight'
        ? theme.ds.typography.border.radius.small
        : 0};
      border-top-left-radius: ${iconPosition === 'insideLeft'
        ? theme.ds.typography.border.radius.small
        : 0};
      border-bottom-left-radius: ${iconPosition === 'insideLeft'
        ? theme.ds.typography.border.radius.small
        : 0};

      svg,
      path {
        max-width: 24px;
        max-height: 24px;
        fill: ${iconIsButton && iconBgColor && inside
          ? theme.ds.colors.white
          : theme.ds.colors.neutral300};
        opacity: 1;
      }
    `
  }}
`

export const Error = styled.p`
  margin-top: 4px;
  color: ${({ theme }) => theme.ds.colors.danger300};
  font-weight: ${({ theme }) => theme.ds.typography.weight.normal};
  line-height: ${({ theme }) => theme.ds.typography.lineHeight.medium};
  font-size: ${({ theme }) => theme.ds.typography.size.xxsmall};
`

const wrapperModifiers = {
  normal: (theme: DefaultTheme) => css`
    ${Input} {
      &:focus {
        border-color: ${theme.ds.colors.primary500};
      }
    }
  `,
  error: (theme: DefaultTheme) => css`
    ${Input} {
      border-color: ${theme.ds.colors.danger200} !important;
    }
    ${Icon} {
      color: ${theme.ds.colors.danger200} !important;
      border-color: ${theme.ds.colors.danger200} !important;
    }
  `,
  disabled: (theme: DefaultTheme) => css`
    ${Input} {
      cursor: not-allowed;
      color: ${theme.ds.colors.neutral300};
      border-color: ${theme.ds.colors.neutral300};
      background-color: ${theme.ds.colors.neutral100};
    }
  `
}

export const Wrapper = styled.div<WrapperProps>`
  ${({ theme, error }) => error && wrapperModifiers.error(theme)}
  ${({ theme, disabled }) => disabled && wrapperModifiers.disabled(theme)}
`
