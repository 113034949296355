import styled from 'styled-components'

export const FormWrapper = styled.form`
  display: flex;
  flex-direction: column;
  padding-bottom: 32px;
  scrollbar-width: none;
`

export const SurgeryCenters = styled.div`
  margin-top: 24px;
`
