import styled from 'styled-components'
import CardNew from 'presentation/shared/components/CardNew'

export const Wrapper = styled.div`
  margin-bottom: 24px;

  h1 {
    justify-self: flex-start;
    align-self: flex-end;

    color: ${({ theme }) => theme.colors.primary};
    font-size: 20px;
    font-weight: bold;
  }
`
export const SurgicalPlanningSection = styled.section`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 24px;

  .section-title {
    margin-bottom: 8px;
  }

  h1 {
    justify-self: flex-start;
    margin-top: 16px;

    color: ${({ theme }) => theme.colors.primary};
    font-size: 20px;
    font-weight: bold;
  }
`
export const ActionCard = styled(CardNew)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 12px 16px;

  .card-title {
    display: flex;
    align-items: center;
    gap: 8px;

    svg {
      width: 18px;
      height: 18px;
    }
  }
`
