import React from 'react'
import { ContainerNew } from 'presentation/shared/components/ContainerNew'
import HeadingNew from 'presentation/shared/components/HeadingNew'
import SupportTextNew from 'presentation/shared/components/SupportTextNew'
import { SurgicalSchedulingDetailsModel } from 'domain/usecases/surgical-scheduling/get-surgical-scheduling-details'
import CardNew from 'presentation/shared/components/CardNew'
import SurgicalOrderCard from 'presentation/shared/layouts/SurgicalOrderCard'
import * as S from './style'
import moment from 'moment'
import { Booleans } from 'common/enum/booleans'
import { Anesthesia } from 'common/enum/anesthesia'

type Props = {
  details: SurgicalSchedulingDetailsModel
}

export default function SurgicalSchedulingDetailsLayout({ details }: Props) {
  const booleansLabel = (value = false) => {
    return Booleans[String(value) as keyof typeof Booleans]
  }

  const appointmentsNotScheduled = details.appointments?.filter(
    (item) => item.status !== 'scheduled'
  )

  return (
    <ContainerNew gap="24px" noHeader>
      {details.status === 'scheduled' ? (
        <div>
          <HeadingNew color="primary600" size="large">
            Agendamento confirmado
          </HeadingNew>
          <SupportTextNew>
            Visualize as informações da sua cirurgia.
          </SupportTextNew>
        </div>
      ) : (
        <div>
          <HeadingNew color="primary600" size="large">
            Solicitação de agendamento
          </HeadingNew>
          <SupportTextNew>
            Visualize as informações da sua solicitação de agendamento.
          </SupportTextNew>
        </div>
      )}

      <SurgicalOrderCard
        procedures
        statusTag
        schedulingDate
        surgicalOrder={{
          ...details.surgical_order,
          patient: details.patient,
          hospital: {
            name: details.surgical_order?.hospital_name,
            id: details.surgical_order?.hospital_id
          },
          scheduling_status: details.status,
          surgical_order_id: details.surgical_order?.id,
          scheduling_date: details.appointments?.map((appointment) => ({
            [details.status === 'scheduled'
              ? 'scheduled_date'
              : 'requested_date']: appointment.start_date
          }))
        }}
        flatten
      />

      {details.status === 'scheduled' ? (
        <div>
          <S.HeadinEdit color="neutral500" size="large">
            Data agendada
          </S.HeadinEdit>

          <SupportTextNew
            color="primary600"
            weight="bold"
            size="small"
            className="capitalize-fl"
            style={{ marginTop: '8px' }}
          >
            {moment(details.surgery_day).format(
              `ddd - DD [de] MMMM [às] H[h]mm`
            )}
          </SupportTextNew>

          {details.reason_to_scheduling_is_another_date && (
            <SupportTextNew
              size="xxsmall"
              color="neutral500"
              style={{ marginTop: '8px' }}
            >
              {details.reason_to_scheduling_is_another_date}
            </SupportTextNew>
          )}

          {appointmentsNotScheduled.length > 0 && (
            <SupportTextNew size="xxsmall" style={{ marginTop: '8px' }}>
              Obs.: As datas solicitadas por você foram
            </SupportTextNew>
          )}

          {appointmentsNotScheduled?.map((appointment, index) => (
            <div key={index}>
              <SupportTextNew as="span" size="xxsmall" key={index}>
                Opção {index + 1}:
              </SupportTextNew>{' '}
              <SupportTextNew
                as="span"
                size="xxsmall"
                className="capitalize-fl"
              >
                {moment(appointment.start_date)
                  .utc()
                  .format(
                    `ddd - DD [de] MMMM ${
                      appointment.any_time ? '' : '[às] H:mm[h]'
                    }`
                  )}
              </SupportTextNew>
            </div>
          ))}
        </div>
      ) : (
        <div>
          <S.HeadinEdit color="neutral500" size="large">
            Data solicitada
          </S.HeadinEdit>

          <S.CardsGroup>
            {appointmentsNotScheduled?.map((appointment, index) => (
              <CardNew
                key={appointment.id}
                shadow="none"
                background="neutral50"
                padding="8px 16px"
              >
                <SupportTextNew size="xxsmall">
                  Opção {index + 1}
                </SupportTextNew>
                <SupportTextNew
                  color="primary600"
                  weight="bold"
                  className="capitalize-fl"
                >
                  {moment(appointment.start_date)
                    .utc()
                    .format(
                      `ddd - DD [de] MMMM ${
                        appointment.any_time ? '' : '[às] H:mm[h]'
                      }`
                    )}
                  {appointment.is_preferential && (
                    <SupportTextNew as="span" size="xsmall">
                      &nbsp;(Preferencial)
                    </SupportTextNew>
                  )}
                </SupportTextNew>
              </CardNew>
            ))}
          </S.CardsGroup>
        </div>
      )}

      <div>
        <S.HeadinEdit color="neutral500" size="large">
          Tempo de cirurgia
        </S.HeadinEdit>

        <S.CardsGroup columns={details.time?.surgery_time ? 3 : 2}>
          <CardNew shadow="none" background="neutral50" padding="8px 16px">
            <SupportTextNew textAligh="center">Previsto</SupportTextNew>
            <SupportTextNew
              color="primary600"
              weight="bold"
              textAligh="center"
              size="large"
            >
              {details.time?.predicted_time.replace(':', 'h')}
            </SupportTextNew>
          </CardNew>
          <CardNew shadow="none" background="neutral50" padding="8px 16px">
            <SupportTextNew textAligh="center">Solicitado</SupportTextNew>
            <SupportTextNew
              color="primary600"
              weight="bold"
              textAligh="center"
              size="large"
            >
              {details.time?.requested_time?.replace(':', 'h') || '--:--'}
            </SupportTextNew>
          </CardNew>
          {details.time?.surgery_time && (
            <CardNew shadow="none" background="primary50" padding="8px 16px">
              <SupportTextNew textAligh="center">Agendado</SupportTextNew>
              <SupportTextNew
                color="primary600"
                weight="bold"
                textAligh="center"
                size="large"
              >
                {details.time?.surgery_time?.replace(':', 'h') || '--:--'}
              </SupportTextNew>
            </CardNew>
          )}
        </S.CardsGroup>
      </div>

      <div>
        <S.HeadinEdit color="neutral500" size="large">
          Anestesia
        </S.HeadinEdit>

        <S.CardsGroup>
          <CardNew shadow="none" background="neutral50" padding="8px 16px">
            <SupportTextNew textAligh="center" size="xsmall">
              Anestesia
            </SupportTextNew>
            <SupportTextNew color="primary600" weight="bold" textAligh="center">
              {Anesthesia[details.anesthetist?.type as keyof typeof Anesthesia]}
            </SupportTextNew>
          </CardNew>
          {details.anesthetist?.type === 'choose-anesthetist' && (
            <CardNew shadow="none" background="neutral50" padding="8px 16px">
              <SupportTextNew textAligh="center" size="xsmall">
                Nome
              </SupportTextNew>
              <SupportTextNew
                color="primary600"
                weight="bold"
                textAligh="center"
                capitalize
              >
                {details.anesthetist?.name.toLowerCase()}
              </SupportTextNew>
            </CardNew>
          )}
        </S.CardsGroup>
      </div>

      <div>
        <S.HeadinEdit color="neutral500" size="large">
          Equipamentos
        </S.HeadinEdit>

        <S.CardsGroup>
          <CardNew shadow="none" background="neutral50" padding="8px 16px">
            <SupportTextNew>
              Deseja incluir equipamento?{' '}
              <SupportTextNew color="primary600" weight="bold" as="span">
                {booleansLabel(Boolean(details.equipaments?.length))}
              </SupportTextNew>
            </SupportTextNew>
          </CardNew>
          {details.equipaments?.map((equipament) => (
            <CardNew
              shadow="none"
              background="neutral50"
              padding="8px 16px"
              key={equipament.code}
            >
              <SupportTextNew
                color="primary600"
                weight="bold"
                className="capitalize-fl"
              >
                {equipament.name.toLocaleLowerCase()}
              </SupportTextNew>
              <SupportTextNew size="xsmall">
                {equipament.code ? `Código ${equipament.code}` : 'Sem código'}
              </SupportTextNew>
            </CardNew>
          ))}
        </S.CardsGroup>
      </div>

      <div>
        <S.HeadinEdit color="neutral500" size="large">
          Confirmar informações
        </S.HeadinEdit>

        <S.CardsGroup columns={2}>
          <CardNew shadow="none" background="neutral50" padding="8px 16px">
            <SupportTextNew textAligh="center" size="xsmall">
              Unidade cirúrgica
            </SupportTextNew>
            <SupportTextNew
              color="primary600"
              weight="bold"
              textAligh="center"
              className="capitalize-fl"
            >
              {details.surgical_order?.surgery_center_name}
            </SupportTextNew>
          </CardNew>
          <CardNew shadow="none" background="neutral50" padding="8px 16px">
            <SupportTextNew textAligh="center" size="xsmall">
              Paciente internado
            </SupportTextNew>
            <SupportTextNew color="primary600" weight="bold" textAligh="center">
              {booleansLabel(details.surgical_order?.patient_internal)}
            </SupportTextNew>
          </CardNew>
          <CardNew shadow="none" background="neutral50" padding="8px 16px">
            <SupportTextNew textAligh="center" size="xsmall">
              Reserva de sangue
            </SupportTextNew>
            <SupportTextNew color="primary600" weight="bold" textAligh="center">
              {booleansLabel(details.surgical_order?.blood_reserve)}
            </SupportTextNew>
          </CardNew>
          <CardNew shadow="none" background="neutral50" padding="8px 16px">
            <SupportTextNew textAligh="center" size="xsmall">
              Vai precisar de CTI
            </SupportTextNew>
            <SupportTextNew color="primary600" weight="bold" textAligh="center">
              {booleansLabel(details.surgical_order?.cti)}
            </SupportTextNew>
          </CardNew>
          <CardNew shadow="none" background="neutral50" padding="8px 16px">
            <SupportTextNew textAligh="center" size="xsmall">
              Técnica
            </SupportTextNew>
            <SupportTextNew color="primary600" weight="bold" textAligh="center">
              {details.surgical_order?.tecnic}
            </SupportTextNew>
          </CardNew>
          <CardNew shadow="none" background="neutral50" padding="8px 16px">
            <SupportTextNew textAligh="center" size="xsmall">
              Congelação
            </SupportTextNew>
            <SupportTextNew color="primary600" weight="bold" textAligh="center">
              {booleansLabel(details.surgical_order?.freezing)}
            </SupportTextNew>
          </CardNew>
        </S.CardsGroup>
      </div>

      <div>
        <S.HeadinEdit color="neutral500" size="large">
          Observação geral
        </S.HeadinEdit>

        <S.CardsGroup style={{ marginBottom: '24px' }}>
          <CardNew shadow="none" background="neutral50" padding="8px 16px">
            <SupportTextNew style={{ whiteSpace: 'pre-wrap' }}>
              {details.general_observation || 'Nenhuma observação'}
            </SupportTextNew>
          </CardNew>
        </S.CardsGroup>
      </div>
    </ContainerNew>
  )
}
