import React from 'react'
import { observer } from 'mobx-react'
import { useHistory } from 'react-router'

import { useStores } from 'presentation/hooks/use-stores'
import Logo from '../Logo'
import HamburguerIcon from '../Icons/HamburguerIcon'
import { ReactComponent as ArrowLeft } from 'presentation/assets/icons/new_arrow-left.svg'
import { ReactComponent as ProfileIcon } from 'presentation/assets/icons/user-profile.svg'
import SideMenu from '../SideMenu'
import * as S from './styles'

type Props = {
  actualPageTitle?: string
  actualPageOnClick?: () => void
  showMenu?: boolean
  topFixed?: boolean
}

export const HeaderNew = observer(
  ({ actualPageOnClick, showMenu = false, topFixed = false }: Props) => {
    const history = useHistory()
    const menuStore = useStores().menu

    return (
      <>
        <S.Wrapper aria-label="header" topFixed={topFixed}>
          {showMenu ? (
            <S.HamburguerIconWrapper
              aria-label="Ícone abrir menu"
              onClick={() => menuStore.open()}
            >
              <HamburguerIcon />
            </S.HamburguerIconWrapper>
          ) : (
            <S.ButtonGoBack
              onClick={
                actualPageOnClick
                  ? () => actualPageOnClick()
                  : () => history.goBack()
              }
              type="button"
            >
              <ArrowLeft />
            </S.ButtonGoBack>
          )}

          <S.LogoWrapper onClick={() => history.push('/home')}>
            <Logo />
          </S.LogoWrapper>

          <S.ProfileWrapper onClick={() => false}>
            <ProfileIcon />
          </S.ProfileWrapper>
        </S.Wrapper>
        {showMenu && <SideMenu />}
      </>
    )
  }
)

export default HeaderNew
