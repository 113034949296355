import React, { createContext, useReducer } from 'react'
import { Switch, useParams, Route, useRouteMatch } from 'react-router'
import SurgicalOrderDetails from 'presentation/doctor/pages/SurgicalOrderDetails'
import SurgicalOrderPendencyDocuments from 'presentation/doctor/pages/SurgicalOrderPendencyDocuments'
import SurgicalOrderPendency from 'presentation/doctor/pages/SurgicalOrderPendency'
import { SurgicalOrderModel } from 'domain/entities/surgical-order-model'
import { SchedulingTypes } from 'domain/usecases/surgical-order/load-surgical-orders-by-doctor-and-status'
import { GuidesDetails } from 'domain/usecases/surgical-order/load-guides-by-type'
import { TimelineModel } from 'domain/usecases/surgical-order/get-surgical-order-timeline'

export const SurgicalOrderContext = createContext({})

type Types =
  | 'PLANNING_TAB'
  | 'PLANNING_TAB_I'
  | 'PLANNING_TAB_O'
  | 'PLANNING_TAB_DOCUMENTS'
  | 'TIMELINE_TAB'
  | 'SURGICAL_ORDER'
  | 'SURGICAL_PENDENCIES'
  | 'CURRENT_SURGICAL_PENDENCY'

type Payload = any

export type Actions = {
  payload: Payload
  type: Types
}

export type ContextType = {
  dispatch: (params: Actions) => void
  state: {
    card: SurgicalOrderModel
    footer: {
      status: SchedulingTypes
      created_at: Date
    }
    pendencies: {
      in_progress: any[]
      pendent: any[]
      resolved: any[]
    }
    currentPendency: any
    timeline: TimelineModel[]
    planning: {
      opmeSuppliers: GuidesDetails[]
      authProcedures: GuidesDetails[]
      documents: Document[]
    } & SurgicalOrderModel
  }
}

const reducer = (state: any, action: Actions) => {
  switch (action.type) {
    case 'SURGICAL_ORDER':
      return {
        ...state,
        card: action.payload.card,
        footer: action.payload.footer
      }
    case 'SURGICAL_PENDENCIES':
      return { ...state, pendencies: action.payload }
    case 'CURRENT_SURGICAL_PENDENCY':
      return { ...state, currentPendency: action.payload }
    case 'PLANNING_TAB':
      return {
        ...state,
        planning: {
          ...action.payload,
          authProcedures: state.planning.authProcedures,
          opmeSuppliers: state.planning.opmeSuppliers,
          documents: state.planning.documents
        }
      }
    case 'PLANNING_TAB_I':
      return {
        ...state,
        planning: {
          ...state.planning,
          authProcedures: action.payload
        }
      }
    case 'PLANNING_TAB_O':
      return {
        ...state,
        planning: {
          ...state.planning,
          opmeSuppliers: action.payload
        }
      }
    case 'PLANNING_TAB_DOCUMENTS':
      return {
        ...state,
        planning: {
          ...state.planning,
          documents: action.payload
        }
      }
    case 'TIMELINE_TAB':
      return { ...state, timeline: action.payload }
    default:
      return state
  }
}

export default function makeSurgicalOrderPage() {
  const { path } = useRouteMatch()
  const [state, dispatch] = useReducer(reducer, {
    card: {
      surgical_order_id: 0,
      scheduling_status: '',
      patient: {
        patient_id: 1,
        name: ''
      },
      health_insurance: {
        health_insurance_id: 0,
        name: '',
        health_insurance_status: []
      },
      hospital: {
        hospital_id: 1,
        name: ''
      },
      procedures: [],
      scheduling_date: [],
      pendencies: [],
      status: [],
      active_status: '',
      isSolicitation: false
    },
    footer: {
      status: '',
      created_at: ''
    },
    pendencies: {
      in_progress: [],
      pendent: [],
      resolved: []
    },
    currentPendency: {
      historic: [],
      documents: []
    },
    planning: {
      ...({} as SurgicalOrderModel),
      opmeSuppliers: [] as GuidesDetails[],
      authProcedures: [] as GuidesDetails[],
      documents: [] as Document[]
    },
    timeline: [] as TimelineModel[]
  })

  return (
    <>
      <Switch>
        <SurgicalOrderContext.Provider value={{ state, dispatch }}>
          <Route path={`${path}/:page`} component={Page} />
        </SurgicalOrderContext.Provider>
      </Switch>
    </>
  )
}

function Page() {
  const { page } = useParams<{ page: string }>()

  switch (page) {
    case 'detalhes':
      return <SurgicalOrderDetails />
    case 'pendencia':
      return <SurgicalOrderPendency />
    case 'documentos-de-pendencia':
      return <SurgicalOrderPendencyDocuments />
    default:
      return <SurgicalOrderDetails />
  }
}
