import { AxiosResponse } from 'axios'
import { ApiRepository } from 'repository/api-repository'
import { ApiResponse } from 'service/protocols/api/api-client'
import {
  IApiRepository,
  RepositoryRequest
} from 'service/protocols/api/api-repository'
import { GetStorage } from 'service/protocols/cache/get-storage'

export class PrepareApiRequestSurgicalSchedulingDecorator
  implements IApiRepository
{
  constructor(
    private readonly getStorage: GetStorage,
    private readonly apiRepository: ApiRepository
  ) {}

  async get(data: RepositoryRequest): Promise<ApiResponse | AxiosResponse> {
    const account = this.getStorage.get('account')
    if (account?.accessToken) {
      Object.assign(data, {
        headers: Object.assign(data.headers || {}, {
          authorization: 'Bearer ' + account.accessToken
        })
      })
    }
    data.url =
      process.env.NODE_ENV === 'production'
        ? `/proxy-surgical-schedule${data.url}`
        : `${
            process.env.REACT_APP_API_MATERDEI_SURGICAL_SCHEDULING_URL +
            data.url
          }`

    return await this.apiRepository.get(data)
  }

  async post(data: RepositoryRequest): Promise<ApiResponse> {
    const account = this.getStorage.get('account')
    if (account?.accessToken) {
      Object.assign(data, {
        Headers: Object.assign(data.headers || {}, {
          authorization: 'Bearer ' + account.accessToken
        })
      })
    }
    data.url =
      process.env.NODE_ENV === 'production'
        ? `/proxy-surgical-schedule${data.url}`
        : `${
            process.env.REACT_APP_API_MATERDEI_SURGICAL_SCHEDULING_URL +
            data.url
          }`

    return await this.apiRepository.post(data)
  }

  async put(data: RepositoryRequest): Promise<ApiResponse> {
    const account = this.getStorage.get('account')
    if (account?.accessToken) {
      Object.assign(data, {
        headers: Object.assign(data.headers || {}, {
          authorization: 'Bearer ' + account.accessToken
        })
      })
    }
    data.url =
      process.env.NODE_ENV === 'production'
        ? `/proxy-surgical-schedule${data.url}`
        : `${
            process.env.REACT_APP_API_MATERDEI_SURGICAL_SCHEDULING_URL +
            data.url
          }`

    return await this.apiRepository.put(data)
  }

  delete(data: RepositoryRequest): Promise<ApiResponse> {
    const account = this.getStorage.get('account')
    if (account?.accessToken) {
      Object.assign(data, {
        headers: Object.assign(data.headers || {}, {
          authorization: 'Bearer ' + account.accessToken
        })
      })
    }
    data.url =
      process.env.NODE_ENV === 'production'
        ? `/proxy-surgical-schedule${data.url}`
        : `${
            process.env.REACT_APP_API_MATERDEI_SURGICAL_SCHEDULING_URL +
            data.url
          }`

    return this.apiRepository.put(data)
  }
}
