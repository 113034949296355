import { Patient } from 'domain/entities/patient-model'
import { TreatmentType } from 'domain/entities/therapeutic-plan.model'
import { CreateTherapeuticPlan } from 'domain/usecases/therapeutic-plan/create-therapeutic-plan'
import 'main/config/yup'
import Button from 'presentation/shared/components/Button'
import { Container } from 'presentation/shared/components/Container'
import Heading from 'presentation/shared/components/Heading'
import Radio from 'presentation/shared/components/RadioButton'
import SupportText from 'presentation/shared/components/SupportText'
import React, { useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { toast } from 'react-toastify'
import * as S from './styles'

interface Props {
  createInitialTherapeuticPlan?: CreateTherapeuticPlan
}

export default function SelectTherapeuticPlanType({
  createInitialTherapeuticPlan
}: Props) {
  const history = useHistory()

  const { state } = useLocation<{
    patient: Patient
  }>()
  const [type, setType] = useState<TreatmentType>()

  const onSubmit = async () => {
    try {
      if (type && state?.patient?.patient_id && createInitialTherapeuticPlan) {
        const therapeuticPlan = await createInitialTherapeuticPlan?.add({
          patient_id: state?.patient?.patient_id,
          treatmentType: type
        })
        history.push('/novo-plano-terapeutico/resumo', {
          therapeuticPlan,
          patient: state.patient
        })
      }
    } catch (error: any) {
      toast.error('Erro ao iniciar tratamento')
    }
  }

  return (
    <Container
      title="Plano terapêutico"
      titleColor="primary"
      subtitle="Solicitação para quimioterápicos e adjuvantes"
      primaryButton={
        <Button fullWidth disabled={!type} onClick={() => onSubmit()}>
          Continuar
        </Button>
      }
      secondaryButton={
        <SupportText onClick={() => history.goBack()} color="primary">
          Cancelar
        </SupportText>
      }
      actualPageText={'Voltar'}
      actualPageOnClick={() => {
        history.goBack()
      }}
      noPadding
    >
      <S.Content>
        <Heading as="h1" color="primary">
          Qual o tipo de tratamento?
        </Heading>
        <S.Inputs>
          <Radio
            name="type"
            labelFor="initial"
            value="initial"
            label="Inicial"
            onCheck={() => setType('INITIAL')}
          />
          <Radio
            name="type"
            labelFor="continuity"
            value="continuity"
            label="Continuidade"
            onCheck={() => setType('CONTINUITY')}
          />
          <Radio
            name="type"
            labelFor="protocol change"
            value="protocol change"
            label="Mudança de protocolo"
            onCheck={() => setType('PROTOCOL_CHANGE')}
          />
        </S.Inputs>
      </S.Content>
    </Container>
  )
}
