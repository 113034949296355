import React, { useEffect, useContext } from 'react'
import HeaderNew from 'presentation/shared/components/HeaderNew'
import SurgicalOrdersSchedulingLayout from 'presentation/doctor/layouts/SurgicalOrdersScheduling'
import { SurgicalOrderDashboardContext } from 'main/factories/pages/doctor/surgical-order/surgical-order-dashboard-factory'
import { useServices } from 'presentation/hooks/use-services'
import { toast } from 'react-toastify'
import {
  WithLoading,
  WithLoadingProps
} from 'presentation/shared/components/HOCs/WithLoading'
import { useHistory, useLocation } from 'react-router'
import {
  SchedulingStatusTypes,
  GetSurgicalSchedulesByDoctor
} from 'domain/usecases/surgical-order/get-surgical-schedules-by-doctor'

function SurgicalOrdersScheduling({ setIsLoading }: WithLoadingProps) {
  const history = useHistory()
  const location =
    useLocation<{ schedulingStatusType: SchedulingStatusTypes }>()
  const surgicalOrderService = useServices().surgicalOrder
  const { dispatch } = useContext<any>(SurgicalOrderDashboardContext)

  const getSchedulingsCount = async () => {
    try {
      const response: any =
        await surgicalOrderService.getSurgicalSchedulesCountByDoctor()

      dispatch({
        type: 'SCHEDULINGS_COUNT',
        payload: response.data
      })
    } catch (error: any) {
      toast.error('Ocorreu um erro ao buscar as pendencias')
    }
  }

  const getSchedulingsList = async (
    params: GetSurgicalSchedulesByDoctor.Params
  ) => {
    setIsLoading(true)
    try {
      const response: any =
        await surgicalOrderService.getSurgicalSchedulesByDoctor(params)

      dispatch({
        type: 'PAGINATION',
        payload: response.data.page_info
      })

      dispatch({
        type: 'SURGICAL_ORDERS_LIST',
        payload: response.data.surgical_schedules,
        clearList: params.pageNumber === 1
      })
    } catch (error: any) {
      toast.error('Ocorreu um erro ao buscar os pedidos cirurgicos')
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    setIsLoading(true)
    const initialStatus = location.state?.schedulingStatusType || 'UNSCHEDULED'
    Promise.all([
      getSchedulingsCount(),
      getSchedulingsList({ schedulingStatus: initialStatus, pageNumber: 1 })
    ]).finally(() => setIsLoading(false))
  }, [])

  return (
    <>
      <HeaderNew
        actualPageTitle="Pedido cirúrgico"
        actualPageOnClick={() => history.goBack()}
      />
      <SurgicalOrdersSchedulingLayout
        getSchedulingsList={getSchedulingsList}
        initialStatus={location.state?.schedulingStatusType}
      />
    </>
  )
}

export default WithLoading(SurgicalOrdersScheduling)
