import theme from 'presentation/styles/theme'
import React, {
  AnchorHTMLAttributes,
  ButtonHTMLAttributes,
  forwardRef,
  ForwardRefRenderFunction
} from 'react'
import * as S from './styles'

type ButtonTypes =
  | AnchorHTMLAttributes<HTMLAnchorElement>
  | ButtonHTMLAttributes<HTMLButtonElement>

export type ButtonProps = {
  as?: React.ElementType
  variant?: 'default' | 'text'
  type?: 'button' | 'submit' | undefined
  size?: 'small' | 'medium' | 'large'
  fontSize?: keyof typeof theme.ds.typography.size
  weight?: keyof typeof theme.ds.typography.weight
  outlined?: boolean
  fullWidth?: boolean
  disabled?: boolean
  noPadding?: boolean
  icon?: React.ReactNode
  iconPosition?: 'left' | 'right'
} & ButtonTypes

const ButtonNew: ForwardRefRenderFunction<S.WrapperProps, ButtonProps> = (
  {
    variant = 'default',
    type,
    size = 'medium',
    fontSize = 'small',
    weight = 'bold',
    outlined = false,
    fullWidth = false,
    disabled = false,
    noPadding = false,
    icon = false,
    iconPosition = 'left',
    ...props
  }: ButtonProps,
  ref
) => (
  <S.Wrapper
    ref={ref}
    variant={variant}
    type={type}
    size={size}
    fontSize={fontSize}
    weight={weight}
    outlined={outlined}
    fullWidth={fullWidth}
    disabled={disabled}
    noPadding={noPadding}
    {...props}
  >
    {!!icon && <S.Icon iconPosition={iconPosition}>{icon}</S.Icon>}
    {!!props.children && <span>{props.children}</span>}
  </S.Wrapper>
)

export default forwardRef(ButtonNew)
