import CardNew from 'presentation/shared/components/CardNew'
import styled from 'styled-components'

export const CardPatient = styled(CardNew)`
  margin-bottom: 24px;
`

export const CardField = styled.div`
  margin: 4px 0;
`
