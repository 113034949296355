import CardNew from 'presentation/shared/components/CardNew'
import styled from 'styled-components'
import theme from 'presentation/styles/theme'

export const PendenciesHeader = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`
export const PendenciesContainer = styled.div`
  display: flex;
  gap: 16px;
  flex-direction: column;
`

export const EmptyState = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 80px;
  gap: 16px;
  text-align: center;
  svg {
    max-width: 80%;
  }
`

export const TabsWrapper = styled.div`
  padding-bottom: 16px;
`

export const CardWrapper = styled.div`
  margin-top: 20px;
`

export const TextWrapper = styled.div`
  margin-bottom: 20px;
`

export const ActionCard = styled(CardNew)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 12px 16px;

  .card-title {
    display: flex;
    align-items: center;
    gap: 8px;

    svg {
      width: 18px;
      height: 18px;
    }
  }
`

export const QuantityWrapper = styled.div`
  display: flex;
  align-self: start;
  gap: 65px;
  margin-top: 8px;
  p {
    white-space: nowrap;
  }
`

export const DosageWrapper = styled.div`
  display: flex;
  align-self: start;
  gap: 48px;
  margin-top: 8px;
  p {
    white-space: nowrap;
  }
`

export const AccordionsContainer = styled.div`
  gap: 6px;
  margin-bottom: 50px;
`

export const Icon = styled.div<{
  color: keyof typeof theme.ds.colors
}>`
  width: 30px;
  display: flex;

  svg {
    path {
      fill: ${({ theme, color = 'secondary100' }) => theme.ds.colors[color]};
    }
  }
`

export const StatusCard = styled(CardNew)`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  margin-top: -20px;
  margin-bottom: 30px;
  div {
    display: flex;
    flex-direction: column;
  }
`

export const Timeline = styled.div`
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`
export const TimelineStep = styled.div<{
  pipe?: keyof typeof theme.ds.colors
  color: keyof typeof theme.ds.colors
}>`
  position: relative;
  margin-bottom: 24px;
  width: 200px;
  &::before {
    content: '';
    display: block;
    position: absolute;
    left: -28px;
    top: 50%;
    transform: translateY(-50%);
    height: 12px;
    width: 12px;
    border-radius: 50%;
    background-color: ${({ theme, color = 'secondary400' }) =>
      theme.ds.colors[color]};
  }
  &::after {
    content: '';
    display: block;
    position: absolute;
    left: -23px;
    top: calc(50% + 12px);
    width: 2px;
    height: calc(100%);
    /* transform: translateY(-50%); */
    background-color: ${({ theme, pipe = 'secondary100' }) =>
      theme.ds.colors[pipe]};
  }
  &:last-child {
    margin-bottom: 0;
    &::after {
      display: none;
    }
  }
`

export const Separator = styled.div`
  width: 1px;
  height: 23px;
  margin-left: -9px;
  margin-right: 2px;
  background-color: ${({ theme }) => theme.ds.colors.neutral100};
`

export const ActionIcons = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;
  margin-top: 30px;

  svg {
    width: 22px;
    height: 22px;
    cursor: pointer;
  }

  .download-icon {
    path {
      stroke: ${({ theme }) => theme.ds.colors.neutral500};
    }
    &:hover {
      path {
        stroke: ${({ theme }) => theme.ds.colors.neutral900};
      }
    }
  }
  .thresh-icon {
    path {
      fill: ${({ theme }) => theme.ds.colors.primary400};
    }
    &:hover {
      path {
        fill: ${({ theme }) => theme.ds.colors.primary500};
      }
    }
  }
`

export const DocumentInfo = styled.div`
  display: flex;

  margin-top: 5px;
`

export const Line = styled.div`
  width: 240px;
  height: 4px;
  margin-top: 8px;
  background-color: ${({ theme }) => theme.ds.colors.primary600};
`
