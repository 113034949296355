import theme from 'presentation/styles/theme'

export enum SurgicalOrderStatus {
  ORDER_REGISTERED = 'ORDER_REGISTERED',
  PRE_SCHEDULE = 'PRE_SCHEDULE',
  OPME_QUOTATION = 'OPME_QUOTATION',
  CRMO = 'CRMO',
  HEALTH_INSURANCE_APPROVE = 'HEALTH_INSURANCE_APPROVE',
  AUTHORIZED = 'AUTHORIZED',
  REVIEW = 'REVIEW',
  CANCELLATION_REQUEST = 'CANCELLATION_REQUEST',
  CANCELLED = 'CANCELLED',
  PENDENCY = 'PENDENCY',
  FINISHED = 'CONCLUDED',
  CONCLUDED = 'CONCLUDED',
  SCHEDULED = 'SCHEDULED',
  FASTPASS = 'FASTPASS',
  CHECK_IN = 'CHECK_IN',
  PATIENT_IN_SURGERY = 'PATIENT_IN_SURGERY',
  PATIENT_RECOVERY = 'PATIENT_RECOVERY',
  PATIENT_DISCHARGE = 'PATIENT_DISCHARGE',
  SERVICE_EVALUATION = 'SERVICE_EVALUATION',

  SCHEDULING_IN_ANALYZE = 'SCHEDULING_IN_ANALYZE'
}

export const TimelineStatus: {
  label: string
  status: SurgicalOrderStatus[] | string[]
  color: string
}[] = [
  {
    label: 'Pedido cadastrado',
    status: [SurgicalOrderStatus.ORDER_REGISTERED],
    color: theme.colors.blue
  },
  {
    label: 'Pedido em revisão',
    status: [SurgicalOrderStatus.REVIEW],
    color: theme.colors.orange
  },
  {
    label: 'Em cotação de OPME',
    status: [SurgicalOrderStatus.OPME_QUOTATION],
    color: theme.colors.bluishGreen
  },
  {
    label: 'Em análise na CRMO',
    status: [SurgicalOrderStatus.CRMO],
    color: theme.colors.bluishGreen
  },
  {
    label: 'Em análise no convênio',
    status: [SurgicalOrderStatus.HEALTH_INSURANCE_APPROVE],
    color: theme.colors.bluishGreen
  },
  {
    label: 'Cirurgia agendada',
    status: [SurgicalOrderStatus.SCHEDULED],
    color: theme.colors.bluishGreen
  },
  {
    label: 'Cirurgia autorizada',
    status: [SurgicalOrderStatus.AUTHORIZED],
    color: theme.colors.orange
  },
  {
    label: 'Cirurgia realizada',
    status: [SurgicalOrderStatus.CONCLUDED],
    color: theme.colors.orange
  },
  {
    label: 'Fastpass',
    status: [SurgicalOrderStatus.FASTPASS],
    color: theme.colors.darkPurple
  },
  {
    label: 'Check-in',
    status: [SurgicalOrderStatus.CHECK_IN],
    color: theme.colors.blueishGreen
  },
  {
    label: 'Paciente em cirurgia',
    status: [SurgicalOrderStatus.PATIENT_IN_SURGERY],
    color: theme.colors.greyishBlue
  },
  {
    label: 'Paciente em recuperação anestésica',
    status: [SurgicalOrderStatus.PATIENT_RECOVERY],
    color: theme.colors.darkBlue
  },
  {
    label: 'Alta do Centro Cirúrgico',
    status: [SurgicalOrderStatus.PATIENT_DISCHARGE],
    color: theme.colors.golden
  },
  {
    label: 'Avaliação dos serviços',
    status: [SurgicalOrderStatus.SERVICE_EVALUATION],
    color: theme.colors.violet
  },
  {
    label: 'Cancelado',
    status: [SurgicalOrderStatus.CANCELLED],
    color: theme.colors.lightRed
  }
]
