import React, { useState, useContext } from 'react'
import SurgicalSchedulingEquipamentsLayout from 'presentation/doctor/layouts/SurgicalSchedulingEquipaments'
import { useServices } from 'presentation/hooks/use-services'
import { EquipamentModel } from 'domain/usecases/surgical-scheduling/search-equipaments'
import { SurgicalSchedulinhContext } from 'main/factories/pages/doctor/surgical-scheduling/surgical-scheduling-factory'
import HeaderNew from 'presentation/shared/components/HeaderNew'

export default function SurgicalSchedulingEquipaments() {
  const { dispatch, state } = useContext<any>(SurgicalSchedulinhContext)
  const surgicalSchedulingService = useServices().surgicalScheduling

  const [equipamentsSuggestions, setEquipamentsSuggestions] = useState<
    EquipamentModel[]
  >([] as EquipamentModel[])

  const searchEquipament = async (term: string) => {
    setEquipamentsSuggestions([] as EquipamentModel[])

    const response = await surgicalSchedulingService.searchEquipaments({
      term
    })

    setEquipamentsSuggestions(response.data || ([] as EquipamentModel[]))
  }

  return (
    <>
      <HeaderNew actualPageTitle="Agendar cirurgia" />
      <SurgicalSchedulingEquipamentsLayout
        equipamentsSuggestions={equipamentsSuggestions}
        searchEquipament={searchEquipament}
        dispatch={dispatch}
        equipaments={state.equipaments}
      />
    </>
  )
}
