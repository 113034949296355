import styled from 'styled-components'

export const PendenciesHeader = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`
export const PendenciesContainer = styled.div`
  display: flex;
  gap: 16px;
  flex-direction: column;
`

export const EmptyState = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 80px;
  gap: 16px;
  text-align: center;
  svg {
    max-width: 80%;
  }
`

export const TabsWrapper = styled.div`
  padding-bottom: 16px;
  /* width: 100%; */
`

export const CardWrapper = styled.div`
  margin-top: 20px;
  margin-bottom: 20px;
`
