import styled from 'styled-components'
import SupportTextNew from 'presentation/shared/components/SupportTextNew'
import { ReactComponent as Info } from 'presentation/assets/icons/info-filled.svg'
import { ReactComponent as Close } from 'presentation/assets/icons/close.svg'
import CardNew from 'presentation/shared/components/CardNew'

export const LinkText = styled(SupportTextNew)`
  text-decoration: underline;

  :hover {
    cursor: pointer;
  }
`

export const InfoIcon = styled(Info)`
  path {
    fill: ${({ theme }) => theme.ds.colors.info300};
  }
`

export const CloseIcon = styled(Close)`
  width: 16px;

  path {
    fill: ${({ theme }) => theme.ds.colors.info300};
  }

  :hover {
    cursor: pointer;
  }
`

export const Card = styled(CardNew)`
  gap: 8px;
  background-color: ${({ theme }) => theme.ds.colors.info25};
  position: fixed;
  top: auto;
  bottom: 10%;
  margin: 0 24px;
`

export const IconsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`
