import React, { useContext, useState } from 'react'
import HeaderNew from 'presentation/shared/components/HeaderNew'
import { useServices } from 'presentation/hooks/use-services'
import { SurgicalOrderContext } from 'main/factories/pages/doctor/surgical-order/surgical-order-factory'
import {
  WithLoading,
  WithLoadingProps
} from 'presentation/shared/components/HOCs/WithLoading'
import { toast } from 'react-toastify'
import SurgicalOrderPendencyDocumentsLayout from 'presentation/doctor/layouts/SurgicalOrderPendencyDocuments'
import { DocumentsList } from 'common/enum/documents-list'
import { generateHash } from 'common/utils/generateHash'
import { renameFile } from 'common/utils/file/renameFile'
import downloadFileFromBlob from 'common/utils/downloadFileFromBlob'
import { useHistory } from 'react-router'

function SurgicalOrderPendencyDocuments({ setIsLoading }: WithLoadingProps) {
  const history = useHistory()
  const surgicalPendencyService = useServices().surgicalPendency
  const {
    dispatch,
    state: { currentPendency }
  } = useContext<any>(SurgicalOrderContext)
  const [hasNewDocument, setHasNewDocument] = useState(false)

  const handlePendencyError = () => {
    toast.warning('Não foi possível carregar a pendência, tente novamente.')
    history.push('/pedidos-cirurgicos/pendencias')
  }

  const getPendencyHistory = async (surgical_pendency_id: number) => {
    if (!surgical_pendency_id) return handlePendencyError()
    try {
      setIsLoading(true)
      const response: any =
        await surgicalPendencyService.loadSurgicalOrderPendenciesHistory(
          surgical_pendency_id
        )

      dispatch({
        type: 'CURRENT_SURGICAL_PENDENCY',
        payload: response.data
      })
    } catch {
      toast.error('Ocorreu um erro ao carregar a pendência')
    } finally {
      setIsLoading(false)
    }
  }

  const uploadSurgicalPendencyDocument = async (
    files: File[],
    type: string
  ) => {
    if (!currentPendency.surgical_pendency_id) return handlePendencyError()
    setIsLoading(true)
    const filesList = [...files]

    await Promise.all(
      filesList.map(async (file) => {
        try {
          const form = new FormData()
          const newFile = renameFile(file, file.name)
          form.append('file', newFile)
          form.append('type', type)
          form.append('group_id', generateHash())
          form.append(
            'surgical_pendency_id',
            String(currentPendency.surgical_pendency_id)
          )

          await surgicalPendencyService.uploadSurgicalPendencyDocument({
            type: newFile.type,
            form: form,
            file: newFile
          })
        } catch {
          toast.error('Ocorreu um erro ao enviar o documento')
        }
      })
    )
      .then(() => getPendencyHistory(currentPendency.surgical_pendency_id))
      .finally(() => setIsLoading(false))
  }

  const downloadDocument = async (
    document_id: number,
    group_id: string,
    type: keyof typeof DocumentsList
  ) => {
    if (!currentPendency.surgical_pendency_id) return handlePendencyError()
    try {
      setIsLoading(true)
      const document =
        await surgicalPendencyService.loadSurgicalPendencyDocument({
          surgical_pendency_id: currentPendency.surgical_pendency_id,
          document_id,
          group_id
        })

      if (document) {
        downloadFileFromBlob(
          document.data,
          document.contentType,
          DocumentsList[type]
        )
      }
    } catch {
      toast.error('Ocorreu um erro ao baixar o documento')
    } finally {
      setIsLoading(false)
    }
  }

  const deleteDocument = async (document_id: number, group_id: string) => {
    if (!currentPendency.surgical_pendency_id) return handlePendencyError()
    try {
      setIsLoading(true)
      await surgicalPendencyService.removeDocumentFromSurgicalPendency({
        surgical_pendency_id: currentPendency.surgical_pendency_id,
        document_id,
        group_id
      })
      await getPendencyHistory(currentPendency.surgical_pendency_id)
    } catch {
      toast.error('Ocorreu um erro ao excluir o documento')
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <>
      <HeaderNew
        actualPageTitle="Pedido cirúrgico"
        actualPageOnClick={() => {
          history.push('/pedido-cirurgico/pendencia', {
            has_new_document: hasNewDocument,
            surgical_pendency_id: currentPendency.surgical_pendency_id
          })
          setHasNewDocument(false)
        }}
      />
      <SurgicalOrderPendencyDocumentsLayout
        onUploadFile={uploadSurgicalPendencyDocument}
        documents={currentPendency.documents}
        onDownloadFile={downloadDocument}
        onDeleteFile={deleteDocument}
        pendencyId={currentPendency.surgical_pendency_id}
        hasNewDocument={hasNewDocument}
        setHasNewDocument={setHasNewDocument}
      />
    </>
  )
}

export default WithLoading(SurgicalOrderPendencyDocuments)
