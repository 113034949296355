import React, { useEffect, useState } from 'react'
import SurgicalSchedulingDetailsLayout from 'presentation/doctor/layouts/SurgicalSchedulingDetails'
import { useServices } from 'presentation/hooks/use-services'
import { SurgicalSchedulingDetailsModel } from 'domain/usecases/surgical-scheduling/get-surgical-scheduling-details'
import {
  WithLoading,
  WithLoadingProps
} from 'presentation/shared/components/HOCs/WithLoading'
import { toast } from 'react-toastify'
import HeaderNew from 'presentation/shared/components/HeaderNew'
import { useLocation } from 'react-router'

type LocationState = {
  surgical_order_id: number
  scheduling_id?: number
  statusSchedulingSurgical: string
}

function SurgicalSchedulingDetails({ setIsLoading }: WithLoadingProps) {
  const { state } = useLocation<LocationState>()
  const [details, setDetails] = useState<SurgicalSchedulingDetailsModel>(
    {} as SurgicalSchedulingDetailsModel
  )
  const surgicalSchedulingService = useServices().surgicalScheduling

  const getSurgicalSchedulingDetails = async () => {
    setIsLoading(true)
    try {
      const response =
        await surgicalSchedulingService.getSurgicalSchedulingDetails(state)
      setDetails(response.data)
    } catch (error: any) {
      toast.error('Ocorreu um erro ao buscar as informações')
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    getSurgicalSchedulingDetails()
  }, [])

  // const goToSchedulingsList = () => {
  //   history.push('/pedidos-cirurgicos/agendamento', {
  //     schedulingStatusType:
  //       details.status === 'scheduled' ? 'SCHEDULED' : 'UNDER_ANALYSIS'
  //   })
  // }

  return (
    <>
      <HeaderNew
        actualPageTitle="Agendamento cirúrgico"
        // actualPageOnClick={goToSchedulingsList}
      />
      <SurgicalSchedulingDetailsLayout details={details} />
    </>
  )
}

export default WithLoading(SurgicalSchedulingDetails)
