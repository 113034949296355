import React, { useEffect, useState } from 'react'

import { UpdatePatientInfo } from 'domain/usecases/patient/update-patient-info'
import { PatientPersonalInfoForm } from 'presentation/shared/components/Forms/CarouselPersonalInfo'
import { LoadPatientInfo } from 'domain/usecases/patient/load-patient-info'
import UpdatePersonalInfoLayout from 'presentation/shared/layouts/UpdatePersonalInfo'
import { Patient } from 'domain/entities/patient-model'
import { useLocation } from 'react-router-dom'

type ChangeSurgicalOrderPersonalInfoProps = {
  useCase?: UpdatePatientInfo
  loadPatientInfo: LoadPatientInfo
}

type LocationType = {
  state: {
    patient_id: number
  }
}

export default function ChangeSurgicalOrderPersonalInfo({
  useCase,
  loadPatientInfo
}: ChangeSurgicalOrderPersonalInfoProps) {
  const [patientInfo, setPatientInfo] = useState({} as LoadPatientInfo.Model)
  const [loading, setLoading] = useState(true)
  const { state } = useLocation() as LocationType

  patientInfo.patient_id = state?.patient_id

  useEffect(() => {
    const loadData = async () => {
      const patient = await loadPatientInfo.load(
        ['name', 'birthday', 'phone', 'cpf', 'email', 'gender'],
        state?.patient_id
      )

      setPatientInfo({ ...patient } as Patient)
      setLoading(false)
    }
    loadData()
  }, [])

  return (
    <>
      {!loading && (
        <UpdatePersonalInfoLayout
          useCase={useCase}
          patientInfo={patientInfo as PatientPersonalInfoForm}
        />
      )}
    </>
  )
}
