import styled from 'styled-components'

export const Wrapper = styled.div`
  position: relative;
  hr {
    border-color: #bfbfbf;
    background-color: #bfbfbf;
    color: #bfbfbf;
    border-top: 1px solid #bfbfbf;
  }
`

export const DateText = styled.div`
  text-align: center;
  font-weight: 500;
  margin: 10px 0;
  color: #6a6a6a;
`

export const ShortCutsWrapper = styled.div`
  display: flex;
  gap: 8px;
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  scrollbar-width: none;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    display: none;
  }
`

export const Loading = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  flex-direction: column;

  &::after {
    content: '';
    position: absolute;
    inset: 0;
    z-index: 3;
    background-color: ${({ theme }) => theme.ds.colors.neutral25};
    opacity: 0.7;
  }

  img {
    position: relative;
    z-index: 4;
    width: 100px;
    height: 100px;
  }
`
