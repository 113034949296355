import styled, { css } from 'styled-components'
import {
  Accordion,
  AccordionSummary,
  AccordionDetails
} from '@material-ui/core'
import { CardProps } from '../CardNew'

export const StyledAccordion = styled(Accordion)<CardProps & { onChange: any }>`
  ${({
    theme,
    shadow = 'satin',
    background = 'white',
    padding = '0px',
    border = 'none',
    disabled = false
  }) => css`
    border-radius: 8px !important;
    box-shadow: ${theme.ds.shadows[shadow]};
    background: ${theme.ds.colors[background]};
    transition: all 0.2s ease-out;
    border: ${border === 'none'
      ? 'none'
      : `1px solid ${
          disabled ? theme.ds.colors.neutral200 : theme.ds.colors[border]
        }`};

    &::before,
    &::after {
      display: none;
    }

    &.Mui-expanded {
      margin: 0;
    }

    .MuiAccordionSummary-root {
      padding: 0 ${padding};
      &.Mui-expanded {
        border-radius: 8px !important;
        min-height: 48px !important;
        background: ${theme.ds.colors.white};
        /* padding: 0 ${padding}; */
      }
    }
    .MuiAccordionDetails-root {
      padding: 0 ${padding} 16px;
      background: ${theme.ds.colors.white};
      border-radius: 8px !important;
    }
  `}

  margin-top: 8px;
`

export const StyledAccordionSummary = styled(AccordionSummary)`
  .MuiAccordionSummary-content {
    display: flex;
    align-items: center;
    height: 30px;
    gap: 8px;

    &.Mui-expanded {
      margin: 0;
      min-height: none !important;
    }

    svg {
      width: 18px;
      height: 18px;
    }
  }
`

export const StyledAccordionDetails = styled(AccordionDetails)`
  display: flex;
  flex-direction: column;
  padding-top: 0;
`
