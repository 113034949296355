import React, { useRef, HTMLAttributes, useState } from 'react'
import ButtonNew, { ButtonProps } from '../ButtonNew'
import * as S from './styles'
import { DocumentType, DocumentsType } from 'domain/entities/document-types'
import { PatientDocument } from 'common/enum/patient-document'
import verifyValidMaxFileSizeInMb from 'common/utils/verify-max-file-size'
// import { DocumentsType } from 'domain/entities/document-types'
// import { PatientDocument } from 'common/enum/patient-document'

type Props = {
  name: string
  accept?: string
  multiple?: boolean
  onInput?: (files: any) => void
} & ButtonProps &
  HTMLAttributes<HTMLDivElement>

const UploadButtonNew = ({
  accept = 'image/jpeg,image/gif,image/png,image/bmp,image/tif,image/tiff,application/pdf,image/x-eps',
  multiple = false,
  children,
  onInput,
  ...props
}: Props) => {
  const inputRef = useRef<HTMLAnchorElement>(null)
  const [errorMessages, setErrorMessages] = useState<string[]>()
  const [inputKey, setInputKey] = useState(1) as any

  function resetFileInput() {
    const randomString = Math.random().toString(36)
    setInputKey(randomString)
  }

  const buttonClick = () => {
    inputRef.current?.click()
  }

  const verifyExtensions = (documentType: DocumentType, file: File) => {
    const extension = String(file.name.split('.').pop()).toLocaleLowerCase()
    return !documentType.extensionsAllowed.includes(extension)
  }

  const allVerifications = (documentType: DocumentType, file: File) => {
    const errors: string[] = []
    const hasMoreSize = !verifyValidMaxFileSizeInMb(
      file.size,
      documentType.maxFileSizeInMB
    )
    if (hasMoreSize && documentType.maxFileSizeInMB) {
      const hasMoreSizeText = `O tamanho máximo do arquivo é de ${documentType.maxFileSizeInMB} MB`
      errors.push(hasMoreSizeText)
    }
    const hasExtensionsNotAllowed = verifyExtensions(documentType, file)
    if (hasExtensionsNotAllowed) {
      const hasExtensionsNotAllowedText = `Apenas arquivos com as seguintes extensões são permitidos: ${documentType.extensionsAllowed?.join(
        ', '
      )}`
      errors.push(hasExtensionsNotAllowedText)
    }

    setErrorMessages(errors)
    return errors.length > 0
  }

  const documentsValidations = (files: any) => {
    if (!files || files?.length <= 0) return false
    const documentType = DocumentsType.getDocumentTypeByType(
      props.name as PatientDocument
    )
    const hasErrors = allVerifications(documentType!, files[0])
    return hasErrors
  }

  const onHandleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.stopPropagation()
    event.preventDefault()
    const files = event.target?.files
    const hasErrors = documentsValidations(files)
    if (hasErrors) return false
    onInput && onInput(files)
    resetFileInput()
  }

  return (
    <>
      <ButtonNew onClick={buttonClick} {...props}>
        {children}
      </ButtonNew>
      {/* <S.InputLabel htmlFor={`file-input-${inputRef}`}>{children}</S.InputLabel> */}
      <S.FileInput
        ref={inputRef}
        type="file"
        hidden
        key={inputKey}
        disabled={props.disabled}
        name={props.name}
        id={`file-input-${props.name}`}
        onInput={(event) => {
          onHandleChange(event as React.ChangeEvent<HTMLInputElement>)
        }}
        // onChange={onHandleChange}
        accept={accept}
        multiple={multiple}
      />
      {errorMessages && errorMessages?.length > 0 && (
        <S.Errors>
          {errorMessages?.map((error, index) => (
            <li key={index}>&#9888; {error}</li>
          ))}
        </S.Errors>
      )}
    </>
  )
}

export default UploadButtonNew
