import styled from 'styled-components'

export const Wrapper = styled.div`
  margin-top: 24px;
  padding-bottom: 32px;
`

export const CardInfo = styled.div`
  margin-bottom: 24px;
  .c-header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    > .c-title {
      color: ${({ theme }) => theme.ds.colors.neutral500};
      font-weight: ${({ theme }) => theme.ds.typography.weight.semiBold};
      font-size: ${({ theme }) => theme.ds.typography.size.small};
      line-height: ${({ theme }) => theme.ds.typography.lineHeight.medium};
    }

    > .btn-edit {
      svg {
        width: 24px;
        height: 24px;
      }
    }
  }

  .c-content {
    margin-top: 8px;
  }

  .item-wrapper {
    font-size: ${({ theme }) => theme.ds.typography.size.xxsmall};
    color: ${({ theme }) => theme.ds.colors.neutral500};
    font-weight: ${({ theme }) => theme.ds.typography.weight.semiBold};
  }

  .item-result {
    font-size: ${({ theme }) => theme.ds.typography.size.xsmall};
    color: ${({ theme }) => theme.ds.colors.neutral900};
    font-weight: ${({ theme }) => theme.ds.typography.weight.normal};
  }

  .calendar-container {
    display: flex;
    align-items: center;
    gap: 8px;

    svg {
      width: 16px;
    }
  }
`

export const BoxInfo = styled.div`
  padding: 8px 16px;
  border: 1px solid ${({ theme }) => theme.ds.colors.neutral200};
  background-color: ${({ theme }) => theme.ds.colors.primary25};
  border-radius: 8px;

  &:not(:first-of-type) {
    margin-top: 8px;
  }

  .label {
    font-size: ${({ theme }) => theme.ds.typography.size.xxsmall};
    color: ${({ theme }) => theme.ds.colors.neutral800};
    font-weight: ${({ theme }) => theme.ds.typography.weight.normal};
    line-height: 18px;
  }

  .text {
    margin-top: 4px;
    font-size: ${({ theme }) => theme.ds.typography.size.xsmall};
    color: ${({ theme }) => theme.ds.colors.primary600};
    font-weight: ${({ theme }) => theme.ds.typography.weight.semiBold};
    line-height: 21px;

    &.-small {
      font-size: ${({ theme }) => theme.ds.typography.size.xxsmall};
      color: ${({ theme }) => theme.ds.colors.neutral500};
      font-weight: ${({ theme }) => theme.ds.typography.weight.normal};
      line-height: 16px;
    }

    &.-large {
      font-size: ${({ theme }) => theme.ds.typography.size.small};
      font-weight: ${({ theme }) => theme.ds.typography.weight.bold};
      line-height: 24px;
      margin-bottom: 8px;
    }
  }

  .list {
    list-style: none;

    .item {
      display: flex;
      align-items: center;
      gap: 4px;
      margin: 8px 0 0;
      padding: 0;

      &:not(:first-of-type) {
        margin-top: 4px;
      }
    }
  }
`
export const BoxInfoWithScroll = styled.div`
  padding: 8px 16px;
  border: 1px solid ${({ theme }) => theme.ds.colors.neutral200};
  background-color: ${({ theme }) => theme.ds.colors.primary25};
  border-radius: 8px;
  overflow-x: auto;
  white-space: nowrap;
  &:not(:first-of-type) {
    margin-top: 8px;
  }

  .label {
    font-size: ${({ theme }) => theme.ds.typography.size.xxsmall};
    color: ${({ theme }) => theme.ds.colors.neutral800};
    font-weight: ${({ theme }) => theme.ds.typography.weight.normal};
    line-height: 18px;
  }

  .text {
    margin-top: 4px;
    font-size: ${({ theme }) => theme.ds.typography.size.xsmall};
    color: ${({ theme }) => theme.ds.colors.primary600};
    font-weight: ${({ theme }) => theme.ds.typography.weight.semiBold};
    line-height: 21px;

    &.-small {
      font-size: ${({ theme }) => theme.ds.typography.size.xxsmall};
      color: ${({ theme }) => theme.ds.colors.neutral500};
      font-weight: ${({ theme }) => theme.ds.typography.weight.normal};
      line-height: 16px;
    }

    &.-large {
      font-size: ${({ theme }) => theme.ds.typography.size.small};
      font-weight: ${({ theme }) => theme.ds.typography.weight.bold};
      line-height: 24px;
      margin-bottom: 8px;
    }
  }

  .list {
    list-style: none;

    .item {
      display: flex;
      align-items: center;
      gap: 4px;
      margin: 8px 0 0;
      padding: 0;

      &:not(:first-of-type) {
        margin-top: 4px;
      }
    }
  }
`

export const FlexInfos = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 8px;
`
export const FlexWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 70px;
  max-width: 1000px;
`
export const GridWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 8px;

  .item-title-grid {
    display: flex;
    justify-content: center;
    font-size: ${({ theme }) => theme.ds.typography.size.xxsmall};
    color: ${({ theme }) => theme.ds.colors.neutral800};
    font-weight: ${({ theme }) => theme.ds.typography.weight.normal};
  }
  .item-grid {
    display: flex;
    justify-content: center;
    font-size: ${({ theme }) => theme.ds.typography.size.xsmall};
    color: ${({ theme }) => theme.ds.colors.primary600};
    font-weight: ${({ theme }) => theme.ds.typography.weight.semiBold};
    line-height: 21px;
  }
`
