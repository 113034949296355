import styled from 'styled-components'
import HeadingNew from 'presentation/shared/components/HeadingNew'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  margin: auto 0;
`

export const ParagraphWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`

export const Paragraph = styled.p`
  font-size: ${({ theme }) => theme.ds.typography.size.medium};
  font-weight: ${({ theme }) => theme.ds.typography.weight.normal};
  line-height: ${({ theme }) => theme.ds.typography.lineHeight.medium};
  color: ${({ theme }) => theme.ds.colors.neutral800};

  b {
    font-weight: ${({ theme }) => theme.ds.typography.weight.semiBold};
  }
`

export const ImageWrapper = styled.div`
  display: flex;
  width: 100%;

  svg {
    margin: 0 auto;
    width: 100%;
    height: 100%;
    max-width: 240px;
    max-height: 240px;
  }
`

export const Heading = styled(HeadingNew)`
  @media (max-width: 400px) {
    font-size: 28px;
    line-height: 1em;
  }
`
