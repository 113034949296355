import { GetOneAmbulatoryOrder } from 'domain/usecases/ambulatory/get-one-ambulatory-order'
import { NewAmbulatoryRepository } from 'repository/interfaces/new-ambulatory-repository'

export class RemoteGetOneAmbulatoryOrder implements GetOneAmbulatoryOrder {
  constructor(private readonly ambulatoryRepository: NewAmbulatoryRepository) {}

  get(
    params: GetOneAmbulatoryOrder.Params
  ): Promise<GetOneAmbulatoryOrder.Model> {
    return this.ambulatoryRepository.getOneAmbulatoryOrder(params)
  }
}
