import React, { ReactNode, useEffect, useState } from 'react'
import { ReactComponent as CheckIcon } from 'presentation/assets/icons/check.svg'
import { ReactComponent as AlertIcon } from 'presentation/assets/icons/alert-icon.svg'
import { ReactComponent as ErrorIcon } from 'presentation/assets/icons/error.svg'
import { ReactComponent as FavoriteIcon } from 'presentation/assets/icons/star_verde.svg'

import * as S from './styles'
import { AnimatePresence } from 'framer-motion'
import Button from '../Button'
import { CSSProperties } from '@material-ui/core/styles/withStyles'
import { IntervalType } from 'presentation/utils/interval-type'

export type ModalProps = {
  action?: () => void
  hasInput?: (value: string) => void
  title?: string
  description?: string | React.ReactNode
  show?: boolean
  close?: () => void
  hasCloseButton?: string
  children?: ReactNode
  radius?: string
  noBackdrop?: boolean
  preventAutomateClose?: boolean
  type?: 'alert' | 'check' | 'error' | 'favorite'
  actionLabel?: string
  style?: CSSProperties
  button?: ReactNode
  preventPadding?: boolean
  bgZIndex?: number
  closeButtonProps?: {
    variant?: 'outlined' | 'white'
    style?: React.CSSProperties
  }

  subtitleProps?: S.SubtitleProps
  img?: string
  animate?: boolean
  closeTimeout?: number
}

export default function Modal({
  title,
  hasInput,
  description,
  show,
  close,
  children,
  radius,
  noBackdrop,
  hasCloseButton,
  preventAutomateClose,
  action,
  actionLabel,
  type,
  button,
  style,
  preventPadding,
  bgZIndex,
  closeButtonProps = {
    variant: 'white'
  },
  subtitleProps,
  img,
  animate = true,
  closeTimeout = 2000
}: ModalProps) {
  const [timer, setTimer] = useState<IntervalType | undefined>()
  const [favoriteValue, setFavoriteValue] = useState<string>()
  useEffect(() => {
    if (show && !preventAutomateClose) {
      const timeout = setTimeout(() => {
        !!close && close()
      }, closeTimeout)

      setTimer(timeout)
    }
  }, [show])

  const handleClick = (e: React.MouseEvent) => {
    e.stopPropagation()
    if (timer) {
      clearTimeout(timer)
      close?.()
    } else {
      close?.()
    }
  }

  const handleIcon = () => {
    if (img) return <img src={img} style={{ maxWidth: '100%' }} />
    switch (type) {
      case 'alert':
        return <AlertIcon style={{ maxWidth: '100%' }} />
      case 'check':
        return <CheckIcon style={{ maxWidth: '100%' }} />
      case 'error':
        return <ErrorIcon style={{ maxWidth: '100%' }} />
      case 'favorite':
        return <FavoriteIcon style={{ maxWidth: '100%' }} />
      default:
        return <CheckIcon style={{ maxWidth: '100%' }} />
    }
  }

  const handleWrapperClick = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    if (preventAutomateClose) {
      return e.stopPropagation()
    }
  }

  return (
    <AnimatePresence initial={animate}>
      {show && (
        <S.bg
          onClick={noBackdrop ? undefined : handleClick}
          style={{
            zIndex: bgZIndex,
            backgroundColor: noBackdrop ? 'transparent' : 'rgba(0, 0, 0, 0.6)'
          }}
        >
          <S.Wrapper
            preventPadding={preventPadding}
            initial={animate && { opacity: 0, scale: 0 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.5 }}
            exit={{ opacity: 0, scale: 0 }}
            key={title}
            onClick={handleWrapperClick}
            style={{ ...style, borderRadius: radius }}
          >
            {children ? (
              children
            ) : (
              <>
                {handleIcon()}
                <S.Title description={description}>{title}</S.Title>
                <S.Subtitle {...subtitleProps}>{description}</S.Subtitle>

                {button}

                {type == 'favorite' && (
                  <S.Input
                    maxLength={30}
                    placeholder="Nome do favorito"
                    onChange={(ev: React.ChangeEvent<HTMLInputElement>): void =>
                      setFavoriteValue(ev.target.value)
                    }
                  />
                )}

                {action && (
                  <S.Buttons>
                    <Button data-testid={'actionButton'} onClick={action}>
                      {actionLabel}
                    </Button>
                    {hasCloseButton && (
                      <Button
                        data-testid={'closeButton'}
                        onClick={close}
                        {...closeButtonProps}
                      >
                        {hasCloseButton}
                      </Button>
                    )}
                  </S.Buttons>
                )}

                {hasInput && (
                  <S.Buttons>
                    <Button
                      onClick={() => hasInput(favoriteValue!)}
                      disabled={!favoriteValue}
                    >
                      {actionLabel}
                    </Button>
                    {hasCloseButton && (
                      <Button
                        data-testid={'closeButton'}
                        onClick={close}
                        {...closeButtonProps}
                      >
                        {hasCloseButton}
                      </Button>
                    )}
                  </S.Buttons>
                )}
              </>
            )}
          </S.Wrapper>
        </S.bg>
      )}
    </AnimatePresence>
  )
}
