import { Patient } from 'domain/entities/patient-model'
import { TherapeuticPlanModel } from 'domain/entities/therapeutic-plan.model'
import { ReactComponent as CheckIcon } from 'presentation/assets/icons/big-check.svg'
import { ReactComponent as ArrowIcon } from 'presentation/assets/icons/rounded-arrow.svg'
import React from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import * as S from './styles'

export function TherapeuticPlanSteps() {
  const { state } = useLocation<{
    patient: Patient
    therapeuticPlan: TherapeuticPlanModel
    files?: File[]
  }>()
  const history = useHistory()

  return (
    <>
      <S.Wrapper>
        <S.Item>
          <label>Identificação do paciente</label>
          <div style={{ height: '28px ', width: '28px ' }}>
            <CheckIcon />
          </div>
        </S.Item>
        <hr />
        <S.Item
          onClick={() =>
            history.push('/novo-plano-terapeutico/diagnostico', {
              patient: state?.patient,
              therapeuticPlan: state?.therapeuticPlan
            })
          }
        >
          <label>Diagnóstico</label>
          <div style={{ height: '28px ', width: '28px ' }}>
            {state?.therapeuticPlan?.diagnosis ? <CheckIcon /> : <ArrowIcon />}
          </div>
        </S.Item>
        <hr />
        <S.Item
          onClick={() =>
            history.push('/novo-plano-terapeutico/protocolo-proposto', {
              patient: state?.patient,
              therapeuticPlan: state?.therapeuticPlan,
              files: state?.files
            })
          }
        >
          <label>Protocolo Proposto</label>
          <div style={{ height: 'auto', width: 'auto' }}>
            <ArrowIcon />
          </div>
        </S.Item>
        <hr />
        <S.Item
          onClick={() =>
            history.push('/novo-plano-terapeutico/exames-laudos', {
              patient: state?.patient,
              therapeuticPlan: state?.therapeuticPlan,
              files: state?.files
            })
          }
        >
          <label>Exames, laudos e relatórios</label>
          <div style={{ height: '28px', width: '28px' }}>
            {(state?.files?.length || 0) > 0 ? <CheckIcon /> : <ArrowIcon />}
          </div>
        </S.Item>
      </S.Wrapper>
    </>
  )
}
