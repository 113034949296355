import theme from 'presentation/styles/theme'
import React, { InputHTMLAttributes, useState } from 'react'
import * as S from './styles'

export type CheckboxProps = {
  label?: string
  labelFor?: string
  labelColor?: keyof typeof theme.ds.colors
  labelSize?: keyof typeof theme.ds.typography.size
  labelWeight?: keyof typeof theme.ds.typography.weight
  value?: string | ReadonlyArray<string> | number | boolean
  onCheck?: (status: boolean) => void
  isChecked?: boolean
  disabled?: boolean
} & InputHTMLAttributes<HTMLInputElement>

const CheckboxNew = ({
  onCheck,
  isChecked = false,
  label,
  labelFor = '',
  labelColor = 'neutral900',
  labelSize = 'small',
  labelWeight = 'normal',
  disabled = false,
  value,
  ...props
}: CheckboxProps) => {
  // controlled component (state)
  const [checked, setChecked] = useState(isChecked)

  const onChange = () => {
    const status = !checked // true => false => true
    setChecked(status)

    !!onCheck && onCheck(status)
  }

  return (
    <S.Wrapper>
      <S.Input
        id={labelFor}
        type="checkbox"
        onChange={props.onChange ? props.onChange : onChange}
        checked={checked}
        value={value}
        disabled={disabled}
        {...props}
      />
      {!!label && (
        <S.Label
          htmlFor={labelFor}
          labelColor={labelColor}
          labelSize={labelSize}
          labelWeight={labelWeight}
          disabled={disabled}
        >
          {label}
        </S.Label>
      )}
    </S.Wrapper>
  )
}

export default CheckboxNew
