import { OpenSurgicalOrderForm } from 'presentation/doctor/layouts/OpenSurgicalOrder'
import HeaderNew from 'presentation/shared/components/HeaderNew'
import React from 'react'

const OpenSurgicalOrderLayout = () => {
  return (
    <>
      <HeaderNew actualPageTitle="Pedido em aberto" />
      <OpenSurgicalOrderForm />
    </>
  )
}
export default OpenSurgicalOrderLayout
