import React from 'react'
import SupportTextNew from 'presentation/shared/components/SupportTextNew'
import DividerNew from 'presentation/shared/components/DividerNew'
import AccordionCardNew from 'presentation/shared/components/AccordionCardNew'
import { Patient } from 'domain/entities/patient-model'
import { formatPhone } from 'presentation/utils/masks'
import { formatPhoneWithParenthesis } from 'presentation/utils/mobile-actions/formart-phone'
import { formatCpf } from 'common/utils/format-cpf'
import { HealthInsurancePlans } from 'domain/usecases/health-insurance/load-health-insurance-plans'

type Props = {
  expanded?: boolean
  handleChange?: (accordionLabel: string, isExpanded: boolean) => void
  patient?: Patient
  healthInsurance?: HealthInsurancePlans
  icon?: React.ReactElement
}
export default function PatientAndInsuranceData({
  expanded,
  handleChange,
  patient,
  healthInsurance,
  icon
}: Props) {
  return (
    <AccordionCardNew
      accordionLabel="PatientAndInsuranceData"
      expanded={expanded}
      handleChange={handleChange}
      header="Dados do paciente e convênio"
      shadow="satin"
      padding="16px"
      gap="4px"
      background="neutral25"
      headerIcon={icon}
    >
      <SupportTextNew color="primary400" weight="bold">
        Dados do paciente
      </SupportTextNew>
      {patient?.patient_name && (
        <>
          <SupportTextNew
            color="neutral800"
            style={{
              fontSize: '14px'
            }}
          >
            Nome do paciente
          </SupportTextNew>
          <SupportTextNew style={{ fontSize: '14px' }}>
            {patient.patient_name}
          </SupportTextNew>
          <DividerNew marginBottom="8px" marginTop="8px" />
        </>
      )}
      {patient?.user?.cpf && (
        <>
          <SupportTextNew
            color="neutral800"
            style={{
              fontSize: '14px'
            }}
          >
            CPF do paciente
          </SupportTextNew>
          <SupportTextNew style={{ fontSize: '14px' }}>
            {formatCpf(patient.user.cpf)}
          </SupportTextNew>
          <DividerNew marginBottom="8px" marginTop="8px" />
        </>
      )}
      {patient?.user?.email && (
        <>
          <SupportTextNew color="neutral800" style={{ fontSize: '14px' }}>
            E-mail
          </SupportTextNew>
          <SupportTextNew style={{ fontSize: '14px' }}>
            {patient.user?.email}
          </SupportTextNew>
          <DividerNew marginBottom="8px" marginTop="8px" />
        </>
      )}
      {patient?.user?.phone && (
        <>
          <SupportTextNew color="neutral800" style={{ fontSize: '14px' }}>
            Celular
          </SupportTextNew>
          <SupportTextNew style={{ fontSize: '14px' }}>
            {formatPhoneWithParenthesis(formatPhone(patient.user.phone))}
          </SupportTextNew>
          <DividerNew marginBottom="8px" marginTop="8px" />
        </>
      )}

      <SupportTextNew color="primary400" weight="bold">
        Dados do convênio
      </SupportTextNew>
      <SupportTextNew color="neutral800" style={{ fontSize: '14px' }}>
        Convênio
      </SupportTextNew>
      <SupportTextNew style={{ fontSize: '14px' }}>
        {healthInsurance?.health_insurance_name || '-'}
      </SupportTextNew>
      {/* {healthInsurance?.health_insurance_code && (
        <>
          <DividerNew marginBottom="8px" marginTop="8px" />
          <SupportTextNew
            color="neutral800"
            style={{ fontSize: '14px' }}
          >
            Número da carteirinha
          </SupportTextNew>
          <SupportTextNew style={{ fontSize: '14px' }}>
            {healthInsurance.healthInsuranceCode}
          </SupportTextNew>
        </>
      )} */}
    </AccordionCardNew>
  )
}
