import { GetAvailableHours } from 'domain/usecases/surgical-scheduling/get-available-hours'
import { SurgicalSchedulingRepository } from 'repository/interfaces/surgical-scheduling-repository'

export class RemoteGetAvailableHours implements GetAvailableHours {
  constructor(
    private readonly surgicalSchedulingRepository: SurgicalSchedulingRepository
  ) {}

  getAvailableHours(
    params: GetAvailableHours.Params
  ): Promise<GetAvailableHours.Model> {
    return this.surgicalSchedulingRepository.getAvailableHours(params)
  }
}
