import React, { useContext, useEffect, useState } from 'react'
import { generateHash } from 'common/utils/generateHash'
import { renameFile } from 'common/utils/file/renameFile'
import { useServices } from 'presentation/hooks/use-services'
import { toast } from 'react-toastify'
import downloadFileFromBlob from 'common/utils/downloadFileFromBlob'
import { SurgicalOrderContext } from 'main/factories/pages/doctor/surgical-order/surgical-order-factory'
import { DocumentsList } from 'common/enum/documents-list'
import {
  WithLoading,
  WithLoadingProps
} from 'presentation/shared/components/HOCs/WithLoading'
import SurgicalOrderPendencyLayout from 'presentation/doctor/layouts/SurgicalOrderPendency'
import { useLocation, useHistory } from 'react-router'
import HeaderNew from 'presentation/shared/components/HeaderNew'
import { PendencyTypes } from 'presentation/shared/layouts/PendencyCard'

const SurgicalOrderPendency = ({ setIsLoading }: WithLoadingProps) => {
  const history = useHistory()
  const surgicalPendencyService = useServices().surgicalPendency
  const [documentSuccessModalIsOpen, setDocumentSuccessModalIsOpen] =
    useState(false)
  const {
    state: { currentPendency },
    dispatch
  } = useContext<any>(SurgicalOrderContext)
  const { state: location } = useLocation<{
    surgical_pendency_id: number
    pendency_order_id?: number
    has_new_document?: boolean
    pendency_type: PendencyTypes
  }>()

  const handlePendencyError = () => {
    toast.warning('Não foi possível carregar a pendência, tente novamente.')
    history.push('/pedidos-cirurgicos/pendencias')
  }

  const getPendencyHistory = async (
    surgical_pendency_id: number,
    pendency_type?: PendencyTypes
  ) => {
    setIsLoading(true)

    if (pendency_type === PendencyTypes.HELP_CARD) {
      try {
        const response: any =
          await surgicalPendencyService.loadHelpPendencyHistory(
            surgical_pendency_id
          )

        dispatch({
          type: 'CURRENT_SURGICAL_PENDENCY',
          payload: response.data
        })
      } catch {
        toast.error('Ocorreu um erro ao carregar a pendência')
      } finally {
        setIsLoading(false)
      }
    } else
      try {
        const response: any =
          await surgicalPendencyService.loadSurgicalOrderPendenciesHistory(
            surgical_pendency_id
          )

        dispatch({
          type: 'CURRENT_SURGICAL_PENDENCY',
          payload: response.data
        })
      } catch {
        toast.error('Ocorreu um erro ao carregar a pendência')
      } finally {
        setIsLoading(false)
      }
  }

  const uploadDocument = async (files: File[], type: string) => {
    if (!currentPendency.surgical_pendency_id) return handlePendencyError()
    setIsLoading(true)
    const filesList = [...files]

    await Promise.all(
      filesList.map(async (file) => {
        try {
          const form = new FormData()
          const newFile = renameFile(file, file.name)
          form.append('file', newFile)
          form.append('type', type)
          form.append('group_id', generateHash())
          form.append(
            'surgical_pendency_id',
            String(currentPendency.surgical_pendency_id)
          )

          await surgicalPendencyService.uploadSurgicalPendencyDocument({
            type: newFile.type,
            form: form,
            file: newFile
          })
        } catch {
          toast.error('Ocorreu um erro ao enviar o documento')
        }
      })
    )
      .then(() => getPendencyHistory(currentPendency.surgical_pendency_id))
      .finally(() => setIsLoading(false))
  }

  const deleteDocument = async (document_id: number, group_id: string) => {
    if (!currentPendency.surgical_pendency_id) return handlePendencyError()
    try {
      setIsLoading(true)
      await surgicalPendencyService.removeDocumentFromSurgicalPendency({
        surgical_pendency_id: currentPendency.surgical_pendency_id,
        document_id,
        group_id
      })
      await getPendencyHistory(currentPendency.surgical_pendency_id)
    } catch {
      toast.error('Ocorreu um erro ao excluir o documento')
    } finally {
      setIsLoading(false)
    }
  }

  const downloadDocument = async (
    document_id: number,
    group_id: string,
    type: keyof typeof DocumentsList
  ) => {
    if (!currentPendency.surgical_pendency_id) return handlePendencyError()
    try {
      setIsLoading(true)
      const document =
        await surgicalPendencyService.loadSurgicalPendencyDocument({
          surgical_pendency_id: currentPendency.surgical_pendency_id,
          document_id,
          group_id
        })

      if (document) {
        downloadFileFromBlob(
          document.data,
          document.contentType,
          DocumentsList[type]
        )
      }
    } catch {
      toast.error('Ocorreu um erro ao baixar o documento')
    } finally {
      setIsLoading(false)
    }
  }

  const goToOrderDetails = () => {
    history.push('/pedido-cirurgico/detalhes', {
      surgical_order_id: currentPendency.surgical_order_id
    })
  }

  const answerSurgicalPendency = async (values: { answer: string }) => {
    if (!currentPendency.surgical_pendency_id) return handlePendencyError()
    try {
      if (currentPendency.type === PendencyTypes.HELP_CARD) {
        setIsLoading(true)
        await surgicalPendencyService.createHelpPendencyMessage({
          surgical_pendency_id: currentPendency.surgical_pendency_id,
          message: values.answer
        })
      } else {
        setIsLoading(true)
        await surgicalPendencyService.answerSurgicalPendency({
          answer: values.answer,
          surgical_pendency_id: currentPendency.surgical_pendency_id,
          group_id: generateHash()
        })
      }

      goToOrderDetails()
    } catch {
      toast.error('Ocorreu um erro ao responder a pendencia')
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    getPendencyHistory(location.surgical_pendency_id, location.pendency_type)
    if (location.has_new_document) setDocumentSuccessModalIsOpen(true)
  }, [])

  return (
    <>
      <HeaderNew
        actualPageTitle="Pedido cirúrgico"
        actualPageOnClick={goToOrderDetails}
      />
      <SurgicalOrderPendencyLayout
        pendencyOrderId={location.pendency_order_id}
        uploadDocument={uploadDocument}
        deleteDocument={deleteDocument}
        downloadDocument={downloadDocument}
        answerSurgicalPendency={answerSurgicalPendency}
        documentSuccessModalIsOpen={documentSuccessModalIsOpen}
        setDocumentSuccessModalIsOpen={setDocumentSuccessModalIsOpen}
      />
    </>
  )
}

export default WithLoading(SurgicalOrderPendency)
