import styled from 'styled-components'

export const Wrapper = styled.div`
  margin-bottom: 24px;

  h1 {
    justify-self: flex-start;
    align-self: flex-end;

    color: ${({ theme }) => theme.colors.primary};
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 8px;
  }
  span {
    justify-self: flex-start;
    align-self: flex-end;

    color: ${({ theme }) => theme.ds.colors.neutral700};
    font-size: 16px;
    font-weight: normal;
  }
  p {
    justify-self: flex-start;
    align-self: flex-end;
    margin: 24px 0;
    color: ${({ theme }) => theme.ds.colors.neutral700};
    font-size: 16px;
    font-weight: normal;
  }
`
export const DocumentsWrapper = styled.div`
  margin-bottom: 16px;
`
