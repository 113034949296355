import { AmbulatoryContact } from 'domain/usecases/ambulatory/ambulatory-contact'
import { GetAmbulatoryCounters } from 'domain/usecases/ambulatory/get-ambulatory-counters'
import { GetAmbulatoryOrders } from 'domain/usecases/ambulatory/get-ambulatory-orders'
import { GetAmbulatorySchedulePanelOrganized } from 'domain/usecases/ambulatory/get-ambulatory-panel-organized'
import { GetOneAmbulatoryOrder } from 'domain/usecases/ambulatory/get-one-ambulatory-order'
import { LoadAmbulatoryOrderDocument } from 'domain/usecases/ambulatory/load-ambulatory-order-document'
import { SearchAmbulatoryOrders } from 'domain/usecases/ambulatory/search-ambulatory-order'
import { SyncAmbulatoryToken } from 'domain/usecases/ambulatory/sync-ambulatory-token'
import { makeAutoObservable } from 'mobx'

type Services = {
  ambulatoryContact: AmbulatoryContact
  syncAmbulatoryToken: SyncAmbulatoryToken
  getAmbulatorySchedulePanelOrganized: GetAmbulatorySchedulePanelOrganized
  getAmbulatoryCounters: GetAmbulatoryCounters
  getAmbulatoryOrders: GetAmbulatoryOrders
  searchAmbulatoryOrders: SearchAmbulatoryOrders
  getOneAmbulatoryOrder: GetOneAmbulatoryOrder
  loadAmbulatoryOrderDocument: LoadAmbulatoryOrderDocument
}

export class AmbulatoryService {
  constructor(private readonly services: Services) {
    makeAutoObservable(this)
  }

  async ambulatoryContact(
    params: AmbulatoryContact.Params,
    patient_id: number,
    ambulatory_schedule_id: number
  ): Promise<AmbulatoryContact.Model> {
    return this.services.ambulatoryContact.contact(
      params,
      patient_id,
      ambulatory_schedule_id
    )
  }

  async syncAmbulatoryToken(
    params: SyncAmbulatoryToken.Params
  ): Promise<SyncAmbulatoryToken.Model> {
    return this.services.syncAmbulatoryToken.sync(params)
  }

  async getAmbulatorySchedulePanelOrganized(
    params: GetAmbulatorySchedulePanelOrganized.Params
  ): Promise<GetAmbulatorySchedulePanelOrganized.Model> {
    return this.services.getAmbulatorySchedulePanelOrganized.get(params)
  }

  async getAmbulatoryCounters(
    doctor_id: number
  ): Promise<GetAmbulatoryCounters.Model> {
    return this.services.getAmbulatoryCounters.get(doctor_id)
  }

  async getAmbulatoryOrders(
    params: GetAmbulatoryOrders.Params
  ): Promise<GetAmbulatoryOrders.Model> {
    return this.services.getAmbulatoryOrders.get(params)
  }

  async getOneAmbulatoryOrder(
    params: GetOneAmbulatoryOrder.Params
  ): Promise<GetOneAmbulatoryOrder.Model> {
    return this.services.getOneAmbulatoryOrder.get(params)
  }

  async searchAmbulatoryOrders(
    params: SearchAmbulatoryOrders.Params
  ): Promise<SearchAmbulatoryOrders.Model> {
    return this.services.searchAmbulatoryOrders.search(params)
  }

  async loadAmbulatoryOrderDocument(
    params: LoadAmbulatoryOrderDocument.Params
  ): Promise<LoadAmbulatoryOrderDocument.Model> {
    return this.services.loadAmbulatoryOrderDocument.load(params)
  }
}

export default AmbulatoryService
