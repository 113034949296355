import { LoadDoctorDaysAvailable } from 'domain/usecases/doctor/load-doctor-days-available'
import { DoctorRepository } from 'repository/interfaces/doctor-repository'

export class RemoteLoadDoctorDaysAvailable implements LoadDoctorDaysAvailable {
  constructor(private readonly doctorRepository: DoctorRepository) {}

  load(
    params?: LoadDoctorDaysAvailable.Params
  ): Promise<LoadDoctorDaysAvailable.Model> {
    return this.doctorRepository.loadDoctorDaysAvailable(params)
  }
}
