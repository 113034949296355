import styled from 'styled-components'
import theme from 'presentation/styles/theme'
import SupportTextNew from '../SupportTextNew'
import { darken } from 'polished'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`

export const AvatarContainer = styled.div`
  margin-top: 16px;
  gap: 8px;
  display: flex;
  justify-content: space-around;
  div {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 4px;
  }
`

export const AvatarSupportText = styled(SupportTextNew)`
  color: ${({ color = 'white' }) => darken(0.1, theme.ds.colors[color])};
  font-weight: 600;
  max-width: 140px;
  text-align: center;
`
