import React from 'react'
import AddSecretaryForm from 'presentation/doctor/components/Forms/AddProfessional/AddSecretary'
import { AddSecretary } from 'domain/usecases/secretary/add-secretary'
import HeaderNew from 'presentation/shared/components/HeaderNew'

type AddSecretaryLayoutProps = {
  addSecretary: AddSecretary
}

export default function AddSecretaryLayout({
  addSecretary
}: AddSecretaryLayoutProps) {
  return (
    <>
      <HeaderNew actualPageTitle="Nova secretária" />
      <AddSecretaryForm addSecretary={addSecretary} />
    </>
  )
}
