import React, { useState } from 'react'
import SupportTextNew from 'presentation/shared/components/SupportTextNew'
import * as S from './styles'

type InfoCardProps = {
  mainText: string
  linkText?: string
  onLinkClick?: () => void
}

const InfoCard = ({ mainText, linkText, onLinkClick }: InfoCardProps) => {
  const [showInfo, setShowInfo] = useState(true)

  const closeInfoCard = () => {
    setShowInfo(false)
  }

  return (
    <>
      {showInfo && (
        <S.Card border="info300">
          <S.IconsWrapper>
            <S.InfoIcon />
            <S.CloseIcon onClick={closeInfoCard} />
          </S.IconsWrapper>
          <SupportTextNew color="neutral900">{mainText}</SupportTextNew>
          {linkText && onLinkClick && (
            <S.LinkText
              color="neutral800"
              weight="bold"
              textAligh="right"
              onClick={onLinkClick}
            >
              {linkText}
            </S.LinkText>
          )}
        </S.Card>
      )}
    </>
  )
}

export default InfoCard
