import React from 'react'
import * as S from './styles'
import { Container } from 'presentation/shared/components/Container'
import Heading from 'presentation/shared/components/Heading'
import { Switch, Route } from 'react-router'
import { NavLink } from 'react-router-dom'
import SurgeryDocumentList from 'presentation/shared/components/SurgeryDocumentList'
import OrderDetails from 'presentation/shared/components/OrderDetails'
import { ListItem } from 'presentation/shared/components/List'
import { TimelineStatusItem } from 'presentation/shared/components/Timeline'
import SupportText from 'presentation/shared/components/SupportText'
import { MedicalTeam } from 'domain/entities/surgery-model'
import BottomMenu from 'presentation/shared/components/BottomMenu'
import { Accompanying } from 'domain/entities/accompanying-model'
import SurgicalOrderTimeline from 'presentation/shared/components/Timeline/surgical-order-timeline'
import { Procedure } from 'presentation/doctor/pages/Patient'
import HeaderNew from 'presentation/shared/components/HeaderNew'
import moment from 'moment'

export type SurgeryDetailsLayoutProps = {
  date?: string
  expectedDate?: string
  title?: string
  hospital?: string
  surgery_id?: number
  documents?: Array<ListItem & { document_id: number; type: string }>
  statusList?: TimelineStatusItem[]
  medicalTeam?: MedicalTeam[]
  mainAccompanying?: Accompanying
  isSolicitation?: boolean
  isUrgent?: boolean
  passwordToFinish?: string
  passwordMaxDateValidation?: string
  passwordMaxStringValidation?: string
  procedureDetails?: string
  observation?: string
  opmeDetails?: string
  opme?: {
    solicitations: {
      description: string
      quantity: number
      observation: string
    }[]
    providers: string[]
  }
  procedures?: Procedure[]
  updatedAt?: string | Date
  guidesTypeProcedures?: any
  guidesTypeOpme?: any
  concludedByName?: string
  concludedByRole?: string
  concludedAt?: string | Date
}

export default function SurgeryDetailsLayout({
  documents,
  statusList,
  date,
  hospital,
  title,
  expectedDate,
  surgery_id,
  isSolicitation,
  isUrgent,
  passwordToFinish,
  passwordMaxDateValidation,
  passwordMaxStringValidation,
  procedures,
  procedureDetails,
  observation,
  opmeDetails,
  opme,
  // updatedAt,
  guidesTypeProcedures,
  guidesTypeOpme,
  concludedByName,
  concludedByRole,
  concludedAt
}: SurgeryDetailsLayoutProps) {
  const isOrderAuthorized = statusList?.some(
    (item) => item.status === 'AUTHORIZED'
  )

  return (
    <>
      <HeaderNew actualPageTitle="Cirurgia" />
      <Container noPadding>
        <S.Header>
          <S.HeadInfo>
            <Heading color="primary" as="h1" size="large">
              {title}
            </Heading>
            <S.LocaleInfoBox>
              <S.LocaleInfo>
                <label>
                  <strong>Nº Aviso: </strong>
                  <span className="locale-info-gray">
                    {surgery_id && !isSolicitation ? surgery_id : ''}
                  </span>
                </label>
              </S.LocaleInfo>
              <S.LocaleInfo>
                {date && (
                  <>
                    <strong>Data Prevista: </strong>
                    <span className="locale-info-gray">{date}</span>
                  </>
                )}
                {!date && (
                  <>
                    <strong>
                      {isUrgent ? 'Data de Urgência:' : 'Data Prevista:'}{' '}
                    </strong>
                    <span className="locale-info-gray">
                      {expectedDate ? expectedDate : 'Aguardando data'}
                    </span>
                  </>
                )}
              </S.LocaleInfo>
              <S.LocaleInfo>
                <strong>Unidade: </strong>
                <span className="locale-info-gray">{hospital}</span>
              </S.LocaleInfo>
            </S.LocaleInfoBox>
          </S.HeadInfo>

          {isOrderAuthorized &&
            concludedByName &&
            concludedByRole &&
            concludedAt && (
              <S.AuthorizationInfo>
                <h1 className="authorization-title">Pedido autorizado</h1>
                <div className="authorized-by">
                  {`Finalizado por ${concludedByName}`}{' '}
                  <span className="bullet-point">&#8226;</span>{' '}
                  {concludedByRole}
                </div>
                <p className="authorization-date">
                  {moment.utc(concludedAt).format('ll [às] HH[h]mm')}
                </p>
              </S.AuthorizationInfo>
            )}

          <S.InfoList>
            <NavLink
              exact
              to={{ pathname: '/cirurgia', state: { id: surgery_id } }}
              activeClassName="selected"
            >
              Informações
            </NavLink>
            <NavLink
              exact
              to={{ pathname: '/cirurgia/status', state: { id: surgery_id } }}
              activeClassName="selected"
            >
              Status
            </NavLink>
            <NavLink
              exact
              to={{
                pathname: '/cirurgia/documentos',
                state: { id: surgery_id }
              }}
              activeClassName="selected"
            >
              Documentos
            </NavLink>
          </S.InfoList>
        </S.Header>
        <S.Content>
          <Switch>
            <Route
              exact
              path={`/cirurgia`}
              render={() => {
                return (
                  <OrderDetails
                    passwordToFinish={passwordToFinish}
                    passwordMaxDateValidation={passwordMaxDateValidation}
                    passwordMaxStringValidation={passwordMaxStringValidation}
                    procedures={procedures}
                    procedureDetails={procedureDetails}
                    observation={observation}
                    opmeDetails={opmeDetails}
                    opme={opme}
                    statusList={statusList}
                    guidesTypeProcedures={guidesTypeProcedures}
                    guidesTypeOpme={guidesTypeOpme}
                    concludedAt={concludedAt}
                  />
                )
              }}
            />
            <Route
              exact
              path={`/cirurgia/status`}
              render={(props) => (
                <S.StatusContainer>
                  <SupportText color="lightGray">
                    Acompanhe o andamento de liberação e marcação do seu
                    procedimento.
                  </SupportText>
                  <Heading as="h2">Status da cirurgia</Heading>
                  <SurgicalOrderTimeline items={statusList} {...props} />
                </S.StatusContainer>
              )}
            />
            <Route
              exact
              path={`/cirurgia/documentos`}
              render={(props) => (
                <SurgeryDocumentList {...props} documents={documents ?? []} />
              )}
            />
          </Switch>
        </S.Content>
      </Container>
      <BottomMenu />
    </>
  )
}
