import SelectField from 'presentation/shared/components/SelectField'
import TextField from 'presentation/shared/components/TextField'
import React, { useState } from 'react'
import { creatinineClearanceOptions } from 'presentation/utils/creatinine-clearance'
import * as S from './styles'

export default function ProposedProtocolStepOne() {
  const [weight, setWeight] = useState<number>()
  const [height, setHeight] = useState<number>()
  const bodySurface = Math.sqrt(
    ((height || 0) * (weight || 0)) / 3600
  )?.toFixed(4)

  return (
    <>
      <S.FormContentStepOne>
        <S.InputWithLabel>
          <TextField
            label="Peso (em Kg)"
            name="weight"
            spellCheck="false"
            type="number"
            icon={
              <S.BodyInfoLabel onClick={(e) => e.stopPropagation()}>
                Kg
              </S.BodyInfoLabel>
            }
            iconLocale="inside"
            iconPosition="right"
            iconMargin="65px"
            value={weight || ''}
            onChange={(e) => setWeight(+e.target.value)}
            required
          />
        </S.InputWithLabel>
        <S.InputWithLabel>
          <TextField
            label="Altura (em cm)"
            name="height"
            spellCheck="false"
            type="number"
            icon={
              <S.BodyInfoLabel onClick={(e) => e.stopPropagation()}>
                Cm
              </S.BodyInfoLabel>
            }
            iconLocale="inside"
            iconPosition="right"
            iconMargin="65px"
            value={height || ''}
            onChange={(e) => setHeight(+e.target.value)}
            required
          />
        </S.InputWithLabel>
        <S.InputWithLabel>
          <TextField
            label="Sup. Corpórea (m²)"
            name="bodySurface"
            spellCheck="false"
            type="number"
            icon={
              <S.BodyInfoLabel onClick={(e) => e.stopPropagation()}>
                m²
              </S.BodyInfoLabel>
            }
            value={bodySurface || ''}
            iconLocale="inside"
            iconPosition="right"
            iconMargin="65px"
            required
            disabled
          />
        </S.InputWithLabel>
        <div />
        <SelectField
          label="Ecog"
          required
          name="ecog"
          items={ecogOptions}
          placeholder={''}
        />
        <SelectField
          label="KPS"
          required
          name="kps"
          items={kpsOptions}
          placeholder={''}
        />
        <SelectField
          label="Creatinina ou clearance"
          required
          name="creatinineClearance.creatinineClearance"
          items={creatinineClearanceOptions}
          placeholder={''}
        />
        <TextField
          label="Valor"
          name="creatinineClearance.value"
          spellCheck="false"
          type="number"
          required
        />
      </S.FormContentStepOne>
    </>
  )
}

const ecogOptions = [...Array(6)].map((option, index) => ({
  label: `0${index}`,
  value: index
}))

const kpsOptions = [...Array(11)].map((option, index) => ({
  label: `${index * 10}`,
  value: index
}))
