import TherapeuticPlanProposedProtocolForm from 'presentation/doctor/components/Forms/AddTherapeuticPlan/NewTherapeuticPlan/TherapeuticPlanProposedProtocol'
import { Container } from 'presentation/shared/components/Container'
import Header from 'presentation/shared/components/Header'
import React from 'react'

export default function TherapeuticPlanProposedProtocol() {
  return (
    <>
      <Header />
      <Container>
        <TherapeuticPlanProposedProtocolForm />
      </Container>
    </>
  )
}
