import { observer } from 'mobx-react'
import { useStores } from 'presentation/hooks/use-stores'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { useHistory } from 'react-router'
import CloseIcon from '@material-ui/icons/Close'

import { ReactComponent as BellIcon } from 'presentation/assets/icons/bell-icon.svg'
import { ReactComponent as NoNotificationsIcon } from 'presentation/assets/icons/no-notifications.svg'
import notificationStore from 'presentation/contexts/notifications/notification-context'
import doctorPendencyStore from 'presentation/contexts/doctor-pendency/doctor-pendency-context'
import { useServices } from 'presentation/hooks/use-services'

import {
  PendencyType,
  SurgicalPendency,
  SurgicalPendencyStatus
} from 'domain/entities/surgical-pendency'
import { ReactComponent as ArrowLeft } from 'presentation/assets/icons/arrow-left.svg'
import { Hospital } from 'domain/entities/hospital-model'
import { Notification } from 'domain/entities/notification-model'
import translateRole from 'common/utils/getTranslatedRole'
import { getCrmoTableStatus } from 'presentation/hospital/pages/Authorization/Functions/getCrmoTableStatus'
import { Profile } from 'common/enum/profile'
import { NotificationType } from 'common/enum/notification-type'
import * as S from './styles'

export const Notifications = observer(({ currentHospitalId }: any) => {
  const service = useServices().surgicalOrder
  const doctorService = useServices().doctor
  const patientService = useServices().patient
  const secretaryService = useServices().secretary
  const notificationService = useServices().notification
  const user = useStores().currentAccount.getCurrentAccount().user
  const [notifications, setNotifications] = useState<Notification[]>([])
  const [pendencies, setPendencies] = useState<any>([])
  const [boxIsOpen, setBoxIsOpen] = useState(false)
  const [refreshNotifications, setRefreshNotifications] = useState(false)
  const history = useHistory()

  const toggleBoxNotifications = () => {
    setBoxIsOpen(!boxIsOpen)
  }

  const toggleRefreshNotifications = () => {
    setRefreshNotifications(!refreshNotifications)
  }

  const loadPendencies = async () => {
    if (
      user.groups?.includes(Profile.SECRETARY) ||
      user.role === Profile.SECRETARY
    ) {
      const response = await secretaryService.loadSecretaryPendencies(
        findSecretaryParams
      )
      setPendencies(response?.data)
    }

    if (user.groups?.includes(Profile.DOCTOR) || user.role === Profile.DOCTOR) {
      const response = await doctorService.loadDoctorPendencies(findParams)
      setPendencies(response?.pendencies?.data)
    }

    if (
      user.groups?.includes(Profile.PATIENT) ||
      user.role === Profile.PATIENT
    ) {
      const response = await patientService.loadPatientPendencies(findParams)
      setPendencies(response?.pendencies?.data)
    }
  }

  const currentHospital = useStores().currentHospital

  const loadNotifications = async () => {
    if (
      user.groups?.includes(Profile.SECRETARY) ||
      user.role === Profile.SECRETARY ||
      user.groups.includes(Profile.CRMO) ||
      user.role === Profile.CRMO
    ) {
      const notifications = await notificationService.loadNotifications({
        user_id: user.user_id,
        hospital_id: currentHospital.getActualHospital()?.hospital_id
      })

      setNotifications(
        notifications?.filter((notification) => notification.active === true)
      )
    } else {
      const notifications = await notificationService.loadNotifications({
        user_id: user.user_id
      })

      setNotifications(
        notifications?.filter((notification) => notification.active === true)
      )
    }
  }

  const findParams = {
    params: [
      `
        data {
          surgical_pendency_id,
          surgical_order_id,
          answer,
          answeredAt,
          requestedBy {name},
          createdAt,
          documents {
            document_id,
            type,
            name
          },
          status,
          observation,
          surgicalOrder {
            patientName,
            procedure {description},
            createdBy {name}
          },
          type
        },
        pageInfo {
          currentPage,
          itemsPerPage,
          totalItems,
          totalPages
        }
      `
    ],
    pagination: {
      pageNumber: 1,
      pageSize: 5
    },
    query: {
      status: SurgicalPendencyStatus.PENDENT
    }
  }

  const findSecretaryParams = {
    fields: [
      `
        data {
          surgical_pendency_id,
          surgical_order_id,
          answer,
          answeredAt,
          requestedBy {name},
          createdAt,
          documents {
            document_id,
            type,
            name
          },
          status,
          observation,
          surgicalOrder {
            patientName,
            procedure {description},
            createdBy {name}
          },
          type
        },
        pageInfo {
          currentPage,
          itemsPerPage,
          totalItems,
          totalPages
        }
      `
    ],
    pagination: {
      pageNumber: 1,
      pageSize: 5
    },
    query: {
      status: SurgicalPendencyStatus.PENDENT
    }
  }

  const showPendencyError = () =>
    toast.warn('Não foi possível visualizar os detalhes da pendência', {
      autoClose: 1500
    })

  const showStatusError = () =>
    toast.warn('Não foi possível visualizar os detalhes do pedido', {
      autoClose: 1500
    })

  const readNotifications = async (
    notification_id: number,
    notification_is_read: boolean
  ) => {
    if (!notification_id || notification_is_read) return
    await notificationService.readNotifications({
      notification_id
    })
  }

  const showStatus = (url: string, notification: Notification) => {
    if (!url || !notification?.attributes?.surgical_order_id)
      return showStatusError()

    readNotifications(notification.notification_id, notification.is_read)
    history.push(url, { id: notification.attributes.surgical_order_id })
    toggleRefreshNotifications()
    setBoxIsOpen(false)
  }

  const showPendencyCRMO = async (notification: Notification) => {
    if (!notification.attributes.surgical_order_id) return showPendencyError()

    try {
      notificationStore.setShowLoadingPendency(true)
      notificationStore.setHospitalNotification(notification)

      const responseSurgicalOrder = (await service.loadSurgicalOrder(
        notification.attributes.surgical_order_id,
        [
          `
              surgical_order_id,
              procedure {description},
              hospital {name, hospital_id},
              patient { name, gender, birthday, patient_id, email, phone},
              healthInsurance {healthInsuranceName},
              doctor {name, doctor_id, email, phone},
              status {status},
              createdAt,
              isSolicitation,
              surgicalCenter,
              patientHospitalized,
              technique,
              createdBy {name, role},
              surgicalCenter,
              hospitalizationType,
              expectedDate,
              pendencies {
                documents{
                  base64,
                  document_id,
                  type,
                  name,
                  group_id
                },
                status,
                answeredAt,
                observation,
                answer,
                surgical_pendency_id,
                createdAt,
                answeredAt,
                requestedBy {name}
              }
            `
        ]
      )) as any

      const formatSurgicalOrder = (surgicalOrder: any) => {
        return {
          surgery_request_id: surgicalOrder.surgical_order_id?.toString(),
          patient: surgicalOrder.patient?.name,
          surgery:
            surgicalOrder?.procedure && surgicalOrder?.procedure?.length > 0
              ? surgicalOrder?.procedure[0].description
              : '',
          doctor: surgicalOrder.doctor?.name,
          insurance: surgicalOrder.healthInsurance?.healthInsuranceName,
          gender: surgicalOrder.patient?.gender,
          unit: new Hospital(
            surgicalOrder.hospital?.hospital_id ?? 0,
            surgicalOrder.hospital?.name ?? '',
            surgicalOrder.hospital?.name ?? ''
          ).name,
          status:
            surgicalOrder?.status && getCrmoTableStatus(surgicalOrder?.status),
          createdAt: moment(surgicalOrder?.createdAt)
            .utc(false)
            .format('DD/MM/YYYY HH:mm'),
          patientHospitalized: surgicalOrder.patientHospitalized && 'Sim',
          createdBy:
            surgicalOrder.createdBy?.name +
            ' * ' +
            translateRole(surgicalOrder.createdBy?.role),
          technique: getTechniqueLabel(surgicalOrder.technique),
          surgicalCenter: surgicalOrder.surgicalCenter,
          pendencies: surgicalOrder.pendencies,
          doctor_id: surgicalOrder.doctor?.doctor_id,
          patient_id: surgicalOrder.patient?.patient_id,
          isSolicitation: surgicalOrder.isSolicitation,
          priorities:
            surgicalOrder.surgicalCenter +
            '---' +
            getTechniqueLabel(surgicalOrder.technique) +
            '---' +
            surgicalOrder.patientHospitalized,
          doctorEmail: surgicalOrder.doctor?.email,
          doctorPhone: surgicalOrder.doctor?.phone,
          patientEmail: surgicalOrder.patient?.email,
          patientPhone: surgicalOrder.patient?.phone,
          isUrgent: {
            urgent: surgicalOrder.hospitalizationType === 'urgency',
            expectedDate: surgicalOrder.expectedDate
          }
        }
      }

      const getTechniqueLabel = (technique: string | undefined) => {
        if (technique === 'robotic') return 'Robótica'
        if (technique === 'conventional') return 'Convencional'
        if (technique === 'makoRobot') return 'Robô Mako'
        else return 'Vídeo'
      }

      const formatedSurgicalOrder = formatSurgicalOrder(responseSurgicalOrder)

      notificationStore.setSurgicalOrder(formatedSurgicalOrder)
      notificationStore.setCurrentSurgicalPendencyId(
        notification.attributes.pendency_id
      )

      readNotifications(notification.notification_id, notification.is_read)

      setTimeout(() => {
        history.push('/pedidos/pendencias', {
          surgeryStatus: undefined,
          state: undefined,
          pendencyModalOpen: true
        })
      }, 1)

      notificationStore.openPendencyModal()
    } catch (err: any) {
      return showPendencyError()
    } finally {
      notificationStore.setShowLoadingPendency(false)
      setBoxIsOpen(false)
      toggleRefreshNotifications()
    }
  }

  const showPendency = (url: string, notification: Notification) => {
    if (
      !url ||
      !pendencies.length ||
      !notification?.attributes?.surgical_order_id
    )
      return showPendencyError()

    const pendency = pendencies.find(
      (pendency: SurgicalPendency) =>
        notification.attributes.surgical_order_id === pendency.surgical_order_id
    )

    if (!pendency) return showPendencyError()

    readNotifications(notification.notification_id, notification.is_read)

    doctorPendencyStore.setDoctorPendency(pendency)
    doctorPendencyStore.setDoctorNotification(notification)

    if (
      user.groups?.includes(Profile.SECRETARY) ||
      user.role === Profile.SECRETARY
    ) {
      const path =
        pendency.type === PendencyType.AUTHORIZATION
          ? 'autorizacao'
          : 'assistencial'

      history.push(`${url}/${path}`, { pendency })
      toggleRefreshNotifications()
      setBoxIsOpen(false)
      return
    }
    history.push(url, { pendency })
    toggleRefreshNotifications()
    setBoxIsOpen(false)
  }

  const handleClickNotification = (notification: Notification) => {
    switch (notification.type) {
      case NotificationType.CRMO_UPDATE_PENDENCY:
        showPendencyCRMO(notification)
        break

      case NotificationType.SECRETARY_CREATE_PENDENCY:
        showPendency('/pendencias', notification)
        break

      case NotificationType.DOCTOR_CREATE_PENDENCY:
        showPendency('/pendencia', notification)
        break

      case NotificationType.PATIENT_CREATE_PENDENCY:
        showPendency('/pendencia', notification)
        break

      case NotificationType.UPDATE_SECRETARY_SURGICAL_ORDER_STATUS:
        showStatus('/pedido/detalhes', notification)
        break

      case NotificationType.UPDATE_DOCTOR_SURGICAL_ORDER_STATUS:
        user.groups?.includes(Profile.SECRETARY) ||
        user.role === Profile.SECRETARY
          ? showStatus('/pedido/detalhes', notification)
          : showStatus('/cirurgia/status', notification)
        break

      case NotificationType.CREATE_PATIENT_NEW_SURGICAL_ORDER:
        showStatus('/cirurgia/status', notification)
        break

      case NotificationType.UPDATE_PATIENT_SURGICAL_ORDER_STATUS:
        showStatus('/cirurgia/status', notification)
        break
    }
  }

  const unreadNotifications = () =>
    notifications?.filter((notification) => !notification.is_read)

  useEffect(() => {
    loadPendencies()
    loadNotifications()
  }, [currentHospitalId, refreshNotifications])

  return (
    <>
      <S.NotificationsBell onClick={toggleBoxNotifications}>
        <BellIcon />
        {unreadNotifications()?.length > 0 && (
          <div className="notifications-amount">
            {unreadNotifications()?.length}
          </div>
        )}
      </S.NotificationsBell>

      <S.BoxNotifications className={boxIsOpen ? '-open' : ''}>
        <S.BoxHeader>
          <button
            className="btn -back"
            type="button"
            onClick={() => setBoxIsOpen(false)}
          >
            <ArrowLeft className="icon" />
          </button>
          <h4>Notificações</h4>
          <button
            className="btn -close"
            type="button"
            onClick={() => setBoxIsOpen(false)}
          >
            <CloseIcon
              className="icon"
              color={'disabled'}
              onClick={() => setBoxIsOpen(false)}
            />
          </button>
        </S.BoxHeader>

        <S.BoxContent>
          {notifications?.length ? (
            notifications.map((notification: Notification, index) => (
              <S.CardNotification
                key={index}
                className={notification.is_read ? '-is-read' : ''}
              >
                <strong>{notification.title || 'title'}</strong>
                <p>{notification.content}</p>
                <button
                  type="button"
                  onClick={() => handleClickNotification(notification)}
                >
                  {notification.button || 'button_text'}
                </button>
              </S.CardNotification>
            ))
          ) : (
            <S.NoNotificationsContainer>
              <NoNotificationsIcon />
              <span>Não há notificações para serem exibidas.</span>
            </S.NoNotificationsContainer>
          )}
        </S.BoxContent>
      </S.BoxNotifications>
    </>
  )
})
