import React from 'react'

import AddPatientForm from 'presentation/doctor/components/Forms/AddPatient'
import HeaderNew from 'presentation/shared/components/HeaderNew'

const AddPatientNewLayout = () => {
  return (
    <>
      <HeaderNew actualPageTitle="Adicionar novo paciente" />
      <AddPatientForm />
    </>
  )
}

export default AddPatientNewLayout
