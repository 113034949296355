import styled from 'styled-components'
import Popover from '@material-ui/core/Popover'

export const PopoverCard = styled(Popover)`
  .MuiPaper-root {
    width: calc(100% - 46px);
    padding: 16px;
    border-radius: 8px;
    margin-top: 4px;
    max-width: 600px;
    min-width: 250px;
    box-shadow: 0px 12px 18px rgba(0, 0, 0, 0.1);
  }
`
