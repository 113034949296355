import { observer } from 'mobx-react'
import { useStores } from 'presentation/hooks/use-stores'
import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router'
import HamburguerIcon from '../Icons/HamburguerIcon'
import Logo from '../Logo'
import SideMenu from '../SideMenu'
import * as S from './styles'
import { Notifications } from 'presentation/shared/components/Notifications'
import { Profile } from 'common/enum/profile'

export const Header = observer(() => {
  const menuStore = useStores().menu
  const history = useHistory()
  const user = useStores().currentAccount.getCurrentAccount().user
  const [groupsCanSeeNotifications, setGroupsCanSeeNotifications] =
    useState<boolean>(false)
  const [roleCanSeeNotifications, setRoleCanSeeNotifications] =
    useState<boolean>(false)

  useEffect(() => {
    const profilesCanSeeNotifications = [
      Profile.CRMO,
      Profile.DOCTOR,
      Profile.PATIENT,
      Profile.SECRETARY
    ]
    setGroupsCanSeeNotifications(
      profilesCanSeeNotifications.some((group) => user.groups?.includes(group))
    )
    setRoleCanSeeNotifications(profilesCanSeeNotifications.includes(user.role))
  }, [user])

  return (
    <>
      <S.Wrapper aria-label="header">
        <S.HamburguerIconWrapper
          aria-label="Open menu icon"
          onClick={() => menuStore.open()}
        >
          <HamburguerIcon />
        </S.HamburguerIconWrapper>

        <S.LogoWrapper onClick={() => history.push('/home')}>
          <Logo />
        </S.LogoWrapper>

        {(groupsCanSeeNotifications || roleCanSeeNotifications) && (
          <Notifications />
        )}
      </S.Wrapper>
      <SideMenu />
    </>
  )
})

export default Header
