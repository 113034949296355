import styled from 'styled-components'

export const Content = styled.div`
  display: flex;
  flex: 1;
  padding-bottom: 100px;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`

export const Inputs = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 40px;
  gap: 20px;
`

export const Icon = styled.div`
  text-align: center;
  height: 54px;
  width: 51px;
  margin-top: 65px;
  border-radius: 0px 10px 10px 0px;
  background: ${({ theme }) => theme.colors.primary};
  svg {
    path {
      fill: #fff;
      opacity: 1;
    }
  }
`

export const Input = styled.div`
  width: 180px;
`

export const SelectInput = styled.div`
  width: 121px;
`

export const SelectsGrid = styled.div`
  display: grid;
  grid: auto / 1fr 1fr 1fr;
  column-gap: 16px;
  row-gap: 24px;
`

export const ChipsGrid = styled.div`
  /* display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-auto-flow: dense;
  column-gap: 9px;
  row-gap: 24px; */
  display: flex;
  flex-wrap: wrap;
  gap: 9px;
  label {
    font-size: 14px;
    padding: 10px 10px;
  }
`

export const Label = styled.label`
  font-size: ${({ theme }) => theme.font.sizes.small};
  font-weight: 400;
  color: ${({ theme }) => theme.colors.primary};
  cursor: pointer;

  strong {
    font-size: ${({ theme }) => theme.font.sizes.small};
    font-weight: 400;
    color: ${({ theme }) => theme.colors.lightRed};
  }
`
