import React from 'react'
import { TermsTypeEnum } from 'common/enum/terms-type'

// Added jun/2024
export default function TermConfidentialityAndDataProtection() {
  return {
    name: TermsTypeEnum.TERM_CONFIDENTIALITY_AND_DATA_PROTECTION_2024,
    title: 'Termo de uso dos médicos e secretárias',
    content: (
      <>
        <div>
          <p>Seja bem vindo(a)!</p>
          <p>
            Agradecemos por acessar o Meu Mater Dei, plataforma digital da Rede
            Mater Dei de Saúde (“Nós”), disponível no site
            https://docmeu.materdei.com.br/login e no aplicativo “Meu Mater Dei”
            (“Plataforma”).
          </p>
          <p>
            Por meio da Plataforma, os médicos (“Médicos”) poderão gerenciar
            suas secretárias (“Secretárias” e, em conjunto com os Médicos,
            “Você”) e (i) acessar sua agenda médica; (ii) agendar cirurgias; e
            (iii) solicitar e cadastrar pedidos cirúrgicos, bem como acompanhar
            os status e pendências de cada um deles.
          </p>
          <p>
            Ao utilizar a Plataforma, Você declara estar integralmente de acordo
            com estes Termos de Uso dos Médicos e Secretárias (“Termos”), tendo
            compreendido e concordado com todas as suas cláusulas. Por isso, é
            importante que Você leia atentamente estes Termos.
          </p>
          <p>
            Se você é paciente ou colaborador da Rede Mater Dei de Saúde, é
            preciso conhecer e estar de acordo com os Termos de Uso específicos
            para essas categorias, que também estão disponíveis em nossa
            Plataforma.
          </p>
          <p>
            Caso tenha qualquer dúvida, fique à vontade para entrar em contato
            conosco – para Nós, é sempre um prazer lhe atender! Basta enviar uma
            mensagem ao nosso time de suporte por meio do e-mail
            meumaterdei@materdei.com.br ou ligar para o telefone (31) 3401-7900.
          </p>
        </div>
        <div>
          <h2>1. Definições</h2>
          <p>
            1.1. Todos os termos referidos com inicial maiúscula nestes Termos,
            incluindo o preâmbulo acima, seja no singular ou no plural, em ambos
            os gêneros, têm os significados atribuídos ao longo destes Termos
            entre parênteses e aspas.
          </p>
        </div>
        <div>
          <h2>2. Sobre a plataforma</h2>
          <p>
            2.1. A atuação da Rede Mater Dei de Saúde envolve, dentre outros
            serviços, a licença limitada e temporária da Plataforma, por meio da
            qual Você poderá (i) acessar sua agenda médica; (ii) agendar
            cirurgias; e (iii) solicitar e cadastrar pedidos cirúrgicos, bem
            como acompanhar os status e pendências de cada um deles.
          </p>
          <p>
            2.2. Ao acessar a Plataforma, Você poderá utilizar todas as
            funcionalidades nela disponibilizadas, declarando, para tanto, ter
            lido, compreendido e aceitado estes
          </p>
          <p>
            2.3. A Plataforma é o ambiente virtual pelo qual os Médicos poderão
            ter uma ampla visão de sua agenda profissional na Rede Mater Dei de
            Saúde.
          </p>
          <p>
            2.4. Para auxiliar no processo de acompanhamento de agenda e
            marcação de cirurgias, os Médicos poderão cadastrar suas Secretárias
            na Plataforma.
          </p>
          <ol style={{ listStyle: 'none' }}>
            <li>
              2.4.1. Os Médicos poderão fazer a gestão de Secretárias, de modo
              que, a qualquer momento, poderão descadastrar Secretárias já
              vinculadas na Plataforma e cadastrar novas profissionais.
            </li>
          </ol>
          <p>
            2.5. Por meio da Plataforma, Você poderá acessar sua agenda
            cirúrgica e ambulatorial.
          </p>
          <ol style={{ listStyle: 'none' }}>
            <li>
              2.5.1. Na agenda, constarão os detalhes dos procedimentos a serem
              realizados, como nome do paciente, convênio, unidade da Rede Mater
              Dei de Saúde, setor, data e horário.
            </li>
          </ol>
          <p>
            2.6. Por meio da Plataforma, Você poderá solicitar e cadastrar
            pedidos cirúrgicos, bem como acompanhar os status e pendências de
            cada um deles.
          </p>
          <ol style={{ listStyle: 'none' }}>
            <li>
              2.6.1. Para cadastrar um novo pedido cirúrgico, Você deverá
              informar a unidade da Rede Mater Dei de Saúde e a unidade
              cirúrgica em que o procedimento será realizado.
            </li>
            <li>
              2.6.2. Você deverá buscar por um paciente cadastrado ou inserir um
              paciente novo, e adicionar (i) os procedimentos que serão
              realizados, (ii) suas quantidades e (iii) o CID referente à
              doença.
            </li>
            <li>
              2.6.3. Você deverá escrever sobre a história clínica do paciente e
              indicar outras informações relevantes para a cirurgia, devendo,
              ainda, adicionar os laudos necessários e a data prevista para a
              cirurgia.
            </li>
            <li>
              2.6.4. Para a autorização do pedido cirúrgico, será necessário
              adicionar: carteira de identidade, carteira do convênio, pedido
              cirúrgico, relatório médico, consentimento cirúrgico,
              consentimento anestésico, avaliação pré-anestésica e outros
              documentos do paciente porventura solicitados.
            </li>
            <li>
              2.6.5. O cadastro de pedido cirúrgico não garante que este será
              autorizado ou que a cirurgia será agendada, sendo necessária a
              autorização da Rede Mater Dei de Saúde e do convênio médico, se
              cabível.
            </li>
          </ol>
          <p>
            2.7. Os pedidos cirúrgicos passarão por análise da Rede Mater Dei de
            Saúde e do convênio médico do paciente. Se identificarmos pendências
            nos pedidos cirúrgicos, Nós enviaremos a Você um e-mail de alerta e
            Você poderá conferi-las na Plataforma.
          </p>
          <ol style={{ listStyle: 'none' }}>
            <li>
              2.7.1. Os pedidos que apresentarem pendências serão informados a
              Você na Plataforma, para que Você responda as interações
              pendentes.
            </li>
            <li>
              2.7.2. Na Plataforma, Você terá a visualização de finalização do
              pedido cirúrgico, podendo acompanhar os itens autorizados, as
              senhas e suas respectivas validades.
            </li>
          </ol>
          <p>
            2.8. Na Plataforma, Você poderá acompanhar o andamento dos pedidos
            cirúrgicos. Caso autorizados, Você poderá agendar a cirurgia ou, se
            cabível, cancelar o pedido cirúrgico.
          </p>
          <p>
            2.9. Para o agendamento da cirurgia, Você deverá confirmar as
            informações fornecidas quando do cadastramento do pedido cirúrgico e
            selecionar a data para a cirurgia, sendo o horário opcional.
          </p>
          <ol style={{ listStyle: 'none' }}>
            <li>
              2.9.1. Antes de finalizar a solicitação de agendamento, Você
              deverá revisar todas as informações fornecidas.
            </li>
          </ol>
        </div>
        <div>
          <h2>3. Condições gerais de uso da plataforma</h2>
          <p>
            3.1. Caso seja constatado, ou haja suspeita, de que Você está
            fazendo uso indevido da Plataforma, a Rede Mater Dei de Saúde
            poderá, a seu exclusivo critério e por medida de segurança,
            suspender imediatamente o seu acesso à Plataforma, informando, por
            e-mail, a suspensão, sua razão e quais medidas Você deverá tomar
            para regularizar a situação, se cabível.
          </p>
          <p>
            3.2. Qualquer atualização nos parâmetros técnicos para utilização da
            Plataforma será comunicada pela Rede Mater Dei de Saúde a Você.
          </p>
          <p>
            3.3. A Rede Mater Dei de Saúde poderá, a seu critério, fazer
            melhorias de funcionamento, correções e inclusão de novos recursos
            na Plataforma.
          </p>
          <p>
            3.4. A Rede Mater Dei de Saúde empregará os melhores esforços na
            consultoria e na evolução tecnológica da Plataforma. No caso de
            interrupção do funcionamento da Plataforma, a Rede Mater Dei de
            Saúde se compromete a tomar as medidas necessárias para restabelecer
            o adequado funcionamento, no menor prazo possível.
          </p>
        </div>
        <div>
          <h2>4. CADASTRO</h2>
          <p>
            4.1. Os Médicos que prestam serviços para a Rede Mater Dei de Saúde
            receberão, por e-mail ou SMS, um convite para se cadastrar na
            Plataforma.
          </p>
          <ol style={{ listStyle: 'none' }}>
            <li>
              4.1.1. Apenas os Médicos cadastrados na base de dados da Rede
              Mater Dei de Saúde poderão ter acesso à Plataforma enquanto
              médicos.
            </li>
          </ol>
          <p>
            4.2. Recebido o convite, Você deverá finalizar seu cadastro. Na
            oportunidade, Você deverá confirmar seu CPF e CRM e criar uma senha
            de acesso de sua preferência, pessoal e intransferível (“Cadastro”).
          </p>
          <ol style={{ listStyle: 'none' }}>
            <li>
              4.2.1. É obrigatório que as informações confirmadas/inseridas
              sejam válidas e verdadeiras, sendo o Médico responsável pelos
              dados preenchidos.
            </li>
          </ol>
          <p>
            4.3. Finalizado o Cadastro, Você deverá fazer sua assinatura digital
            na Plataforma.
          </p>
          <p>
            4.4. Os Médicos poderão vincular Secretárias ao seu Cadastro por
            meio da Plataforma. Nesse caso, as Secretárias receberão um convite
            por e-mail para criar seu próprio cadastro.
          </p>
          <ol style={{ listStyle: 'none' }}>
            <li>
              4.4.1. As Secretárias somente terão acesso aos pedidos cirúrgicos
              dos Médicos a que estejam vinculadas.
            </li>
            <li>
              4.4.2. Uma vez desvinculada pelo Médico, o acesso da Secretária à
              Plataforma será suspenso.
            </li>
          </ol>
          <p>
            4.5. Nos reservamos o direito de recusar qualquer solicitação de
            Cadastro e de suspender, a qualquer tempo, qualquer Cadastro
            previamente efetuado que esteja em desacordo com estes Termos.
          </p>
          <p>
            4.6. A Rede Mater Dei de Saúde poderá alterar os seus critérios de
            elegibilidade de acesso e utilização a qualquer momento, sem que
            para isso tenha que te comunicar ou avisar previamente.
          </p>
          <p>
            4.7. Para atualizar ou editar seu Cadastro, Você poderá fazê-lo
            presencialmente, em qualquer das unidades da Rede Mater Dei de
            Saúde, ou virtualmente, bastando, para tanto, enviar um e-mail com a
            sua solicitação para meumaterdei@materdei.com.br.
          </p>
          <p>
            4.8. Se você entender que foi inserida na Plataforma qualquer
            informação falsa ou que viole estes Termos, você deverá nos enviar
            um e-mail solicitando a remoção do conteúdo ou o bloqueio do acesso
            do responsável pelas informações, sem prejuízo das demais sanções
            cabíveis.
          </p>
          <p>
            4.9. Caso deseje, Você poderá solicitar a exclusão da sua conta na
            Plataforma.
          </p>
          <ol style={{ listStyle: 'none' }}>
            <li>
              4.9.1. A exclusão de sua conta não lhe isentará de quaisquer
              obrigações, tampouco limitará qualquer responsabilidade que você
              possa ter perante a Rede Mater Dei de Saúde ou terceiros.
            </li>
            <li>
              4.9.2. Ainda que sua conta seja excluída, a Rede Mater Dei de
              Saúde manterá em seus bancos de dados, pelo período necessário
              para cumprimento de obrigações legais, regulatórias e/ou para fins
              de auditorias, informações referentes ao atendimento médico, bem
              como prontuários dos pacientes, conforme determinado pela
              Resolução do CFM n.º 1.821/2007.
            </li>
          </ol>
        </div>
        <div>
          <h2>5. Responsabilidades do médico e da secretária</h2>
          <p>
            5.1. Sem prejuízo das obrigações previstas nestes Termos, Você fica
            ciente e se obriga a:
          </p>
          <ol type="a">
            <li>
              reconhecer que todos os acessos realizados na Plataforma são
              interpretados como tendo sido feitos por Você (Médico ou
              Secretária), de forma incontestável;
            </li>
            <li>impedir que terceiros utilizem o seu perfil ou sua senha;</li>
            <li>manter seu Cadastro sempre atualizado;</li>
            <li>
              dispor de dispositivos, recursos e equipamentos tecnológicos com
              antivírus e firewall habilitados, softwares devidamente
              atualizados, além da adoção de medidas de segurança mínimas para
              acesso à Plataforma;
            </li>
            <li>
              deixar seus sistemas de antispam, filtros similares ou
              configurações de redirecionamento de mensagens ajustados de modo
              que não interfiram no recebimento dos comunicados e materiais da
              Rede Mater Dei de Saúde, não sendo aceitável nenhuma escusa caso
              não tenha tido acesso a algum e-mail ou mensagem eletrônica em
              virtude dos recursos mencionados;
            </li>
            <li>
              respeitar todos os direitos de propriedade intelectual de
              titularidade da Rede Mater Dei de Saúde;
            </li>
            <li>
              na incidência de danos decorrentes de atos praticados por meio do
              acesso à Plataforma ou a terceiros, arcar com todas as obrigações
              de indenizar o sujeito lesado, assumindo o polo passivo de ação
              judicial ou procedimento administrativo e requerendo a exclusão da
              Rede Mater Dei de Saúde, devendo arcar totalmente com as despesas
              e custas processuais atinentes, deixando-a livre de prejuízos e
              ônus
            </li>
          </ol>
        </div>
        <div>
          <h2>6. Suporte Remoto</h2>
          <p>
            6.1. A Rede Mater Dei de Saúde oferece serviço de suporte remoto
            (chat, telefone e/ou internet) para utilização da Plataforma, de
            segunda à sexta-feira, dentro do horário comercial, com exceção de
            feriados nacionais, estaduais e municipais do local das unidades da
            Rede Mater Dei de Saúde (“Suporte Remoto”). Mesmo fora do horário do
            Suporte Remoto, a Rede Mater Dei de Saúde manterá disponível o
            acesso para registro de chamadas e demandas, que serão atendidas no
            dia útil seguinte ao do registro.
          </p>
          <p>
            6.2. O Suporte Remoto é um serviço gratuito prestado pela Rede Mater
            Dei de Saúde, limitando-se exclusivamente a esclarecimentos sobre o
            funcionamento da Plataforma. Assim, a Rede Mater Dei de Saúde não se
            responsabiliza pelo suporte relacionado ao uso de software e
            hardware utilizados por Você.
          </p>
        </div>
        <div>
          <h2>
            7. Isenções e limitações de Responsabilidade da Rede Mater Dei de
            Saúde no âmbito do Meu Mater Dei
          </h2>
          <p>
            7.1. A Rede Mater Dei de Saúde responderá única e exclusivamente
            pela disponibilização da Plataforma.
          </p>
          <p>
            7.2. A Rede Mater Dei de Saúde não oferece nenhuma garantia de que a
            Plataforma sempre funcionará sem interrupções, atrasos, imperfeições
            ou erros.
          </p>
          <p>
            7.3. A Rede Mater Dei de Saúde não será responsável por verificar,
            controlar, aprovar ou garantir a adequação ou exatidão das
            informações ou dados disponibilizados por Você.
          </p>
          <p>
            7.4. A Rede Mater Dei de Saúde não se responsabiliza por eventos
            definidos na legislação como caso fortuito ou força maio.
          </p>
        </div>
        <div>
          <h2>8. Privacidade e Proteção de Dados Pessoais</h2>
          <p>
            8.1. O tratamento dos dados pessoais inseridos na Plataforma ou
            informados por meio dos nossos canais de comunicação será regido
            pela Lei Geral de Proteção de Dados (Lei n.º 13.709/2018), com as
            diretrizes a serem emanadas pela Autoridade Nacional de Proteção de
            Dados e com as demais normas aplicáveis, bem como pela Política de
            Privacidade da Plataforma.
          </p>
          <p>
            8.2. Os dados pessoais acessados por Você pela Plataforma somente
            poderão ser Tratados para cumprir com obrigações regulatórias ou com
            estes Termos, sendo vedado o desvio de finalidade.
          </p>
          <p>
            8.3. Você se compromete a reportar a Nós, no prazo de 48 (quarenta e
            oito) horas corridas a contar do momento em que tomar conhecimento
            do fato, a ocorrência de qualquer incidente envolvendo os dados
            pessoais tratados no âmbito destes Termos, fornecendo por escrito a
            Nós todas as providências adotadas, cooperando na resolução do
            incidente.
          </p>
          <p>
            8.4. Você se compromete a auxiliar a Rede Mater Dei de Saúde na
            adoção das medidas necessárias para o cumprimento das obrigações
            relacionadas ao exercício dos direitos dos titulares de dados
            pessoais. Você deverá reportar a Nós imediatamente eventuais
            solicitações por parte dos titulares dos dados.
          </p>
          <p>
            8.5. Caso tenha qualquer dúvida sobre proteção de dados, fique à
            vontade para entrar em contato conosco – para Nós, é sempre um
            prazer lhe atender! Basta enviar uma mensagem à(o) nosso(a) DPO, por
            meio do contato{' '}
            <a
              href="mailto:dpo@materdei.com.br"
              target="_blank"
              rel="noreferrer"
              title="dpo@materdei.com.br "
            >
              dpo@materdei.com.br
            </a>{' '}
            ou por meio do{' '}
            <a
              href="https://www.materdei.com.br/institucional/dpo-mater-dei"
              target="_blank"
              rel="noreferrer"
              title="https://www.materdei.com.br/institucional/dpo-mater-dei"
            >
              formulário em nosso site
            </a>
            .
          </p>
        </div>
        <div>
          <h2>9. Propriedade intelectual</h2>
          <p>
            9.1. É concedida a Você uma licença limitada, individual,
            temporária, não exclusiva e intransferível para uso da Plataforma.
            Seu direito de usar a Plataforma está limitado às condições
            estabelecidos nestes Termos.
          </p>
          <p>
            9.2. É permitida a divulgação da Plataforma em suas redes sociais ou
            canais de propaganda.
          </p>
          <p>
            9.3. A Rede Mater Dei de Saúde é a única detentora dos direitos de
            propriedade intelectual sobre a marca e dos elementos componentes da
            Plataforma, incluindo, mas sem se limitar, software, códigos,
            domínios, tecnologias, textos, fotografias, vídeos, interfaces
            visuais, recursos interativos, gráficos, design, logo, marca,
            patentes, invenções ou outros elementos que constituam propriedade
            intelectual ou industrial.
          </p>
          <p>
            9.4. É vedado a Você modificar, reproduzir, republicar, criar,
            baixar, transmitir, distribuir, alugar, emprestar, vender, ceder,
            licenciar, sublicenciar, fazer engenharia reversa, exibir
            publicamente ou criar trabalhos derivados baseados na Plataforma, no
            todo ou em parte, exceto quando expressamente autorizado por escrito
            por Nós. Além disso, Nós não concedemos nenhum direito expresso ou
            implícito além dos dispostos nestes Termos.
          </p>
          <p>
            9.5. Sem prejuízo das penalidades previstas nestes Termos, caso Você
            descumpra as obrigações assumidas nesta cláusula, estará sujeito a
            indenizar a Rede Mater Dei de Saúde pelas perdas e danos cabíveis e
            lucros cessantes que lhe forem causados.
          </p>
        </div>
        <div>
          <h2>10. Anticorrupção</h2>
          <p>
            10.1. A Rede Mater Dei de Saúde declara que é contrária à prática de
            atos lesivos à administração pública, nacional ou estrangeira, assim
            entendidos como todos aqueles atos que atentem contra o patrimônio
            público nacional ou estrangeiro, contra princípios da administração
            pública ou contra os compromissos internacionais assumidos pelo
            Brasil e declara que adotará as melhores práticas de prevenção à
            inobservância e monitoramento ao cumprimento das leis anticorrupção,
            de defesa da concorrência e de prevenção à lavagem de dinheiro e/ou
            terceiros por elas contratados, bem como cumprirá a legislação
            anticorrupção aplicável, principalmente as leis n.º 12.846/13 e n.º
            8.429/1992.
          </p>
          <p>
            10.2. A Rede Mater Dei de Saúde declara e garante que não é
            investigada ou é parte em processo judicial ou administrativo que
            apura a prática de lavagem de dinheiro, corrupção pública ou
            privada, fraude em licitações e/ou atos contra a Administração
            Pública.
          </p>
          <p>
            10.3. Ainda, garante que não recebe, oferece, promete ou dá, direta
            ou indiretamente, qualquer ganho, privilégio ou benefício ilícito ou
            indevido, de ou para qualquer pessoa que exerça por eleição,
            convocação, nomeação, designação, contratação ou de qualquer outra
            forma de investidura ou vínculo, mandato, cargo, emprego, função ou
            outra forma de representação, bem como seus respectivos familiares:
            (i) nos poderes Legislativo, Executivo ou Judiciário, nacional ou
            estrangeiro; (ii) em órgãos da Administração Pública ou ente público
            ou sociedades controladas direta ou indiretamente pelo poder
            público, nacionais ou estrangeiras; e, ainda, (iv) partidos
            políticos, candidatos a cargos públicos, associados a partidos
            políticos, a fim de influenciar ou induzir.
          </p>
          <p>
            10.4. A Rede Mater Dei de Saúde cooperará integralmente com qualquer
            investigação que se pretenda conduzir a respeito de violação,
            efetiva ou potencial, desta obrigação e notificará imediatamente o
            Paciente sobre qualquer solicitação recebida para executar
            procedimento que possa constituir violação às normas estrangeiras de
            repressão à corrupção ou qualquer lei, estatuto ou regulamento
            semelhante adotado pelo Brasil.
          </p>
        </div>
        <div>
          <h2>11. Modificações dos Termos e condições gerais</h2>
          <p>
            11.1. A Rede Mater Dei de Saúde poderá alterar, a qualquer tempo,
            estes Termos, visando seu aprimoramento e melhoria da Plataforma. Os
            novos Termos entrarão em vigor 10 (dez) dias após sua publicação. No
            prazo de 5 (cinco) dias contados a partir da publicação da nova
            versão, Você deverá comunicar por e-mail, de forma expressa, caso
            não concorde com os termos alterados. Nesse caso, o vínculo
            contratual deixará de existir, desde que não haja contas ou dívidas
            em aberto. Não havendo manifestação, entender-se-á que Você aceitou
            os novos Termos.
          </p>
        </div>
        <div>
          <h2>12. Disposições gerais</h2>
          <p>
            12.1. Em caso de dúvidas sobre a Plataforma, você poderá nos
            encaminhar e-mail para o meumaterdei@materdei.com.br ou ligar para o
            telefone (31) 33401-7900.
          </p>
          <p>
            12.2. As Partes são totalmente independentes, sendo cada uma única e
            exclusivamente responsável por seus atos e obrigações, visto que
            estes Termos não criam entre a Rede Mater Dei de Saúde e Você
            qualquer outro vínculo, responsabilidade ou obrigação, além daqueles
            aqui contraídos. Você reconhece, em especial, que estes Termos não
            lhe outorgam qualquer poder de representação, mandato, agência,
            comissão ou outro poder análogo com relação à Rede Mater Dei de
            Saúde. As partes declaram em especial que nenhuma delas detém
            qualquer autoridade para assumir ou criar qualquer obrigação,
            expressa ou implícita, em nome da outra, e nem a representar sob
            nenhuma circunstância.
          </p>

          <p>
            12.3. As partes assumem integral responsabilidade pelo pagamento de
            suas respectivas obrigações fiscais e parafiscais decorrentes da
            execução destes Termos, responsabilizando-se, ainda, pelas
            consequências advindas de quaisquer demandas administrativas,
            fiscais, judiciais, trabalhistas, previdenciárias ou de outra
            natureza que decorram do presente instrumento.
          </p>
          <p>
            12.4. Estes Termos e os direitos e obrigações dele resultantes não
            poderão ser cedidos ou transferidos, parcial ou integralmente, por
            Você sem o consentimento prévio por escrito da Rede Mater Dei de
            Saúde. A Rede Mater Dei de Saúde, por sua vez, poderá livremente
            ceder os direitos e obrigações resultantes destes Termos de Uso, no
            todo ou em parte, a quaisquer terceiros sem o prévio consentimento
            dos Médicos e Secretárias.
          </p>
          <p>
            12.5. O não exercício por Nós de quaisquer de seus direitos
            assegurados por estes Termos, bem como a não aplicação de quaisquer
            das penalidades nele previstas, não serão considerados como renúncia
            a estes direitos, nem constituirão novação contratual.
          </p>
          <p>
            12.6. Caso qualquer disposição destes Termos seja considerada
            inválida, tal invalidade não afetará a validade de quaisquer outras
            de suas disposições e condições.
          </p>
        </div>
        <div>
          <h2>13. Legislação Aplicável e Foro de Eleição</h2>
          <p>
            13.1. Estes Termos são regidos de acordo com as leis da República
            Federativa do Brasil. Fica eleito o foro da Comarca de Belo
            Horizonte/MG, para dirimir quaisquer litígios decorrentes destes
            Termos de Uso, com renúncia a qualquer outro foro, por mais
            privilegiado que seja.
          </p>
        </div>
        <div>
          <p style={{ textAlign: 'right' }}>
            Belo Horizonte, 17 de maio de 2024.
          </p>
        </div>
      </>
    ),
    acceptMessage: `Declaro que li e aceito as condições do Termo de uso dos médicos e secretárias`
  }
}
