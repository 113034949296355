import React, { useContext, useEffect, useState } from 'react'
import SurgicalSchedulingAppointmentsLayout from 'presentation/doctor/layouts/SurgicalSchedulingAppointments'
import { useServices } from 'presentation/hooks/use-services'
import { SurgicalSchedulinhContext } from 'main/factories/pages/doctor/surgical-scheduling/surgical-scheduling-factory'
import { AvailableDaysModel } from 'domain/usecases/surgical-scheduling/get-available-days'
import { AvailableHoursModel } from 'domain/usecases/surgical-scheduling/get-available-hours'
import {
  WithLoading,
  WithLoadingProps
} from 'presentation/shared/components/HOCs/WithLoading'
import moment from 'moment-timezone'
import { toast } from 'react-toastify'
import HeaderNew from 'presentation/shared/components/HeaderNew'
import { ProcedureModel } from 'domain/entities/surgical-order-model'

function SurgicalSchedulingAppointments({ setIsLoading }: WithLoadingProps) {
  const { dispatch, state } = useContext<any>(SurgicalSchedulinhContext)
  const surgicalSchedulingService = useServices().surgicalScheduling
  const [availableDays, setAvailableDays] = useState<AvailableDaysModel[]>(
    [] as AvailableDaysModel[]
  )
  const [availableHours, setAvailableHours] = useState<AvailableHoursModel[]>(
    [] as AvailableHoursModel[]
  )
  const [hasHour, setHasHour] = useState<boolean | undefined>(undefined)

  const getAvailableDays = async (startDate: string, endDate: string) => {
    setIsLoading(true)
    try {
      const response = await surgicalSchedulingService.getAvailableDays({
        duration: state.time.requested_time || state.time.predicted_time,
        surgeryCenterCode: state.surgical_order.room_id,
        endDate,
        startDate
      })

      setAvailableDays(response.data)
    } catch (error: any) {
      toast.error('Ocorreu um erro ao carregar os dias disponiveis')
    } finally {
      setIsLoading(false)
    }
  }

  const getAvailableHours = async (date: string) => {
    setIsLoading(true)

    const mainProcedure = state.surgical_order?.procedures?.find(
      (procedure: ProcedureModel) => {
        return procedure.isMain
      }
    )

    try {
      const response = await surgicalSchedulingService.getAvailableHours({
        duration: state.time.requested_time || state.time.predicted_time,
        surgeryCenterCode: state.surgical_order.room_id,
        date,
        procedureId: mainProcedure?.code
      })

      setAvailableHours(response.data)
      if (hasHour === undefined && isValidDate) {
        setHasHour(response.data?.length > 0)
      }
    } catch (error: any) {
      toast.error('Ocorreu um erro ao carregar os horarios disponiveis')
    } finally {
      setIsLoading(false)
    }
  }

  const selectedDate = state.appointments?.length
    ? state.appointments[0]?.start_date
    : undefined

  const isValidDate =
    moment(state.surgical_order.expectedDate).isSameOrAfter(new Date()) ||
    selectedDate

  const initData = async () => {
    if (selectedDate || isValidDate) {
      getAvailableHours(selectedDate || state.surgical_order.expectedDate)
    } else {
      setAvailableHours([] as AvailableHoursModel[])
    }
  }

  useEffect(() => {
    initData()
  }, [])

  return (
    <>
      <HeaderNew actualPageTitle="Agendar cirurgia" />
      <SurgicalSchedulingAppointmentsLayout
        dispatch={dispatch}
        getAvailableDays={getAvailableDays}
        getAvailableHours={getAvailableHours}
        availableHours={availableHours}
        availableDays={availableDays}
        initialDate={selectedDate || state.surgical_order.expectedDate}
        initialStatus={{
          hasDate: Boolean(state.surgical_order?.expectedDate),
          hasHour: hasHour
        }}
        isInitialdDateValid={isValidDate}
        state={state.appointments || []}
        setIsLoading={setIsLoading}
      />
    </>
  )
}

export default WithLoading(SurgicalSchedulingAppointments)
