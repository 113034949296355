import { TherapeuticPlanDiagnosisModel } from './therapeutic-plan-diagnosis-model'
import { ProposedProtocolModel } from './proposed-protocol-model'

export type TherapeuticPlanModel = {
  therapeutic_plan_id?: number
  treatmentType?: TreatmentType
  justification?: string
  status: TherapeuticPlanStatus
  diagnosis?: TherapeuticPlanDiagnosisModel
  proposedProtocol?: ProposedProtocolModel
  createdById: number
  createdAt: Date
  updatedAt: Date
}

export type TreatmentType = 'INITIAL' | 'CONTINUITY' | 'PROTOCOL_CHANGE'
export const TreatmentTypeLang = {
  PROTOCOL_CHANGE: 'Mudança de Protocolo',
  CONTINUITY: 'Continuidade',
  INITIAL: 'Inicial'
}
export type TherapeuticPlanStatus =
  | 'opened'
  | 'sent'
  | 'concluded'
  | 'in validation at the pharmacy'
  | 'in revision'
  | 'approved request'
  | 'requested purchase of the drug'
  | 'available drug'
