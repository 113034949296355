import React, { useContext, useState } from 'react'
import { SurgicalOrderDashboardContext } from 'main/factories/pages/doctor/surgical-order/surgical-order-dashboard-factory'
import * as S from './styles'
import TextFieldNew from 'presentation/shared/components/TextFieldNew'
import { ContainerNew } from 'presentation/shared/components/ContainerNew'
import { ReactComponent as SearchIcon } from 'presentation/assets/icons/search2.svg'
import SupportTextNew from 'presentation/shared/components/SupportTextNew'
import SurgicalOrderCard from 'presentation/shared/layouts/SurgicalOrderCard'
import {
  SchedulingTypes,
  LoadSurgicalOrdersByDoctorAndStatus
} from 'domain/usecases/surgical-order/load-surgical-orders-by-doctor-and-status'
import ButtonNew from 'presentation/shared/components/ButtonNew'
import { ReactComponent as EmptyStateIcon } from 'presentation/assets/icons/empty-state-neutral.svg'
import { useHistory } from 'react-router'
import {
  SurgicalOrdersStatus,
  SurgicalOrdersStatusPlural
} from 'common/enum/surgical-orders-status'
import SheetModalNew from 'presentation/shared/components/SheetModalNew'
import {
  PasswordMaxDateValidationStatus,
  PasswordMaxDateValidationStatusTranslation
} from 'common/enum/password-max-date-validation-status'
import { ExpirationDateTypes } from 'domain/usecases/surgical-order/load-expiration-date-status-dashboard-count'

type Props = {
  status?: SchedulingTypes
  passwordStatus?: ExpirationDateTypes
  getOrdersList: (params: LoadSurgicalOrdersByDoctorAndStatus.Params) => void
  deleteSurgicalOrder?: (surgical_order_id: number) => void
}

export default function SurgicalOrdersPendenciesListLayout({
  status,
  passwordStatus,
  getOrdersList,
  deleteSurgicalOrder
}: Props) {
  const { state } = useContext<any>(SurgicalOrderDashboardContext)
  const [searchTerm, setSearchTerm] = useState('')
  const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false)
  const [orderToDelete, setOrderToDelete] = useState<number | undefined>()
  const history = useHistory()

  const ModalConfirmButton = () => {
    return (
      <ButtonNew
        onClick={async () => {
          if (orderToDelete && deleteSurgicalOrder) {
            await deleteSurgicalOrder(orderToDelete)
            unsetDeletModal()
          }
        }}
      >
        Excluir
      </ButtonNew>
    )
  }

  const unsetDeletModal = () => {
    setDeleteModalIsOpen(false)
    setOrderToDelete(undefined)
  }

  const ModalCancelButton = () => {
    return (
      <ButtonNew variant="text" onClick={() => unsetDeletModal()}>
        Cancelar
      </ButtonNew>
    )
  }

  const handleDeleteOrder = (surgical_order_id: number) => {
    setDeleteModalIsOpen(true)
    setOrderToDelete(surgical_order_id)
  }

  const getTransletedStatus = () => {
    if (passwordStatus) {
      return PasswordMaxDateValidationStatusTranslation[passwordStatus]
    } else if (status === SurgicalOrdersStatus.REQUESTED_CANCELLATION) {
      return 'Solicitações de cancelamento'
    } else if (status) {
      return SurgicalOrdersStatusPlural[status]
    } else {
      return 'Todos os pedidos'
    }
  }

  const searchOrder = (nextPage = false) => {
    getOrdersList({
      status,
      search: searchTerm,
      pageNumber: nextPage ? state.pageInfo.current_page + 1 : 1,
      passwordStatus
    })
  }

  const isLastPage = () => {
    return state.pageInfo?.current_page === state.pageInfo?.total_pages
  }

  const goToSurgicalOrder = (surgicalOrder: any) => {
    if (surgicalOrder.active_status === 'ORDER_OPEN') {
      history.push('/pedido-em-aberto', {
        surgicalOrder: surgicalOrder,
        comesFromOpenOrders: true
      })
    } else {
      history.push('/pedido-cirurgico/detalhes', {
        surgical_order_id: surgicalOrder.surgical_order_id
      })
    }
  }

  const onEnterSearch = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.keyCode === 13) {
      searchOrder()
    }
  }

  return (
    <ContainerNew gap="32px" noHeader>
      <SupportTextNew
        color="neutral900"
        weight="bold"
        className="capitalize-fl"
      >
        {getTransletedStatus()}
      </SupportTextNew>

      {passwordStatus === PasswordMaxDateValidationStatus.EXPIRES_SOON ? (
        <SupportTextNew color="neutral900">
          Vencimento nos próximos{' '}
          <span style={{ fontWeight: 'bold' }}>15 dias</span>
        </SupportTextNew>
      ) : (
        ''
      )}

      <TextFieldNew
        icon={<SearchIcon />}
        iconIsButton
        placeholder="Paciente, N° do aviso"
        value={searchTerm}
        onInputChange={setSearchTerm}
        onIconClick={() => searchOrder()}
        onKeyDown={onEnterSearch}
      />
      <S.PendenciesContainer>
        {state.surgicalOrdersList?.length ? (
          <>
            {state.surgicalOrdersList?.map((surgicalOrder: any) => {
              return (
                <>
                  {surgicalOrder.active_status ===
                  SurgicalOrdersStatus.ORDER_OPEN ? (
                    <SurgicalOrderCard
                      key={surgicalOrder.surgical_order_id}
                      surgicalOrder={surgicalOrder}
                      onClick={() => goToSurgicalOrder(surgicalOrder)}
                      showTrash
                      trashIconList
                      onTrashClick={handleDeleteOrder}
                    />
                  ) : (
                    <SurgicalOrderCard
                      key={surgicalOrder.surgical_order_id}
                      surgicalOrder={surgicalOrder}
                      onClick={() => goToSurgicalOrder(surgicalOrder)}
                      procedures
                      schedulingDate
                      statusTag
                    />
                  )}
                </>
              )
            })}
            <ButtonNew
              variant="text"
              onClick={() => searchOrder(true)}
              disabled={isLastPage()}
            >
              {isLastPage() ? 'Fim' : 'Ver mais'}
            </ButtonNew>
          </>
        ) : (
          <S.EmptyState>
            <EmptyStateIcon />
            <SupportTextNew color="neutral400" size="medium">
              Nenhum pedido cirurgico encontrado
            </SupportTextNew>
          </S.EmptyState>
        )}
      </S.PendenciesContainer>

      <SheetModalNew
        title="Excluir pedido em aberto"
        primaryButton={<ModalConfirmButton />}
        secondaryButton={<ModalCancelButton />}
        isOpen={deleteModalIsOpen}
        onClose={() => setDeleteModalIsOpen(false)}
      >
        <SupportTextNew weight="semiBold">
          Tem certeza que deseja excluir este pedido?
        </SupportTextNew>
      </SheetModalNew>
    </ContainerNew>
  )
}
