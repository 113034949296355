export enum DocumentsList {
  'GUIA SOLICITAÇÃO DE AUTORIZAÇÃO PARA PROCEDIMENTO AMBULATORIAL' = 'GUIA SOLICITAÇÃO DE AUTORIZAÇÃO PARA PROCEDIMENTO AMBULATORIAL',
  'Carteira de identidade' = 'Carteira de identidade',
  'Carteira do convênio' = 'Carteira do convênio',
  'Laudos dos exames' = 'Laudos de exames',
  'Relatório Médico' = 'Relatório médico',
  'Consent. cirúrgico' = 'Consentimento cirúrgico',
  'Consent. anestésico' = 'Consentimento anestésico',
  'Avaliação pré-anestésica' = 'Avaliação pré-anestésica',
  'Outros' = 'Outros',
  'Autorização' = 'Autorização',
  'Acordo de serviço' = 'Acordo de serviço',
  'Planejamento cirúrgico' = 'Planejamento cirúrgico',
  'Consulta pré-anestésica' = 'Consulta pré-anestésica',
  'Consent. de hemoterapia' = 'Consentimento de hemoterapia',
  'GUIA' = 'Guia',
  'GUIA DE OPME' = 'Guia de OPME',
  'GUIA SADT' = 'Guia SADT',
  'Consent. informado' = 'Consentimento informado',
  'Biópsia' = 'Biópsia',
  'Orçamento' = 'Orçamento',
  'Guia de autorização' = 'Guia de autorização',
  'Documento do paciente' = 'Documento do paciente',
  'Risco cirúrgico' = 'Risco cirúrgico',
  'Pedido cirúrgico' = 'Pedido cirúrgico',
  'Pedido médico' = 'Pedido médico',
  'Laudo de Imagem' = 'Laudo de imagem',
  'Termo de consentimento' = 'Termo de consentimento',
  'Todas as orientações' = 'Todas as orientações',
  'Documento do Exame' = 'Documento do exame',
  'Carteira do convênio - Frente' = 'Carteira do convênio (frente)',
  'Carteira do convênio - Verso' = 'Carteira do convênio (verso)',
  'Carteira de identidade - Frente' = 'Carteira de identidade (frente)',
  'Carteira de identidade - Verso' = 'Carteira de identidade (verso)',
  'GUIA SOLICITAÇÃO DE INTERNAÇÃO' = 'Guia de solicitação de internação',
  'PLANEJAMENTO CIRÚRGICO' = 'Planejamento cirúrgico',
  'GUIA DE PEDIDO CIRÚRGICO' = 'Guia de pedido cirúrgico'
}

// export enum DocumentsList {
//   HEALTH_CARD_FRONT = 'Carteira do convênio - Frente',
//   HEALTH_CARD_BACK = 'Carteira do convênio - Verso',
//   HEALTH_CARD = 'Carteira do convênio',
//   MEDICAL_REPORT = 'Relatório Médico',
//   IDENTITY_CARD_FRONT = 'Carteira de identidade - Frente',
//   IDENTITY_CARD_BACK = 'Carteira de identidade - Verso',
//   IDENTITY_CARD = 'Carteira de identidade',
//   SERVICE_AGREEMENT = 'Acordo de serviço',
//   AUTHORIZATION = 'Autorização',
//   SURGICAL_PLANNING = 'Planejamento cirúrgico',
//   SURGICAL_CONSENT = 'Consent. cirúrgico',
//   ANESTHETIC_CONSENT = 'Consent. anestésico',
//   PRE_ANESTHETIC_CONSENT = 'Consulta pré-anestésica',
//   PRE_ANESTHETIC_EVALUATION = 'Avaliação pré-anestésica',
//   HEMOTHERAPY_CONSENT = 'Consent. de hemoterapia',
//   GUIDE = 'GUIA',
//   SURGICAL_RISK = 'Risco cirúrgico',
//   SURGICAL_ORDER = 'Pedido cirúrgico',
//   MEDICAL_ORDER = 'Pedido médico',
//   EXAM_REPORT = 'Laudos dos exames',
//   IMAGE_REPORT = 'Laudo de Imagem',
//   CONSENT_TERM = 'Termo de consentimento',
//   ALL_GUIDANCES = 'Todas as orientações',
//   PATIENT_DOCUMENT = 'Documento do paciente',
//   AUTHORIZATION_GUIDE = 'Guia de autorização',
//   BIOPSY = 'Biópsia',
//   BUDGET = 'Orçamento',
//   INFORMED_CONSENT = 'Consent. informado',
//   GUIDE_SPSADT = 'GUIA SADT',
//   GUIDE_OPME = 'GUIA DE OPME',
//   GUIDE_HOSPITALIZATION_REQUEST = 'GUIA SOLICITAÇÃO DE INTERNAÇÃO',
//   GUIDE_SURGICAL_PLANNING = 'PLANEJAMENTO CIRÚRGICO',
//   GUIDE_SURGICAL_ORDER = 'GUIA DE PEDIDO CIRÚRGICO',
//   EXAM_DOCUMENT = 'Documento do Exame',
//   // DOCTOR #=#=#=#=#=#=#=#=#=#=#=#=#=#=#=#=#=#=#=#=#=#=#=3
//   UNDERGRADUATE_DEGREE_FRONT = 'Diploma de graduação - Frente',
//   UNDERGRADUATE_DEGREE_BACK = 'Diploma de graduação - Verso',
//   SPECIALIZATION_DEGREE = 'Diploma de especialização',
//   CLINIC_INTERN_PROGRAM = 'Plano de Residência',
//   FIRE_SAFETY = 'Código B - Segurança Contra Incêndio - Assistencial',
//   INTEGRITY_PROGRAM = 'Programa de Integridade da Rede Mater Dei de Saúde',
//   BASIC_LIFE_SUPPORT = 'Suporte Básico de Vida',
//   INFECTION_PREVENTION = 'Prevenção de Infecção Relacionado à Assistência',
//   PHARMACEUTICAL_CARE = 'Assistência Farmacêutica',
//   MANAGED_PROTOCOLS = 'Protocolos Gerenciados',
//   PATIENT_SAFETY = 'Segurança do Paciente',
//   ELETRONIC_MEDICAL_RECORDS = 'Utilização Segura do Prontuário Eletrônico',
//   ACCESS_T0_INTERACT = 'Acesso ao Interact',
//   SEDATION_TECHNIQUES_MONITORING = 'Sedação, Técnicas, Monitorização',
//   NALS = 'Suporte Avançado de Vida Neonatal (NALS)',
//   ACLS = 'Suporte Avançado de Vida (ACLS)',
//   PALS = 'Suporte Avançado de Vida Pediátrico (PALS)',
//   OTHER_DOCTOR_CERTIFICATES = 'Outros Certificados do Médico',
//   COUNCIL_ANNUITY_PAYMENT = 'Comprovante de Pagamento da Anuidade do Conselho',
//   DRIVERS_LICENSE_FRONT = 'Carteira de Motorista - Frente',
//   DRIVERS_LICENSE_BACK = 'Carteira de Motorista - Verso',
//   INDIVIDUAL_REGISTRATION_FRONT = 'CPF - Frente',
//   INDIVIDUAL_REGISTRATION_BACK = 'CPF - Verso',
//   BADGE_PHOTO = 'Foto do Crachá',
//   ADVICE_WALLET = 'Carteira do Conselho',
//   LATTES = 'Currículo',
//   CERTIFICATE_OF_PRIVILEGE = 'Certificado de Privilégio',
//   DOCUMENT_VALIDATION_DOCTOR = 'Documento de Validação de Fonte',
//   EMPTY = ''
// }
