import React, { ReactNode, useEffect, useState } from 'react'

export type CarouselProps = {
  children: ReactNode[]
  setState: React.Dispatch<React.SetStateAction<CarouselState>>
}

export type CarouselState = {
  slideNext: () => void
  slidePrev: () => void
  slideTo: (number: number) => void
  activeIndex: number
  isLast: boolean
}

const ConditionalCarousel = ({ children, setState }: CarouselProps) => {
  const [actualPage, setActualPage] = useState<number>(0)

  useEffect(() => {
    setState({
      activeIndex: actualPage,
      slideTo: setActualPage,
      slideNext: () => slide('next'),
      slidePrev: () => slide('prev'),
      isLast: (children && actualPage === children.length - 1) ?? false
    })
  }, [actualPage])

  function slide(side: 'prev' | 'next') {
    const newPage = actualPage + (side === 'next' ? 1 : -1)
    if (newPage > 0 || newPage < children?.length) {
      setActualPage(newPage)
    }
  }

  return (
    <>
      {children.filter((page, idx) => {
        return idx === actualPage && <div>{page}</div>
      })}
    </>
  )
}

export default ConditionalCarousel
