import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useStores } from 'presentation/hooks/use-stores'
import HeaderNew from 'presentation/shared/components/HeaderNew'
import SupportText from 'presentation/shared/components/SupportText'
import ProfilePic from 'presentation/shared/components/ProfilePic'
import * as S from './styles'
import { User } from 'domain/entities/user-model'
import {
  WithLoading,
  WithLoadingProps
} from 'presentation/shared/components/HOCs/WithLoading'
import { ReactComponent as CheckIcon } from 'presentation/assets/icons/check.svg'
import { toast } from 'react-toastify'
import { LocalStorageAdapter } from 'infra/storage-adapter/storage-adapter'

type DoctorCardProps = {
  doctor: User
  user: User
  index: number
  handleChangeSecretaryDoctor: any
}

export const SecretaryDoctorsLayout = WithLoading(
  ({ setIsLoading }: WithLoadingProps) => {
    const history = useHistory()
    const currentAccount = useStores().currentAccount
    const user = currentAccount.getCurrentAccount().user
    const menuStore = useStores().menu
    const permissions = useStores().permissions
    const localStorageAdapter = new LocalStorageAdapter()
    const secretaryDoctors = localStorageAdapter.get('secretary_doctors')

    useEffect(() => {
      localStorageAdapter.set('secretary_doctors', user.doctor_secretary)
    }, [])

    const handleChangeSecretaryDoctor = (doctor: User) => {
      if (doctor.user_id !== user.user_id) {
        setIsLoading(true)
        currentAccount
          .setCurrentDoctorSecretary(doctor.user_id)
          .then(() => {
            history.push('/home', { secretaryDoctors })
          })
          .catch((e: any) => {
            toast.error(e.message)
          })
          .finally(() => {
            setIsLoading(false)
          })
      }
    }

    const logout = async () => {
      await currentAccount.removeCurrentAccount()
      permissions.clean()
      history.push('/login')
      menuStore.close()
    }

    return (
      <>
        <HeaderNew actualPageOnClick={logout} />
        <SupportText style={{ margin: '20px' }}>
          Selecione em qual perfil deseja entrar:
        </SupportText>

        {user.doctor_secretary?.map((doctor: User, index: number) => {
          return (
            <DoctorCard
              key={index}
              doctor={doctor}
              index={index}
              handleChangeSecretaryDoctor={handleChangeSecretaryDoctor}
              user={user}
            />
          )
        })}
      </>
    )
  }
)

export const DoctorCard = ({
  handleChangeSecretaryDoctor,
  doctor,
  index,
  user
}: DoctorCardProps) => {
  const isSelectedDoctor = (doctor_id: number) => {
    if (user.user_id === doctor_id) return true
    return false
  }

  return (
    <S.Card
      className={isSelectedDoctor(doctor?.user_id) ? 'isActive' : ''}
      onClick={() => handleChangeSecretaryDoctor(doctor)}
      key={index}
    >
      <S.Profile>
        <ProfilePic
          data-testid="profile pic"
          outlined
          size="small"
          name={doctor.name}
        />
        {doctor.name.toLocaleLowerCase()}
      </S.Profile>
      <span className="button">
        {isSelectedDoctor(doctor.user_id) ? <CheckIcon /> : 'Entrar'}
      </span>
    </S.Card>
  )
}
