import React from 'react'
import SupportTextNew from '../SupportTextNew'
import AvatarNew from '../AvatarNew'
import theme from 'presentation/styles/theme'
import * as S from './styles'

type Props = {
  title?: string
  subtitle?: string
  counts: any
  types: {
    key: string
    label: string
    color: keyof typeof theme.ds.colors
    active: boolean
    action: () => void
  }[]
  disableHoverOnActive?: boolean
}

export default function PendenciesCountersdNwe({
  counts,
  types,
  title,
  subtitle,
  disableHoverOnActive = false
}: Props) {
  return (
    <S.Wrapper>
      {title && (
        <SupportTextNew weight="bold" color="neutral800" size="medium">
          {title}
        </SupportTextNew>
      )}
      {subtitle && <SupportTextNew>{subtitle}</SupportTextNew>}
      <S.AvatarContainer>
        {types.map((type) => {
          return (
            <div key={type.key}>
              <AvatarNew
                onClick={type.action}
                text={counts[type.key as keyof typeof counts]}
                active={type.active}
                size="xxlarge"
                color={type.color as keyof typeof theme.ds.colors}
                disableHoverOnActive={disableHoverOnActive}
              />
              <S.AvatarSupportText
                as="span"
                color={type.color}
                className="capitalize-fl"
              >
                {type.label}
              </S.AvatarSupportText>
            </div>
          )
        })}
      </S.AvatarContainer>
    </S.Wrapper>
  )
}
