export enum HospitalizationMode {
  hospital = 'Hospitalar',
  external = 'Externo',
  interned = 'Internado',
  ambulatory = 'Ambulatorial',
  'day hospital' = 'Hospital dia',
  'emergency room' = 'Pronto-socorro'
}

export const hospitalizationList = [
  {
    label: HospitalizationMode.hospital,
    value: 'hospital'
  },
  {
    label: HospitalizationMode.external,
    value: 'external'
  },
  {
    label: HospitalizationMode.interned,
    value: 'interned'
  },
  {
    label: HospitalizationMode.ambulatory,
    value: 'ambulatory'
  },
  {
    label: HospitalizationMode['day hospital'],
    value: 'day hospital'
  },
  {
    label: HospitalizationMode['emergency room'],
    value: 'emergency room'
  }
]
