import { GetSurgicalSchedulingDetails } from 'domain/usecases/surgical-scheduling/get-surgical-scheduling-details'
import { SurgicalSchedulingRepository } from 'repository/interfaces/surgical-scheduling-repository'

export class RemoteGetSurgicalSchedulingDetails
  implements GetSurgicalSchedulingDetails
{
  constructor(
    private readonly surgicalSchedulingRepository: SurgicalSchedulingRepository
  ) {}

  getSurgicalSchedulingDetails(
    params: GetSurgicalSchedulingDetails.Params
  ): Promise<GetSurgicalSchedulingDetails.Model> {
    return this.surgicalSchedulingRepository.getSurgicalSchedulingDetails(
      params
    )
  }
}
