import React, { useState } from 'react'

import { useFormik } from 'formik'
import { useHistory } from 'react-router'
import * as yup from 'yup'

import Modal from 'presentation/shared/components/Modal'
import { cpfMask, phoneMask } from 'presentation/utils/masks'
import * as S from './styles'
import 'main/config/yup'
import { UnlinkSecretary } from 'domain/usecases/secretary/unlink-secretary'
import ProfilePic from 'presentation/shared/components/ProfilePic'
import { toast } from 'react-toastify'
import { UpdateUser } from 'domain/usecases/user/update-user'
import { useServices } from 'presentation/hooks/use-services'
import { ContainerNew } from 'presentation/shared/components/ContainerNew'
import ButtonNew from 'presentation/shared/components/ButtonNew'
import TextFieldNew from 'presentation/shared/components/TextFieldNew'

type Props = {
  initialValues?: SecretaryFormValues
  secretary_id: number
  user_id?: number
  unlinkSecretary?: UnlinkSecretary
  updateSecretaryProfile: UpdateUser
}

export default function SecretaryForm({
  initialValues = {} as SecretaryFormValues,
  secretary_id,
  user_id,
  unlinkSecretary
}: Props) {
  const history = useHistory()
  const [validateDelete, setValidateDelete] = useState(false)
  const [deleteModal, setDeleteModal] = useState(false)
  const [successModal, setSuccessModal] = useState(false)

  const [enableEditSecretary, setEnableEditSecretary] = useState<boolean>(false)
  const userServices = useServices().user
  const handleForm = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    validateOnMount: true,
    onSubmit: ({ email, phone }) => {
      userServices.updateUser({
        email,
        phone: phone?.replace('+', '').replaceAll(' ', ''),
        user_id: user_id
      })
      setSuccessModal(true)
    }
  })

  const discardChanges = () => {
    handleForm.resetForm()
    setEnableEditSecretary(false)
  }

  const deleteSecretary = () => {
    try {
      unlinkSecretary?.delete({ secretary_id: secretary_id })
      setDeleteModal(true)
    } catch (error: any) {
      toast.error(error.message)
    }
  }

  return (
    <ContainerNew
      noHeader
      primaryButton={
        enableEditSecretary && (
          <ButtonNew
            size="large"
            type="button"
            onClick={() => handleForm.submitForm()}
            fullWidth
            style={{ marginBottom: '8px' }}
          >
            Salvar
          </ButtonNew>
        )
      }
      secondaryButton={
        <ButtonNew
          outlined
          size="large"
          type="button"
          onClick={() => setValidateDelete(true)}
          fullWidth
        >
          Excluir secretária
        </ButtonNew>
      }
    >
      <S.Wrapper onSubmit={handleForm.handleSubmit} role="form">
        <S.Header>
          {enableEditSecretary ? (
            <span className="edit-button" onClick={() => discardChanges()}>
              Descartar alterações
            </span>
          ) : (
            <span
              className="edit-button"
              onClick={() => setEnableEditSecretary(!enableEditSecretary)}
            >
              Editar
            </span>
          )}
        </S.Header>
        <S.Content>
          <ProfilePic
            size="large"
            name={handleForm.values?.name}
            style={{ marginBottom: '24px' }}
          />
          <TextFieldNew
            disabled
            label="Nome"
            placeholder="Seu nome"
            name="name"
            onInputChange={handleForm.handleChange('name')}
            onBlur={handleForm.handleBlur('name')}
            error={handleForm.touched.name && handleForm.errors.name}
            value={handleForm.values?.name}
          />

          <TextFieldNew
            disabled
            label="CPF"
            placeholder="000.000.000-00"
            name="cpf"
            style={{ marginTop: '20px' }}
            onInputChange={handleForm.handleChange('cpf')}
            onBlur={handleForm.handleBlur('cpf')}
            mask={cpfMask}
            value={handleForm.values?.cpf}
            error={handleForm.touched.cpf && handleForm.errors.cpf}
          />
          <TextFieldNew
            disabled={!enableEditSecretary}
            label="E-mail"
            placeholder="seuemail@gmail.com"
            style={{ marginTop: '20px' }}
            name="email"
            onInputChange={handleForm.handleChange('email')}
            onBlur={handleForm.handleBlur('email')}
            value={handleForm.values?.email}
            error={handleForm.touched.email && handleForm.errors.email}
            required
          />
          <TextFieldNew
            disabled={!enableEditSecretary}
            label="Celular"
            placeholder="+55 (xx) xxxxxxxxx"
            style={{ marginTop: '20px' }}
            mask={phoneMask}
            name="phone"
            onInputChange={handleForm.handleChange('phone')}
            onBlur={handleForm.handleBlur('phone')}
            error={handleForm.touched.phone && handleForm.errors.phone}
            value={handleForm.values?.phone}
            required
          />
        </S.Content>
        <Modal show={validateDelete}>
          <S.ConfirmationModal>
            <span>Excluir esse profissional?</span>
            <div>
              <ButtonNew type="button" onClick={() => setValidateDelete(false)}>
                Cancelar
              </ButtonNew>
              <ButtonNew outlined type="button" onClick={deleteSecretary}>
                Excluir
              </ButtonNew>
            </div>
          </S.ConfirmationModal>
        </Modal>
        <Modal
          show={deleteModal}
          title="Profissional excluido com sucesso"
          close={() => history.goBack()}
        />
        <Modal
          title="Dados salvos com sucesso"
          show={successModal}
          close={() => history.goBack()}
        />
      </S.Wrapper>
    </ContainerNew>
  )
}

export type SecretaryFormValues = {
  name?: string
  lastName?: string
  cpf?: string
  email?: string
  phone?: string
}

const validationSchema = yup.object().shape({
  name: yup.string().required(),
  email: yup.string().email().required(),
  cpf: yup.string().cpf().required(),
  phone: yup
    .string()
    .test('masked-phone-validation', 'Telefone inválido', function (val) {
      return !!(
        val?.match(/^(?:\+)[0-9]{2}\s?[0-9]{2}\s?[0-9]{9}$/) ||
        val?.match(/^[0-9]{13}$/)
      )
    })
    .required()
})
