import React, { useContext } from 'react'
import SurgicalSchedulingFinishLayout from 'presentation/doctor/layouts/SurgicalSchedulingFinish'
import { SurgicalSchedulinhContext } from 'main/factories/pages/doctor/surgical-scheduling/surgical-scheduling-factory'
import HeaderNew from 'presentation/shared/components/HeaderNew'
import { useHistory, useLocation } from 'react-router-dom'

type LocationParams = {
  schedulingId: number
}

function SurgicalSchedulingFinish() {
  const { state } = useContext<any>(SurgicalSchedulinhContext)
  const location = useLocation<LocationParams>()
  const history = useHistory()

  return (
    <>
      <HeaderNew
        actualPageTitle="Agendar cirurgia"
        actualPageOnClick={() => history.push('/home')}
      />
      <SurgicalSchedulingFinishLayout
        surgicalOrderId={state.surgical_order_id}
        schedulingId={location.state.schedulingId}
      />
    </>
  )
}

export default SurgicalSchedulingFinish
