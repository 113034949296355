import React, { useContext } from 'react'
import {
  WithLoading,
  WithLoadingProps
} from 'presentation/shared/components/HOCs/WithLoading'
import AmbulatoryOrderStatusListLayout from 'presentation/doctor/layouts/AmbulatoryOrderStatusList'
import { useServices } from 'presentation/hooks/use-services'
import { GetAmbulatoryOrders } from 'domain/usecases/ambulatory/get-ambulatory-orders'
import { AmbulatoryAuthorizationDashboardContext } from 'main/factories/pages/doctor/ambulatory-authorization/ambulatory-authorization-dashboard-factory'
import { toast } from 'react-toastify'

function AmbulatoryOrdersStatusList({ setIsLoading }: WithLoadingProps) {
  const { dispatch } = useContext<any>(AmbulatoryAuthorizationDashboardContext)

  const ambulatoryService = useServices().ambulatory

  const doctorService = useServices().doctor

  const getAmbulatoryOrders = async ({
    status
  }: GetAmbulatoryOrders.Params) => {
    setIsLoading(true)
    try {
      const response = await doctorService.loadDoctor(['doctor_id'])

      const doctorId = response.doctor_id

      const response_ambulatory_orders =
        await ambulatoryService.getAmbulatoryOrders({
          doctorId: doctorId,
          pageSize: 5,
          status: status
        })

      dispatch({
        type: 'PAGINATION',
        payload: response_ambulatory_orders.meta
      })

      dispatch({
        type: 'AMBULATORY_ORDERS_LIST_STATUS',
        payload: response_ambulatory_orders.data
      })
    } catch (error: any) {
      toast.error(
        'Não foi possível carregar os pedidos de autorização ambulatorial'
      )
    } finally {
      setIsLoading(false)
    }
  }

  const searchAmbulatoryOrders = async (params: GetAmbulatoryOrders.Params) => {
    setIsLoading(true)
    try {
      const response = await doctorService.loadDoctor(['doctor_id'])

      const doctorId = response.doctor_id

      const response_ambulatory_orders =
        await ambulatoryService.searchAmbulatoryOrders({
          doctorId: doctorId,
          pageSize: 5,
          ...params
        })

      dispatch({
        type: 'PAGINATION',
        payload: response_ambulatory_orders.meta
      })

      dispatch({
        type: 'SEARCH_AMBULATORY_ORDERS_LIST',
        payload: response_ambulatory_orders.data
      })
    } catch (error: any) {
      toast.error(
        'Não foi possível carregar os pedidos de autorização ambulatorial'
      )
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <>
      <AmbulatoryOrderStatusListLayout
        getAmbulatoryOrders={getAmbulatoryOrders}
        searchAmbulatoryOrders={searchAmbulatoryOrders}
      />
    </>
  )
}

export default WithLoading(AmbulatoryOrdersStatusList)
