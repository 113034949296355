import { makeAutoObservable } from 'mobx'
import { Patient } from 'domain/entities/patient-model'
import { SurgicalOrderOpmeModel } from 'domain/entities/surgical-order-opme-model'
import { HealthInsurancePlans } from 'domain/usecases/health-insurance/load-health-insurance-plans'
import { SurgeryCenterNew } from 'domain/entities/surgery-center-model-new'
import { HospitalNew } from 'domain/entities/hospital-model-new'
import { SurgicalOrderNew } from 'domain/entities/surgical-order-new'
import { ProcedureModel } from 'domain/entities/surgical-order-model'
import { SurgicalOrderAnesthesiologyModel } from 'domain/entities/surgical-order-anesthesiology-model'
import { SurgicalOrderCidModel } from 'domain/entities/surgical-order-cid-model'

export type LocationProps = {
  hospital: HospitalNew
  surgeryCenter: Partial<SurgeryCenterNew>
}

export type PatientProps = {
  searchInput?: string
  searchPatientList: Patient[]
  patient: Patient
}

export type HealthInsuranceProps = {
  healthInsurance: Partial<HealthInsurancePlans>
}

export type ProceduresProps = {
  procedures: ProcedureModel[]
  cids: SurgicalOrderCidModel[]
}

export type OpmeProps = SurgicalOrderOpmeModel

export type AnesthesiologyProps = SurgicalOrderAnesthesiologyModel[]

export type CooperativeProps = {
  cooperative_name?: string
}

export type SurgicalOrderProps = Partial<SurgicalOrderNew> & {
  surgical_order_id: number
}

export type ClinicalProps = {
  expected_date: string
  clinical_history: {
    allergy: string[]
    blood_transfusion: boolean
    clinical_history: string
    psychological_aspects: string
  }
  clinical_record: {
    cti: boolean
    extra_blood: boolean
    technique: string
    freeze: boolean
    hospitalization_mode: string
    patient_hospitalized: boolean
    hospitalization_type: string
    locale_hospitalization: string
    bullish_forecast_date: string
    requested_dailies: number
    medical_observation: string
    oncology_or_research: boolean
  }
}

export class SurgicalOrder {
  constructor() {
    makeAutoObservable(this)
  }

  location = {} as LocationProps
  patient = {} as PatientProps
  healthInsurance = {} as HealthInsuranceProps
  cooperative = {} as CooperativeProps
  procedures = {} as ProceduresProps
  opme = {} as OpmeProps
  anesthesiology = {} as AnesthesiologyProps
  surgicalOrder = {} as SurgicalOrderProps
  clinical = {} as ClinicalProps
  // testSurgicalOrderId = 234739718
  // clinicalHistory = {}

  getLocation() {
    return this.location
  }

  setLocation(locationData: LocationProps) {
    this.location = locationData
  }

  getPatient() {
    return this.patient
  }

  setPatient(patientData: PatientProps) {
    this.patient = patientData
  }

  getHealthInsurance() {
    return this.healthInsurance
  }

  setHealthInsurance(healthInsuranceData: HealthInsuranceProps) {
    this.healthInsurance = healthInsuranceData
  }

  getCooperative() {
    return this.cooperative
  }

  setCooperative(cooperativeData: CooperativeProps) {
    this.cooperative = cooperativeData
  }

  getProcedures() {
    return this.procedures
  }

  setProcedures(proceduresData: ProceduresProps) {
    this.procedures = proceduresData
  }

  getOpme() {
    return this.opme
  }

  setOpme(opmeData: OpmeProps) {
    this.opme = opmeData
  }

  getAnesthesiology() {
    return this.anesthesiology
  }

  setAnesthesiology(anesthesiologyData: AnesthesiologyProps) {
    this.anesthesiology = anesthesiologyData
  }

  setSurgicalOrder(surgicalOrderData: SurgicalOrderProps) {
    this.surgicalOrder = surgicalOrderData
  }

  getSurgicalOrder() {
    return this.surgicalOrder
  }

  getClinical() {
    return this.clinical
  }

  setClinical(clinicalData: ClinicalProps) {
    this.clinical = clinicalData
  }

  clear() {
    this.location = {} as LocationProps
    this.patient = {} as PatientProps
    this.healthInsurance = {} as HealthInsuranceProps
    this.cooperative = {} as CooperativeProps
    this.procedures = {} as ProceduresProps
    this.opme = {} as OpmeProps
    this.anesthesiology = {} as AnesthesiologyProps
    this.surgicalOrder = {} as SurgicalOrderProps
    this.clinical = {} as ClinicalProps
  }
}

export default SurgicalOrder
