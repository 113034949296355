import { GetAmbulatoryOrders } from 'domain/usecases/ambulatory/get-ambulatory-orders'
import { NewAmbulatoryRepository } from 'repository/interfaces/new-ambulatory-repository'

export class RemoteGetAmbulatoryOrders implements GetAmbulatoryOrders {
  constructor(private readonly ambulatoryRepository: NewAmbulatoryRepository) {}

  get(params: GetAmbulatoryOrders.Params): Promise<GetAmbulatoryOrders.Model> {
    return this.ambulatoryRepository.getAmbulatoryOrders(params)
  }
}
