import { ApiClientImplementation } from 'infra/api-client/api-client'

import { PrepareApiRequestDecorator } from 'main/decorators/prepare-api-request-decorator/prepare-api-request-decorator'
import { makeLocalStorageAdapter } from 'main/factories/cache/local-storage-adapter-factory'
import { ApiRepository } from 'repository/api-repository'
import { SurgicalOrderRepository } from 'repository/repositories/surgical-order/surgical-order-repository'
import { RemoteLoadSurgicalOrderDocument } from 'service/usecases/load-surgical-order-document/load-upload-surgical-order-document'
import SurgicalOrderService from 'presentation/contexts/services/surgical-order-service'
import { RemoteUploadSurgicalOrderDocument } from 'service/usecases/upload-surgical-order-document/remote-upload-surgical-order-document'
import { RemoteLoadSurgicalOrder } from 'service/usecases/load-surgical-order/remote-load-surgical-order'
import { RemoteLoadCid } from 'service/usecases/load-cid/remote-load-cid'
import { RemoteConcludeSurgicalOrder } from 'service/usecases/conclude-surgical-order/remote-conclude-surgical-order'

import { RemoteDeleteSurgicalOrderSolicitation } from 'service/usecases/delete-surgical-order-solicitation/remote-delete-surgical-order-solicitation'
import { RemoteUploadSurgicalOrderDocumentsSameType } from 'service/usecases/upload-surgical-order-documents-same-type/remote-upload-surgical-order-documents-same-type'
import { RemoteLoadFavoriteSurgicalOrders } from 'service/usecases/load-favorite-surgical-orders/remote-load-favorite-surgical-orders'
import { RemoteDeleteFavoritedSurgicalOrder } from 'service/usecases/delete-favorited-surgical-order/remote-delete-favorited-surgical-order'
import { RemoteCheckinSurgicalOrder } from 'service/usecases/checkin-surgical-order/remote-checkin-surgical-order'
import { RemoteLoadFavoriteDoctorSurgicalOrders } from 'service/usecases/load-favorite-doctor-orders/remote-load-favorite-doctor-orders'
import { RemoteUploadToMergeDocument } from 'service/usecases/upload-to-merge-document/remote-upload-to-merge-document'
import { RemoteConfirmMergeDocuments } from 'service/usecases/confirm-merge-documents/remote-confirm-merge-documents'
import { RemoteDeleteUploadedDocuments } from 'service/usecases/delete-uploaded-document-surgical-order/remote-delete-uploaded-document-surgical-order'
import { RemoteLoadGuidesByType } from 'service/usecases/load-guides-by-type/remote-load-guides-by-type'
import { RemoteLoadDashboardStatusCount } from 'service/usecases/load-surgical-order-dashboard-status-count/remote-load-surgical-order-dashboard-status-count'
import { RemoteLoadSurgicalOrdersByDoctorAndStatus } from 'service/usecases/load-surgical-orders-by-doctor-and-status/remote-load-surgical-orders-by-doctor-and-status'
import { RemoteLoadSurgicalOrderNoticeCard } from 'service/usecases/load-surgical-orders-notice-card/remote-load-surgical-orders-notice-card'
import { RemoteGetSurgicalOrderTimeline } from 'service/usecases/get-surgical-order-timeline/remote-get-surgical-order-timeline'
import { RemoteGetSurgicalSchedulesByDoctor } from 'service/usecases/get-surgical-schedules-by-doctor/remote-get-surgical-schedules-by-doctor'
import { RemoteGetSurgicalSchedulesCountByDoctor } from 'service/usecases/get-surgical-schedules-count-by-doctor/remote-get-surgical-schedules-count-by-doctor'
import { RemoteLoadExpirationDateStatusDashboardCounts } from 'service/usecases/load-expiration-date-status-dashboard-counts/remote-load-expiration-date-status-dashboard-counts'
import { RemoteLoadHospitalsByDoctorCpf } from 'service/usecases/load-hospitals-by-doctor-cpf/remote-load-hospitals-by-doctor-cpf'
import { HospitalRepository } from 'repository/repositories/hospital/hospital-repository'
import { RemoteLoadSurgeryCenter } from 'service/usecases/load-surgery-center/remote-load-surgery-center'
import { SurgeryCenterRepository } from 'repository/repositories/surgery-center/surgery-center-repository'
import { RemoteUpdateSurgicalOrderCooperative } from 'service/usecases/update-surgical-order-cooperative/remote-update-surgical-order-cooperative'
import { RemoteCreateSurgicalOrder } from 'service/usecases/create-surgical-order/remote-create-surgical-order'
import { RemoteGetSurgicalOrderById } from 'service/usecases/get-surgical-order-by-id/remote-get-surgical-order-by-id'
import { RemoteUploadSurgicalOrderDocumentNew } from 'service/usecases/upload-surgical-order-document-new/remote-upload-surgical-order-document'
import { RemoteSearchProcedures } from 'service/usecases/search-procedures/remote-search-procedures'
import { RemoteSearchCID } from 'service/usecases/search-cid/remote-search-cid'
import { RemoteLoadAnesthesiologies } from 'service/usecases/load-anesthesiologies/remote-load-anesthesiologies'
import { RemoteFinishSurgicalOrder } from 'service/usecases/finish-surgical-order/remote-get-surgical-order-by-id'
import { RemoteRequestSurgicalOrderCancellation } from 'service/usecases/request-surgical-order-cancellation/request-surgical-order-cancellation'
import { RemoteGetSurgicalOrderCancellationNotifications } from 'service/usecases/get-surgical-order-cancellation/get-surgical-order-cancellation'
import { RemoteGetSurgicalOrderCancellationNotificationsCount } from 'service/usecases/get-surgical-order-cancellation-count/get-surgical-order-cancellation-count'
import { RemoteAddSurgicalOrderCancellationComment } from 'service/usecases/add-surgical-order-cancellation-comment/add-surgical-order-cancellation-comment'
import { RemoteConfirmReadCancellationRequests } from 'service/usecases/read-cancellation-requests/read-cancellation-requests'

export default function makeSurgicalOrderService() {
  const apiRepository = new PrepareApiRequestDecorator(
    makeLocalStorageAdapter(),
    new ApiRepository(new ApiClientImplementation())
  )

  const surgicalOrderRepository = new SurgicalOrderRepository(apiRepository)
  const hospitalRepository = new HospitalRepository(apiRepository)
  const surgeryCenterRepository = new SurgeryCenterRepository(apiRepository)

  const loadSurgicalOrderDocument = new RemoteLoadSurgicalOrderDocument(
    surgicalOrderRepository
  )
  const uploadSurgicalOrderDocument = new RemoteUploadSurgicalOrderDocument(
    surgicalOrderRepository
  )
  const loadSurgicalOrder = new RemoteLoadSurgicalOrder(surgicalOrderRepository)
  const loadCids = new RemoteLoadCid(surgicalOrderRepository)
  const concludeSurgicalOrder = new RemoteConcludeSurgicalOrder(
    surgicalOrderRepository
  )
  const deleteSurgicalOrderSolicitation =
    new RemoteDeleteSurgicalOrderSolicitation(surgicalOrderRepository)
  const checkin = new RemoteCheckinSurgicalOrder(surgicalOrderRepository)

  const uploadSurgicalOrderDocumentsSameType =
    new RemoteUploadSurgicalOrderDocumentsSameType(surgicalOrderRepository)

  const loadFavoriteSurgicalOrders = new RemoteLoadFavoriteSurgicalOrders(
    surgicalOrderRepository
  )

  const loadFavoriteDoctorSurgicalOrders =
    new RemoteLoadFavoriteDoctorSurgicalOrders(surgicalOrderRepository)

  const deleteFavoritedSurgicalOrder = new RemoteDeleteFavoritedSurgicalOrder(
    surgicalOrderRepository
  )

  const uploadToMergeDocument = new RemoteUploadToMergeDocument(
    surgicalOrderRepository
  )

  const confirmMergeDocuments = new RemoteConfirmMergeDocuments(
    surgicalOrderRepository
  )

  const deleteUploadedDocuments = new RemoteDeleteUploadedDocuments(
    surgicalOrderRepository
  )

  const loadGuidesByType = new RemoteLoadGuidesByType(surgicalOrderRepository)

  const loadDashboardStatusCount = new RemoteLoadDashboardStatusCount(
    surgicalOrderRepository
  )

  const loadSurgicalOrdersByDoctorAndStatus =
    new RemoteLoadSurgicalOrdersByDoctorAndStatus(surgicalOrderRepository)

  const loadSurgicalOrderNoticeCard = new RemoteLoadSurgicalOrderNoticeCard(
    surgicalOrderRepository
  )

  const getSurgicalSchedulesByDoctor = new RemoteGetSurgicalSchedulesByDoctor(
    surgicalOrderRepository
  )

  const getSurgicalSchedulesCountByDoctor =
    new RemoteGetSurgicalSchedulesCountByDoctor(surgicalOrderRepository)

  const getSurgicalOrderTimeline = new RemoteGetSurgicalOrderTimeline(
    surgicalOrderRepository
  )

  const loadExpirationDateStatusDashboardCounts =
    new RemoteLoadExpirationDateStatusDashboardCounts(surgicalOrderRepository)

  const loadHospitalsByDoctorCpf = new RemoteLoadHospitalsByDoctorCpf(
    hospitalRepository
  )

  const loadSurgeryCenters = new RemoteLoadSurgeryCenter(
    surgeryCenterRepository
  )

  const updateSurgicalOrderNew = new RemoteUpdateSurgicalOrderCooperative(
    surgicalOrderRepository
  )

  const createSurgicalOrder = new RemoteCreateSurgicalOrder(
    surgicalOrderRepository
  )

  const getSurgicalOrderById = new RemoteGetSurgicalOrderById(
    surgicalOrderRepository
  )

  const uploadSurgicalOrderDocumentNew =
    new RemoteUploadSurgicalOrderDocumentNew(surgicalOrderRepository)

  const deleteUploadedDocumentsNew = new RemoteDeleteUploadedDocuments(
    surgicalOrderRepository
  )

  const searchProcedures = new RemoteSearchProcedures(surgicalOrderRepository)

  const searchCID = new RemoteSearchCID(surgicalOrderRepository)

  const loadAnesthesiologies = new RemoteLoadAnesthesiologies(
    surgicalOrderRepository
  )

  const finishSurgicalOrder = new RemoteFinishSurgicalOrder(
    surgicalOrderRepository
  )

  const requestSurgicalOrderCancellation =
    new RemoteRequestSurgicalOrderCancellation(surgicalOrderRepository)

  const getSurgicalOrderCancellationNotifications =
    new RemoteGetSurgicalOrderCancellationNotifications(surgicalOrderRepository)

  const getSurgicalOrderCancellationNotificationsCount =
    new RemoteGetSurgicalOrderCancellationNotificationsCount(
      surgicalOrderRepository
    )

  const addSurgicalOrderCancellationComment =
    new RemoteAddSurgicalOrderCancellationComment(surgicalOrderRepository)

  const confirmReadCancellationRequests =
    new RemoteConfirmReadCancellationRequests(surgicalOrderRepository)

  return new SurgicalOrderService({
    loadSurgicalOrderDocument,
    uploadSurgicalOrderDocument,
    loadSurgicalOrder,
    loadCids,
    concludeSurgicalOrder,
    deleteSurgicalOrderSolicitation,
    uploadSurgicalOrderDocumentsSameType,
    loadFavoriteSurgicalOrders,
    loadFavoriteDoctorSurgicalOrders,
    deleteFavoritedSurgicalOrder,
    checkin,
    uploadToMergeDocument,
    confirmMergeDocuments,
    deleteUploadedDocuments,
    loadGuidesByType,
    loadDashboardStatusCount,
    loadSurgicalOrdersByDoctorAndStatus,
    loadSurgicalOrderNoticeCard,
    getSurgicalSchedulesByDoctor,
    getSurgicalSchedulesCountByDoctor,
    getSurgicalOrderTimeline,
    loadExpirationDateStatusDashboardCounts,
    loadHospitalsByDoctorCpf,
    loadSurgeryCenters,
    updateSurgicalOrderNew,
    createSurgicalOrder,
    getSurgicalOrderById,
    uploadSurgicalOrderDocumentNew,
    deleteUploadedDocumentsNew,
    searchProcedures,
    searchCID,
    loadAnesthesiologies,
    finishSurgicalOrder,
    requestSurgicalOrderCancellation,
    getSurgicalOrderCancellationNotifications,
    getSurgicalOrderCancellationNotificationsCount,
    addSurgicalOrderCancellationComment,
    confirmReadCancellationRequests
  })
}
