import React, { useState, useContext } from 'react'
import { ContainerNew } from 'presentation/shared/components/ContainerNew'
import { ReactComponent as RightArrow } from 'presentation/assets/icons/v-arrow.svg'
import ButtonNew from 'presentation/shared/components/ButtonNew'
import SupportTextNew from 'presentation/shared/components/SupportTextNew'
import * as S from './styles'
import HeadingNew from 'presentation/shared/components/HeadingNew'
import TabsNew from 'presentation/shared/components/TabsNew'
import SurgicalOrderCard from 'presentation/shared/layouts/SurgicalOrderCard'
import { useHistory } from 'react-router'
import { SurgicalOrderContext } from 'main/factories/pages/doctor/surgical-order/surgical-order-factory'
import TimelineStatusModal from './Modals/TimelineStatusModal'
import moment from 'moment'
import {
  SurgicalOrdersStatus,
  SurgicalOrdersStatusSingular
} from 'common/enum/surgical-orders-status'
import PendenciesTab from './Tabs/PendenciesTab'
import PlanningTab from './Tabs/PlanningTab'
import TimelineTab from './Tabs/TimelineTab'
import theme from 'presentation/styles/theme'
import SheetModalNew from 'presentation/shared/components/SheetModalNew'
import Divider from 'presentation/shared/components/DividerNew'
import TextAreaNew from 'presentation/shared/components/TextAreaNew'
import { capitalizeEachWordText } from 'common/utils/capitalizeEachWordText'
import { useServices } from 'presentation/hooks/use-services'
import { toast } from 'react-toastify'
import { WithLoadingProps } from 'presentation/shared/components/HOCs/WithLoading'
import { useFormik } from 'formik'
import * as yup from 'yup'

export const getDateTextFromNow = (date: Date | string) => {
  const dateUtc = moment.utc(date).format('YYYY-MM-DD HH:mm:ss')
  return moment(dateUtc).fromNow()
}

export const getColor = (status: keyof typeof SurgicalOrdersStatus) => {
  switch (status) {
    case SurgicalOrdersStatus.AUTHORIZED:
    case SurgicalOrdersStatus.CONCLUDED:
      return {
        main: 'success700',
        lighten: 'success200'
      }
    case SurgicalOrdersStatus.UNDER_REVIEW_AT_THE_HOSPITAL:
    case SurgicalOrdersStatus.UNDER_REVIEW_AT_THE_INSURANCE:
      return {
        main: 'secondary700',
        lighten: 'secondary200'
      }
    case SurgicalOrdersStatus.ORDER_OPEN:
    case SurgicalOrdersStatus.CANCELLED:
    case SurgicalOrdersStatus.REQUESTED_CANCELLATION:
    case SurgicalOrdersStatus.PARTIALLY_AUTHORIZED:
      return {
        main: 'primary600',
        lighten: 'primary600'
      }
    case SurgicalOrdersStatus.DENIED:
      return {
        main: 'attention900',
        lighten: 'attention900'
      }
    default:
      return {
        main: 'neutral700',
        lighten: 'neutral200'
      }
  }
}

type Props = {
  getSurgicalOrder: () => Promise<void>
  getSurgicalOrderTimeline: () => Promise<void>
} & WithLoadingProps

export default function SurgicalSchedulingInformationsLayout({
  setIsLoading,
  getSurgicalOrder,
  getSurgicalOrderTimeline
}: Props) {
  const history = useHistory()
  const [isOpen, setIsOpen] = useState(false)
  const { state } = useContext<any>(SurgicalOrderContext)
  const [isCancellationRequestOpen, setIsCancellationRequestOpen] =
    useState(false)
  const surgicalOrderService = useServices().surgicalOrder

  const goToAnalysis = () => {
    setIsOpen(true)
  }

  const timelineItemsActions = {
    REQUESTED_CANCELLATION_SURGICAL_ORDER: goToAnalysis
  }

  const Tabs = [
    {
      label: 'Pendências',
      componentChildren: <PendenciesTab />
    },
    {
      label: 'Linha do tempo',
      componentChildren: (
        <TimelineTab timelineItemsActions={timelineItemsActions} />
      )
    },
    {
      label: 'Planejamento',
      componentChildren: <PlanningTab />
    }
  ]

  const openRequestCancellationModal = () => {
    setIsCancellationRequestOpen(true)
  }

  const scheduleSurgery = () => {
    history.push('/agendamento-cirurgico/inicio', {
      surgical_order_id: state.card.surgical_order_id
    })
  }

  const refreshSurgicalOrder = async () => {
    await getSurgicalOrder()
    await getSurgicalOrderTimeline()
  }

  const formik = useFormik({
    initialValues: {
      cancellation_reason: ''
    },
    validateOnChange: true,
    validateOnMount: true,
    validationSchema,
    onSubmit: async (values) => {
      try {
        setIsLoading(true)
        await surgicalOrderService.requestSurgicalOrderCancellation({
          surgical_order_id: state.card.surgical_order_id,
          reason: values.cancellation_reason
        })
        await refreshSurgicalOrder()
      } catch (error) {
        toast.error(
          'Ocorreu um erro ao solicitar cancelamento do pedido cirúrgico'
        )
      } finally {
        setIsLoading(false)
        setIsCancellationRequestOpen(false)
      }
    }
  })

  return (
    <ContainerNew gap="16px">
      <HeadingNew color="primary600" size="large">
        Detalhes do pedido cirúrgico
      </HeadingNew>

      <SurgicalOrderCard
        surgicalOrder={state.card}
        hideFooter
        hideStatusTag
        procedures
        schedulingDate
        statusTag
      />

      {state.card.active_status !== 'ORDER_OPEN' && (
        <S.StatusCard
          background="neutral25"
          shadow="satin"
          onClick={goToAnalysis}
        >
          <div>
            <SupportTextNew
              color={
                getColor(state.card.active_status)
                  .main as keyof typeof theme.ds.colors
              }
              weight="bold"
              size="large"
              className="capitalize-fl"
            >
              {
                SurgicalOrdersStatusSingular[
                  state.footer
                    .status as keyof typeof SurgicalOrdersStatusSingular
                ]
              }
            </SupportTextNew>
            {state.card.active_status === 'REQUESTED_CANCELLATION' ? (
              <SupportTextNew size="small">
                Solicitação de cancelamento enviada{' '}
                {getDateTextFromNow(
                  state.card.requested_cancellation?.props?.created_at
                )}{' '}
                por{' '}
                {capitalizeEachWordText(
                  state.card.requested_cancellation?.props?.requested_by.name
                )}
                .
              </SupportTextNew>
            ) : (
              <SupportTextNew size="medium">
                Pedido criado {getDateTextFromNow(state.footer.created_at)}
              </SupportTextNew>
            )}
          </div>
          <SupportTextNew color="secondary700" weight="bold" size="large">
            <RightArrow style={{ marginLeft: '16px' }} />
          </SupportTextNew>
        </S.StatusCard>
      )}

      {state.card.scheduling_status === 'UNSCHEDULED' && (
        <ButtonNew fullWidth size="large" onClick={scheduleSurgery}>
          Agendar cirurgia
        </ButtonNew>
      )}

      {state.card.active_status !== SurgicalOrdersStatus.CANCELLED &&
        state.card.active_status !== SurgicalOrdersStatus.CONCLUDED &&
        state.card.active_status !==
          SurgicalOrdersStatus.REQUESTED_CANCELLATION && (
          <ButtonNew
            fullWidth
            size="large"
            outlined
            onClick={openRequestCancellationModal}
          >
            Cancelar pedido cirúrgico
          </ButtonNew>
        )}

      <S.TabsWrapper>
        <TabsNew tabItems={Tabs} />
      </S.TabsWrapper>

      <TimelineStatusModal
        setIsLoading={setIsLoading}
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        status={state.footer.status}
        created_at={getDateTextFromNow(state.footer.created_at)}
        timeline={state.card.status}
        cancellation_request={state.card.requested_cancellation?.props}
        refreshSurgicalOrder={refreshSurgicalOrder}
      />

      <SheetModalNew
        isOpen={isCancellationRequestOpen}
        onClose={() => setIsCancellationRequestOpen(false)}
        title="Cancelar pedido cirúrgico"
      >
        <SupportTextNew
          weight="semiBold"
          size="small"
          color="neutral900"
          style={{ marginBottom: '24px' }}
        >
          Tem certeza que deseja cancelar este pedido cirúrgico?
        </SupportTextNew>
        <SupportTextNew size="small" color="neutral900">
          Esta <b>solicitação</b> será recebida pela CRMO que cuidará do
          processo de cancelamento do seu pedido.
        </SupportTextNew>
        <Divider />
        <TextAreaNew
          label="Qual o motivo do cancelamento?"
          required
          requiredColor="neutral600"
          placeholder="Descreva o motivo aqui."
          style={{ marginBottom: '24px' }}
          id="cancellation_reason"
          name="cancellation_reason"
          value={formik.values.cancellation_reason}
          onChange={formik.handleChange}
          error={formik.errors.cancellation_reason}
        />
        <ButtonNew
          fullWidth
          size="large"
          disabled={!!formik.errors.cancellation_reason}
          onClick={formik.submitForm}
        >
          Cancelar pedido cirúrgico
        </ButtonNew>
      </SheetModalNew>
    </ContainerNew>
  )
}

const validationSchema = yup.object().shape({
  cancellation_reason: yup
    .string()
    .required()
    .max(200, 'Máximo 200 caracteres.')
})
