import React from 'react'
import { ContainerNew } from 'presentation/shared/components/ContainerNew'
import HeadingNew from 'presentation/shared/components/HeadingNew'
import ButtonNew from 'presentation/shared/components/ButtonNew'
// import DividerNew from 'presentation/shared/components/DividerNew'
import SupportTextNew from 'presentation/shared/components/SupportTextNew'
import SurgicalOrderCard from 'presentation/shared/layouts/SurgicalOrderCard'
import { ReactComponent as EditIcon } from 'presentation/assets/icons/edit-icon.svg'
import { useHistory } from 'react-router'
import {
  SendSurgicalSchedulingModel,
  AppointmentModel
} from 'domain/usecases/surgical-scheduling/send-surgical-scheduling'
import CardNew from 'presentation/shared/components/CardNew'
import * as S from './style'
import moment from 'moment'
import { Booleans } from 'common/enum/booleans'
import { Anesthesia } from 'common/enum/anesthesia'
import { Techniques } from 'common/enum/techniques'

type Props = {
  state: SendSurgicalSchedulingModel
  sendSurgicalScheduling: (params: SendSurgicalSchedulingModel) => void
}

export default function SurgicalSchedulingSummaryLayout({
  state,
  sendSurgicalScheduling
}: Props) {
  const history = useHistory()
  const submitForm = () => {
    sendSurgicalScheduling(state)
    history.push('/agendamento-cirurgico/resumo')
  }

  const submitButton = () => {
    return (
      <ButtonNew fullWidth size="large" onClick={() => submitForm()}>
        Solicitar agendamento
      </ButtonNew>
    )
  }

  const editItem = (step: string) => {
    history.push(`/agendamento-cirurgico/${step}`, { isReviewing: true })
  }

  const formatDateTime = ({ hour, start_date, any_time }: AppointmentModel) => {
    if (start_date) {
      const formatedDateTime = moment(
        `${start_date}${!any_time ? ':' + hour : ''}`,
        ['YYYY-MM-DD:HH:mm', 'YYYY-MM-DD']
      ).format(`dddd - DD MMM[.] ${any_time ? '' : '[às] HH:mm'}`)
      return formatedDateTime.replace('-feira', '')
    }
    return ''
  }

  const booleansLabel = (value = false) => {
    return Booleans[String(value) as keyof typeof Booleans]
  }

  return (
    <ContainerNew primaryButton={submitButton()} gap="24px" noHeader>
      <div>
        <HeadingNew color="primary600" size="large">
          Solicitar agendamento
        </HeadingNew>
        <SupportTextNew>
          Revise as informações para solicitar o agendamento.
        </SupportTextNew>
      </div>

      <SurgicalOrderCard
        procedures
        schedulingDate
        statusTag
        surgicalOrder={{
          ...state.surgical_order,
          patient: state.patient,
          hospital: {
            name: state.surgical_order.hospital_name,
            id: state.surgical_order.hospital_id
          },
          scheduling_status: 'analyze',
          surgical_order_id: state.surgical_order.id
          // scheduling_date: state.appointments.map((appointment) => ({
          //   requested_date: appointment.start_date
          // }))
        }}
        flatten
      />

      <div>
        <S.HeadinEdit color="neutral500" size="large">
          Data solicitada
          <EditIcon onClick={() => editItem('data-e-hora')} />
        </S.HeadinEdit>

        <S.CardsGroup>
          <CardNew
            shadow="none"
            background="neutral50"
            padding="8px 16px"
            className="capitalize-fl"
          >
            <SupportTextNew color="primary600" weight="bold">
              {formatDateTime(state.appointments[0])}{' '}
              <SupportTextNew as="span" size="xsmall">
                (Preferencial)
              </SupportTextNew>
            </SupportTextNew>
          </CardNew>
          {state.appointments[1]?.start_date && (
            <CardNew
              shadow="none"
              background="neutral50"
              padding="8px 16px"
              className="capitalize-fl"
            >
              <SupportTextNew color="primary600" weight="bold">
                {formatDateTime(state.appointments[1])}
              </SupportTextNew>
            </CardNew>
          )}
        </S.CardsGroup>
      </div>

      <div>
        <S.HeadinEdit color="neutral500" size="large">
          Tempo de cirurgia
          <EditIcon onClick={() => editItem('duracao')} />
        </S.HeadinEdit>

        <S.CardsGroup columns={2}>
          <CardNew shadow="none" background="neutral50" padding="8px 16px">
            <SupportTextNew textAligh="center">Previsto</SupportTextNew>
            <SupportTextNew
              color="primary600"
              weight="bold"
              textAligh="center"
              size="large"
            >
              {state.time.predicted_time.replace(':', 'h')}
            </SupportTextNew>
          </CardNew>
          <CardNew shadow="none" background="neutral50" padding="8px 16px">
            <SupportTextNew textAligh="center">Solicitado</SupportTextNew>
            <SupportTextNew
              color="primary600"
              weight="bold"
              textAligh="center"
              size="large"
            >
              {state.time.requested_time?.replace(':', 'h') || '--:--'}
            </SupportTextNew>
          </CardNew>
        </S.CardsGroup>
      </div>

      <div>
        <S.HeadinEdit color="neutral500" size="large">
          Anestesia
          <EditIcon onClick={() => editItem('anestesia')} />
        </S.HeadinEdit>

        <S.CardsGroup>
          <CardNew shadow="none" background="neutral50" padding="8px 16px">
            <SupportTextNew textAligh="center" size="xsmall">
              Anestesia
            </SupportTextNew>
            <SupportTextNew color="primary600" weight="bold" textAligh="center">
              {Anesthesia[state.anesthetist.type as keyof typeof Anesthesia]}
            </SupportTextNew>
          </CardNew>
          {state.anesthetist.type === 'choose-anesthetist' && (
            <CardNew shadow="none" background="neutral50" padding="8px 16px">
              <SupportTextNew textAligh="center" size="xsmall">
                Nome
              </SupportTextNew>
              <SupportTextNew
                color="primary600"
                weight="bold"
                textAligh="center"
                capitalize
              >
                {state.anesthetist.name.toLowerCase()}
              </SupportTextNew>
            </CardNew>
          )}
        </S.CardsGroup>
      </div>

      <div>
        <S.HeadinEdit color="neutral500" size="large">
          Equipamentos
          <EditIcon onClick={() => editItem('equipamentos')} />
        </S.HeadinEdit>

        <S.CardsGroup>
          <CardNew shadow="none" background="neutral50" padding="8px 16px">
            <SupportTextNew>
              Deseja incluir equipamento?{' '}
              <SupportTextNew color="primary600" weight="bold" as="span">
                {booleansLabel(Boolean(state.equipaments.length))}
              </SupportTextNew>
            </SupportTextNew>
          </CardNew>
          {state.equipaments.map((equipament) => (
            <CardNew
              shadow="none"
              background="neutral50"
              padding="8px 16px"
              key={equipament.code}
            >
              <SupportTextNew
                color="primary600"
                weight="bold"
                className="capitalize-fl"
              >
                {equipament.name.toLocaleLowerCase()}
              </SupportTextNew>
              <SupportTextNew size="xsmall">
                {equipament.code ? `Código ${equipament.code}` : 'Sem código'}
              </SupportTextNew>
            </CardNew>
          ))}
        </S.CardsGroup>
      </div>

      <div>
        <S.HeadinEdit color="neutral500" size="large">
          Confirmar informações
          <EditIcon onClick={() => editItem('informacoes')} />
        </S.HeadinEdit>

        <S.CardsGroup columns={2}>
          <CardNew shadow="none" background="neutral50" padding="8px 16px">
            <SupportTextNew textAligh="center" size="xsmall">
              Unidade cirúrgica
            </SupportTextNew>
            <SupportTextNew
              color="primary600"
              weight="bold"
              textAligh="center"
              className="capitalize-fl"
            >
              {state.surgical_order.surgery_center_name}
            </SupportTextNew>
          </CardNew>
          <CardNew shadow="none" background="neutral50" padding="8px 16px">
            <SupportTextNew textAligh="center" size="xsmall">
              Paciente internado
            </SupportTextNew>
            <SupportTextNew color="primary600" weight="bold" textAligh="center">
              {booleansLabel(state.surgical_order.patient_internal)}
            </SupportTextNew>
          </CardNew>
          <CardNew shadow="none" background="neutral50" padding="8px 16px">
            <SupportTextNew textAligh="center" size="xsmall">
              Reserva de sangue
            </SupportTextNew>
            <SupportTextNew color="primary600" weight="bold" textAligh="center">
              {booleansLabel(state.surgical_order.blood_reserve)}
            </SupportTextNew>
          </CardNew>
          <CardNew shadow="none" background="neutral50" padding="8px 16px">
            <SupportTextNew textAligh="center" size="xsmall">
              Vai precisar de CTI
            </SupportTextNew>
            <SupportTextNew color="primary600" weight="bold" textAligh="center">
              {booleansLabel(state.surgical_order.cti)}
            </SupportTextNew>
          </CardNew>
          <CardNew shadow="none" background="neutral50" padding="8px 16px">
            <SupportTextNew textAligh="center" size="xsmall">
              Técnica
            </SupportTextNew>
            <SupportTextNew color="primary600" weight="bold" textAligh="center">
              {
                Techniques[
                  state.surgical_order.tecnic as keyof typeof Techniques
                ]
              }
            </SupportTextNew>
          </CardNew>
          <CardNew shadow="none" background="neutral50" padding="8px 16px">
            <SupportTextNew textAligh="center" size="xsmall">
              Congelação
            </SupportTextNew>
            <SupportTextNew color="primary600" weight="bold" textAligh="center">
              {booleansLabel(state.surgical_order.freezing)}
            </SupportTextNew>
          </CardNew>
        </S.CardsGroup>
      </div>

      <div>
        <S.HeadinEdit color="neutral500" size="large">
          Observação geral
          <EditIcon onClick={() => editItem('observacoes')} />
        </S.HeadinEdit>

        <S.CardsGroup>
          <CardNew shadow="none" background="neutral50" padding="8px 16px">
            <SupportTextNew style={{ whiteSpace: 'pre-wrap' }}>
              {state.general_observation || 'Nenhuma observação'}
            </SupportTextNew>
          </CardNew>
        </S.CardsGroup>
      </div>
    </ContainerNew>
  )
}
