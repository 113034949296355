import styled from 'styled-components'

export const FlexInfos = styled.div`
  /* display: grid;
  grid-template-columns: 1fr 1fr; */
  display: flex;
  flex-direction: column;
  margin-top: 8px;
`
export const CidsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1200px;
  padding-right: 70px;
`
export const OpmeWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 12px;
  max-width: 1200px;
`
export const ProcedureWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 12px;
  max-width: 1200px;
  padding-right: 70px;
`
