import styled from 'styled-components'

export const Wrapper = styled.form`
  flex: 1;
  display: flex;
  flex-direction: column;
  h1 {
    margin-top: 10px;
    font-weight: 700;
    line-height: 140%;
  }
`

export const Step = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`

export const Content = styled.div`
  flex: 1;
  justify-content: center;
  display: flex;
  flex-direction: column;
`

export const Buttons = styled.div`
  width: 100%;
  text-align: center;

  button {
    margin-bottom: 10px;
  }

  span {
    padding: 10px 20%;
  }
`
