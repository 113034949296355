import { TextArea } from 'presentation/doctor/layouts/AnswerSurgicalPendency/styles'
import SelectField from 'presentation/shared/components/SelectField'
import { therapeuticPlanPurposeOptions } from 'presentation/utils/therapeutic-plan-purpose'
import { therapeuticPlanTreatmentRegimenOptions } from 'presentation/utils/therapeutic-plan-treatment-regimen'
import React from 'react'
import * as S from './styles'

export default function ProposedProtocolStepThree() {
  return (
    <>
      <S.FormContentStepThree>
        <SelectField
          label="Finalidade"
          required
          name="purpose"
          items={therapeuticPlanPurposeOptions}
          placeholder={''}
        />
        <SelectField
          label="Regime de tratamento"
          required
          name="treatmentRegimen"
          items={therapeuticPlanTreatmentRegimenOptions}
          placeholder={''}
        />
        <TextArea
          required
          name="procedureQuantity"
          rows={4}
          label="Procedimento e quantidade"
        />
      </S.FormContentStepThree>
    </>
  )
}
