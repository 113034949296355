import React, { useContext, useEffect, useState } from 'react'
import AvatarCountersListNew from 'presentation/shared/components/AvatarCountersListNew'
import { SurgicalOrderDashboardContext } from 'main/factories/pages/doctor/surgical-order/surgical-order-dashboard-factory'
import * as S from './styles'
// import AutoCompleteNew from 'presentation/shared/components/AutoCompleteNew'
import TextFieldNew from 'presentation/shared/components/TextFieldNew'
import { ContainerNew } from 'presentation/shared/components/ContainerNew'
import { ReactComponent as SearchIcon } from 'presentation/assets/icons/search2.svg'
import SupportTextNew from 'presentation/shared/components/SupportTextNew'
import AvatarNew from 'presentation/shared/components/AvatarNew'
import SurgicalOrderCard from 'presentation/shared/layouts/SurgicalOrderCard'
import theme from 'presentation/styles/theme'
import {
  PendencieTypes,
  LoadSurgicalOrdersByPendencyStatus
} from 'domain/usecases/surgical-pendency/load-surgical-orders-by-pendency-status'
import ButtonNew from 'presentation/shared/components/ButtonNew'
import { ReactComponent as EmptyStateIcon } from 'presentation/assets/icons/empty-state-happy.svg'
import { useHistory } from 'react-router'
import { PendencyStatusPlural } from 'common/enum/pendency-status'

type Props = {
  initialStatus?: PendencieTypes
  getPendenciesList: (params: LoadSurgicalOrdersByPendencyStatus.Params) => void
  getPendencyCount: (search?: string) => void
}

export default function SurgicalOrdersPendenciesListLayout({
  initialStatus,
  getPendenciesList,
  getPendencyCount
}: Props) {
  const { state } = useContext<any>(SurgicalOrderDashboardContext)
  const [activePendencyType, setActivePendencyType] = useState<PendencieTypes>(
    initialStatus || 'PENDENT'
  )
  const [searchTerm, setSearchTerm] = useState('')
  const history = useHistory()

  useEffect(() => {
    setActivePendencyType(initialStatus || 'PENDENT')
  }, [])

  const pendenciesTypes = () => [
    {
      key: 'PENDENT',
      label: PendencyStatusPlural.PENDENT,
      color: 'terciary400' as keyof typeof theme.ds.colors,
      active: activePendencyType === 'PENDENT',
      action: () => changePendencyType('PENDENT')
    },
    {
      key: 'IN_PROGRESS',
      label: PendencyStatusPlural.IN_PROGRESS,
      color: 'secondary400' as keyof typeof theme.ds.colors,
      active: activePendencyType === 'IN_PROGRESS',
      action: () => changePendencyType('IN_PROGRESS')
    },
    {
      key: 'RESOLVED',
      label: PendencyStatusPlural.RESOLVED,
      color: 'primary400' as keyof typeof theme.ds.colors,
      active: activePendencyType === 'RESOLVED',
      action: () => changePendencyType('RESOLVED')
    }
  ]

  const getCounterType = () => {
    return pendenciesTypes().find((type) => {
      return type.key === activePendencyType
    })
  }

  const changePendencyType = (type: PendencieTypes) => {
    if (activePendencyType !== type) {
      setSearchTerm('')
      setActivePendencyType(type)
      getPendencyCount()
      // searchPendencies()
      getPendenciesList({ status: type, pageNumber: 1 })
    }
  }

  const searchPendencies = (nextPage = false) => {
    getPendencyCount(searchTerm)
    getPendenciesList({
      status: activePendencyType,
      search: searchTerm,
      pageNumber: nextPage ? state.pageInfo.current_page + 1 : 1
    })
  }

  const isLastPage = () => {
    return state.pageInfo?.current_page === state.pageInfo?.total_pages
  }

  const goToSurgicalOrder = (surgical_order_id: number) => {
    history.push('/pedido-cirurgico/detalhes', {
      surgical_order_id
    })
  }

  const onEnterSearch = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.keyCode === 13) {
      searchPendencies()
    }
  }

  return (
    <ContainerNew gap="32px" noHeader>
      <AvatarCountersListNew
        title="Pendências"
        subtitle="Acompanhe as suas pendências"
        types={pendenciesTypes()}
        counts={state.pendencyCount}
        disableHoverOnActive
      />
      <TextFieldNew
        icon={<SearchIcon />}
        iconIsButton
        placeholder="Paciente, N° do aviso"
        value={searchTerm}
        onInputChange={setSearchTerm}
        onIconClick={() => searchPendencies()}
        onKeyDown={onEnterSearch}
      />
      <S.PendenciesContainer>
        <S.PendenciesHeader>
          <AvatarNew
            text={state.pendencyCountFiltered[activePendencyType]}
            color={
              (getCounterType()?.color as keyof typeof theme.ds.colors) ||
              'primary400'
            }
            active
          />
          <SupportTextNew
            weight="bold"
            color="neutral700"
            size="medium"
            className="capitalize-fl"
          >
            {getCounterType()?.label}
          </SupportTextNew>
        </S.PendenciesHeader>
        <SupportTextNew>
          Responda interações pendentes em seus pedidos
        </SupportTextNew>
        {state.surgicalOrdersList?.length ? (
          <>
            {state.surgicalOrdersList?.map((surgicalOrder: any) => {
              return (
                <SurgicalOrderCard
                  procedures
                  schedulingDate
                  statusTag
                  key={surgicalOrder.surgical_order_id}
                  surgicalOrder={surgicalOrder}
                  footerColor={getCounterType()?.color}
                  onClick={goToSurgicalOrder}
                />
              )
            })}
            <ButtonNew
              variant="text"
              onClick={() => searchPendencies(true)}
              disabled={isLastPage()}
            >
              {isLastPage() ? 'Fim' : 'Ver mais'}
            </ButtonNew>
          </>
        ) : (
          <S.EmptyState>
            <EmptyStateIcon />
            <SupportTextNew color="neutral400" size="medium">
              Nenhum pedido cirurgico encontrado
            </SupportTextNew>
          </S.EmptyState>
        )}
      </S.PendenciesContainer>
    </ContainerNew>
  )
}
