import { UploadSurgicalOrderDocumentNew } from 'domain/usecases/surgical-order/upload-surgical-order-document-new'
import { SurgicalOrderRepository } from 'repository/interfaces/surgical-order-repository'

export class RemoteUploadSurgicalOrderDocumentNew
  implements UploadSurgicalOrderDocumentNew
{
  constructor(
    private readonly surgicalOrderRepository: SurgicalOrderRepository
  ) {}
  upload(
    params: UploadSurgicalOrderDocumentNew.Params
  ): Promise<UploadSurgicalOrderDocumentNew.Model> {
    return this.surgicalOrderRepository.uploadSurgicalOrderDocumentNew(params)
  }
}
