import React from 'react'
import StatusChip from '../StatusChip'
import * as S from './styles'

type Props = {
  concluded?: boolean
}

export default function TherapeuticPlanCard({ concluded = false }: Props) {
  return (
    <S.Wrapper concluded={concluded}>
      <div>
        <h3>PLANO TERAPÊUTICO 1</h3>
        <StatusChip
          label="Concluído"
          fontSize="xxsmall"
          color={concluded ? 'lightGray' : 'orange'}
        />
      </div>
      <S.PatientInfoContainer>
        <S.PatientInfo>
          <div>
            <strong>Tipo de tratamento: </strong>
            <label>Inicial</label>
          </div>
        </S.PatientInfo>
        <S.PatientInfo>
          <div>
            <strong>N° total de ciclos: </strong>
            <label>11</label>
          </div>
        </S.PatientInfo>
        <S.PatientInfo>
          <div>
            <strong>Ciclo atual: </strong>
            <label>20</label>
          </div>
        </S.PatientInfo>
      </S.PatientInfoContainer>
    </S.Wrapper>
  )
}
