import React from 'react'
import { SchedulingTypes } from 'domain/usecases/surgical-order/load-surgical-orders-by-doctor-and-status'
import { PendencieTypes } from 'domain/usecases/surgical-pendency/load-surgical-orders-by-pendency-status'
import * as S from './styles'
import theme from 'presentation/styles/theme'
import { SurgicalOrdersStatusSingular } from 'common/enum/surgical-orders-status'
import { PendencyStatusSingular } from 'common/enum/pendency-status'

type Props = {
  status: SchedulingTypes | PendencieTypes
  type?: 'scheduling' | 'pendency'
}

const SchediligStatusCard = ({ status, type = 'scheduling' }: Props) => {
  const getTranslatedLabel = () => {
    switch (type) {
      case 'pendency':
        return PendencyStatusSingular[status as PendencieTypes]
      case 'scheduling':
      default:
        return SurgicalOrdersStatusSingular[status as SchedulingTypes]
    }
  }

  const getTag = () => {
    switch (status) {
      case 'AUTHORIZED':
      case 'CONCLUDED':
      case 'RESOLVED':
        return {
          textColor: 'success900',
          bgColor: 'success100'
        }
      case 'UNDER_REVIEW_AT_THE_HOSPITAL':
      case 'UNDER_REVIEW_AT_THE_INSURANCE':
      case 'IN_PROGRESS':
        return {
          textColor: 'secondary900',
          bgColor: 'secondary100'
        }
      case 'ORDER_OPEN':
      case 'PENDENT':
        return {
          textColor: 'terciary900',
          bgColor: 'terciary100'
        }
      case 'CANCELLED':
      default:
        return {
          textColor: 'neutral900',
          bgColor: 'neutral200'
        }
    }
  }

  return (
    <S.StatusTag
      bgColor={getTag().bgColor as keyof typeof theme.ds.colors}
      color={getTag().textColor as keyof typeof theme.ds.colors}
      as="span"
      className="capitalize-fl"
    >
      {getTranslatedLabel()}
    </S.StatusTag>
  )
}

export default SchediligStatusCard
