import styled, { css } from 'styled-components'
import { AvatarTextProps } from '.'
import { darken, mix } from 'polished'
// import theme from 'presentation/styles/theme'

enum Sizes {
  mini = '12px',
  small = '24px',
  medium = '36px',
  large = '40px',
  xlarge = '56px',
  xxlarge = '72px'
}

enum FontSize {
  mini = '10px',
  small = '14px',
  medium = '18px',
  large = '20px',
  xlarge = '22px',
  xxlarge = '24px'
}

export const AvatarText = styled.div<AvatarTextProps>`
  ${({
    color,
    active = false,
    size = 'medium',
    theme,
    onClick,
    disableHoverOnActive = false
  }) => css`
    background-color: ${theme.ds.colors[active ? color : 'white']};
    border: 1px solid ${mix(0.5, theme.ds.colors[color], '#fff')};
    color: ${active
      ? theme.ds.colors.white
      : darken(0.1, theme.ds.colors[color])};
    width: ${Sizes[size]};
    height: ${Sizes[size]};
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: ${FontSize[size]};
    font-weight: ${theme.ds.typography.weight.bold};
    transition: all 0.2s ease-in-out;
    user-select: none;
    cursor: ${onClick ? 'pointer' : 'default'};

    ${onClick &&
    css`
      &:hover {
        background-color: ${!disableHoverOnActive
          ? theme.ds.colors[active ? 'white' : color]
          : theme.ds.colors[color]};
        color: ${active && !disableHoverOnActive
          ? darken(0.1, theme.ds.colors[color])
          : theme.ds.colors.white};
      }
    `}
  `}
`
