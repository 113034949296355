import { SendSurgicalScheduling } from 'domain/usecases/surgical-scheduling/send-surgical-scheduling'
import { SurgicalSchedulingRepository } from 'repository/interfaces/surgical-scheduling-repository'

export class RemoteSendSurgicalScheduling implements SendSurgicalScheduling {
  constructor(
    private readonly surgicalSchedulingRepository: SurgicalSchedulingRepository
  ) {}

  sendSurgicalScheduling(
    params: SendSurgicalScheduling.Params
  ): Promise<SendSurgicalScheduling.Model> {
    return this.surgicalSchedulingRepository.sendSurgicalScheduling(params)
  }
}
