import React from 'react'

import HeaderNew from 'presentation/shared/components/HeaderNew'
import SelectAnesthesiologyForm from 'presentation/doctor/components/Forms/AddSurgicalOrderNew/SelectAnesthesiology'

const SelectAnesthesiologyLayout = () => {
  return (
    <>
      <HeaderNew actualPageTitle="Novo pedido cirúrgico - Procedimentos e CID" />
      <SelectAnesthesiologyForm />
    </>
  )
}

export default SelectAnesthesiologyLayout
