export enum HospitalizationType {
  urgency = 'Urgência',
  elective = 'Eletivo'
}

export const hospitalizationList = [
  {
    label: HospitalizationType.urgency,
    value: 'urgency'
  },
  {
    label: HospitalizationType.elective,
    value: 'elective'
  }
]
