import React from 'react'
import * as S from './styles'
import CardNew from 'presentation/shared/components/CardNew'
import SupportTextNew from 'presentation/shared/components/SupportTextNew'
import DividerNew from 'presentation/shared/components/DividerNew'
import BadgeStatusNew from 'presentation/shared/components/BadgeStatusNew'
import StatusTag from 'presentation/shared/components/StatusTag'
import { ReactComponent as PendencyIcon } from 'presentation/assets/icons/pendency-icon.svg'
import { ReactComponent as ThreshIcon } from 'presentation/assets/icons/trash-icon.svg'
import theme from 'presentation/styles/theme'
import moment from 'moment'
import { ReactComponent as AlertIcon } from 'presentation/assets/icons/alert-triangle-filled.svg'
import { PasswordMaxDateValidationStatus } from 'common/enum/password-max-date-validation-status'

type Props = {
  surgicalOrder: any
  footerColor?: keyof typeof theme.ds.colors
  onClick?: (surgical_order_id: number) => void
  onTrashClick?: (surgical_order_id: number) => void
  showTrash?: boolean
  hideFooter?: boolean
  hideStatusTag?: boolean
  flatten?: boolean
  procedures?: boolean
  schedulingDate?: boolean
  statusTag?: boolean
  trashIconList?: boolean
  id?: string
}

export default function SurgicalOrderCard({
  surgicalOrder,
  footerColor = 'terciary400',
  onClick,
  onTrashClick,
  showTrash = false,
  hideFooter = false,
  hideStatusTag = false,
  flatten = false,
  procedures,
  schedulingDate,
  statusTag,
  trashIconList,
  id
}: Props) {
  const getDateLabel = () => {
    switch (surgicalOrder.scheduling_status) {
      case 'scheduled':
      case 'SCHEDULED':
        return {
          label: 'Data da cirurgia',
          key: 'scheduled_date'
        }
      case 'analyze':
      case 'ANALYZE':
      case 'scheduled_in_analysis':
      case 'SCHEDULED_IN_ANALYSIS':
        return {
          label: 'Data solicitada',
          key: 'requested_date'
        }
      case 'unfinshed':
      case 'UNFINISHED':
      case 'UNSCHEDULED':
      case 'unscheduled':
      default:
        return {
          label: 'Data prevista no aviso',
          key: 'expected_date'
        }
    }
  }

  const onDelete = (event: React.MouseEvent) => {
    event.stopPropagation()
    onTrashClick && onTrashClick(surgicalOrder.surgical_order_id)
  }

  const mainProcedure = () =>
    surgicalOrder.procedures
      ?.find((procedure: any) => procedure.isMain)
      ?.description?.toLowerCase()

  return (
    <CardNew
      background={onClick || flatten ? 'neutral25' : 'white'}
      shadow={onClick && !flatten ? 'satin' : 'none'}
      border={onClick || flatten ? 'none' : 'primary300'}
      onClick={
        onClick ? () => onClick(surgicalOrder.surgical_order_id) : undefined
      }
      id={id}
    >
      {surgicalOrder.active_status !== 'ORDER_OPEN' && (
        <>
          <S.CardHeader>
            <SupportTextNew weight="bold" color="neutral900">
              Aviso {surgicalOrder.surgical_order_id || 0}
            </SupportTextNew>

            <BadgeStatusNew status={surgicalOrder.scheduling_status} />
          </S.CardHeader>

          <DividerNew />
        </>
      )}

      <S.CardBody>
        <div>
          <SupportTextNew weight="bold" color="neutral900" size="xsmall">
            Paciente
          </SupportTextNew>
          <SupportTextNew capitalize>
            {surgicalOrder.patient?.name.toLowerCase() || '-'}
          </SupportTextNew>
        </div>

        {procedures && (
          <div>
            <SupportTextNew weight="bold" color="neutral900" size="xsmall">
              Procedimento
            </SupportTextNew>
            <SupportTextNew capitalize>
              {mainProcedure() || 'Não preenchido'}
            </SupportTextNew>
          </div>
        )}

        <div>
          <SupportTextNew weight="bold" color="neutral900" size="xsmall">
            Convênio
          </SupportTextNew>
          <SupportTextNew capitalize>
            {surgicalOrder.health_insurance?.name?.toLowerCase() || '-'}
          </SupportTextNew>
        </div>

        {schedulingDate && surgicalOrder?.scheduling_date && (
          <div>
            <SupportTextNew weight="bold" color="neutral900" size="xsmall">
              {getDateLabel().label}
            </SupportTextNew>
            <SupportTextNew>
              {surgicalOrder.scheduling_date?.length
                ? surgicalOrder.scheduling_date.map((date: any) =>
                    date[getDateLabel().key]
                      ? moment
                          .utc(date[getDateLabel().key])
                          .format('DD [de] MMM[.] [de] YYYY')
                      : 'Não preenchido'
                  )
                : '-'}
            </SupportTextNew>
          </div>
        )}

        <div>
          <SupportTextNew weight="bold" color="neutral900" size="xsmall">
            Unidade
          </SupportTextNew>
          <SupportTextNew capitalize>
            {surgicalOrder.hospital?.name
              ?.replace(/^\d+\s*-\s*/, '')
              .toLowerCase() || '-'}
          </SupportTextNew>
        </div>

        {surgicalOrder?.finalize_surgical_order
          ?.password_max_date_validation ? (
          <div>
            <SupportTextNew weight="bold" color="neutral900" size="xsmall">
              Senha de autorização
            </SupportTextNew>
            <SupportTextNew>
              <S.AlertIconWrapper
                status={
                  surgicalOrder?.finalize_surgical_order
                    ?.password_max_date_validation_status
                }
              >
                {surgicalOrder?.finalize_surgical_order
                  ?.password_max_date_validation_status !==
                PasswordMaxDateValidationStatus.VALID ? (
                  <AlertIcon />
                ) : (
                  ''
                )}
                Válida até{' '}
                {moment(
                  surgicalOrder?.finalize_surgical_order
                    ?.password_max_date_validation
                )
                  .utc()
                  .format('DD/MM/YYYY')}
              </S.AlertIconWrapper>
            </SupportTextNew>
          </div>
        ) : (
          ''
        )}

        {statusTag && !hideStatusTag && surgicalOrder.active_status && (
          <StatusTag status={surgicalOrder.active_status} />
        )}

        {trashIconList && showTrash && (
          <S.TrashIconInList>
            <ThreshIcon onClick={onDelete} className="tresh-icon" />
          </S.TrashIconInList>
        )}
      </S.CardBody>

      {!trashIconList &&
        ((surgicalOrder.pendencies && !hideFooter) || showTrash) && (
          <S.CardFooter color={footerColor}>
            <SupportTextNew
              weight="bold"
              color="neutral900"
              style={{ display: 'flex', alignItems: 'center' }}
            >
              {showTrash ? (
                ''
              ) : (
                <>
                  <PendencyIcon style={{ marginRight: '8px' }} />
                  Pendências
                </>
              )}
            </SupportTextNew>
            {showTrash ? (
              <ThreshIcon onClick={onDelete} className="tresh-icon" />
            ) : (
              <S.BadgeCounter color={footerColor}>
                {surgicalOrder.pendencies.total}
              </S.BadgeCounter>
            )}
          </S.CardFooter>
        )}
    </CardNew>
  )
}
