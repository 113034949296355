import { LoadExpirationDateStatusDashboardCounts } from 'domain/usecases/surgical-order/load-expiration-date-status-dashboard-count'
import { SurgicalOrderRepository } from 'repository/repositories/surgical-order/surgical-order-repository'

export class RemoteLoadExpirationDateStatusDashboardCounts
  implements LoadExpirationDateStatusDashboardCounts
{
  constructor(
    private readonly surgicalOrderRepository: SurgicalOrderRepository
  ) {}

  load(): Promise<LoadExpirationDateStatusDashboardCounts.Model> {
    return this.surgicalOrderRepository.loadExpirationDateStatusDashboardCounts()
  }
}
