export function formatPhoneWithParenthesis(phoneNumber?: string) {
  const cleanNumber = phoneNumber?.replace(/\D/g, '')

  const numeroFormatado = cleanNumber?.replace(
    /^(\d{2})(\d{2})(\d{9})$/,
    '+$1 ($2) $3'
  )

  return numeroFormatado
}
