import styled from 'styled-components'

export const StepContainer = styled.div`
  width: 100%;
  height: auto;
  min-height: 90%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`

export const StepDotsContainer = styled.div`
  margin: auto;
  margin-bottom: 20px;
`
