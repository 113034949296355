import { GetSurgicalSchedulesCountByDoctor } from 'domain/usecases/surgical-order/get-surgical-schedules-count-by-doctor'
import { SurgicalOrderRepository } from 'repository/interfaces/surgical-order-repository'

export class RemoteGetSurgicalSchedulesCountByDoctor
  implements GetSurgicalSchedulesCountByDoctor
{
  constructor(
    private readonly surgicalOrderRepository: SurgicalOrderRepository
  ) {}

  getSurgicalSchedulesCountByDoctor(): Promise<GetSurgicalSchedulesCountByDoctor.Model> {
    return this.surgicalOrderRepository.getSurgicalSchedulesCountByDoctor()
  }
}
