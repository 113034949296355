export enum PatientDocument {
  HEALTH_CARD = 'Carteira do convênio',
  HEALTH_CARD_FRONT = 'Carteira do convênio - Frente',
  HEALTH_CARD_BACK = 'Carteira do convênio - Verso',
  MEDICAL_REPORT = 'Relatório Médico',
  IDENTITY_CARD = 'Carteira de identidade',
  IDENTITY_CARD_FRONT = 'Carteira de identidade - Frente',
  IDENTITY_CARD_BACK = 'Carteira de identidade - Verso',
  SERVICE_AGREEMENT = 'Acordo de serviço',
  AUTHORIZATION = 'Autorização',
  SURGICAL_PLANNING = 'Planejamento cirúrgico',
  SURGICAL_CONSENT = 'Consent. cirúrgico',
  ANESTHETIC_CONSENT = 'Consent. anestésico',
  PRE_ANESTHETIC_CONSENT = 'Consulta pré-anestésica',
  PRE_ANESTHETIC_EVALUATION = 'Avaliação pré-anestésica',
  HEMOTHERAPY_CONSENT = 'Consent. de hemoterapia',
  GUIDE = 'GUIA',
  SURGICAL_RISK = 'Risco cirúrgico',
  SURGICAL_ORDER = 'Pedido cirúrgico',
  MEDICAL_ORDER = 'Pedido médico',
  EXAM_REPORT = 'Laudos dos exames',
  EXAM_DOCUMENT = 'Documento do Exame',
  IMAGE_REPORT = 'Laudo de Imagem',
  CONSENT_TERM = 'Termo de consentimento',
  ALL_GUIDANCES = 'Todas as orientações',
  PATIENT_DOCUMENT = 'Documento do paciente',
  AUTHORIZATION_GUIDE = 'Guia de autorização',
  BIOPSY = 'Biópsia',
  BUDGET = 'Orçamento',
  INFORMED_CONSENT = 'Consent. informado',
  GUIDE_SPSADT = 'GUIA SADT',
  GUIDE_OPME = 'GUIA DE OPME',
  GUIDE_HOSPITALIZATION_REQUEST = 'GUIA SOLICITAÇÃO DE INTERNAÇÃO',
  GUIDE_SURGICAL_PLANNING = 'PLANEJAMENTO CIRÚRGICO',
  GUIDE_SURGICAL_ORDER = 'GUIA DE PEDIDO CIRÚRGICO',
  OTHERS = 'Outros'
}

export enum OpenSurgicalOrderPatientDocument {
  'PLANEJAMENTO CIRÚRGICO' = 'PLANEJAMENTO CIRÚRGICO',
  'Guia de autorização' = 'Guia de autorização',
  'Carteira de identidade' = 'Carteira de identidade',
  'Carteira do convênio' = 'Carteira do convênio',
  'Pedido cirúrgico' = 'Planejamento cirúrgico',
  'Relatório Médico' = 'Relatório médico',
  'Consent. cirúrgico' = 'Consentimento cirúrgico',
  'Consent. anestésico' = 'Consentimento anestésico',
  'Avaliação pré-anestésica' = 'Avaliação pré-anestésica',
  'Laudos dos exames' = 'Laudos dos exames',
  'LAUDO DE EXAME' = 'LAUDO DE EXAME',
  'Outros' = 'Outros'
}
export enum OpenSurgicalOrderPatientInformation {
  'Dados paciente e convênio' = 'Dados paciente e convênio',
  'Dados cooperativa' = 'Dados cooperativa',
  'Procedimentos e CID' = 'Procedimentos e CID',
  'OPME e fornecedores' = 'OPME e fornecedores',
  'História clínica' = 'História clínica',
  'Ficha clínica' = 'Ficha clínica',
  'Laudos e outros documentos' = 'Laudos e outros documentos'
}
