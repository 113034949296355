// Remove all comments in the future:
import React, { useEffect, useState } from 'react'
import {
  BrowserRouter,
  Redirect,
  Route,
  Switch,
  useLocation,
  withRouter
} from 'react-router-dom'
import { Profile } from 'common/enum/profile'
import { useStores } from 'presentation/hooks/use-stores'
import AddProfessional from 'presentation/doctor/pages/AddProfessional'
import makePatientLogin from 'main/factories/pages/patient/login/patient-login-factory'
import makePasswordRecovery from 'main/factories/pages/patient/password-recovery/patient-password-recovery-factory'
import makeDoctorSignup from 'main/factories/pages/doctor/signup/doctor-signup-factory'
import makeDoctorIndicationRegister from 'main/factories/pages/doctor/doctor-indication/doctor-indication-register-factory'
import DoctorHome from 'presentation/doctor/pages/Home'
import makeDoctorAddSecretary from 'main/factories/pages/doctor/add-secretary/doctor-add-secretary-factory'
import makeDoctorSecretaries from 'main/factories/pages/doctor/secretaries/doctor-secretaries-factory'
import makeDoctorSecretaryDetails from 'main/factories/pages/doctor/secretary-details/doctor-secretary-details-factory'
import makeDoctorAddSignature from 'main/factories/pages/doctor/add-signature/doctor-add-signature-factory'
import makeDoctorSignature from 'main/factories/pages/doctor/signature/doctor-signature-factory'
import PrivateRoute from 'presentation/shared/components/PrivateRoute'
import Allergies from 'presentation/doctor/pages/Allergies'
import makeDoctorAddAllergy from 'main/factories/pages/doctor/add-allergy/doctor-add-allergy-factory'
// import makeDoctorAddPatient from 'main/factories/pages/doctor/add-patient/doctor-add-patient-factory'
// import makeDoctorCreateSurgicalOrder from 'main/factories/pages/doctor/add-surgical-order_old/create-surgical-order/doctor-create-surgical-order'
import makeDoctorAddSurgicalOrderInfo from 'main/factories/pages/doctor/add-surgical-order-info/doctor-add-surgical-oder-info-factory'
// import makeDoctorSelectProcedure from 'main/factories/pages/doctor/add-surgical-order_old/select-procedure/doctor-select-procedure-factory'
// import makeDoctorSelectOpme from 'main/factories/pages/doctor/add-surgical-order_old/select-opme/doctor-select-opme-factory'
import makePatientSaveSurgicalOrderPersonalInfo from 'presentation/shared/pages/create-surgical-order/save-personal-info/save-surgical-order-personal-info'
import makePatientSaveSurgicalOrderInsuranceInfo from 'presentation/shared/pages/create-surgical-order/save-insurance-info'
// import makeDoctorCreateMedicalRecord from 'main/factories/pages/doctor/add-surgical-order_old/create-medical-record/doctor-create-medical-record'
// import makeDoctorReviewSurgicalOrder from 'main/factories/pages/doctor/add-surgical-order_old/review-surgical-order/doctor-review-surgical-order-factory'
// import makeDoctorSelectTherapeuticPatient from 'main/factories/pages/doctor/add-therapeutic-plan/select-therapeutic-patient/doctor-select-therapeutic-patient'
import makeDoctorSelectTherapeuticPlan from 'main/factories/pages/doctor/add-therapeutic-plan/select-therapeutic-plan'
import makeDoctorCreateTherapeuticPlan from 'main/factories/pages/doctor/add-therapeutic-plan/create-therapeutic-plan'
import makeDoctorTherapeuticPlanResume from 'main/factories/pages/doctor/add-therapeutic-plan/therapeutic-plan-resume'
import makeDoctorTherapeuticPlanDiagnosis from 'main/factories/pages/doctor/add-therapeutic-plan/therapeutic-plan-diagnosis'
import makeDoctorTherapeuticPlanAttachments from 'main/factories/pages/doctor/add-therapeutic-plan/therapeutic-plan-attachments'
import makeDoctorAnswerPendency from 'main/factories/pages/doctor/pendency/doctor-pendency-factory'
// import makePatientProfile from 'main/factories/pages/patient/profile/patient-profile-factory'
import makeDoctorPersonalInfo from 'main/factories/pages/doctor/personal-info/doctor-personal-info-Factory'
import { AppendSurgicalOrderDocument } from 'presentation/shared/pages/AppendSurgicalOrderDocument'
import { MedicalReports } from 'presentation/shared/pages/MedicalReports'
import PatientProfileLayout from 'presentation/patient/layouts/Profile'
import SurgeryDetails from 'presentation/shared/pages/SurgeryDetails'
// import DoctorOnboarding from 'presentation/doctor/pages/Onbording'
// import DoctorSignTerms from 'presentation/doctor/pages/SignTerms'
import SurgicalOrderDocuments from 'presentation/doctor/pages/SurgicalOrderDocuments'
// import makeReviewSurgicalOrderList from 'main/factories/pages/doctor/review-surgical-order-list/review-surgical-order-list'
import TherapeuticPlanProposedProtocol from 'presentation/doctor/pages/AddTherapeuticPlan/TherapeuticPlanProposedProtocol'
// import DoctorSignupByRecommendation from 'presentation/doctor/pages/SignupByRecommendation'
// import AddDoctorInfo from 'presentation/doctor/pages/AddDoctorInfo'
// import ReviewDoctorRegister from 'presentation/doctor/pages/ReviewDoctorRegister'
import { Pendencies } from 'presentation/doctor/pages/Pendencies'
// import TrainingPlatform from 'presentation/doctor/pages/TrainingPlatform'
// import makeDoctorAddNewBornPatient from 'main/factories/pages/doctor/add-newborn-patient/doctor-add-newborn-patient-factory'
// import makeDoctorFavoritesFactory from 'main/factories/pages/doctor/favorites/doctor-favorites-factory'
import makeOnboarding from 'main/factories/pages/doctor/onboarding/onboarding-factory'
// import Attendances from 'presentation/doctor/pages/Attendances'
import UserTerms from 'presentation/shared/pages/UserTerms'
import makeSurgicalSchedulingPages from 'main/factories/pages/doctor/surgical-scheduling/surgical-scheduling-factory'
import SurgicalSchedulingDetails from 'presentation/doctor/pages/SurgicalSchedulingDetails'
// import Schedulings from 'presentation/doctor/pages/Schedulings'
// import makeSurgicalOrderSchedulingsPage from 'main/factories/pages/doctor/SurgicalOrderSchedulingsListPage/doctor-surgical-order-list'
import makeSurgicalOrder from 'main/factories/pages/doctor/surgical-order/surgical-order-factory'
import makeSurgicalOrderDashboard from 'main/factories/pages/doctor/surgical-order/surgical-order-dashboard-factory'
import SecretaryDoctors from 'presentation/doctor/pages/SecretaryDoctors'
import makeDoctorUpdatePassword from 'main/factories/pages/doctor/update-password/doctor-personal-info-Factory'
import CooperativeForm from 'presentation/doctor/pages/CooperativeForm'
import SelectLocation from 'presentation/doctor/pages/AddSurgicalOrderNew/SelectLocation'
import AddPatientNew from 'presentation/doctor/pages/AddPatientNew'
import SelectPatient from 'presentation/doctor/pages/AddSurgicalOrderNew/SelectPatient'
import ReviewPatient from 'presentation/doctor/pages/AddSurgicalOrderNew/ReviewPatient'
import OpenSurgicalOrder from 'presentation/doctor/pages/AddSurgicalOrderNew/OpenSurgicalOrder'
import ReportsAndDocuments from 'presentation/doctor/pages/AddSurgicalOrderNew/ReportsAndDocuments'
import SelectProceduresAndCID from 'presentation/doctor/pages/AddSurgicalOrderNew/SelectProceduresAndCID'
import ReviewSurgicalOrder from 'presentation/doctor/pages/ReviewSurgicalOrder'
import SurgicalOrderCreated from 'presentation/doctor/pages/SurgicalOrderCreated'
import SelectOPME from 'presentation/doctor/pages/AddSurgicalOrderNew/SelectOPME'
import SelectAnesthesiology from 'presentation/doctor/pages/AddSurgicalOrderNew/SelectAnesthesiology'
import AddClinicalHistoryAndRecord from 'presentation/doctor/pages/AddSurgicalOrderNew/AddClinicalHistoryAndRecord'
import SurgicalOrderIntegration from 'presentation/doctor/pages/SurgicalOrderIntegration'
import { LocalStorageAdapter } from 'infra/storage-adapter/storage-adapter'
import MyProductivity from 'presentation/doctor/pages/MyProductivity'
import makeAmbulatoryAuthorizationPage from 'main/factories/pages/doctor/ambulatory-authorization/ambulatory-authorization-dashboard-factory'

export const ScrollToTop = withRouter(() => {
  const { pathname } = useLocation()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  return null
})

const SwitchRouter = withRouter(() => {
  const { pathname } = useLocation()
  const stores = useStores()
  const currentUser = stores.currentAccount

  const [pendingTerms, setPendingTerms] = useState<boolean>(false)

  const isPendingTerms = currentUser
    .getCurrentAccount()
    ?.user?.terms?.some((term) => term.signed === false)

  const pendingTermsOfUse = () =>
    currentUser.getCurrentAccount()?.accessToken && isPendingTerms

  const setSurgicalOrderIntegrationUserFlag = () => {
    const localStorageAdapter = new LocalStorageAdapter()
    const currentFlags = localStorageAdapter.get('user-flags')

    if (currentFlags?.surgicalOrderIntegration === undefined) {
      localStorageAdapter.set('user-flags', {
        ...currentFlags,
        surgicalOrderIntegration: false
      })
    }
  }

  useEffect(() => {
    setPendingTerms(!!pendingTermsOfUse())
    setSurgicalOrderIntegrationUserFlag()
  }, [pathname])

  return (
    <>
      {pendingTerms ? (
        <Switch>
          <PrivateRoute
            path="/termos-de-uso"
            exact
            component={UserTerms}
            profile={[Profile.DOCTOR, Profile.SECRETARY]}
          />

          <Route
            path="/*"
            component={() => {
              return (
                <>
                  <Redirect to="/termos-de-uso" />
                </>
              )
            }}
          />
        </Switch>
      ) : (
        <Switch>
          <Route
            path="/"
            exact
            component={() => {
              return (
                <>
                  <Redirect
                    to={
                      currentUser.getCurrentAccount()?.accessToken
                        ? '/home'
                        : '/login'
                    }
                  />
                </>
              )
            }}
          />
          <Route
            path="/login"
            exact
            component={() => makePatientLogin(Profile.DOCTOR)}
          />
          <Route path="/cadastro/:hash" exact component={makeDoctorSignup} />
          <Route
            path="/cadastro-corpo-clinico"
            exact
            component={makeDoctorIndicationRegister}
          />
          <PrivateRoute
            path="/home"
            exact
            component={DoctorHome}
            profile={[Profile.DOCTOR]}
          />
          <PrivateRoute
            path="/selecionar-perfil"
            exact
            component={SecretaryDoctors}
            profile={[Profile.SECRETARY]}
          />
          <PrivateRoute
            path="/doctor-onboarding"
            exact
            component={makeOnboarding}
            profile={[Profile.DOCTOR]}
          />
          {/* <PrivateRoute
            path="/atendimentos"
            exact
            component={Attendances}
            profile={[Profile.DOCTOR]}
          /> */}
          {/* <PrivateRoute
            path="/agendamentos-de-cirurgias"
            exact
            component={Schedulings}
            profile={[Profile.DOCTOR]}
          /> */}
          <Route
            path="/recuperar-senha/:type"
            exact
            component={() => makePasswordRecovery({ client: Profile.DOCTOR })}
          />
          <PrivateRoute
            path="/vincular-profissional"
            exact
            component={AddProfessional}
            profile={[Profile.DOCTOR]}
          />
          <PrivateRoute
            path="/vincular-profissional/secretaria"
            exact
            component={makeDoctorAddSecretary}
            profile={[Profile.DOCTOR]}
          />
          <PrivateRoute
            path="/secretaria"
            exact
            component={makeDoctorSecretaries}
            profile={[Profile.DOCTOR]}
          />
          <PrivateRoute
            path="/secretaria/detalhes"
            exact
            component={makeDoctorSecretaryDetails}
            profile={[Profile.DOCTOR]}
          />
          <PrivateRoute
            path="/assinatura/save"
            exact
            component={makeDoctorAddSignature}
            profile={[Profile.DOCTOR]}
          />
          <PrivateRoute
            path="/assinatura"
            exact
            component={makeDoctorSignature}
            profile={[Profile.DOCTOR]}
          />
          <PrivateRoute
            path="/alergia/save"
            exact
            component={makeDoctorAddAllergy}
            profile={[Profile.DOCTOR]}
          />
          <PrivateRoute
            path="/alergia"
            exact
            component={Allergies}
            profile={[Profile.DOCTOR]}
          />
          <PrivateRoute
            path="/pendencias"
            exact
            component={Pendencies}
            profile={[Profile.DOCTOR]}
          />
          <PrivateRoute
            path="/pendencia"
            exact
            component={makeDoctorAnswerPendency}
            profile={[Profile.DOCTOR]}
          />
          {/* <PrivateRoute
            path="/pedido/novo"
            exact
            component={makeDoctorCreateSurgicalOrder}
            profile={[Profile.DOCTOR]}
          /> */}
          {/* Novo fluxo criar pedido cirúrgico */}
          <PrivateRoute
            path="/novo-pedido/local"
            exact
            component={SelectLocation}
            profile={[Profile.DOCTOR]}
          />
          <PrivateRoute
            path="/novo-pedido/paciente"
            exact
            component={SelectPatient}
            profile={[Profile.DOCTOR]}
          />
          <PrivateRoute
            path="/novo-pedido/revisar-paciente"
            exact
            component={ReviewPatient}
            profile={[Profile.DOCTOR]}
          />
          <PrivateRoute
            path="/novo-pedido/cooperativa"
            exact
            component={CooperativeForm}
            profile={[Profile.DOCTOR]}
          />
          <PrivateRoute
            path="/novo-pedido/procedimentos"
            exact
            component={SelectProceduresAndCID}
            profile={[Profile.DOCTOR]}
          />
          <PrivateRoute
            path="/novo-pedido/opme"
            exact
            component={SelectOPME}
            profile={[Profile.DOCTOR]}
          />
          <PrivateRoute
            path="/novo-pedido/anestesiologia"
            exact
            component={SelectAnesthesiology}
            profile={[Profile.DOCTOR]}
          />
          <PrivateRoute
            path="/novo-pedido/historia-clinica"
            exact
            component={AddClinicalHistoryAndRecord}
            profile={[Profile.DOCTOR]}
          />
          <PrivateRoute
            path="/novo-pedido/laudos-documentos"
            exact
            component={ReportsAndDocuments}
            profile={[Profile.DOCTOR]}
          />
          <PrivateRoute
            path="/novo-pedido/resumo"
            exact
            component={ReviewSurgicalOrder}
            profile={[Profile.DOCTOR]}
          />
          <PrivateRoute
            path="/novo-pedido/criado"
            exact
            component={SurgicalOrderCreated}
            profile={[Profile.DOCTOR]}
          />
          <PrivateRoute
            path={'/pedido-em-aberto'}
            component={OpenSurgicalOrder}
            profile={[Profile.DOCTOR]}
          />
          <PrivateRoute
            path="/novo-paciente"
            exact
            component={AddPatientNew}
            profile={[Profile.DOCTOR]}
          />
          {/* <PrivateRoute
            path="/paciente/save"
            exact
            component={makeDoctorAddPatient}
            profile={[Profile.DOCTOR]}
          /> */}
          {/* <PrivateRoute
            path="/paciente/recem-nascido/save"
            exact
            component={makeDoctorAddNewBornPatient}
            profile={[Profile.DOCTOR]}
          /> */}
          <PrivateRoute
            path="/pedido--cirurgico"
            exact
            component={makeDoctorAddSurgicalOrderInfo}
            profile={[Profile.DOCTOR]}
          />
          <PrivateRoute
            path="/pedido-cirurgico/save/dados-pessoais"
            exact
            component={makePatientSaveSurgicalOrderPersonalInfo}
            profile={[Profile.DOCTOR]}
          />
          <PrivateRoute
            path="/pedido-cirurgico/save/dados-convenio"
            exact
            component={makePatientSaveSurgicalOrderInsuranceInfo}
            profile={[Profile.DOCTOR]}
          />
          {/* <PrivateRoute
            path="/pedido-cirurgico/procedimento"
            exact
            component={makeDoctorSelectProcedure}
            profile={[Profile.DOCTOR]}
          />
          <PrivateRoute
            path="/pedido-cirurgico/opme"
            exact
            component={makeDoctorSelectOpme}
            profile={[Profile.DOCTOR]}
          />
          <PrivateRoute
            path="/pedido-cirurgico/ficha-clinica"
            exact
            component={makeDoctorCreateMedicalRecord}
            profile={[Profile.DOCTOR]}
          />
          <PrivateRoute
            path="/pedido-cirurgico/resumo"
            exact
            component={makeDoctorReviewSurgicalOrder}
            profile={[Profile.DOCTOR]}
          />
          <PrivateRoute
            path="/novo-plano-terapeutico/selecionar-paciente"
            exact
            component={makeDoctorSelectTherapeuticPatient}
            profile={[Profile.DOCTOR]}
          /> */}
          <PrivateRoute
            path="/novo-plano-terapeutico/selecionar-plano"
            exact
            component={makeDoctorSelectTherapeuticPlan}
            profile={[Profile.DOCTOR]}
          />
          <PrivateRoute
            path="/novo-plano-terapeutico/novo"
            exact
            component={makeDoctorCreateTherapeuticPlan}
            profile={[Profile.DOCTOR]}
          />
          <PrivateRoute
            path="/novo-plano-terapeutico/resumo"
            exact
            component={makeDoctorTherapeuticPlanResume}
            profile={[Profile.DOCTOR]}
          />
          <PrivateRoute
            path="/novo-plano-terapeutico/diagnostico"
            exact
            component={makeDoctorTherapeuticPlanDiagnosis}
            profile={[Profile.DOCTOR]}
          />
          {/* <PrivateRoute
            path="/perfil"
            exact
            component={makePatientProfile}
            profile={[Profile.DOCTOR]}
          /> */}
          <PrivateRoute
            path="/informacoes-pessoais"
            exact
            component={makeDoctorPersonalInfo}
            profile={[Profile.DOCTOR]}
          />
          <PrivateRoute
            path="/alterar-senha"
            exact
            component={makeDoctorUpdatePassword}
            profile={[Profile.DOCTOR]}
          />

          <PrivateRoute
            path="/novo-plano-terapeutico/exames-laudos"
            exact
            component={makeDoctorTherapeuticPlanAttachments}
            profile={[Profile.DOCTOR]}
          />
          <PrivateRoute
            path="/novo-plano-terapeutico/protocolo-proposto"
            exact
            component={TherapeuticPlanProposedProtocol}
            profile={[Profile.DOCTOR]}
          />
          <PrivateRoute
            path="/upload/documentos/:type"
            exact
            component={AppendSurgicalOrderDocument}
            profile={[Profile.DOCTOR]}
          />
          <PrivateRoute
            path="/upload/laudos"
            exact
            component={MedicalReports}
            profile={[Profile.DOCTOR]}
          />
          <PrivateRoute
            path="/pedido-anexo"
            exact
            component={SurgicalOrderDocuments}
            profile={[Profile.DOCTOR]}
          />
          <PrivateRoute
            path="/laudos"
            exact
            component={MedicalReports}
            profile={[Profile.DOCTOR]}
          />
          {/* <PrivateRoute
            path="/agendamentos"
            exact
            component={makeSurgicalOrderListPage}
            profile={[Profile.DOCTOR]}
          /> */}
          {/* <PrivateRoute
            path="/agendamentos-cirurgicos"
            exact
            component={makeSurgicalOrderSchedulingsPage}
            profile={[Profile.DOCTOR]}
          /> */}
          <PrivateRoute
            path="/perfil"
            exact
            component={PatientProfileLayout}
            profile={[Profile.DOCTOR, Profile.SECRETARY]}
          />
          <PrivateRoute
            path="/cirurgia"
            component={SurgeryDetails}
            profile={[Profile.DOCTOR]}
          />

          {/* Remove comments in the future: */}
          {/* <Route path="/onboarding/:hash" exact component={DoctorOnboarding} /> */}
          {/* <Route
            path="/aceitar-termos/:hash"
            exact
            component={DoctorSignTerms}
          /> */}
          {/* <Route
            path="/indicacoes/cadastro/:hash"
            exact
            component={DoctorSignupByRecommendation}
          /> */}
          {/* <PrivateRoute
            path="/cadastro-por-indicacao"
            exact
            component={AddDoctorInfo}
            profile={[Profile.DOCTOR]}
          /> */}
          {/* <PrivateRoute
            path="/cadastro-por-indicacao/resumo"
            exact
            component={ReviewDoctorRegister}
            profile={[Profile.DOCTOR]}
          /> */}
          {/* <PrivateRoute
            path="/cadastro-por-indicacao/resumo/plataforma-de-treinamento"
            exact
            component={TrainingPlatform}
            profile={[Profile.DOCTOR]}
          /> */}
          {/* <PrivateRoute
            path="/favoritos"
            exact
            component={makeDoctorFavoritesFactory}
            profile={[Profile.DOCTOR]}
          /> */}

          <PrivateRoute
            path="/agendamento-cirurgico/detalhes"
            component={SurgicalSchedulingDetails}
            profile={[Profile.DOCTOR]}
          />
          <PrivateRoute
            path="/agendamento-cirurgico"
            component={makeSurgicalSchedulingPages}
            profile={[Profile.DOCTOR]}
          />
          <PrivateRoute
            path="/pedido-cirurgico"
            component={makeSurgicalOrder}
            profile={[Profile.DOCTOR]}
          />
          <PrivateRoute
            path="/pedidos-autorizacao-ambulatorial"
            component={makeAmbulatoryAuthorizationPage}
            profile={[Profile.DOCTOR]}
          />
          <PrivateRoute
            path="/pedidos-cirurgicos"
            component={makeSurgicalOrderDashboard}
            profile={[Profile.DOCTOR]}
          />
          <PrivateRoute
            path="/integracao-pedido-cirurgico"
            exact
            component={SurgicalOrderIntegration}
            profile={[Profile.DOCTOR]}
          />
          <PrivateRoute
            path="/minhas-cirurgias"
            exact
            component={MyProductivity}
            profile={[Profile.DOCTOR]}
          />
          <Route path="/*" component={() => <Redirect to="/" />} />
        </Switch>
      )}
    </>
  )
})

const DoctorRouter = () => {
  return (
    <BrowserRouter basename="/">
      <ScrollToTop />
      <SwitchRouter />
    </BrowserRouter>
  )
}

export default DoctorRouter
