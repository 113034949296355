export enum LatexAllergyOptions {
  YES = 'Sim',
  NO = 'Não',
  NOT_INFORMED = 'Não informado'
}

export const latexAllergyOptions = [
  {
    label: 'Sim',
    value: LatexAllergyOptions.YES
  },
  {
    label: 'Não',
    value: LatexAllergyOptions.NO
  },
  {
    label: 'Não informado',
    value: LatexAllergyOptions.NOT_INFORMED
  }
]
