import React, { useState, HTMLAttributes, useEffect } from 'react'
import CalendarNew from '../CalendarNew'
import TextFieldNew from '../TextFieldNew'
import * as S from './styles'
import { ReactComponent as CalendarIcon } from 'presentation/assets/icons/calendar.svg'
import { dateMask } from 'presentation/utils/masks'
import { CalendarProps } from 'react-calendar'
import moment from 'moment-timezone'

type Props = {
  mainValue: string
  label?: string
  placeholder?: string
  onMainChange: (date: string) => void
  closeOnChange?: boolean
} & CalendarProps &
  HTMLAttributes<HTMLDivElement>

export default function DatePickerNew({
  label,
  mainValue,
  onClickDay,
  onClickMonth,
  onActiveStartDateChange,
  defaultActiveStartDate,
  tileDisabled,
  onMainChange,
  minDate,
  placeholder,
  closeOnChange = false
}: Props) {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
  const [calendarValue, setCalendarValue] = useState<Date | null>(null)
  const [textFieldValue, setTextFieldValue] = useState<string>('')

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  useEffect(() => {
    setCalendarValue(mainValue ? new Date(`${mainValue}:`) : null)
    setTextFieldValue(mainValue ? moment(mainValue).format('DD/MM/YYYY') : '')
  }, [mainValue])

  const handleCalendarChange = (date: Date) => {
    onMainChange(moment(date).format('YYYY-MM-DD'))
  }

  // const handleTextFieldChange = (value: string) => {
  //   if (value.replace('_', '').length === 10) {
  //     const IsoDate = moment(value, 'DD/MM/YYYY').format('YYYY-MM-DD')
  //     onMainChange(IsoDate)
  //   }
  // }

  // const completeDate = (evt: any) => {
  //   const value = evt.target.value.replace('_', '')
  //   if (value.length < 10) {
  //     const formatedDate = mainValue
  //       ? moment(mainValue).format('DD/MM/YYYY')
  //       : ''

  //     setTextFieldValue(formatedDate)
  //   }
  // }

  const handleClickDay = (
    value: Date,
    event: React.MouseEvent<HTMLButtonElement, globalThis.MouseEvent>
  ) => {
    onClickDay && onClickDay(value, event)
    closeOnChange && handleClose()
  }

  const removeFocus = (event: any) => {
    event.target.blur()
  }

  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined

  return (
    <div>
      <TextFieldNew
        aria-describedby={id}
        icon={<CalendarIcon />}
        label={label}
        onIconClick={handleClick}
        onClick={handleClick}
        onFocus={removeFocus}
        value={textFieldValue}
        // onInputChange={handleTextFieldChange}
        iconIsButton
        // onBlur={completeDate}
        mask={dateMask}
        placeholder={placeholder}
        // {...props}
      />
      <S.PopoverCard
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        <CalendarNew
          onClickDay={handleClickDay}
          onClickMonth={onClickMonth}
          onActiveStartDateChange={onActiveStartDateChange}
          defaultActiveStartDate={defaultActiveStartDate}
          tileDisabled={tileDisabled}
          onDateChange={(value: any) => handleCalendarChange(value)}
          minDate={minDate}
          value={calendarValue}
        />
      </S.PopoverCard>
    </div>
  )
}
