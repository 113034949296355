import { ApiClientImplementation } from 'infra/api-client/api-client'
import { PrepareApiRequestDecorator } from 'main/decorators/prepare-api-request-decorator/prepare-api-request-decorator'
import { makeLocalStorageAdapter } from 'main/factories/cache/local-storage-adapter-factory'
import TherapeuticPlanAttachments from 'presentation/doctor/pages/AddTherapeuticPlan/TherapeuticPlanAttachements'
import React from 'react'
import { ApiRepository } from 'repository/api-repository'
import { TherapeuticPlanRepository } from 'repository/repositories/therapeutic-plan/therapeutic-plan-repository'
import { RemoteUploadTherapeuticPlanDocument } from 'service/usecases/upload-therapeutic-plan-document/remote-upload-therapeutic-plan-documen'

export default function makeDoctorTherapeuticPlanAttachments() {
  const apiClient = new ApiClientImplementation()
  const apiRepository = new PrepareApiRequestDecorator(
    makeLocalStorageAdapter(),
    new ApiRepository(apiClient)
  )
  const therapeuticPlanRepository = new TherapeuticPlanRepository(apiRepository)
  const uploadTPDocument = new RemoteUploadTherapeuticPlanDocument(
    therapeuticPlanRepository
  )

  return <TherapeuticPlanAttachments uploadDocument={uploadTPDocument} />
}
