import styled, { css } from 'styled-components'

export const ListTitle = styled.p`
  ${({ theme }) => css`
    margin-left: 21px;
    font-size: ${theme.font.sizes.xsmall};
    font-weight: ${theme.font.normal};
    color: ${theme.colors.gray};
  `}
`

export const ListDescription = styled.p`
  ${({ theme }) => css`
    margin-left: 21px;
    font-size: ${theme.font.sizes.xxsmall};
    font-weight: ${theme.font.normal};
    color: ${theme.colors.lightGray};
  `}
`

export const Header = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: space-between;

    span {
      font-size: ${theme.font.sizes.xsmall};
      font-weight: ${theme.font.normal};
      color: ${theme.colors.gray};
    }
  `}
`

export const EmptyList = styled.p`
  ${({ theme }) => css`
    font-size: ${theme.font.sizes.medium};
    font-weight: ${theme.font.normal};
    color: ${theme.colors.lightGray};
    text-align: center;
    margin-top: 2rem;
  `}
`
