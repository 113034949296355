import React from 'react'
import HeaderNew from 'presentation/shared/components/HeaderNew'
import { useHistory } from 'react-router'
import AmbulatoryOrderDetailsLayout from 'presentation/doctor/layouts/AmbulatoryOrderDetails'
import { WithLoading } from 'presentation/shared/components/HOCs/WithLoading'

function AmbulatoryOrdersDetails() {
  const history = useHistory()

  return (
    <>
      <HeaderNew
        actualPageTitle="Detalhes pedido de autorização ambulatorial"
        actualPageOnClick={() =>
          history.push('/pedidos-autorizacao-ambulatorial/dashboard')
        }
      />
      <AmbulatoryOrderDetailsLayout />
    </>
  )
}

export default WithLoading(AmbulatoryOrdersDetails)
