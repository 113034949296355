import React, { useContext, useState } from 'react'
import * as S from './styles'
import { ContainerNew } from 'presentation/shared/components/ContainerNew'
import SupportTextNew from 'presentation/shared/components/SupportTextNew'
import { ReactComponent as SearchIcon } from 'presentation/assets/icons/search2.svg'
import ActionCardNew from 'presentation/shared/components/ActionCardNew'
import TextFieldNew from 'presentation/shared/components/TextFieldNew'
import theme from 'presentation/styles/theme'
import { useHistory } from 'react-router'
import {
  AmbulatoryAuthorizationStatus,
  AmbulatoryAuthorizationStatusPlural
} from 'common/enum/ambulatory-authorization-status'
import HeaderNew from 'presentation/shared/components/HeaderNew'
import { AmbulatoryAuthorizationDashboardContext } from 'main/factories/pages/doctor/ambulatory-authorization/ambulatory-authorization-dashboard-factory'
import AmbulatoryCard from 'presentation/doctor/components/AmbulatoryCard'
import { AmbulatoryOrderModel } from 'domain/entities/ambulatory-orders-model'
import ButtonNew from 'presentation/shared/components/ButtonNew'
import { GetAmbulatoryOrders } from 'domain/usecases/ambulatory/get-ambulatory-orders'
import { SearchAmbulatoryOrders } from 'domain/usecases/ambulatory/search-ambulatory-order'
import { ReactComponent as SchedulingBannerIcon } from 'presentation/assets/banners/surgical-order-dashboard-scheduling-banner.svg'
import { ReactComponent as RightArrow } from 'presentation/assets/icons/v-arrow.svg'

type Props = {
  getAmbulatoryOrders: (params: GetAmbulatoryOrders.Params) => void
  searchAmbulatoryOrders: (params: SearchAmbulatoryOrders.Params) => void
}

export default function AmbulatoryAuthorizationDashboardLayout({
  getAmbulatoryOrders,
  searchAmbulatoryOrders
}: Props) {
  const history = useHistory()
  const { state } = useContext<any>(AmbulatoryAuthorizationDashboardContext)
  const [searchTerm, setSearchTerm] = useState('')

  const first_status_group_counters = [
    {
      key: AmbulatoryAuthorizationStatus.HOSPITAL_ANALYSIS,
      label: AmbulatoryAuthorizationStatusPlural.HOSPITAL_ANALYSIS,
      color: 'secondary500'
    },
    {
      key: AmbulatoryAuthorizationStatus.ANALYSIS_INSURANCE,
      label: AmbulatoryAuthorizationStatusPlural.ANALYSIS_INSURANCE,
      color: 'secondary500'
    },
    {
      key: AmbulatoryAuthorizationStatus.DENIED,
      label: AmbulatoryAuthorizationStatusPlural.DENIED,
      color: 'terciary500'
    },
    {
      key: AmbulatoryAuthorizationStatus.AUTHORIZED,
      label: AmbulatoryAuthorizationStatusPlural.AUTHORIZED,
      color: 'primary500'
    },
    {
      key: AmbulatoryAuthorizationStatus.DONE,
      label: AmbulatoryAuthorizationStatusPlural.DONE,
      color: 'primary500'
    },
    {
      key: AmbulatoryAuthorizationStatus.CANCELED,
      label: AmbulatoryAuthorizationStatusPlural.CANCELED,
      color: 'neutral500'
    }
  ]

  const goToOrdersList = (
    quantity: number,
    orderStatus?: AmbulatoryAuthorizationStatus
  ) => {
    history.push('/pedidos-autorizacao-ambulatorial/pedidos', {
      orderStatus
    })
  }

  // const goToSurgicalOrder = (surgical_order_id: number) => {
  //   history.push('/pedido-cirurgico/detalhes', {
  //     surgical_order_id
  //   })
  // }

  const onEnterSearch = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.keyCode === 13) {
      getAmbulatoryOrderFromSearch()
    }
  }

  const getAmbulatoryOrderFromSearch = () => {
    if (!searchTerm) {
      getAmbulatoryOrders({ pageNumber: 1 })
      return
    }
    searchAmbulatoryOrders({
      search: searchTerm
    })
  }

  const getOrders = (nextPage = false) => {
    getAmbulatoryOrders({
      pageNumber: nextPage ? state.pageInfo.currentPage + 1 : 1
    })
  }

  const isLastPage = () => {
    return state.pageInfo?.currentPage === state.pageInfo?.totalPages
  }

  const getOrderStatus = (key: string) => {
    return AmbulatoryAuthorizationStatus[
      key as keyof typeof AmbulatoryAuthorizationStatus
    ]
  }

  const getMainProcedure = (procedures: any) => {
    if (procedures.length === 1) {
      return procedures[0].name
    } else return procedures.find((procedure: any) => procedure.isMain)?.name
  }

  const getMainMedication = (medications: any) => {
    if (medications.length === 1) {
      return medications[0].name
    } else return medications.find((medication: any) => medication.isMain)?.name
  }

  const getActiveStatus = (statuses: any) => {
    if (statuses.length === 1) {
      return statuses[0].status
    } else return statuses.find((status: any) => status.isActive)?.status
  }

  return (
    <>
      <HeaderNew
        showMenu
        actualPageTitle="Autorização ambulatorial"
        // actualPageOnClick={() => history.push('/home')}
        topFixed
      />
      <ContainerNew>
        <S.Container gap="24px" marginTop="20px">
          <S.CountersContainer>
            <SupportTextNew
              weight="bold"
              color="neutral800"
              size="xlarge"
              style={{ marginBottom: '5px' }}
            >
              Autorização ambulatorial
            </SupportTextNew>

            <S.FirstStatusGroupCountersContainer>
              {first_status_group_counters.map((type) => {
                return (
                  <S.VerticalCounterCard
                    key={type.key}
                    border={type.color as keyof typeof theme.ds.colors}
                    shadow="none"
                    padding="8px"
                    onClick={() =>
                      goToOrdersList(
                        state.statusCount[type.key],
                        getOrderStatus(type.key)
                      )
                    }
                  >
                    <SupportTextNew
                      color="neutral600"
                      className="capitalize-fl"
                      textAligh="center"
                      weight="bold"
                      size="xxsmall"
                      style={{ height: '25px', marginBottom: '6px' }}
                    >
                      {type.label}
                    </SupportTextNew>

                    <SupportTextNew
                      color={type.color as keyof typeof theme.ds.colors}
                      size="xxlarge"
                      weight="bold"
                      textAligh="center"
                      style={{ height: '35px' }}
                    >
                      {state.statusCount[type.key]}
                    </SupportTextNew>
                  </S.VerticalCounterCard>
                )
              })}
            </S.FirstStatusGroupCountersContainer>
          </S.CountersContainer>
        </S.Container>

        <S.Container gap="8px" marginTop="14px">
          <S.OrdersContainer>
            <S.CounterBall>{state.pageInfo.totalItems}</S.CounterBall>
            <ActionCardNew
              title="Todos os pedidos"
              text={''}
              color="neutral600"
              weight="bold"
              size="medium"
              shadow="none"
              padding="none"
              onClick={() => goToOrdersList(state.pageInfo.total_items)}
            />
          </S.OrdersContainer>
          <TextFieldNew
            icon={<SearchIcon />}
            iconIsButton
            placeholder="Paciente, N° do aviso"
            value={searchTerm}
            onInputChange={setSearchTerm}
            onIconClick={() => getAmbulatoryOrderFromSearch()}
            onKeyDown={onEnterSearch}
          />
        </S.Container>

        <S.Container gap="8px" marginTop="14px">
          <S.ShortCutsWrapper style={{ display: 'ruby' }}>
            <S.CardWrapper>
              {state.ambulatoryOrdersList.map((order: AmbulatoryOrderModel) => {
                return (
                  <AmbulatoryCard
                    procedure={getMainProcedure(order?.procedures)}
                    medication={getMainMedication(order?.medications)}
                    patient={order.patient.name}
                    doctor={order.doctor.name}
                    healthInsurance={order.healthInsurance.name}
                    unit={order.hospital.name}
                    key={order.id}
                    orderId={order.orderId}
                    status={getActiveStatus(order.statuses)}
                    order={order}
                    withHeight={true}
                  />
                )
              })}

              {!isLastPage() && (
                <ButtonNew
                  style={{ alignSelf: 'center' }}
                  variant="text"
                  onClick={() => getOrders(true)}
                >
                  Ver mais
                </ButtonNew>
              )}
            </S.CardWrapper>
          </S.ShortCutsWrapper>
        </S.Container>

        <S.SchedulingBanner>
          <SupportTextNew color="secondary700" weight="bold" size="xsmall">
            Cirurgia
          </SupportTextNew>
          <SupportTextNew color="primary700" weight="semiBold" size="medium">
            Agendamento cirúrgico
          </SupportTextNew>
          <S.SchedulingBannerBody>
            <SchedulingBannerIcon />
            <div>
              <SupportTextNew>Agende suas cirurgias pelo</SupportTextNew>
              <SupportTextNew weight="semiBold">Meu MaterDei</SupportTextNew>
              <ButtonNew
                size="medium"
                style={{ marginTop: '8px' }}
                onClick={() => {
                  history.push('/pedidos-cirurgicos/agendamento')
                }}
              >
                Agendar cirurgias
                <RightArrow style={{ marginLeft: '10px' }} />
              </ButtonNew>
            </div>
          </S.SchedulingBannerBody>
        </S.SchedulingBanner>
      </ContainerNew>
    </>
  )
}
