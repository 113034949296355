import {
  InternalServerError,
  UserAlreadyExistsError,
  CpfInUseError,
  EmailInUseError,
  UserNotExistsError,
  InvalidCodeError,
  UnauthorizedError,
  DoctorNotExistsError,
  MvError,
  CredentialsNotExistError,
  InvalidCpfFormat,
  FavoriteAlreadyExists,
  NotFoundError,
  FileEncrypted
} from 'common/errors/'
import { CpfRequiredError } from 'common/errors/user/cpf-required'
import { EmailRequiredError } from 'common/errors/user/email-required'
import { HealthInsuranceNotIntegratedError } from 'common/errors/health-insurance-not-integrated'
import { InvalidHealthCardError } from 'common/errors/invalid-health-card'
import { MaritalStatusRequired } from 'common/errors/marital-status-required'
import { PatientAlreadyExistsError } from 'common/errors/patient-already-exist'
import { PatientEmailNotEqualsToInMvError } from 'common/errors/user/patient-email-not-equal-mv'
import { PhoneInUseError } from 'common/errors/user/phone-in-use'
import { SurgicalOrderAlreadyCancelledError } from 'common/errors/surgical-order-already-cancelled'
import { SurgicalOrderAlreadyConcludedError } from 'common/errors/surgical-order-already-concluded'
import { ForbiddenError } from 'common/errors/forbidden-error'

export const RepositoryErrors = {
  ERROR_IN_DB: InternalServerError,
  USER_ALREADY_EXIST: UserAlreadyExistsError,
  EMAIL_IN_USE: EmailInUseError,
  CPF_IN_USE: CpfInUseError,
  USER_NOT_EXIST: UserNotExistsError,
  CODE_NOT_RIGHT: InvalidCodeError,
  UNAUTHORIZED: UnauthorizedError,
  PHONE_IN_USE: PhoneInUseError,
  PATIENT_EMAIL_NOT_EQUALS_IN_MV: PatientEmailNotEqualsToInMvError,
  NOT_FOUND: NotFoundError,
  DOCTOR_NOT_EXIST: DoctorNotExistsError,
  ERROR_IN_MV: MvError,
  CREDENTIALS_NOT_EXIST: CredentialsNotExistError,
  FAVORITE_NAME_ALREADY_EXIST: FavoriteAlreadyExists,
  INVALID_CPF_FORMAT: InvalidCpfFormat,
  FILE_IS_ENCRYPTED: FileEncrypted,
  EMAIL_REQUIRED: EmailRequiredError,
  CPF_REQUIRED: CpfRequiredError,
  MARITAL_STATUS_REQUIRED: MaritalStatusRequired,
  PATIENT_ALREADY_EXIST: PatientAlreadyExistsError,
  INVALID_HEALTH_CARD: InvalidHealthCardError,
  HEALTH_INSURANCE_NOT_INTEGRATED: HealthInsuranceNotIntegratedError,
  SURGICAL_ORDER_ALREADY_CANCELED: SurgicalOrderAlreadyCancelledError,
  SURGICAL_ORDER_ALREADY_CONCLUDED: SurgicalOrderAlreadyConcludedError,
  FORBIDDEN: ForbiddenError
}

export type RepositoryErrorsKey = keyof typeof RepositoryErrors
