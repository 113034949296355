import React, { useEffect, useState } from 'react'

import { useHistory } from 'react-router-dom'

import { Header } from 'presentation/shared/components/Header'
import { Container } from 'presentation/shared/components/Container'
import { SurgicalOrderModel } from 'domain/entities/surgical-order-model'
import { PatientDocument } from 'common/enum/patient-document'
import List, { ListItem } from 'presentation/shared/components/List'
import CheckIcon from 'presentation/assets/icons/big-check.svg'
import ArrowIcon from 'presentation/assets/icons/rounded-arrow.svg'
import * as S from './styles'
import Button from 'presentation/shared/components/Button'
import { useServices } from 'presentation/hooks/use-services'
import { Document } from 'domain/entities/document'
import { sortReports } from 'presentation/utils/reports/sort-reports'
import { getNextReportIndex } from 'presentation/utils/reports/get-next-index'

type Props = { surgicalOrder?: SurgicalOrderModel }

type Reports = {
  documentUri: string
} & Document

export default function SurgicalOrderDocumentsLayout({ surgicalOrder }: Props) {
  const history = useHistory()
  const [reports, setReports] = useState<any[]>([])
  const [reportList, setReportList] = useState<ListItem[]>([])
  const service = useServices().surgicalOrder

  useEffect(() => {
    ;(async () => {
      if (surgicalOrder && surgicalOrder.surgical_order_id) {
        setReports(
          await service
            .loadSurgicalOrder(surgicalOrder.surgical_order_id, [
              'documents {document_id, type, name, documentUri}'
            ])
            .then(
              (res) =>
                res.documents?.filter((doc) => doc.name.startsWith('Laudos')) ??
                []
            )
        )
      }
    })()
  }, [])

  function generateReportList(reportsList?: Reports[]) {
    if (reportsList) {
      const reportListName = reportsList.map(({ name }) => name)
      const uniqueReportList = [...new Set(reportListName)]

      const concludedReports = uniqueReportList.map(
        (reportName): ListItem => ({
          title: reportName,
          icon: CheckIcon,
          click: () => {
            history.push('/upload/documentos/laudos-exames', {
              title: reportName,
              surgicalOrderId: surgicalOrder?.surgical_order_id,
              isSolicitation: surgicalOrder?.isSolicitation,
              initialValues: reportsList.filter(
                ({ name }) => name === reportName
              )
            })
          }
        })
      )

      if (concludedReports.length === 0) {
        concludedReports.push({
          title: `Laudos 1`,
          icon: ArrowIcon,
          click: () =>
            history.push('/upload/documentos/laudos-exames', {
              title: `Laudos 1`,
              surgicalOrderId: surgicalOrder?.surgical_order_id,
              isSolicitation: surgicalOrder?.isSolicitation,
              initialValues: []
            })
        })
      }

      setReportList(sortReports(concludedReports))
    }
  }

  useEffect(() => {
    if (reports) {
      generateReportList(reports)
    }
  }, [reports, surgicalOrder])

  const documentList: ListItem[] = [
    {
      title: 'Carteira de identidade',
      icon:
        surgicalOrder?.documents &&
        surgicalOrder?.documents?.filter(
          (doc) =>
            (doc.type === PatientDocument.IDENTITY_CARD_FRONT ||
              doc.type === PatientDocument.IDENTITY_CARD_BACK ||
              doc.type === PatientDocument.IDENTITY_CARD) &&
            doc.documentUri
        )?.length < 1
          ? ArrowIcon
          : CheckIcon,
      click: () => {
        history.push('/upload/documentos/pessoais', {
          surgicalOrderId: surgicalOrder?.surgical_order_id,
          isSolicitation: surgicalOrder?.isSolicitation,
          initialValues: surgicalOrder?.documents?.filter(
            (doc) =>
              doc.type === PatientDocument.IDENTITY_CARD_FRONT ||
              doc.type === PatientDocument.IDENTITY_CARD_BACK ||
              doc.type === PatientDocument.IDENTITY_CARD
          )
        })
      }
    },
    {
      title: 'Carteira do convênio',
      icon:
        surgicalOrder?.documents &&
        surgicalOrder?.documents?.filter(
          (doc) =>
            (doc.type === PatientDocument.HEALTH_CARD_FRONT ||
              doc.type === PatientDocument.HEALTH_CARD_BACK ||
              doc.type === PatientDocument.HEALTH_CARD) &&
            doc.documentUri
        )?.length < 1
          ? ArrowIcon
          : CheckIcon,
      click: () => {
        history.push('/upload/documentos/convenio', {
          surgicalOrderId: surgicalOrder?.surgical_order_id,
          isSolicitation: surgicalOrder?.isSolicitation,
          initialValues: surgicalOrder?.documents?.filter(
            (doc) =>
              doc.type === PatientDocument.HEALTH_CARD_FRONT ||
              doc.type === PatientDocument.HEALTH_CARD_BACK ||
              doc.type === PatientDocument.HEALTH_CARD
          )
        })
      }
    },
    {
      title: 'Pedido cirúrgico',
      icon:
        surgicalOrder?.documents &&
        surgicalOrder?.documents?.filter(
          (doc) =>
            doc.type === PatientDocument.SURGICAL_ORDER && doc.documentUri
        )?.length < 1
          ? ArrowIcon
          : CheckIcon,
      click: () => {
        history.push('/upload/documentos/pedido-cirurgico', {
          surgicalOrderId: surgicalOrder?.surgical_order_id,
          isSolicitation: surgicalOrder?.isSolicitation,
          initialValues: surgicalOrder?.documents?.filter(
            (doc) => doc.type === PatientDocument.SURGICAL_ORDER
          )
        })
      }
    },
    {
      title: 'Relatório médico',
      icon:
        surgicalOrder?.documents &&
        surgicalOrder?.documents?.filter(
          (doc) =>
            doc.type === PatientDocument.MEDICAL_REPORT && doc.documentUri
        )?.length < 1
          ? ArrowIcon
          : CheckIcon,
      click: () => {
        history.push('/upload/documentos/relatorio-medico', {
          surgicalOrderId: surgicalOrder?.surgical_order_id,
          isSolicitation: surgicalOrder?.isSolicitation,
          initialValues: surgicalOrder?.documents?.filter(
            (doc) => doc.type === PatientDocument.MEDICAL_REPORT
          )
        })
      }
    },
    {
      title: 'Consentimento cirúrgico',
      icon:
        surgicalOrder?.documents &&
        surgicalOrder?.documents?.filter(
          (doc) =>
            doc.type === PatientDocument.SURGICAL_CONSENT && doc.documentUri
        )?.length < 1
          ? ArrowIcon
          : CheckIcon,
      click: () => {
        history.push('/upload/documentos/consent-cirurgico', {
          surgicalOrderId: surgicalOrder?.surgical_order_id,
          isSolicitation: surgicalOrder?.isSolicitation,
          initialValues: surgicalOrder?.documents?.filter(
            (doc) => doc.type === PatientDocument.SURGICAL_CONSENT
          )
        })
      }
    },
    {
      title: 'Consentimento anestésico',
      icon:
        surgicalOrder?.documents &&
        surgicalOrder?.documents?.filter(
          (doc) =>
            doc.type === PatientDocument.ANESTHETIC_CONSENT && doc.documentUri
        )?.length < 1
          ? ArrowIcon
          : CheckIcon,
      click: () => {
        history.push('/upload/documentos/consent-anestesico', {
          surgicalOrderId: surgicalOrder?.surgical_order_id,
          isSolicitation: surgicalOrder?.isSolicitation,
          initialValues: surgicalOrder?.documents?.filter(
            (doc) => doc.type === PatientDocument.ANESTHETIC_CONSENT
          )
        })
      }
    },
    {
      title: 'Consulta pré-anestésica',
      icon:
        surgicalOrder?.documents &&
        surgicalOrder?.documents?.filter(
          (doc) =>
            doc.type === PatientDocument.PRE_ANESTHETIC_EVALUATION &&
            doc.documentUri
        )?.length < 1
          ? ArrowIcon
          : CheckIcon,
      click: () => {
        history.push('/upload/documentos/pre-anestesico', {
          surgicalOrderId: surgicalOrder?.surgical_order_id,
          isSolicitation: surgicalOrder?.isSolicitation,
          initialValues: surgicalOrder?.documents?.filter(
            (doc) => doc.type === PatientDocument.PRE_ANESTHETIC_EVALUATION
          )
        })
      }
    },
    ...reportList
  ]

  const canAddReport = () => {
    const docFiltered = surgicalOrder?.documents?.filter(
      (doc) => doc.type === PatientDocument.EXAM_REPORT
    )

    const addReport = docFiltered && [
      ...new Set(docFiltered.map((report) => report.name))
    ]

    return addReport && addReport.length === reportList.length
  }

  return (
    <>
      <Header />
      <Container
        actualPageText="Voltar"
        actualPageOnClick={() =>
          history.push('/pedido--cirurgico', {
            surgicalOrder: surgicalOrder
          })
        }
        title="Laudos e documentos"
        secondaryButton={
          <Button
            fullWidth
            type="button"
            variant="white"
            onClick={() =>
              history.push('/pedido--cirurgico', {
                surgicalOrder: surgicalOrder
              })
            }
          >
            Voltar
          </Button>
        }
        primaryButton={
          <Button
            fullWidth
            type="button"
            onClick={() =>
              history.push('/pedido-cirurgico/resumo', {
                surgicalOrder: surgicalOrder
              })
            }
          >
            Próximo
          </Button>
        }
      >
        <S.List>
          <List items={documentList} size="medium" />
        </S.List>
        {canAddReport() && (
          <S.MoreExamReportButton
            onClick={() => {
              const newReportNumber = getNextReportIndex(reportList)
              const title = `Laudos ${newReportNumber}`
              const unsortedReportList = [
                ...reportList,
                {
                  title,
                  icon: ArrowIcon,
                  click: () => {
                    history.push('/upload/documentos/laudos-exames', {
                      title,
                      surgicalOrderId: surgicalOrder?.surgical_order_id,
                      isSolicitation: surgicalOrder?.isSolicitation,
                      initialValues: []
                    })
                  }
                }
              ]
              setReportList(sortReports(unsortedReportList))
            }}
          >
            Adicionar mais laudos
          </S.MoreExamReportButton>
        )}
      </Container>
    </>
  )
}
