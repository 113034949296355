import React, { useEffect, useState } from 'react'

import moment from 'moment-timezone'
import { toast } from 'react-toastify'
import 'moment/locale/pt-br'

import CalendarNew from 'presentation/shared/components/CalendarNew'
import { useServices } from 'presentation/hooks/use-services'
import LoadingGif from 'presentation/assets/icons/loading.gif'
import * as S from './styles'
import { DoctorScheduleCard } from './DoctorScheduleCard'
import { ScheduleData } from 'domain/usecases/doctor/load-doctor-schedule'
import { ScheduleDayData } from 'domain/usecases/doctor/load-doctor-days-available'

export const DoctorScheduleCalendarNew = () => {
  const [selectedDay, setSelectedDay] = useState<string>(
    moment().format('DD-MM-YYYY')
  )
  const [schedule, setSchedule] = useState<ScheduleData[]>()
  const [scheduleDays, setScheduleDays] = useState<ScheduleDayData>()

  const [loading, setLoading] = useState<boolean>(false)

  const service = useServices().doctor

  useEffect(() => {
    loadDataWithDaysAvailable()
  }, [])

  const isDisabledDate = ({ date, view }: any) => {
    if (view === 'month') {
      const formatedDate = moment(date).format('YYYY-MM-DD')

      const mappedDates = scheduleDays?.data?.map((date) =>
        moment(date.day).format('YYYY-MM-DD')
      )

      return !mappedDates?.includes(formatedDate)
    }
    return false
  }

  const changeMonth = (date: Date) => {
    const formatedDate = moment(date).format('YYYY-MM-DD')
    loadDataWithDaysAvailable(formatedDate)
  }

  const prevNextMonth = ({ view, action, activeStartDate }: any) => {
    if (view === 'month' && ['prev', 'next'].includes(action)) {
      const formatedDate = moment(activeStartDate).format('YYYY-MM-DD')
      loadDataWithDaysAvailable(formatedDate)
      setSchedule([])
    }
  }

  async function loadDataWithDaysAvailable(date?: string | null) {
    try {
      setLoading(true)

      const doctorDaysAvailable = await service.loadDoctorDaysAvailable({
        date: date ? date : moment().toISOString()
      })

      setScheduleDays(doctorDaysAvailable)
    } catch (error: any) {
      setScheduleDays({} as ScheduleDayData)

      toast.error(error.message)
    } finally {
      setLoading(false)
    }
  }

  async function loadData(date?: string) {
    try {
      setLoading(true)
      const doctorSchedules = await service.loadDoctorSchedule({
        date: date ? date : moment().toISOString()
      })

      setSchedule(doctorSchedules)
    } catch (error: any) {
      setSchedule({} as ScheduleData[])
      setScheduleDays({} as ScheduleDayData)

      toast.error(error.message)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    ;(async () => {
      if (selectedDay) {
        await loadData(moment(selectedDay, 'DD-MM-YYYY').toISOString())
      }
    })()
  }, [selectedDay])

  return (
    <S.Wrapper>
      <CalendarNew
        tileDisabled={isDisabledDate}
        formatLongDate={(date: any) => date}
        onClickMonth={changeMonth}
        onActiveStartDateChange={prevNextMonth}
        // activeStartDate={new Date()}
        onChange={(val: any) => {
          setSelectedDay(moment(val).format('DD-MM-YYYY'))
        }}
      />
      <S.DateText>
        {moment(selectedDay, 'DD-MM-YYYY')
          .locale('pt-BR')
          .format('DD [de] MMMM [de] YYYY')}
      </S.DateText>
      <S.ShortCutsWrapper>
        {schedule &&
          schedule?.length > 0 &&
          schedule?.map((schedule, index) => (
            <DoctorScheduleCard
              data={schedule.data}
              type={schedule.type}
              date={schedule.date}
              key={index}
            />
          ))}
      </S.ShortCutsWrapper>
      {loading && (
        <S.Loading>
          <img src={LoadingGif} />
        </S.Loading>
      )}
    </S.Wrapper>
  )
}
