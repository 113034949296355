import React from 'react'
import * as S from './styles'
import SupportTextNew from '../SupportTextNew'
import theme from 'presentation/styles/theme'

export type Props = {
  status:
    | 'unfinshed'
    | 'UNFINISHED'
    | 'unscheduled'
    | 'UNSCHEDULED'
    | 'scheduled'
    | 'SCHEDULED'
    | 'analyze'
    | 'ANALYZE'
    | 'scheduled_in_analysis'
    | 'SCHEDULED_IN_ANALYSIS'
}

const BadgeStatusNew = ({ status = 'unfinshed' }: Props) => {
  const getStatus = () => {
    switch (status) {
      case 'scheduled':
      case 'SCHEDULED':
        return {
          label: 'Agendado',
          color: theme.ds.colors.primary600
        }
      case 'analyze':
      case 'ANALYZE':
      case 'scheduled_in_analysis':
      case 'SCHEDULED_IN_ANALYSIS':
        return {
          label: 'Em análise',
          color: theme.ds.colors.secondary500
        }
      case 'unfinshed':
      case 'UNFINISHED':
      case 'UNSCHEDULED':
      case 'unscheduled':
        return {
          label: 'Não agendado',
          color: theme.ds.colors.terciary500
        }
      default:
        return {
          label: 'Indefinido',
          color: theme.ds.colors.neutral500
        }
    }
  }

  return (
    <S.StatusWrapper>
      <S.StatusCircle color={getStatus().color} />
      <SupportTextNew>{getStatus().label}</SupportTextNew>
    </S.StatusWrapper>
  )
}

export default BadgeStatusNew
