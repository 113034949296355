import { GetSurgicalOrderById } from 'domain/usecases/surgical-order/get-surgical-order-by-id'
import { SurgicalOrderRepository } from 'repository/repositories/surgical-order/surgical-order-repository'

export class RemoteGetSurgicalOrderById implements GetSurgicalOrderById {
  constructor(
    private readonly surgicalOrderRepository: SurgicalOrderRepository
  ) {}

  get(
    params: GetSurgicalOrderById.Params
  ): Promise<GetSurgicalOrderById.Model> {
    return this.surgicalOrderRepository.getSurgicalOrderById(params)
  }
}
