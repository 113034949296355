import React from 'react'

import HeaderNew from 'presentation/shared/components/HeaderNew'
import SelectProceduresAndCIDForm from 'presentation/doctor/components/Forms/AddSurgicalOrderNew/SelectProceduresAndCID'

const SelectProceduresAndCIDLayout = () => {
  return (
    <>
      <HeaderNew actualPageTitle="Novo pedido cirúrgico - Procedimentos e CID" />
      <SelectProceduresAndCIDForm />
    </>
  )
}

export default SelectProceduresAndCIDLayout
