import { ApiClientImplementation } from 'infra/api-client/api-client'
import { PrepareApiRequestDecorator } from 'main/decorators/prepare-api-request-decorator/prepare-api-request-decorator'
import { makeLocalStorageAdapter } from 'main/factories/cache/local-storage-adapter-factory'
import TherapeuticPlanDiagnosis from 'presentation/doctor/pages/AddTherapeuticPlan/TherapeuticPlanDiagnosis'
import React from 'react'
import { ApiRepository } from 'repository/api-repository'
import { SurgicalOrderRepository } from 'repository/repositories/surgical-order/surgical-order-repository'
import { RemoteLoadCid } from 'service/usecases/load-cid/remote-load-cid'

export default function makeDoctorTherapeuticPlanDiagnosis() {
  const apiClient = new ApiClientImplementation()
  const apiRepository = new PrepareApiRequestDecorator(
    makeLocalStorageAdapter(),
    new ApiRepository(apiClient)
  )

  const surgicalOrderRepository = new SurgicalOrderRepository(apiRepository)
  const loadCid = new RemoteLoadCid(surgicalOrderRepository)

  return <TherapeuticPlanDiagnosis loadCid={loadCid} />
}
