import React, { useEffect, useState } from 'react'
import moment from 'moment'

import { useFormik } from 'formik'
import { useHistory, useLocation } from 'react-router'
import DateRange from '@material-ui/icons/DateRange'
import { GenderNew } from 'common/enum/gender'
import { toast } from 'react-toastify'
import { useServices } from 'presentation/hooks/use-services'
import { useStores } from 'presentation/hooks/use-stores'
import {
  MaritalStatus,
  maritalStatus
} from 'presentation/utils/default-marital-status'
import { cpfMask, dateMask, phoneMask } from 'presentation/utils/masks'
import { isValidDate } from 'presentation/utils/validators/is-valid-data'
import { isPatientRN } from 'presentation/utils/validators/is-patient-rn'
import {
  WithLoading,
  WithLoadingProps
} from 'presentation/shared/components/HOCs/WithLoading'
import { ContainerNew } from 'presentation/shared/components/ContainerNew'
import Calendar from 'presentation/shared/components/Calendar'
import SelectFieldNew from 'presentation/shared/components/SelectFieldNew'
import SupportTextNew from 'presentation/shared/components/SupportTextNew'
import TextFieldNew from 'presentation/shared/components/TextFieldNew'
import ButtonNew from 'presentation/shared/components/ButtonNew'
import ValidationSchema from './validationSchema'
import * as S from './styles'
import { HealthInsurancePlans } from 'domain/usecases/health-insurance/load-health-insurance-plans'
import { sortBy } from 'lodash'
import { ReactComponent as SearchIcon } from 'presentation/assets/icons/search.svg'

type AddPatientFormValues = {
  hospital_id: number
  name: string
  email: string
  phone: string
  birthday: string
  cpf: string
  gender: GenderNew
  marital_status: MaritalStatus
  health_insurance: string
  health_insurance_card_number: string
}

const AddPatientForm = WithLoading(({ setIsLoading }: WithLoadingProps) => {
  const { state } = useLocation<{
    hospital_id: number
    isEditing?: boolean
  }>()
  const HOSPITAL_SALVADOR_ID = 8
  const surgicalOrderContext = useStores().surgicalOrder
  const history = useHistory()
  const patientServices = useServices().patient
  const surgicalOrderService = useServices().surgicalOrder
  const [showCalendar, setShowCalendar] = useState<boolean>(false)
  const healthInsuranceService = useServices().healthInsurance
  const [healthInsurances, setHealthInsurances] = useState<
    HealthInsurancePlans[]
  >([])
  const patientContext = surgicalOrderContext.getPatient()
  const patientService = useServices().patient
  const [messagePatientNotFound, setMessagePatientNotFound] =
    useState<string>('')

  const loadHealthInsurances = async () => {
    try {
      setIsLoading(true)
      setHealthInsurances(
        await healthInsuranceService.loadHealthInsurancePlans({
          hospital_id:
            state?.hospital_id ||
            surgicalOrderContext.location?.hospital?.hospital_id,
          params: ['code', 'description', 'ansRegister']
        })
      )
    } catch (error: any) {
      toast.error('Ocorreu um erro ao buscar os convênios')
    } finally {
      setIsLoading(false)
    }
  }

  const toggleCalendar = () => {
    setShowCalendar(showCalendar !== true)
  }

  const changeDate = (date: any) => {
    formik.resetForm()
    const momentDate = moment(date)
    if (momentDate.isValid()) {
      formik.setFieldValue('birthday', momentDate.format('DD/MM/YYYY'))
      toggleCalendar()
    } else {
      formik.setFieldValue('birthday', '')
    }
  }

  const handleBackButton = () => history.goBack()

  const isNotFutureDate = (date: string) =>
    moment().diff(moment(date, 'DD/MM/YYYY'), 'seconds') >= 0

  const createNewPatient = async ({
    payload,
    health_insurance,
    health_insurance_card_number
  }: any) => {
    const patient = await patientServices.addPatientNew(payload)

    surgicalOrderContext.setPatient({
      ...patientContext,
      patient: patient?.data
    })

    const healthInsurance = healthInsurances.find(
      (insurance) => insurance.code === parseInt(health_insurance)
    )

    if (healthInsurance) {
      surgicalOrderContext.setHealthInsurance({
        healthInsurance: {
          ...healthInsurance,
          healthCard: health_insurance_card_number
        }
      })
    }
  }

  const createSurgicalOrder = async () => {
    if (
      state?.isEditing ||
      surgicalOrderContext.surgicalOrder.surgical_order_id
    )
      return

    const surgicalOrderResponse =
      await surgicalOrderService.createSurgicalOrder({
        hospital_id: surgicalOrderContext.location.hospital?.hospital_id,
        patient: {
          patient_id: surgicalOrderContext.patient.patient?.patient_id,
          patient_name:
            surgicalOrderContext.patient.patient?.name ||
            surgicalOrderContext.patient.patient?.patient_name
        },
        surgical_center: {
          surgical_center_id:
            surgicalOrderContext.location?.surgeryCenter?.surgery_center_id ||
            surgicalOrderContext.location?.surgeryCenter?.surgical_center_id,
          surgical_center:
            surgicalOrderContext.location?.surgeryCenter?.name ||
            surgicalOrderContext.location?.surgeryCenter?.description
        },
        health_insurance: {
          health_insurance_code:
            surgicalOrderContext.healthInsurance.healthInsurance?.code,
          health_insurance_name:
            surgicalOrderContext.healthInsurance.healthInsurance?.description,
          health_insurance_card:
            surgicalOrderContext.getHealthInsurance().healthInsurance
              ?.healthCard
        }
      })
    surgicalOrderContext.setSurgicalOrder(surgicalOrderResponse)
  }

  const updateSurgicalOrder = async () => {
    if (
      // state?.isEditing ||
      surgicalOrderContext.surgicalOrder?.surgical_order_id
    )
      return

    await surgicalOrderService.updateSurgicalOrder({
      surgical_order_id:
        surgicalOrderContext.getSurgicalOrder()?.surgical_order_id,
      patient: {
        patient_id: surgicalOrderContext.getPatient().patient?.patient_id,
        patient_name:
          surgicalOrderContext.getPatient().patient?.name ||
          surgicalOrderContext.getPatient().patient?.patient_name
      },
      health_insurance: {
        health_insurance_id:
          surgicalOrderContext.getSurgicalOrder()?.health_insurance
            ?.health_insurance_id,
        health_insurance_code:
          surgicalOrderContext.getHealthInsurance().healthInsurance?.code,
        health_insurance_name:
          surgicalOrderContext.getHealthInsurance().healthInsurance
            ?.description,
        health_insurance_card:
          surgicalOrderContext.getHealthInsurance().healthInsurance?.healthCard
      }
    })
  }

  const handleSearchPatientByCPF = async () => {
    if (!isPatientRN(formik.values.birthday) || formik.errors.cpf) return

    try {
      setIsLoading(true)

      const formatedCPF = formik.values.cpf?.replace(/\D+/g, '')
      const response = await patientService.getPatientByCpfOrName({
        cpf: formatedCPF
      })

      if (response[0] && response[0]?.name) {
        formik.setFieldValue('name', response[0]?.name)
        formik.setFieldValue('email', response[0]?.user?.email)
        formik.setFieldValue('phone', response[0]?.user?.phone)
      } else {
        setMessagePatientNotFound(
          'Paciente não encontrado. Cadastre novo paciente.'
        )
        return
      }
    } catch (error: any) {
      toast.error(error?.message)
    } finally {
      setIsLoading(false)
    }
  }

  const onPressEnterKey = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.keyCode === 13) {
      handleSearchPatientByCPF()
    }
  }

  const handleCPFInputChange = () => {
    // Remove error message
    setMessagePatientNotFound('')
    // Reset form fields
    formik.setFieldValue('name', '')
    formik.setFieldValue('email', '')
    formik.setFieldValue('phone', '')
    formik.setFieldValue('health_insurance', '')
  }

  useEffect(() => {
    if (
      !state?.hospital_id &&
      !surgicalOrderContext.location?.hospital?.hospital_id
    ) {
      history.push('/home')
    } else {
      loadHealthInsurances()
    }
  }, [])

  const formik = useFormik({
    initialValues: {
      hospital_id:
        (state?.hospital_id ||
          surgicalOrderContext.location?.hospital?.hospital_id) ??
        undefined
    } as AddPatientFormValues,
    validationSchema: ValidationSchema,
    validateOnMount: true,
    validateOnChange: true,
    enableReinitialize: true,
    onSubmit: async (values) => {
      const {
        hospital_id,
        name,
        email,
        phone,
        birthday,
        cpf,
        gender,
        marital_status,
        health_insurance,
        health_insurance_card_number
      } = values

      const formatedBirthdayDate = moment(birthday, 'DD/MM/YYYY').toISOString()
      const formatedPhone = phone?.replace(/\D/g, '')
      const formatedCPF = cpf?.replace(/\D+/g, '')

      const patientPayload = {
        hospital_id: hospital_id,
        sex: gender,
        name,
        marital_status,
        birthday: formatedBirthdayDate,
        cpf: formatedCPF,
        phone: formatedPhone,
        email
      }

      const patientRNPayload = {
        hospital_id: hospital_id,
        sex: gender,
        name: `RN de ${name}`,
        marital_status: MaritalStatus.SINGLE,
        birthday: formatedBirthdayDate,
        cpf: formatedCPF,
        phone: formatedPhone,
        email
      }

      const payload = isPatientRN(birthday) ? patientRNPayload : patientPayload

      try {
        setIsLoading(true)
        await createNewPatient({
          payload,
          health_insurance,
          health_insurance_card_number
        })
        await createSurgicalOrder()
        await updateSurgicalOrder()

        if (state?.isEditing) {
          history.push('/novo-pedido/resumo')
        } else if (
          surgicalOrderContext.location.hospital?.hospital_id ===
          HOSPITAL_SALVADOR_ID
        ) {
          history.push('/novo-pedido/cooperativa')
        } else {
          history.push('/novo-pedido/procedimentos')
        }
      } catch (err: any) {
        toast.error(`[Novo paciente] ${err.message}`)
        return history.goBack()
      } finally {
        setIsLoading(false)
      }
    }
  })

  return (
    <ContainerNew
      title="Incluir paciente"
      titleColor="primary600"
      buttonsHasBoxShadow={!state?.isEditing}
      primaryButton={
        <ButtonNew
          fullWidth
          size="large"
          onClick={formik.submitForm}
          disabled={!formik.isValid || formik.isSubmitting}
        >
          {state?.isEditing ? 'Novo paciente' : 'Próximo'}
        </ButtonNew>
      }
      secondaryButton={
        <ButtonNew fullWidth size="large" outlined onClick={handleBackButton}>
          Anterior
        </ButtonNew>
      }
    >
      <S.FormWrapper
        style={{ marginTop: '24px' }}
        onSubmit={formik.handleSubmit}
      >
        <div>
          <TextFieldNew
            label="Data de nascimento"
            placeholder="Digite a data de nascimento"
            name="birthday"
            id="birthday"
            mask={dateMask}
            icon={<DateRange />}
            iconPosition="insideRight"
            iconIsButton={true}
            onIconClick={() => toggleCalendar()}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.birthday ? formik.errors.birthday : undefined}
            value={formik.values.birthday}
            required
          />
          {showCalendar && (
            <Calendar
              value={
                isValidDate(formik.values.birthday)
                  ? moment(formik.values.birthday, 'DD/MM/YYYY').toDate()
                  : undefined
              }
              onDateChange={(date) => changeDate(date)}
              maxDate={moment().toDate()}
            />
          )}

          {isValidDate(formik.values.birthday) &&
            isPatientRN(formik.values.birthday) && (
              <SupportTextNew
                style={{ marginTop: '8px' }}
                size="xsmall"
                color="primary600"
              >
                Pacientes com até 30 dias de nascimento são considerados
                recém-nascidos. Neste caso, o cadastro é feito no nome da mãe.
              </SupportTextNew>
            )}
        </div>

        {/* Show when birthday is filled */}
        {isValidDate(formik.values.birthday) &&
          isNotFutureDate(formik.values.birthday) && (
            <>
              <div>
                <SelectFieldNew
                  label="Sexo biológico"
                  placeholder="Selecione o sexo"
                  name="gender"
                  id="gender"
                  value={formik.values.gender || ''}
                  items={[
                    {
                      label: 'Masculino',
                      value: GenderNew.MALE
                    },
                    {
                      label: 'Feminino',
                      value: GenderNew.FEMALE
                    }
                  ]}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.gender && formik.errors.gender}
                  required
                />
              </div>

              <div>
                <TextFieldNew
                  label={`CPF ${
                    isPatientRN(formik.values.birthday)
                      ? 'da mãe'
                      : 'do paciente'
                  }`}
                  placeholder={`Digite o CPF ${
                    isPatientRN(formik.values.birthday) ? 'da mãe' : ''
                  }`}
                  name="cpf"
                  id="cpf"
                  mask={cpfMask}
                  onChange={formik.handleChange}
                  onInputChange={handleCPFInputChange}
                  error={
                    (formik.touched.cpf && formik.errors.cpf) ||
                    messagePatientNotFound
                  }
                  onBlur={formik.handleBlur}
                  value={formik.values.cpf || ''}
                  icon={
                    isPatientRN(formik.values.birthday) &&
                    !formik.errors.cpf && <SearchIcon />
                  }
                  iconIsButton={true}
                  onIconClick={handleSearchPatientByCPF}
                  onKeyDown={onPressEnterKey}
                  required
                />
              </div>

              {isPatientRN(formik.values.birthday) ? (
                <div>
                  <S.Label htmlFor="name">
                    Nome da mãe
                    <S.Asterisk>*</S.Asterisk>
                  </S.Label>

                  <S.RNInputWrapper>
                    <TextFieldNew
                      placeholder="RN"
                      value="RN"
                      disabled
                      readOnly
                    />
                    <TextFieldNew
                      placeholder="Informe o CPF da mãe"
                      name="name"
                      id="name"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.name || ''}
                      error={formik.touched.name && formik.errors.name}
                      disabled={isPatientRN(formik.values.birthday)}
                      readOnly={isPatientRN(formik.values.birthday)}
                      required
                    />
                  </S.RNInputWrapper>
                </div>
              ) : (
                <div>
                  <TextFieldNew
                    label="Nome do paciente"
                    placeholder="Digite o nome do paciente"
                    name="name"
                    id="name"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.name || ''}
                    error={formik.touched.name && formik.errors.name}
                    required
                  />
                </div>
              )}

              {!isPatientRN(formik.values.birthday) && (
                <div>
                  <SelectFieldNew
                    label="Estado civil"
                    placeholder="Selecione o estado civil"
                    name="marital_status"
                    id="marital_status"
                    value={formik.values.marital_status || ''}
                    items={maritalStatus}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.marital_status &&
                      formik.errors.marital_status
                    }
                    required
                  />
                </div>
              )}

              <div>
                <TextFieldNew
                  label="E-mail"
                  placeholder="Digite o e-mail"
                  name="email"
                  id="email"
                  value={formik.values.email || ''}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.email && formik.errors.email}
                  required
                />
              </div>

              <div>
                <TextFieldNew
                  label="Celular"
                  placeholder="+55 xx xxxxxxxxx"
                  name="phone"
                  id="phone"
                  value={formik.values.phone || ''}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  defaultValue="+55"
                  mask={phoneMask}
                  error={formik.touched.phone && formik.errors.phone}
                  required
                />
              </div>

              <div>
                <SelectFieldNew
                  label="Convênio"
                  placeholder="Selecione o convênio"
                  name="health_insurance"
                  id="health_insurance"
                  items={sortBy(
                    healthInsurances.map((healthInsurance) => ({
                      label: healthInsurance.description,
                      value: healthInsurance.code
                    })),
                    (val) => val.label
                  )}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.health_insurance
                      ? formik.errors.health_insurance
                      : undefined
                  }
                  required
                  value={formik.values.health_insurance || ''}
                />
              </div>

              <div>
                <TextFieldNew
                  label="Número da carteirinha"
                  placeholder="Digite o número da carteirinha"
                  name="health_insurance_card_number"
                  id="health_insurance_card_number"
                  value={formik.values.health_insurance_card_number}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              </div>
            </>
          )}
      </S.FormWrapper>
    </ContainerNew>
  )
})

export default AddPatientForm
