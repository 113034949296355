import React, { useContext, useEffect, useState } from 'react'
import * as S from './styles'
import SupportTextNew from 'presentation/shared/components/SupportTextNew'
import DocumentAccordeon from 'presentation/shared/layouts/DocumentAccordeon'
import Procedures from './Accordions/Procedures'
import ClinicalHistory from './Accordions/ClinicalHistory'
import ClinicalRecord from './Accordions/ClinicalRecord'
import GeneralComments from './Accordions/GeneralComments'
import OpmeAndSuppliers from './Accordions/OpmeAndSuppliers'
import PatientAndInsuranceData from './Accordions/PatientAndInsuranceData'
import QueueNumber from './Accordions/QueueNumber'
import { ReactComponent as CheckCircle } from 'presentation/assets/icons/check-circle.svg'
import { ReactComponent as AlertCircle } from 'presentation/assets/icons/alert-circle.svg'
import { ReactComponent as Arrow } from 'presentation/assets/icons/v-arrow.svg'
import { SurgicalOrderContext } from 'main/factories/pages/doctor/surgical-order/surgical-order-factory'
import { useServices } from 'presentation/hooks/use-services'
import { toast } from 'react-toastify'
import {
  WithLoading,
  WithLoadingProps
} from 'presentation/shared/components/HOCs/WithLoading'
import { renameFile } from 'common/utils/file/renameFile'
import { Document } from 'domain/entities/document'
import { DocumentsList } from 'common/enum/documents-list'
import downloadFileFromBlob from 'common/utils/downloadFileFromBlob'
import moment from 'moment-timezone'
import AccordionCardNew from 'presentation/shared/components/AccordionCardNew'
import { OpenSurgicalOrderPatientDocument } from 'common/enum/patient-document'
import { ReportsCard } from 'presentation/shared/layouts/ReportsCard'
import { Patient } from 'domain/entities/patient-model'
import { Cooperative } from './Accordions/Coopertive'
import { SurgicalOrderNew } from 'domain/entities/surgical-order-new'
import { AnesthesiologyType } from 'domain/usecases/surgical-order/load-guides-by-type'
import { HealthInsurancePlans } from 'domain/usecases/health-insurance/load-health-insurance-plans'
import { Opme } from 'domain/entities/surgical-order-opme-model'
import { Separator } from 'presentation/shared/components/Separator'
import UploadButtonNew from 'presentation/shared/components/UploadButtonNew'
import { SurgicalOrderStatus } from 'presentation/shared/components/Timeline/status'
import { getSurgicalOrderStatusLabelByEnum } from 'presentation/utils/surgical-order-status'
import { useHistory } from 'react-router'

type Props = WithLoadingProps

const PlanningTab = ({ setIsLoading }: Props) => {
  const { state } = useContext<any>(SurgicalOrderContext)
  const [finishedAccordionOpen, setFinishedAccordionOpen] = useState('')
  const [informationAccordionOpen, setInformationAccordionOpen] = useState('')
  const [mainInformationAccordionOpen, setMainInformationAccordionOpen] =
    useState('')
  const [mainReportsAccordionOpen, setMainReportsAccordionOpen] = useState('')
  const [mainOtherDocumentsAccordionOpen, setMainOtherDocumentsAccordionOpen] =
    useState('')
  const [responseData, setResponseData] = useState<SurgicalOrderNew>(
    {} as SurgicalOrderNew
  )
  const [documents, setDocuments] = useState<Document[]>([])
  const [opmeProviders, setOpmeProviders] = useState<string[]>([])
  const [opmeSolicitation, setOpmeSolicitation] = useState<Opme[]>([])
  const [opmeFinalized, setOpmeFinalized] = useState<any[]>()
  const [anesthesiology, setAnesthesiology] = useState<AnesthesiologyType[]>([])
  const [reportsAccordionOpen] = useState('')
  const surgicalOrderService = useServices().surgicalOrder
  const HOSPITAL_SALVADOR_ID = 8
  const surgicalOrderStatusReview = getSurgicalOrderStatusLabelByEnum(
    SurgicalOrderStatus.REVIEW
  )
  const surgicalOrderStatusOpmeQuatation = getSurgicalOrderStatusLabelByEnum(
    SurgicalOrderStatus.OPME_QUOTATION
  )
  const history = useHistory()
  const isSurgicalOrderReview =
    responseData.active_status === surgicalOrderStatusReview ||
    responseData.active_status === surgicalOrderStatusOpmeQuatation

  if (!state.card.surgical_order_id) {
    toast.error('Ocorreu um erro ao carregar o planejamento cirúrgico')
    history.push('/home')
  }

  const getSurgicalOrderByIdForDocuments = async () => {
    try {
      setIsLoading(true)
      const response = await surgicalOrderService.getSurgicalOrderById({
        surgical_order_id: state.card.surgical_order_id
      })
      setResponseData(response)
      setDocuments(response.documents)
      setOpmeProviders(response.opme?.providers as string[])
      setOpmeSolicitation(response.opme?.solicitations as [])
      setOpmeFinalized(response.finalized?.opme)
      setAnesthesiology(response.anesthesiologies)

      return response
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoading(false)
    }
  }

  const documentsList = () => {
    const mappedDocs = mappedDocuments()

    return Object.keys(OpenSurgicalOrderPatientDocument).map((document) => {
      const docs = mappedDocs
        .filter((doc: any) => doc.type === document)
        .sort((a: any, b: any) => {
          if (moment(a.sendedAt).isAfter(b.sendedAt)) return -1
          else if (moment(a.sendedAt).isBefore(b.sendedAt)) return 1
          else return 0
        })

      const filesList = docs.reduce(
        (acc: any, curr: any) => [...acc, ...curr.documents],
        []
      )

      return {
        label: DocumentsList[document as keyof typeof DocumentsList],
        type: document,
        status: docs[0]?.status,
        documents: filesList
      }
    })
  }

  const mappedDocuments = () => {
    return (
      documents.map((document: Document) => ({
        type: document.type,
        status: document.status,
        documents: [
          {
            ...document,
            sended_at: document.sendedAt,
            status_updated_by: {
              name: document.updated_by?.name
            }
          }
        ]
      })) || []
    )
  }

  useEffect(() => {
    getSurgicalOrderByIdForDocuments()
  }, [])

  const handleChangeInformationAccordion = (
    label: string,
    isExpanded: boolean
  ) => {
    setInformationAccordionOpen(!isExpanded ? '' : label)
  }

  const handleChangeMainInformationAccordion = (
    label: string,
    isExpanded: boolean
  ) => {
    setMainInformationAccordionOpen(!isExpanded ? '' : label)
  }

  const handleChangeMainReportsAccordion = (
    label: string,
    isExpanded: boolean
  ) => {
    setMainReportsAccordionOpen(!isExpanded ? '' : label)
  }

  const handleChangeMainOtherDocumentsAccordion = (
    label: string,
    isExpanded: boolean
  ) => {
    setMainOtherDocumentsAccordionOpen(!isExpanded ? '' : label)
  }

  const handleChangeFinishedAccordion = (
    label: string,
    isExpanded: boolean
  ) => {
    setFinishedAccordionOpen(!isExpanded ? '' : label)
  }
  const uploadDocument = async (files: any, type: string) => {
    setIsLoading(true)
    try {
      const file = files[0]
      const form = new FormData()
      const newFile = renameFile(file, file.name)
      form.append('file', newFile)
      form.append('type', type)
      form.append('surgical_order_id', String(state.card.surgical_order_id))

      await surgicalOrderService.uploadSurgicalOrderDocumentNew({
        form: form
      })
      await getSurgicalOrderByIdForDocuments()
    } catch (error) {
      toast.error('Ocorreu um erro ao enviar o documento')
    } finally {
      setIsLoading(false)
    }
  }

  const deleteDocument = async (document_id: number) => {
    setIsLoading(true)
    try {
      await surgicalOrderService.deleteUploadedDocumentsNew({
        document_id
      })

      await getSurgicalOrderByIdForDocuments()
    } catch (error) {
      toast.error('Ocorreu um erro ao deletar o documento')
    } finally {
      setIsLoading(false)
    }
  }

  const downloadDocument = async (
    document_id: number,
    _group_id: string,
    type: keyof typeof DocumentsList
  ) => {
    setIsLoading(true)
    try {
      const surgicalOrderId = state.card.surgical_order_id

      const file = await surgicalOrderService.loadSurgicalOrderDocument({
        surgical_order_id: surgicalOrderId,
        document_id
      })

      if (file) {
        downloadFileFromBlob(file.data, file.contentType, DocumentsList[type])
      }
    } catch {
      toast.error('Ocorreu um erro ao baixar o documento')
    } finally {
      setIsLoading(false)
    }
  }

  const finishedItems = [
    <QueueNumber
      key="QueueNumber"
      expanded={finishedAccordionOpen === 'QueueNumber'}
      handleChange={handleChangeFinishedAccordion}
      password={responseData.finalized?.password_to_finish}
      expirationText={
        responseData.finalized?.password_max_date_validation_status
      }
      expirationDate={
        responseData.finalized?.password_max_date_validation as any
      }
      passwordObservation={
        responseData.finalized?.password_max_string_validation
      }
      icon={<CheckCircle />}
    />,
    <Procedures
      isFinished
      key="ProceduresAndCid"
      expanded={informationAccordionOpen === 'Procedures'}
      handleChange={handleChangeInformationAccordion}
      procedures={responseData.finalized?.procedures as any}
      cids={responseData.cids as any}
      proceduresObservation={responseData.finalized?.procedure_observation}
      icon={<CheckCircle />}
    />,
    <OpmeAndSuppliers
      isFinished
      key="OpmeAndSuppliers"
      expanded={informationAccordionOpen === 'OpmeAndSuppliers'}
      handleChange={handleChangeInformationAccordion}
      opmeSolicitations={opmeSolicitation}
      opmeProvider={opmeProviders}
      opmeFinalized={opmeFinalized}
      anesthesiology={anesthesiology}
      opmeObservation={responseData.finalized?.opme_observation}
      opmeDetails={responseData.finalized?.opme_details}
      icon={<CheckCircle />}
    />,
    <GeneralComments
      key="GeneralComments"
      expanded={finishedAccordionOpen === 'GeneralComments'}
      handleChange={handleChangeFinishedAccordion}
      comment={responseData.finalized?.general_observation}
      icon={<CheckCircle />}
    />
  ]

  const informationItems = [
    <PatientAndInsuranceData
      key="PatientAndInsuranceData"
      expanded={informationAccordionOpen === 'PatientAndInsuranceData'}
      handleChange={handleChangeInformationAccordion}
      patient={responseData.patient as Patient}
      healthInsurance={responseData.health_insurance as HealthInsurancePlans}
      icon={<CheckCircle />}
    />,
    responseData.hospital?.hospital_id === HOSPITAL_SALVADOR_ID && (
      <Cooperative
        cooperative={responseData.cooperative_name}
        icon={<CheckCircle />}
      />
    ),
    <Procedures
      key="ProceduresAndCid"
      expanded={informationAccordionOpen === 'Procedures'}
      handleChange={handleChangeInformationAccordion}
      procedures={responseData.procedures as any}
      procedureDetails={state.planning.procedure_details}
      cids={responseData.cids as any}
      icon={<CheckCircle />}
    />,
    <OpmeAndSuppliers
      key="OpmeAndSuppliers"
      expanded={informationAccordionOpen === 'OpmeAndSuppliers'}
      handleChange={handleChangeInformationAccordion}
      opmeSolicitations={opmeSolicitation}
      opmeProvider={opmeProviders}
      anesthesiology={anesthesiology}
      icon={<CheckCircle />}
    />,
    <ClinicalHistory
      key="ClinicalHistory"
      expanded={informationAccordionOpen === 'ClinicalHistory'}
      handleChange={handleChangeInformationAccordion}
      clinicalHistory={responseData.clinical_history?.clinical_history}
      allergy={responseData.clinical_history?.allergy as string[]}
      bloodTransfusion={responseData.clinical_history?.blood_transfusion}
      oncologyOrResearch={responseData.clinical_record?.oncology_or_research}
      psychologicalAspects={
        responseData.clinical_history?.psychological_aspects
      }
      icon={<CheckCircle />}
    />,
    <ClinicalRecord
      key="ClinicalRecord"
      expanded={informationAccordionOpen === 'ClinicalRecord'}
      handleChange={handleChangeInformationAccordion}
      hospitalizationLocation={
        responseData.clinical_record?.locale_hospitalization
      }
      cti={state.planning.cti}
      freeze={state.planning.freeze}
      extraBlood={state.planning.extraBlood}
      expectedDate={responseData.expected_date as any}
      technique={responseData.clinical_record?.technique as any}
      patientHospitalized={state.planning.patientHospitalized}
      bullishForecastDate={
        responseData.clinical_record?.bullish_forecast_date as any
      }
      hospitalizationType={
        responseData.clinical_record?.hospitalization_type as any
      }
      hospitalizationMode={
        responseData.clinical_record?.hospitalization_mode as any
      }
      requestedDailies={responseData.clinical_record?.requested_dailies as any}
      icon={<CheckCircle />}
    />
  ]

  const filteredReports = documents.filter(
    (document) =>
      document.type === OpenSurgicalOrderPatientDocument['Laudos dos exames']
  )

  const documentsNotToShow = ['LAUDO DE EXAME', 'Laudos dos exames']

  const documentsNotToUpload = ['PLANEJAMENTO CIRÚRGICO', 'Guia de autorização']

  return (
    <S.TabWrapper>
      {['CONCLUDED', 'AUTHORIZED', 'CANCELLED'].includes(
        state.card.status[state.card.status.length - 1].status
      ) ? (
        <S.PlanningSection>
          <AccordionCardNew
            header={'Finalização'}
            expanded={mainInformationAccordionOpen === 'Fineshed'}
            accordionLabel="Fineshed"
            handleChange={handleChangeMainInformationAccordion}
            gap="8px"
          >
            {finishedItems.map((item) => item)}
          </AccordionCardNew>
          <Separator />
        </S.PlanningSection>
      ) : (
        <S.PlanningSection>
          <AccordionCardNew
            header={'Informações'}
            expanded={mainInformationAccordionOpen === 'Informations'}
            accordionLabel="Informations"
            handleChange={handleChangeMainInformationAccordion}
            gap="8px"
          >
            {state.card.active_status === 'ORDER_OPEN' ? (
              <S.ActionCard onClick={() => console.log('click')}>
                <div className="card-title">
                  <AlertCircle />
                  <SupportTextNew
                    color="neutral800"
                    weight="semiBold"
                    size="medium"
                  >
                    Ficha clinica
                  </SupportTextNew>
                </div>
                <Arrow />
              </S.ActionCard>
            ) : (
              informationItems.map((item) => item)
            )}
          </AccordionCardNew>
          <Separator />
        </S.PlanningSection>
      )}

      <S.PlanningSection>
        <AccordionCardNew
          header={'Laudos'}
          expanded={mainReportsAccordionOpen === 'Reports'}
          accordionLabel="Reports"
          handleChange={handleChangeMainReportsAccordion}
          gap="8px"
        >
          {filteredReports.length > 0 && (
            <ReportsCard
              key={filteredReports[0].type}
              documents={filteredReports}
              downloadDocument={downloadDocument}
              informationAccordionOpen={reportsAccordionOpen}
              planningTab
              showStatus={false}
            />
          )}

          {responseData.report_recommendation && (
            <S.ReportsRecommendations>
              <h1>Laudo indicado</h1>
              <span>{responseData.report_recommendation}</span>
            </S.ReportsRecommendations>
          )}

          {!responseData.report_recommendation &&
            filteredReports.length === 0 && (
              <S.NoReport>Nenhum laudo foi incluído.</S.NoReport>
            )}

          {isSurgicalOrderReview &&
            documentsList()?.map(
              (document) =>
                document.type ===
                  OpenSurgicalOrderPatientDocument['Laudos dos exames'] && (
                  <UploadButtonNew
                    onInput={(files: any) =>
                      uploadDocument(files, document.type)
                    }
                    name={document.type}
                    outlined
                    multiple={false}
                    fullWidth
                    size="large"
                    style={{
                      marginTop: '16px',
                      display: 'flex',
                      justifyItems: 'center',
                      alignItems: 'center'
                    }}
                  >
                    {<S.ButtonSpan>Incluir laudos</S.ButtonSpan>}
                  </UploadButtonNew>
                )
            )}
        </AccordionCardNew>
        <Separator />
      </S.PlanningSection>

      <S.PlanningSection>
        <AccordionCardNew
          header={'Outros Documentos'}
          expanded={mainOtherDocumentsAccordionOpen === 'OthersDocuments'}
          accordionLabel="OthersDocuments"
          handleChange={handleChangeMainOtherDocumentsAccordion}
          gap="8px"
        >
          {documentsList()
            .filter((document) => !documentsNotToShow.includes(document.type))
            .map((document, index) => (
              <DocumentAccordeon
                gap="8px"
                key={index}
                document={document}
                shadow="satin"
                padding="18px"
                onDownloadFile={downloadDocument}
                onUploadFile={(files: any) =>
                  uploadDocument(files, document.type)
                }
                onDeleteFile={deleteDocument}
                expandedDocument={informationAccordionOpen}
                handleChange={handleChangeInformationAccordion}
                uploadButton={
                  isSurgicalOrderReview &&
                  !documentsNotToUpload.includes(document.type)
                }
                showStatus={false}
              />
            ))}
        </AccordionCardNew>
        <Separator />
      </S.PlanningSection>
    </S.TabWrapper>
  )
}

export default WithLoading(PlanningTab)
