import theme from 'presentation/styles/theme'
import styled, { css } from 'styled-components'

export type VerticalDividerProps = {
  marginLeft?: string
  marginRight?: string
  width?: string
  leak?: string
  color?: keyof typeof theme.ds.colors
}

export const VerticalDivider = styled.div<VerticalDividerProps>`
  ${({
    marginLeft = '4px',
    marginRight = '4px',
    width = '1px',
    leak = '0px',
    color = 'neutral200'
  }) => css`
    background-color: ${theme.ds.colors[color]};
    height: calc(100% + (${leak} * 2));
    margin-top: calc(${leak} * -1);
    width: ${width};
    margin-left: ${marginLeft};
    margin-right: ${marginRight};
  `}
`
