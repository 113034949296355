import { LoadSurgeryCenters } from 'domain/usecases/surgery-center/load-surgery-centers'
import { SurgeryCenterRepository } from 'repository/interfaces/surgery-center-repository'

export class RemoteLoadSurgeryCenters implements LoadSurgeryCenters {
  constructor(
    private readonly surgeryCenterRepository: SurgeryCenterRepository
  ) {
    this.surgeryCenterRepository = surgeryCenterRepository
  }

  load(params: LoadSurgeryCenters.Params): Promise<LoadSurgeryCenters.Model> {
    return this.surgeryCenterRepository.loadSurgeryCenters(params)
  }
}
