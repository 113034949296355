import { ApiSchedulingImplementation } from 'infra/api-scheduling/api-scheduling-client'
import { PrepareApiRequestSchedulingDecorator } from 'main/decorators/prepare-api-request-scheduling-decorator/prepare-api-request-scheduling-decorator'
import { makeLocalStorageAdapter } from 'main/factories/cache/local-storage-adapter-factory'
import { ApiSchedulingRepository } from 'repository/api-scheduling-repository'
import { SchedulingRepository } from 'repository/repositories/scheduling/scheduling-repository'
import { RemoteSearchScheduling } from 'service/usecases/scheduling/remote-search-schedules/remote-search-scheduling'
import { RemoteGetRecentAppointments } from 'service/usecases/scheduling/remote-get-recent-appointments/remote-get-recent-appointments'
import { RemoteFinishSchedule } from 'service/usecases/scheduling/remote-finish-schedule/remote-finish-schedule'

import { RemoteGetAppointmentsByMonth } from 'service/usecases/scheduling/remote-get-appointments-by-month/remote-get-appointments-by-month'
import { RemoteGetHoursByDay } from 'service/usecases/scheduling/remote-get-hours-by-day/remote-get-hours-by-day'
import { SchedulingService } from 'presentation/contexts/services/scheduling-service'
import { RemoteGetReasonCancel } from 'service/usecases/scheduling/remote-get-reason-cancel/remote-get-reason-cancel'
import { RemoteCancelSchedule } from 'service/usecases/scheduling/remote-cancel-schedule/remote-cancel-schedule'

export default function makeSchedulingService() {
  const apiScheduling = new ApiSchedulingImplementation()
  const apiSchedulingRepository = new PrepareApiRequestSchedulingDecorator(
    makeLocalStorageAdapter(),
    new ApiSchedulingRepository(apiScheduling)
  )
  const schedulingRepository = new SchedulingRepository(apiSchedulingRepository)

  const searchFiltersSchedules = new RemoteSearchScheduling(
    schedulingRepository
  )
  const getRecentAppointments = new RemoteGetRecentAppointments(
    schedulingRepository
  )
  const finishSchedule = new RemoteFinishSchedule(schedulingRepository)
  const getAppointmentsByMonth = new RemoteGetAppointmentsByMonth(
    schedulingRepository
  )
  const getHoursByDay = new RemoteGetHoursByDay(schedulingRepository)
  const getReasonCancel = new RemoteGetReasonCancel(schedulingRepository)
  const cancelSchedule = new RemoteCancelSchedule(schedulingRepository)

  return new SchedulingService({
    searchFiltersSchedules,
    getRecentAppointments,
    finishSchedule,
    getAppointmentsByMonth,
    getHoursByDay,
    getReasonCancel,
    cancelSchedule
  })
}
