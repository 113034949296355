import React, { useState } from 'react'

import * as S from './styles'
import moment from 'moment-timezone'
import {
  ScheduleData,
  ScheduleDataTypes
} from 'domain/usecases/doctor/load-doctor-schedule'
import { SurgicalOrderModel } from 'domain/entities/surgical-order-model'
import { Schedule } from 'domain/entities/schedule-model'
import { getStatusInfo } from 'presentation/utils/service-status'
import { Hospital } from 'domain/entities/hospital-model'
import { DoctorScheduleModal } from '../DoctorScheduleModal'
import { getScheduleStatus } from 'presentation/utils/schedule-status'
import { ScheduleType, ScheduleTypeTitle } from 'common/enum/schedule-type'

export type CardContentProps = {
  type: ScheduleType
  label: string
  status: string
  title: string
  patient: string
  date: string
  hospital: string
  endDate: string
}

export function DoctorScheduleCard({ type, data, date }: ScheduleData) {
  const [showModal, setShowModal] = useState<boolean>(false)
  const content = dataAdapter(type, data)

  const wsRegex = /^\s+|\s+$/g // Remove espaços em branco no inicio e no final da string

  const titleWords = content.title
    ?.replace(wsRegex, '')
    .toLowerCase()
    .split(' ')

  // Capitalize title words
  for (let i = 0; i < titleWords?.length; i++) {
    titleWords[i] = titleWords[i][0].toUpperCase() + titleWords[i].substring(1)
  }

  const formattedTitle = titleWords?.join(' ')
  const formattedPatientName = content.patient
    ?.replace(wsRegex, '')
    .toLowerCase()

  return (
    <>
      <S.Wrapper
        type={type}
        onClick={() => setShowModal(true)}
        data-testid="doctor-schedule-card-wrapper"
      >
        <h6 className="schedule-type">{content.label}</h6>
        <span className="schedule-time">
          {moment.utc(content.date).format('HH[h]mm')}
          {content.endDate != '-'
            ? `- ${moment.utc(content.endDate).format('HH[h]mm')}`
            : ''}
        </span>
        <h4 className="schedule-title">{formattedTitle}</h4>
        <div className="schedule-footer">
          <div className="box-item">
            <span className="title">Paciente</span>
            <p className="description">{formattedPatientName}</p>
          </div>
          <div className="box-item">
            <span className="title">Unidade</span>
            <p className="description">{content.hospital}</p>
          </div>
        </div>
      </S.Wrapper>
      <DoctorScheduleModal
        show={showModal}
        data={{ type, date, data }}
        onClose={() => setShowModal(false)}
      />
    </>
  )
}

function dataAdapter(
  type: ScheduleType,
  data: ScheduleDataTypes
): CardContentProps {
  const response: CardContentProps = {
    patient: data.patientName,
    hospital:
      new Hospital(0, data.hospitalName ?? '', data.hospitalName ?? '').name ||
      ''
  } as CardContentProps

  switch (type) {
    case ScheduleType.SURGICAL:
      data = data as SurgicalOrderModel
      response.label = ScheduleTypeTitle.SURGICAL
      response.title = data.procedure?.[0].description || '-'
      response.date = data.surgeryDate || '-'
      response.endDate = data.surgeryEndDate || '-'
      response.status =
        getStatusInfo(data?.status?.slice(-1)?.[0]?.status)?.label || '-'
      break
    case ScheduleType.AMBULATORY:
      data = data as Schedule
      response.label = ScheduleTypeTitle.AMBULATORY
      response.title = data.scheduleItemDescription || '-'
      response.status = getScheduleStatus(data.status)
      // eslint-disable-next-line no-constant-condition
      //@ts-ignore
      response.date =
        typeof data.scheduleTime === 'string'
          ? data.scheduleTime
          : data.scheduleTime.toISOString() || '-'
      response.endDate =
        typeof data.scheduleEndDate === 'string'
          ? data.scheduleEndDate
          : data.scheduleEndDate?.toISOString() || '-'
      break
    default:
      return response
  }
  return response
}
