import styled, { css, DefaultTheme } from 'styled-components'

import { ButtonProps } from '.'

export type WrapperProps = Pick<
  ButtonProps,
  | 'variant'
  | 'weight'
  | 'size'
  | 'fontSize'
  | 'outlined'
  | 'fullWidth'
  | 'noPadding'
>

export type IconProps = Pick<ButtonProps, 'iconPosition'>

const variants = {
  default: (
    theme: DefaultTheme,
    weight?: keyof typeof theme.ds.typography.weight,
    outlined?: boolean,
    disabled?: boolean
  ) => css`
    color: ${theme.ds.colors.white};
    background-color: ${theme.ds.colors.primary600};
    font-weight: ${weight
      ? theme.ds.typography.weight?.[weight!]
      : theme.ds.typography.weight.bold};
    border: none;

    svg,
    path {
      fill: ${theme.ds.colors.white};
    }

    &:hover {
      background-color: ${theme.ds.colors.primary500};
    }

    &:active {
      background-color: ${theme.ds.colors.primary700};
    }

    ${outlined &&
    css`
      color: ${theme.ds.colors.primary600};
      background-color: ${theme.ds.colors.transparent};
      border: 1px solid ${theme.ds.colors.primary600};

      svg,
      path {
        fill: ${theme.ds.colors.primary600};
      }

      &:hover {
        color: ${theme.ds.colors.primary500};
        background-color: ${theme.ds.colors.transparent};
        border-color: ${theme.ds.colors.primary500};

        svg,
        path {
          fill: ${theme.ds.colors.primary500};
        }
      }

      &:active {
        color: ${theme.ds.colors.primary700};
        background-color: ${theme.ds.colors.transparent};
        border-color: ${theme.ds.colors.primary700};

        svg,
        path {
          fill: ${theme.ds.colors.primary700};
        }
      }
    `}

    ${disabled &&
    css`
      pointer-events: none;
      color: ${theme.ds.colors.neutral400};
      background-color: ${theme.ds.colors.neutral200};

      svg,
      path {
        fill: ${theme.ds.colors.neutral400};
      }
    `}

    ${outlined &&
    disabled &&
    css`
      pointer-events: none;
      color: ${theme.ds.colors.neutral400};
      border: 1px solid ${theme.ds.colors.neutral400};
      background-color: ${theme.ds.colors.transparent};

      svg,
      path {
        fill: ${theme.ds.colors.primary400};
      }
    `}
  `,

  text: (
    theme: DefaultTheme,
    weight?: keyof typeof theme.ds.typography.weight,
    disabled?: boolean,
    fontSize?: keyof typeof theme.ds.typography.size
  ) => css`
    color: ${theme.ds.colors.primary600};
    background-color: ${theme.ds.colors.transparent};
    font-weight: ${weight
      ? theme.ds.typography.weight?.[weight!]
      : theme.ds.typography.weight.bold};
    border: none;
    font-size: ${fontSize && theme.ds.typography.size?.[fontSize!]} !important;

    svg,
    path {
      fill: ${theme.ds.colors.primary600};
    }

    &:hover {
      color: ${theme.ds.colors.primary500};

      svg,
      path {
        fill: ${theme.ds.colors.primary500};
      }
    }

    &:active {
      color: ${theme.ds.colors.primary700};

      svg,
      path {
        fill: ${theme.ds.colors.primary700};
      }
    }

    ${disabled &&
    css`
      pointer-events: none;
      color: ${theme.ds.colors.neutral400};
      background-color: ${theme.ds.colors.transparent};

      svg,
      path {
        fill: ${theme.ds.colors.neutral400};
      }
    `}
  `
}

const sizes = {
  small: (theme: DefaultTheme, size?: 'small' | 'medium' | 'large') => css`
    height: 32px;
    padding: 8px;
    font-size: ${size
      ? theme.ds.typography.size?.[size!]
      : theme.ds.typography.size.xsmall};

    svg,
    path {
      height: 32px;
    }
  `,

  medium: (theme: DefaultTheme, size?: 'small' | 'medium' | 'large') => css`
    height: 40px;
    padding: 8px 24px;
    font-size: ${size
      ? theme.ds.typography.size?.[size!]
      : theme.ds.typography.size.small};

    svg,
    path {
      height: 40px;
    }
  `,

  large: (theme: DefaultTheme, size?: 'small' | 'medium' | 'large') => css`
    height: 48px;
    padding: 8px 32px;
    font-size: ${size
      ? theme.ds.typography.size?.[size!]
      : theme.ds.typography.size.small};

    svg,
    path {
      height: 48px;
    }
  `
}

export const Wrapper = styled.button<WrapperProps>`
  ${({
    theme,
    variant,
    size,
    fontSize,
    weight,
    outlined,
    noPadding,
    fullWidth,
    disabled
  }) => css`
    font-family: ${theme.ds.typography.fontFamily};
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: ${fullWidth ? '100%' : 'unset'};
    line-height: ${theme.ds.typography.lineHeight.medium};
    border-radius: ${theme.ds.typography.border.radius.small};
    outline: none;
    text-decoration: none;
    flex-shrink: 0;
    transition: all ease 0.1s;

    span {
      display: flex;
      align-items: center;
    }

    ${variant === 'default' &&
    variants[variant](theme, weight, outlined, disabled)}
    ${variant === 'text' &&
    variants[variant](theme, weight, disabled, fontSize)}
    ${!!size && sizes[size](theme)}
    ${!!noPadding &&
    css`
      padding: 0 !important;
      height: auto !important;
    `}
  `}
`

export const Icon = styled.span<IconProps>`
  ${({ iconPosition }) => {
    const posLeft = iconPosition === 'left'
    const posRight = iconPosition === 'right'

    return css`
      order: ${posLeft ? 0 : 1};
      margin-left: ${posLeft ? '0' : '8px'};
      margin-right: ${posRight ? '0' : '8px'};

      svg,
      path {
        display: flex;
        align-items: center;
        max-width: 24px;
        max-height: 24px;
        opacity: 1;
        transition: all ease 0.1s;
      }
    `
  }}
`
