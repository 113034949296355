import styled, { css } from 'styled-components'

export const Wrapper = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 12px;
  button {
    margin-top: 24px;
  }

  div {
    width: 100%;
  }
`

export const Row = styled.div<{ cols: number }>`
  margin-bottom: 15px;
  ${({ cols }) => css`
    width: 100%;
    display: grid;
    gap: 2%;
    align-items: flex-end;
    grid-template-rows: 1fr;
    grid-template-columns: ${`repeat(${cols}, 1fr)`};
    margin-top: 12px;

    textarea,
    div {
      width: 100%;
    }
  `}
`

export const BodyInfoLabel = styled.small`
  color: ${({ theme }) => theme.colors.lightGray};
  height: 8px;
  width: 1px;
  display: block;
  margin-right: 10px;
`

export const TypeTextField = styled.div`
  & > div > div > div {
    width: auto;
    height: auto;
  }
`

export const LineDivider = styled.hr`
  height: 0px;
  border: 1px solid #dadee2;
  background: #dadee2;
  margin-top: 12px;
  width: 100%;
  margin: 40px 0;
`
