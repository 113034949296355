import { UploadTherapeuticPlanDocument } from 'domain/usecases/therapeutic-plan/upload-therapeutic-plan-document'
import TherapeuticPlanAttachmentsForm from 'presentation/doctor/components/Forms/AddTherapeuticPlan/NewTherapeuticPlan/TherapeuticPlanAttachments'
import { Container } from 'presentation/shared/components/Container'
import Header from 'presentation/shared/components/Header'
import React from 'react'

type Props = {
  uploadDocument: UploadTherapeuticPlanDocument
}

export default function TherapeuticPlanAttachments({ uploadDocument }: Props) {
  return (
    <>
      <Header />
      <Container>
        <TherapeuticPlanAttachmentsForm uploadDocument={uploadDocument} />
      </Container>
    </>
  )
}
