import React from 'react'
import UpdateSecretaryProfileForm from 'presentation/secretary/components/Forms/UpdateProfile'
import { User } from 'domain/entities/user-model'

type Props = {
  data: User
  setOpenSuccessModal: React.Dispatch<React.SetStateAction<boolean>>
}
export default function UpdateSecretaryInfoLayout({
  data,
  setOpenSuccessModal
}: Props) {
  return (
    <>
      <UpdateSecretaryProfileForm
        data={data}
        setOpenSuccessModal={setOpenSuccessModal}
      />
    </>
  )
}
