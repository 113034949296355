import styled from 'styled-components'

export const RadioGroup = styled.div<{
  isColumn?: boolean
  isReverse?: boolean
}>`
  display: flex;
  flex-direction: ${({ isColumn, isReverse }) =>
    isColumn
      ? isReverse
        ? 'column-reverse'
        : 'column'
      : isReverse
      ? 'row-reverse'
      : 'row'};
  gap: ${({ isColumn }) => (isColumn ? '8px' : '0')};

  div {
    width: 100%;
  }
`
