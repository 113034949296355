import styled from 'styled-components'

export const Wrapper = styled.div<{
  concluded?: boolean
}>`
  width: 100%;
  box-shadow: 2px 2px 8px -4px rgba(0, 0, 0, 0.1);
  padding: 21px;
  border-radius: ${({ theme }) => theme.border.radius.medium};
  background-color: ${({ theme }) => theme.colors.white};
  color: ${({ theme, concluded }) =>
    concluded ? theme.colors.lightGray : theme.colors.primary};
  margin-top: 10px;

  div {
    display: flex;
    justify-content: space-between;
  }
`

export const PatientInfo = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: ${({ theme }) => theme.font.sizes.xsmall};

  strong {
    font-weight: ${({ theme }) => theme.font.bold};
  }

  label {
    font-weight: ${({ theme }) => theme.font.normal};
  }

  & + & {
    margin-top: 4px;
  }
`

export const PatientInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
`
