import React from 'react'
import { ScheduleType } from 'common/enum/schedule-type'
import { Schedule } from 'domain/entities/schedule-model'
import { SurgicalOrderModel } from 'domain/entities/surgical-order-model'
import { ScheduleData } from 'domain/usecases/doctor/load-doctor-schedule'
import { AmbulatoryScheduleDetails } from './AmbulatoryScheduleDetails'
import { SurgicalScheduleDetails } from './SurgicalScheduleDetails'
import SheetModalNew from 'presentation/shared/components/SheetModalNew'
// import ButtonNew from 'presentation/shared/components/ButtonNew'
// import * as S from './styles'

type DoctorScheduleModalProps = {
  show: boolean
  onClose: () => void
  data: ScheduleData
}

export const DoctorScheduleModal = ({
  show,
  onClose,
  data
}: DoctorScheduleModalProps) => {
  const getComponent = () => {
    switch (data?.type) {
      case ScheduleType.AMBULATORY:
        return <AmbulatoryScheduleDetails data={data?.data as Schedule} />
      case ScheduleType.SURGICAL:
        return (
          <SurgicalScheduleDetails data={data?.data as SurgicalOrderModel} />
        )
      default:
        return <></>
    }
  }

  return (
    <SheetModalNew title="Detalhes" isOpen={show} onClose={onClose}>
      {getComponent()}
    </SheetModalNew>
  )
}
