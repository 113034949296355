import React, { AnchorHTMLAttributes } from 'react'
import * as S from './styles'
import theme from '../../../styles/theme'

type HeadingTypes = AnchorHTMLAttributes<HTMLAnchorElement>

export type HeadingProps = {
  children: React.ReactNode
  color?: keyof typeof theme.ds.colors
  size?: keyof typeof theme.ds.typography.size
  weight?: keyof typeof theme.ds.typography.weight
  flexStart?: boolean
  as?: React.ElementType
  required?: boolean
  requiredColor?: keyof typeof theme.ds.colors
} & HeadingTypes

const HeadingNew = ({
  children,
  required,
  color = 'neutral900',
  size = 'medium',
  weight = 'bold',
  requiredColor = 'danger200',
  ...props
}: HeadingProps) => (
  <S.Wrapper
    {...props}
    color={color}
    size={size}
    weight={weight}
    required={required}
    requiredColor={requiredColor}
  >
    {children}
    {required && <span>*</span>}
  </S.Wrapper>
)

export default HeadingNew
