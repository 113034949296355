import * as yup from 'yup'
import { isPatientRN } from 'presentation/utils/validators/is-patient-rn'

const ValidationSchema = yup.object().shape({
  birthday: yup
    .date()
    .typeError('Data inválida')
    .max(
      new Date(),
      'Data futura não permitida. Escolha uma data igual ou anterior à data atual'
    )
    .format('DD/MM/YYYY', true)
    .required(),
  name: yup.string().required(),
  cpf: yup.string().cpf().required(),
  gender: yup.string().required(),
  marital_status: yup.string().when('birthday', {
    is: (date: any) => !isPatientRN(date),
    then: yup.string().required()
  }),
  email: yup.string().email().required(),
  phone: yup
    .string()
    .test('masked-phone-validation', 'Telefone inválido', function (val) {
      return !!(
        val?.match(/^(?:\+)[0-9]{2}\s?[0-9]{2}\s?[0-9]{9}$/) ||
        val?.match(/^[0-9]{13}$/)
      )
    })
    .required(),
  health_insurance: yup.string().required()
})

export default ValidationSchema
