import React, { HtmlHTMLAttributes } from 'react'
import { Property } from 'csstype'
import DividerNew from 'presentation/shared/components/DividerNew'
import { ReactComponent as CloseIcon } from 'presentation/assets/icons/close.svg'
import HeadingNew from '../HeadingNew'

import * as S from './styles'

export type SheetModalProps = {
  isOpen: boolean
  isDraggable?: boolean
  backdrop?: 'true' | 'false'
  marginbottom?: string
  size?: 'small' | 'medium' | 'auto' | number
  onClose: () => void
  primaryButton?: React.ReactNode
  secondaryButton?: React.ReactNode
  gap?: string
  title?: string
  justifyContent?: Property.JustifyContent
  alignItems?: Property.AlignItems
  subHeader?: React.ReactNode | React.ReactElement
} & HtmlHTMLAttributes<HTMLElement>

export default function SheetModalNew({
  isOpen,
  onClose,
  isDraggable,
  backdrop = 'true',
  size = 'auto',
  marginbottom,
  title,
  primaryButton,
  secondaryButton,
  gap = '0px',
  subHeader,
  ...props
}: SheetModalProps) {
  // const snapPoints = typeof size === 'number' ? [size] : []

  return (
    <S.Sheet
      disableDrag={!isDraggable}
      isOpen={isOpen}
      onClose={onClose}
      onCloseEnd={onClose}
      backdrop={backdrop}
      marginbottom={marginbottom}
      // snapPoints={snapPoints}
      size={size}
      gap={gap}
    >
      <S.Sheet.Container style={props.style}>
        {title && (
          <div>
            <S.Header>
              <HeadingNew weight="semiBold" size="large">
                {title}
              </HeadingNew>
              <CloseIcon width="12px" onClick={onClose} />
              {subHeader && <S.SubHeader>{subHeader}</S.SubHeader>}
            </S.Header>
            <DividerNew marginTop="24px" marginBottom="0px" leak="-24px" />
          </div>
        )}
        <S.Sheet.Content style={props.style}>{props.children}</S.Sheet.Content>
        <S.Footer>
          {primaryButton && primaryButton}
          {secondaryButton && secondaryButton}
        </S.Footer>
      </S.Sheet.Container>

      <S.Sheet.Backdrop onTap={onClose} data-testid={'sheet-modal-backdrop'} />
    </S.Sheet>
  )
}
