import { ApiClientImplementation } from 'infra/api-client/api-client'
import { PrepareApiRequestAmbulatoryDecorator } from 'main/decorators/prepare-api-request-ambulatory-decorator/prepare-api-request-ambulatory-decorator'
import { PrepareApiRequestDecorator } from 'main/decorators/prepare-api-request-decorator/prepare-api-request-decorator'
import { makeLocalStorageAdapter } from 'main/factories/cache/local-storage-adapter-factory'
import AmbulatoryService from 'presentation/contexts/services/ambulatory-service'
import { ApiRepository } from 'repository/api-repository'
import { AmbulatoryRepository } from 'repository/repositories/ambulatory/ambulatory-repository'
import { NewAmbulatoryRepository } from 'repository/repositories/new-ambulatory/new-ambulatory-repository'
import { RemoteAmbulatoryContact } from 'service/usecases/ambulatory-contact/remote-ambulatory-contact'
import { RemoteGetAmbulatoryCounters } from 'service/usecases/get-ambulatory-counters/remote-get-ambulatory-counters'
import { RemoteGetAmbulatoryOrders } from 'service/usecases/get-ambulatory-orders/remote-get-ambulatory-orders'
import { RemoteGetAmbulatorySchedulePanelOrganized } from 'service/usecases/get-ambulatory-panel-organized/remote-get-ambulatory-panel-organized'
import { RemoteGetOneAmbulatoryOrder } from 'service/usecases/get-one-ambulatory-order/remote-get-one-ambulatory-order'
import { RemoteLoadAmbulatoryOrderDocument } from 'service/usecases/load-ambulatory-order-documents/remote-load-ambulatory-order-documents'
import { RemoteSearchAmbulatoryOrders } from 'service/usecases/search-ambulatory-orders/remote-get-ambulatory-orders'
import { RemoteSyncAmbulatoryToken } from 'service/usecases/sync-ambulatory-token/remote-sync-ambulatory-token'

export default function makeAmbulatoryService() {
  const apiRepository = new PrepareApiRequestDecorator(
    makeLocalStorageAdapter(),
    new ApiRepository(new ApiClientImplementation())
  )

  const apiAmbulatory = new ApiClientImplementation()

  const apiAmbulatoryRepository = new PrepareApiRequestAmbulatoryDecorator(
    makeLocalStorageAdapter(),
    new ApiRepository(apiAmbulatory)
  )

  const ambulatoryRepository = new AmbulatoryRepository(apiRepository)

  const newAmbulatoryRepository = new NewAmbulatoryRepository(
    apiAmbulatoryRepository
  )

  const ambulatoryContact = new RemoteAmbulatoryContact(ambulatoryRepository)

  const syncAmbulatoryToken = new RemoteSyncAmbulatoryToken(
    ambulatoryRepository
  )

  const getAmbulatorySchedulePanelOrganized =
    new RemoteGetAmbulatorySchedulePanelOrganized(ambulatoryRepository)

  const getAmbulatoryCounters = new RemoteGetAmbulatoryCounters(
    newAmbulatoryRepository
  )

  const getAmbulatoryOrders = new RemoteGetAmbulatoryOrders(
    newAmbulatoryRepository
  )

  const searchAmbulatoryOrders = new RemoteSearchAmbulatoryOrders(
    newAmbulatoryRepository
  )

  const getOneAmbulatoryOrder = new RemoteGetOneAmbulatoryOrder(
    newAmbulatoryRepository
  )

  const loadAmbulatoryOrderDocument = new RemoteLoadAmbulatoryOrderDocument(
    newAmbulatoryRepository
  )

  return new AmbulatoryService({
    ambulatoryContact,
    syncAmbulatoryToken,
    getAmbulatorySchedulePanelOrganized,
    getAmbulatoryCounters,
    getAmbulatoryOrders,
    searchAmbulatoryOrders,
    getOneAmbulatoryOrder,
    loadAmbulatoryOrderDocument
  })
}
