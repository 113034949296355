import styled, { css } from 'styled-components'
import CardNew from '../CardNew'
import { darken } from 'polished'

export const ActionCard = styled(CardNew)`
  ${({ color = 'neutral600', theme }) => css`
    /* display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between; */
    display: grid;
    grid-template-columns: auto 1fr auto;
    align-items: center;
    gap: 8px;

    svg {
      width: 12px;
      height: 16px;
      margin-top: 2px;
      path {
        fill: ${darken(0.03, theme.ds.colors[color])} !important;
      }
    }

    .title {
      color: ${darken(0.03, theme.ds.colors[color])} !important;
    }
  `}
`
