import styled from 'styled-components'

export const FormContentStepTwo = styled.div`
  display: flex;
  flex-wrap: wrap;
  height: 100%;
  align-items: center;
`

export const Inputs = styled.div<{
  width: string
}>`
  width: ${({ width }) => width};
`

export const Icon = styled.div`
  text-align: center;
  height: 54px;
  width: 51px;
  margin-top: 65px;
  border-radius: 0px 10px 10px 0px;
  background: ${({ theme }) => theme.colors.primary};
  svg {
    path {
      fill: ${({ theme }) => theme.colors.white};
      opacity: 1;
    }
  }
`

export const CycleInputs = styled.div`
  display: grid;
  grid-template-columns: 55% 40%;
  gap: 5%;
  align-items: flex-end;
`
