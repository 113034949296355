import { LoadAllSurgicalOrdersByDoctor } from 'domain/usecases/doctor/load-all-surgical-order'
import { DoctorRepository } from 'repository/interfaces/doctor-repository'

export class RemoteLoadAllSurgicalOrderByDoctor
  implements LoadAllSurgicalOrdersByDoctor
{
  constructor(private readonly doctorRepository: DoctorRepository) {}

  loadAllOrders(
    params: LoadAllSurgicalOrdersByDoctor.Params
  ): Promise<LoadAllSurgicalOrdersByDoctor.Model> {
    return this.doctorRepository.loadAllSurgicalOrdersByDoctor(params)
  }
}
