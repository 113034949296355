export enum PasswordMaxDateValidationStatus {
  VALID = 'VALID',
  EXPIRED = 'EXPIRED',
  EXPIRES_SOON = 'EXPIRES_SOON'
}

export enum PasswordMaxDateValidationStatusTranslation {
  VALID = 'Senhas válidas',
  EXPIRED = 'Senhas vencidas',
  EXPIRES_SOON = 'Senhas a vencer'
}
