import { ApiNotificationImplementation } from 'infra/api-notification/api-notification-client'

import { PrepareApiRequestNotificationDecorator } from 'main/decorators/prepare-api-request-notification-decorator/prepare-api-request-notification-decorator'
import { makeLocalStorageAdapter } from 'main/factories/cache/local-storage-adapter-factory'
import { ApiNotificationRepository } from 'repository/api-notification-repository'
import { NotificationRepository } from 'repository/repositories/notification/notification-repository'
import { RemoteLoadNotifications } from 'service/usecases/load-notifications/remote-load-notifications'
import { RemoteReadNotifications } from 'service/usecases/read-notifications/remote-read-notifications'
import { RemoteLoadNotificationsByPendency } from 'service/usecases/load-notifications-by-pendency/remote-load-notifications-by-pendency'
// import { RemoteDisableNotifications } from 'service/usecases/disable-notifications/remote-disable-notifications'

import NotificationService from 'presentation/contexts/services/notification-service'

export default function makeNotificationService() {
  const apiNotification = new ApiNotificationImplementation()

  const apiNotificationRepository = new PrepareApiRequestNotificationDecorator(
    makeLocalStorageAdapter(),
    new ApiNotificationRepository(apiNotification)
  )

  const notificationRepository = new NotificationRepository(
    apiNotificationRepository
  )

  const loadNotifications = new RemoteLoadNotifications(notificationRepository)

  const readNotifications = new RemoteReadNotifications(notificationRepository)

  // const disableNotifications = new RemoteDisableNotifications(
  //   notificationRepository
  // )

  const loadNotificationsByPendency = new RemoteLoadNotificationsByPendency(
    notificationRepository
  )

  return new NotificationService({
    loadNotifications,
    readNotifications,
    // disableNotifications,
    loadNotificationsByPendency
  })
}
