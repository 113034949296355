import React from 'react'
import { ContainerNew } from 'presentation/shared/components/ContainerNew'
import HeadingNew from 'presentation/shared/components/HeadingNew'
import ButtonNew from 'presentation/shared/components/ButtonNew'
import SupportTextNew from 'presentation/shared/components/SupportTextNew'
import TextAreaNew from 'presentation/shared/components/TextAreaNew'
import { useHistory } from 'react-router'
import * as S from './style'
import { useFormik } from 'formik'
import { useLocation } from 'react-router'
import * as yup from 'yup'

type Props = {
  dispatch: (action: { type: any; payload: any }) => void
  general_observation: string
}

export default function SurgicalSchedulingCommentsLayout({
  dispatch,
  general_observation
}: Props) {
  const history = useHistory()
  const location = useLocation<{ isReviewing?: boolean }>()

  const submitForm = (values: any) => {
    dispatch({
      type: 'COMMENT',
      payload: values.general_observation
    })

    if (location.state?.isReviewing) {
      history.push('/agendamento-cirurgico/resumo')
    } else {
      history.push('/agendamento-cirurgico/resumo')
    }
  }

  const formik = useFormik({
    initialValues: {
      general_observation: general_observation || ''
    },
    validationSchema: validationSchema,
    onSubmit: submitForm
  })

  const submitButton = () => {
    return (
      <ButtonNew
        fullWidth
        size="large"
        onClick={formik.submitForm}
        disabled={!formik.isValid}
      >
        Próximo
      </ButtonNew>
    )
  }

  return (
    <ContainerNew primaryButton={submitButton()} gap="24px" noHeader>
      <div>
        <HeadingNew color="primary600" size="large">
          Observação geral
        </HeadingNew>
        <SupportTextNew>
          Deseja incluir alguma outra observação sobre a cirurgia que considera
          pertinente ao agendamento?
        </SupportTextNew>
      </div>

      <div style={{ position: 'relative' }}>
        <S.Label weight="bold" color="neutral900">
          Observação
        </S.Label>
        <TextAreaNew
          placeholder="Escreva sua observação aqui."
          value={formik.values.general_observation}
          onInputChange={formik.handleChange('general_observation')}
          rows={14}
        />
        <SupportTextNew
          size="xsmall"
          color={formik.isValid ? 'neutral400' : 'danger300'}
          style={{ position: 'absolute', right: '8px', bottom: '-24px' }}
        >
          {formik.values.general_observation.length}/500
        </SupportTextNew>
      </div>
    </ContainerNew>
  )
}

const validationSchema = yup.object().shape({
  general_observation: yup.string().max(500, 'Limite de caracteres escedido')
})
