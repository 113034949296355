export enum Booleans {
  true = 'Sim',
  false = 'Não'
}

export const booleansList = [
  {
    label: 'Sim',
    value: true
  },
  {
    label: 'Não',
    value: false
  }
]
