import React, { createContext, useReducer } from 'react'
import { Switch, useParams, Route, useRouteMatch } from 'react-router'
// import SurgicalOrdersPendenciesList from 'presentation/doctor/pages/SurgicalOrdersPendenciesList'
// import SurgicalOrdersScheduling from 'presentation/doctor/pages/SurgicalOrdersScheduling'
// import SurgicalOrdersStatusList from 'presentation/doctor/pages/SurgicalOrdersStatusList'
import { SurgicalOrderModel } from 'domain/entities/surgical-order-model'
import AmbulatoryAuthorization from 'presentation/doctor/pages/AmbulatoryAuthorization'
import AmbulatoryOrdersStatusList from 'presentation/doctor/pages/AmbulatoryOrdersStatusList'

import AmbulatoryOrderDetails from 'presentation/doctor/pages/AmbulatoryOrderDetails'

import { Pagination } from 'service/protocols/api/api-client'
import { AmbulatoryOrdersSearchModel } from 'domain/entities/search-ambulatory-orders-model'
export const AmbulatoryAuthorizationDashboardContext = createContext({})

type Types =
  | 'PENDENCY_COUNT'
  | 'PENDENCY_COUNT_FILTERED'
  | 'STATUS_COUNT'
  | 'SCHEDULINGS_COUNT'
  | 'AMBULATORY_ORDERS_LIST'
  | 'SEARCH_AMBULATORY_ORDERS_LIST'
  | 'AMBULATORY_ORDERS_LIST_STATUS'
  | 'PAGINATION'

type Payload = any

export type Actions = {
  payload: Payload
  type: Types
  clearList?: boolean
}

export type ContextType = {
  dispatch: (params: Actions) => void
  state: {
    statusCount: {
      AUTHORIZED: number
      CANCELED: number
      DONE: number
      HOSPITAL_ANALYSIS: number
      ANALYSIS_INSURANCE: number
      DENIED: number
    }
  }
}

const reducer = (state: any, action: Actions) => {
  switch (action.type) {
    case 'AMBULATORY_ORDERS_LIST':
      if (action.clearList) {
        return { ...state, ambulatoryOrdersList: action.payload }
      } else {
        return {
          ...state,
          ambulatoryOrdersList: [
            ...state.ambulatoryOrdersList,
            ...action.payload
          ]
        }
      }
    case 'AMBULATORY_ORDERS_LIST_STATUS':
      return {
        ...state,
        ambulatoryOrdersList: [...action.payload]
      }

    case 'SEARCH_AMBULATORY_ORDERS_LIST':
      return {
        ...state,
        ambulatoryOrdersList: [...action.payload]
      }

    case 'STATUS_COUNT':
      return {
        ...state,
        statusCount: action.payload
      }

    case 'PAGINATION':
      return { ...state, pageInfo: action.payload }
    default:
      return state
  }
}

export default function makeAmbulatoryAuthorizationPage() {
  const { path } = useRouteMatch()
  const [state, dispatch] = useReducer(reducer, {
    statusCount: {
      AUTHORIZED: 0,
      CANCELED: 0,
      DONE: 0,
      ORDER_OPEN: 0,
      HOSPITAL_ANALYSIS: 0,
      ANALYSIS_INSURANCE: 0,
      DENIED: 0
    },
    ambulatoryOrdersList: [] as SurgicalOrderModel[],
    searchAmbulatoryOrdersList: [] as AmbulatoryOrdersSearchModel[],
    pageInfo: {} as Pagination
  })

  return (
    <>
      <Switch>
        <AmbulatoryAuthorizationDashboardContext.Provider
          value={{ state, dispatch }}
        >
          <Route path={`${path}/:page`} component={Page} />
        </AmbulatoryAuthorizationDashboardContext.Provider>
      </Switch>
    </>
  )
}

function Page() {
  const { page } = useParams<{ page: string }>()
  switch (page) {
    case 'dashboard':
      return <AmbulatoryAuthorization />
    case 'pedidos':
      return <AmbulatoryOrdersStatusList />
    case 'detalhes':
      return <AmbulatoryOrderDetails />
    default:
      return <AmbulatoryAuthorization />
  }
}
