import { AddPatientNew } from 'domain/usecases/patient/add-patient-new'
import { PatientRepository } from 'repository/interfaces/patient-repository'

export class RemoteAddPatientNew implements AddPatientNew {
  constructor(private readonly patientRepository: PatientRepository) {}

  add(params: AddPatientNew.Params): Promise<AddPatientNew.Model> {
    return this.patientRepository.addPatientNew(params)
  }
}
