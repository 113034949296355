import { AccountModel } from 'domain/entities/account-model'
import { Hospital } from 'domain/entities/hospital-model'
import { ChangeHospital } from 'domain/usecases/hospital/change-hospital'
import { LoadHospitals } from 'domain/usecases/hospital/load-hospitals'
import { makeAutoObservable, runInAction } from 'mobx'

export class CurrentHospital {
  constructor(
    private setCurrentHospital: (hospital: Hospital) => void,
    private getCurrentHospital: () => Hospital,
    private setCurrentUser: (account: AccountModel) => void,
    private readonly loadHospitals: LoadHospitals,
    private readonly changeHospital: ChangeHospital
  ) {
    makeAutoObservable(this)
  }

  hospitalList: Hospital[] = []
  actualHospital?: Hospital = this.getCurrentHospital()
  hospitalSelected = 0

  async setActualHospital(hospital_id: number) {
    const hospital = this.hospitalList.find(
      (hospital) => hospital.hospital_id === hospital_id
    )

    if (hospital?.hospital_id) {
      const account = await this.changeHospital.update({
        hospital_id: hospital.hospital_id,
        params: [
          'accessToken',
          'user {name, user_id, role, groups, permissions, isMain, terms { name, signed}, features { feature, active }}'
        ]
      })

      runInAction(() => {
        this.setCurrentUser(account)
        this.setCurrentHospital(hospital)
        this.actualHospital = hospital
      })
    }
  }

  getActualHospital(): Hospital {
    return this.getCurrentHospital()
  }

  setHospitalList(hospitals: Hospital[]): Hospital[] {
    return (this.hospitalList = hospitals)
  }

  getHospitalSelected() {
    return this.hospitalSelected
  }

  setHospitalSelected(hospitalSelected: number) {
    this.hospitalSelected = hospitalSelected
  }

  async load() {
    try {
      this.hospitalList = await this.loadHospitals.load([
        'hospital_id',
        'name',
        'nickname',
        'friendly_name',
        'uf'
      ])
      this.actualHospital = this.getActualHospital()
    } catch (error: any) {
      return error.message
    }
  }
}

export default CurrentHospital
