import React, { useContext, useEffect, useState } from 'react'
// import { SurgicalOrderDashboardContext } from 'main/factories/pages/doctor/surgical-order/surgical-order-dashboard-factory'
import * as S from './styles'
import TextFieldNew from 'presentation/shared/components/TextFieldNew'
import { ContainerNew } from 'presentation/shared/components/ContainerNew'
import { ReactComponent as SearchIcon } from 'presentation/assets/icons/search2.svg'
import SupportTextNew from 'presentation/shared/components/SupportTextNew'
import { useHistory, useLocation } from 'react-router'
import AmbulatoryCard from 'presentation/doctor/components/AmbulatoryCard'
import {
  AmbulatoryAuthorizationStatus,
  AmbulatoryAuthorizationStatusPlural
} from 'common/enum/ambulatory-authorization-status'
import HeaderNew from 'presentation/shared/components/HeaderNew'
import TabsNew from 'presentation/shared/components/TabsNew'
import { AmbulatoryAuthorizationDashboardContext } from 'main/factories/pages/doctor/ambulatory-authorization/ambulatory-authorization-dashboard-factory'
import { GetAmbulatoryOrders } from 'domain/usecases/ambulatory/get-ambulatory-orders'
import { AmbulatoryOrderModel } from 'domain/entities/ambulatory-orders-model'
import { SearchAmbulatoryOrders } from 'domain/usecases/ambulatory/search-ambulatory-order'

type Props = {
  getAmbulatoryOrders: (params: GetAmbulatoryOrders.Params) => void
  searchAmbulatoryOrders: (params: SearchAmbulatoryOrders.Params) => void
}

export default function AmbulatoryOrderStatusListLayout({
  getAmbulatoryOrders,
  searchAmbulatoryOrders
}: Props) {
  const history = useHistory()
  const location = useLocation<any>()
  const [searchTerm, setSearchTerm] = useState('')
  const [currentTab, setCurrentTab] = useState(0)

  const onEnterSearch = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.keyCode === 13) {
      getAmbulatoryOrderFromSearch()
    }
  }

  const locationState = location.state

  const getAmbulatoryOrderFromSearch = () => {
    if (!searchTerm) {
      return
    }
    searchAmbulatoryOrders({
      search: searchTerm
    })
  }

  useEffect(() => {
    history.replace({ ...location, state: {} })
  }, [])

  function getCurrentTab() {
    if (locationState?.orderStatus) {
      const statusValue =
        AmbulatoryAuthorizationStatusPlural[
          locationState?.orderStatus as keyof typeof AmbulatoryAuthorizationStatusPlural
        ]

      const tabIndex = Tabs.findIndex((tab) => tab.label === statusValue)
      setCurrentTab(tabIndex)
    }
  }

  useEffect(() => {
    getCurrentTab()
  }, [])

  const Tabs = [
    {
      label: 'Em análise no hospital',
      componentChildren: (
        <GenericTab
          getAmbulatoryOrders={getAmbulatoryOrders}
          currentTab={currentTab}
          selectedStatus={locationState?.orderStatus}
          status={
            locationState?.orderStatus
              ? locationState?.orderStatus
              : AmbulatoryAuthorizationStatus.HOSPITAL_ANALYSIS
          }
        />
      )
    },
    {
      label: 'Em análise no convênio',
      componentChildren: (
        <GenericTab
          getAmbulatoryOrders={getAmbulatoryOrders}
          currentTab={currentTab}
          selectedStatus={locationState?.orderStatus}
          status={
            locationState?.orderStatus
              ? locationState?.orderStatus
              : AmbulatoryAuthorizationStatus.ANALYSIS_INSURANCE
          }
        />
      )
    },
    {
      label: 'Autorizados',
      componentChildren: (
        <GenericTab
          getAmbulatoryOrders={getAmbulatoryOrders}
          currentTab={currentTab}
          selectedStatus={locationState?.orderStatus}
          status={
            locationState?.orderStatus
              ? locationState?.orderStatus
              : AmbulatoryAuthorizationStatus.AUTHORIZED
          }
        />
      )
    },
    {
      label: 'Realizados',
      componentChildren: (
        <GenericTab
          getAmbulatoryOrders={getAmbulatoryOrders}
          currentTab={currentTab}
          selectedStatus={locationState?.orderStatus}
          status={
            locationState?.orderStatus
              ? locationState?.orderStatus
              : AmbulatoryAuthorizationStatus.DONE
          }
        />
      )
    },
    {
      label: 'Negados',
      componentChildren: (
        <GenericTab
          getAmbulatoryOrders={getAmbulatoryOrders}
          currentTab={currentTab}
          selectedStatus={locationState?.orderStatus}
          status={
            locationState?.orderStatus
              ? locationState?.orderStatus
              : AmbulatoryAuthorizationStatus.DENIED
          }
        />
      )
    },
    {
      label: 'Cancelados',
      componentChildren: (
        <GenericTab
          getAmbulatoryOrders={getAmbulatoryOrders}
          currentTab={currentTab}
          selectedStatus={locationState?.orderStatus}
          status={
            locationState?.orderStatus
              ? locationState?.orderStatus
              : AmbulatoryAuthorizationStatus.CANCELED
          }
        />
      )
    }
  ]

  return (
    <>
      <HeaderNew
        showMenu
        // actualPageTitle="Pedidos ambulatoriais"
        actualPageOnClick={() =>
          history.push('/pedidos-autorizacao-ambulatorial/dashboard')
        }
        topFixed
      />

      <ContainerNew gap="22px" noHeader spacingTop>
        <SupportTextNew weight="bold" color="neutral800" size="large">
          Pedidos ambulatoriais
        </SupportTextNew>

        <TextFieldNew
          icon={<SearchIcon />}
          iconIsButton
          placeholder="Paciente, N° do aviso"
          value={searchTerm}
          onInputChange={setSearchTerm}
          onIconClick={() => getAmbulatoryOrderFromSearch()}
          onKeyDown={onEnterSearch}
        />

        <S.PendenciesContainer>
          <S.TabsWrapper>
            <TabsNew
              tabItems={Tabs}
              returnTab={currentTab}
              variant="scrollable"
              onChangeTab={(index) => setCurrentTab(index)}
            />
          </S.TabsWrapper>
        </S.PendenciesContainer>
      </ContainerNew>
    </>
  )
}

type GenericTabProps = {
  status: AmbulatoryAuthorizationStatus
  getAmbulatoryOrders: (params: GetAmbulatoryOrders.Params) => void
  currentTab: number
  selectedStatus?: AmbulatoryAuthorizationStatus
}

const GenericTab = ({
  status,
  currentTab,
  selectedStatus,
  getAmbulatoryOrders
}: GenericTabProps) => {
  useEffect(() => {
    if (selectedStatus) {
      getAmbulatoryOrders({
        status: selectedStatus
      })
    } else {
      getAmbulatoryOrders({
        status
      })
    }
  }, [currentTab])

  const getMainProcedure = (procedures: any) => {
    if (procedures.length === 1) {
      return procedures[0].name
    } else return procedures.find((procedure: any) => procedure.isMain)?.name
  }

  const getMainMedication = (medications: any) => {
    if (medications.length === 1) {
      return medications[0].name
    } else return medications.find((medication: any) => medication.isMain)?.name
  }

  const getActiveStatus = (statuses: any) => {
    if (statuses.length === 1) {
      return statuses[0].status
    } else return statuses.find((status: any) => status.isActive)?.status
  }

  const { state } = useContext<any>(AmbulatoryAuthorizationDashboardContext)

  return (
    <>
      <S.CardWrapper>
        {state.ambulatoryOrdersList.map((order: AmbulatoryOrderModel) => {
          return (
            <AmbulatoryCard
              procedure={getMainProcedure(order?.procedures)}
              medication={getMainMedication(order?.medications)}
              patient={order.patient.name}
              doctor={order.doctor.name}
              healthInsurance={order.healthInsurance.name}
              unit={order.hospital.name}
              key={order.id}
              orderId={order.orderId}
              status={getActiveStatus(order.statuses)}
              order={order}
            />
          )
        })}
      </S.CardWrapper>
    </>
  )
}
