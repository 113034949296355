import { LoadDashboardStatusCount } from 'domain/usecases/surgical-order/load-dashboard-status-count'
import { SurgicalOrderRepository } from 'repository/interfaces/surgical-order-repository'

export class RemoteLoadDashboardStatusCount
  implements LoadDashboardStatusCount
{
  constructor(
    private readonly surgicalOrderRepository: SurgicalOrderRepository
  ) {}

  load(): Promise<LoadDashboardStatusCount.Model> {
    return this.surgicalOrderRepository.loadDashboardStatusCount()
  }
}
