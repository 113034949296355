import styled, { css } from 'styled-components'
import ModalSheet from 'react-modal-sheet'

export const Header = styled.header`
  svg {
    position: absolute;
    top: 30px;
    right: 24px;
    cursor: pointer;
  }
`

export const SubHeader = styled.div`
  margin-top: 16px;
`

export const Footer = styled.footer`
  display: flex;
  flex-direction: column;
  gap: 16px;
`

export const Sheet = styled(ModalSheet)<{
  backdrop: 'true' | 'false'
  marginbottom?: string
  gap: string
  size: 'small' | 'medium' | 'auto' | number
}>`
  ${({ theme, backdrop, marginbottom, size, gap }) => css`
    font-family: ${theme.ds.typography.fontFamily};

    .react-modal-sheet-container {
      height: ${typeof size === 'number'
        ? `min(${size}px, calc(100% - env(safe-area-inset-top) - 34px))`
        : 'auto'} !important;
      border-top-right-radius: 16px !important;
      border-top-left-radius: 16px !important;

      header {
        padding: 24px 24px 0 24px;
      }
      footer {
        padding: 0 24px 24px 24px;
      }
    }

    .react-modal-sheet-content {
      gap: ${gap};
      display: flex;
      flex-direction: column;
      padding: 16px 24px;
      width: 100%;
    }

    ${backdrop === 'false' && wrapperModifiers.backdrop()}
    ${!!marginbottom && wrapperModifiers.marginbottom(marginbottom)}
  `}
`

const wrapperModifiers = {
  backdrop: () => css`
    .react-modal-sheet-backdrop {
      background-color: transparent !important;
    }
  `,
  marginbottom: (margin: string) => css`
    & {
      bottom: ${margin} !important;
    }

    .react-modal-sheet-backdrop {
      top: -${margin} !important;
    }
  `
}
