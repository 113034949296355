import styled from 'styled-components'
import SupportTextNew from 'presentation/shared/components/SupportTextNew'

export const FlexDiv = styled.div`
  flex: 1;
`

export const TextAreaContainer = styled.div`
  position: relative;
  margin-bottom: 24px;
`

export const CharactersCounter = styled(SupportTextNew)`
  position: absolute;
  right: 8px;
  bottom: -24px;
`
