import React from 'react'

import HeaderNew from 'presentation/shared/components/HeaderNew'
import ReviewSurgicalOrderLayout from 'presentation/doctor/layouts/ReviewSurgicalOrder'

const ReviewSurgicalOrder = () => (
  <>
    <HeaderNew actualPageTitle="Resumo do pedido" />
    <ReviewSurgicalOrderLayout />
  </>
)

export default ReviewSurgicalOrder
