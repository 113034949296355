import React, { useEffect, useState } from 'react'
import * as S from './styles'
import { ContainerNew } from 'presentation/shared/components/ContainerNew'
import HeaderNew from 'presentation/shared/components/HeaderNew'
import SupportTextNew from 'presentation/shared/components/SupportTextNew'
import { useHistory, useLocation } from 'react-router'
import { AmbulatoryOrderModel } from 'domain/entities/ambulatory-orders-model'
import AmbulatoryCard from 'presentation/doctor/components/AmbulatoryCard'
import AccordionCardNew from 'presentation/shared/components/AccordionCardNew'
import DividerNew from 'presentation/shared/components/DividerNew'
import TabsNew from 'presentation/shared/components/TabsNew'
import {
  AmbulatoryAuthorizationStatus,
  AmbulatoryAuthorizationStatusSingular
} from 'common/enum/ambulatory-authorization-status'
import moment from 'moment'
import SheetModalNew from 'presentation/shared/components/SheetModalNew'
import { getDateTextFromNow } from '../SurgicalOrderDetails'
import theme from 'presentation/styles/theme'
import { ReactComponent as RightArrow } from 'presentation/assets/icons/v-arrow.svg'
import { ReactComponent as PendencyIcon } from 'presentation/assets/icons/pendency-icon.svg'
import { getColorStatus } from './utils'
import CardNew from 'presentation/shared/components/CardNew'
import { ReactComponent as DownloadIcon } from 'presentation/assets/icons/DonwloadIcon.svg'
// import { toast } from 'react-toastify'
import { useServices } from 'presentation/hooks/use-services'
import downloadFileFromBlob from 'common/utils/downloadFileFromBlob'
import {
  WithLoading,
  WithLoadingProps
} from 'presentation/shared/components/HOCs/WithLoading'
import { toast } from 'react-toastify'

type OrderModel = {
  order: AmbulatoryOrderModel
}
export default function AmbulatoryOrderDetailsLayout() {
  const history = useHistory()
  const location = useLocation<any>()

  const [currentStatus, setCurrentStatus] = useState('')

  const { order }: OrderModel = location.state

  const getActiveStatus = (statuses: any) => {
    if (statuses.length === 1) {
      return statuses[0].status
    } else return statuses.find((status: any) => status.isActive)?.status
  }

  useEffect(() => {
    const statusValue = getActiveStatus(order.statuses)
    setCurrentStatus(statusValue)
  }, [])

  const Tabs = [
    {
      label: 'Informações',
      componentChildren: <Accordions order={order} status={currentStatus} />
    },

    {
      label: '',
      disabled: true,
      componentChildren: <></>
    }
  ]

  return (
    <>
      <HeaderNew
        showMenu
        actualPageOnClick={() =>
          history.push('/pedidos-autorizacao-ambulatorial/pedidos')
        }
        topFixed
      />

      <ContainerNew noHeader>
        <S.TextWrapper>
          <SupportTextNew weight="bold" color="neutral800" size="xlarge">
            Detalhes pedido de autorização ambulatorial
          </SupportTextNew>

          <SupportTextNew weight="semiBold" color="neutral700" size="medium">
            Acompanhe os detalhes do seus pedido de autorização ambulatorial
          </SupportTextNew>
        </S.TextWrapper>

        <div style={{ marginBottom: '25px', width: '100%' }}>
          <AmbulatoryCard
            patient={order.patient.name}
            doctor={order.doctor.name}
            healthInsurance={order.healthInsurance.name}
            unit={order.hospital.name}
            key={order.id}
            orderId={order.orderId}
            status={getActiveStatus(order.statuses)}
            order={order}
            withBorder
          />
        </div>

        <TimeLineStatusContainer
          order={order}
          activeStatus={getActiveStatus(order.statuses)}
        />
        {/* <TimelineStatus statuses={order.statuses} /> */}

        <S.TabsWrapper>
          <TabsNew tabItems={Tabs} />
        </S.TabsWrapper>
      </ContainerNew>
    </>
  )
}

type AccordionsProps = {
  order: AmbulatoryOrderModel
  status: string
}
const Accordions = ({ order, status }: AccordionsProps) => {
  const [informationAccordionOpen, setInformationAccordionOpen] = useState('')

  return (
    <S.AccordionsContainer>
      {status === AmbulatoryAuthorizationStatus.AUTHORIZED && (
        <PasswordAccordion
          informationAccordionOpen={informationAccordionOpen}
          setInformationAccordionOpen={setInformationAccordionOpen}
          order={order}
          status={status}
        />
      )}
      <ProcedureAccordion
        informationAccordionOpen={informationAccordionOpen}
        setInformationAccordionOpen={setInformationAccordionOpen}
        order={order}
        status={status}
      />
      <MedicationAccordion
        informationAccordionOpen={informationAccordionOpen}
        setInformationAccordionOpen={setInformationAccordionOpen}
        order={order}
        status={status}
      />
      <MaterialAccordion
        informationAccordionOpen={informationAccordionOpen}
        setInformationAccordionOpen={setInformationAccordionOpen}
        order={order}
        status={status}
      />
      <ObservationAccordion
        informationAccordionOpen={informationAccordionOpen}
        setInformationAccordionOpen={setInformationAccordionOpen}
        order={order}
      />

      {status === AmbulatoryAuthorizationStatus.AUTHORIZED && (
        <DocumentsAccordion
          informationAccordionOpen={informationAccordionOpen}
          setInformationAccordionOpen={setInformationAccordionOpen}
          order={order}
        />
      )}
    </S.AccordionsContainer>
  )
}

type PasswordAccordionProps = {
  order: AmbulatoryOrderModel
  status: string
  informationAccordionOpen: any
  setInformationAccordionOpen: any
}

const PasswordAccordion = ({
  order,
  status,
  informationAccordionOpen,
  setInformationAccordionOpen
}: PasswordAccordionProps) => {
  function handleSetInformation() {
    if (informationAccordionOpen === 'PasswordInfo') {
      setInformationAccordionOpen('')
    } else {
      setInformationAccordionOpen('PasswordInfo')
    }
  }

  return (
    <AccordionCardNew
      accordionLabel="PasswordInfo"
      expanded={informationAccordionOpen === 'PasswordInfo'}
      handleChange={handleSetInformation}
      header="Senha"
      shadow="satin"
      padding="16px"
      background="neutral25"
    >
      <>
        {order.finish && order.finish.password ? (
          order.finish.password.password ? (
            <>
              <S.QuantityWrapper>
                <div>
                  <SupportTextNew
                    color={
                      getColorStatus(status)
                        ?.color as keyof typeof theme.ds.colors
                    }
                    weight="semiBold"
                    size="xxsmall"
                  >
                    Senha
                  </SupportTextNew>

                  <SupportTextNew
                    color="neutral800"
                    weight="semiBold"
                    size="small"
                  >
                    {order.finish.password.password}
                  </SupportTextNew>
                </div>

                <div>
                  <SupportTextNew
                    color={
                      getColorStatus(status)
                        ?.color as keyof typeof theme.ds.colors
                    }
                    weight="semiBold"
                    size="xxsmall"
                  >
                    Validade da Senha
                  </SupportTextNew>

                  <SupportTextNew
                    color="neutral800"
                    weight="semiBold"
                    size="small"
                  >
                    {moment
                      .utc(order.finish.password.passwordValidationDate)
                      .format('DD/MM/YYYY ')}
                  </SupportTextNew>
                </div>
              </S.QuantityWrapper>
              <SupportTextNew color="neutral800" weight="normal" size="small">
                A senha terá validade de 30 dias a partir da data de autorização
                do pedido
              </SupportTextNew>
            </>
          ) : (
            <>
              <SupportTextNew
                color={
                  getColorStatus(status)?.color as keyof typeof theme.ds.colors
                }
                weight="bold"
                size="xxsmall"
              >
                Senha
              </SupportTextNew>
              <SupportTextNew color="neutral800" weight="semiBold" size="small">
                Não preenchido
              </SupportTextNew>
            </>
          )
        ) : (
          <>
            <SupportTextNew
              color={
                getColorStatus(status)?.color as keyof typeof theme.ds.colors
              }
              weight="bold"
              size="xxsmall"
            >
              Senha
            </SupportTextNew>
            <SupportTextNew color="neutral800" weight="semiBold" size="small">
              Não preenchido
            </SupportTextNew>
          </>
        )}
      </>
    </AccordionCardNew>
  )
}

type ProcedureAccordionProps = {
  order: AmbulatoryOrderModel
  status: string
  informationAccordionOpen: any
  setInformationAccordionOpen: any
}

const ProcedureAccordion = ({
  order,
  status,
  informationAccordionOpen,
  setInformationAccordionOpen
}: ProcedureAccordionProps) => {
  function handleSetInformation() {
    if (informationAccordionOpen === 'ProcedureInfo') {
      setInformationAccordionOpen('')
    } else {
      setInformationAccordionOpen('ProcedureInfo')
    }
  }

  return (
    <AccordionCardNew
      accordionLabel="ProcedureInfo"
      expanded={informationAccordionOpen === 'ProcedureInfo'}
      handleChange={handleSetInformation}
      header="Procedimentos"
      shadow="satin"
      padding="16px"
      background="neutral25"
    >
      <SupportTextNew
        color={getColorStatus(status)?.color as keyof typeof theme.ds.colors}
        weight="bold"
        size="xxsmall"
      >
        Procedimento
      </SupportTextNew>

      {order.finish ? (
        order.finish.procedures.length ? (
          order.finish.procedures.map((procedure, index) => {
            return (
              <>
                <SupportTextNew
                  size="small"
                  weight="semiBold"
                  color="neutral800"
                >
                  {procedure.name}
                </SupportTextNew>

                <S.QuantityWrapper>
                  <div>
                    <SupportTextNew
                      color="neutral800"
                      weight="semiBold"
                      size="xxsmall"
                    >
                      Quant. solicitada
                    </SupportTextNew>

                    <SupportTextNew
                      color="neutral800"
                      weight="semiBold"
                      size="small"
                    >
                      {procedure.quantity}
                    </SupportTextNew>
                  </div>

                  <div>
                    <SupportTextNew
                      color="neutral800"
                      weight="semiBold"
                      size="xxsmall"
                    >
                      Quant. autorizada
                    </SupportTextNew>

                    <SupportTextNew
                      color="neutral800"
                      weight="semiBold"
                      size="small"
                    >
                      {procedure.authorizedQuantity}
                    </SupportTextNew>
                  </div>
                </S.QuantityWrapper>

                {index !== order!.finish!.procedures.length - 1 && (
                  <DividerNew
                    marginBottom="8px"
                    marginTop="8px"
                    color={
                      getColorStatus(status)
                        ?.color as keyof typeof theme.ds.colors
                    }
                  />
                )}
              </>
            )
          })
        ) : (
          <>
            <SupportTextNew color="neutral800" weight="semiBold" size="small">
              Não preenchido
            </SupportTextNew>
          </>
        )
      ) : order.procedures.length ? (
        order.procedures.map((procedure, index) => {
          return (
            <>
              <SupportTextNew size="small" weight="semiBold" color="neutral800">
                {procedure.name}
              </SupportTextNew>

              <S.QuantityWrapper>
                <div>
                  <SupportTextNew
                    color="neutral800"
                    weight="semiBold"
                    size="xxsmall"
                  >
                    Quant. solicitada
                  </SupportTextNew>

                  <SupportTextNew
                    color="neutral800"
                    weight="semiBold"
                    size="small"
                  >
                    {procedure.quantity}
                  </SupportTextNew>
                </div>
              </S.QuantityWrapper>

              {index !== order.procedures.length - 1 && (
                <DividerNew
                  marginBottom="8px"
                  marginTop="8px"
                  color={
                    getColorStatus(status)
                      ?.color as keyof typeof theme.ds.colors
                  }
                />
              )}
            </>
          )
        })
      ) : (
        <>
          <SupportTextNew color="neutral800" weight="semiBold" size="small">
            Não preenchido
          </SupportTextNew>
        </>
      )}
    </AccordionCardNew>
  )
}

type MedicationAccordionProps = {
  order: AmbulatoryOrderModel
  informationAccordionOpen: any
  setInformationAccordionOpen: any
  status: string
}

const MedicationAccordion = ({
  order,
  informationAccordionOpen,
  setInformationAccordionOpen,
  status
}: MedicationAccordionProps) => {
  function handleSetInformation() {
    if (informationAccordionOpen === 'MedicationInfo') {
      setInformationAccordionOpen('')
    } else {
      setInformationAccordionOpen('MedicationInfo')
    }
  }

  return (
    <AccordionCardNew
      accordionLabel="MedicationInfo"
      expanded={informationAccordionOpen === 'MedicationInfo'}
      handleChange={handleSetInformation}
      header="Medicações"
      shadow="satin"
      padding="16px"
      background="neutral25"
    >
      {order.finish ? (
        order.finish.medications.map((medication, index) => {
          return (
            <>
              <SupportTextNew
                color={
                  getColorStatus(status)?.color as keyof typeof theme.ds.colors
                }
                weight="bold"
                size="xxsmall"
              >
                Medicação
              </SupportTextNew>
              {medication ? (
                <>
                  <SupportTextNew
                    size="small"
                    weight="semiBold"
                    color="neutral800"
                  >
                    {medication.name}
                  </SupportTextNew>

                  <S.QuantityWrapper>
                    <div>
                      <SupportTextNew
                        color="neutral800"
                        weight="semiBold"
                        size="xxsmall"
                      >
                        Quant. solicitada
                      </SupportTextNew>

                      <SupportTextNew
                        color="neutral800"
                        weight="semiBold"
                        size="small"
                      >
                        {medication.quantity}
                      </SupportTextNew>
                    </div>

                    <div>
                      <SupportTextNew
                        color="neutral800"
                        weight="semiBold"
                        size="xxsmall"
                      >
                        Quant. autorizada
                      </SupportTextNew>

                      <SupportTextNew
                        color="neutral800"
                        weight="semiBold"
                        size="small"
                      >
                        {medication.authorizedQuantity}
                      </SupportTextNew>
                    </div>
                  </S.QuantityWrapper>

                  <S.DosageWrapper>
                    <div>
                      <SupportTextNew
                        color="neutral800"
                        weight="semiBold"
                        size="xxsmall"
                      >
                        Dosagem solicitada
                      </SupportTextNew>

                      <SupportTextNew
                        color="neutral800"
                        weight="semiBold"
                        size="small"
                      >
                        {medication.dosage}
                      </SupportTextNew>
                    </div>

                    <div>
                      <SupportTextNew
                        color="neutral800"
                        weight="semiBold"
                        size="xxsmall"
                      >
                        Dosagem autorizada
                      </SupportTextNew>

                      <SupportTextNew
                        color="neutral800"
                        weight="semiBold"
                        size="small"
                      >
                        {medication.authorizedDosage}
                      </SupportTextNew>
                    </div>
                  </S.DosageWrapper>

                  {index !== order!.finish!.medications.length - 1 && (
                    <DividerNew
                      marginBottom="8px"
                      marginTop="8px"
                      color={
                        getColorStatus(status)
                          ?.color as keyof typeof theme.ds.colors
                      }
                    />
                  )}
                </>
              ) : (
                <>
                  <SupportTextNew
                    color={
                      getColorStatus(status)
                        ?.color as keyof typeof theme.ds.colors
                    }
                    weight="bold"
                    size="xxsmall"
                  >
                    Medicação
                  </SupportTextNew>
                  <SupportTextNew
                    color="neutral800"
                    weight="semiBold"
                    size="small"
                  >
                    Não preenchido
                  </SupportTextNew>
                </>
              )}
            </>
          )
        })
      ) : order.medications.length ? (
        order.medications.map((medication, index) => {
          return (
            <>
              <SupportTextNew
                color={
                  getColorStatus(status)?.color as keyof typeof theme.ds.colors
                }
                weight="bold"
                size="xxsmall"
              >
                Medicação
              </SupportTextNew>
              {medication ? (
                <>
                  <SupportTextNew
                    size="small"
                    weight="semiBold"
                    color="neutral800"
                  >
                    {medication.name}
                  </SupportTextNew>

                  <S.QuantityWrapper>
                    <div>
                      <SupportTextNew
                        color="neutral800"
                        weight="semiBold"
                        size="xxsmall"
                      >
                        Quant. solicitada
                      </SupportTextNew>

                      <SupportTextNew
                        color="neutral800"
                        weight="semiBold"
                        size="small"
                      >
                        {medication.quantity}
                      </SupportTextNew>
                    </div>
                  </S.QuantityWrapper>

                  <S.DosageWrapper>
                    <div>
                      <SupportTextNew
                        color="neutral800"
                        weight="semiBold"
                        size="xxsmall"
                      >
                        Dosagem solicitada
                      </SupportTextNew>

                      <SupportTextNew
                        color="neutral800"
                        weight="semiBold"
                        size="small"
                      >
                        {medication.dosage}
                      </SupportTextNew>
                    </div>
                  </S.DosageWrapper>

                  {index !== order.medications.length - 1 && (
                    <DividerNew
                      marginBottom="8px"
                      marginTop="8px"
                      color={
                        getColorStatus(status)
                          ?.color as keyof typeof theme.ds.colors
                      }
                    />
                  )}
                </>
              ) : (
                <>
                  <SupportTextNew
                    color={
                      getColorStatus(status)
                        ?.color as keyof typeof theme.ds.colors
                    }
                    weight="bold"
                    size="xxsmall"
                  >
                    Medicação
                  </SupportTextNew>
                  <SupportTextNew
                    color="neutral800"
                    weight="semiBold"
                    size="small"
                  >
                    Não preenchido
                  </SupportTextNew>
                </>
              )}
            </>
          )
        })
      ) : (
        <>
          <SupportTextNew
            color={
              getColorStatus(status)?.color as keyof typeof theme.ds.colors
            }
            weight="bold"
            size="xxsmall"
          >
            Medicação
          </SupportTextNew>
          <SupportTextNew color="neutral800" weight="semiBold" size="small">
            Não preenchido
          </SupportTextNew>
        </>
      )}
    </AccordionCardNew>
  )
}

type MaterialAccordionProps = {
  order: AmbulatoryOrderModel
  status: string
  informationAccordionOpen: any
  setInformationAccordionOpen: any
}

const MaterialAccordion = ({
  order,
  status,
  informationAccordionOpen,
  setInformationAccordionOpen
}: MaterialAccordionProps) => {
  function handleSetInformation() {
    if (informationAccordionOpen === 'MaterialInfo') {
      setInformationAccordionOpen('')
    } else {
      setInformationAccordionOpen('MaterialInfo')
    }
  }

  return (
    <AccordionCardNew
      accordionLabel="MaterialInfo"
      expanded={informationAccordionOpen === 'MaterialInfo'}
      handleChange={handleSetInformation}
      header="Materiais"
      shadow="satin"
      padding="16px"
      background="neutral25"
    >
      <SupportTextNew
        color={getColorStatus(status)?.color as keyof typeof theme.ds.colors}
        weight="bold"
        size="xxsmall"
      >
        Descrição
      </SupportTextNew>

      {order.finish ? (
        order.finish.materials.length ? (
          order.finish.materials.map((material, index) => {
            return (
              <>
                <SupportTextNew
                  size="small"
                  weight="semiBold"
                  color="neutral800"
                >
                  {material.name}
                </SupportTextNew>

                <S.QuantityWrapper>
                  <div>
                    <SupportTextNew
                      color="neutral800"
                      weight="semiBold"
                      size="xxsmall"
                    >
                      Quant. solicitada
                    </SupportTextNew>

                    <SupportTextNew
                      color="neutral800"
                      weight="semiBold"
                      size="small"
                    >
                      {material.quantity}
                    </SupportTextNew>
                  </div>

                  <div>
                    <SupportTextNew
                      color="neutral800"
                      weight="semiBold"
                      size="xxsmall"
                    >
                      Quant. autorizada
                    </SupportTextNew>

                    <SupportTextNew
                      color="neutral800"
                      weight="semiBold"
                      size="small"
                    >
                      {material.authorizedQuantity}
                    </SupportTextNew>
                  </div>
                </S.QuantityWrapper>

                {index !== order.materials.length - 1 && (
                  <DividerNew
                    marginBottom="8px"
                    marginTop="8px"
                    color={
                      getColorStatus(status)
                        ?.color as keyof typeof theme.ds.colors
                    }
                  />
                )}
              </>
            )
          })
        ) : (
          <SupportTextNew color="neutral800" weight="semiBold" size="small">
            Não preenchido
          </SupportTextNew>
        )
      ) : order.materials.length ? (
        order.materials.map((material, index) => {
          return (
            <>
              <SupportTextNew size="small" weight="semiBold" color="neutral800">
                {material.name}
              </SupportTextNew>

              <S.QuantityWrapper>
                <div>
                  <SupportTextNew
                    color="neutral800"
                    weight="semiBold"
                    size="xxsmall"
                  >
                    Quant. solicitada
                  </SupportTextNew>

                  <SupportTextNew
                    color="neutral800"
                    weight="semiBold"
                    size="small"
                  >
                    {material.quantity}
                  </SupportTextNew>
                </div>
              </S.QuantityWrapper>

              {index !== order.materials.length - 1 && (
                <DividerNew
                  marginBottom="8px"
                  marginTop="8px"
                  color={
                    getColorStatus(status)
                      ?.color as keyof typeof theme.ds.colors
                  }
                />
              )}
            </>
          )
        })
      ) : (
        <SupportTextNew color="neutral800" weight="semiBold" size="small">
          Não preenchido
        </SupportTextNew>
      )}
    </AccordionCardNew>
  )
}

type ObservationAccordionProps = {
  order: AmbulatoryOrderModel
  informationAccordionOpen: any
  setInformationAccordionOpen: any
}

const ObservationAccordion = ({
  order,
  informationAccordionOpen,
  setInformationAccordionOpen
}: ObservationAccordionProps) => {
  function handleSetInformation() {
    if (informationAccordionOpen === 'ObservationInfo') {
      setInformationAccordionOpen('')
    } else {
      setInformationAccordionOpen('ObservationInfo')
    }
  }

  return (
    <AccordionCardNew
      accordionLabel="ObservationInfo"
      expanded={informationAccordionOpen === 'ObservationInfo'}
      handleChange={handleSetInformation}
      header="Observações"
      shadow="satin"
      padding="16px"
      background="neutral25"
    >
      <>
        {order.finish ? (
          order.finish.observation ? (
            <SupportTextNew color="neutral800" weight="normal" size="small">
              {order.finish.observation}
            </SupportTextNew>
          ) : (
            <SupportTextNew color="neutral800" weight="semiBold" size="small">
              Não preenchido
            </SupportTextNew>
          )
        ) : order.observation ? (
          <SupportTextNew color="neutral800" weight="normal" size="small">
            {order.observation}
          </SupportTextNew>
        ) : (
          <SupportTextNew color="neutral800" weight="semiBold" size="small">
            Não preenchido
          </SupportTextNew>
        )}
      </>
    </AccordionCardNew>
  )
}

type DocumentsAccordionProps = {
  order: AmbulatoryOrderModel
  informationAccordionOpen: any
  setInformationAccordionOpen: any
} & WithLoadingProps

const DocumentsAccordion = WithLoading(
  ({
    order,
    informationAccordionOpen,
    setInformationAccordionOpen,
    setIsLoading
  }: DocumentsAccordionProps) => {
    function handleSetInformation() {
      if (informationAccordionOpen === 'DocumentInfo') {
        setInformationAccordionOpen('')
      } else {
        setInformationAccordionOpen('DocumentInfo')
      }
    }

    const ambulatoryService = useServices().ambulatory

    const downloadDocument = async (name: string) => {
      setIsLoading(true)
      try {
        const file = await ambulatoryService.loadAmbulatoryOrderDocument({
          orderId: order.id,
          documentName: name
        })

        if (file) {
          downloadFileFromBlob(file.data, file.contentType, name)
        }
      } catch {
        toast.error('Ocorreu um erro ao baixar o documento')
      } finally {
        setIsLoading(false)
      }
    }

    const authorizedGuideDocuments = order.documents?.filter(
      (document: any) => document.type === 'AUTHORIZED_GUIDE'
    )

    return (
      <AccordionCardNew
        accordionLabel="DocumentInfo"
        expanded={informationAccordionOpen === 'DocumentInfo'}
        handleChange={handleSetInformation}
        header="Anexos"
        shadow="satin"
        padding="16px"
        background="neutral25"
      >
        <>
          {authorizedGuideDocuments.length ? (
            <>
              {authorizedGuideDocuments.map((file: any, index: number) => (
                <div key={index}>
                  <CardNew
                    shadow="none"
                    background="neutral25"
                    border="neutral100"
                    padding="12px 16px"
                    style={{
                      flexDirection: 'row',
                      justifyContent: 'space-between'
                    }}
                  >
                    <div>
                      <SupportTextNew
                        color="neutral800"
                        size="xxsmall"
                        weight="semiBold"
                      >
                        Guia autorizada.pdf
                      </SupportTextNew>

                      <S.DocumentInfo>
                        <SupportTextNew
                          color="neutral700"
                          size="xxsmall"
                          style={{
                            alignSelf: 'center',
                            width: '40px'
                          }}
                        >
                          {(file.size / 1024).toFixed()}kb
                        </SupportTextNew>

                        <SupportTextNew
                          color="neutral700"
                          style={{ fontSize: '4px', alignSelf: 'center' }}
                        >
                          ●
                        </SupportTextNew>

                        <SupportTextNew
                          color="neutral700"
                          size="xxsmall"
                          style={{
                            alignSelf: 'center',
                            width: '200px',
                            marginLeft: '10px'
                          }}
                        >
                          Incluído em{' '}
                          {moment(file.createdAt)
                            .utc()
                            .format('DD [de] MMM[.] [de] YYYY ')}{' '}
                          por {file.createdByName}
                        </SupportTextNew>
                      </S.DocumentInfo>
                      <S.Line></S.Line>
                    </div>

                    <S.ActionIcons>
                      {downloadDocument && (
                        <DownloadIcon
                          className="download-icon"
                          onClick={() => downloadDocument(file.filename)}
                        />
                      )}
                    </S.ActionIcons>
                  </CardNew>
                </div>
              ))}
            </>
          ) : (
            <SupportTextNew color="neutral800" weight="semiBold" size="small">
              Nenhum documento incluído
            </SupportTextNew>
          )}
        </>
      </AccordionCardNew>
    )
  }
)

type TimeLineStatusProps = {
  order: AmbulatoryOrderModel
  activeStatus: AmbulatoryAuthorizationStatus
}

const TimeLineStatusContainer = ({
  order,
  activeStatus
}: TimeLineStatusProps) => {
  const [isOpen, setIsOpen] = useState(false)

  function onClose() {
    setIsOpen(false)
  }

  const mainStatusCreatedAt = order.statuses.find(
    (status: any) => status.isActive
  )?.createdAt

  return (
    <>
      <S.StatusCard onClick={() => setIsOpen(true)}>
        <S.Icon
          color={
            getColorStatus(activeStatus)?.color as keyof typeof theme.ds.colors
          }
        >
          <PendencyIcon />
        </S.Icon>
        <S.Separator />
        <div>
          <SupportTextNew
            weight="bold"
            color={
              getColorStatus(activeStatus)
                ?.color as keyof typeof theme.ds.colors
            }
            size="small"
          >
            {AmbulatoryAuthorizationStatusSingular[activeStatus]}
          </SupportTextNew>

          {activeStatus === AmbulatoryAuthorizationStatus.AUTHORIZED ? (
            <SupportTextNew weight="normal" color="neutral800" size="xsmall">
              Duração total:
              {' ' +
                moment(
                  moment.utc(mainStatusCreatedAt).format('YYYY-MM-DD HH:mm:ss')
                ).fromNow(true)}
            </SupportTextNew>
          ) : (
            <SupportTextNew weight="normal" color="neutral800" size="xsmall">
              Pedido criado {getDateTextFromNow(order.createdAt)}
            </SupportTextNew>
          )}
        </div>
        <RightArrow style={{ marginLeft: 'auto' }} />
      </S.StatusCard>

      <StatusSheetModal
        isOpen={isOpen}
        onClose={onClose}
        order={order}
        activeStatus={activeStatus}
      />
    </>
  )
}

type StatusSheetModalProps = {
  order: AmbulatoryOrderModel
  activeStatus: AmbulatoryAuthorizationStatus
  isOpen: boolean
  onClose: () => void
}

const StatusSheetModal = ({
  isOpen,
  onClose,
  order,
  activeStatus
}: StatusSheetModalProps) => {
  order.statuses.sort(
    (prevStep, nextStep) =>
      (new Date(nextStep.createdAt) as any) -
      (new Date(prevStep.createdAt) as any)
  )

  return (
    <SheetModalNew
      isOpen={isOpen}
      onClose={() => onClose()}
      title="Status do pedido"
      gap="24px"
      size={600}
    >
      <S.Timeline>
        {order.statuses.map((step, index) => {
          return (
            <S.TimelineStep
              key={step.status}
              color={
                index > 0
                  ? (getColorStatus(activeStatus)
                      ?.background as keyof typeof theme.ds.colors)
                  : (getColorStatus(activeStatus)
                      ?.color as keyof typeof theme.ds.colors)
              }
              pipe={
                getColorStatus(activeStatus)
                  ?.background as keyof typeof theme.ds.colors
              }
            >
              <SupportTextNew
                color="neutral900"
                size="medium"
                className="capitalize-fl"
                style={{ whiteSpace: 'nowrap' }}
              >
                {
                  AmbulatoryAuthorizationStatusSingular[
                    step.status as keyof typeof AmbulatoryAuthorizationStatusSingular
                  ]
                }
              </SupportTextNew>
              <SupportTextNew color="neutral900" weight="bold" size="xsmall">
                {moment(step.createdAt)
                  .utc()
                  .format('DD [de] MMM[.] [de] YYYY [às] HH[h]mm')}
              </SupportTextNew>
            </S.TimelineStep>
          )
        })}

        <S.TimelineStep
          color={
            getColorStatus(activeStatus)
              ?.background as keyof typeof theme.ds.colors
          }
        >
          <SupportTextNew
            color="neutral900"
            size="medium"
            className="capitalize-fl"
          >
            Pedido criado
          </SupportTextNew>
          <SupportTextNew color="neutral900" weight="bold" size="xsmall">
            {moment(order.createdAt)
              .utc()
              .format('DD [de] MMM[.] [de] YYYY [às] HH[h]mm')}
          </SupportTextNew>
        </S.TimelineStep>
      </S.Timeline>
    </SheetModalNew>
  )
}
