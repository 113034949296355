import React, { Fragment, useContext, useState } from 'react'
import ButtonNew from 'presentation/shared/components/ButtonNew'
import DividerNew from 'presentation/shared/components/DividerNew'
import TextAreaNew from 'presentation/shared/components/TextAreaNew'
import MessageContainer from 'presentation/shared/layouts/MessageContainer'
import CardNew from 'presentation/shared/components/CardNew'
import StatusTag from 'presentation/shared/components/StatusTag'
import { SurgicalOrderContext } from 'main/factories/pages/doctor/surgical-order/surgical-order-factory'
import { DocumentsList } from 'common/enum/documents-list'
import DocumentAccordion from 'presentation/shared/layouts/DocumentAccordeon'
import { useFormik } from 'formik'
import * as yup from 'yup'
import * as S from './styles'
import { ContainerNew } from 'presentation/shared/components/ContainerNew'
import { useHistory } from 'react-router'
import DocumentSuccessModal from './DocumentConfirmModal'
import { PendencyTypes } from 'presentation/shared/layouts/PendencyCard'

type Props = {
  uploadDocument: (files: File[], type: string) => void
  deleteDocument: (document_id: number, group_id: string) => void
  downloadDocument: (
    document_id: number,
    group_id: string,
    type: keyof typeof DocumentsList
  ) => void
  answerSurgicalPendency: (values: { answer: string }) => void
  setDocumentSuccessModalIsOpen: (isOpen: boolean) => void
  documentSuccessModalIsOpen: boolean
  pendencyOrderId?: number
}

const SurgicalOrderPendency = ({
  uploadDocument,
  deleteDocument,
  downloadDocument,
  answerSurgicalPendency,
  setDocumentSuccessModalIsOpen,
  documentSuccessModalIsOpen,
  pendencyOrderId
}: Props) => {
  const history = useHistory()
  const {
    state: { currentPendency }
  } = useContext<any>(SurgicalOrderContext)
  const [isHistoryExpanded, setIsHitoryExpanded] = useState(false)
  const [expandedDocument, setExpandedDocument] = useState('')

  const formik = useFormik({
    initialValues: {
      answer: ''
    },
    validationSchema: validationSchema,
    onSubmit: answerSurgicalPendency
  })

  const displayedHistoric = () => {
    return !isHistoryExpanded && currentPendency.historic.length > 3
      ? currentPendency.historic.slice(0, 3)
      : currentPendency.historic
  }

  const AnswerPendency = () => {
    return (
      <ButtonNew
        size="large"
        disabled={
          !formik.isValid || !formik.values.answer || formik.isSubmitting
        }
        onClick={formik.submitForm}
        fullWidth
      >
        Responder
      </ButtonNew>
    )
  }

  const isResolvedPendency = () => {
    return currentPendency.status !== 'RESOLVED'
  }

  const changeExpandedAccordeon = (
    accordeonLabel: string,
    isExpanded: boolean
  ) => {
    setExpandedDocument(isExpanded ? accordeonLabel : '')
  }

  const title =
    currentPendency?.type === PendencyTypes.HELP_CARD
      ? `Preciso de ajuda ${('000' + pendencyOrderId).slice(-3)}`
      : `Pendência ${('000' + pendencyOrderId).slice(-3)}`

  return (
    <>
      <ContainerNew
        title={title}
        gap="16px"
        primaryButton={isResolvedPendency() && <AnswerPendency />}
        subHeader={
          <StatusTag status={currentPendency.status} type="pendency" />
        }
      >
        <CardNew border="primaryLight100" shadow="none" padding="0px">
          {displayedHistoric().map(
            (
              { message, createdAt, responsible, profile, description }: any,
              index: number
            ) => {
              return (
                <Fragment key={index}>
                  <MessageContainer
                    message={message}
                    createdAt={createdAt}
                    responsible={responsible}
                    profile={profile}
                    description={description}
                  />
                  {(index !== displayedHistoric().length - 1 ||
                    currentPendency.historic.length > 3) && (
                    <DividerNew
                      marginBottom="4px"
                      marginTop="4px"
                      leak="-8px"
                      color="neutral100"
                    />
                  )}
                </Fragment>
              )
            }
          )}
          {currentPendency.historic.length > 3 && (
            <ButtonNew
              variant="text"
              onClick={() => setIsHitoryExpanded(!isHistoryExpanded)}
              style={{ justifyContent: 'flex-start', padding: '24px 16px' }}
            >
              {isHistoryExpanded ? 'Ver menos' : 'Ver mais'}
            </ButtonNew>
          )}
        </CardNew>

        {currentPendency?.type !== PendencyTypes.HELP_CARD && (
          <>
            <DividerNew marginBottom="0px" marginTop="0px" />
            {isResolvedPendency() && (
              <ButtonNew
                size="large"
                outlined
                onClick={() =>
                  history.push('/pedido-cirurgico/documentos-de-pendencia')
                }
              >
                Incluir documento
              </ButtonNew>
            )}
          </>
        )}

        <S.FlexDiv>
          {currentPendency?.documents?.length > 0 && (
            <div>
              {currentPendency?.documents?.map(
                (document: any, index: number) => {
                  return (
                    <Fragment key={index}>
                      <DocumentAccordion
                        key={document.key}
                        document={document}
                        padding="0"
                        hideHeaderDivider
                        handleChange={changeExpandedAccordeon}
                        expandedDocument={expandedDocument}
                        onDeleteFile={deleteDocument}
                        onDownloadFile={downloadDocument}
                        onUploadFile={
                          !['APPROVED', 'approved'].includes(document.status)
                            ? (files: any) =>
                                uploadDocument(files, document.type)
                            : undefined
                        }
                        multiple
                        uploadButton={true}
                      />
                      {currentPendency?.documents?.length - 1 !== index && (
                        <DividerNew marginBottom="8px" marginTop="8px" />
                      )}
                    </Fragment>
                  )
                }
              )}
            </div>
          )}
          {isResolvedPendency() && (
            <DividerNew marginTop="0px" marginBottom="0px" />
          )}
        </S.FlexDiv>

        {isResolvedPendency() && (
          <S.TextAreaContainer>
            <TextAreaNew
              label="Adicionar comentário"
              placeholder="Adicionar um comentário para a CRMO"
              value={formik.values.answer}
              onInputChange={formik.handleChange('answer')}
              rows={3}
            />
            <S.CharactersCounter
              size="xsmall"
              color={formik.isValid ? 'neutral400' : 'danger300'}
            >
              {formik.values.answer.length}/800
            </S.CharactersCounter>
          </S.TextAreaContainer>
        )}
      </ContainerNew>

      <DocumentSuccessModal
        isOpen={documentSuccessModalIsOpen}
        onClose={() => setDocumentSuccessModalIsOpen(false)}
      />
    </>
  )
}

export default SurgicalOrderPendency

const validationSchema = yup.object().shape({
  answer: yup.string().required().max(800, 'Limite de caracteres excedido')
})
