import React, { useEffect, useState } from 'react'

import {
  WithLoading,
  WithLoadingProps
} from 'presentation/shared/components/HOCs/WithLoading'
import ButtonNew from 'presentation/shared/components/ButtonNew'
import { ContainerNew } from 'presentation/shared/components/ContainerNew'
import * as S from '../styles'
import { useHistory, useLocation } from 'react-router'
import HeadingNew from 'presentation/shared/components/HeadingNew'
import { TitleAndSubtitleWrapper } from 'presentation/shared/components/ContainerTitleAndSubtitle'
import CheckboxNew from 'presentation/shared/components/CheckboxNew'
import { SurgicalOrderAnesthesiologyModel } from 'domain/entities/surgical-order-anesthesiology-model'
import { useServices } from 'presentation/hooks/use-services'
import { toast } from 'react-toastify'
import { useStores } from 'presentation/hooks/use-stores'
import { useFormik } from 'formik'

const SelectAnesthesiologyForm = WithLoading(
  ({ setIsLoading }: WithLoadingProps) => {
    const { state } = useLocation<{
      isEditing?: boolean
    }>()
    const history = useHistory()
    const surgicalOrderContext = useStores().surgicalOrder
    const surgicalOrderObjContext = surgicalOrderContext.getSurgicalOrder()
    const surgicalOrderService = useServices().surgicalOrder

    const [anesthesiologies, setAnesthesiologies] = useState<
      SurgicalOrderAnesthesiologyModel[]
    >([])
    const [selectedAnesthesiologies] = useState<
      SurgicalOrderAnesthesiologyModel[]
    >([])

    const loadAnesthesiologies = async () => {
      try {
        setIsLoading(true)

        const loadedAnesthesiologies =
          await surgicalOrderService.loadAnesthesiologies()

        setAnesthesiologies(loadedAnesthesiologies)
      } catch (error: any) {
        toast.error('Ocorreu um erro ao buscar anestesiologias')
      } finally {
        setIsLoading(false)
      }
    }

    useEffect(() => {
      loadAnesthesiologies()
    }, [])

    const formik = useFormik({
      initialValues: {
        anesthesiologies: surgicalOrderObjContext?.anesthesiologies || []
      } as any,
      onSubmit: async (values) => {
        try {
          setIsLoading(true)
          await surgicalOrderService.updateSurgicalOrder({
            surgical_order_id:
              surgicalOrderContext.getSurgicalOrder().surgical_order_id,
            patient: {
              patient_id: Number(
                surgicalOrderContext.patient.patient.patient_id
              ),
              patient_name:
                surgicalOrderContext.getPatient().patient.patient_name ||
                surgicalOrderContext.getPatient().patient.name
            },
            anesthesiology: values.anesthesiologies,
            ...values
          })

          surgicalOrderContext.setAnesthesiology(selectedAnesthesiologies)

          // toast.success('Os dados do pedido foram atualizados!')

          if (state?.isEditing) {
            history.push('/novo-pedido/resumo')
          } else {
            history.push('/novo-pedido/historia-clinica')
          }
        } catch (error: any) {
          toast.error(`[Anestesiologia] ${error?.message}`)
          history.goBack()
        } finally {
          setIsLoading(false)
        }
      }
    })

    return (
      <ContainerNew
        buttonsHasBoxShadow={!state?.isEditing}
        primaryButton={
          <ButtonNew onClick={formik.submitForm} fullWidth size="large">
            {state?.isEditing ? 'Salvar edição' : 'Próximo'}
          </ButtonNew>
        }
        secondaryButton={
          !state?.isEditing && (
            <ButtonNew onClick={history.goBack} fullWidth outlined size="large">
              Anterior
            </ButtonNew>
          )
        }
      >
        <S.FormWrapper>
          <TitleAndSubtitleWrapper
            title="Outros materiais"
            subtitle="Deseja incluir algum item na lista de OPMES da cirurgia?"
          />
          <HeadingNew
            as="label"
            color="neutral900"
            size="small"
            weight="normal"
          >
            Selecione os itens que deseja incluir:
          </HeadingNew>
          {anesthesiologies
            ? anesthesiologies.map((anesthesiology, index) => {
                return (
                  <CheckboxNew
                    key={anesthesiology.anesthesiology_id}
                    label={anesthesiology.name}
                    labelFor={`anesthesiology_${index}`}
                    value={anesthesiology.anesthesiology_id}
                    checked={formik.values.anesthesiologies.some(
                      (selectedAnesthesiology: any) =>
                        selectedAnesthesiology.anesthesiology_id ===
                        anesthesiology.anesthesiology_id
                    )}
                    onCheck={(isSelecting: boolean) => {
                      if (isSelecting) {
                        formik.setFieldValue('anesthesiologies', [
                          ...formik.values.anesthesiologies,
                          anesthesiology
                        ])
                      } else {
                        formik.setFieldValue(
                          'anesthesiologies',
                          formik.values.anesthesiologies.filter(
                            (selectedAnesthesiology: any) =>
                              selectedAnesthesiology.anesthesiology_id !==
                              anesthesiology.anesthesiology_id
                          )
                        )
                      }
                    }}
                  />
                )
              })
            : null}
        </S.FormWrapper>
      </ContainerNew>
    )
  }
)

export default SelectAnesthesiologyForm
