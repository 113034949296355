const capitalizeFirstLetters = (text: string) => {
  return text
    .split(' ')
    .map((namePart) => {
      return (
        namePart.charAt(0).toUpperCase() + namePart.substring(1).toLowerCase()
      )
    })
    .join(' ')
}

export default capitalizeFirstLetters
