import React, { useEffect, useState, useContext } from 'react'
import SurgicalSchedulingInformationsLayout from 'presentation/doctor/layouts/SurgicalSchedulingInformations'
import { useServices } from 'presentation/hooks/use-services'
import { HospitalModel } from 'domain/usecases/surgical-scheduling/get-hospitals'
import { SurgeryCenterModel } from 'domain/usecases/surgical-scheduling/get-surgery-center'
import { SurgicalSchedulinhContext } from 'main/factories/pages/doctor/surgical-scheduling/surgical-scheduling-factory'
import moment from 'moment-timezone'
import { useLocation, useHistory } from 'react-router'
import {
  WithLoading,
  WithLoadingProps
} from 'presentation/shared/components/HOCs/WithLoading'
import { toast } from 'react-toastify'
import HeaderNew from 'presentation/shared/components/HeaderNew'

function SurgicalSchedulingInformation({ setIsLoading }: WithLoadingProps) {
  const location = useLocation<{ isReviewing?: boolean }>()
  const history = useHistory()
  const { dispatch, state } = useContext<any>(SurgicalSchedulinhContext)
  const [isLoadingSurgeryCenters, setIsLoadingSurgeryCenters] = useState(false)
  const [hospitalsList, setHospitalsList] = useState<HospitalModel[]>(
    [] as HospitalModel[]
  )
  const [surgicalCentersList, setSurgicalCentersList] = useState<
    SurgeryCenterModel[]
  >([] as SurgeryCenterModel[])

  const surgicalOrderService = useServices().surgicalOrder
  const surgicalSchedulingService = useServices().surgicalScheduling

  const getSurgicalOrder = async () => {
    if (!location.state?.isReviewing) {
      try {
        const response = await surgicalOrderService.loadSurgicalOrder(
          state.surgical_order_id,
          [
            `
              surgical_order_id
              extraBlood
              freeze
              technique
              patientHospitalized
              cti
              hospital_id
              surgical_center_id
              surgicalCenter
              hospitalizationType
              hospital {
                name
              }
              procedure {
                isMain
                code
                description
                quantity
                surgery_id
              }
              healthInsurance {
                health_insurance_id
                healthInsuranceName
              }
              doctor {
                doctor_id
                name
              }
              patient {
                patient_id
                name
                birthday
              }
              expectedDate
            `
          ]
        )

        const {
          extraBlood,
          freeze,
          surgical_center_id,
          hospital_id,
          cti,
          technique,
          patientHospitalized,
          procedure,
          expectedDate,
          healthInsurance,
          hospitalizationType,
          doctor,
          patient,
          surgicalCenter,
          surgical_order_id,
          hospital
        } = response

        dispatch({
          type: 'INFORMATIONS',
          payload: {
            surgical_order: {
              blood_reserve: extraBlood,
              freezing: freeze,
              room_id: surgical_center_id,
              patient_internal: patientHospitalized,
              patient_urgency: hospitalizationType === 'urgency',
              surgery_center_name: surgicalCenter,
              tecnic: technique,
              hospital_name: hospital?.name,
              hospital_id,
              cti,
              id: surgical_order_id,
              procedures: procedure,
              expectedDate: expectedDate
                ? moment(expectedDate).utc().format('YYYY-MM-DD')
                : '',
              health_insurance: {
                id: healthInsurance?.health_insurance_id,
                name: healthInsurance?.healthInsuranceName
              }
            },
            doctor: {
              name: doctor?.name,
              id: doctor?.doctor_id
            },
            patient: {
              name: patient?.name,
              id: patient?.patient_id,
              birthday: patient?.birthday
            }
          }
        })
      } catch (error: any) {
        toast.error('Ocorreu um erro ao carregar as informaçoes.')
      }
    }
  }

  const getHospitalsList = async () => {
    try {
      const response = await surgicalSchedulingService.getHospitals()
      setHospitalsList(response.data)
    } catch (error: any) {
      toast.error('Houve uma falha ao carregar os hospitais.')
    }
  }

  const getSurgicalCentersList = async (code: number) => {
    setIsLoadingSurgeryCenters(true)
    try {
      const response = await surgicalSchedulingService.getSurgeryCenter({
        code
      })
      setSurgicalCentersList(response.data)
    } catch (error: any) {
      toast.error('Ocorreu um erro ao carregar os centros cirurgicos.')
    } finally {
      setIsLoadingSurgeryCenters(false)
    }
  }

  useEffect(() => {
    setIsLoading(true)
    Promise.all([getHospitalsList(), getSurgicalOrder()]).finally(() => {
      setIsLoading(false)
    })
  }, [])

  return (
    <>
      <HeaderNew
        actualPageTitle="Agendar cirurgia"
        actualPageOnClick={() =>
          history.push('/pedidos-cirurgicos/agendamento')
        }
      />
      <SurgicalSchedulingInformationsLayout
        hospitalsList={hospitalsList}
        surgicalCentersList={surgicalCentersList}
        surgicalOrder={state.surgical_order}
        isLoadingSurgeryCenters={isLoadingSurgeryCenters}
        getSurgicalCentersList={getSurgicalCentersList}
        dispatch={dispatch}
      />
    </>
  )
}

export default WithLoading(SurgicalSchedulingInformation)
