import React, { useEffect, useState } from 'react'

import { PatientDocument } from 'common/enum/patient-document'
import { Header } from 'presentation/shared/components/Header'
import BottomMenu from 'presentation/shared/components/BottomMenu'
import { Container } from 'presentation/shared/components/Container'
import Button from 'presentation/shared/components/Button'
import { useServices } from 'presentation/hooks/use-services'
import Modal from 'presentation/shared/components/Modal'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import TextButton from 'presentation/shared/components/TextButton'
import {
  WithLoading,
  WithLoadingProps
} from 'presentation/shared/components/HOCs/WithLoading'
import { useUploadSection } from 'presentation/hooks/use-upload-section'
import { UploadSection } from 'presentation/shared/components/UploadSection'
import { ListFile } from 'presentation/shared/components/UploadSection/UploadListFiles/UploadListFile'
import { DocumentsType } from 'domain/entities/document-types'
import { renameFile } from 'common/utils/file/renameFile'
import TypedModal from 'presentation/shared/components/Modal'
import { generateHash } from 'common/utils/generateHash'

export type SurgicalOrderListFileType = {
  document_id?: number
  type: PatientDocument
}

type Props = {
  type: PatientDocument
  title?: string
  surgicalOrderId: number
  initialValues: ListFile<SurgicalOrderListFileType>[]
  isSolicitation?: boolean
  isPatient: boolean
} & WithLoadingProps

export const AppendSurgicalOrderDocumentLayout = WithLoading(
  ({
    title,
    surgicalOrderId,
    initialValues,
    type,
    setIsLoading,
    isSolicitation,
    isPatient
  }: Props) => {
    const [handleSuccessModal, setHandleSuccessModal] = useState<boolean>(false)
    const [showCancel, setShowCancel] = useState<boolean>(false)
    const [showConfirmUnify, setShowConfirmUnify] = useState<boolean>(false)
    const [deleteFile, setDeleteFile] =
      useState<ListFile<SurgicalOrderListFileType>>()
    const [filesDeleted, setFilesDeleted] = useState<number[]>([])
    const services = useServices().surgicalOrder
    const history = useHistory()

    const getDocumentType = () => {
      return DocumentsType.getDocumentTypeByType(type)
    }
    const documentType = getDocumentType()

    const uploadSolicitationDocuments = async (files: File[]) => {
      if (isPatient && isSolicitation)
        return uploadNonSolicitationDocuments(files)
      for (const file of files) {
        const formData = new FormData()
        formData.append('surgical_order_id', surgicalOrderId.toString())
        formData.append('type', type)
        formData.append('file', file)
        await services.uploadSurgicalOrderDocument({
          file: file,
          type: type,
          form: formData
        })
      }
    }

    const uploadNonSolicitationDocuments = async (files: File[]) => {
      setShowConfirmUnify(false)
      const group_id = generateHash()
      for (const file of files) {
        await services.uploadToMergeDocument({
          file,
          type,
          group_id
        })
      }
      await services.confirmMergeDocuments({
        group_id,
        surgical_order_id: surgicalOrderId
      })
    }

    const getNewFiles = () => {
      const filesNotUploaded = filesSection.filter(
        (file) => !file.identifier?.document_id
      )
      return filesNotUploaded.map((listFile) => {
        const file = listFile.file
        let newFileName: string = documentType?.type[0] ?? ''
        if (title?.startsWith('Laudos')) {
          newFileName = title
        }
        return renameFile(file, newFileName, {
          forceOriginalExtension: true
        })
      })
    }

    const onUpload = () => {
      if (
        (!isSolicitation && filesSection.length !== 0) ||
        (isPatient && isSolicitation)
      )
        return setShowConfirmUnify(true)
      return uploadFile()
    }

    const deleteUpdatedFiles = async () => {
      const filesToDelete: ListFile<SurgicalOrderListFileType>[] = []
      initialValues.forEach((file) => {
        if (!file.identifier?.document_id) return
        const wasUpdated = !filesSection.some(
          (fileSection) =>
            fileSection.identifier?.document_id === file.identifier?.document_id
        )
        const wasDeleted = filesDeleted.includes(file.identifier?.document_id)
        if (!wasUpdated || wasDeleted) return
        filesToDelete.push(file)
      })
      for (const file of filesToDelete) {
        if (file.identifier?.document_id) {
          await services.deleteUploadedDocuments({
            document_id: file.identifier?.document_id
          })
        }
      }
    }

    const uploadFile = async () => {
      const files = getNewFiles()
      if (files.length === 0) return history.goBack()
      try {
        setIsLoading(true)
        if (isSolicitation) {
          await deleteUpdatedFiles()
          await uploadSolicitationDocuments(files)
        } else {
          await uploadNonSolicitationDocuments(files)
        }
        setHandleSuccessModal(true)
      } catch (error: any) {
        toast.error(error.message)
      } finally {
        setIsLoading(false)
      }
    }

    useEffect(() => {
      if (initialValues.length !== filesSection.length) {
        resetInfo(initialValues)
      }
    }, [initialValues])

    const {
      filesInfo,
      onAdd,
      onUpdate,
      onDelete,
      onDownload,
      files: filesSection,
      resetInfo
    } = useUploadSection({
      maxFileSizeInMB: documentType?.maxFileSizeInMB,
      maxFiles: documentType?.maxFiles,
      verifyUniqueFile: true,
      initialFiles: initialValues,
      extensionsAllowed: ['pdf', 'jpg', 'jpeg']
    })

    const handleAdd = (files: ListFile<SurgicalOrderListFileType>[]) => {
      onAdd(
        files.map((file) => ({
          ...file,
          identifier: {
            type
          }
        }))
      )
    }

    const handleUpdate = (file: ListFile<SurgicalOrderListFileType>) => {
      onUpdate({
        ...file,
        identifier: {
          type
        }
      })
    }

    const deleteUploadedDocsToSurgicalOrder = async () => {
      const document = deleteFile
      if (!document) return
      const document_id = document.identifier?.document_id
      if (document_id) {
        setIsLoading(true)
        setFilesDeleted([...filesDeleted, document_id])
        await services.deleteUploadedDocuments({
          document_id
        })
        setIsLoading(false)
      }
      onDelete(document)
      setDeleteFile(undefined)
    }

    const isNotSolicitationNotEmpty =
      !isSolicitation && initialValues.length !== 0

    const isPatientNotEmpty = isPatient && initialValues.length !== 0
    const onlyVisualization = isNotSolicitationNotEmpty || isPatientNotEmpty

    const getCan = () => {
      if (onlyVisualization) {
        return {
          canAdd: false,
          canDelete: false,
          canDownload: true,
          canUpdate: false
        }
      }
      return {
        canAdd: filesInfo.canAdd,
        canDelete: true,
        canDownload: true,
        canUpdate: true
      }
    }

    const canUpload = getCan()

    const isRequired = documentType?.minFiles !== 0

    const onCancel = () => {
      const hasNotUploadedFiles = filesSection.some(
        (file) => !file.identifier?.document_id
      )
      if (hasNotUploadedFiles) {
        setShowCancel(true)
      } else {
        history.goBack()
      }
    }

    return (
      <>
        <Header />
        <Container
          title={title || documentType?.label}
          titleColor="primary"
          subtitle="Insira abaixo a imagem do seu documento"
          actualPageText="Documentos"
          actualPageOnClick={onCancel}
          primaryButton={
            <>
              {!onlyVisualization && (
                <Button
                  fullWidth
                  type="button"
                  onClick={() => onUpload()}
                  disabled={isRequired ? filesSection.length < 1 : false}
                >
                  Enviar
                </Button>
              )}
            </>
          }
          secondaryButton={
            <>
              {!onlyVisualization && (
                <TextButton onClick={onCancel} style={{ padding: '10px 0' }}>
                  Cancelar
                </TextButton>
              )}
            </>
          }
          justifyContent="center"
        >
          <UploadSection
            files={filesSection}
            can={canUpload}
            onAdd={handleAdd}
            onDelete={(doc) => setDeleteFile(doc)}
            onUpdate={handleUpdate}
            onDownload={onDownload}
            errors={filesInfo.errorMessage}
            acceptFiles={'application/pdf, image/jpeg'}
          />
        </Container>
        <BottomMenu />
        <Modal
          title="Documentos anexados com sucesso!"
          show={handleSuccessModal}
          close={history.goBack}
        />
        <TypedModal
          type="alert"
          show={!!deleteFile}
          actionLabel="Excluir"
          action={deleteUploadedDocsToSurgicalOrder}
          title="Deseja realmente excluir?"
          description="Essa ação não poderá ser desfeita."
          hasCloseButton="Cancelar"
          closeButtonProps={{
            variant: 'outlined',
            style: {
              marginTop: '10px'
            }
          }}
          close={() => setDeleteFile(undefined)}
          preventAutomateClose
        />
        <TypedModal
          type="alert"
          show={showCancel}
          actionLabel="Confirmar"
          action={history.goBack}
          title="Você possui documentos não enviados, deseja realmente cancelar?"
          description="Documentos não enviados serão perdidos."
          hasCloseButton="Cancelar"
          closeButtonProps={{
            variant: 'outlined',
            style: {
              marginTop: '10px'
            }
          }}
          close={() => setShowCancel(false)}
          preventAutomateClose
        />
        <TypedModal
          type="check"
          show={showConfirmUnify}
          actionLabel="Confirmar"
          action={() => uploadFile()}
          title="Deseja realmente confirmar?"
          description="Após confirmação, os documentos serão unificados, esta ação não poderá ser desfeita."
          hasCloseButton="Cancelar"
          closeButtonProps={{
            variant: 'outlined',
            style: {
              marginTop: '10px'
            }
          }}
          close={() => setShowConfirmUnify(false)}
          subtitleProps={{
            colorFont: 'gray'
          }}
          preventAutomateClose
        />
      </>
    )
  }
)
