import React from 'react'

import HeaderNew from 'presentation/shared/components/HeaderNew'
import AddClinicalHistoryAndRecordForm from 'presentation/doctor/components/Forms/AddSurgicalOrderNew/AddClinicalHistoryAndRecord'

const AddClinicalHistoryAndRecordLayout = () => {
  return (
    <>
      <HeaderNew actualPageTitle="Novo pedido cirúrgico - Procedimentos e CID" />
      <AddClinicalHistoryAndRecordForm />
    </>
  )
}

export default AddClinicalHistoryAndRecordLayout
