export default function deleteNonMandatoryFields<T>(
  params: Array<keyof T>,
  obj: T | undefined
) {
  if (params && obj) {
    for (const item of params) {
      delete obj[item]
    }
  }

  return obj
}
