export enum AmbulatoryAuthorizationStatus {
  HOSPITAL_ANALYSIS = 'HOSPITAL_ANALYSIS',
  ANALYSIS_INSURANCE = 'ANALYSIS_INSURANCE',
  AUTHORIZED = 'AUTHORIZED',
  DENIED = 'DENIED',
  CANCELED = 'CANCELED',
  DONE = 'DONE',
  WAITING_BUDGET = 'WAITING_BUDGET'
}

export enum AmbulatoryAuthorizationStatusPlural {
  HOSPITAL_ANALYSIS = 'Em análise no hospital',
  ANALYSIS_INSURANCE = 'Em análise no convênio',
  AUTHORIZED = 'Autorizados',
  DENIED = 'Negados',
  CANCELED = 'Cancelados',
  DONE = 'Realizados',
  WAITING_BUDGET = 'Aguardando orçamento'
}

export enum AmbulatoryAuthorizationStatusSingular {
  HOSPITAL_ANALYSIS = 'Em análise no hospital',
  ANALYSIS_INSURANCE = 'Em análise no convênio',
  DENIED = 'Negado',
  AUTHORIZED = 'Autorizado',
  CANCELED = 'Cancelado',
  DONE = 'Realizado',
  WAITING_BUDGET = 'Aguardando orçamento'
}
