import React, { useEffect, useState } from 'react'

import { useFormik, getIn } from 'formik'
import * as yup from 'yup'
import 'main/config/yup'
import TextField from 'presentation/shared/components/TextField'

import * as S from './styles'
import { ReactComponent as MmdLogo } from 'presentation/assets/icons/mmd-logo.svg'
import { ReactComponent as CheckCircle } from 'presentation/assets/icons/check-circle2.svg'
import AutoComplete from 'presentation/shared/components/AutoComplete'
import { useServices } from 'presentation/hooks/use-services'
import { Specialty } from 'domain/usecases/specialties/load-specialties'
import { toast } from 'react-toastify'
import SelectField from 'presentation/shared/components/SelectField'
import { blockInvalidChar } from 'common/utils/blockInvalidChar'
import SheetModal from 'presentation/shared/components/SheetModal'
import Modal from 'presentation/shared/components/Modal'
import LoadingModal from 'presentation/shared/components/LoadingModal'

export default function DoctorIndicationRegister() {
  const [specialties, setSpecialties] = useState<Specialty[]>([])
  const [, setSelectedHospitals] = useState<number[]>([])
  const [, setIsLoading] = useState<boolean>(false)
  const [isLoadingModal, setIsLoadingModal] = useState<boolean>(false)
  const [searchSpecialty, setSearchSpecialty] = useState('')

  const [handleSuccessModal, setHandleSuccessModal] = useState<boolean>(false)

  const {
    handleChange,
    values,
    setFieldValue,
    handleSubmit,
    errors,
    touched,
    resetForm
  } = useFormik({
    initialValues: {
      name: '',
      email: '',
      specialty: {} as Specialty,
      crm: '',
      crmUf: ''
    },
    validationSchema: validationSchema,
    validateOnMount: true,
    onSubmit: async () => {
      try {
        setIsLoadingModal(true)
        await handleSaveRecommendation(values)
        resetForm()
        setFieldValue('specialty', {})
        setSearchSpecialty('')
        setHandleSuccessModal(true)
      } catch (error: any) {
        toast.error('Ocorreu ao cadastrar')
      } finally {
        setIsLoadingModal(false)
      }
    }
  })

  const recommendationServices = useServices().recommendation
  const handleSaveRecommendation = async (
    values: AddRecommendationFormValues
  ) => {
    if (!values?.doctor_recommendation_id)
      return await recommendationServices.createRecommendationWithoutDoctor({
        crm: values.crm ?? '',
        crmUf: values.crmUf ?? '',
        name: values.name ?? '',
        email: values.email ?? '',
        units: values.units ?? '',
        specialty:
          {
            name: values.specialty.name,
            code: values.specialty.code
          } ?? {},
        nameRecommendedBy: '',
        emergency: values.emergency === 'emergency-yes',
        emergencyJustification: values.emergencyJustification,
        reasonEmergency: values.reasonEmergency ?? ''
      })
  }

  const specialtyServices = useServices().specialty

  const loadSpecialties = async (name: string) => {
    try {
      if (!name) {
        return
      }
      setIsLoading(true)
      const specialties = await specialtyServices.loadSpecialties({
        fields: ['name', 'code'],
        name,
        pagination: { pageNumber: 1, pageSize: 50 }
      })
      setSpecialties(specialties.data)
    } catch (err: any) {
      toast.error(err.message)
    } finally {
      setIsLoading(false)
    }
  }

  function selectSpecialty(specialty: Specialty) {
    if (specialty) {
      setFieldValue('specialty', specialty)
      setSearchSpecialty(specialty.name)
    } else {
      setFieldValue('specialty', undefined)
    }
  }

  const brasilStatesUF = [
    { label: 'Selecionar...', value: '' },
    {
      label: 'BA',
      value: 'BA'
    },
    {
      label: 'MG',
      value: 'MG'
    },
    {
      label: 'GO',
      value: 'GO'
    }
  ]

  const checkCrmErrors = () => {
    if (errors.crm && errors.crm === CRM_MAX_ERROR) {
      toast.error(CRM_MAX_ERROR)
    }
  }

  useEffect(() => {
    checkCrmErrors()
  }, [errors])

  return (
    <>
      <S.Header>
        <div style={{ padding: '18px' }}>
          <MmdLogo />
        </div>
      </S.Header>

      <S.TitleContainer style={{ marginBottom: '56px' }}>
        <S.Title>Quer fazer parte do corpo clínico?</S.Title>
        <S.Subtitle>Insira seus dados abaixo e inscreva-se.</S.Subtitle>
      </S.TitleContainer>

      <S.Wrapper role="form" onSubmit={handleSubmit}>
        <S.FieldContainer>
          <TextField
            className="large"
            label="Nome"
            required
            onInputChange={handleChange('name')}
            error={touched.name && errors.name}
            value={values.name}
            placeholder="Insira seu nome completo"
          />

          <TextField
            className="large"
            label="Email"
            required
            onInputChange={handleChange('email')}
            error={touched.email && errors.email}
            value={values.email}
            placeholder="Insira seu e-mail"
          />
        </S.FieldContainer>

        <S.FieldContainer style={{ marginBottom: '56px', gap: '32px' }}>
          <AutoComplete
            label="Especialidade"
            bgColor="white"
            suggestions={specialties.map((specialty) => ({
              label: `${specialty.name}`,
              value: specialty
            }))}
            className="large"
            name="specialty.name"
            placeholder="Selecione"
            value={searchSpecialty}
            error={
              getIn(touched, 'specialty') && getIn(errors, 'specialty.name')
            }
            onSuggestionClick={selectSpecialty}
            onType={(val) => loadSpecialties(val)}
            required
            onInputChange={(value) => {
              setSearchSpecialty(value)
              if (!value) {
                setFieldValue('specialty.name', '')
              }
            }}
          />
          <S.CrmUf>
            <TextField
              className="crm"
              label="CRM"
              required
              type="number"
              onKeyDown={blockInvalidChar}
              onInputChange={handleChange('crm')}
              error={touched.crm ? errors.crm : undefined}
              value={values.crm}
            />

            <SelectField
              className="uf"
              label="UF"
              name="crmUf"
              items={brasilStatesUF}
              onInputChange={(uf) => {
                setFieldValue('crmUf', uf)
                setSelectedHospitals([])
              }}
              value={values.crmUf}
              required
              error={touched.crmUf ? errors.crmUf : undefined}
            />
          </S.CrmUf>
        </S.FieldContainer>
        <S.SubmitButton type="submit" style={{ margin: 'auto' }}>
          Enviar
        </S.SubmitButton>
      </S.Wrapper>

      {window.innerWidth < 650 ? (
        <SheetModal
          style={{ height: '455px' }}
          size={1000}
          isOpen={handleSuccessModal}
          onClose={() => setHandleSuccessModal(false)}
        >
          <S.DraggerMobile></S.DraggerMobile>

          <S.TitleContainer>
            <CheckCircle />
          </S.TitleContainer>

          <S.TitleContainer>
            <S.ModalTitle>Inscrição enviada com sucesso!</S.ModalTitle>
            <S.ModalSubtitle>
              Enviamos um e-mail com os próximos passos.
            </S.ModalSubtitle>
          </S.TitleContainer>

          <S.TitleContainer style={{ marginBottom: '40px' }}>
            <S.ModalSubtitle>Em caso de dúvidas ligue para</S.ModalSubtitle>
            <S.GreenTitle>(31) 3401-7266</S.GreenTitle>
          </S.TitleContainer>

          <S.SubmitButton
            onClick={() => setHandleSuccessModal(false)}
            style={{ margin: 'auto' }}
            type="button"
          >
            Ok!
          </S.SubmitButton>
        </SheetModal>
      ) : (
        <Modal
          style={{ width: '525px', height: '376px' }}
          show={handleSuccessModal}
        >
          <S.TitleContainer>
            <CheckCircle />
          </S.TitleContainer>

          <S.TitleContainer>
            <S.ModalTitle>Inscrição enviada com sucesso!</S.ModalTitle>
            <S.ModalSubtitle>
              Enviamos um e-mail com os próximos passos.
            </S.ModalSubtitle>
          </S.TitleContainer>

          <S.TitleContainer style={{ marginBottom: '40px' }}>
            <S.ModalSubtitle>Em caso de dúvidas ligue para</S.ModalSubtitle>
            <S.GreenTitle>(31) 3401-7266</S.GreenTitle>
          </S.TitleContainer>

          <S.SubmitButton
            onClick={() => setHandleSuccessModal(false)}
            style={{ margin: 'auto' }}
            type="button"
          >
            Ok!
          </S.SubmitButton>
        </Modal>
      )}
      <LoadingModal show={isLoadingModal} />
    </>
  )
}

type AddRecommendationFormValues = {
  name?: string
  email?: string
  specialty: Specialty
  crm?: string
  crmUf?: string
  units?: string
  doctor_id?: number
  emergency?: string
  reasonEmergency?: string
  emergencyJustification?: string
  doctor_recommendation_id?: number
  created_user_id?: number
}

const CRM_MAX_ERROR =
  'O Registro no Conselho não pode ter mais de 10 caracteres.'

const validationSchema = yup.object().shape({
  name: yup.string().required(),
  email: yup.string().email().required(),
  specialty: yup.object().shape({
    name: yup.string().required(),
    code: yup.number().required()
  }),
  crm: yup
    .string()
    .required()
    .min(5, 'O CRM deve possuir no mínimo 5 caracteres')
    .max(10, CRM_MAX_ERROR),
  crmUf: yup.string().required()
})
