import { LoadAnesthesiologies } from 'domain/usecases/surgical-order/load-anesthesiologies'
import { SurgicalOrderRepository } from 'repository/interfaces/surgical-order-repository'

export class RemoteLoadAnesthesiologies {
  constructor(
    private readonly surgicalOrderRepository: SurgicalOrderRepository
  ) {
    this.surgicalOrderRepository = surgicalOrderRepository
  }

  load(): Promise<LoadAnesthesiologies.Model> {
    return this.surgicalOrderRepository.loadAnesthesiologies()
  }
}
