// libs
import { useMediaQuery, useTheme } from '@material-ui/core'

/**
 * Returns the matches for the media queries
 *
 * @returns sm: 600px
 * @returns md: 900px
 * @returns lg: 1200px
 * @returns xl: 1536px
 */
export function useMedia() {
  const theme = useTheme()

  return {
    sm: useMediaQuery(theme.breakpoints.up('sm')),
    md: useMediaQuery(theme.breakpoints.up('md')),
    lg: useMediaQuery(theme.breakpoints.up('lg')),
    xl: useMediaQuery(theme.breakpoints.up('xl'))
  }
}
