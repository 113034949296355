import React from 'react'
import * as S from './styles'
import { SurgeryDetailsLayoutProps } from 'presentation/shared/layouts/SurgeryDetails'
import { ReactComponent as PersonIcon } from 'presentation/assets/icons/person-outline.svg'
import { ReactComponent as ClipboardIcon } from 'presentation/assets/icons/clipboard.svg'
import Heading from '../Heading'
import moment from 'moment-timezone'

enum OpmeStatus {
  OPME_AUTORIZADO = 'OPME autorizado',
  OPME_AUTORIZADO_COM_DIVERGENCIA = 'OPME autorizado com divergencia',
  OPME_NEGADO = 'OPME negado',
  PEDIDO_SEM_OPME = 'Pedido sem OPME'
}

export default function OrderDetails({
  statusList,
  passwordToFinish,
  passwordMaxDateValidation,
  passwordMaxStringValidation,
  procedureDetails,
  // procedures,
  observation,
  opmeDetails,
  // opme,
  guidesTypeProcedures,
  guidesTypeOpme,
  concludedAt
}: SurgeryDetailsLayoutProps) {
  const isOrderAuthorized = statusList?.some(
    (item) => item.status === 'AUTHORIZED'
  )

  return (
    <>
      {isOrderAuthorized && (
        <S.Wrapper>
          {passwordToFinish && (
            <PasswordCard
              passwordToFinish={passwordToFinish}
              passwordMaxDateValidation={passwordMaxDateValidation}
              passwordMaxStringValidation={passwordMaxStringValidation}
              concludedAt={concludedAt}
            />
          )}
          {guidesTypeProcedures?.length > 0 && (
            <ProceduresCard
              procedures={guidesTypeProcedures}
              procedureDetails={procedureDetails}
            />
          )}
          {((guidesTypeOpme != undefined && opmeDetails) ||
            (guidesTypeOpme != undefined &&
              opmeDetails?.split(',')[0] !== OpmeStatus.PEDIDO_SEM_OPME)) && (
            <OpmeCard opme={guidesTypeOpme} opmeDetails={opmeDetails} />
          )}

          {observation && <GeneralObservation observation={observation} />}
        </S.Wrapper>
      )}
      {isOrderAuthorized === false && (
        <S.Wrapper>
          <Heading>Pedido não autorizado</Heading>
        </S.Wrapper>
      )}
    </>
  )
}

const PasswordCard = ({
  passwordToFinish,
  passwordMaxDateValidation,
  passwordMaxStringValidation
}: any) => {
  return (
    <S.CardWrapper>
      <h3>
        <PersonIcon />
        &nbsp;Senha
      </h3>
      <p>
        Senha: <strong className="strong-primary">{passwordToFinish}</strong>
      </p>
      <p className="password-validity">
        {passwordMaxDateValidation ? (
          <>
            Validade da senha:{' '}
            <strong className="strong-primary">
              {moment(passwordMaxDateValidation).format('DD/MM/YYYY')}
            </strong>
          </>
        ) : null}
      </p>
      {passwordMaxStringValidation ? (
        <p>{passwordMaxStringValidation}</p>
      ) : null}
    </S.CardWrapper>
  )
}

const ProceduresCard = ({ procedures, procedureDetails }: any) => {
  return (
    <S.CardWrapper>
      <h3 className="procedure-title">
        <ClipboardIcon />
        &nbsp;Procedimentos
      </h3>
      {procedures?.map((procedure: any) => {
        return procedure.details?.map((detail: any, index: any) => {
          return (
            <ProcedureOrOpmeDetails
              detail={detail}
              isOpme={false}
              key={index}
            />
          )
        })
      })}

      {procedureDetails && (
        <ObservationCard
          observation={procedureDetails}
          title={'Observação sobre procedimentos'}
        />
      )}
    </S.CardWrapper>
  )
}

const ObservationCard = ({ observation, title }: any) => {
  return (
    <S.GreenCard>
      <h3 className="procedure-observation-title">{title}</h3>
      <p>{observation}</p>
    </S.GreenCard>
  )
}

const OpmeCard = ({ opme, opmeDetails }: any) => {
  if (opme.length > 0) {
    return (
      <>
        <S.CardWrapper>
          <h3 className="procedure-title">
            <ClipboardIcon />
            &nbsp;OPME
          </h3>
          {opmeDetails?.split(',')[0] ? (
            <p>
              <strong>OPME autorizado: </strong>
              {`${
                opmeDetails?.split(',')[0] === OpmeStatus.PEDIDO_SEM_OPME
                  ? ''
                  : opmeDetails?.split(',')[0] === OpmeStatus.OPME_NEGADO
                  ? 'Não, '
                  : 'Sim, '
              }${opmeDetails?.split(',')[0]}`}
            </p>
          ) : null}

          {opme?.map((opmes: any) => {
            return opmes.details?.map((detail: any, index: any) => {
              return (
                <ProcedureOrOpmeDetails
                  key={index}
                  detail={detail}
                  isOpme={true}
                />
              )
            })
          })}

          {opmeDetails?.split(',')[1] && (
            <ObservationCard
              observation={opmeDetails?.split(',')[1]}
              title={'Observação sobre OPME'}
            />
          )}
        </S.CardWrapper>
      </>
    )
  }
  return <></>
}

const GeneralObservation = ({ observation }: any) => {
  return (
    <S.CardWrapper>
      <h3>Observações Gerais</h3>
      <p> {observation} </p>
    </S.CardWrapper>
  )
}

const ProcedureOrOpmeDetails = ({ detail, isOpme }: any) => {
  return (
    <S.NewCard>
      <p
        className={`card-description ${isOpme ? 'card-description-opme' : ''}`}
      >
        {detail.procedure}
      </p>
      <S.Divider />
      <div className="card-quantities">
        <p>Quant. Solicitada: {detail.quantity_solicitation ?? 0}</p>
        <p>Quant. Autorizada: {detail.quantity_authorization ?? 0}</p>
      </div>
      <S.Divider />
      {isOpme ? (
        <p>Fornecedor: {detail.provider}</p>
      ) : (
        <p>Código TUSS: {detail.fat_id}</p>
      )}
    </S.NewCard>
  )
}
