import { Hospital } from 'domain/entities/hospital-model'
import { LoadHospitalsByDoctorCpf } from 'domain/usecases/hospital/load-hospitals-by-doctor-cpf'
import { HospitalRepository } from 'repository/interfaces/hospital-repository'
export class RemoteLoadHospitalsByDoctorCpf
  implements LoadHospitalsByDoctorCpf
{
  constructor(private readonly hospitalRepository: HospitalRepository) {}

  async load(
    cpf: string,
    params?: LoadHospitalsByDoctorCpf.Params
  ): Promise<LoadHospitalsByDoctorCpf.Model> {
    const hospitalList = await this.hospitalRepository.loadHospitalsByDoctorCpf(
      cpf,
      params
    )
    return hospitalList.map((hospital) => {
      return new Hospital(
        hospital.hospital_id,
        hospital.nickname,
        hospital.name,
        hospital?.friendly_name,
        hospital?.uf,
        hospital?.specialityHospital,
        hospital?.hasPartnerCode
      )
    })
  }
}

export type LoadHospitalsModel = LoadHospitalsByDoctorCpf.Model
