import React, { HTMLAttributes } from 'react'
import theme from '../../../styles/theme'
import * as S from './styles'

export type SupportTextProps = {
  color?: keyof typeof theme.ds.colors
  weight?: keyof typeof theme.ds.typography.weight
  size?: keyof typeof theme.ds.typography.size
  textAligh?: 'center' | 'left' | 'right' | 'justify'
  capitalize?: boolean
  as?: 'p' | 'span' | 'div' | 'strong' | 'i' | 'b' | 'q'
  uppercase?: boolean
} & HTMLAttributes<HTMLParagraphElement>

const SupportTextNew = ({
  color = 'neutral500',
  weight = 'normal',
  size = 'small',
  textAligh = 'left',
  capitalize = false,
  children,
  as = 'p',
  uppercase = false,
  ...props
}: SupportTextProps) => (
  <S.Wrapper
    color={color}
    weight={weight}
    size={size}
    capitalize={capitalize}
    textAligh={textAligh}
    as={as}
    uppercase={uppercase}
    {...props}
  >
    {children}
  </S.Wrapper>
)

export default SupportTextNew
