import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { isAndroid, isIOS } from 'react-device-detect'
import { useStores } from 'presentation/hooks/use-stores'
import BottomMenu from 'presentation/shared/components/BottomMenu'
import { ContainerNew } from 'presentation/shared/components/ContainerNew'
import HeaderNew from 'presentation/shared/components/HeaderNew'
import HeadingNew from 'presentation/shared/components/HeadingNew'
import ShortCutCard from 'presentation/doctor/components/ShortCutCard'
import ActionCardNew from 'presentation/shared/components/ActionCardNew'
import { ReactComponent as MedicalIllustration } from 'presentation/assets/illustrations/medical.svg'
import { ReactComponent as ClipboardIllustration } from 'presentation/assets/illustrations/clipboard.svg'
import { ReactComponent as BusinessWomanIllustration } from 'presentation/assets/illustrations/businesswoman.svg'
import { ReactComponent as DownArrowIcon } from 'presentation/assets/icons/down-arrow.svg'
import { ReactComponent as RightArrowIcon } from 'presentation/assets/icons/v-arrow.svg'
import { ReactComponent as SeiwaLogo } from 'presentation/assets/icons/seiwa-logo.svg'
import { ReactComponent as SchedulingSurgicalIllustration } from 'presentation/assets/illustrations/scheduling-surgical.svg'
import * as S from './styles'
import { DoctorScheduleCalendarNew } from 'presentation/doctor/components/DoctorScheduleCalendarNew'
// import { useScrollContainer } from 'react-indiana-drag-scroll'
import { DoctorCard } from '../SecretaryDoctors'
import { User } from 'domain/entities/user-model'
import { WithLoading } from 'presentation/shared/components/HOCs/WithLoading'
import { toast } from 'react-toastify'
import { Profile } from 'common/enum/profile'
import { LocalStorageAdapter } from 'infra/storage-adapter/storage-adapter'
import InfoCard from 'presentation/shared/components/InfoCard'
import { useServices } from 'presentation/hooks/use-services'
import BannerMyProductivity from 'presentation/doctor/components/BannerMyProductivity'
import moment from 'moment-timezone'
import Carousel, {
  CarouselState
} from 'presentation/shared/components/Carousel'
import SupportTextNew from 'presentation/shared/components/SupportTextNew'
import ButtonNew from 'presentation/shared/components/ButtonNew'

type Props = {
  pendenciesNumber: number
  showSurgicalOrderCancellationNotification: boolean
}

const DoctorHomeLayout = ({
  pendenciesNumber,
  showSurgicalOrderCancellationNotification
}: Props) => {
  // const scrollContainer = useScrollContainer()
  const [carousel, setCarousel] = useState({} as CarouselState)
  const doctor = useStores().currentAccount?.getCurrentAccount()?.user
  const secretary =
    useStores().currentAccount?.getCurrentAccount()?.user.secretary_doctor
  const history = useHistory()
  const surgicalOrderService = useServices().surgicalOrder

  const formattedDoctorName = doctor?.name
    ?.toLocaleLowerCase()
    .split(' ')
    .slice(0, 2)
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ')

  const formattedSecretaryName = secretary?.name
    ?.toLocaleLowerCase()
    .split(' ')
    .slice(0, 2)
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ')

  const localStorageAdapter = new LocalStorageAdapter()
  const secretaryDoctors = localStorageAdapter.get(
    'secretary_doctors'
  ) as User[]

  const [doctorsSecretaryDrawer, setDoctorsSecretaryDrawer] = useState(false)

  function toggleDrawer(open: boolean) {
    if (secretaryDoctors?.length > 0) {
      setDoctorsSecretaryDrawer(open)
    }
    return
  }

  const handleDownloadSeiwaApp = () => {
    const ANDROID_LINK =
      'https://play.google.com/store/apps/details?id=com.seiwa'
    const IOS_LINK = 'https://apps.apple.com/br/app/seiwa/id1553323682'
    const WEB_LINK = 'https://seiwa.app/'

    const getRedirectLink = () => {
      if (isAndroid) return ANDROID_LINK
      if (isIOS) return IOS_LINK
      return WEB_LINK
    }

    window.open(getRedirectLink(), '_blank')
  }

  const StayTunedCarouselChildren: JSX.Element[] = [
    <S.StayTunedItem key={1}>
      <S.CardSeiwa>
        <div className="c-header">
          <HeadingNew
            as="h5"
            color="white"
            size="large"
            style={{ lineHeight: '1.2' }}
          >
            Conheça a Seiwa
          </HeadingNew>
          <SeiwaLogo style={{ marginTop: '16px' }} />
        </div>
        <div className="c-content">
          <div className="box-text">
            <SupportTextNew color="neutral700" size="xxsmall" weight="semiBold">
              Gestão de recebimentos
            </SupportTextNew>
            <SupportTextNew color="neutral700" size="xxsmall" weight="semiBold">
              Pagamentos glosados
            </SupportTextNew>
            <SupportTextNew color="neutral700" size="xxsmall" weight="semiBold">
              Histórico de pagamentos
            </SupportTextNew>
          </div>
          <ButtonNew type="button" onClick={handleDownloadSeiwaApp}>
            Baixar app <RightArrowIcon style={{ marginLeft: '8px' }} />
          </ButtonNew>
        </div>
      </S.CardSeiwa>
    </S.StayTunedItem>
    // <S.StayTunedItem key={2}>
    //   <div>
    //     <h1>Carousel 2</h1>
    //   </div>
    // </S.StayTunedItem>
  ]

  return (
    <>
      <HeaderNew actualPageTitle="Home" showMenu />
      <ContainerNew noHeader gap="24px">
        {secretary ? (
          <>
            <HeadingNew
              style={{ marginBottom: '15px' }}
              color="neutral500"
              weight="semiBold"
              size="small"
            >
              Olá, {formattedSecretaryName}
            </HeadingNew>

            <HeadingNew color="neutral400" weight="semiBold" size="xsmall">
              Você está logada no perfil de
            </HeadingNew>

            <HeadingNew
              style={{ textDecoration: 'underline', cursor: 'pointer' }}
              color="primary600"
              weight="semiBold"
              onClick={() => toggleDrawer(true)}
              size="small"
            >
              {formattedDoctorName}
              <DownArrowIcon style={{ marginLeft: '5px' }} />
            </HeadingNew>

            <DoctorsSecretaryDrawer
              secretaryDoctors={secretaryDoctors}
              setDoctorsSecretaryDrawer={setDoctorsSecretaryDrawer}
              doctorsSecretaryDrawer={doctorsSecretaryDrawer}
            />
          </>
        ) : (
          <HeadingNew color="neutral500" weight="semiBold" size="small">
            Olá, Dr(a) {formattedDoctorName}
          </HeadingNew>
        )}

        <BannerMyProductivity date={moment().subtract(1, 'month').toDate()} />

        {pendenciesNumber > 0 && (
          <ActionCardNew
            text={pendenciesNumber}
            title="Pendências a resolver"
            color="terciary400"
            shadow="satin"
            onClick={() => history.push('/pedidos-cirurgicos/pendencias')}
            weight="bold"
          />
        )}

        <div>
          <HeadingNew
            as="h3"
            className="s-title"
            color="neutral800"
            weight="bold"
            style={{ marginBottom: '8px' }}
          >
            Meus atalhos
          </HeadingNew>

          <S.ShortCutsWrapper>
            <ShortCutCard
              titleSmaller="Pedidos de autorização"
              title="Ambulatorial"
              titleColor="info600"
              bgColor="info50"
              onClick={() =>
                history.push('/pedidos-autorizacao-ambulatorial/dashboard')
              }
              image={<ClipboardIllustration />}
            />

            <ShortCutCard
              titleSmaller="Finalização de"
              title="Pedidos Cirúrgicos"
              onClick={() => history.push('/pedidos-cirurgicos/dashboard')}
              image={<MedicalIllustration />}
            />
            <ShortCutCard
              titleSmaller="Agendamento"
              title="Cirúrgico"
              titleColor="secondary600"
              bgColor="secondary50"
              onClick={() => history.push('/pedidos-cirurgicos/agendamento')}
              image={<SchedulingSurgicalIllustration />}
            />
            {!secretary && (
              <ShortCutCard
                titleSmaller="Gestão de"
                title="Secretárias"
                titleColor="terciary600"
                bgColor="terciary50"
                onClick={() => history.push('/secretaria')}
                image={<BusinessWomanIllustration />}
              />
            )}
          </S.ShortCutsWrapper>
        </div>

        {StayTunedCarouselChildren.length > 0 && (
          <div>
            <HeadingNew
              as="h3"
              className="s-title"
              color="neutral800"
              weight="bold"
              style={{ marginBottom: '8px' }}
            >
              Fique atento
            </HeadingNew>

            <S.CarouselWrapper>
              <Carousel
                state={carousel}
                setState={setCarousel}
                slidesPerView={1}
                spaceBetween={24}
                pagination={{ type: 'bullets' }}
                bulletBottomPosition={0}
                // dynamicHeight={true}
                // effect="fade"
                // fade={{ crossFade: true }}
              >
                {[...StayTunedCarouselChildren]}
              </Carousel>
            </S.CarouselWrapper>
          </div>
        )}

        <div>
          <HeadingNew
            as="h3"
            className="s-title"
            color="neutral800"
            weight="bold"
            style={{ marginBottom: '8px' }}
          >
            Meus compromissos
          </HeadingNew>

          <DoctorScheduleCalendarNew />
        </div>
      </ContainerNew>
      {showSurgicalOrderCancellationNotification && (
        <InfoCard
          mainText="Um ou mais pacientes solicitaram o cancelamento do pedido cirúrgico."
          linkText="Ver solicitações de cancelamento"
          onLinkClick={async () => {
            await surgicalOrderService.confirmReadCancellationRequests()
            history.push('/pedidos-cirurgicos/pedidos', {
              orderStatus: 'REQUESTED_CANCELLATION'
            })
          }}
        />
      )}
      <BottomMenu />
    </>
  )
}

export default DoctorHomeLayout

type DrawerProps = {
  secretaryDoctors: User[]
  setIsLoading: (state: boolean) => void
  setDoctorsSecretaryDrawer: (state: boolean) => void
  doctorsSecretaryDrawer: boolean
}

const DoctorsSecretaryDrawer = WithLoading(
  ({
    secretaryDoctors,
    setIsLoading,
    doctorsSecretaryDrawer,
    setDoctorsSecretaryDrawer
  }: DrawerProps) => {
    const history = useHistory()
    const currentAccount = useStores().currentAccount

    const handleChangeSecretaryDoctor = (user: User) => {
      setIsLoading(true)

      currentAccount
        .setCurrentDoctorSecretary(user.user_id)
        .then(() => {
          if (user.role === Profile.SECRETARY) {
            history.push('/selecionar-perfil')
          } else {
            toast.success('Perfil selecionado com sucesso')
            setIsLoading(true)
            setTimeout(() => {
              window.location.reload()
            }, 500)
            setIsLoading(false)
            history.push('/home')
          }
        })
        .catch((e: any) => {
          toast.error(e.message)
        })
        .finally(() => {
          setIsLoading(false)
        })
    }

    return (
      <>
        <S.DrawerWrapper
          className="drawer"
          anchor={'top'}
          open={doctorsSecretaryDrawer}
          onClose={() => setDoctorsSecretaryDrawer(false)}
        >
          <span>Selecione em qual perfil deseja entrar:</span>
          {secretaryDoctors?.map((doctor, index) => {
            return (
              <DoctorCard
                key={index}
                doctor={doctor}
                user={currentAccount.getCurrentAccount().user}
                index={index}
                handleChangeSecretaryDoctor={handleChangeSecretaryDoctor}
              />
            )
          })}
        </S.DrawerWrapper>
      </>
    )
  }
)
