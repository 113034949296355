import styled, { css } from 'styled-components'

import { ButtonProps } from '.'

export type WrapperProps = Pick<ButtonProps, 'label'>

const wrapperModifiers = {
  disabled: () => css`
    &:disabled {
      cursor: not-allowed;
    }
  `
}

export const Button = styled.button<ButtonProps>`
  ${({ disabled }) => css`
    width: min(320px, 100%);
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 54px;
    padding: 12px 24px;
    border-radius: 10px;
    border: ${({ theme }) => '2px solid' + theme.colors.primary};
    background-color: transparent;
    cursor: pointer;
    color: ${({ theme }) => theme.colors.primary};
    font-weight: 600;
    font-size: ${({ theme }) => theme.font.sizes.medium};

    :hover {
      background-color: ${({ theme }) => theme.colors.primary};
      color: ${({ theme }) => theme.colors.white};
    }
    ${disabled && wrapperModifiers.disabled()};
  `}
`
