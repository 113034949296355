import React, { HTMLAttributes } from 'react'
import * as S from './styles'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos'
import 'react-calendar/dist/Calendar.css'

import CalendarComponent, { CalendarProps } from 'react-calendar'
import moment from 'moment'

type Props = {
  range?: boolean
  hasMonth?: boolean
  showDays?: boolean
  onDateChange?: (cb: Date | Date[]) => void
} & CalendarProps &
  HTMLAttributes<HTMLDivElement>

const Calendar = ({ range = false, onDateChange, ...props }: Props) => {
  const weekDay = ['dom', 'seg', 'ter', 'qua', 'qui', 'sex', 'sáb']

  const toCamelCase = (date: string) => {
    return date.substr(0, 1).toUpperCase() + date.substr(1)
  }

  const change = (val: Date | Date[]) => {
    onDateChange && onDateChange(val)
  }

  const getnavigationLabel = ({ date, label, view }: any) => {
    if (view === 'month') {
      return `${label} ${moment(date, 'YYYY-MM-DD').year()}`
    }
    return label
  }

  return (
    <S.Wrapper {...props}>
      <CalendarComponent
        showNeighboringMonth={false}
        calendarType="ISO 8601"
        formatShortWeekday={(_, date) => weekDay[date.getDay()]}
        formatMonthYear={(_, date) => {
          return toCamelCase(date.toLocaleString('pt-BR', { month: 'long' }))
        }}
        formatMonth={(_, date) => {
          return toCamelCase(date.toLocaleString('pt-BR', { month: 'long' }))
        }}
        next2Label={null}
        prev2Label={null}
        nextLabel={<ArrowForwardIosIcon />}
        prevLabel={<ArrowBackIosIcon />}
        navigationLabel={getnavigationLabel}
        selectRange={range}
        onChange={(val: Date | Date[]) => change(val)}
        {...props}
      />
    </S.Wrapper>
  )
}

export default Calendar
