import theme from 'presentation/styles/theme'
import styled, { css } from 'styled-components'

export type CircleProps = {
  color: keyof typeof theme.ds.colors
  progress: number
  verticalPosition?: 'up' | 'down' | string
  userFocus?: boolean
  quadrant?: 'first' | 'second' | 'third'
}

export const TitleRow = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;

  @media (min-width: 500px) {
    grid-template-columns: 1fr 1fr 1fr 1fr 0.75fr;
  }
`

export const IndicatorRow = styled.div`
  display: grid;
  grid-template-columns: 1fr 3fr;
  gap: 2px;
  margin-bottom: 2px;

  @media (min-width: 500px) {
    grid-template-columns: 1fr 3fr 0.75fr;
  }
`

export const QuadrantsRow = styled.div`
  display: flex;
  gap: 3px;
  justify-content: space-between;
  height: 44px;
  position: relative;

  &::after {
    content: '';
    position: absolute;
    left: 5%;
    top: 45%;
    height: 4px;
    width: 90%;
    background-color: ${({ theme }) => theme.ds.colors.neutral200};
    border-radius: 16px;
  }
`

export const Quadrant = styled.div`
  position: relative;
  display: flex;
  background-color: ${({ theme }) => theme.ds.colors.neutral100};
  width: 100%;
  min-width: 66.33px;
  height: 44px;
  border-radius: 8px;
`

export const QuadrantCircle = styled.div<CircleProps>`
  ${({ color, progress, verticalPosition, userFocus, quadrant }) => css`
    position: absolute;
    top: ${verticalPosition === 'up'
      ? '25%'
      : verticalPosition === 'down'
      ? '75%'
      : '50%'};
    left: ${quadrant === 'first' && progress <= 30
      ? `${progress + 15}%`
      : quadrant === 'third' && progress >= 70
      ? `${progress - 15}%`
      : `${progress}%`};
    transform: translate(-50%, -50%);
    width: 20px;
    height: 20px;
    background-color: ${theme.ds.colors[color]};
    border-radius: 50%;
    z-index: 2;
    box-shadow: ${userFocus ? '0 0 0 8px rgba(130, 206, 201, 0.35)' : 'none'};
  `}
`

export const Legend = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  margin-top: 8px;
  justify-content: space-between;

  @media (min-width: 500px) {
    justify-content: center;
    gap: 7%;
  }
`

export const LegendCircle = styled.div<{ color: keyof typeof theme.ds.colors }>`
  ${({ color }) => `
    width: 10px;
    height: 10px;
    background-color: ${theme.ds.colors[color]};
    border-radius: 50%;
  `}
`
