export enum Profile {
  PATIENT = 'patient',
  DOCTOR = 'doctor',
  HOSPITAL = 'admin',
  SECRETARY = 'secretary',
  CRMO = 'crmo',
  HOSPITALIZATION = 'hospitalization',
  AMBULATORY = 'ambulatory',
  ONCOLOGY = 'oncology',
  DOCTOR_REGISTER = 'doctor_register',
  DIRECTOR_DOCTOR_REGISTER = 'director_doctor_register',
  ADMIN_SECTORIAL_CRMO = 'admin_sectorial_crmo',
  EMERGENCY_ROOM = 'emergency_room'
}

export enum ProfileLocated {
  PATIENT = 'Paciente',
  DOCTOR = 'Médico(a)',
  ADMIN = 'Admin',
  HOSPITAL = 'Admin',
  SECRETARY = 'Secretária',
  CRMO = 'CRMO',
  HOSPITALIZATION = 'Hospitalização',
  AMBULATORY = 'Ambulatório',
  ONCOLOGY = 'Oncologia',
  DOCTOR_REGISTER = 'Cadastro médico',
  DIRECTOR_DOCTOR_REGISTER = 'Diretor médico',
  ADMIN_SECTORIAL_CRMO = 'Admin Setorial CRMO'
}
