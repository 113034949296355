import { GetHospitals } from 'domain/usecases/surgical-scheduling/get-hospitals'
import { GetSurgeryCenter } from 'domain/usecases/surgical-scheduling/get-surgery-center'
import { SearchEquipaments } from 'domain/usecases/surgical-scheduling/search-equipaments'
import { SearchAnesthetists } from 'domain/usecases/surgical-scheduling/search-anesthetists'
import { GetPredictedTime } from 'domain/usecases/surgical-scheduling/get-predicted-time'
import { GetSurgicalSchedulingDetails } from 'domain/usecases/surgical-scheduling/get-surgical-scheduling-details'
import { GetAvailableDays } from 'domain/usecases/surgical-scheduling/get-available-days'
import { GetAvailableHours } from 'domain/usecases/surgical-scheduling/get-available-hours'
import { SendSurgicalScheduling } from 'domain/usecases/surgical-scheduling/send-surgical-scheduling'

import { makeAutoObservable } from 'mobx'
import { CheckAvailableSchedulingDate } from 'domain/usecases/surgical-scheduling/check-available-scheduling-date'

type Services = {
  getHospitals: GetHospitals
  getSurgeryCenter: GetSurgeryCenter
  searchEquipaments: SearchEquipaments
  searchAnesthetists: SearchAnesthetists
  getPredictedTime: GetPredictedTime
  getAvailableHours: GetAvailableHours
  getAvailableDays: GetAvailableDays
  sendSurgicalScheduling: SendSurgicalScheduling
  getSurgicalSchedulingDetails: GetSurgicalSchedulingDetails
  checkAvailableSchedulingDate: CheckAvailableSchedulingDate
}

export class SurgicalSchedulingService {
  constructor(private readonly services: Services) {
    makeAutoObservable(this)
  }

  async getHospitals(): Promise<GetHospitals.Model> {
    return this.services.getHospitals.getHospitals()
  }

  async getSurgeryCenter(
    params: GetSurgeryCenter.Params
  ): Promise<GetSurgeryCenter.Model> {
    return this.services.getSurgeryCenter.getSurgeryCenterByHospitalCode(params)
  }

  async searchEquipaments(
    params: SearchEquipaments.Params
  ): Promise<SearchEquipaments.Model> {
    return this.services.searchEquipaments.search(params)
  }

  async searchAnesthetists(
    params: SearchAnesthetists.Params
  ): Promise<SearchAnesthetists.Model> {
    return this.services.searchAnesthetists.search(params)
  }

  async getPredictedTime(
    params: GetPredictedTime.Params
  ): Promise<GetPredictedTime.Model> {
    return this.services.getPredictedTime.getPredictedTime(params)
  }

  async getAvailableDays(
    params: GetAvailableDays.Params
  ): Promise<GetAvailableDays.Model> {
    return this.services.getAvailableDays.getAvailableDays(params)
  }

  async getAvailableHours(
    params: GetAvailableHours.Params
  ): Promise<GetAvailableHours.Model> {
    return this.services.getAvailableHours.getAvailableHours(params)
  }

  async sendSurgicalScheduling(
    params: SendSurgicalScheduling.Params
  ): Promise<SendSurgicalScheduling.Model> {
    return this.services.sendSurgicalScheduling.sendSurgicalScheduling(params)
  }

  async getSurgicalSchedulingDetails(
    params: GetSurgicalSchedulingDetails.Params
  ): Promise<GetSurgicalSchedulingDetails.Model> {
    return this.services.getSurgicalSchedulingDetails.getSurgicalSchedulingDetails(
      params
    )
  }

  async checkAvailableSchedulingDate(
    params: CheckAvailableSchedulingDate.Params
  ): Promise<CheckAvailableSchedulingDate.Model> {
    return this.services.checkAvailableSchedulingDate.check(params)
  }
}

export default SurgicalSchedulingService
