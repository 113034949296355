import { changeSelectedUserDoctorMutation } from 'repository/graphql/mutations'
import { IDoctorSecretaryRepository } from 'repository/interfaces/doctor-secretary-repository'
import { makeGraphQLVariable } from 'repository/graphql/utils/make-variables'
import { IApiRepository } from 'service/protocols/api/api-repository'
import { ApiStatusCode } from 'service/protocols/api/api-client'
import handleGraphQLError from 'repository/graphql/utils/handle-error'
import { RepositoryErrors } from 'repository/errors/repository-errors'
import { ChangeDoctorSecretary } from 'domain/usecases/doctor-secretary/change-doctor-secretary'

export class DoctorSecretaryRepository implements IDoctorSecretaryRepository {
  constructor(private readonly apiRepository: IApiRepository) {}

  async changeDoctorSecretary(
    params: ChangeDoctorSecretary.Params
  ): Promise<ChangeDoctorSecretary.Model> {
    const apiRepository = this
      .apiRepository as IApiRepository<ChangeDoctorSecretary.Model>

    const query = changeSelectedUserDoctorMutation

    const httpResponse = await apiRepository.post({
      url: '/graphql',
      body: {
        query: query.query,
        variables: makeGraphQLVariable(params.user_id, 'user_id')
      },
      query: query.name
    })

    if (
      httpResponse.statusCode &&
      httpResponse.statusCode !== ApiStatusCode.ok
    ) {
      throw handleGraphQLError(RepositoryErrors[httpResponse.error!])
    } else {
      return httpResponse.body as ChangeDoctorSecretary.Model
    }
  }
}
