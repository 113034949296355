import React, { HTMLAttributes } from 'react'
import theme from '../../../styles/theme'
import * as S from './styles'

export type SupportTextProps = {
  color?:
    | 'primary'
    | 'gray'
    | 'lightGray'
    | 'red'
    | 'primaryDarker'
    | keyof typeof theme.colors
  weight?: keyof typeof theme.ds.typography.weight
} & HTMLAttributes<HTMLParagraphElement>

const SupportText = ({
  color = 'gray',
  weight = 'normal',
  children,
  ...props
}: SupportTextProps) => (
  <S.Wrapper color={color} weight={weight} {...props}>
    {children}
  </S.Wrapper>
)

export default SupportText
