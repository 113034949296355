import styled from 'styled-components'
import SupportTextNew from 'presentation/shared/components/SupportTextNew'

export const Banner = styled.img`
  margin-bottom: 42px;
  width: calc(100% - 80px);
  max-width: 400px;
`

export const Texts = styled(SupportTextNew)`
  text-align: center;
  margin: 32px 0;
  max-width: 600px;
`
