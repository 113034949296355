import React, { useEffect, useState } from 'react'
import UpdateDoctorPersonalInfoLayout from 'presentation/doctor/layouts/UpdatePersonalInfo'
import { LoadDoctor } from 'domain/usecases/doctor/load-doctor'
import { Doctor } from 'domain/entities/doctor-model'
import { useStores } from 'presentation/hooks/use-stores'
import Modal from 'presentation/shared/components/Modal'
import UpdateSecretaryInfoLayout from 'presentation/doctor/layouts/UpdateSecretaryInfo'
import { useServices } from 'presentation/hooks/use-services'

type Props = {
  loadDoctor: LoadDoctor
}
export default function UpdateDoctorPersonalInfo({ loadDoctor }: Props) {
  const [data, setData] = useState<Doctor>({} as Doctor)
  const [openSuccessModal, setOpenSuccessModal] = useState<boolean>(false)
  const [updatePasswordSuccess, setUpdatePasswordSuccess] =
    useState<boolean>(false)

  const secretary =
    useStores().currentAccount?.getCurrentAccount()?.user.secretary_doctor
  const secretaryService = useServices().secretary
  const [secretaryInfo, setSecretaryInfo] = useState() as any
  const loadSecretary = async () => {
    if (secretary) {
      const secretaryRegistered = await secretaryService.getSecretaryByCpf({
        cpf: secretary.cpf.replace(/\D+/g, ''),
        fields: ['name, email, phone, landlinePhone, cpf, user_id']
      })
      setSecretaryInfo(secretaryRegistered)
    }
  }

  useEffect(() => {
    loadSecretary()
  }, [])

  const getUserInfo = async () => {
    const doctor = await loadDoctor.load([
      'name',
      'lastName',
      'cpf',
      'gender',
      'birthDate',
      'ethnicity',
      'badgeName',
      'bannerViewedByDoctor'
    ])
    setData(doctor)
  }
  useEffect(() => {
    getUserInfo()
  }, [])

  return (
    <>
      {secretaryInfo ? (
        <UpdateSecretaryInfoLayout
          data={secretaryInfo}
          setOpenSuccessModal={setOpenSuccessModal}
        />
      ) : (
        data.name && <UpdateDoctorPersonalInfoLayout data={data} />
      )}

      <Modal
        title="Dados atualizado com sucesso"
        show={openSuccessModal}
        close={() => setOpenSuccessModal(false)}
      />
      <Modal
        title="Senha Atualizada"
        show={updatePasswordSuccess}
        close={() => setUpdatePasswordSuccess(false)}
      />
    </>
  )
}
