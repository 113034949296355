import { SearchFiltersSchedules } from 'domain/usecases/scheduling/search-filters-schedules'
import { GetHoursByDay } from 'domain/usecases/scheduling/get-hours-by-day'

export const searchFiltersSchedulesQuery = (
  params: SearchFiltersSchedules.Params
) => ({
  query: `
    query Search($query: String!, $type: SearchTypeEnum!, $term: Int, $patient: PatientData!, $healthInsurance: HealthInsuranceData!) {
      search(data: {
        query: $query, type: $type, term: $term, patient: $patient, healthInsurance: $healthInsurance
      }) {${params?.fields.join(',')}}
    }
  `,
  name: 'search'
})

export const getHoursByDayQuery = (params: GetHoursByDay.Params) => ({
  query: `
    query GetHoursByDay(
      $healthInsurance: HealthInsuranceData!,
      $specialty: SpecialtyData,
      $doctor: DoctorData,
      $patient: PatientData
      $units: [UnitData!]!,
      $schedule_id: Int!,
      $date: String,
    ) {
      getHoursByDay(
        data: {
          doctor: $doctor,
          healthInsurance: $healthInsurance,
          specialty: $specialty,
          patient: $patient,
          units: $units,
          schedule_id: $schedule_id,
          date: $date
        }
      ) {${params?.fields.join(',')}}
    }
  `,
  name: 'getHoursByDay'
})

export const getReasonCancelQuery = () => ({
  query: `
    query {
      getReasonCancel {
        reason_cancel_id,
        name
      }
    }
  `,
  name: 'getReasonCancel'
})
