import React, { useEffect, useState } from 'react'
import SupportTextNew from 'presentation/shared/components/SupportTextNew'
import ButtonNew from 'presentation/shared/components/ButtonNew'
import SheetModalNew from 'presentation/shared/components/SheetModalNew'
import TextAreaNew from 'presentation/shared/components/TextAreaNew'
import RadioButtonNew from 'presentation/shared/components/RadioButtonNew'
import { AppointmentModel } from 'domain/usecases/surgical-scheduling/send-surgical-scheduling'
import { useLocation } from 'react-router'
import moment from 'moment'
import * as S from './styles'

type Props = {
  formik: any
  onClose: (value: boolean) => void
  isOpen: boolean
}

export default function PreferentialDateModal({
  onClose,
  isOpen,
  formik
}: Props) {
  const location = useLocation<{ isReviewing?: boolean }>()
  const getPreference: () => { pref: 'main' | 'alt' | 'any'; just: string } =
    () => {
      if (formik.values.main.justificative || !location.state?.isReviewing) {
        return {
          pref: 'main',
          just: formik.values.main.justificative
        }
      } else if (formik.values.alt.justificative) {
        return {
          pref: 'alt',
          just: formik.values.alt.justificative
        }
      } else {
        return {
          pref: 'any',
          just: ''
        }
      }
    }

  const [mainLabel, setMainLabel] = useState<string>('')
  const [altLabel, setAltLabel] = useState<string>('')
  const [preference, setPreference] = useState<'main' | 'alt' | 'any'>(
    getPreference().pref
  )
  const [justificative, setJustificative] = useState<string>(
    getPreference().just
  )

  const submitButton = () => {
    return (
      <ButtonNew
        fullWidth
        size="large"
        onClick={formik.submitForm}
        disabled={!formik.isValid || (preference !== 'any' && !justificative)}
      >
        Selecionar preferência
      </ButtonNew>
    )
  }

  const formatDateTime = ({ hour, any_time, start_date }: AppointmentModel) => {
    const formattedDateTime = moment(
      `${start_date}:${!any_time ? ':' + hour : ''}`,
      ['YYYY-MM-DD:HH:mm', 'YYYY-MM-DD']
    ).format(`dddd - DD MMM[.] ${any_time ? '' : '[às] HH:mm'}`)

    return formattedDateTime.replace('-feira', '')
  }

  const { main, alt } = formik.values
  useEffect(() => {
    if (alt.start_date) {
      setAltLabel(formatDateTime(alt))
    }
  }, [alt.hour, alt.start_date, alt.any_time])

  useEffect(() => {
    if (main.start_date) {
      setMainLabel(formatDateTime(main))
    }
  }, [main.hour, main.start_date, main.any_time])

  const dates = ['main', 'alt']
  const handlePreferenceChange = (opt: 'main' | 'alt' | 'any') => {
    setPreference(opt)

    dates.forEach((date) => {
      formik.setFieldValue(
        `${date}.justificative`,
        date === opt ? justificative : ''
      )
    })
  }

  const handleJustificativeChange = (text: string) => {
    setJustificative(text)

    dates.forEach((date) => {
      formik.setFieldValue(
        `${date}.justificative`,
        date === preference ? text : ''
      )
    })
  }

  return (
    <SheetModalNew
      isDraggable
      isOpen={isOpen}
      onClose={() => onClose(false)}
      title="Data de preferência"
      primaryButton={submitButton()}
      gap="24px"
    >
      <SupportTextNew textAligh="center">
        Você selecionou as sequintes datas para o agendamento da cirurgia:
      </SupportTextNew>

      <div>
        <S.Label weight="bold" color="neutral900">
          Qual a data de sua preferência? *
        </S.Label>

        <S.RadioGroup>
          <RadioButtonNew
            showBackground={false}
            label={mainLabel}
            name="main-date"
            labelFor="main-date-main"
            value={preference}
            checked={preference === 'main'}
            onCheck={() => handlePreferenceChange('main')}
            required
          />
          <RadioButtonNew
            showBackground={false}
            value={preference}
            label={altLabel}
            name="main-date"
            labelFor="main-date-alt"
            checked={preference === 'alt'}
            onCheck={() => handlePreferenceChange('alt')}
            required
          />
          <RadioButtonNew
            label="Não tenho preferência"
            showBackground={false}
            name="main-date"
            labelFor="main-date-any"
            onCheck={() => handlePreferenceChange('any')}
            checked={preference === 'any'}
            value={preference}
            required
          />
        </S.RadioGroup>
      </div>

      {preference !== 'any' && (
        <div>
          <S.Label weight="bold" color="neutral900">
            Descreva o motivo de sua data de preferência *
          </S.Label>
          <TextAreaNew
            rows={4}
            placeholder="Digite aqui o motivo."
            value={justificative}
            onInputChange={(value) => handleJustificativeChange(value)}
          />
        </div>
      )}

      <SupportTextNew size="xsmall" textAligh="center">
        A data de preferência não é uma garantia. Caso haja disponibilidade na
        data inserida, o setor de marcação pode realizar o agendamento.
      </SupportTextNew>
    </SheetModalNew>
  )
}
