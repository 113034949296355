import React, { useEffect, useState } from 'react'
import { ContainerNew } from 'presentation/shared/components/ContainerNew'
import ButtonNew from 'presentation/shared/components/ButtonNew'
import HeadingNew from 'presentation/shared/components/HeadingNew'
import SupportTextNew from 'presentation/shared/components/SupportTextNew'
import RadioButtonNew from 'presentation/shared/components/RadioButtonNew'
import TextAreaNew from 'presentation/shared/components/TextAreaNew'
import { useFormik } from 'formik'
import DividerNew from 'presentation/shared/components/DividerNew'
import * as S from './styles'
import { booleansList } from 'common/enum/booleans'
import { useHistory } from 'react-router'
import { clone } from 'lodash'
import { useLocation } from 'react-router'
import SelectFieldNew from 'presentation/shared/components/SelectFieldNew'
import * as yup from 'yup'

type Props = {
  predictedTime: string
  dispatch: (action: { type: any; payload: any }) => void
  duration: {
    predicted_time: string
    requested_time?: string
    justificative?: string
  }
}

export default function SurgicalSchedulingDurationLayout({
  predictedTime,
  dispatch,
  duration
}: Props) {
  const location = useLocation<{ isReviewing?: boolean }>()
  const history = useHistory()
  const [isSuggestion, setIsSuggestion] = useState<boolean>(
    duration?.requested_time ? true : false
  )
  const [durationsList, setDurationsList] = useState<
    { label: string; value: string }[]
  >([])

  const formik = useFormik({
    initialValues: {
      requested_time: duration?.requested_time || '',
      justificative: duration?.justificative || '',
      predicted_time: predictedTime
    },
    validationSchema: validationSchema(isSuggestion),
    validateOnMount: true,
    validateOnChange: true,
    enableReinitialize: true,
    onSubmit: (values) => submitForm(values)
  })

  useEffect(() => {
    formik.validateForm()
  }, [isSuggestion])

  useEffect(() => {
    formik.setFieldValue('predicted_time', predictedTime)

    const timesList = []
    const splittedTime = predictedTime.split(':')
    const hasMinuts = splittedTime[1] === '30'
    let maxTime = Number(splittedTime[0]) * 3
    if (hasMinuts) maxTime++

    for (let i = 0; i <= maxTime; i++) {
      const h = ('00' + i).slice(-2)
      if (i > 0) {
        timesList.push(`${h}:00`)
      }
      if (i < maxTime || hasMinuts) {
        timesList.push(`${h}:30`)
      }

      const mappedHours = timesList.map((time) => ({
        label: time,
        value: time
      }))

      setDurationsList(mappedHours)
    }
  }, [predictedTime])

  const submitForm = (values: any) => {
    const payload = clone(values)

    if (!isSuggestion) {
      delete payload.requested_time
      delete payload.justificative
    }

    dispatch({
      type: 'TIME',
      payload
    })

    if (location.state?.isReviewing) {
      history.push('/agendamento-cirurgico/resumo')
    } else {
      history.push('/agendamento-cirurgico/data-e-hora')
    }
  }

  const submitButton = () => {
    return (
      <ButtonNew
        fullWidth
        size="large"
        onClick={formik.submitForm}
        disabled={!formik.isValid}
      >
        Próximo
      </ButtonNew>
    )
  }

  const boolListReversed = () => {
    const newArr = [...booleansList]
    return newArr.reverse()
  }

  return (
    <ContainerNew primaryButton={submitButton()} gap="24px" noHeader>
      <S.EstimatedTiming>
        <HeadingNew
          color="secondary400"
          weight="bold"
          size="xxxlarge"
          style={{ lineHeight: '1.2em' }}
        >
          {predictedTime.replace(':', 'h')}
        </HeadingNew>
        <SupportTextNew weight="bold" color="neutral900">
          Tempo de cirurgia
        </SupportTextNew>
        <SupportTextNew style={{ marginTop: '16px' }} size="xsmall">
          Este tempo foi calculado com base no <b>tempo médio gasto por você</b>{' '}
          nas outras vezes que realizou este procedimento.
        </SupportTextNew>
      </S.EstimatedTiming>

      <DividerNew marginBottom="8px" marginTop="8px" />

      <div>
        <S.Label weight="bold" color="neutral900">
          Deseja sugerir outro tempo de duração? *
        </S.Label>
        <S.BooleanRadioWrapper isColumn>
          {boolListReversed().map((boolean) => (
            <RadioButtonNew
              showBackground={false}
              label={boolean.label}
              name="suggestion"
              labelFor={'suggestion-' + boolean.value}
              onCheck={(value) => setIsSuggestion(value === 'true')}
              checked={isSuggestion === boolean.value}
              value={String(boolean.value)}
              key={String(boolean.value)}
              required
            />
          ))}
        </S.BooleanRadioWrapper>
      </div>

      {isSuggestion && (
        <div>
          <S.Label weight="bold" color="neutral900">
            Selecione o tempo e justifique abaixo.
          </S.Label>
          <SelectFieldNew
            label="Tempo de cirurgia *"
            placeholder="Escolha uma duração"
            value={formik.values.requested_time}
            onInputChange={formik.handleChange('requested_time')}
            items={durationsList}
            style={{ marginBottom: '24px' }}
          />
          <TextAreaNew
            label="Justificativa de alteração *"
            placeholder="Digite aqui o motivo da alteração"
            value={formik.values.justificative}
            onInputChange={formik.handleChange('justificative')}
            rows={5}
          />
        </div>
      )}
    </ContainerNew>
  )
}

const validationSchema = (isSuggestion: boolean) => {
  return yup.object().shape({
    requested_time: isSuggestion
      ? yup
          .string()
          .trim()
          .matches(/[0-9][0-9]:[0-5][0-9]$/, 'Hora inválida')
      : yup.string(),
    justificative: isSuggestion ? yup.string().required() : yup.string(),
    predicted_time: yup.string().required()
  })
}
