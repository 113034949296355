import React, { useState } from 'react'
import * as yup from 'yup'

import {
  WithLoading,
  WithLoadingProps
} from 'presentation/shared/components/HOCs/WithLoading'
import TextFieldNew from 'presentation/shared/components/TextFieldNew'
import ButtonNew from 'presentation/shared/components/ButtonNew'
import CardNew from 'presentation/shared/components/CardNew'
import { ContainerNew } from 'presentation/shared/components/ContainerNew'
import { ReactComponent as SearchIcon } from 'presentation/assets/icons/search.svg'
import { ReactComponent as ArrowIcon } from 'presentation/assets/icons/open-arrow.svg'
import SupportTextNew from 'presentation/shared/components/SupportTextNew'
import * as S from '../styles'
import { useHistory, useLocation } from 'react-router'
import Divider from 'presentation/shared/components/DividerNew'
import { TitleAndSubtitleWrapper } from 'presentation/shared/components/ContainerTitleAndSubtitle'
import AutoCompleteNew from 'presentation/shared/components/AutoCompleteNew'
import { useServices } from 'presentation/hooks/use-services'
import { toast } from 'react-toastify'
import { ProcedureModel } from 'domain/entities/surgical-order-model'
import { useFormik } from 'formik'
import { Cid } from 'domain/entities/cid'
import { useStores } from 'presentation/hooks/use-stores'
import { ProceduresProps } from 'presentation/contexts/surgical-order/surgical-order-context'
import { SurgicalOrderCidModel } from 'domain/entities/surgical-order-cid-model'

type ProceduresSuggestions = {
  label: string
  value: ProcedureModel
}

type CIDSuggestions = {
  label: string
  value: SurgicalOrderCidModel
}

const SelectProceduresAndCIDForm = WithLoading(
  ({ setIsLoading }: WithLoadingProps) => {
    const { state } = useLocation<{
      isEditing?: boolean
    }>()
    const history = useHistory()
    const surgicalOrderContext = useStores().surgicalOrder
    const surgicalOrderService = useServices().surgicalOrder

    const [proceduresSuggestions, setProceduresSuggestions] = useState<
      ProceduresSuggestions[]
    >([])
    const [procedureSearchTerm, setProcedureSearchTerm] = useState('')
    const [currentProcedure, setCurrentProcedure] = useState<ProcedureModel>({
      quantity: 1
    } as ProcedureModel)

    const [CIDSuggestions, setCIDSuggestions] = useState<CIDSuggestions[]>([])
    const [CIDSearchTerm, setCIDSearchTerm] = useState('')

    const getProceduresSuggestions = async (term: string) => {
      try {
        setProcedureSearchTerm(term)

        if (term) {
          let searchParams

          if (isNaN(Number(term))) {
            searchParams = { name: term }
          } else {
            searchParams = { code: term }
          }

          const searchProcedures = await surgicalOrderService.searchProcedures(
            searchParams
          )

          const suggestionsProcedures = searchProcedures.map((procedure) => {
            return {
              label: `${procedure.tuss_id} - ${procedure.description}`,
              value: procedure
            }
          })

          setProceduresSuggestions(suggestionsProcedures)
        }
      } catch (error: any) {
        toast.error('Ocorreu um erro ao buscar procedimentos')
        setProceduresSuggestions([])
      }
    }

    const addProcedure = () => {
      if (
        !formik.values.procedures.find(
          (procedure) => procedure.tuss_id === currentProcedure.tuss_id
        ) &&
        currentProcedure.tuss_id &&
        currentProcedure.quantity >= 1
      ) {
        formik.setFieldValue('procedures', [
          ...formik.values.procedures,
          {
            ...currentProcedure,
            code: currentProcedure.tuss_id,
            quantity: currentProcedure.quantity || 1
          }
        ])
        setProcedureSearchTerm('')
        setCurrentProcedure({
          quantity: 1
        } as ProcedureModel)
      }
    }

    const removeProcedure = (id?: string) => {
      if (id) {
        formik.setFieldValue(
          'procedures',
          formik.values.procedures.filter((procedure) => procedure.code !== id)
        )
      }
    }

    const getCIDSuggestions = async (term: string) => {
      try {
        setCIDSearchTerm(term)

        if (term) {
          const searchParams = { name: term }

          const searchCID = await surgicalOrderService.searchCID(searchParams)

          const suggestionsCID = searchCID.map((CID) => {
            return {
              label: `${CID.code} - ${CID.description}`,
              value: CID
            }
          })

          setCIDSuggestions(suggestionsCID)
        }
      } catch (error: any) {
        toast.error('Ocorreu um erro ao buscar CID')
        setCIDSuggestions([])
      }
    }

    const addCID = (cid: Cid) => {
      if (
        !formik.values.cids.find((addedCid) => addedCid.code === cid.code) &&
        cid.code
      ) {
        formik.setFieldValue('cids', [...formik.values.cids, cid])
        setCIDSearchTerm('')
      }
    }

    const removeCID = (id?: string) => {
      if (id) {
        formik.setFieldValue(
          'cids',
          formik.values.cids.filter((cid) => cid.code !== id)
        )
      }
    }

    const capitalizeFirstLetter = (name: string) => {
      name = name.trim()

      if (name[0].match(/"/)) {
        name = name[0] + name[1].toUpperCase() + name.substring(2).toLowerCase()
      } else {
        name = name[0].toUpperCase() + name.substring(1).toLowerCase()
      }

      return name
    }

    const formik = useFormik({
      initialValues: {
        procedures:
          surgicalOrderContext.getProcedures()?.procedures ??
          surgicalOrderContext.getSurgicalOrder()?.procedures ??
          [],
        cids:
          surgicalOrderContext.getProcedures()?.cids ??
          surgicalOrderContext.getSurgicalOrder()?.cids ??
          []
      } as ProceduresProps,
      validationSchema: validationSchema,
      validateOnChange: true,
      validateOnMount: true,
      onSubmit: async (values) => {
        try {
          setIsLoading(true)

          values.procedures[0] = {
            ...values.procedures[0],
            is_main: true
          }

          for (let i = 1; i < values.procedures.length; i++) {
            values.procedures[i] = {
              ...values.procedures[i],
              is_main: false
            }
          }

          values.cids[0] = {
            ...values.cids[0],
            is_main: true
          }

          for (let i = 1; i < values.cids.length; i++) {
            values.cids[i] = {
              ...values.cids[i],
              is_main: false
            }
          }

          await surgicalOrderService.updateSurgicalOrder({
            surgical_order_id:
              surgicalOrderContext.getSurgicalOrder()?.surgical_order_id,
            patient: {
              patient_id: surgicalOrderContext.getPatient().patient.patient_id,
              patient_name:
                surgicalOrderContext.getPatient().patient.name ||
                surgicalOrderContext.getPatient().patient.patient_name
            },
            procedure: values.procedures,
            cid: values.cids
          })

          surgicalOrderContext.setProcedures(values)

          // toast.success('Os dados do pedido foram atualizados!')

          if (state?.isEditing) {
            history.push('/novo-pedido/resumo')
          } else {
            history.push('/novo-pedido/opme')
          }
        } catch (error: any) {
          toast.error(`[Procedimentos] ${error?.message}`)
          history.goBack()
        } finally {
          setIsLoading(false)
        }
      }
    })

    return (
      <ContainerNew
        buttonsHasBoxShadow={!state?.isEditing}
        primaryButton={
          <ButtonNew
            onClick={formik.submitForm}
            fullWidth
            size="large"
            disabled={!!formik.errors.procedures || !!formik.errors.cids}
          >
            {state?.isEditing ? 'Salvar edição' : 'Próximo'}
          </ButtonNew>
        }
        secondaryButton={
          !state?.isEditing && (
            <ButtonNew onClick={history.goBack} fullWidth outlined size="large">
              Anterior
            </ButtonNew>
          )
        }
      >
        <S.FormWrapper onSubmit={formik.handleSubmit}>
          <TitleAndSubtitleWrapper
            title="Procedimentos"
            subtitle="Adicione os procedimentos que serão realizados e suas quantidades."
          />
          <AutoCompleteNew
            label="Código TUSS"
            required
            requiredColor="neutral800"
            placeholder="Digite o código TUSS ou nome"
            icon={<SearchIcon />}
            iconIsButton
            onSuggestionClick={(procedure) => {
              setProcedureSearchTerm(
                `${procedure.tuss_id} - ${procedure.description}`
              )
              setCurrentProcedure({
                ...procedure,
                quantity: currentProcedure.quantity
              })
            }}
            suggestions={proceduresSuggestions}
            onInputChange={setProcedureSearchTerm}
            onType={getProceduresSuggestions}
            value={procedureSearchTerm}
          />
          <TextFieldNew
            label="Quantidade"
            required
            requiredColor="neutral800"
            value={
              currentProcedure.quantity >= 1 ? currentProcedure.quantity : 0
            }
            onInputChange={(quantity) => {
              const isValidQuantityInput = (value: string) => {
                const digitRegex = /\d+/g
                const lastChar = value.charAt(value.length - 1)

                return (
                  (digitRegex.test(lastChar) && lastChar !== ',') ||
                  value === ''
                )
              }

              if (isValidQuantityInput(quantity)) {
                setCurrentProcedure({
                  ...currentProcedure,
                  quantity: Number(quantity)
                })
              }
            }}
            min={1}
            error={
              currentProcedure.quantity < 1 ? 'Quantidade inválida.' : undefined
            }
            icon={
              <S.ArrowIconsWrapper>
                <ArrowIcon
                  onClick={() =>
                    setCurrentProcedure({
                      ...currentProcedure,
                      quantity: currentProcedure.quantity + 1
                    })
                  }
                />
                <ArrowIcon
                  onClick={() =>
                    setCurrentProcedure({
                      ...currentProcedure,
                      quantity:
                        currentProcedure.quantity > 1
                          ? currentProcedure.quantity - 1
                          : currentProcedure.quantity
                    })
                  }
                />
              </S.ArrowIconsWrapper>
            }
          />
          <ButtonNew
            outlined
            fullWidth
            size="large"
            onClick={addProcedure}
            type="button"
          >
            Adicionar procedimento
          </ButtonNew>
          {formik.values.procedures
            ? formik.values.procedures.map((procedure) => {
                return (
                  <CardNew key={procedure.tuss_id} color="neutral25" gap="8px">
                    <SupportTextNew
                      color="primary600"
                      weight="bold"
                      size="small"
                    >
                      {capitalizeFirstLetter(procedure.description)}
                    </SupportTextNew>
                    <S.ProcedureCardInfoRow>
                      <S.ProcedureCardInfo>
                        <SupportTextNew size="xxsmall" color="neutral600">
                          Código TUSS
                        </SupportTextNew>
                        <SupportTextNew size="xxsmall" color="neutral900">
                          {procedure.code}
                        </SupportTextNew>
                      </S.ProcedureCardInfo>
                      <S.ProcedureCardInfo>
                        <SupportTextNew size="xxsmall" color="neutral600">
                          Quantidade
                        </SupportTextNew>
                        <SupportTextNew size="xxsmall" color="neutral900">
                          {procedure.quantity}
                        </SupportTextNew>
                      </S.ProcedureCardInfo>
                      <S.TrashIcon
                        onClick={() => removeProcedure(procedure.code)}
                      />
                    </S.ProcedureCardInfoRow>
                  </CardNew>
                )
              })
            : null}
          <S.DividerWrapper>
            <Divider color="neutral100" marginTop="0px" marginBottom="0px" />
          </S.DividerWrapper>
          <TitleAndSubtitleWrapper
            title="CID"
            subtitle="Adicione o CID referente à doença."
          />
          <AutoCompleteNew
            label="Código"
            required
            requiredColor="neutral800"
            placeholder="Digite o código ou nome"
            icon={<SearchIcon />}
            iconIsButton
            onSuggestionClick={addCID}
            suggestions={CIDSuggestions}
            onInputChange={setCIDSearchTerm}
            onType={getCIDSuggestions}
            value={CIDSearchTerm}
          />
          {formik.values.cids
            ? formik.values.cids.map((cid) => {
                return (
                  <CardNew key={cid.code} color="neutral25" gap="8px">
                    <SupportTextNew
                      color="primary600"
                      weight="bold"
                      size="small"
                    >
                      {capitalizeFirstLetter(cid.description)}
                    </SupportTextNew>
                    <S.ProcedureCardInfoRow>
                      <S.ProcedureCardInfo>
                        <SupportTextNew size="xxsmall" color="neutral600">
                          CID
                        </SupportTextNew>
                        <SupportTextNew size="xxsmall" color="neutral900">
                          {cid.code}
                        </SupportTextNew>
                      </S.ProcedureCardInfo>
                      <S.TrashIcon onClick={() => removeCID(cid.code)} />
                    </S.ProcedureCardInfoRow>
                  </CardNew>
                )
              })
            : null}
        </S.FormWrapper>
      </ContainerNew>
    )
  }
)

export default SelectProceduresAndCIDForm

const validationSchema = yup.object().shape({
  procedures: yup.array().min(1).required(),
  cids: yup.array().min(1).required()
})
