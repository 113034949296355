import React, { useState, useEffect, ReactElement } from 'react'
import { useFormik } from 'formik'
import * as yup from 'yup'
import { toast } from 'react-toastify'
import { useHistory } from 'react-router-dom'

import Modal from 'presentation/shared/components/Modal'
import Checkbox from 'presentation/shared/components/Checkbox'
import Button from 'presentation/shared/components/Button'
import { useServices } from 'presentation/hooks/use-services'
import { useStores } from 'presentation/hooks/use-stores'
import {
  WithLoading,
  WithLoadingProps
} from 'presentation/shared/components/HOCs/WithLoading'
import * as S from './styles'
import { Profile } from 'common/enum/profile'
import DoctorTerms from 'presentation/assets/terms/doctor-terms'
import SecretaryTerms from 'presentation/assets/terms/secretary-terms'

type Geolocation = {
  latitude: number
  longitude: number
  accuracy: number
}

type Props = {
  index: number
  termName: string
} & WithLoadingProps

type Term = {
  name: string
  title: string
  content: ReactElement
  acceptMessage: string
}

declare const navigator: any

const AcceptUserTerms = WithLoading(
  ({ index, termName, setIsLoading }: Props) => {
    const currentAccount = useStores().currentAccount?.getCurrentAccount()
    const actionCurrentAccount = useStores().currentAccount
    const [geolocation, setGeolocation] = useState<Geolocation>(
      {} as Geolocation
    )
    const [successModal, setSuccessModal] = useState<boolean>()
    const [term, setTerm] = useState<Term>()

    const history = useHistory()
    const userServices = useServices().user

    const formik = useFormik({
      initialValues: {
        term: ''
      } as AcceptTermFormValues,
      validationSchema: AcceptUseTermFormValidation,
      validateOnMount: true,
      onSubmit: async (values) => {
        const payload = {
          term: values.term,
          geolocation: JSON.stringify(geolocation),
          platform: navigator?.platform,
          acceptedAt: new Date(),
          user_id: currentAccount.user.user_id
        }

        try {
          setIsLoading(true)
          await userServices.acceptUserTerm(payload)
          const filteredTerms = currentAccount.user.terms?.filter(
            (term) => term.name !== values.term
          )

          const signedTerm = {
            name: values.term,
            signed: true
          }

          filteredTerms?.push(signedTerm)
          currentAccount.user.terms = filteredTerms
          actionCurrentAccount.setCurrentAccount(currentAccount)
          setSuccessModal(true)
        } catch {
          toast.error(
            'Ocorreu um erro ao assinar o termo, tente novamente mais tarde.'
          )
        } finally {
          setIsLoading(false)
        }
      }
    })

    const handleCheckboxOnChange = (fieldName: string, term: string) =>
      formik.values.term
        ? formik.setFieldValue(fieldName, '')
        : formik.setFieldValue(fieldName, term)

    const getLocationByPosition = () => {
      if (navigator?.geolocation) {
        const nav = navigator?.geolocation //NOSONAR
        nav.getCurrentPosition((position: any) => {
          const { accuracy, latitude, longitude } = position?.coords
          setGeolocation({ accuracy, latitude, longitude })
        })
      }
    }

    const getTermByRoleAndName = (termName: string) => {
      switch (currentAccount.user.role) {
        case Profile.SECRETARY: {
          const secretaryTerms = SecretaryTerms()
          const term = secretaryTerms.filter((term) => term.name === termName)
          if (!term.length) {
            return toast.error(`Ocorreu um erro ao buscar ${term[0]?.title}`)
          }
          return setTerm(term[0])
        }

        default: {
          const doctorTerms = DoctorTerms()
          const term = doctorTerms.filter((term) => term.name === termName)
          if (!term.length) {
            return toast.error(`Ocorreu um erro ao buscar ${term[0]?.title}`)
          }
          return setTerm(term[0])
        }
      }
    }

    useEffect(() => {
      getTermByRoleAndName(termName)
      getLocationByPosition()
    }, [])

    return (
      <>
        <S.Wrapper role="form" onSubmit={formik.handleSubmit}>
          <S.Term>
            <div className="header">
              <h1>{term?.title}</h1>
            </div>
            <div className="content">{term?.content}</div>
          </S.Term>
          <S.AcceptTerm>
            <Checkbox
              label={term?.acceptMessage}
              id={`term_${index}`}
              name={`term_${index}`}
              labelFor={`term_${index}`}
              onCheck={() => handleCheckboxOnChange('term', term!.name)}
            />
            <Button
              type="submit"
              fullWidth
              disabled={formik.isSubmitting || !formik.isValid}
            >
              Prosseguir
            </Button>
          </S.AcceptTerm>
        </S.Wrapper>
        <Modal
          title={`${term?.title} assinado com sucesso.`}
          show={successModal}
          close={() => history.push('/home')}
        />
      </>
    )
  }
)

export type AcceptTermFormValues = {
  geolocation: string
  acceptedAt: Date
  platform: string
  term: string
  user_id: number
}

const AcceptUseTermFormValidation = yup.object().shape({
  term: yup.string().required()
})

export default AcceptUserTerms
