import { LoadHospitalsByDoctor } from 'domain/usecases/hospital/load-hospitals-by-doctor'
import { LoadSurgeryCenters } from 'domain/usecases/surgery-center/load-surgery-centers'
import { makeAutoObservable } from 'mobx'

type Services = {
  loadHospitalsByDoctor: LoadHospitalsByDoctor
  loadSurgeryCenters: LoadSurgeryCenters
}

export class HospitalService {
  constructor(private readonly services: Services) {
    makeAutoObservable(this)
  }

  async loadHospitalsByDoctor(): Promise<LoadHospitalsByDoctor.Model> {
    return this.services.loadHospitalsByDoctor.load()
  }

  async loadSurgeryCenters(
    params: LoadSurgeryCenters.Params
  ): Promise<LoadSurgeryCenters.Model> {
    return this.services.loadSurgeryCenters.load(params)
  }
}
