import { LoadDoctorSchedule } from 'domain/usecases/doctor/load-doctor-schedule'
import { SearchDoctors } from 'domain/usecases/doctor/search-doctors'
import { LoadDoctor } from 'domain/usecases/doctor/load-doctor'
import { FinishDoctorSurgicalOrder } from 'domain/usecases/doctor/finish-doctor-surgical-order'
import { LoadDoctorInfo } from 'domain/usecases/doctor/load-doctor-info'
import { LoadDoctorByInviteToken } from 'domain/usecases/doctor/load-doctor-by-invite-token'
import { UpdateDoctor } from 'domain/usecases/doctor/update-doctor'
import { LoadDocumentExternalCertifications } from 'domain/usecases/doctor/load-documents-external-certifications'
import { UploadDoctorDocument } from 'domain/usecases/doctor/upload-doctor-document'
import { LoadDoctorPendencies } from 'domain/usecases/doctor/load-doctor-pendencies'
import { LoadRecommendedDoctors } from 'domain/usecases/doctor/load-recommended-doctors'
import { LoadDoctorWithRecommendation } from 'domain/usecases/doctor/load-doctor-with-recommendation'
import { ResendDoctorInvite } from 'domain/usecases/doctor/resendDoctorInvite'
import { LoadDoctorDocument } from 'domain/usecases/doctor/load-doctor-document'
import { AddFavoriteSurgicalOrder } from 'domain/usecases/doctor/add-favorite-surgical-order'
import { FinishDoctorRecommendation } from 'domain/usecases/doctor/finish-doctor-recommendation'
import { LoadMedicalTeams } from 'domain/usecases/medical-team/load-medical-teams'
import { LoadAllDoctorRegisters } from 'domain/usecases/doctor/load-all-doctor-registers'
import { GetRequiredDoctorDocuments } from 'domain/usecases/doctor/get-doctor-required-documents'
import { CompleteDoctorRegister } from '../../../domain/usecases/doctor/complete-doctor-register'
import { CreateDoctorRegisterStepPendency } from 'domain/usecases/doctor-register-step-pendency/create-doctor-register-step-pendency'
import { SendDoctorRegisterChangesToAnalysis } from '../../../domain/usecases/doctor/send-doctor-register-changes-to-analysis'
import { LoadDoctorRegisterPendenciesGroupedDate } from '../../../domain/usecases/doctor/load-doctor-register-pendencies-grouped-date'
import { UpdateDoctorRegisterStatus } from 'domain/usecases/doctor-register/update-doctor-register-status'
import { LinkSecretary } from 'domain/usecases/doctor/link-secretary'
import { LoadAllSurgicalOrdersByDoctor } from 'domain/usecases/doctor/load-all-surgical-order'
import { LoadDoctorSurgeryRequestCount } from 'domain/usecases/doctor/load-doctor-surgery-request-count'
import { LoadDoctorDaysAvailable } from 'domain/usecases/doctor/load-doctor-days-available'
import { LoadSurgicalOrdersByDoctor } from 'domain/usecases/doctor/load-surgical-order'
import { GetMyProductivityInfo } from 'domain/usecases/doctor/get-my-productivity-info'

type Services = {
  loadDoctorSchedule: LoadDoctorSchedule
  searchDoctors: SearchDoctors
  loadDoctor: LoadDoctor
  finishDoctorSurgicalOrder: FinishDoctorSurgicalOrder
  loadDoctorInfo: LoadDoctorInfo
  loadDoctorByInviteToken: LoadDoctorByInviteToken
  finishDoctorRecommendation: FinishDoctorRecommendation
  updateDoctor: UpdateDoctor
  loadDocumentsExternalCertifications: LoadDocumentExternalCertifications
  uploadDoctorDocument: UploadDoctorDocument
  loadSurgeryRequestCount: LoadDoctorSurgeryRequestCount
  loadSchedulingSurgeryRequestCount: LoadDoctorSurgeryRequestCount
  loadAllSurgicalOrdersByDoctor: LoadAllSurgicalOrdersByDoctor
  loadDoctorPendencies: LoadDoctorPendencies
  loadRecommendedDoctors: LoadRecommendedDoctors
  loadDoctorWithRecommendation: LoadDoctorWithRecommendation
  resendDoctorInvite: ResendDoctorInvite
  loadDoctorDocument: LoadDoctorDocument
  addFavoriteSurgicalOrder: AddFavoriteSurgicalOrder
  loadMedicalTeams: LoadMedicalTeams
  loadAllDoctorRegisters: LoadAllDoctorRegisters
  getRequiredDoctorDocuments: GetRequiredDoctorDocuments
  completeDoctorRegister: CompleteDoctorRegister
  createDoctorRegisterPendency: CreateDoctorRegisterStepPendency
  sendDoctorRegisterChangesToAnalysis: SendDoctorRegisterChangesToAnalysis
  loadDoctorRegisterPendenciesGroupedDate: LoadDoctorRegisterPendenciesGroupedDate
  updateDoctorRegisterStatus: UpdateDoctorRegisterStatus
  linkSecretary: LinkSecretary
  loadDoctorDaysAvailable: LoadDoctorDaysAvailable
  loadSurgicalOrdersByDoctor: LoadSurgicalOrdersByDoctor
  getMyProductivityInfo: GetMyProductivityInfo
}

export class DoctorService {
  constructor(services: Services) {
    this.services = services
  }

  private readonly services: Services

  async loadDoctorSchedule(
    params: LoadDoctorSchedule.Params
  ): Promise<LoadDoctorSchedule.Model> {
    return this.services.loadDoctorSchedule.load(params)
  }

  async loadDoctorDaysAvailable(
    params: LoadDoctorDaysAvailable.Params
  ): Promise<LoadDoctorDaysAvailable.Model> {
    return this.services.loadDoctorDaysAvailable.load(params)
  }

  async searchDoctors(
    params: SearchDoctors.Params
  ): Promise<SearchDoctors.Model> {
    return this.services.searchDoctors.load(params)
  }

  async loadDoctor(params: LoadDoctor.Params): Promise<LoadDoctor.Model> {
    return this.services.loadDoctor.load(params)
  }

  async finishDoctorSurgicalOrder(
    params: FinishDoctorSurgicalOrder.Params
  ): Promise<FinishDoctorSurgicalOrder.Model> {
    return this.services.finishDoctorSurgicalOrder.add(params)
  }

  async loadDoctorInfo(
    doctor_id: number,
    params?: LoadDoctorInfo.Params
  ): Promise<LoadDoctorInfo.Model> {
    return this.services.loadDoctorInfo.load(doctor_id, params)
  }

  async loadDoctorByInviteToken(
    token: string,
    params: LoadDoctorByInviteToken.Params
  ): Promise<LoadDoctorByInviteToken.Model> {
    return this.services.loadDoctorByInviteToken.load(token, params)
  }

  finishDoctorRecommendation(
    params: FinishDoctorRecommendation.Params
  ): Promise<FinishDoctorRecommendation.Model> {
    return this.services.finishDoctorRecommendation.finishDoctorRecommendation(
      params
    )
  }

  async updateDoctor(params: UpdateDoctor.Params): Promise<UpdateDoctor.Model> {
    return this.services.updateDoctor.update(params)
  }

  async loadExternalCertifications(
    params: LoadDocumentExternalCertifications.Params
  ): Promise<LoadDocumentExternalCertifications.Model> {
    return this.services.loadDocumentsExternalCertifications.load(params)
  }

  async uploadDoctorDocument(
    params: UploadDoctorDocument.Params
  ): Promise<UploadDoctorDocument.Model> {
    return this.services.uploadDoctorDocument.upload(params)
  }

  async loadSurgeryRequestCount(): Promise<LoadDoctorSurgeryRequestCount.Model> {
    return this.services.loadSurgeryRequestCount.count()
  }

  async loadSchedulingSurgeryRequestCount(): Promise<LoadDoctorSurgeryRequestCount.Model> {
    return this.services.loadSurgeryRequestCount.countSchedulings()
  }

  async loadAllSurgicalOrdersByDoctor(
    params: LoadAllSurgicalOrdersByDoctor.Params
  ): Promise<LoadAllSurgicalOrdersByDoctor.Model> {
    return this.services.loadAllSurgicalOrdersByDoctor.loadAllOrders(params)
  }

  async loadDoctorPendencies(
    params: LoadDoctorPendencies.Params
  ): Promise<LoadDoctorPendencies.Model> {
    return this.services.loadDoctorPendencies.load(params)
  }

  async loadRecommendedDoctors(
    params: LoadRecommendedDoctors.Params
  ): Promise<LoadRecommendedDoctors.Model> {
    return this.services.loadRecommendedDoctors.load(params)
  }

  async loadDoctorWithRecommendation(
    params: LoadDoctorWithRecommendation.Params
  ): Promise<LoadDoctorWithRecommendation.Model> {
    return this.services.loadDoctorWithRecommendation.load(params)
  }

  async resendDoctorInvite(
    params: ResendDoctorInvite.Params
  ): Promise<ResendDoctorInvite.Model> {
    return this.services.resendDoctorInvite.resend(params)
  }

  async loadDoctorDocument(
    params: LoadDoctorDocument.Params
  ): Promise<LoadDoctorDocument.Model> {
    return this.services.loadDoctorDocument.load(params)
  }

  async addFavoriteSurgicalOrder(
    params: AddFavoriteSurgicalOrder.Params
  ): Promise<AddFavoriteSurgicalOrder.Model> {
    return this.services.addFavoriteSurgicalOrder.add(params)
  }

  async loadMedicalTeams(
    params: LoadMedicalTeams.Params
  ): Promise<LoadMedicalTeams.Model> {
    return this.services.loadMedicalTeams.load(params)
  }

  async loadAllDoctorRegister(
    params: LoadAllDoctorRegisters.Params
  ): Promise<LoadAllDoctorRegisters.Model> {
    return this.services.loadAllDoctorRegisters.load(params)
  }

  async completeDoctorRegister(
    params: CompleteDoctorRegister.Params
  ): Promise<CompleteDoctorRegister.Model> {
    return this.services.completeDoctorRegister.execute(params)
  }

  getRequiredDoctorDocuments(
    doctor: GetRequiredDoctorDocuments.Params
  ): GetRequiredDoctorDocuments.Model {
    return this.services.getRequiredDoctorDocuments.execute(doctor)
  }

  createDoctorRegisterPendency(
    params: CreateDoctorRegisterStepPendency.Params
  ): Promise<CreateDoctorRegisterStepPendency.Model> {
    return this.services.createDoctorRegisterPendency.add(params)
  }

  async sendDoctorRegisterChangesToAnalysis(
    params: SendDoctorRegisterChangesToAnalysis.Params
  ): Promise<SendDoctorRegisterChangesToAnalysis.Model> {
    return this.services.sendDoctorRegisterChangesToAnalysis.execute(params)
  }

  async loadDoctorRegisterPendenciesGroupedDate(
    params: LoadDoctorRegisterPendenciesGroupedDate.Params
  ): Promise<LoadDoctorRegisterPendenciesGroupedDate.Model> {
    return this.services.loadDoctorRegisterPendenciesGroupedDate.execute(params)
  }
  async updateDoctorRegisterStatus(
    params: UpdateDoctorRegisterStatus.Params
  ): Promise<UpdateDoctorRegisterStatus.Model> {
    return this.services.updateDoctorRegisterStatus.execute(params)
  }
  async linkSecretary(
    params: LinkSecretary.Params
  ): Promise<LinkSecretary.Model> {
    return this.services.linkSecretary.execute(params)
  }

  async loadSurgicalOrdersByDoctor(
    params: LoadSurgicalOrdersByDoctor.Params
  ): Promise<LoadSurgicalOrdersByDoctor.Model> {
    return this.services.loadSurgicalOrdersByDoctor.loadOrders(params)
  }

  async getMyProductivityInfo(
    params: GetMyProductivityInfo.Params
  ): Promise<GetMyProductivityInfo.Model> {
    return this.services.getMyProductivityInfo.getMyProductivityInfo(params)
  }
}

export default DoctorService
