import { CheckAvailableSchedulingDate } from 'domain/usecases/surgical-scheduling/check-available-scheduling-date'
import { SurgicalSchedulingRepository } from 'repository/interfaces/surgical-scheduling-repository'

export class RemoteCheckAvailableSchedulingDate
  implements CheckAvailableSchedulingDate
{
  constructor(
    private readonly surgicalSchedulingRepository: SurgicalSchedulingRepository
  ) {}

  check(
    params: CheckAvailableSchedulingDate.Params
  ): Promise<CheckAvailableSchedulingDate.Model> {
    return this.surgicalSchedulingRepository.checkAvailableSchedulingDate(
      params
    )
  }
}
