import React from 'react'
import { AllHTMLAttributes } from 'react'

import theme from 'presentation/styles/theme'
import * as S from './styles'

export type TextAreaProps = {
  onInputChange?: (value: string) => void
  label?: string
  labelColor?: keyof typeof theme.ds.colors
  initialValue?: string
  icon?: React.ReactNode
  disabled?: boolean
  error?: string | false
  bgColor?: keyof typeof theme.ds.colors
  required?: boolean
  requiredColor?: keyof typeof theme.ds.colors
  outline?: boolean
  outlineColor?: keyof typeof theme.ds.colors
  dataTestId?: string | number
  textColor?: keyof typeof theme.ds.colors
  fontSize?: keyof typeof theme.ds.typography.size
} & AllHTMLAttributes<HTMLTextAreaElement>

const TextAreaNew: React.ForwardRefRenderFunction<
  HTMLTextAreaElement,
  TextAreaProps
> = (
  {
    label,
    labelColor = 'neutral900',
    name,
    error,
    disabled = false,
    onInputChange,
    style,
    bgColor = 'white',
    required = false,
    requiredColor = 'danger300',
    outline,
    outlineColor = 'white',
    dataTestId,
    textColor = 'neutral900',
    fontSize = 'small',
    ...props
  }: TextAreaProps,
  ref
) => {
  const onChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newValue = e.currentTarget.value

    !!onInputChange && onInputChange(newValue)
    !!props.onChange && props.onChange(e)
  }

  return (
    <S.Wrapper style={style} disabled={disabled} error={!!error}>
      {!!label && (
        <S.Label htmlFor={name} labelColor={labelColor}>
          {label}
          {required ? (
            <S.Asterisk requiredColor={requiredColor}>*</S.Asterisk>
          ) : (
            ''
          )}
        </S.Label>
      )}
      <S.InputWrapper>
        <S.Input
          onChange={onChange}
          disabled={disabled}
          name={name}
          value={props.value}
          bgColor={bgColor}
          ref={ref}
          {...(label ? { id: name } : {})}
          rows={props.rows}
          cols={props.cols}
          placeholder={props.placeholder}
          aria-label={props['aria-label']}
          outline={outline}
          outlineColor={outlineColor}
          onBlur={props.onBlur}
          defaultValue={props.defaultValue}
          data-testid={dataTestId}
          textColor={textColor}
          fontSize={fontSize}
        />
      </S.InputWrapper>
      {!!error && <S.Error>{error}</S.Error>}
    </S.Wrapper>
  )
}

export default React.forwardRef(TextAreaNew)
