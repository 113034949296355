import React from 'react'
import SupportTextNew from 'presentation/shared/components/SupportTextNew'
import AccordionCardNew from 'presentation/shared/components/AccordionCardNew'
import UploadButtonNew from 'presentation/shared/components/UploadButtonNew'
import CardNew, { CardProps } from 'presentation/shared/components/CardNew'
import { ReactComponent as DownloadIcon } from 'presentation/assets/icons/DonwloadIcon.svg'
import { ReactComponent as TrashIcon } from 'presentation/assets/icons/trash-icon.svg'
import { ReactComponent as ClockCircle } from 'presentation/assets/icons/clock-circle.svg'
import { ReactComponent as CheckCircle } from 'presentation/assets/icons/check-circle.svg'
import { ReactComponent as AlertCircle } from 'presentation/assets/icons/alert-circle.svg'
import * as S from './styles'
import moment from 'moment-timezone'
import { DocumentsList } from 'common/enum/documents-list'

type Props = {
  document: any
  marginTop?: string
  expandIcon?: React.ReactElement
  onUploadFile?: (files: any) => void
  onDeleteFile?: (document_id: number, group_id: string) => void
  onDownloadFile?: (
    document_id: number,
    group_id: string,
    type: keyof typeof DocumentsList
  ) => void
  hideHeaderDivider?: boolean
  expandedDocument?: string
  handleChange?: (accordeonLabel: string, isExpanded: boolean) => void
  multiple?: boolean
  uploadButton?: boolean
  showStatus?: boolean
  reportsRecommendations?: string
} & CardProps

const DocumentAccordeon = ({
  document,
  shadow = 'none',
  background = 'transparent',
  border = 'none',
  padding = '8px',
  gap = '8px',
  marginTop,
  hideHeaderDivider = false,
  multiple = false,
  expandedDocument,
  expandIcon,
  uploadButton,
  showStatus,
  reportsRecommendations,
  onUploadFile,
  handleChange,
  onDownloadFile,
  onDeleteFile,
  onClick
}: Props) => {
  const StatusIcon = () => {
    switch (document.status) {
      case 'APPROVED':
      case 'approved':
        return <CheckCircle />
      case 'ANALYZING':
      case 'analyzing':
        return <ClockCircle />
      case 'DISAPPROVED':
      case 'disapproved':
      case 'NOT_UPLOADED':
      case 'not uploaded':
      default:
        return <AlertCircle />
    }
  }

  const getStatusText = (status: string, name: string) => {
    switch (status) {
      case 'APPROVED':
      case 'approved':
        return 'Validado por: ' + name
      case 'ANALYZING':
      case 'analyzing':
        return 'Pendente de validação'
      case 'DISAPPROVED':
      case 'disapproved':
        return 'Invalidado por ' + name
      case 'NOT_UPLOADED':
      case 'not uploaded':
      default:
        return 'Pendente de envio'
    }
  }

  return (
    <AccordionCardNew
      accordionLabel={document.type}
      expanded={expandedDocument === document.type}
      header={DocumentsList[document.type as keyof typeof DocumentsList]}
      headerIcon={<StatusIcon />}
      shadow={shadow}
      background={background}
      border={border}
      padding={padding}
      gap={gap}
      marginTop={marginTop}
      expandIcon={expandIcon}
      hideHeaderDivider={hideHeaderDivider}
      handleChange={handleChange}
      onClick={onClick}
    >
      {document?.documents?.map((file: any, index: number) => (
        <div key={index}>
          <CardNew
            shadow="none"
            background="neutral25"
            border="neutral100"
            padding="12px 16px"
            style={{
              flexDirection: 'row',
              justifyContent: 'space-between'
            }}
          >
            <SupportTextNew color="neutral800">{file.name}</SupportTextNew>
            <S.ActionIcons>
              {onDownloadFile && (
                <DownloadIcon
                  className="download-icon"
                  onClick={() =>
                    onDownloadFile(
                      file.document_id,
                      file.group_id,
                      document.type
                    )
                  }
                />
              )}
              {[
                'not uploaded',
                'NOT_UPLOADED',
                'analyzing',
                'ANALYZING'
              ].includes(file.status) &&
                onDeleteFile && (
                  <TrashIcon
                    className="thresh-icon"
                    onClick={() =>
                      onDeleteFile(file.document_id, file.group_id)
                    }
                  />
                )}
            </S.ActionIcons>
          </CardNew>

          <S.DocumentDetails>
            <div>
              <SupportTextNew color="neutral600" size="xxsmall">
                Inclusão
              </SupportTextNew>
              <SupportTextNew color="neutral900" size="xsmall">
                {['not uploaded', 'NOT_UPLOADED'].includes(file.status)
                  ? 'Rascunho salvo em '
                  : 'Incluido em '}
                {moment.utc(file.sended_at).format('DD [de] MMM[. de] YYYY ')}
                {file.created_by?.name
                  ? `por ${file.created_by?.name
                      .split(' ')
                      .map((word: string) => {
                        return (
                          word.charAt(0).toUpperCase() +
                          word.slice(1).toLowerCase()
                        )
                      })
                      .join(' ')}`
                  : ''}
              </SupportTextNew>
            </div>
            {showStatus && (
              <div>
                <SupportTextNew color="neutral600" size="xxsmall">
                  Validação
                </SupportTextNew>
                <SupportTextNew color="neutral900" size="xsmall">
                  {getStatusText(
                    file.status,
                    file.status_updated_by?.name
                      ? file.status_updated_by?.name
                      : 'nome não informado'
                  )}
                </SupportTextNew>
              </div>
            )}
          </S.DocumentDetails>
        </div>
      ))}

      {document.type === 'Laudos dos exames' && reportsRecommendations && (
        <SupportTextNew color="neutral900" size="xsmall">
          {reportsRecommendations}
        </SupportTextNew>
      )}

      {!reportsRecommendations && uploadButton && onUploadFile && (
        <UploadButtonNew
          onInput={onUploadFile}
          name={document.type}
          outlined
          multiple={multiple}
        >
          Incluir documento
        </UploadButtonNew>
      )}
    </AccordionCardNew>
  )
}

export default DocumentAccordeon
