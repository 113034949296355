import styled from 'styled-components'
import CardNew from 'presentation/shared/components/CardNew'

export const StatusCard = styled(CardNew)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  svg {
    height: 14px;
    width: 14px;
    path {
      fill: ${({ theme }) => theme.ds.colors.secondary700};
    }
  }
`

export const TabsWrapper = styled.div`
  padding-bottom: 16px;
`
