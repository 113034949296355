import React from 'react'
import * as S from './styles'
import { ReactComponent as PendencyIcon } from 'presentation/assets/icons/pendency-icon.svg'
import { ReactComponent as RightArrow } from 'presentation/assets/icons/v-arrow.svg'
import CardNew from 'presentation/shared/components/CardNew'
import DividerNew from 'presentation/shared/components/DividerNew'
import SupportTextNew from 'presentation/shared/components/SupportTextNew'
import moment from 'moment'
import theme from 'presentation/styles/theme'
import {
  convertNameToTitleCase,
  getShortName
} from 'presentation/utils/get-short-name'
import { Profile } from 'common/enum/profile'

export enum PendencyTypes {
  HELP_CARD = 'HELP_CARD',
  AUTHORIZED = 'AUTHORIZED'
}

export enum PendencyDictionary {
  HELP_CARD = 'Preciso de ajuda',
  AUTHORIZED = 'Pendência'
}

export type ProcedurePendencyCardProps = {
  pendency: any
  onClick?: () => void
}

const ProcedurePendencyCardNew = ({
  pendency,
  onClick
}: ProcedurePendencyCardProps) => {
  const getPendencyType = (description?: string) => {
    if (pendency.type === 'HELP_CARD') {
      switch (pendency.status) {
        case 'PENDENT':
          return {
            color: 'terciary400',
            colorLight: 'terciary200',
            colorDarker: 'terciary800',
            description: description
          }
        case 'RESOLVED':
          return {
            color: 'primary400',
            colorLight: 'primary200',
            colorDarker: 'primary800',
            description: description
          }
        case 'IN_PROGRESS':
        default:
          return {
            color: 'secondary400',
            colorLight: 'secondary200',
            colorDarker: 'secondary800',
            description: description
          }
      }
    } else
      switch (pendency.status) {
        case 'PENDENT':
          return {
            color: 'terciary400',
            colorLight: 'terciary200',
            colorDarker: 'terciary800',
            description: 'Você possui uma pendência para resolver.'
          }
        case 'RESOLVED':
          return {
            color: 'primary400',
            colorLight: 'primary200',
            colorDarker: 'primary800',
            description: 'Pendência resolvida'
          }
        case 'IN_PROGRESS':
        default:
          return {
            color: 'secondary400',
            colorLight: 'secondary200',
            colorDarker: 'secondary800',
            description:
              'Esta pendência foi respondida. Aguarde o retorno da CRMO.'
          }
      }
  }

  const title =
    pendency?.type === PendencyTypes.HELP_CARD
      ? `Preciso de ajuda ${('000' + pendency.surgical_pendency_order_id).slice(
          -3
        )}`
      : `Pendência ${('000' + pendency.surgical_pendency_order_id).slice(-3)}`

  return (
    <CardNew shadow="satin" background="neutral25" onClick={onClick && onClick}>
      <S.CardHeader>
        <S.CardTitle
          color={getPendencyType().color as keyof typeof theme.ds.colors}
        >
          <PendencyIcon />
          <SupportTextNew weight="semiBold" color="neutral900">
            {title}
          </SupportTextNew>
        </S.CardTitle>

        <RightArrow />
      </S.CardHeader>

      <DividerNew marginTop="8px" marginBottom="12px" />

      <SupportTextNew color="neutral700" style={{ marginBottom: '8px' }}>
        {
          getPendencyType(
            pendency.historic[pendency.historic.length - 1].description
          ).description
        }
      </SupportTextNew>

      <S.Quote
        color={getPendencyType().colorLight as keyof typeof theme.ds.colors}
      >
        <SupportTextNew>
          <q>{pendency.historic[pendency.historic.length - 1].message}</q>
        </SupportTextNew>
      </S.Quote>

      <div style={{ marginTop: '8px' }}>
        {pendency.historic[0].profile === Profile.DOCTOR.toUpperCase() && (
          <SupportTextNew
            color={
              getPendencyType().colorDarker as keyof typeof theme.ds.colors
            }
            weight="bold"
            size="xsmall"
            as="span"
          >
            Dr(a)
          </SupportTextNew>
        )}{' '}
        <SupportTextNew
          color={getPendencyType().colorDarker as keyof typeof theme.ds.colors}
          weight="bold"
          size="xxsmall"
          as="span"
          capitalize
        >
          Última mensagem
        </SupportTextNew>
        <SupportTextNew size="xxsmall">
          {pendency.historic[pendency.historic.length - 1].profile ===
            Profile.DOCTOR.toUpperCase() && 'Dr(a) '}
          {getShortName(
            convertNameToTitleCase(
              pendency.historic[
                pendency.historic.length - 1
              ].responsible.toLowerCase()
            )
          )}{' '}
          em{' '}
          {moment
            .utc(pendency.historic[pendency.historic.length - 1].createdAt)
            .format('DD [de] MMM[. de] YYYY [às] HH[h]mm')}
        </SupportTextNew>
        <SupportTextNew
          color={getPendencyType().colorDarker as keyof typeof theme.ds.colors}
          weight="bold"
          size="xxsmall"
          as="span"
          capitalize
        >
          Criação da pendência
        </SupportTextNew>
        <SupportTextNew size="xxsmall">
          {pendency?.responsible &&
            getShortName(
              convertNameToTitleCase(pendency?.responsible?.toLowerCase())
            )}{' '}
          em{' '}
          {moment
            .utc(pendency?.createdAt)
            .format('DD [de] MMM[. de] YYYY [às] HH[h]mm')}
        </SupportTextNew>
      </div>
    </CardNew>
  )
}

export default ProcedurePendencyCardNew
