import { CreatinineClearanceType } from 'domain/entities/proposed-protocol-model'

export const creatinineClearanceOptions = [
  {
    label: 'Creatinina',
    value: CreatinineClearanceType.CREATININE
  },
  {
    label: 'Clearance',
    value: CreatinineClearanceType.CLEARANCE
  }
]
