import React, { createContext, useReducer } from 'react'
import { Switch, Route, useParams, useRouteMatch } from 'react-router-dom'
// import PrivateRoute from 'presentation/shared/components/PrivateRoute'
import SurgicalSchedulingInformations from 'presentation/doctor/pages/SurgicalSchedulingInformations'
import SurgicalSchedulingAdvertising from 'presentation/doctor/pages/SurgicalSchedulingAdvertising'
import SurgicalSchedulingIEquipaments from 'presentation/doctor/pages/SurgicalSchedulingEquipaments'
import SurgicalSchedulingDuration from 'presentation/doctor/pages/SurgicalSchedulingDuration'
import SurgicalSchedulingAnesthesia from 'presentation/doctor/pages/SurgicalSchedulingAnesthesia'
import SurgicalSchedulingAppointments from 'presentation/doctor/pages/SurgicalSchedulingAppointments'
import SurgicalSchedulingComments from 'presentation/doctor/pages/SurgicalSchedulingComments'
import SurgicalSchedulingSummary from 'presentation/doctor/pages/SurgicalSchedulingSummary'
import SurgicalSchedulingFinish from 'presentation/doctor/pages/SurgicalSchedulingFinish'
import { EquipamentModel } from 'domain/usecases/surgical-scheduling/search-equipaments'
import { AnesthesistModel } from 'domain/usecases/surgical-scheduling/search-anesthetists'
import { SurgicalOrderModel } from 'domain/entities/surgical-order-model'
import {
  SendSurgicalSchedulingModel,
  AppointmentModel
} from 'domain/usecases/surgical-scheduling/send-surgical-scheduling'

export const SurgicalSchedulinhContext = createContext({})

type Types =
  | 'START'
  | 'INFORMATIONS'
  | 'SURGICAL_ORDER'
  | 'EQUIPAMENTS'
  | 'ANESTHESIA'
  | 'TIME'
  | 'APPOINTMENTS'
  | 'COMMENT'

type Payload =
  | number
  | AnesthesistModel[]
  | EquipamentModel[]
  | AppointmentModel[]
  | SurgicalOrderModel
  | { general_observation: string }
  | SendSurgicalSchedulingModel
  | any

export type Actions = {
  payload: Payload
  type: Types
}

export type ContextType = {
  dispatch: (params: Actions) => void
  state: SendSurgicalSchedulingModel
}

const reducer = (state: SendSurgicalSchedulingModel, action: Actions) => {
  switch (action.type) {
    case 'INFORMATIONS':
      return { ...state, ...action.payload }
    case 'SURGICAL_ORDER':
      return {
        ...state,
        surgical_order: {
          ...state.surgical_order,
          ...action.payload
        }
      }
    case 'EQUIPAMENTS':
      return { ...state, equipaments: action.payload }
    case 'ANESTHESIA':
      return { ...state, anesthetist: action.payload }
    case 'TIME':
      return { ...state, time: action.payload }
    case 'APPOINTMENTS':
      return { ...state, appointments: action.payload }
    case 'COMMENT':
      return { ...state, general_observation: action.payload }
    default:
      return state
  }
}

export default function makeSurgicalSchedulingPages() {
  const { path } = useRouteMatch()
  const [state, dispatch] = useReducer(
    reducer,
    {} as SendSurgicalSchedulingModel
  )

  return (
    <>
      <Switch>
        <SurgicalSchedulinhContext.Provider value={{ state, dispatch }}>
          <Route path={`${path}/:step`} component={Step} />
        </SurgicalSchedulinhContext.Provider>
      </Switch>
    </>
  )
}

function Step() {
  const { step } = useParams<{ step: string }>()

  switch (step) {
    case 'inicio':
      return <SurgicalSchedulingAdvertising />
    case 'equipamentos':
      return <SurgicalSchedulingIEquipaments />
    case 'anestesia':
      return <SurgicalSchedulingAnesthesia />
    case 'informacoes':
      return <SurgicalSchedulingInformations />
    case 'duracao':
      return <SurgicalSchedulingDuration />
    case 'data-e-hora':
      return <SurgicalSchedulingAppointments />
    case 'observacoes':
      return <SurgicalSchedulingComments />
    case 'resumo':
      return <SurgicalSchedulingSummary />
    case 'final':
      return <SurgicalSchedulingFinish />
    default:
      return <SurgicalSchedulingAdvertising />
  }
}
