import styled from 'styled-components'

export const FormWrapper = styled.form`
  display: flex;
  flex-direction: column;
  margin-top: 24px;
  flex: 1;
  gap: 16px;
  padding-bottom: 24px;

  & > div:not(:first-child) {
    margin-top: 8px;
  }
`

export const ButtonsWrapper = styled.div`
  padding: 24px 24px 0 24px;
  margin: 0 -24px;
  box-shadow: 0px -2px 3px 1px rgba(112, 118, 131, 0.12);
  z-index: 1;

  & > button:not(:first-child) {
    margin-top: 16px;
  }
`

export const ModalWrapper = styled.div``

export const Title = styled.span`
  font-size: ${({ theme }) => theme.ds.typography.size.medium};
  font-weight: ${({ theme }) => theme.ds.typography.weight.semiBold};
  color: ${({ theme }) => theme.ds.colors.neutral900};
  display: flex;
  align-items: center;
  justify-content: space-between;

  svg {
    width: 12px;
    path {
      fill: ${({ theme }) => theme.ds.colors.neutral500};
    }
  }
`

export const Complement = styled.span`
  font-size: ${({ theme }) => theme.ds.typography.size.small};
`

export const ComplementBold = styled.p`
  font-weight: ${({ theme }) => theme.ds.typography.weight.semiBold};
`

export const ModalButtons = styled.div`
  margin-top: 40px;

  button + button {
    margin-top: 12px;
  }
`
