import React, { useState, useContext } from 'react'
import SurgicalSchedulingAnesthesiaLayout from 'presentation/doctor/layouts/SurgicalSchedulingAnesthesia'
import { useServices } from 'presentation/hooks/use-services'
import { AnesthesistModel } from 'domain/usecases/surgical-scheduling/search-anesthetists'
import { SurgicalSchedulinhContext } from 'main/factories/pages/doctor/surgical-scheduling/surgical-scheduling-factory'
import HeaderNew from 'presentation/shared/components/HeaderNew'

function SurgicalSchedulingAnesthesia() {
  const { dispatch, state } = useContext<any>(SurgicalSchedulinhContext)
  const surgicalSchedulingService = useServices().surgicalScheduling
  const [anesthetistsList, setAnesthetistsList] = useState<AnesthesistModel[]>(
    [] as AnesthesistModel[]
  )

  const searchAnesthetists = async (name: string) => {
    const response = await surgicalSchedulingService.searchAnesthetists({
      name,
      page: String(1),
      limit: String(999)
    })

    setAnesthetistsList(response.data)
  }

  return (
    <>
      <HeaderNew actualPageTitle="Agendar cirurgia" />
      <SurgicalSchedulingAnesthesiaLayout
        anesthetistsList={anesthetistsList}
        searchAnesthetists={searchAnesthetists}
        dispatch={dispatch}
        anesthesia={state.anesthetist}
      />
    </>
  )
}

export default SurgicalSchedulingAnesthesia
