import styled, { css } from 'styled-components'
import theme from '../../../styles/theme'
import Divider from 'presentation/shared/components/DividerNew'

export type TagProps = {
  color?: keyof typeof theme.ds.colors
}

export type HeaderProps = {
  color?: keyof typeof theme.ds.colors
}

export const Wrapper = styled.div<any>`
  ${({ theme, color }) => css`
    min-width: 293px;
    width: 100%;
    border: 1px solid ${theme.ds.colors?.[color!]};
    border-radius: 4px;
    margin-bottom: 15px;
  `}
`

export const CardBody = styled.div<any>`
  ${({ withHeight }) => css`
    display: flex;
    background-color: ${theme.ds.colors.white};
    justify-content: space-between;
    padding: 15px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.2);
    min-height: ${withHeight ? '500px' : '0px'};
  `}
`

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;

  h2 {
    margin-bottom: 6px;
  }
`

export const Header = styled.div<any>`
  ${({ theme, color }) => css`
    background-color: ${theme.ds.colors?.[color!]};
    justify-content: space-between;
    padding: 16px 13px 1px 13px;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  `}
`

export const HeaderInfo = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  p {
    white-space: nowrap;
    margin-right: 8px;
  }
`

export const Tag = styled.div<any>`
  ${({ theme, color }) => css`
    background-color: ${theme.ds.colors?.[color!]};
    height: 31px;
    color: ${theme.ds.colors.white};
    border-radius: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px;

    font-size: ${theme.ds.typography.size.xxsmall};
    font-weight: ${theme.ds.typography.weight.semiBold};
    text-wrap: nowrap;
  `}
`

export const DividerColor = styled(Divider)<any>`
  ${({ theme, color }) => css`
    background-color: ${theme.ds.colors?.[color!]};
  `}
`
