import { ReactComponent as SearchIcon } from 'presentation/assets/icons/search.svg'
import TextField from 'presentation/shared/components/TextField'
import React from 'react'
import * as S from './styles'

export default function ProposedProtocolStepTwo() {
  return (
    <>
      <S.FormContentStepTwo>
        <S.Inputs width="100%">
          <TextField
            label="Nome ou sigla"
            required
            icon={
              <S.Icon>
                <SearchIcon width="29px" height="29px" />
              </S.Icon>
            }
            iconPosition="right"
            iconLocale="outside"
            name={'protocol.description'}
          />
        </S.Inputs>
        <S.CycleInputs>
          <TextField
            label="Intervalo de ciclos"
            name="cycleInterval"
            type="number"
            required
          />
          <TextField
            label="Ciclo atual"
            name="currentCycle"
            type="number"
            required
          />
          <TextField
            label="Nº. Total Ciclos"
            name="totalCycles"
            type="number"
            required
          />
        </S.CycleInputs>
        <S.Inputs width="70%">
          <TextField
            label="Linha de tratamento"
            name="treatmentLine"
            required
          />
        </S.Inputs>
      </S.FormContentStepTwo>
    </>
  )
}
