import React, { useContext, useEffect, useState } from 'react'
import AvatarCountersListNew from 'presentation/shared/components/AvatarCountersListNew'
import { SurgicalOrderDashboardContext } from 'main/factories/pages/doctor/surgical-order/surgical-order-dashboard-factory'
import * as S from './styles'
// import AutoCompleteNew from 'presentation/shared/components/AutoCompleteNew'
import TextFieldNew from 'presentation/shared/components/TextFieldNew'
import { ContainerNew } from 'presentation/shared/components/ContainerNew'
import { ReactComponent as SearchIcon } from 'presentation/assets/icons/search2.svg'
import SupportTextNew from 'presentation/shared/components/SupportTextNew'
import AvatarNew from 'presentation/shared/components/AvatarNew'
import SurgicalOrderCard from 'presentation/shared/layouts/SurgicalOrderCard'
import SelectFieldNew from 'presentation/shared/components/SelectFieldNew'
import theme from 'presentation/styles/theme'
// import {
//   PendencieTypes,
//   LoadSurgicalOrdersByPendencyStatus
// } from 'domain/usecases/surgical-pendency/load-surgical-orders-by-pendency-status'
import ButtonNew from 'presentation/shared/components/ButtonNew'
import { ReactComponent as EmptyStateIcon } from 'presentation/assets/icons/empty-state-sad.svg'
import { useHistory } from 'react-router'
import {
  SchedulingStatusTypes,
  OrderStatusTypes,
  GetSurgicalSchedulesByDoctor
} from 'domain/usecases/surgical-order/get-surgical-schedules-by-doctor'

type Props = {
  initialStatus?: SchedulingStatusTypes
  getSchedulingsList: (params: GetSurgicalSchedulesByDoctor.Params) => void
}
export default function SurgicalOrdersSchedulingLayout({
  initialStatus,
  getSchedulingsList
}: Props) {
  const { state } = useContext<any>(SurgicalOrderDashboardContext)
  const [activeSchedulingStatusType, setActiveSchedulingStatusType] =
    useState<SchedulingStatusTypes>(initialStatus || 'UNSCHEDULED')
  const [searchTerm, setSearchTerm] = useState('')
  const [statusFilter, setStatusFilter] = useState<
    OrderStatusTypes | undefined
  >(undefined)
  const history = useHistory()

  useEffect(() => {
    setActiveSchedulingStatusType(initialStatus || 'UNSCHEDULED')
  }, [])

  const statusTypes = () => [
    {
      key: 'UNSCHEDULED',
      label: 'Não agendados',
      description: 'Agende agora uma data para a sua cirurgia.',
      color: 'terciary400' as keyof typeof theme.ds.colors,
      active: activeSchedulingStatusType === 'UNSCHEDULED',
      action: () => changeSchedulingStatusType('UNSCHEDULED')
    },
    {
      key: 'UNDER_ANALYSIS',
      label: 'Agendamento em análise',
      description: 'Acompanhe suas solicitações de agendamento',
      color: 'secondary400' as keyof typeof theme.ds.colors,
      active: activeSchedulingStatusType === 'UNDER_ANALYSIS',
      action: () => changeSchedulingStatusType('UNDER_ANALYSIS')
    },
    {
      key: 'SCHEDULED',
      label: 'Agendados',
      description: 'Acompanhe suas cirurgias agendadas',
      color: 'primary400' as keyof typeof theme.ds.colors,
      active: activeSchedulingStatusType === 'SCHEDULED',
      action: () => changeSchedulingStatusType('SCHEDULED')
    }
  ]

  const filterItems = () => [
    {
      label: 'Todos os pedidos',
      value: 'ALL'
    },
    {
      label: 'Autorizados',
      value: 'AUTHORIZED'
    },
    {
      label: 'Em análise no hospital',
      value: 'UNDER_REVIEW_AT_THE_HOSPITAL'
    },
    {
      label: 'Em análise no convênio',
      value: 'UNDER_REVIEW_AT_THE_INSURANCE'
    }
  ]

  const getCounterType = () => {
    return statusTypes().find((type) => {
      return type.key === activeSchedulingStatusType
    })
  }

  const changeSchedulingStatusType = (type: SchedulingStatusTypes) => {
    if (activeSchedulingStatusType !== type) {
      setSearchTerm('')
      setStatusFilter(undefined)
      setActiveSchedulingStatusType(type)
      // searchSchedulings()
      getSchedulingsList({ schedulingStatus: type, pageNumber: 1 })
    }
  }

  const changeFilterValue = (value: OrderStatusTypes) => {
    setStatusFilter(value)
    // searchSchedulings()
    getSchedulingsList({
      schedulingStatus: activeSchedulingStatusType,
      status: value,
      search: searchTerm,
      pageNumber: 1
    })
  }

  const searchSchedulings = (nextPage = false) => {
    getSchedulingsList({
      schedulingStatus: activeSchedulingStatusType,
      status: statusFilter,
      search: searchTerm,
      pageNumber: nextPage ? state.pageInfo.current_page + 1 : 1
    })
  }

  const isLastPage = () => {
    return state.pageInfo?.current_page === state.pageInfo?.total_pages
  }

  const goToSurgicalOrder = (surgical_order_id: number) => {
    // const nextRoute =
    //   activeSchedulingStatusType === 'UNSCHEDULED'
    //     ? '/agendamento-cirurgico/inicio'
    //     : '/pedido-cirurgico/detalhes'
    history.push(
      // nextRoute
      `/agendamento-cirurgico/${
        activeSchedulingStatusType === 'UNSCHEDULED' ? 'inicio' : 'detalhes'
      }`,
      { surgical_order_id }
    )
  }

  const onEnterSearch = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.keyCode === 13) {
      searchSchedulings()
    }
  }

  return (
    <ContainerNew gap="32px" noHeader>
      <AvatarCountersListNew
        title="Agendamento"
        subtitle="Acompanhe os seus agendamentos"
        types={statusTypes()}
        counts={state.schedulingsCount}
        disableHoverOnActive
      />
      <TextFieldNew
        icon={<SearchIcon />}
        iconIsButton
        placeholder="Paciente, N° do aviso"
        value={searchTerm}
        onInputChange={setSearchTerm}
        onIconClick={() => searchSchedulings()}
        onKeyDown={onEnterSearch}
      />
      <SelectFieldNew
        value={statusFilter}
        onInputChange={(val) => changeFilterValue(val as OrderStatusTypes)}
        items={filterItems()}
      />
      <S.PendenciesContainer>
        <S.PendenciesHeader>
          <AvatarNew
            // text={state.schedulingsCount[activeSchedulingStatusType]}
            text={state.pageInfo?.total_items}
            color={
              (getCounterType()?.color as keyof typeof theme.ds.colors) ||
              'primary400'
            }
            active
          />
          <SupportTextNew weight="bold" color="neutral700" size="medium">
            {getCounterType()?.label}
          </SupportTextNew>
        </S.PendenciesHeader>
        <SupportTextNew>{getCounterType()?.description}</SupportTextNew>
        {state.surgicalOrdersList?.length ? (
          <>
            {state.surgicalOrdersList?.map((surgicalOrder: any) => {
              return (
                <SurgicalOrderCard
                  schedulingDate
                  procedures
                  statusTag
                  key={surgicalOrder.surgical_order_id}
                  surgicalOrder={surgicalOrder}
                  footerColor={getCounterType()?.color}
                  onClick={goToSurgicalOrder}
                />
              )
            })}
            <ButtonNew
              variant="text"
              onClick={() => searchSchedulings(true)}
              disabled={isLastPage()}
            >
              {isLastPage() ? 'Fim' : 'Ver mais'}
            </ButtonNew>
          </>
        ) : (
          <S.EmptyState>
            <EmptyStateIcon />
            <SupportTextNew color="neutral400" size="medium">
              Nenhum pedido cirurgico encontrado
            </SupportTextNew>
          </S.EmptyState>
        )}
      </S.PendenciesContainer>
    </ContainerNew>
  )
}
