import React from 'react'
import { Container } from 'presentation/shared/components/Container'
import { useStores } from 'presentation/hooks/use-stores'
import FormAcceptUserTerms from 'presentation/shared/components/Forms/AcceptUserTerms'
import * as S from './styles'

const UserTermsLayout = () => {
  const stores = useStores()
  const currentUser = stores.currentAccount
  const pendingTerms = currentUser
    ?.getCurrentAccount()
    ?.user?.terms?.filter((term) => term.signed === false)

  return (
    <>
      <Container noPadding>
        <S.Wrapper>
          {/* Put on a carousel in the future */}
          {pendingTerms?.reverse()?.map((term, index) => (
            <div className="form-wrapper" key={index}>
              <FormAcceptUserTerms index={index} termName={term.name} />
            </div>
          ))}
        </S.Wrapper>
      </Container>
    </>
  )
}

export default UserTermsLayout
