import { makeAutoObservable } from 'mobx'
import { Patient } from 'domain/entities/patient-model'
import { CreateSurgicalOrderFormValues } from 'presentation/doctor/pages/AddSurgicalOrder_old/CreateSurgicalOrder'

export class CurrentSurgery {
  constructor() {
    makeAutoObservable(this)
  }

  surgeryIdSelected = 0
  newSurgicalOrder = {} as CreateSurgicalOrderFormValues
  surgeryPatient = {} as Patient

  getSurgery() {
    return this.newSurgicalOrder
  }

  setSurgery(newSurgery: CreateSurgicalOrderFormValues) {
    this.newSurgicalOrder = newSurgery
  }

  getSurgeryIdSelected() {
    return this.surgeryIdSelected
  }

  getSurgeryPatient() {
    return this.surgeryPatient
  }

  setSurgeryPatient(surgeryPatientData: Patient) {
    this.surgeryPatient = surgeryPatientData
  }

  setSurgeryIdSelected(surgeryIDSelected: number) {
    this.surgeryIdSelected = surgeryIDSelected
  }
}

export default CurrentSurgery
