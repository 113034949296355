import addSurgicalOrderIcon from 'presentation/assets/icons/add-surgical-order.svg'
// TO-DO: uncomment when budget request is live
// import requestBudgetIcon from 'presentation/assets/icons/budgets-light.svg'
// TO-DO: uncomment when exams and surgicals is live
// import examsAndSurgicalsIcon from 'presentation/assets/icons/exams-and-surgicals-light.svg'
// TO-DO: uncomment when service solicitation is live
// import serviceOrderIcon from 'presentation/assets/icons/service-order-light.svg'
// TO-DO: uncomment when Emergency Room Attendance is live
// import requestHelpcon from 'presentation/assets/icons/request-help.svg'
import makeAnAppointment from 'presentation/assets/icons/exams-and-surgicals-light.svg'
import doctorIcon from 'presentation/assets/icons/doctor-icon.svg'
import addExam from 'presentation/assets/icons/add-exam.svg'

export type OptionsType = {
  link: string
  label: string
  icon: string
  clearSurgicalOrderContext?: boolean
}

type ProfilesType = {
  doctor: OptionsType[]
  patient: OptionsType[]
  admin: OptionsType[]
  secretary: OptionsType[]
  crmo: OptionsType[]
  hospitalization: OptionsType[]
  ambulatory: OptionsType[]
  oncology: OptionsType[]
  doctor_register: OptionsType[]
  director_doctor_register: OptionsType[]
  admin_sectorial_crmo: OptionsType[]
  emergency_room: OptionsType[]
}

const profiles: ProfilesType = {
  doctor: [
    {
      link: '/novo-pedido/local',
      clearSurgicalOrderContext: true,
      label: 'Novo pedido cirúrgico',
      icon: doctorIcon
    },
    // TO-DO: uncomment when therapeutic plan is live
    // {
    //   link: '/novo-plano-terapeutico/selecionar-paciente',
    //   label: 'Novo plano terapêutico',
    //   icon: doctorIcon
    // },
    // TO-DO: uncomment when doctor channel is live
    // {
    //   link: '/home',
    //   label: 'Canal médico',
    //   icon: doctorIcon
    // },
    {
      link: '/vincular-profissional',
      label: 'Vincular profissional',
      icon: doctorIcon
    }
  ],
  patient: [
    {
      link: '/pedido--cirurgico',
      label: 'Solicitar pedido cirúrgico',
      icon: addSurgicalOrderIcon
    },
    {
      link: '',
      label: 'Marcar consulta',
      icon: makeAnAppointment
    },
    {
      link: '/novo-exame',
      label: 'Agendar exame',
      icon: addExam
    }
    // TO-DO: uncomment when budget request is live
    // {
    //   link: '/home',
    //   label: 'Solicitar orçamento',
    //   icon: requestBudgetIcon
    // },
    // TO-DO: uncomment when exams and surgical is live
    // {
    //   link: '/vincular-profissional',
    //   label: 'Consultas e exames',
    //   icon: examsAndSurgicalsIcon
    // }
    // TO-DO: uncomment when service solicitation is live
    // {
    //   link: '/vincular-profissional',
    //   label: 'Solicitar serviço',
    //   icon: serviceOrderIcon
    // }
    // TO-DO: uncomment when Emergency Room Attendance is live
    // {
    //   link: '/solicitar-atendimento',
    //   label: 'Solicitar pronto socorro',
    //   icon: requestHelpcon
    // }
  ],
  admin: [],
  secretary: [],
  crmo: [],
  hospitalization: [],
  ambulatory: [],
  oncology: [],
  doctor_register: [],
  director_doctor_register: [],
  admin_sectorial_crmo: [],
  emergency_room: []
}

export default profiles
