import React from 'react'
import * as S from './styles'
import AvatarNew, { AvatarTextProps } from '../AvatarNew'
import SupportTextNew, { SupportTextProps } from '../SupportTextNew'
import { CardProps } from '../CardNew'
import { ReactComponent as RightArrowIcon } from 'presentation/assets/icons/v-arrow.svg'

type Props = {
  title: string
  subtitle?: string
  weight?: string
} & CardProps &
  AvatarTextProps &
  SupportTextProps

export default function ActionCardNew({
  title,
  subtitle,
  color,
  size,
  text,
  weight = 'semiBold',
  active = true,
  ...props
}: Props) {
  return (
    <S.ActionCard color={color} {...props}>
      {text && (
        <AvatarNew text={text} color={color} size={size} active={active} />
      )}
      <div>
        <SupportTextNew size="large" weight={weight} className="title">
          {title}
        </SupportTextNew>
        {subtitle && <SupportTextNew>{subtitle}</SupportTextNew>}
      </div>
      <RightArrowIcon />
    </S.ActionCard>
  )
}
