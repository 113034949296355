import styled from 'styled-components'

export const List = styled.div`
  background-color: white;
  border-radius: ${({ theme }) => theme.border.radius.small};
  li {
    flex-direction: row-reverse;
  }

  img.icon {
    width: 28px;
  }
`

export const Card = styled.div`
  width: 100%;
  background-color: white;
  border-radius: ${({ theme }) => theme.border.radius.small};
  padding: 22px 16px;
  margin-top: 20px;

  & header {
    & h6 {
      line-height: 21px;
      color: ${({ theme }) => theme.colors.primary};
      font-size: ${({ theme }) => theme.font.sizes.xsmall};
      font-weight: ${({ theme }) => theme.font.semiBold};
    }
    & span {
      line-height: 21px;
      display: block;
      text-align: left;
      margin-bottom: 20px;
      color: ${({ theme }) => theme.colors.primary};
      font-size: ${({ theme }) => theme.font.sizes.medium};
      font-weight: ${({ theme }) => theme.font.black};
    }
  }

  & > div {
    display: grid;
    grid-template-columns: auto 4fr;
    font-size: ${({ theme }) => theme.font.sizes.xsmall};

    & img {
      grid-row: span 2;
      align-self: center;
      margin-right: 10px;
    }
    & span {
      justify-self: flex-start;
      align-self: flex-end;
      color: ${({ theme }) => theme.colors.primary};
    }
    & p {
      color: ${({ theme }) => theme.colors.gray};
      font-weight: ${({ theme }) => theme.font.semiBold};
    }
  }
`
