import { ApiTracksaleRepository } from 'repository/api-tracksale-repository'
import { ApiResponse } from 'service/protocols/api-tracksale/api-tracksale-client'
import {
  IApiTracksaleRepository,
  RepositoryRequest
} from 'service/protocols/api-tracksale/api-tracksale-repository'
import { GetStorage } from 'service/protocols/cache/get-storage'

export class PrepareApiRequestTracksaleDecorator
  implements IApiTracksaleRepository
{
  constructor(
    private readonly getStorage: GetStorage,
    private readonly apiTracksaleRepository: ApiTracksaleRepository
  ) {}

  async post(data: RepositoryRequest): Promise<ApiResponse> {
    // const account = this.getStorage.get('account')
    // if (account?.accessToken) {
    //   Object.assign(data, {
    //     headers: Object.assign(data.headers || {}, {
    //       authorization: 'Bearer ' + account.accessToken
    //     })
    //   })
    // }
    data.url = `${process.env.REACT_APP_API_TRACKSALE_URL + data.url}`

    return await this.apiTracksaleRepository.post(data)
  }

  // async get(data: RepositoryRequest): Promise<ApiResponse> {
  //   const account = this.getStorage.get('account')
  //   if (account?.accessToken) {
  //     Object.assign(data, {
  //       headers: Object.assign(data.headers || {}, {
  //         authorization: 'Bearer ' + account.accessToken
  //       })
  //     })
  //   }
  // data.url =
  //   process.env.NODE_ENV === 'production'
  //     ? `/${data.url}`
  //     : `${process.env.REACT_APP_API_TRACKSALE_URL + data.url}`

  //   return await this.apiTracksaleRepository.get(data)
  // }

  // async put(data: RepositoryRequest): Promise<ApiResponse> {
  //   const account = this.getStorage.get('account')
  //   if (account?.accessToken) {
  //     Object.assign(data, {
  //       headers: Object.assign(data.headers || {}, {
  //         authorization: 'Bearer ' + account.accessToken
  //       })
  //     })
  //   }
  //   data.url =
  //     process.env.NODE_ENV === 'production'
  //       ? `/${data.url}`
  //       : `${process.env.REACT_APP_API_TRACKSALE_URL + data.url}`

  //   return await this.apiTracksaleRepository.put(data)
  // }
}
