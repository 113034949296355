import styled from 'styled-components'

export const HeaderContainer = styled.div`
  display: flex;
  text-align: center;
  gap: 8px;
  word-break: break-word;
  justify-content: center;
  align-items: center;
`

export const Container = styled.div`
  width: 100%;
  color: ${({ theme }) => theme.colors.gray};
  display: flex;
  flex-direction: column;
  gap: 10px;
  flex: 1;
  overflow-y: auto;
  word-break: normal;
  text-align: left;
`

export const ContainerData = styled.div`
  max-height: 100%;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
`

export const Item = styled.div`
  span {
    font-weight: 500;
    color: ${({ theme }) => theme.ds.colors.neutral800};
    font-size: ${({ theme }) => theme.ds.typography.size.xxsmall};
    line-height: 15px;
  }

  p {
    color: ${({ theme }) => theme.ds.colors.neutral900};
    font-size: ${({ theme }) => theme.ds.typography.size.small};
    line-height: ${({ theme }) => theme.ds.typography.lineHeight.medium};
  }
`

export const Divider = styled.div`
  position: relative;
  display: block;
  width: 100%;
  border-top: 1px solid ${({ theme }) => theme.ds.colors.neutral100};
`
