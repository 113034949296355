import { FinishSurgicalOrder } from 'domain/usecases/surgical-order/finish-surgical-order'
import { SurgicalOrderRepository } from 'repository/repositories/surgical-order/surgical-order-repository'

export class RemoteFinishSurgicalOrder implements FinishSurgicalOrder {
  constructor(
    private readonly surgicalOrderRepository: SurgicalOrderRepository
  ) {}

  post(params: FinishSurgicalOrder.Params): Promise<FinishSurgicalOrder.Model> {
    return this.surgicalOrderRepository.finishSurgicalOrder(params)
  }
}
