import { GetRecentAppointments } from 'domain/usecases/scheduling/get-recent-appointments'
import { SchedulingRepository } from 'repository/interfaces/scheduling-repository'

export class RemoteGetRecentAppointments implements GetRecentAppointments {
  constructor(private readonly schedulingRepository: SchedulingRepository) {}

  load(
    params: GetRecentAppointments.Params
  ): Promise<GetRecentAppointments.Model> {
    return this.schedulingRepository.getRecentAppointments(params)
  }
}

export type SearchDoctorsModel = GetRecentAppointments.Model
