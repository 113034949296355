import NewTherapeuticPlanHome from 'presentation/doctor/components/Forms/AddTherapeuticPlan/NewTherapeuticPlan/NewTherapeuticPlanHome'
import { Container } from 'presentation/shared/components/Container'
import Header from 'presentation/shared/components/Header'
import React from 'react'

export default function TherapeuticPlanResume() {
  return (
    <>
      <Header />
      <Container noPadding>
        <NewTherapeuticPlanHome />
      </Container>
    </>
  )
}
