import styled, { css } from 'styled-components'

export const PerformanceGrid = styled.div`
  margin-top: 24px;
  span {
    color: ${({ theme }) => theme.ds.colors.neutral700};
    font-size: ${({ theme }) => theme.ds.typography.size.xsmall};
    font-weight: normal;
    display: flex;
    align-items: center;
    gap: 4px;
  }
`
export const CardContainer = styled.div`
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
  margin-top: 24px;
  margin-bottom: 8px;

  > div {
    flex: 1;
  }
`
export const OtherIndicatorsContainer = styled.div`
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
  margin-bottom: 8px;

  > div {
    flex: 1;
  }
`
export const CardCaptionContainer = styled.div`
  display: inline-block;
  gap: 8px;
  margin-bottom: 24px;
`
export const CardCaption = styled.span`
  display: inline-block;
  color: ${({ theme }) => theme.ds.colors.neutral700};
  font-weight: normal;
  margin-right: 10px;

  svg {
    margin-right: 4px;
  }

  @media screen and (max-width: 768px) {
    font-size: 10px;
  }

  @media screen and (min-width: 769px) {
    font-size: 16px;
  }
`

export const CalendarWrapper = styled.div`
  z-index: 100;
  position: relative;
  /* border: 1px solid red; */
`

export const DateSelector = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;

  .btn {
    position: relative;
    width: 32px;
    height: 32px;
    outline: none;
    border: none;
    background-color: transparent;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    svg {
      width: 16.66px;
      height: 16.66px;
      color: ${({ theme }) => theme.ds.colors.neutral500};
      fill: ${({ theme }) => theme.ds.colors.neutral500};
    }

    &:last-child {
      justify-content: flex-end;

      svg {
        margin-right: -4px;
      }
    }

    &:disabled {
      cursor: not-allowed;
      opacity: 0.4;
    }
  }

  > .date {
    cursor: pointer;
    text-transform: capitalize;
    color: ${({ theme }) => theme.ds.colors.neutral900};
    font-weight: ${({ theme }) => theme.ds.typography.weight.semiBold};
    font-size: ${({ theme }) => theme.ds.typography.size.large};
  }
`

export const CalendarSelector = styled.div`
  ${() => css`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;

    .react-calendar {
      border-radius: 8px;
      box-shadow: 0px 8px 12px 6px #0000001f;

      button {
        height: 35px;
        border-radius: 8px;
        border: none 8px !important;

        /* MONTHS / YEARS */
        &.react-calendar__year-view__months__month,
        &.react-calendar__decade-view__years__year,
        &.react-calendar__century-view__decades__decade {
          border-radius: 8px;
        }
      }
    }
  `}
`
