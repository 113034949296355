import React, { useState, useCallback } from 'react'
import { toast } from 'react-toastify'
import TextFieldNew, {
  TextFieldProps
} from 'presentation/shared/components/TextFieldNew'
import { debounce } from 'lodash'
import LoadingGif from 'presentation/assets/icons/loading.gif'

import * as S from './styles'
import { ReactComponent as CloseIcon } from 'presentation/assets/icons/close.svg'
import ButtonNew from '../ButtonNew'

export type AutoCompleteProps = {
  suggestions?: { label: string; value: any }[]
  onSuggestionClick?: (value: any) => void
  onType?: (value: any) => void
  // onIconClick?: (value: any) => void
  onInputChange: (value: any) => void
  clear?: () => void
  debounceDelay?: number
  emptyState?: (closeFunc: (bool: boolean) => void) => React.ReactNode
  returnFullSuggestion?: boolean
} & TextFieldProps

const AutoCompleteNew = ({
  suggestions,
  onSuggestionClick,
  onType,
  // onIconClick,
  onInputChange,
  clear,
  debounceDelay = 500,
  emptyState,
  returnFullSuggestion = false,
  ...inputProps
}: AutoCompleteProps) => {
  const [showSuggestions, setShowSuggestions] = useState(false)
  const [loading, setLoading] = useState(true)

  const changeTrigger = async (userInput: string) => {
    if (userInput) {
      try {
        setLoading(true)
        setShowSuggestions(true)
        onType && (await onType(userInput))
      } catch (err: any) {
        toast.error(err.message)
      } finally {
        setLoading(false)
      }
    }
  }

  const debounced = useCallback(
    debounce((func, e) => func(e), debounceDelay),
    []
  )

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const userInput = e.target.value
    setShowSuggestions(false)
    onInputChange(userInput)
    debounced(changeTrigger, userInput)

    if (!userInput) {
      setShowSuggestions(false)
    }
  }

  const onItemClick = (
    event: React.MouseEvent<HTMLLIElement>,
    suggestion: any
  ) => {
    setShowSuggestions(false)
    try {
      const data = returnFullSuggestion ? suggestion : suggestion.value
      onSuggestionClick?.(data)
    } catch {
      return
    }
  }

  // const onIconClickHandle = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   const userInput = e.currentTarget.value
  //   setShowSuggestions(false)
  //   onIconClick(userInput)
  //   debounced(changeTrigger, userInput)

  //   if (!userInput) {
  //     setShowSuggestions(false)
  //   }
  // }

  const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    // User pressed the enter key
    if (e.keyCode === 13) {
      setShowSuggestions(false)
    }
  }

  const SuggestionsListComponent = () => {
    if (loading) {
      return (
        <S.Suggestions className="suggestions">
          <li style={{ textAlign: 'center' }}>
            <p>Buscando...</p>
            <img src={LoadingGif} alt="loading" />
          </li>
        </S.Suggestions>
      )
    }
    // eslint-disable-next-line react/prop-types
    if (suggestions?.length === 0 && !loading) {
      return (
        <S.Suggestions>
          {emptyState ? (
            <li className="empty-state">{emptyState(setShowSuggestions)}</li>
          ) : (
            <li style={{ textAlign: 'center' }}>Nenhum registro encontrado.</li>
          )}
        </S.Suggestions>
      )
    }
    return (
      <S.Suggestions className="suggestions">
        {/* eslint-disable-next-line react/prop-types */}
        {suggestions?.map((suggestion, index) => {
          return (
            <li
              key={index}
              data-testid={`doctor-autocomplete-doctor-${index}`}
              onClick={(e) => onItemClick(e, suggestion)}
            >
              {suggestion.label}
            </li>
          )
        })}
      </S.Suggestions>
    )
  }

  return (
    <S.Wrapper isOpen={showSuggestions}>
      <TextFieldNew
        className="input"
        type="text"
        onChange={onChange}
        onKeyDown={onKeyDown}
        autoComplete="off"
        {...inputProps}
        data-testid="autocomplete-tuss-field"
      />
      {showSuggestions && <SuggestionsListComponent />}
      {clear && inputProps.disabled && (
        <ButtonNew onClick={clear} className="clear-btn" type="button">
          <CloseIcon className="icon" />
        </ButtonNew>
      )}
    </S.Wrapper>
  )
}

export default AutoCompleteNew
