import React, { useState } from 'react'
import { ContainerNew } from 'presentation/shared/components/ContainerNew'
import ButtonNew from 'presentation/shared/components/ButtonNew'
import { useHistory } from 'react-router'
import RadioButtonNew from 'presentation/shared/components/RadioButtonNew'
import SupportTextNew from 'presentation/shared/components/SupportTextNew'
import AutoCompleteNew from 'presentation/shared/components/AutoCompleteNew'
import HeadingNew from 'presentation/shared/components/HeadingNew'
import { EquipamentModel } from 'domain/usecases/surgical-scheduling/search-equipaments'
import { ReactComponent as TrashIcon } from 'presentation/assets/icons/trash-icon.svg'
import { ReactComponent as SearchIcon } from 'presentation/assets/icons/search.svg'
import * as S from './styles'
import { booleans } from './enums'
import { useLocation } from 'react-router'

type Props = {
  equipamentsSuggestions: EquipamentModel[]
  searchEquipament: (term: string) => void
  dispatch: (action: { type: any; payload: any }) => void
  equipaments: EquipamentModel[]
}

export default function SurgicalSchedulingEquipamentsLayout({
  equipamentsSuggestions,
  searchEquipament,
  dispatch,
  equipaments
}: Props) {
  const [hasEquipaments, setHasEquipaments] = useState<boolean>(
    equipaments?.length > 0
  )
  const [equipamentsList, setEquipamentsList] = useState<EquipamentModel[]>(
    equipaments || ([] as EquipamentModel[])
  )
  const [equipamentsListBkp, setEquipamentsListBkp] = useState<
    EquipamentModel[]
  >([] as EquipamentModel[])
  const [searchTerm, setSearchTerm] = useState('')
  const history = useHistory()
  const location = useLocation<{ isReviewing?: boolean }>()

  const submitForm = () => {
    dispatch({
      type: 'EQUIPAMENTS',
      payload: equipamentsList
    })

    if (location.state?.isReviewing) {
      history.push('/agendamento-cirurgico/resumo')
    } else {
      history.push('/agendamento-cirurgico/anestesia')
    }
  }

  const addEquipament = (
    item: { value?: number; label: string },
    closeResults?: (bool: boolean) => void
  ) => {
    const newList = equipamentsList.concat({
      code: Number(item.value),
      name: item.label
    })
    setEquipamentsList(newList)
    setSearchTerm('')
    if (closeResults) closeResults(false)
  }

  const removeEquipament = (index: number) => {
    const newList = [...equipamentsList]
    newList.splice(index, 1)
    setEquipamentsList(newList)
  }

  const mappedSuggestions = () => {
    return equipamentsSuggestions.map((equipament) => ({
      label: equipament.name,
      value: equipament.code
    }))
  }

  const handleHasEquipament = (value: boolean) => {
    if (!value) {
      setEquipamentsListBkp(equipamentsList)
      setEquipamentsList([])
    } else {
      setEquipamentsList(equipamentsListBkp)
      setEquipamentsListBkp([])
    }
    setHasEquipaments(value)
  }

  const booleanRadio = (
    <S.BooleanRadioWrapper>
      {booleans.map((option) => (
        <RadioButtonNew
          showBackground={false}
          label={option.label}
          name="equipaments"
          labelFor={'options-' + option}
          key={String(option.value)}
          value={String(option.value)}
          checked={hasEquipaments === option.value}
          onCheck={(value) => handleHasEquipament(value === 'true')}
          required
        />
      ))}
    </S.BooleanRadioWrapper>
  )

  const emptyState = (closeResults: (bool: boolean) => void) => (
    <S.EmptyState>
      <SupportTextNew>
        O equipamento que você pesquisou não retornou resultados, deseja incluir
        assim mesmo?
      </SupportTextNew>
      <ButtonNew
        outlined
        size="small"
        fullWidth
        className="include-button"
        onClick={() => addEquipament({ label: searchTerm }, closeResults)}
      >
        Incluir equipamento
      </ButtonNew>
    </S.EmptyState>
  )

  const equipamentsCards = () => {
    return equipamentsList.map(({ name, code }: EquipamentModel, index) => (
      <S.EquipamentCard
        key={`${code}${index}`}
        background="neutral50"
        padding="12px 8px"
        shadow="satin"
      >
        <header className="card-header">
          <HeadingNew color="primary600">{name}</HeadingNew>
          <SupportTextNew size="xxsmall" color="neutral800">
            {code ? `Código ${code}` : 'Sem código'}
          </SupportTextNew>
        </header>
        <footer className="card-footer">
          <TrashIcon
            className="trash-icon"
            width="22px"
            onClick={() => removeEquipament(index)}
          />
        </footer>
      </S.EquipamentCard>
    ))
  }

  const submitButton = (
    <ButtonNew
      size="large"
      fullWidth
      onClick={submitForm}
      disabled={hasEquipaments && !equipamentsList.length}
    >
      Próximo
    </ButtonNew>
  )

  return (
    <ContainerNew primaryButton={submitButton} gap="24px" noHeader>
      <div>
        <HeadingNew color="primary600" size="large">
          Equipamentos
        </HeadingNew>
        <SupportTextNew>Inclua equipamentos se necessário.</SupportTextNew>
      </div>

      <div>
        <S.Label weight="bold" color="neutral900">
          Deseja incluir algum outro equipamento?*
        </S.Label>
        {booleanRadio}
      </div>

      {hasEquipaments && (
        <div>
          <S.Label weight="bold" color="neutral900">
            Equipamento
          </S.Label>
          <AutoCompleteNew
            name="equipament"
            placeholder="Pesquise pelo nome"
            value={searchTerm}
            onType={searchEquipament}
            onInputChange={setSearchTerm}
            suggestions={mappedSuggestions()}
            onSuggestionClick={(item) => addEquipament(item)}
            icon={<SearchIcon />}
            iconColor="white"
            iconIsButton
            emptyState={emptyState}
            disabled={false}
            debounceDelay={800}
            returnFullSuggestion
          />
        </div>
      )}

      {equipamentsCards()}
    </ContainerNew>
  )
}
