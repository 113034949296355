import CardNew from 'presentation/shared/components/CardNew'
import styled, { DefaultTheme, css } from 'styled-components'
import { MyProductivityProps } from '..'
export type ContentProps = Pick<MyProductivityProps, 'variant'>

const variants = {
  positive: (theme: DefaultTheme) => css`
    color: ${theme.ds.colors.success900};
  `,

  negative: (theme: DefaultTheme) => css`
    color: ${theme.ds.colors.danger900};
  `,

  equal: (theme: DefaultTheme) => css`
    color: ${theme.ds.colors.info900};
  `,

  indicatorPositive: (theme: DefaultTheme) => css`
    color: ${theme.ds.colors.success500};
  `,

  indicatorNegative: (theme: DefaultTheme) => css`
    color: ${theme.ds.colors.danger500};
  `,

  indicatorEqual: (theme: DefaultTheme) => css`
    color: ${theme.ds.colors.info500};
  `
}

export const StyledCardNew = styled(CardNew)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 8px;
  align-items: center;
`
export const ComparisonTitle = styled.div`
  font-size: 12px;
  font-weight: ${({ theme }) => theme.font.bold};
  color: ${({ theme }) => theme.ds.colors.neutral700};
`
export const ComparisonValue = styled.div<ContentProps>`
  ${({ theme, variant }) => css`
    font-size: 20px;
    font-weight: ${({ theme }) => theme.font.bold};
    ${!!variant && variants[variant](theme)}
  `}
`
export const OtherIndicatorsCardTitle = styled.span`
  font-weight: ${({ theme }) => theme.font.semiBold};
  color: ${({ theme }) => theme.ds.colors.neutral700};

  @media screen and (max-width: 768px) {
    font-size: 12px;
  }

  @media screen and (min-width: 769px) {
    font-size: 14px;
  }
`
export const ValueContainer = styled.div<ContentProps>`
  ${({ theme, variant }) => css`
    font-size: 32px;
    font-weight: ${({ theme }) => theme.font.bold};
    ${!!variant && variants[variant](theme)}
  `}
`
export const Avarage = styled.span`
  font-weight: ${({ theme }) => theme.ds.typography.weight.normal};
  color: ${({ theme }) => theme.ds.colors.neutral700};
  margin-bottom: 8px;

  @media screen and (max-width: 768px) {
    font-size: 12px;
  }

  @media screen and (min-width: 769px) {
    font-size: 16px;
  }
`
export const AvarageEspeciality = styled.span`
  font-size: 12px;
  font-weight: ${({ theme }) => theme.ds.typography.weight.semiBold};
  color: ${({ theme }) => theme.ds.colors.neutral700};
`

export const GeneralAverage = styled.span`
  font-size: 24px;
  font-weight: ${({ theme }) => theme.ds.typography.weight.bold};
  color: ${({ theme }) => theme.ds.colors.neutral700};
`
