import { GetAppointmentsByMonth } from 'domain/usecases/scheduling/get-appointments-by-month'
import { SchedulingRepository } from 'repository/interfaces/scheduling-repository'

export class RemoteGetAppointmentsByMonth implements GetAppointmentsByMonth {
  constructor(private readonly schedulingRepository: SchedulingRepository) {}

  load(
    params: GetAppointmentsByMonth.Params
  ): Promise<GetAppointmentsByMonth.Model> {
    return this.schedulingRepository.getAppointmentsByMonth(params)
  }
}

export type SearchDoctorsModel = GetAppointmentsByMonth.Model
