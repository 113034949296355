import React, { useEffect } from 'react'

import * as S from './styles'

import { useState } from 'react'
import { useHistory, useLocation } from 'react-router'
import { useServices } from 'presentation/hooks/use-services'
import { useStores } from 'presentation/hooks/use-stores'
import { toast } from 'react-toastify'
import { ContainerNew } from 'presentation/shared/components/ContainerNew'
import { SurgicalOrderNew } from 'domain/entities/surgical-order-new'
import { Document } from 'domain/entities/document'
import { GuidesDetails } from 'domain/usecases/surgical-order/load-guides-by-type'
import { SurgicalOrderModel } from 'domain/entities/surgical-order-model'
import { ReactComponent as AccordionRightArrow } from 'presentation/assets/icons/accordion-right-arrow.svg'
import { ReactComponent as CheckCircle } from 'presentation/assets/icons/check-circle.svg'
import { ReactComponent as AlertCircle } from 'presentation/assets/icons/alert-circle-grey.svg'
import SurgicalOrderCard from 'presentation/shared/layouts/SurgicalOrderCard'
import AccordionCardNew from 'presentation/shared/components/AccordionCardNew'
import { GetSurgicalOrderById } from 'domain/usecases/surgical-order/get-surgical-order-by-id'
import { Patient } from 'domain/entities/patient-model'
import { SurgicalOrderCidModel } from 'domain/entities/surgical-order-cid-model'
import { SurgicalOrderOpmeModel } from 'domain/entities/surgical-order-opme-model'
import {
  WithLoading,
  WithLoadingProps
} from 'presentation/shared/components/HOCs/WithLoading'
import ButtonNew from 'presentation/shared/components/ButtonNew'

type OpenSurgicalOrderProps = {
  surgicalOrder?: SurgicalOrderNew
  planning?: {
    opmeSuppliers: GuidesDetails[]
    authProcedures: GuidesDetails[]
    documents: Document[]
  } & SurgicalOrderModel
}

export const OpenSurgicalOrderForm = WithLoading(
  ({ setIsLoading }: WithLoadingProps) => {
    const stateLocation = useLocation<OpenSurgicalOrderProps>()
    const surgicalOrderService = useServices().surgicalOrder
    const surgicalOrderContext = useStores().surgicalOrder
    const surgicalOrderObjContext = useStores().surgicalOrder.getSurgicalOrder()
    const [responseState, setResponseState] =
      useState<GetSurgicalOrderById.Model>()
    const history = useHistory()
    const HOSPITAL_ID_SALVADOR = 8

    if (!stateLocation.state.surgicalOrder?.surgical_order_id) {
      toast.error('Ocorreu um erro ao carregar o pedido cirúrgico')
      history.push('/home')
    }

    const getSurgicalOrderById = async () => {
      setIsLoading(true)
      try {
        const response = await surgicalOrderService.getSurgicalOrderById({
          surgical_order_id: Number(
            stateLocation.state.surgicalOrder?.surgical_order_id
          )
        })

        surgicalOrderContext.setSurgicalOrder(response)

        surgicalOrderContext.setLocation({
          hospital: response.hospital,
          surgeryCenter: {
            surgery_center_id: response.surgical_center?.surgical_center_id,
            name: response.surgical_center?.surgical_center
          }
        })

        surgicalOrderContext.setPatient({
          ...surgicalOrderContext.patient,
          patient: response?.patient as Patient
        })

        surgicalOrderContext.setProcedures({
          ...surgicalOrderContext.procedures,
          procedures: response.procedures!,
          cids: response.cids as SurgicalOrderCidModel[]
        })

        surgicalOrderContext.setHealthInsurance({
          healthInsurance: {
            health_insurance_id:
              response?.health_insurance?.health_insurance_id,
            code: response?.health_insurance?.health_insurance_code,
            description: response?.health_insurance?.health_insurance_name,
            healthCard: response?.health_insurance?.health_insurance_card
          }
        })

        surgicalOrderContext.cooperative = {
          cooperative_name: response.cooperative_name
        }

        surgicalOrderContext.setOpme(response?.opme as SurgicalOrderOpmeModel)

        surgicalOrderContext.setAnesthesiology(response?.anesthesiologies)

        surgicalOrderContext.clinical = {
          ...surgicalOrderContext.clinical,
          clinical_history: response.clinical_history,
          clinical_record: response.clinical_record
        }

        setResponseState(response)
        return response
      } catch (error) {
        toast.error('Ocorreu um erro ao carregar os documentos')
      } finally {
        setIsLoading(false)
      }
    }

    const handleSubmit = async () => {
      try {
        setIsLoading(true)
        const response = await surgicalOrderService.finishSurgicalOrder({
          surgical_order_id: surgicalOrderObjContext?.surgical_order_id
        })

        surgicalOrderContext.setSurgicalOrder({
          ...surgicalOrderContext.getSurgicalOrder(),
          surgical_order_id: response.surgical_order_id
        })

        history.push('/novo-pedido/criado', {
          surgical_order_id: response.surgical_order_id
        })
      } catch (error: any) {
        toast.error(error?.message)
      } finally {
        setIsLoading(false)
      }
    }

    useEffect(() => {
      getSurgicalOrderById()
    }, [])

    const checkIfAllDataIsCompleted = () => {
      const hasPatientData = !!responseState?.patient
      const hasProceduresData = !!responseState?.procedures
      const hasOpmeData = !!responseState?.opme || !null
      const hasClinicalHistoryData =
        !!responseState?.clinical_history.clinical_history
      const hasClinicalRecordData = !!responseState?.clinical_record.cti
      // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
      const hasDocumentsData = responseState?.documents?.length! > 0

      let hasCooperativeData = true
      if (responseState?.hospital.hospital_id === HOSPITAL_ID_SALVADOR) {
        hasCooperativeData = !!responseState?.cooperative_name
      }

      return (
        hasPatientData &&
        hasProceduresData &&
        hasOpmeData &&
        hasClinicalHistoryData &&
        hasClinicalRecordData &&
        hasDocumentsData &&
        hasCooperativeData
      )
    }

    return (
      <>
        <ContainerNew
          primaryButton={
            <ButtonNew
              fullWidth
              size="large"
              onClick={handleSubmit}
              disabled={!checkIfAllDataIsCompleted()}
            >
              Enviar pedido cirúrgico
            </ButtonNew>
          }
        >
          <S.Wrapper>
            <h1>Pedido em aberto</h1>
          </S.Wrapper>
          <SurgicalOrderCard
            procedures
            schedulingDate
            statusTag
            surgicalOrder={stateLocation.state.surgicalOrder}
          />
          <S.SurgicalPlanningSection>
            <h1>Informações</h1>
            <AccordionCardNew
              header={'Dados paciente e convênio'}
              accordionLabel={'Dados paciente e convênio'}
              expandIcon={<AccordionRightArrow />}
              headerIcon={
                responseState?.patient ? <CheckCircle /> : <AlertCircle />
              }
              onClick={() => {
                history.push('/novo-pedido/revisar-paciente')
              }}
              expanded={false}
              background="neutral25"
              border="neutral100"
              shadow="normal"
              padding="16px"
            />
            {responseState?.hospital.hospital_id === HOSPITAL_ID_SALVADOR && (
              <AccordionCardNew
                header={'Dados cooperativa'}
                accordionLabel={'Dados cooperativa'}
                expandIcon={<AccordionRightArrow />}
                headerIcon={
                  responseState.cooperative_name ? (
                    <CheckCircle />
                  ) : (
                    <AlertCircle />
                  )
                }
                onClick={() => {
                  history.push('/novo-pedido/cooperativa')
                }}
                expanded={false}
                background="neutral25"
                border="neutral100"
                shadow="normal"
                padding="16px"
              />
            )}
            <AccordionCardNew
              header={'Procedimentos e CID'}
              accordionLabel={'Procedimentos e CID'}
              expandIcon={<AccordionRightArrow />}
              headerIcon={
                responseState?.procedures ? <CheckCircle /> : <AlertCircle />
              }
              onClick={() => {
                history.push('/novo-pedido/procedimentos')
              }}
              expanded={false}
              background="neutral25"
              border="neutral100"
              shadow="normal"
              padding="16px"
            />
            <AccordionCardNew
              header={'OPME e fornecedores'}
              accordionLabel={'OPME e fornecedores'}
              expandIcon={<AccordionRightArrow />}
              headerIcon={
                responseState?.opme || !null ? <CheckCircle /> : <AlertCircle />
              }
              onClick={() => {
                history.push('/novo-pedido/opme')
              }}
              expanded={false}
              background="neutral25"
              border="neutral100"
              shadow="normal"
              padding="16px"
            />
            <AccordionCardNew
              header={'História clínica'}
              accordionLabel={'História clínica'}
              expandIcon={<AccordionRightArrow />}
              headerIcon={
                responseState?.clinical_history.clinical_history ? (
                  <CheckCircle />
                ) : (
                  <AlertCircle />
                )
              }
              onClick={() => {
                history.push('/novo-pedido/historia-clinica')
              }}
              expanded={false}
              background="neutral25"
              border="neutral100"
              shadow="normal"
              padding="16px"
            />
            <AccordionCardNew
              header={'Ficha clínica'}
              accordionLabel={'Ficha clínica'}
              expandIcon={<AccordionRightArrow />}
              headerIcon={
                responseState?.clinical_record.cti !== null ? (
                  <CheckCircle />
                ) : (
                  <AlertCircle />
                )
              }
              onClick={() => {
                history.push('/novo-pedido/historia-clinica')
              }}
              expanded={false}
              background="neutral25"
              border="neutral100"
              shadow="normal"
              padding="16px"
            />
            <AccordionCardNew
              header={'Laudos e outros documentos'}
              accordionLabel={'Laudos e outros documentos'}
              expandIcon={<AccordionRightArrow />}
              headerIcon={
                responseState?.documents.length ? (
                  <CheckCircle />
                ) : (
                  <AlertCircle />
                )
              }
              onClick={() => {
                history.push('/novo-pedido/laudos-documentos')
              }}
              expanded={false}
              background="neutral25"
              border="neutral100"
              shadow="normal"
              padding="16px"
            />
          </S.SurgicalPlanningSection>
        </ContainerNew>
      </>
    )
  }
)
