import {
  createGlobalStyle,
  DefaultTheme,
  GlobalStyleComponent
} from 'styled-components'

const GlobalStyles: GlobalStyleComponent<any, DefaultTheme> = createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    scroll-behavior: smooth;
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    &::before,
    &::after {
      box-sizing: inherit;
    }

    &::-webkit-scrollbar {
      -webkit-appearance: none;
    }

    &::-webkit-scrollbar:vertical {
      width: 11px;
    }

    &::-webkit-scrollbar:horizontal {
      height: 11px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 8px;
      border: 2px solid #F0F6EF;
      background-color: rgba(0, 0, 0, 0.2);
    }
  }

    html {
      height: 100%;
      min-height: 100%;
    }

    body {
      height: 100%;
      min-height: 100%;
      display: flex;
      flex-direction: column;
      flex: 1 1 0%;
      background-color: ${({ theme }) => theme.colors.mainBg};
    }

    #root {
      display: flex;
      flex-direction: column;
      flex: 1 1 0%;
      min-height: 100%;
      position: relative;
      max-height: 100%;
      font-family: ${({ theme }) => theme.font.family};
      font-size: ${({ theme }) => theme.font.sizes.small};
      background-color: ${({ theme }) => theme.colors.mainBg};
      overflow-x: hidden;
    }

  .swiper-container {
    width: 100%;
    height: 100%;
    z-index: 0;
  }

  .swiper-wrapper {
    width: 100%;
    height: 100% !important;
    z-index: 0;
  }

  .swiper-slide {
    height: 100% !important;
    z-index: 0;
    display: flex;
    flex-direction: column;
  }

  /* TOASTIFY OVERRIDES */
  /** Used to define container behavior: width, position: fixed etc... **/
.Toastify__toast-container {
  width: auto;
  max-width: 430px;
  min-width: 320px;
  @media (max-width: 480px) {
    width: 100vw;
    max-width: unset;
    min-width: unset;
    padding: 8px;
  }
}

/** Used to define the position of the ToastContainer **/
.Toastify__toast-container--top-left {
  top: 40px;
}
.Toastify__toast-container--top-center {
  top: 40px
}
.Toastify__toast-container--top-right {
  top: 40px;
}
.Toastify__toast-container--bottom-left {
  bottom: 40px;
}
.Toastify__toast-container--bottom-center {
  bottom: 40px;
}
.Toastify__toast-container--bottom-right {
  bottom: 40px;
}

/** Classes for the displayed toast **/
.Toastify__toast {
  border-radius: ${({ theme }) => theme.ds.typography.border.radius.small};
  font-family: ${({ theme }) => theme.ds.typography.fontFamily};
  line-height: 1.3em;
  margin-bottom: 8px;
}

/* .Toastify__toast--rtl {
} */

.Toastify__toast-body {
  padding: 0 6px;
  align-items: start;
}

/** Used to position the icon **/
.Toastify__toast-icon {
  margin-inline-end: 8px;
  width: 18px;
  padding-top: 1px;
}

/** Handle the notification color and the text color based on the theme **/

/* Light theme */
.Toastify__toast-theme--light {
  /* Passing prop className to toast emitter for override styles:
      className: '-default'
      className: '-success'
      className: '-error'
      className: '-warning'
      className: '-info'
  */
  &.-default {
    background: ${({ theme }) => theme.ds.colors.neutral500};
    color: ${({ theme }) => theme.ds.colors.white};

    .Toastify__close-button {
      opacity: 1;
      color: ${({ theme }) => theme.ds.colors.white};
    }

    .Toastify__progress-bar {
      background: ${({ theme }) => theme.ds.colors.white};
    }
  }

  &.-success {
    background: ${({ theme }) => theme.ds.colors.primary600};
    color: ${({ theme }) => theme.ds.colors.white};

    .Toastify__close-button {
      opacity: 1;
      color: ${({ theme }) => theme.ds.colors.white};
    }

    .Toastify__progress-bar {
      background: ${({ theme }) => theme.ds.colors.white};
    }
  }

  &.-error {
    background: ${({ theme }) => theme.ds.colors.danger300};
    color: ${({ theme }) => theme.ds.colors.white};

    .Toastify__close-button {
      opacity: 1;
      color: ${({ theme }) => theme.ds.colors.white};
    }

    .Toastify__progress-bar {
      background: ${({ theme }) => theme.ds.colors.white};
    }
  }

  &.-warning {
    background: ${({ theme }) => theme.ds.colors.warning400};
    color: ${({ theme }) => theme.ds.colors.white};

    .Toastify__close-button {
      opacity: 1;
      color: ${({ theme }) => theme.ds.colors.white};
    }

    .Toastify__progress-bar {
      background: ${({ theme }) => theme.ds.colors.white};
    }
  }

  &.-info {
    background: ${({ theme }) => theme.ds.colors.info300};
    color: ${({ theme }) => theme.ds.colors.white};

    .Toastify__close-button {
      opacity: 1;
      color: ${({ theme }) => theme.ds.colors.white};
    }

    .Toastify__progress-bar {
      background: ${({ theme }) => theme.ds.colors.white};
    }
  }
}

/* Dark theme */
/* .Toastify__toast-theme--dark {
} */


/* Colored theme */
/*
.Toastify__toast-theme--colored.Toastify__toast--default {
}
.Toastify__toast-theme--colored.Toastify__toast--info {
}
.Toastify__toast-theme--colored.Toastify__toast--success {
}
.Toastify__toast-theme--colored.Toastify__toast--warning {
}
.Toastify__toast-theme--colored.Toastify__toast--error {
} */


/* .Toastify__progress-bar {
}
.Toastify__progress-bar--rtl {
}
.Toastify__progress-bar-theme--light {
}
.Toastify__progress-bar-theme--dark {
}
.Toastify__progress-bar--info {
}
.Toastify__progress-bar--success {
}
.Toastify__progress-bar--warning {
}
.Toastify__progress-bar--error {
} */

/** colored notifications share the same progress bar color **/
/* .Toastify__progress-bar-theme--colored.Toastify__progress-bar--info,
.Toastify__progress-bar-theme--colored.Toastify__progress-bar--success,
.Toastify__progress-bar-theme--colored.Toastify__progress-bar--warning,
.Toastify__progress-bar-theme--colored.Toastify__progress-bar--error {
} */

/** Classes for the close button. Better use your own closeButton **/
/* .Toastify__close-button {
}
.Toastify__close-button--default {
}
.Toastify__close-button > svg {
}
.Toastify__close-button:hover,
.Toastify__close-button:focus {
} */

.capitalize-fl {
  display: inline-block;
  text-transform: lowercase;
  &::first-letter {
    text-transform: uppercase;
  }
}

`

export default GlobalStyles
