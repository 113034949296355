import React, { useState } from 'react'
import { useServices } from 'presentation/hooks/use-services'
import { useStores } from 'presentation/hooks/use-stores'
import { ContainerNew } from 'presentation/shared/components/ContainerNew'
import {
  WithLoading,
  WithLoadingProps
} from 'presentation/shared/components/HOCs/WithLoading'
import ButtonNew from 'presentation/shared/components/ButtonNew'
import TextFieldNew from 'presentation/shared/components/TextFieldNew'
import { useHistory, useLocation } from 'react-router'
import { ReactComponent as SearchIcon } from 'presentation/assets/icons/search.svg'
import capitalizeFirstLetters from 'common/utils/capitalizeFirstLetters'
import moment from 'moment'
import SupportTextNew from 'presentation/shared/components/SupportTextNew'
import { formatMask, cpfMask } from 'presentation/utils/masks'
import * as S from './styles'
import { validateCpf } from 'presentation/utils/validators/cpf-validator'
import { useFormik } from 'formik'
import { PatientProps } from 'presentation/contexts/surgical-order/surgical-order-context'
import Divider from 'presentation/shared/components/DividerNew'

const SelectPatientForm = WithLoading(({ setIsLoading }: WithLoadingProps) => {
  const { state } = useLocation<{
    isEditing?: boolean
  }>()
  const surgicalOrderContext = useStores().surgicalOrder
  const patientService = useServices().patient
  const history = useHistory()
  const [errorMessage, setErrorMessage] = useState('')

  const hasDigit = (value: string) => {
    return value.match(/\d/)
  }

  const getPatientByCpfOrName = async () => {
    surgicalOrderContext.patient.searchPatientList = []
    setErrorMessage('')

    const search = surgicalOrderContext.patient.searchInput

    if (search) {
      let cpf, name

      if (hasDigit(search)) {
        cpf = search.replace(/\D+/g, '')

        if (!validateCpf(cpf)) {
          setErrorMessage('CPF inválido.')
          setIsLoading(false)
          return
        }
      } else {
        name = search
      }

      setIsLoading(true)

      surgicalOrderContext.patient.searchPatientList =
        await patientService.getPatientByCpfOrName({ cpf, name })

      if (!isSearchPatientListValid()) {
        setErrorMessage('Paciente não encontrado. Cadastre novo paciente.')
      }
    }

    setIsLoading(false)
  }

  const handleSearchInputChange = (search: string) => {
    surgicalOrderContext.patient.searchInput = search
  }

  const isSearchPatientListValid = (): boolean => {
    if (
      surgicalOrderContext.patient.searchPatientList &&
      surgicalOrderContext.patient.searchPatientList.length > 0 &&
      surgicalOrderContext.patient.searchPatientList.every(
        (patient) => patient !== null
      )
    )
      return true
    else return false
  }

  const selectPatient = (values: PatientProps) => {
    surgicalOrderContext.patient = values
    history.push('/novo-pedido/revisar-paciente', {
      isEditing: state?.isEditing
    })
  }

  const formik = useFormik({
    initialValues: {} as PatientProps,
    onSubmit: selectPatient
  })

  const onEnterSearch = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.keyCode === 13) {
      getPatientByCpfOrName()
    }
  }

  return (
    <ContainerNew
      title="Paciente"
      titleColor="primary600"
      subtitle="Busque por um paciente cadastrado ou insira um paciente novo."
      subtitleColor="neutral700"
    >
      <ButtonNew
        onClick={() =>
          history.push('/novo-paciente', {
            hospital_id: surgicalOrderContext.location?.hospital?.hospital_id,
            isEditing: state?.isEditing
          })
        }
        outlined
        size="large"
        style={{ margin: '24px 0' }}
      >
        Novo paciente
      </ButtonNew>
      <TextFieldNew
        label="Buscar paciente"
        required={true}
        requiredColor="neutral900"
        placeholder="Busque pelo CPF ou Nome"
        icon={<SearchIcon />}
        keepStyleOnIconIsButton
        onIconClick={getPatientByCpfOrName}
        name="searchInput"
        id="searchInput"
        value={surgicalOrderContext.patient.searchInput || ''}
        onChange={formik.handleChange}
        onInputChange={handleSearchInputChange}
        autoComplete="off"
        onKeyDown={onEnterSearch}
        error={errorMessage}
      />
      {isSearchPatientListValid() ? (
        <>
          <Divider color="neutral100" marginTop="24px" marginBottom="24px" />
          {surgicalOrderContext.patient.searchPatientList?.map((patient) => (
            <S.CardPatient
              key={patient.patient_id}
              background="neutral25"
              onClick={() => {
                selectPatient({
                  patient,
                  searchInput: surgicalOrderContext.patient.searchInput,
                  searchPatientList:
                    surgicalOrderContext.patient.searchPatientList
                })
              }}
            >
              <S.CardField>
                <SupportTextNew weight="bold" color="neutral900" size="xxsmall">
                  Paciente
                </SupportTextNew>
                <SupportTextNew color="neutral900" size="xsmall">
                  {patient?.name ? capitalizeFirstLetters(patient?.name) : ''}
                </SupportTextNew>
              </S.CardField>

              <S.CardField>
                <SupportTextNew weight="bold" color="neutral900" size="xxsmall">
                  Data de nascimento
                </SupportTextNew>
                <SupportTextNew color="neutral900" size="xsmall">
                  {patient?.birthday
                    ? moment.utc(patient.birthday).format('DD/MM/YYYY')
                    : ''}
                </SupportTextNew>
              </S.CardField>

              <S.CardField>
                <SupportTextNew weight="bold" color="neutral900" size="xxsmall">
                  CPF
                </SupportTextNew>
                <SupportTextNew color="neutral900" size="xsmall">
                  {patient?.user?.cpf
                    ? formatMask(cpfMask, patient.user.cpf)
                    : ''}
                </SupportTextNew>
              </S.CardField>
            </S.CardPatient>
          ))}
        </>
      ) : (
        ''
      )}
    </ContainerNew>
  )
})

export default SelectPatientForm
