import { SearchProcedures } from 'domain/usecases/surgical-order/search-procedures'
import { SurgicalOrderRepository } from 'repository/interfaces/surgical-order-repository'

export class RemoteSearchProcedures implements SearchProcedures {
  constructor(
    private readonly surgicalOrderRepository: SurgicalOrderRepository
  ) {
    this.surgicalOrderRepository = surgicalOrderRepository
  }

  load(params: SearchProcedures.Params): Promise<SearchProcedures.Model> {
    return this.surgicalOrderRepository.searchProcedures(params)
  }
}
