import {
  createGlobalStyle,
  DefaultTheme,
  GlobalStyleComponent,
  css
} from 'styled-components'

const GuideModalTourStyles: GlobalStyleComponent<
  any,
  DefaultTheme
> = createGlobalStyle`
${({ theme }) => css`
  .guide-modal * {
    font-family: ${theme.ds.typography.fontFamily} !important;
  }

  .guide-mask-modal-tour {
    border-color: rgba(0, 0, 0, 0.5);

    &::after {
      top: -8px;
      left: -8px;
      width: calc(100% + 16px);
      height: calc(100% + 16px);
      border-radius: 4px;
      border: 8px solid #fff;
      box-shadow: unset;
    }
  }

  .guide-modal-tour {
    padding: 16px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 100%;
    max-width: fit-content;
    min-width: 250px;

    > .guide-modal-hotspot {
      background-color: ${theme.ds.colors.primary600};
      animation: pulse 1s infinite;

      @keyframes pulse {
        0% {
          box-shadow: 0 0 0 0 ${theme.ds.colors.primary600};
        }
        100% {
          box-shadow: 0 0 0 8px transparent;
        }
      }
    }

    /* > .guide-modal-arrow {} */

    /* > .guide-modal-close-icon {
      svg {}
    } */

    > .guide-modal-title {
      margin: 0;
      color: ${theme.ds.colors.primary800};
      font-size: ${theme.ds.typography.size.medium};
      font-weight: ${theme.ds.typography.weight.bold};
      height: auto;
    }

    > .guide-modal-content {
      margin: 0;
      color: ${theme.ds.colors.neutral800};
      font-size: ${theme.ds.typography.size.xsmall};
      font-weight: ${theme.ds.typography.weight.normal};
      line-height: 1.4;
      width: 100%;
      max-width: 300px;
      min-width: 200px;

      b,
      strong {
        font-weight: ${theme.ds.typography.weight.bold};
      }
    }

    > .guide-modal-footer {
      max-height: unset;
      height: auto;

      > .guide-modal-footer-text {
        color: ${theme.ds.colors.neutral400};
        font-size: ${theme.ds.typography.size.xxsmall} !important;
        margin-right: 32px;
      }

      > .guide-modal-footer-btn-group {
        gap: 8px;

        > .guide-modal-footer-btn {
          margin: 0;
          box-shadow: none;
          border: none;
          line-height: ${theme.ds.typography.lineHeight.medium};
          border-radius: ${theme.ds.typography.border.radius.xsmall};
          color: ${theme.ds.colors.white};
          background-color: ${theme.ds.colors.primary600};
          font-weight: ${theme.ds.typography.weight.semiBold};
          height: 32px;
          padding: 0 16px;
          font-size: ${theme.ds.typography.size.small} !important;
          transition: all ease 0.1s;

          &:hover {
            background-color: ${theme.ds.colors.primary500};
          }

          &:active {
            background-color: ${theme.ds.colors.primary700};
          }
        }

        > .guide-modal-footer-prev-btn {
          color: ${theme.ds.colors.primary600};
          background-color: ${theme.ds.colors.transparent};
          border: 1px solid ${theme.ds.colors.primary600};

          &:hover {
            color: ${theme.ds.colors.primary500};
            background-color: ${theme.ds.colors.transparent};
            border-color: ${theme.ds.colors.primary500};
          }

          &:active {
            color: ${theme.ds.colors.primary700};
            background-color: ${theme.ds.colors.transparent};
            border-color: ${theme.ds.colors.primary700};
          }
        }

        > .guide-modal-footer-skip-btn {
          color: ${theme.ds.colors.primary600};
          background-color: ${theme.ds.colors.transparent};
          text-decoration: underline;

          &:hover {
            color: ${theme.ds.colors.primary500};
            background-color: ${theme.ds.colors.transparent};
          }

          &:active {
            color: ${theme.ds.colors.primary700};
            background-color: ${theme.ds.colors.transparent};
          }
        }
      }
    }

    /* Variants */
    &.-hide-title {
      > .guide-modal-title {
        display: none;
      }
    }

    &.-hide-footer {
      > .guide-modal-footer {
        display: none;
      }
    }
  }
`}
`

export default GuideModalTourStyles
