import React, { SelectHTMLAttributes } from 'react'

import theme from 'presentation/styles/theme'
import * as S from './styles'

export type SelectFieldProps = {
  onInputChange?: (value: string) => void
  label?: string
  labelColor?: keyof typeof theme.ds.colors
  labelIcon?: React.ReactNode
  onLabelIconClick?: () => void
  initialValue?: string
  disabled?: boolean
  error?: string | false | string[]
  items?: {
    value: string | ReadonlyArray<string> | number
    label: string
    selected?: boolean
  }[]
  bgColor?: keyof typeof theme.ds.colors
  placeholder?: string
  requiredColor?: keyof typeof theme.ds.colors
} & SelectHTMLAttributes<HTMLSelectElement>

export default function SelectFieldNew({
  onInputChange,
  label,
  labelColor = 'neutral900',
  labelIcon,
  bgColor = 'white',
  onLabelIconClick,
  required = false,
  requiredColor = 'danger200',
  disabled = false,
  error,
  name,
  style,
  items,
  placeholder = 'Selecionar',
  ...props
}: SelectFieldProps) {
  const onChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const newValue = e.currentTarget.value
    !!onInputChange && onInputChange(newValue)
    !!props.onChange && props.onChange(e)
  }

  const labelIconClick = () => {
    !!onLabelIconClick && onLabelIconClick()
  }

  return (
    <S.Wrapper style={style} disabled={disabled} error={!!error}>
      {(!!label || !!labelIcon) && (
        <S.LabelWrapper>
          {!!label && (
            <S.Label htmlFor={name} labelColor={labelColor}>
              {label}
              {required ? (
                <S.Asterisk requiredColor={requiredColor}>*</S.Asterisk>
              ) : (
                ''
              )}
            </S.Label>
          )}
          {!!labelIcon && (
            <S.LabelIcon onClick={labelIconClick}>{labelIcon}</S.LabelIcon>
          )}
        </S.LabelWrapper>
      )}
      <S.SelectWrapper>
        <S.Select
          onChange={onChange}
          name={name}
          disabled={disabled}
          data-testid="select"
          bgColor={bgColor}
          id={name}
          {...props}
        >
          <S.Option value="" disabled>
            {placeholder}
          </S.Option>
          {items?.map((item) => (
            <S.Option
              key={item.value + '-' + item.label}
              value={item.value}
              selected={item.selected}
              data-testid={`select-options-${item.value}`}
            >
              {item.label}
            </S.Option>
          ))}
        </S.Select>
      </S.SelectWrapper>
      {!!error && <S.Error>{error}</S.Error>}
    </S.Wrapper>
  )
}
