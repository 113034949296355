import styled, { css } from 'styled-components'
import { rgba } from 'polished'
import { ScheduleType } from 'common/enum/schedule-type'
import theme from 'presentation/styles/theme'

import { CardContentProps } from './'

export const Wrapper = styled.div<Pick<CardContentProps, 'type'>>`
  ${({ theme, type }) => css`
    position: relative;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    gap: 4px;
    height: 232px;
    width: 100%;
    max-width: 270px;
    min-width: 220px;
    padding: 16px;
    background-color: ${theme.ds.colors.neutral50};
    box-shadow: 2px 2px 2px ${rgba(theme.ds.colors.black, 0.06)};
    border-radius: 8px;

    > .schedule-type {
      font-weight: ${theme.ds.typography.weight.semiBold};
      font-size: ${theme.ds.typography.size.xsmall};
      line-height: ${theme.ds.typography.lineHeight.small};
      color: ${getTypeColor[type]};
    }

    > .schedule-time {
      margin-top: 4px;
      color: ${theme.ds.colors.neutral800};
      font-weight: ${theme.ds.typography.weight.semiBold};
      font-size: ${theme.ds.typography.size.medium};
      line-height: ${theme.ds.typography.lineHeight.medium};
    }

    > .schedule-title {
      color: ${getTypeColor[type]};
      font-size: ${theme.ds.typography.size.xsmall};
      font-weight: ${theme.ds.typography.weight.semiBold};
      line-height: ${theme.ds.typography.lineHeight.medium};
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    > .schedule-return {
      margin-top: 4px;
      font-size: ${theme.ds.typography.size.xsmall};
      color: ${theme.ds.colors.neutral800};
      font-weight: ${theme.ds.typography.weight.bold};
    }

    > .schedule-footer {
      display: flex;
      flex-direction: column;
      gap: 4px;
      margin-top: auto;
      margin-bottom: 0;
      .box-item {
        margin: 0;
        padding: 0;
        line-height: ${theme.ds.typography.lineHeight.small};

        > .title {
          font-size: 10px;
          color: ${theme.ds.colors.neutral600};
          font-weight: normal;
        }

        > .description {
          font-size: ${theme.ds.typography.size.xxsmall};
          color: ${theme.ds.colors.neutral900};
          font-weight: normal;
          text-transform: capitalize;
        }
      }
    }
  `}
`

const getTypeColor: Record<ScheduleType, string> = {
  [ScheduleType.SURGICAL]: theme.ds.colors.primary600,
  [ScheduleType.AMBULATORY]: theme.ds.colors.secondary500
}
