export enum Personas {
  crmo = 'CRMO',
  doctor = 'médico',
  system = 'sistema',
  patient = 'paciente',
  secretary = 'secretária'
}

export const PersonasList = [
  {
    label: 'CRMO',
    value: 'crmo'
  },
  {
    label: 'médico',
    value: 'doctor'
  },
  {
    label: 'sistema',
    value: 'system'
  },
  {
    label: 'paciente',
    value: 'patient'
  },
  {
    label: 'secretária',
    value: 'secretary'
  }
]
