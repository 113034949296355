import React, { useEffect, useContext } from 'react'
import HeaderNew from 'presentation/shared/components/HeaderNew'
import SurgicalOrderDetailsLayout from 'presentation/doctor/layouts/SurgicalOrderDetails'
import { useServices } from 'presentation/hooks/use-services'
import { SurgicalOrderContext } from 'main/factories/pages/doctor/surgical-order/surgical-order-factory'
import { useLocation } from 'react-router'
import {
  WithLoading,
  WithLoadingProps
} from 'presentation/shared/components/HOCs/WithLoading'
import { toast } from 'react-toastify'
import { useHistory } from 'react-router'

function SurgicalOrderDetails({ setIsLoading }: WithLoadingProps) {
  const surgicalOrderService = useServices().surgicalOrder
  const surgicalPendencyService = useServices().surgicalPendency
  const { dispatch } = useContext<any>(SurgicalOrderContext)
  const { state } = useLocation<{ surgical_order_id: number }>()
  const history = useHistory()

  if (!state.surgical_order_id) {
    toast.error('Ocorreu um erro ao carregar o pedido cirúrgico')
    history.push('/home')
  }

  const getSurgicalOrder = async () => {
    try {
      const response: any =
        await surgicalOrderService.loadSurgicalOrderNoticeCard({
          surgical_order_id: state.surgical_order_id
        })

      dispatch({
        type: 'SURGICAL_ORDER',
        payload: response.data
      })
    } catch {
      toast.error('Ocorreu um erro ao carregar o pedido cirúrgico')
      setTimeout(() => {
        history.push('/home')
      }, 1000)
    }
  }

  const getSurgicalOrderTimeline = async () => {
    try {
      const response = await surgicalOrderService.getSurgicalOrderTimeline({
        surgical_order_id: state.surgical_order_id
      })

      dispatch({
        type: 'TIMELINE_TAB',
        payload: response.data.reverse()
      })
    } catch {
      toast.error('Ocorreu um erro ao carregar os documentos')
    }
  }

  const getPendenciesList = async () => {
    try {
      const response: any =
        await surgicalPendencyService.loadSurgicalOrderPendencies(
          state.surgical_order_id
        )

      dispatch({
        type: 'SURGICAL_PENDENCIES',
        payload: response.data
      })
    } catch {
      toast.error('Ocorreu um erro ao carregar as pendencias')
    }
  }

  useEffect(() => {
    setIsLoading(true)
    Promise.all([getSurgicalOrder(), getPendenciesList()]).finally(() =>
      setIsLoading(false)
    )
  }, [])

  return (
    <>
      <HeaderNew
        actualPageTitle="Pedido cirúrgico"
        actualPageOnClick={() => history.push('/pedidos-cirurgicos/dashboard')}
      />
      <SurgicalOrderDetailsLayout
        setIsLoading={setIsLoading}
        getSurgicalOrder={getSurgicalOrder}
        getSurgicalOrderTimeline={getSurgicalOrderTimeline}
      />
    </>
  )
}

export default WithLoading(SurgicalOrderDetails)
