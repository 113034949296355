import React, { HTMLAttributes } from 'react'
import * as S from './styles'
import CameraIcon from 'presentation/assets/icons/camera-upload.svg'
import ProfileIcon from 'presentation/assets/icons/user-profile.svg'
// import { getRandomProfileImageUrl } from 'presentation/utils/get-random-profile-image'

export type ProfilePicProps = {
  src?: string
  size?: 'small' | 'medium' | 'large' | 'xl' | 'xmedium'
  outlined?: boolean
  name?: string
  upload?: (e: React.ChangeEvent<HTMLInputElement>) => void
} & HTMLAttributes<HTMLImageElement>

export const ProfilePic = ({
  src,
  size = 'medium',
  outlined = false,
  name,
  upload,
  ...props
}: ProfilePicProps) => {
  const getInitials = (name: string) => {
    if (name) {
      const filterName = name.toLowerCase().replace(/\s(de|da|dos|das)\s/g, ' ') // Remove os de,da, dos,das.
      const initials = filterName.match(/\b(\w)/gi) // Iniciais de cada parte do nome.
      return initials!.length > 1
        ? initials![0].toString().toLocaleUpperCase() +
            initials![1].toString().toLocaleUpperCase()
        : initials!.toString().toLocaleUpperCase()
    }
  }

  return (
    <S.Wrapper upload={upload}>
      <div>
        {name ? (
          <S.Initials outlined={outlined} size={size}>
            {getInitials(name)}
          </S.Initials>
        ) : (
          <S.Image
            upload={upload}
            {...props}
            outlined={outlined}
            size={size}
            src={src || ProfileIcon}
            alt="profile pic"
          />
        )}
        {upload && (
          <label htmlFor="profile-pic">
            <img src={CameraIcon} alt="Icon camera" />
            <input type="file" id="profile-pic" onChange={upload} />
          </label>
        )}
      </div>
    </S.Wrapper>
  )
}

export default ProfilePic
