import styled from 'styled-components'
import CardNew from 'presentation/shared/components/CardNew'

export const TabWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 16px;
`

export const PlanningSection = styled.section`
  display: flex;
  flex-direction: column;
  gap: 8px;

  .section-title {
    margin-bottom: 8px;
  }
`

export const ActionCard = styled(CardNew)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 12px 16px;

  .card-title {
    display: flex;
    align-items: center;
    gap: 8px;

    svg {
      width: 18px;
      height: 18px;
    }
  }
`
export const ReportsRecommendations = styled.div`
  h1 {
    margin-top: 12px;
    font-size: 16px;
    font-weight: 600;
    color: ${({ theme }) => theme.ds.colors.primary400};
  }

  span {
    margin-top: 4px;
    font-size: 14px;
    font-weight: 500;
    color: ${({ theme }) => theme.ds.colors.neutral600};
  }
`

export const NoReport = styled.span`
  margin-top: 16px;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  color: ${({ theme }) => theme.ds.colors.neutral400};
`
export const ButtonSpan = styled.span`
  color: '#038E82';
  font-weight: 600;
`
