import React from 'react'
import { useFormik } from 'formik'
import TextField from 'presentation/shared/components/TextField'
import SelectField from 'presentation/shared/components/SelectField'
import { dateMask } from 'presentation/utils/masks'
import * as S from './styles'
import Modal from 'presentation/shared/components/Modal'
import { useState } from 'react'
import { toast } from 'react-toastify'
import Button from 'presentation/shared/components/Button'

type Props = {
  initialValues: updatePersonalInfoFormValues
}

export default function UpdateDoctorPersonalInfoForm({
  initialValues = {} as updatePersonalInfoFormValues
}: Props) {
  const [successModal, setSuccessModal] = useState<boolean>(false)
  const formik = useFormik<updatePersonalInfoFormValues>({
    initialValues: initialValues,
    onSubmit: async () => {
      try {
        setSuccessModal(true)
      } catch (error: any) {
        toast.error(error.message)
      }
    }
  })

  return (
    <>
      {/* <p style={{ alignSelf: 'center' }}>{formik.initialValues.name}</p> */}
      <TextField
        label="Nome Completo"
        value={formik.values.name}
        initialValue={`${formik.initialValues.name} ${formik.initialValues.lastName}`}
        onInputChange={formik.handleChange('name')}
        disabled
        style={{ marginBottom: '22px' }}
      />
      <TextField
        label="Como gostaria de ser chamado"
        value={formik.values.badgeName}
        disabled
        style={{ marginBottom: '10px' }}
      />

      <S.Row cols={1}>
        <TextField label="Etnia" value={formik.values.ethnicity} disabled />
      </S.Row>

      <S.Row cols={1}>
        <TextField label="Naturalidade" disabled />
      </S.Row>

      <S.Row cols={1}>
        <TextField
          label="Data de nascimento"
          mask={dateMask}
          value={String(formik.values.birthDate)}
          onInputChange={formik.handleChange('birthday')}
          disabled
        />
      </S.Row>

      <S.Row cols={1}>
        <SelectField
          label="Gênero"
          value={formik.values.gender}
          initialValue={formik.initialValues.gender}
          items={[
            { label: 'Masculino', value: 'M' },
            { label: 'Feminino', value: 'F' }
          ]}
          onInputChange={formik.handleChange('gender')}
          disabled
        />
      </S.Row>

      <S.Wrapper onSubmit={formik.handleSubmit}>
        <Button color="primary" fullWidth type="submit">
          Salvar
        </Button>
      </S.Wrapper>
      <Modal
        title="Ação não permitida"
        description="Favor procurar a diretoria técnica ou enviar um email para diretoria04@materdei.com.br, solicitando a edição dos seus dados. "
        show={successModal}
        close={() => setSuccessModal(false)}
        preventAutomateClose
        type="alert"
      />

      <S.LineDivider />
    </>
  )
}

type updatePersonalInfoFormValues = {
  name: string
  lastName?: string
  badgeName?: string
  gender: string
  birthDate?: Date
  ethnicity?: string
}
