import { User } from './user-model'

type SpecialityHospital = {
  name?: string
}

export class Hospital {
  private _hospital_id: number
  private _nickname: string
  private _name: string
  private _friendly_name?: string
  private _uf?: string
  private _user?: User
  private _specialityHospital?: SpecialityHospital[]
  private _hasPartnerCode?: boolean

  constructor(
    hospital_id: number,
    nickname: string,
    name: string,
    friendly_name?: string,
    uf?: string,
    specialityHospital?: SpecialityHospital[],
    hasPartnerCode?: boolean
  ) {
    this._hospital_id = hospital_id
    this._nickname = nickname
    this._name = this.formatName(name)
    this._friendly_name = friendly_name
    this._uf = uf
    this._specialityHospital = specialityHospital
    this._hasPartnerCode = hasPartnerCode
  }

  private formatName(name: string): string {
    const matches = name?.split(/-(.+)/)
    if (matches && matches[1]) {
      return matches[1]
        ?.toLowerCase()
        .replace(/\b\w/g, (l) => l.toUpperCase())
        .replace('S/A', '')
        .replace('Hospital Mater Dei', '')
        .replace('Hospital', '')
        .trim()
    }
    return name
  }

  public get hospital_id(): number {
    return this._hospital_id
  }

  public set hospital_id(hospital_id: number) {
    this._hospital_id = hospital_id
  }

  public get nickname(): string {
    return this._nickname
  }

  public set nickname(nickname: string) {
    this._nickname = nickname
  }

  public get name(): string {
    return this._name
  }

  public set name(name: string) {
    this._name = name
  }

  public get uf(): string | undefined {
    return this._uf
  }

  public set uf(uf: string | undefined) {
    this._uf = uf
  }

  public get friendly_name(): string | undefined {
    return this._friendly_name
  }

  public set friendly_name(friendly_name: string | undefined) {
    this._friendly_name = friendly_name
  }

  public get user(): User | undefined {
    return this._user
  }

  public set user(user: User | undefined) {
    this._user = user
  }

  public get specialityHospital(): SpecialityHospital[] | undefined {
    return this._specialityHospital
  }

  public set specialityHospital(
    specialityHospital: SpecialityHospital[] | undefined
  ) {
    this._specialityHospital = specialityHospital
  }

  public get hasPartnerCode(): boolean | undefined {
    return this._hasPartnerCode
  }

  public set hasPartnerCode(hasPartnerCode: boolean | undefined) {
    this._hasPartnerCode = hasPartnerCode
  }

  toJSON() {
    return {
      hospital_id: this._hospital_id,
      nickname: this._nickname,
      name: this._name,
      user: this._user,
      friendly_name: this._friendly_name,
      uf: this._uf,
      specialityHospital: this._specialityHospital,
      hasPartnerCode: this._hasPartnerCode
    }
  }
}
