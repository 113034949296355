import styled from 'styled-components'

export const ActionIcons = styled.div`
  display: flex;
  gap: 12px;

  svg {
    width: 22px;
    height: 22px;
    cursor: pointer;
  }

  .download-icon {
    path {
      stroke: ${({ theme }) => theme.ds.colors.primary400};
    }
    &:hover {
      path {
        stroke: ${({ theme }) => theme.ds.colors.primary500};
      }
    }
  }
  .thresh-icon {
    path {
      fill: ${({ theme }) => theme.ds.colors.primary400};
    }
    &:hover {
      path {
        fill: ${({ theme }) => theme.ds.colors.primary500};
      }
    }
  }
`
export const DocumentDetails = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  p {
    line-height: 1.4em;
  }
`
