import { SearchAnesthetists } from 'domain/usecases/surgical-scheduling/search-anesthetists'
import { SurgicalSchedulingRepository } from 'repository/interfaces/surgical-scheduling-repository'

export class RemoteSearchAnesthetists implements SearchAnesthetists {
  constructor(
    private readonly surgicalSchedulingRepository: SurgicalSchedulingRepository
  ) {}

  search(params: SearchAnesthetists.Params): Promise<SearchAnesthetists.Model> {
    return this.surgicalSchedulingRepository.searchAnesthetists(params)
  }
}
