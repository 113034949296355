import styled from 'styled-components'

export const Wrapper = styled.div`
  /* display: grid;
  grid-template-columns: repeat(auto-fill, minmax(298px, 1fr));
  grid-gap: 24px 44px; */
  /* max-width: 720px; */
  flex: 1;
  width: 100%;
`
export const Actions = styled.div`
  display: flex;
  margin-top: 35px;
  margin-bottom: 20px;
  width: 100%;
  justify-content: flex-end;
`

export const Title = styled.strong`
  font-weight: ${({ theme }) => theme.ds.typography.weight.bold};
  color: ${({ theme }) => theme.ds.colors.neutral900};
  margin-bottom: 20px;
`
