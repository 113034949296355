import React, { useState } from 'react'
import { useHistory } from 'react-router'
import * as S from './styles'
import Items, { OptionsType } from './items'

import { ReactComponent as HomeIcon } from 'presentation/assets/icons/home.svg'
import { ReactComponent as PlusIcon } from 'presentation/assets/icons/plus.svg'
import { ReactComponent as HelpIcon } from 'presentation/assets/icons/help-circle.svg'
import ProfilePic from '../ProfilePic'
import { useStores } from 'presentation/hooks/use-stores'
import { Profile } from 'common/enum/profile'
import { makeLocalStorageAdapter } from 'main/factories/cache/local-storage-adapter-factory'
import SheetModalNew from '../SheetModalNew'

type bottomMenuActions = {
  onAppointment?: (label: string) => void
}

const BottomMenu = ({ onAppointment }: bottomMenuActions) => {
  const history = useHistory()
  const account = useStores().currentAccount.getCurrentAccount()
  const surgicalOrderContext = useStores().surgicalOrder
  const [handleMenu, setHandleMenu] = useState(false)
  // feature flip novo fluxo pedido cirurgico
  // const doctor = useStores().currentAccount?.getCurrentAccount()?.user
  // const secretary =
  //   useStores().currentAccount?.getCurrentAccount()?.user.secretary_doctor
  // const isActiveSurgicalOrderNew =
  //   doctor.features?.active || secretary?.features?.active

  const redirect = (url: string) => {
    setHandleMenu(false)
    makeLocalStorageAdapter().set('favorite_doctor', {})
    return history.push(url)
  }

  const items: Record<
    Extract<Profile, Profile.PATIENT | Profile.DOCTOR>,
    { path?: string; noFill?: boolean; icon: React.ReactNode }[]
  > = {
    [Profile.PATIENT]: [
      {
        path: '/ajuda',
        noFill: true,
        icon: <HelpIcon />
      },
      {
        path: '/home',
        icon: <HomeIcon />
      },
      {
        path: '/perfil',
        icon: <ProfilePic size="small" />
      }
    ],
    [Profile.DOCTOR]: [
      {
        path: '/home',
        icon: <HomeIcon />
      },
      {
        path: undefined,
        icon: (
          <S.OpenMenuButton
            onClick={() => setHandleMenu(!handleMenu)}
            data-testid="open-menu-button"
          >
            <PlusIcon fill="#ffffff" />
          </S.OpenMenuButton>
        )
      },
      {
        path: '/perfil',
        icon: <ProfilePic size="small" />
      }
    ]
  }

  const handleAppointment = (label: string) => {
    if (onAppointment) {
      onAppointment(label)
      setHandleMenu(false)
    }
  }

  const handleClickItem = (item: OptionsType) => {
    if (item.clearSurgicalOrderContext) surgicalOrderContext.clear()

    item.link.length === 0 ? handleAppointment(item.label) : redirect(item.link)

    // item.link.length === 0
    //   ? handleAppointment(item.label)
    //   : redirect(isActiveSurgicalOrderNew ? item.link : '/pedido/novo')
  }

  return (
    <S.Wrapper>
      {(account.user.role === Profile.DOCTOR ||
        account.user.role === Profile.PATIENT) &&
        items[account.user.role].map((item, index) => {
          return item.path ? (
            <S.MenuItem
              key={index}
              to={item.path}
              activeClassName="active-menu"
              nofill={item.noFill ? 'true' : undefined}
            >
              <div>{item.icon}</div>
            </S.MenuItem>
          ) : (
            <div key={index}>{item.icon}</div>
          )
        })}
      <SheetModalNew
        isOpen={handleMenu}
        style={{
          background: '#FFFFFF',
          boxShadow: 'none'
        }}
        marginbottom="56px"
        onClose={() => setHandleMenu(false)}
        backdrop="true"
        // size={300}
      >
        <S.ModalMenu>
          {Items[account?.user?.role]?.map((item, index) => (
            <S.ModalItem
              key={`${item.label}-${index}`}
              onClick={() => handleClickItem(item)}
              data-testid={item.link.length > 0 && item.label}
            >
              <S.ModalIcon src={item.icon} />
              <S.ModalDescription>{item.label}</S.ModalDescription>
            </S.ModalItem>
          ))}
        </S.ModalMenu>
      </SheetModalNew>
    </S.Wrapper>
  )
}

export default BottomMenu
