import React, { useRef, useState } from 'react'
import * as S from './styles'

import LoadingGif from 'presentation/assets/icons/loading.gif'

import MUIDataTable from 'mui-datatables'
import { isEqual } from 'lodash'
import { PaginationModel } from 'domain/entities/pagination-model'

type TableProps = {
  title?: string
  columns?: {
    name: string
    label: string
    options: { filter?: boolean; sort?: boolean; isDeleteColumn?: boolean }
  }[]
  data?: Array<any>
  checkbox?: boolean
  rowsDelete?: (params: number[]) => void
  onClick?: (evt: any, data: any) => void
  pagination?: boolean
  onPaginate?: TablePaginationFunction
  count?: number
  rowsPerPage?: number
  noItemsDescription?: string
  page?: number
  styleOptions?: {
    cellRelative?: boolean
    rowColor?: string
  }
  loading?: boolean
  style?: React.CSSProperties
}

export type TablePaginationFunction = (
  currentPage: number,
  rowsPerPage: number
) => Promise<PaginationModel | undefined>

const Table = ({
  columns,
  data,
  title,
  checkbox = false,
  pagination = true,
  rowsDelete,
  onClick,
  onPaginate,
  count,
  rowsPerPage,
  noItemsDescription,
  styleOptions,
  page,
  loading = false,
  style
}: TableProps) => {
  const [paginationState, setPaginationState] = useState<{
    rowsPerPage: number
    actualPage: number
  }>({ rowsPerPage: 10, actualPage: 0 })

  const tableRef = useRef<HTMLDivElement>(null)

  const deleteRow = (params: {
    lookup: {
      [dataIndex: number]: boolean
    }
    data: {
      index: number
      dataIndex: number
    }[]
  }) => {
    !!rowsDelete && rowsDelete(params.data.map((value) => value.dataIndex))
  }

  const click = (evt: any, value: any) => {
    return !!onClick && onClick(evt, value)
  }

  const components = () => {
    const tableComponents: any = {
      TableToolbar: () => null
    }

    if (!pagination) {
      tableComponents.TableFooter = () => null
    }
    return tableComponents
  }

  const onPaginationChange = (type: 'page' | 'rows', value: number) => {
    if (type === 'page') {
      onPaginate?.(value, paginationState.rowsPerPage)
      setPaginationState({ ...paginationState, actualPage: value })
    } else if (type === 'rows') {
      onPaginate?.(paginationState.actualPage, value)
      setPaginationState({ ...paginationState, rowsPerPage: value })
    }
    tableRef?.current?.scrollTo?.(0, 0)
  }

  const setColumnProps = {
    style: {
      align: 'center',
      textAlign: 'center',
      justifyContent: 'center',
      backgroundColor: styleOptions?.rowColor
    }
  }

  const getNoMatchComponent = () => {
    if (loading) {
      return (
        <S.LoadingMore>
          <img src={LoadingGif} />
        </S.LoadingMore>
      )
    }
    if (noItemsDescription) return noItemsDescription
    return 'Nenhum registro para ser exibido'
  }

  return (
    <S.Wrapper
      ref={tableRef}
      cellRelative={styleOptions?.cellRelative}
      style={style}
    >
      <MUIDataTable
        title={title}
        data={data && !loading ? data : []}
        components={components()}
        columns={
          columns?.map((column) => ({
            ...column,
            options: {
              ...column.options,
              setCellProps: () => setColumnProps,
              setCellHeaderProps: () => setColumnProps
            }
          })) ?? []
        }
        options={{
          filterType: 'checkbox',
          selectableRowsHideCheckboxes: !checkbox,
          onRowsDelete: (rowsDeleted) => deleteRow(rowsDeleted),
          elevation: 0,
          onRowClick: (evt, value) => click(evt, value),
          responsive: 'standard',
          confirmFilters: false,
          filter: false,
          search: false,
          download: false,
          sortFilterList: false,
          searchOpen: false,
          serverSide: true,
          onChangePage: (val) => onPaginationChange('page', val + 1),
          onChangeRowsPerPage: (val) => onPaginationChange('rows', val),
          count: count,
          rowsPerPage: rowsPerPage,
          page: page && page - 1,
          rowsPerPageOptions: [10, 20, 30],
          textLabels: {
            selectedRows: {
              text: 'Registro selecionado'
            },
            pagination: {
              rowsPerPage: 'Registros por página',
              displayRows: 'de'
            },
            body: {
              noMatch: getNoMatchComponent()
            }
          }
        }}
      />
    </S.Wrapper>
  )
}

function dataCompare(prevData: TableProps, nextData: TableProps) {
  return isEqual(prevData, nextData)
}

export default React.memo(Table, dataCompare)
