import React from 'react'

import HeaderNew from 'presentation/shared/components/HeaderNew'
import ReviewPatientForm from 'presentation/doctor/components/Forms/AddSurgicalOrderNew/ReviewPatient'

const ReviewPatientLayout = () => {
  return (
    <>
      <HeaderNew actualPageTitle="Novo pedido cirúrgico - Revisar paciente" />
      <ReviewPatientForm />
    </>
  )
}

export default ReviewPatientLayout
