import React, { useEffect, useState } from 'react'
import { Hospital } from 'domain/entities/hospital-model'
import { SurgicalOrderModel } from 'domain/entities/surgical-order-model'
import moment from 'moment-timezone'
import { useServices } from 'presentation/hooks/use-services'
import * as S from '../styles'

type SurgicalScheduleDetailsProps = {
  data: SurgicalOrderModel
}

export const SurgicalScheduleDetails = ({
  data
}: SurgicalScheduleDetailsProps) => {
  const [surgicalOrderInfo, setSurgicalOrderInfo] =
    useState<SurgicalOrderModel>()
  const surgicalOrderService = useServices().surgicalOrder

  useEffect(() => {
    surgicalOrderService
      .loadSurgicalOrder(data?.surgical_order_id as number, [
        'surgical_order_id',
        'medicalTeam {',
        '  medical_activity_id',
        '  medicalDescription',
        '  doctorName',
        '  isMain',
        '  doctor_id',
        '}',
        'medicalDischargeDate'
      ])
      .then((res) => setSurgicalOrderInfo(res))
  }, [data])

  const getPrimaryDoctor = () => {
    const SURGEON_CODE = 1
    return (
      surgicalOrderInfo?.medicalTeam?.find(
        (team) => !!team.isMain && +team.medical_activity_id === SURGEON_CODE
      )?.doctorName || '-'
    )
  }

  // const getAnesthetist = () => {
  //   const ANESTHETIST_CODE = 6
  //   return (
  //     surgicalOrderInfo?.medicalTeam?.find(
  //       (team) => +team.medical_activity_id === ANESTHETIST_CODE
  //     )?.doctorName || '-'
  //   )
  // }

  return (
    <S.ContainerData>
      <S.Item>
        <span>Aviso</span>
        <p>{data?.surgical_order_id}</p>
      </S.Item>

      <S.Item>
        <span>Paciente</span>
        <p>{data?.patientName}</p>
      </S.Item>

      <S.Item>
        <span>Convênio</span>
        <p>{data?.scheduleHealthInsurance}</p>
      </S.Item>

      <S.Divider />

      <S.Item>
        <span>Procedimento</span>
        <p>
          {
            data?.procedure?.find(
              (surgicalProcedure) => surgicalProcedure.isMain
            )?.description
          }
        </p>
      </S.Item>

      <S.Item>
        <span>Unidade</span>
        <p>
          {new Hospital(0, data.hospitalName ?? '', data.hospitalName ?? '')
            .name || ''}
        </p>
      </S.Item>

      <S.Item>
        <span>Setor</span>
        <p>{data?.sector}</p>
      </S.Item>

      <S.Item>
        <span>Cirurgião Principal</span>
        <p>{getPrimaryDoctor()}</p>
      </S.Item>

      <S.Item>
        <span>Data e horário da cirurgia</span>
        <p>
          {data?.surgeryDate &&
            moment
              .utc(data.surgeryDate)
              .format('DD [de] MMM[.] [de] YYYY [às] HH[h]mm')}
        </p>
      </S.Item>
    </S.ContainerData>
  )
}
