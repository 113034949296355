import { RepositoryErrors } from 'repository/errors/repository-errors'
import handleGraphQLError from 'repository/graphql/utils/handle-error'
import { SchedulingRepository as ISchedulingRepository } from 'repository/interfaces/scheduling-repository'
import { ApiStatusCode } from 'service/protocols/api-scheduling/api-scheduling-client'
import { IApiSchedulingRepository } from 'service/protocols/api-scheduling/api-scheduling-repository'
import { SearchFiltersSchedules } from 'domain/usecases/scheduling/search-filters-schedules'
import { GetHoursByDay } from 'domain/usecases/scheduling/get-hours-by-day'
import { GetAppointmentsByMonth } from 'domain/usecases/scheduling/get-appointments-by-month'
import { GetRecentAppointments } from 'domain/usecases/scheduling/get-recent-appointments'
import { FinishSchedule } from 'domain/usecases/scheduling/finish-schedule'
import { GetReasonCancel } from 'domain/usecases/scheduling/get-reason-cancel'
import { CancelSchedule } from 'domain/usecases/scheduling/cancel-schedule'
import {
  searchFiltersSchedulesQuery,
  getHoursByDayQuery,
  getReasonCancelQuery
} from 'repository/graphql/queries/scheduling'
import {
  getRecentAppointmentsQuery,
  getAppointmentsByMonthQuery,
  finishScheduleMutation,
  cancelScheduleMutation
} from 'repository/graphql/mutations/scheduling'
// import { makeGraphQLVariable } from 'repository/graphql/utils/make-variables'

export class SchedulingRepository implements ISchedulingRepository {
  constructor(
    private readonly apiSchedulingRepository: IApiSchedulingRepository
  ) {}

  async searchFiltersSchedules(
    params: SearchFiltersSchedules.Params
  ): Promise<SearchFiltersSchedules.Model> {
    const apiSchedulingRepository = this
      .apiSchedulingRepository as IApiSchedulingRepository<SearchFiltersSchedules.Model>

    const query = searchFiltersSchedulesQuery(params)

    const httpResponse = await apiSchedulingRepository.post({
      url: '/graphql',
      body: {
        query: query.query,
        variables: params.data
      },
      query: query.name
    })

    if (
      httpResponse.statusCode &&
      httpResponse.statusCode !== ApiStatusCode.ok
    ) {
      throw handleGraphQLError(RepositoryErrors[httpResponse.error!])
    } else {
      return httpResponse.body as SearchFiltersSchedules.Model
    }
  }

  async getRecentAppointments(
    params: GetRecentAppointments.Params
  ): Promise<GetRecentAppointments.Model> {
    const apiSchedulingRepository = this
      .apiSchedulingRepository as IApiSchedulingRepository<GetRecentAppointments.Model>

    const query = getRecentAppointmentsQuery(params)

    const httpResponse = await apiSchedulingRepository.post({
      url: '/graphql',
      body: {
        query: query.query,
        variables: params.data
      }
    })

    if (
      httpResponse.statusCode &&
      httpResponse.statusCode !== ApiStatusCode.ok
    ) {
      throw handleGraphQLError(RepositoryErrors[httpResponse.error!])
    } else {
      return httpResponse.body as GetRecentAppointments.Model
    }
  }

  async getAppointmentsByMonth(
    params: GetAppointmentsByMonth.Params
  ): Promise<GetAppointmentsByMonth.Model> {
    const apiSchedulingRepository = this
      .apiSchedulingRepository as IApiSchedulingRepository<GetAppointmentsByMonth.Model>

    const query = getAppointmentsByMonthQuery(params)

    const httpResponse = await apiSchedulingRepository.post({
      url: '/graphql',
      body: {
        query: query.query,
        variables: params.data
      },
      query: query.name
    })

    if (
      httpResponse.statusCode &&
      httpResponse.statusCode !== ApiStatusCode.ok
    ) {
      throw handleGraphQLError(RepositoryErrors[httpResponse.error!])
    } else {
      return httpResponse.body as GetAppointmentsByMonth.Model
    }
  }

  async getHoursByDay(
    params: GetHoursByDay.Params
  ): Promise<GetHoursByDay.Model> {
    const apiSchedulingRepository = this
      .apiSchedulingRepository as IApiSchedulingRepository<GetHoursByDay.Model>

    const query = getHoursByDayQuery(params)

    const httpResponse = await apiSchedulingRepository.post({
      url: '/graphql',
      body: {
        query: query.query,
        variables: params.data
      },
      query: query.name
    })

    if (
      httpResponse.statusCode &&
      httpResponse.statusCode !== ApiStatusCode.ok
    ) {
      throw handleGraphQLError(RepositoryErrors[httpResponse.error!])
    } else {
      return httpResponse.body as GetHoursByDay.Model
    }
  }

  async finishSchedule(
    params: FinishSchedule.Params
  ): Promise<FinishSchedule.Model> {
    const apiSchedulingRepository = this
      .apiSchedulingRepository as IApiSchedulingRepository<FinishSchedule.Model>

    const query = finishScheduleMutation(params)

    const httpResponse = await apiSchedulingRepository.post({
      url: '/graphql',
      body: {
        query: query.query,
        variables: params.data
      }
    })

    if (
      httpResponse.statusCode &&
      httpResponse.statusCode !== ApiStatusCode.ok
    ) {
      throw handleGraphQLError(RepositoryErrors[httpResponse.error!])
    } else {
      return httpResponse.body as FinishSchedule.Model
    }
  }

  async getReasonCancel(): Promise<GetReasonCancel.Model> {
    const apiSchedulingRepository = this
      .apiSchedulingRepository as IApiSchedulingRepository<GetReasonCancel.Model>

    const query = getReasonCancelQuery()

    const httpResponse = await apiSchedulingRepository.post({
      url: '/graphql',
      body: {
        query: query.query
      }
    })

    if (
      httpResponse.statusCode &&
      httpResponse.statusCode !== ApiStatusCode.ok
    ) {
      throw handleGraphQLError(RepositoryErrors[httpResponse.error!])
    } else {
      return httpResponse.body as GetReasonCancel.Model
    }
  }

  async cancelSchedule(
    params: CancelSchedule.Params
  ): Promise<CancelSchedule.Model> {
    const apiSchedulingRepository = this
      .apiSchedulingRepository as IApiSchedulingRepository<CancelSchedule.Model>

    const query = cancelScheduleMutation(params)

    const httpResponse = await apiSchedulingRepository.post({
      url: '/graphql',
      body: {
        query: query.query,
        variables: params.data
      }
    })

    if (
      httpResponse.statusCode &&
      httpResponse.statusCode !== ApiStatusCode.ok
    ) {
      throw handleGraphQLError(RepositoryErrors[httpResponse.error!])
    } else {
      return httpResponse.body as CancelSchedule.Model
    }
  }
}
