import React, { useEffect, useState } from 'react'

import { useFormik } from 'formik'
import { sortBy } from 'lodash'
import * as S from './styles'
import { cpfMask, formatMask, phoneMask } from 'presentation/utils/masks'
import { HealthInsurancePlans } from 'domain/usecases/health-insurance/load-health-insurance-plans'
import ButtonNew from 'presentation/shared/components/ButtonNew'
import { ContainerNew } from 'presentation/shared/components/ContainerNew'
import TextFieldNew from 'presentation/shared/components/TextFieldNew'
import SelectFieldNew from 'presentation/shared/components/SelectFieldNew'
import SheetModalNew from 'presentation/shared/components/SheetModalNew'
import Divider from 'presentation/shared/components/DividerNew'
import { ReactComponent as CloseIcon } from 'presentation/assets/icons/close.svg'
import capitalizeFirstLetters from 'common/utils/capitalizeFirstLetters'
import { useStores } from 'presentation/hooks/use-stores'
import { useServices } from 'presentation/hooks/use-services'
import { useHistory, useLocation } from 'react-router'
import { Patient } from 'domain/entities/patient-model'
import {
  WithLoading,
  WithLoadingProps
} from 'presentation/shared/components/HOCs/WithLoading'
import { toast } from 'react-toastify'
import { HealthInsuranceProps } from 'presentation/contexts/surgical-order/surgical-order-context'

const ReviewPatientForm = WithLoading(({ setIsLoading }: WithLoadingProps) => {
  const { state } = useLocation<{
    isEditing?: boolean
  }>()
  const HOSPITAL_SALVADOR_ID = 8
  const surgicalOrderContext = useStores().surgicalOrder
  const patientService = useServices().patient
  const surgicalOrderService = useServices().surgicalOrder
  const healthInsuranceService = useServices().healthInsurance
  const history = useHistory()
  const [healthInsurances, setHealthInsurances] = useState<
    HealthInsurancePlans[]
  >([])
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false)
  const [patientToCompare, setPatientToCompare] = useState<Patient>(
    {} as Patient
  )

  const loadHealthInsurances = async () => {
    try {
      setIsLoading(true)
      setHealthInsurances(
        await healthInsuranceService.loadHealthInsurancePlans({
          hospital_id: surgicalOrderContext.location.hospital?.hospital_id,
          params: ['code', 'description', 'ansRegister']
        })
      )
    } catch (error: any) {
      toast.error('Ocorreu um erro ao buscar os convênios')
      history.goBack()
    } finally {
      setIsLoading(false)
    }
  }

  const handleEmailChange = (email: string) => {
    if (patientToCompare.user) {
      setPatientToCompare({
        ...patientToCompare,
        user: {
          ...patientToCompare.user,
          email
        }
      })
    }
  }

  const handlePhoneChange = (phone: string) => {
    if (patientToCompare.user) {
      setPatientToCompare({
        ...patientToCompare,
        user: {
          ...patientToCompare.user,
          phone
        }
      })
    }
  }

  const handleInsuranceChange = (id: string) => {
    if (!id) {
      surgicalOrderContext.setHealthInsurance({} as HealthInsuranceProps)
      return
    }

    const healthInsurance = healthInsurances?.find(
      (val) => val.code.toString() === id
    )

    if (!healthInsurance) {
      surgicalOrderContext.setHealthInsurance({} as HealthInsuranceProps)
      return
    }

    surgicalOrderContext.setHealthInsurance({
      healthInsurance
    })
    formik.setFieldValue('healthInsurance', healthInsurance)
  }

  const handleHealthCardChange = (healthCard: string) => {
    if (surgicalOrderContext.healthInsurance.healthInsurance) {
      surgicalOrderContext.healthInsurance.healthInsurance.healthCard =
        healthCard
    }
  }

  const isAnyRequiredFieldEmpty = (): boolean => {
    return (
      (!patientToCompare?.name && !patientToCompare?.patient_name) ||
      !patientToCompare?.user?.email ||
      !patientToCompare?.user?.phone ||
      !surgicalOrderContext.healthInsurance.healthInsurance
    )
  }

  const isFieldUpdated = (): boolean => {
    return (
      surgicalOrderContext.patient.patient?.user?.email !==
        patientToCompare?.user?.email ||
      surgicalOrderContext.patient.patient?.user?.phone !==
        patientToCompare?.user?.phone
    )
  }

  // const resetHealthInsuranceField = () => {
  //   if (surgicalOrderContext.healthInsurance.healthInsurance) {
  //     surgicalOrderContext.healthInsurance = {} as HealthInsuranceProps
  //   }
  // }

  const updatePatient = async () => {
    if (!isFieldUpdated()) return
    const phone = patientToCompare.user?.phone
    const formattedPhone = phone ? phone?.replace(/\D/g, '') : phone

    await patientService.updatePatient({
      patient_id: patientToCompare.patient_id!,
      email: patientToCompare.user?.email,
      phone: formattedPhone
    })

    const searchPatientList = surgicalOrderContext.patient.searchPatientList
    const currentPatientIndex =
      surgicalOrderContext.patient.searchPatientList?.findIndex(
        (patient) => patient?.patient_id === patientToCompare.patient_id
      )

    if (Number(currentPatientIndex) !== -1) {
      searchPatientList[Number(currentPatientIndex)] = patientToCompare
    }

    surgicalOrderContext.patient = {
      ...surgicalOrderContext.patient,
      patient: patientToCompare,
      searchPatientList: searchPatientList
    }

    // toast.success('As informações do paciente foram atualizadas!')
  }

  const createSurgicalOrder = async () => {
    if (
      state?.isEditing ||
      surgicalOrderContext.surgicalOrder.surgical_order_id
    )
      return

    const surgicalOrderResponse =
      await surgicalOrderService.createSurgicalOrder({
        hospital_id: surgicalOrderContext.location.hospital?.hospital_id,
        patient: {
          patient_id: surgicalOrderContext.patient.patient?.patient_id,
          patient_name:
            surgicalOrderContext.patient.patient?.name ||
            surgicalOrderContext.patient.patient?.patient_name
        },
        surgical_center: {
          surgical_center_id:
            surgicalOrderContext.location?.surgeryCenter?.surgery_center_id ||
            surgicalOrderContext.location?.surgeryCenter?.surgical_center_id,
          surgical_center:
            surgicalOrderContext.location?.surgeryCenter?.name ||
            surgicalOrderContext.location?.surgeryCenter?.description
        },
        health_insurance: {
          health_insurance_code:
            surgicalOrderContext.healthInsurance.healthInsurance?.code,
          health_insurance_name:
            surgicalOrderContext.healthInsurance.healthInsurance?.description,
          health_insurance_card:
            surgicalOrderContext.healthInsurance.healthInsurance?.healthCard
        }
      })
    surgicalOrderContext.setSurgicalOrder(surgicalOrderResponse)
  }

  const updateSurgicalOrder = async () => {
    if (
      // !state?.isEditing ||
      !surgicalOrderContext.surgicalOrder?.surgical_order_id
    )
      return

    await surgicalOrderService.updateSurgicalOrder({
      surgical_order_id:
        surgicalOrderContext.getSurgicalOrder()?.surgical_order_id,
      patient: {
        patient_id: surgicalOrderContext.getPatient().patient?.patient_id,
        patient_name:
          surgicalOrderContext.getPatient().patient?.name ||
          surgicalOrderContext.getPatient().patient?.patient_name
      },
      health_insurance: {
        health_insurance_id:
          surgicalOrderContext.getSurgicalOrder()?.health_insurance
            ?.health_insurance_id,
        health_insurance_code:
          surgicalOrderContext.getHealthInsurance().healthInsurance?.code,
        health_insurance_name:
          surgicalOrderContext.getHealthInsurance().healthInsurance
            ?.description,
        health_insurance_card:
          surgicalOrderContext.getHealthInsurance()?.healthInsurance?.healthCard
      }
    })

    // toast.success('Os dados do pedido foram atualizados!')
  }

  useEffect(() => {
    if (
      !surgicalOrderContext.location.hospital?.hospital_id ||
      !surgicalOrderContext.patient.patient?.patient_id
    ) {
      return history.push('/home')
    }
    // resetHealthInsuranceField()
    loadHealthInsurances()
    setPatientToCompare({
      ...surgicalOrderContext.patient.patient
    })
  }, [])

  const formik = useFormik({
    initialValues: {} as Patient,
    onSubmit: async () => {
      if (surgicalOrderContext.patient.patient?.user && patientToCompare.user) {
        try {
          setIsLoading(true)
          await updatePatient()
          await updateSurgicalOrder()
          await createSurgicalOrder()

          if (state?.isEditing) {
            history.push('/novo-pedido/resumo')
          } else if (
            surgicalOrderContext.location.hospital?.hospital_id ===
            HOSPITAL_SALVADOR_ID
          ) {
            history.push('/novo-pedido/cooperativa')
          } else {
            history.push('/novo-pedido/procedimentos')
          }
        } catch (err: any) {
          toast.error(`[Dados do paciente] ${err.message}`)
          return history.goBack()
        } finally {
          setIsLoading(false)
        }
      }
    }
  })

  return (
    <>
      <ContainerNew
        title="Dados do paciente"
        subtitle="Confira os dados do paciente"
        titleColor="primary600"
        subtitleColor="neutral700"
        buttonsHasBoxShadow={!state?.isEditing}
        primaryButton={
          <ButtonNew
            onClick={() => {
              isFieldUpdated()
                ? setIsConfirmationModalOpen(true)
                : formik.submitForm()
            }}
            fullWidth
            size="large"
            disabled={isAnyRequiredFieldEmpty()}
          >
            {state?.isEditing ? 'Salvar edição' : 'Próximo'}
          </ButtonNew>
        }
        secondaryButton={
          <ButtonNew size="large" onClick={history.goBack} fullWidth outlined>
            Anterior
          </ButtonNew>
        }
      >
        <S.FormWrapper onSubmit={formik.handleSubmit}>
          {surgicalOrderContext.patient.patient?.user?.cpf && (
            <TextFieldNew
              label="CPF do paciente"
              color="primary600"
              disabled
              value={
                surgicalOrderContext.patient.patient?.user?.cpf
                  ? formatMask(
                      cpfMask,
                      surgicalOrderContext.patient.patient?.user.cpf
                    )
                  : ''
              }
              id="cpf"
              name="cpf"
              mask={cpfMask}
              data-testid="review-patient-cpf-input"
              required
              requiredColor="neutral800"
              labelColor="neutral800"
            />
          )}
          <TextFieldNew
            label="Nome do paciente"
            disabled
            value={
              surgicalOrderContext.patient.patient?.name
                ? capitalizeFirstLetters(
                    surgicalOrderContext.patient.patient?.name
                  )
                : surgicalOrderContext.patient.patient?.patient_name
                ? capitalizeFirstLetters(
                    surgicalOrderContext.patient.patient?.patient_name
                  )
                : ''
            }
            id="name"
            name="name"
            data-testid="review-patient-name-input"
            required
            requiredColor="neutral800"
            labelColor="neutral800"
          />
          <TextFieldNew
            label="E-mail"
            value={patientToCompare.user?.email || ''}
            data-testid="review-patient-email-input"
            required
            requiredColor="neutral800"
            labelColor="neutral800"
            id="email"
            name="email"
            onChange={formik.handleChange}
            onInputChange={handleEmailChange}
          />
          <TextFieldNew
            label="Celular"
            value={patientToCompare.user?.phone || ''}
            data-testid="review-patient-phone-input"
            required
            requiredColor="neutral800"
            labelColor="neutral800"
            id="phone"
            name="phone"
            onInputChange={handlePhoneChange}
            defaultValue="+55"
            mask={phoneMask}
          />
          <SelectFieldNew
            label="Convênio"
            value={
              surgicalOrderContext.healthInsurance.healthInsurance?.code || ''
            }
            items={sortBy(
              healthInsurances.map((healthInsurance) => ({
                label: healthInsurance.description,
                value: healthInsurance.code
              })),
              (val) => val.label
            )}
            id="healthInsurance"
            name="healthInsurance"
            required
            requiredColor="neutral800"
            labelColor="neutral800"
            onInputChange={handleInsuranceChange}
          />
          <TextFieldNew
            label="Número da carteirinha"
            value={
              surgicalOrderContext.healthInsurance.healthInsurance
                ?.healthCard || ''
            }
            placeholder="Número da carteinha"
            maxLength={25}
            id="card"
            name="card"
            onChange={formik.handleChange}
            onInputChange={handleHealthCardChange}
          />
        </S.FormWrapper>
        <SheetModalNew
          isOpen={isConfirmationModalOpen}
          onClose={() => setIsConfirmationModalOpen(false)}
        >
          <S.ModalWrapper>
            <S.Title>
              Atualizar informações
              <CloseIcon onClick={() => setIsConfirmationModalOpen(false)} />
            </S.Title>
            <Divider color="neutral100" />
            <S.Complement>
              <S.ComplementBold>
                Você tem certeza que deseja atualizar as informações do
                paciente?
              </S.ComplementBold>
              <br />
              Esta ação não poderá ser desfeita.
            </S.Complement>
            <S.ModalButtons>
              <ButtonNew
                onClick={() => {
                  setIsConfirmationModalOpen(false)
                  formik.submitForm()
                }}
                fullWidth
              >
                Atualizar informações
              </ButtonNew>
              <ButtonNew
                onClick={() => setIsConfirmationModalOpen(false)}
                fullWidth
                variant="text"
              >
                Cancelar
              </ButtonNew>
            </S.ModalButtons>
          </S.ModalWrapper>
        </SheetModalNew>
      </ContainerNew>
    </>
  )
})

export default ReviewPatientForm
