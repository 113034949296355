import { Patient } from 'domain/entities/patient-model'
import 'main/config/yup'
import { useStores } from 'presentation/hooks/use-stores'
import Button from 'presentation/shared/components/Button'
import { Container } from 'presentation/shared/components/Container'
import TherapeuticPlanDetails from 'presentation/shared/components/TherapeuticPlanDetails'
import TherapeuticPlanList from 'presentation/shared/components/TherapeuticPlanList'
import theme from 'presentation/styles/theme'
import React from 'react'
import { useHistory, useLocation } from 'react-router-dom'

export default function SelectTherapeuticPlanForm() {
  const account = useStores().currentAccount
  const history = useHistory()
  const { state } = useLocation<{
    patient: Patient
  }>()

  return (
    <Container
      noPadding
      actualPageText={'Voltar'}
      actualPageOnClick={() => {
        history.goBack()
      }}
    >
      <TherapeuticPlanDetails
        doctorName={account.getCurrentAccount().user.name}
        patient={state?.patient}
      />
      <Button
        fullWidth
        style={{
          background: theme.colors.white,
          color: theme.colors.primary,
          fontSize: '16px'
        }}
        onClick={() =>
          history.push('/novo-plano-terapeutico/novo', {
            patient: state?.patient
          })
        }
      >
        Novo FAQ
      </Button>
      <TherapeuticPlanList therapeuticPlans={[]} />
    </Container>
  )
}
