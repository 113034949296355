import { SurgicalPendencyRepository as Repository } from 'repository/interfaces/surgical-pendency-repository'
import { AddSurgicalPendency } from 'domain/usecases/surgical-pendency/add-surgical-pendency'
import { IApiRepository } from 'service/protocols/api/api-repository'
import {
  answerSurgicalPendency,
  createSurgicalPendency,
  finishSurgicalPendency,
  validateDocumentPendency,
  removeDocumentFromSurgicalPendency
} from 'repository/graphql/mutations'
import { makeGraphQLVariable } from 'repository/graphql/utils/make-variables'
import { ApiStatusCode } from 'service/protocols/api/api-client'
import handleGraphQLError from 'repository/graphql/utils/handle-error'
import { RepositoryErrors } from 'repository/errors/repository-errors'
import { AnswerSurgicalPendency } from 'domain/usecases/surgical-pendency/answer-surgical-pendency'
import { UploadSurgicalPendencyDocument } from 'domain/usecases/surgical-pendency/upload-surgical-pendency-document'
import { LoadSurgicalPendencyDocument } from 'domain/usecases/surgical-pendency/load-surgical-pendency-document'
import { FinishSurgicalPendency } from 'domain/usecases/surgical-pendency/finish-surgical-pendency'
import { ValidateDocumentPendency } from 'domain/usecases/surgical-pendency/validate-document-pendency'
import { LoadDashboardPendencyCount } from 'domain/usecases/surgical-pendency/load-dashboard-pendency-count'
import { LoadSurgicalOrdersByPendencyStatus } from 'domain/usecases/surgical-pendency/load-surgical-orders-by-pendency-status'
import { LoadSurgicalOrderPendencies } from 'domain/usecases/surgical-pendency/load-surgical-order-pendencies'
import { LoadSurgicalOrderPendenciesHistory } from 'domain/usecases/surgical-pendency/load-surgical-order-pendencies-history'
import { RemoveDocumentFromSurgicalPendency } from 'domain/usecases/surgical-pendency/remove-document-from-surgical-pendency'
import { LoadHelpPendencyHistory } from 'domain/usecases/surgical-order/load-help-pendency-history'
import { CreateHelpPendencyMessage } from 'domain/usecases/surgical-order/create-help-pendency-message'
export class SurgicalPendencyRepository implements Repository {
  constructor(private readonly apiRepository: IApiRepository) {}

  async addSurgicalPendency(
    params: AddSurgicalPendency.Params
  ): Promise<AddSurgicalPendency.Model> {
    const apiRepository = this
      .apiRepository as IApiRepository<AddSurgicalPendency.Model>

    const query = createSurgicalPendency

    const httpResponse = await apiRepository.post({
      url: '/graphql',
      body: {
        query: query.query,
        variables: makeGraphQLVariable(params, 'data')
      },
      query: query.name
    })

    if (httpResponse.statusCode !== ApiStatusCode.ok) {
      throw handleGraphQLError(RepositoryErrors[httpResponse.error!])
    } else {
      return httpResponse.body as AddSurgicalPendency.Model
    }
  }

  async answerSurgicalPendency({
    group_id,
    ...data
  }: AnswerSurgicalPendency.Params): Promise<AnswerSurgicalPendency.Model> {
    const apiRepository = this
      .apiRepository as IApiRepository<AnswerSurgicalPendency.Model>

    const query = answerSurgicalPendency
    const httpResponse = await apiRepository.post({
      url: '/graphql',
      body: {
        query: query.query,
        variables: {
          ...makeGraphQLVariable(data, 'data'),
          ...makeGraphQLVariable(group_id, 'group_id')
        }
      },
      query: query.name
    })

    if (httpResponse.statusCode !== ApiStatusCode.ok) {
      throw handleGraphQLError(RepositoryErrors[httpResponse.error!])
    } else {
      return httpResponse.body as AnswerSurgicalPendency.Model
    }
  }

  async uploadSurgicalPendencyDocument(
    params: UploadSurgicalPendencyDocument.Params
  ): Promise<UploadSurgicalPendencyDocument.Model> {
    const apiRepository = this
      .apiRepository as IApiRepository<UploadSurgicalPendencyDocument.Model>

    const httpResponse = await apiRepository.post({
      url: '/surgical-pendency/upload-attachment',
      body: params.form
    })

    if (
      httpResponse.statusCode &&
      httpResponse.statusCode !== ApiStatusCode.created
    ) {
      throw handleGraphQLError(RepositoryErrors[httpResponse.error!])
    } else {
      return httpResponse.body as UploadSurgicalPendencyDocument.Model
    }
  }

  async loadSurgicalPendencyDocument(
    surgical_order_id: number,
    document_id: number,
    group_id?: string
  ): Promise<LoadSurgicalPendencyDocument.Model> {
    const apiRepository = this
      .apiRepository as IApiRepository<LoadSurgicalPendencyDocument.Model>

    const httpResponse: any = await apiRepository.get(
      {
        url: `/surgical-pendency/${surgical_order_id}/attachment/${document_id}${
          group_id ? `?group_id=${group_id}` : ''
        }`
      },
      true
    )

    const contentType = httpResponse.headers['content-type']
    const data = httpResponse.data

    return {
      contentType,
      data
    }
  }

  async finishSurgicalPendency(
    params: FinishSurgicalPendency.Params
  ): Promise<FinishSurgicalPendency.Model> {
    const apiRepository = this
      .apiRepository as IApiRepository<FinishSurgicalPendency.Model>

    const query = finishSurgicalPendency

    const httpResponse = await apiRepository.post({
      url: '/graphql',
      body: {
        query: query.query,
        variables: makeGraphQLVariable(params, 'data')
      },
      query: query.name
    })

    if (httpResponse.statusCode !== ApiStatusCode.ok) {
      throw handleGraphQLError(RepositoryErrors[httpResponse.error!])
    } else {
      return httpResponse.body as FinishSurgicalPendency.Model
    }
  }

  async validateDocumentPendency(
    params: ValidateDocumentPendency.Params
  ): Promise<ValidateDocumentPendency.Model> {
    const apiRepository = this
      .apiRepository as IApiRepository<ValidateDocumentPendency.Model>

    const query = validateDocumentPendency

    const httpResponse = await apiRepository.post({
      url: '/graphql',
      body: {
        query: query.query,
        variables: params
      },
      query: query.name
    })

    if (httpResponse.statusCode !== ApiStatusCode.ok) {
      throw handleGraphQLError(RepositoryErrors[httpResponse.error!])
    } else {
      return httpResponse.body as ValidateDocumentPendency.Model
    }
  }

  async loadDashboardPendencyCount(
    params?: LoadDashboardPendencyCount.Params
  ): Promise<LoadDashboardPendencyCount.Model> {
    const apiRepository = this
      .apiRepository as IApiRepository<LoadDashboardPendencyCount.Model>

    const httpResponse: any = await apiRepository.get({
      url: '/surgical-pendency/dashboard/status-count',
      params
    })

    return httpResponse.body as LoadDashboardPendencyCount.Model
  }

  async loadSurgicalOrdersByPendencyStatus(
    params: LoadSurgicalOrdersByPendencyStatus.Params
  ): Promise<LoadSurgicalOrdersByPendencyStatus.Model> {
    const apiRepository = this
      .apiRepository as IApiRepository<LoadSurgicalOrdersByPendencyStatus.Model>

    const httpResponse: any = await apiRepository.get({
      url: '/surgical-pendency/by-surgical-pendency-status',
      params
    })

    return httpResponse.body as LoadSurgicalOrdersByPendencyStatus.Model
  }

  async loadSurgicalOrderPendencies(
    surgical_order_id: LoadSurgicalOrderPendencies.Params
  ): Promise<LoadSurgicalOrderPendencies.Model> {
    const apiRepository = this
      .apiRepository as IApiRepository<LoadSurgicalOrderPendencies.Model>

    const httpResponse: any = await apiRepository.get({
      url: `/surgical-pendency/surgical-order/${surgical_order_id}`
    })

    return httpResponse.body as LoadSurgicalOrderPendencies.Model
  }

  async loadSurgicalOrderPendenciesHistory(
    surgical_order_id: LoadSurgicalOrderPendenciesHistory.Params
  ): Promise<LoadSurgicalOrderPendenciesHistory.Model> {
    const apiRepository = this
      .apiRepository as IApiRepository<LoadSurgicalOrderPendenciesHistory.Model>

    const httpResponse: any = await apiRepository.get({
      url: `/surgical-pendency/historic/${surgical_order_id}`
    })

    if (
      httpResponse.statusCode &&
      httpResponse.statusCode !== ApiStatusCode.ok
    ) {
      throw handleGraphQLError(
        RepositoryErrors[httpResponse.error! as keyof typeof RepositoryErrors]
      )
    } else {
      return httpResponse.body as LoadSurgicalOrderPendenciesHistory.Model
    }
  }

  async loadHelpPendencyHistory(
    surgical_pendency_id: LoadHelpPendencyHistory.Params
  ): Promise<LoadHelpPendencyHistory.Model> {
    const apiRepository = this
      .apiRepository as IApiRepository<LoadHelpPendencyHistory.Model>

    const httpResponse: any = await apiRepository.get({
      url: `/active-searchers/${surgical_pendency_id}/historic`
    })

    return httpResponse.body as LoadHelpPendencyHistory.Model
  }

  async createHelpPendencyMessage(
    params: CreateHelpPendencyMessage.Params
  ): Promise<CreateHelpPendencyMessage.Model> {
    const apiRepository = this
      .apiRepository as IApiRepository<CreateHelpPendencyMessage.Model>

    const httpResponse: any = await apiRepository.post({
      url: `/active-searchers/${params.surgical_pendency_id}/historic`,
      body: {
        message: params.message
      }
    })

    return httpResponse.body as CreateHelpPendencyMessage.Model
  }

  async removeDocumentFromSurgicalPendency(
    params: RemoveDocumentFromSurgicalPendency.Params
  ): Promise<RemoveDocumentFromSurgicalPendency.Model> {
    const apiRepository = this
      .apiRepository as IApiRepository<RemoveDocumentFromSurgicalPendency.Model>

    const query = removeDocumentFromSurgicalPendency

    const httpResponse = await apiRepository.post({
      url: `/graphql`,
      body: {
        query: query.query,
        variables: {
          ...makeGraphQLVariable(params.document_id, 'document_id'),
          ...makeGraphQLVariable(
            params.surgical_pendency_id,
            'surgical_pendency_id'
          ),
          ...makeGraphQLVariable(params.group_id, 'group_id')
        }
      },
      query: query.name
    })

    if (
      httpResponse.statusCode &&
      httpResponse.statusCode !== ApiStatusCode.ok
    ) {
      throw handleGraphQLError(RepositoryErrors[httpResponse.error!])
    } else {
      return httpResponse.body as RemoveDocumentFromSurgicalPendency.Model
    }
  }
}
