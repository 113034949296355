import { LoadAmbulatoryOrderDocument } from 'domain/usecases/ambulatory/load-ambulatory-order-document'
import { NewAmbulatoryRepository } from 'repository/interfaces/new-ambulatory-repository'

export class RemoteLoadAmbulatoryOrderDocument
  implements LoadAmbulatoryOrderDocument
{
  constructor(private readonly ambulatoryRepository: NewAmbulatoryRepository) {}

  load(
    params: LoadAmbulatoryOrderDocument.Params
  ): Promise<LoadAmbulatoryOrderDocument.Model> {
    return this.ambulatoryRepository.loadAmbulatoryOrderDocument(params)
  }
}
