import { Cid } from 'domain/entities/cid'
import { Patient } from 'domain/entities/patient-model'
import { TherapeuticPlanModel } from 'domain/entities/therapeutic-plan.model'
import { LoadCid } from 'domain/usecases/surgical-order/load-cid'
import { useFormik } from 'formik'
import 'main/config/yup'
import { ReactComponent as SearchIcon } from 'presentation/assets/icons/search.svg'
import Button from 'presentation/shared/components/Button'
import { CarouselState } from 'presentation/shared/components/Carousel'
import ConditionalCarousel from 'presentation/shared/components/Carousel/ConditionalCarousel'
import Chip from 'presentation/shared/components/Chip'
import { Container } from 'presentation/shared/components/Container'
import SelectField from 'presentation/shared/components/SelectField'
import StepDots from 'presentation/shared/components/StepDots'
import SupportText from 'presentation/shared/components/SupportText'
import TextArea from 'presentation/shared/components/TextArea'
import TextField from 'presentation/shared/components/TextField'
import React, { useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { toast } from 'react-toastify'
import {
  DiagnosisForm,
  DiagnosisFormSchema,
  MOptions,
  NOptions,
  StagingOptions,
  TOptions
} from './Constants'

import FindTherapeuticPlanCidSheet from './FindTherapeuticPlanCidSheet'
import * as S from './styles'

interface TherapeuticPlanDiagnosisProps {
  loadCid: LoadCid
}

export default function TherapeuticPlanDiagnosisForm({
  loadCid
}: TherapeuticPlanDiagnosisProps) {
  const history = useHistory()

  const { state } = useLocation<{
    patient: Patient
    therapeuticPlan: TherapeuticPlanModel
  }>()

  const [diagnosisCarousel, setDiagnosisCarousel] = useState(
    {} as CarouselState
  )
  const [listCid, setListCid] = useState<boolean>(false)
  const [cids, setCids] = useState<Cid[]>()

  const {
    handleChange,
    values,
    setFieldValue,
    isValid,
    isSubmitting,
    handleSubmit
  } = useFormik<DiagnosisForm>({
    validateOnMount: true,
    validateOnBlur: true,
    validateOnChange: true,
    validationSchema: DiagnosisFormSchema,
    initialValues: {
      step: 1,
      cid_id: (state?.therapeuticPlan?.diagnosis as unknown as DiagnosisForm)
        ?.cid_id,
      detailsPreviousTreatment:
        state?.therapeuticPlan?.diagnosis?.detailsPreviousTreatment,
      lymphNodes: state?.therapeuticPlan?.diagnosis?.lymphNodes,
      metastasis: state?.therapeuticPlan?.diagnosis?.metastasis,
      metastasisLocation: state?.therapeuticPlan?.diagnosis?.metastasisLocation,
      pathologicalAnatomy:
        state?.therapeuticPlan?.diagnosis?.pathologicalAnatomy,
      previousTreatments: state?.therapeuticPlan?.diagnosis?.previousTreatments,
      staging: state?.therapeuticPlan?.diagnosis?.staging,
      topographic: state?.therapeuticPlan?.diagnosis?.topographic,
      tumor: state?.therapeuticPlan?.diagnosis?.tumor,
      searchCID: (state?.therapeuticPlan?.diagnosis as unknown as DiagnosisForm)
        ?.cid_id
    },
    onSubmit: (data) => {
      history.push('/novo-plano-terapeutico/resumo', {
        patient: state?.patient,
        therapeuticPlan: {
          ...state?.therapeuticPlan,
          diagnosis: data
        }
      })
    }
  })

  const searchCid = async () => {
    const cid = await loadCid.load({
      fields: ['data{', 'description', 'cid_id}'],
      pagination: { pageSize: 10, pageNumber: 1 },
      query: values?.searchCID
    })
    if (cid.data.length > 0) {
      setCids(cid.data)
      setListCid(true)
    } else {
      toast.warn('Nenhum CID encontrado')
      setCids(undefined)
    }
  }

  const onChipCheck = (value: string) => {
    setFieldValue('previousTreatments', value)
  }

  return (
    <>
      <Container
        title="Diagnóstico"
        titleColor="primary"
        subtitle="Para iniciar busque ou adicione o paciente."
        noPadding
        primaryButton={
          <Button
            fullWidth
            disabled={!isValid || isSubmitting}
            onClick={() => {
              if (diagnosisCarousel.activeIndex === 0) {
                diagnosisCarousel.slideNext()
                setFieldValue('step', 2)
              } else {
                handleSubmit()
              }
            }}
          >
            Continuar
          </Button>
        }
        secondaryButton={
          <SupportText
            onClick={() =>
              history.push('/novo-plano-terapeutico/resumo', {
                patient: state?.patient,
                therapeuticPlan: state?.therapeuticPlan
              })
            }
            color="primary"
          >
            Cancelar
          </SupportText>
        }
        actualPageText="Voltar"
        actualPageOnClick={() => {
          if (diagnosisCarousel.activeIndex !== 0) {
            setFieldValue('step', 1)
            diagnosisCarousel.slidePrev()
          } else {
            history.push('/novo-plano-terapeutico/resumo', {
              patient: state?.patient,
              therapeuticPlan: state?.therapeuticPlan
            })
          }
        }}
      >
        <ConditionalCarousel setState={setDiagnosisCarousel}>
          <>
            <S.Inputs>
              <S.Input>
                <TextField
                  label="CID"
                  required
                  icon={
                    <S.Icon onClick={() => searchCid()}>
                      <SearchIcon width="29px" height="29px" />
                    </S.Icon>
                  }
                  iconPosition="right"
                  iconLocale="outside"
                  name={'searchCID'}
                  onChange={handleChange}
                  value={values.searchCID || ''}
                />
              </S.Input>
              <S.SelectInput>
                <SelectField
                  label="Estadiamento"
                  required
                  name="staging"
                  items={StagingOptions}
                  onChange={handleChange}
                  value={values.staging || ''}
                  placeholder={''}
                />
              </S.SelectInput>
              <S.SelectsGrid>
                <SelectField
                  label="T"
                  required
                  name="tumor"
                  items={TOptions}
                  onChange={handleChange}
                  value={values.tumor || ''}
                  placeholder={''}
                />
                <SelectField
                  label="N"
                  required
                  name="lymphNodes"
                  items={NOptions}
                  onChange={handleChange}
                  value={values.lymphNodes || ''}
                  placeholder={''}
                />
                <SelectField
                  label="M"
                  required
                  name="metastasis"
                  items={MOptions}
                  onChange={handleChange}
                  value={values.metastasis || ''}
                  placeholder={''}
                />
              </S.SelectsGrid>
              <TextField
                label="Diagnóstico (Anátomo-Patológico)"
                required
                name={'pathologicalAnatomy'}
                onChange={handleChange}
                value={values.pathologicalAnatomy || ''}
                placeholder={''}
              />
              <TextField
                label="Diagnóstico topográfico"
                required
                name={'topographic'}
                onChange={handleChange}
                value={values.topographic || ''}
                placeholder={''}
              />
            </S.Inputs>
          </>
          <>
            <S.Inputs>
              <TextArea
                rows={3}
                label="Localização das metástases"
                required
                name={'metastasisLocation'}
                onChange={handleChange}
                value={values.metastasisLocation || ''}
              />
              <S.Label>
                Tratamentos anteriores <strong>*</strong>
              </S.Label>
              <S.ChipsGrid>
                <Chip
                  label="RxT"
                  value={'RxT'}
                  onCheck={onChipCheck}
                  checked={values?.previousTreatments === 'RxT'}
                />
                <Chip
                  label="QT"
                  value={'QT'}
                  onCheck={onChipCheck}
                  checked={values?.previousTreatments === 'QT'}
                />
                <Chip
                  label="TMO"
                  value={'TMO'}
                  onCheck={onChipCheck}
                  checked={values?.previousTreatments === 'TMO'}
                />
                <Chip
                  label="Outros"
                  value={'Others'}
                  onCheck={onChipCheck}
                  checked={values?.previousTreatments === 'Others'}
                />
                <Chip
                  label="Cirurgia"
                  value={'Surgery'}
                  onCheck={onChipCheck}
                  checked={values?.previousTreatments === 'Surgery'}
                />
                <Chip
                  label="Não realizou nenhum tratamento anterior"
                  value={'no previous treatment'}
                  onCheck={onChipCheck}
                  checked={
                    values?.previousTreatments === 'no previous treatment'
                  }
                />
              </S.ChipsGrid>
              <TextArea
                rows={3}
                label="Especificar"
                required
                name={'detailsPreviousTreatment'}
                onChange={handleChange}
                value={values.detailsPreviousTreatment || ''}
              />
            </S.Inputs>
          </>
        </ConditionalCarousel>
        <div style={{ margin: 'auto' }}>
          <StepDots active={diagnosisCarousel.activeIndex} total={2} />
        </div>
      </Container>
      <FindTherapeuticPlanCidSheet
        close={() => setListCid(false)}
        show={listCid}
        submit={(cid) => {
          if (cid) {
            setFieldValue('cid_id', cid?.cid_id)
            setFieldValue('searchCID', cid?.cid_id)
          }
          setListCid(false)
        }}
        cidList={cids}
      />
    </>
  )
}
