import styled from 'styled-components'

export const Wrapper = styled.div`
  /*
    flex: 1;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(298px, 1fr));
    grid-gap: 24px 44px;
    max-width: 720px;
  */

  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
`
export const Actions = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 40px 0 10px 0;
`

export const LineDivider = styled.hr`
  height: 0px;
  border: 1px solid #dadee2;
  background: #dadee2;
  margin-top: 12px;
  width: 100%;
  margin: 40px 0;
`

export const Title = styled.strong`
  font-weight: ${({ theme }) => theme.ds.typography.weight.bold};
  color: ${({ theme }) => theme.ds.colors.neutral900};
  margin-bottom: 20px;
`
