import styled from 'styled-components'

export const Wrapper = styled.main`
  h2 {
    margin-top: 20px;
    font-weight: ${({ theme }) => theme.font.black};
  }
  p {
    margin-bottom: 20px;
    line-height: 24px;
  }
`

export const CardWrapper = styled.div`
  display: flex;
  height: auto;
  width: 100%;
  position: relative;
  padding: 0.7em 1.5em;

  background-color: white;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 10px;
  min-height: 80px;

  h3 {
    font-size: 1em;
    color: ${({ theme }) => theme.colors.primary};
    font-size: ${({ theme }) => theme.font.sizes.small};
    font-weight: ${({ theme }) => theme.font.bold};
    margin-bottom: 5px;
    display: flex;
    align-items: center;

    svg {
      path {
        fill: ${({ theme }) => theme.colors.primary};
      }
    }
  }

  p {
    line-height: 17px;
    font-size: ${({ theme }) => theme.ds.typography.size.small};
    font-weight: ${({ theme }) => theme.font.light};
    color: ${({ theme }) => theme.colors.gray};
  }

  .password-validity {
    margin-bottom: 10px;
  }

  .password-description {
    line-height: 25px;
    font-weight: ${({ theme }) => theme.font.light};
    margin-bottom: 6px;
  }

  .procedure-title {
    svg {
      rect {
        fill: transparent;
      }
    }
  }

  .procedure-observation-title {
    font-size: 0.96em;
  }

  .procedure-container {
    display: flex;
    gap: 20px;

    p {
      margin-bottom: 9px;
    }
  }

  .procedure-description {
    margin-bottom: 9px;
  }

  strong {
    line-height: 11px;
    font-size: ${({ theme }) => theme.font.sizes.small};
    font-weight: ${({ theme }) => theme.font.bold};
    color: ${({ theme }) => theme.colors.gray};
  }

  .strong-primary {
    color: #025146;
  }
`

export const Divider = styled.div`
  height: 1px;
  background: ${({ theme }) => theme.ds.colors.primary100};
  margin: 3px 0;

  &:nth-last-child(2) {
    background: ${({ theme }) => theme.ds.colors.neutral200};
  }
`

export const GreenCard = styled.div`
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  border-radius: ${({ theme }) => theme.ds.typography.border.radius.small};
  background-color: ${({ theme }) => theme.ds.colors.primary50};
  color: ${({ theme }) => theme.colors.primary};
  min-height: 81px;
`

export const NewCard = styled.main`
  background-color: ${({ theme }) => theme.ds.colors.neutral50};
  font-size: ${({ theme }) => theme.ds.typography.size.xsmall};
  border-radius: ${({ theme }) => theme.ds.typography.border.radius.small};
  padding: 10px 10px 10px 10px;
  margin-bottom: 8px;
  box-shadow: 0px 1px 2px #e1e1e1;

  p {
    margin: 0;
  }

  .card-description {
    font-weight: ${({ theme }) => theme.ds.typography.weight.bold};
  }

  .card-description-opme {
    color: ${({ theme }) => theme.ds.colors.primary600};
  }

  .card-quantities {
    display: flex;
    text-align: justify;

    p {
      margin-right: 1em;
    }
  }
`
