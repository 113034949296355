import React, { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router'
import * as yup from 'yup'
import { toast } from 'react-toastify'
import { useFormik } from 'formik'

import {
  WithLoading,
  WithLoadingProps
} from 'presentation/shared/components/HOCs/WithLoading'
import { useServices } from 'presentation/hooks/use-services'
import { useStores } from 'presentation/hooks/use-stores'
import { ContainerNew } from 'presentation/shared/components/ContainerNew'
import HeadingNew from 'presentation/shared/components/HeadingNew'
import SelectFieldNew from 'presentation/shared/components/SelectFieldNew'
import RadioButtonNew from 'presentation/shared/components/RadioButtonNew'
import ButtonNew from 'presentation/shared/components/ButtonNew'
import * as S from './styles'
import { LocationProps } from 'presentation/contexts/surgical-order/surgical-order-context'
import { SurgeryCenterNew } from 'domain/entities/surgery-center-model-new'
import { HospitalNew } from 'domain/entities/hospital-model-new'

const SelectLocationForm = WithLoading(({ setIsLoading }: WithLoadingProps) => {
  const { state } = useLocation<{
    isEditing?: boolean
  }>()
  const surgicalOrderService = useServices().surgicalOrder
  const surgicalOrderContext = useStores().surgicalOrder
  const hospitalService = useServices().hospital
  const [hospitals, setHospitals] = useState<HospitalNew[]>([])
  const [surgeryCenters, setSurgeryCenters] = useState<SurgeryCenterNew[]>([])
  const history = useHistory()
  const locationContext = surgicalOrderContext.getLocation()

  const loadHospitalsByDoctor = async () => {
    try {
      setIsLoading(true)
      const loadHospitals = await hospitalService.loadHospitalsByDoctor()
      setHospitals(loadHospitals)

      if (locationContext.hospital?.hospital_id) {
        loadSurgeryCenters(locationContext.hospital?.hospital_id)
      }
    } catch (error: any) {
      toast.error('Ocorreu um erro ao buscar as unidades')
    } finally {
      setIsLoading(false)
    }
  }

  const loadSurgeryCenters = async (hospital_id: number) => {
    try {
      setIsLoading(true)
      setSurgeryCenters(
        await hospitalService.loadSurgeryCenters({ hospital_id })
      )
    } catch (error: any) {
      toast.error('Ocorreu um erro ao buscar os centros cirúrgicos')
    } finally {
      setIsLoading(false)
    }
  }

  const handleChangeHospital = async (id: string) => {
    await formik.setFieldValue('hospital', undefined)
    await formik.setFieldValue('surgeryCenter', undefined)
    const hospital = hospitals?.find(
      (hospital) => hospital.hospital_id == Number(id)
    )
    if (hospital) {
      formik.setFieldValue('hospital', hospital)
      loadSurgeryCenters(hospital.hospital_id)
    } else {
      formik.setFieldValue('hospital', undefined)
      formik.setFieldValue('surgeryCenter', undefined)
    }
  }

  async function handleChangeSurgeryCenter(surgeryCenter: SurgeryCenterNew) {
    if (surgeryCenter) {
      await formik.setFieldValue('surgeryCenter', surgeryCenter)
    } else {
      await formik.setFieldValue('surgeryCenter', undefined)
    }
  }

  const updateSurgicalOrder = async () => {
    if (!state?.isEditing) return

    const surgicalOrderContextLocation = surgicalOrderContext.getLocation()

    try {
      setIsLoading(true)
      await surgicalOrderService.updateSurgicalOrder({
        surgical_order_id:
          surgicalOrderContext.getSurgicalOrder()?.surgical_order_id,
        patient: {
          patient_id:
            surgicalOrderContext.getSurgicalOrder()?.patient?.patient_id,
          patient_name:
            surgicalOrderContext.getSurgicalOrder()?.patient?.patient_name
        },
        hospital_id: surgicalOrderContextLocation.hospital?.hospital_id,
        surgical_center: {
          surgical_center_id:
            surgicalOrderContext.location?.surgeryCenter?.surgery_center_id ||
            surgicalOrderContext.location?.surgeryCenter?.surgical_center_id,
          surgical_center:
            surgicalOrderContext.location?.surgeryCenter?.name ||
            surgicalOrderContext.location?.surgeryCenter?.description
        }
      })

      // toast.success('Os dados do pedido foram atualizados!')
    } catch (err: any) {
      toast.error(err.message)
    } finally {
      setIsLoading(false)
    }
  }

  const formik = useFormik({
    initialValues: {
      hospital: locationContext.hospital ?? '',
      surgeryCenter: locationContext.surgeryCenter ?? ''
    } as LocationProps,
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      const { hospital, surgeryCenter } = values
      surgicalOrderContext.setLocation({
        hospital,
        surgeryCenter
      })

      try {
        await updateSurgicalOrder()

        if (state?.isEditing) {
          history.push('/novo-pedido/resumo')
        } else {
          history.push('/novo-pedido/paciente')
        }
      } catch (error: any) {
        toast.error(`[Local do pedido] ${error?.message}`)
      } finally {
        setIsLoading(false)
      }
    }
  })

  useEffect(() => {
    loadHospitalsByDoctor()
  }, [])

  return (
    <ContainerNew
      title="Local"
      titleColor="primary600"
      subtitle="Onde o procedimento será realizado?"
      buttonsHasBoxShadow={!state?.isEditing}
      primaryButton={
        <ButtonNew
          fullWidth
          size="large"
          onClick={formik.submitForm}
          disabled={
            !!formik.errors.hospital ||
            !!formik.errors.surgeryCenter ||
            !formik.values.hospital ||
            !formik.values.surgeryCenter
          }
        >
          {state?.isEditing ? 'Salvar edição' : 'Próximo'}
        </ButtonNew>
      }
    >
      <S.FormWrapper
        style={{ marginTop: '24px' }}
        onSubmit={formik.handleSubmit}
      >
        <SelectFieldNew
          placeholder="Selecione a unidade"
          label="Unidade"
          name="hospital.hospital_id"
          items={hospitals?.map(({ name, friendly_name, hospital_id }) => ({
            value: hospital_id,
            label: friendly_name ?? name
          }))}
          onInputChange={handleChangeHospital}
          value={formik.values.hospital?.hospital_id ?? ''}
          required
          requiredColor="neutral900"
        />
        {formik.values.hospital?.hospital_id && (
          <S.SurgeryCenters>
            <HeadingNew
              as="label"
              required
              requiredColor="neutral900"
              color="neutral900"
              size="small"
              weight="normal"
            >
              Unidade cirúrgica
            </HeadingNew>
            {surgeryCenters?.map((center, index) => (
              <RadioButtonNew
                key={index}
                name="surgeryCenter.surgery_center_id"
                labelFor={`radio-${index}`}
                label={center.name}
                showBackground={false}
                value={center.surgery_center_id ?? ''}
                onCheck={() => handleChangeSurgeryCenter(center)}
                checked={
                  formik.values.surgeryCenter?.surgery_center_id ===
                  center.surgery_center_id
                }
              />
            ))}
          </S.SurgeryCenters>
        )}
      </S.FormWrapper>
    </ContainerNew>
  )
})

export default SelectLocationForm

const validationSchema = yup.object().shape({
  hospital: yup.object().required(),
  surgeryCenter: yup.mixed().required()
})
