import { SearchAmbulatoryOrders } from 'domain/usecases/ambulatory/search-ambulatory-order'
import { NewAmbulatoryRepository } from 'repository/interfaces/new-ambulatory-repository'

export class RemoteSearchAmbulatoryOrders implements SearchAmbulatoryOrders {
  constructor(private readonly ambulatoryRepository: NewAmbulatoryRepository) {}

  search(
    params: SearchAmbulatoryOrders.Params
  ): Promise<SearchAmbulatoryOrders.Model> {
    return this.ambulatoryRepository.searchAmbulatoryOrders(params)
  }
}
