import { ApiClientImplementation } from 'infra/api-client/api-client'

import { PrepareApiRequestDecorator } from 'main/decorators/prepare-api-request-decorator/prepare-api-request-decorator'
import { makeLocalStorageAdapter } from 'main/factories/cache/local-storage-adapter-factory'
import { ApiRepository } from 'repository/api-repository'
import { DoctorRepository } from 'repository/repositories/doctor/doctor-repository'
import { RemoteLoadDoctorSchedule } from 'service/usecases/load-doctor-schedule/remote-load-doctor-schedule'
import DoctorService from 'presentation/contexts/services/doctor-service'
import { RemoteSearchDoctors } from 'service/usecases/search-doctors/remote-search-doctors'
import { RemoteFinishDoctorSurgicalOrder } from 'service/usecases/finish-doctor-surgical-order/remote-finish-doctor-surgical-order'
import { RemoteLoadDoctorInfo } from 'service/usecases/load-doctor-info/remote-load-doctor-info'
import { RemoteLoadDoctor } from 'service/usecases/load-doctor/remote-load-doctor'
import { RemoteLoadDoctorByInviteToken } from 'service/usecases/load-doctor-by-invite-token/remote-load-doctor-by-invite-token'
import { RemoteUpdateDoctor } from 'service/usecases/update-doctor/remote-update-doctor'
import { RemoteLoadDoctorPendencies } from 'service/usecases/load-doctor-pendencies/remote-load-doctor-pendencies'
import { RemoteLoadRecommendedDoctors } from 'service/usecases/load-recommended-doctors/remote-load-recommended-doctors'
import { RemoteLoadDoctorWithRecommendation } from 'service/usecases/load-doctor-with-recommendation/remote-load-doctor-with-recommendation'
import { RemoteResendDoctorInvite } from 'service/usecases/resend-doctor-invite/remote-resend-doctor-invite'
import { RemoteUploadDoctorDocument } from 'service/usecases/upload-doctor-document/remote-upload-doctor-document'
import { RemoteLoadDocumentExternalCertifications } from 'service/usecases/load-documents-external-certifications/remote-load-documents-external-certifications'
import { RemoteLoadDoctorDocument } from 'service/usecases/load-doctor-document.ts/remote-load-doctor-document'
import { RemoteAddFavoriteSurgicalOrder } from 'service/usecases/add-favorite-doctor/remote-add-favorite-surgical-order'
import { RemoteFinishDoctorRecommendation } from 'service/usecases/finish-doctor-recommendation/remote-finish-doctor-recommendation'
import { RemoteLoadMedicalTeams } from 'service/usecases/load-medical-teams/remote-load-medical-teams'
import { RemoteLoadAllDoctorRegisters } from 'service/usecases/load-all-doctor-registers/remote-load-all-doctor-registers'
import { GetRequiredDoctorDocumentsImpl } from 'service/usecases/get-required-doctor-documents/get-required-doctor-documents-imp'
import { RemoteCompleteDoctorRegister } from '../../../../../service/usecases/complete-doctor-register/remote-complete-doctor-register'
import { RemoteCreateDoctorRegisterStepPendency } from 'service/usecases/create-doctor-register-step-pendency/remote-create-doctor-register-step-pendency'
import { RemoteSendDoctorRegisterChangesToAnalysis } from '../../../../../service/usecases/send-doctor-register-changes-to-analysis/remote-send-doctor-register-changes-to-analysis'
import { RemoteLoadDoctorRegisterPendenciesGroupedDate } from '../../../../../service/usecases/load-doctor-register-pendencies-grouped-date/remote-load-doctor-register-pendencies-grouped-date'
import { RemoteUpdateDoctorRegisterStatus } from '../../../../../service/usecases/update-doctor-register-status/remote-update-doctor-register-status'
import { RemoteLinkSecretary } from 'service/usecases/link-secretary/remote-link-secretary'
import { RemoteLoadAllSurgicalOrderByDoctor } from 'service/usecases/load-all-surgical-order-by-doctor/remote-load-all-surgical-order-by-doctor'
import { RemoteDoctorLoadSurgeryRequestCount } from 'service/usecases/doctor-load-surgery-request-count/remote-doctor-load-surgery-request-count'
import { RemoteLoadDoctorDaysAvailable } from 'service/usecases/load-doctor-days-available/remote-load-days-available'
import { RemoteLoadSurgicalOrderByDoctor } from 'service/usecases/load-surgical-order-by-doctor/remote-load-surgical-order-by-doctor'
import { RemoteGetMyProductivityInfo } from 'service/usecases/get-my-productivity-info/remote-get-my-productivity-info'

export default function makeDoctorService() {
  const apiRepository = new PrepareApiRequestDecorator(
    makeLocalStorageAdapter(),
    new ApiRepository(new ApiClientImplementation())
  )

  const doctorRepository = new DoctorRepository(apiRepository)

  const loadDoctorSchedule = new RemoteLoadDoctorSchedule(doctorRepository)
  const searchDoctors = new RemoteSearchDoctors(doctorRepository)

  const finishDoctorSurgicalOrder = new RemoteFinishDoctorSurgicalOrder(
    doctorRepository
  )
  const loadDoctorInfo = new RemoteLoadDoctorInfo(doctorRepository)
  const loadDoctor = new RemoteLoadDoctor(doctorRepository)
  const loadDoctorPendencies = new RemoteLoadDoctorPendencies(doctorRepository)

  const loadDoctorByInviteToken = new RemoteLoadDoctorByInviteToken(
    doctorRepository
  )

  const finishDoctorRecommendation = new RemoteFinishDoctorRecommendation(
    doctorRepository
  )

  const updateDoctor = new RemoteUpdateDoctor(doctorRepository)

  const loadDoctorWithRecommendation = new RemoteLoadDoctorWithRecommendation(
    doctorRepository
  )
  const uploadDoctorDocument = new RemoteUploadDoctorDocument(doctorRepository)
  const resendDoctorInvite = new RemoteResendDoctorInvite(doctorRepository)

  const loadDocumentsExternalCertifications =
    new RemoteLoadDocumentExternalCertifications(doctorRepository)

  const loadRecommendedDoctors = new RemoteLoadRecommendedDoctors(
    doctorRepository
  )

  const loadDoctorDocument = new RemoteLoadDoctorDocument(doctorRepository)

  const addFavoriteSurgicalOrder = new RemoteAddFavoriteSurgicalOrder(
    doctorRepository
  )

  const loadMedicalTeams = new RemoteLoadMedicalTeams(doctorRepository)

  const loadAllDoctorRegisters = new RemoteLoadAllDoctorRegisters(
    doctorRepository
  )

  const completeDoctorRegister = new RemoteCompleteDoctorRegister(
    doctorRepository
  )

  const getRequiredDoctorDocuments = new GetRequiredDoctorDocumentsImpl()

  const createDoctorRegisterPendency =
    new RemoteCreateDoctorRegisterStepPendency(doctorRepository)

  const sendDoctorRegisterChangesToAnalysis =
    new RemoteSendDoctorRegisterChangesToAnalysis(doctorRepository)

  const loadDoctorRegisterPendenciesGroupedDate =
    new RemoteLoadDoctorRegisterPendenciesGroupedDate(doctorRepository)

  const updateDoctorRegisterStatus = new RemoteUpdateDoctorRegisterStatus(
    doctorRepository
  )
  const linkSecretary = new RemoteLinkSecretary(doctorRepository)

  const loadAllSurgicalOrdersByDoctor = new RemoteLoadAllSurgicalOrderByDoctor(
    doctorRepository
  )

  const loadSurgeryRequestCount = new RemoteDoctorLoadSurgeryRequestCount(
    doctorRepository
  )
  const loadSchedulingSurgeryRequestCount =
    new RemoteDoctorLoadSurgeryRequestCount(doctorRepository)

  const loadDoctorDaysAvailable = new RemoteLoadDoctorDaysAvailable(
    doctorRepository
  )

  const loadSurgicalOrdersByDoctor = new RemoteLoadSurgicalOrderByDoctor(
    doctorRepository
  )

  const getMyProductivityInfo = new RemoteGetMyProductivityInfo(
    doctorRepository
  )

  return new DoctorService({
    loadDoctorDaysAvailable,
    loadDoctorSchedule,
    searchDoctors,
    finishDoctorSurgicalOrder,
    loadDoctorInfo,
    loadDoctor,
    loadDoctorByInviteToken,
    updateDoctor,
    loadDocumentsExternalCertifications,
    uploadDoctorDocument,
    loadSurgeryRequestCount,
    loadSchedulingSurgeryRequestCount,
    loadAllSurgicalOrdersByDoctor,
    loadDoctorPendencies,
    loadRecommendedDoctors,
    loadDoctorWithRecommendation,
    resendDoctorInvite,
    loadDoctorDocument,
    addFavoriteSurgicalOrder,
    finishDoctorRecommendation,
    loadMedicalTeams,
    loadAllDoctorRegisters,
    getRequiredDoctorDocuments,
    createDoctorRegisterPendency,
    completeDoctorRegister,
    sendDoctorRegisterChangesToAnalysis,
    loadDoctorRegisterPendenciesGroupedDate,
    updateDoctorRegisterStatus,
    linkSecretary,
    loadSurgicalOrdersByDoctor,
    getMyProductivityInfo
  })
}
