import { IApiRepository } from 'service/protocols/api/api-repository'
import { ApiStatusCode } from 'service/protocols/api/api-client'
import { InternalServerError, NoAvailableHoursError } from 'common/errors'
import { SurgicalSchedulingRepository as Repository } from 'repository/interfaces/surgical-scheduling-repository'
import { GetHospitals } from 'domain/usecases/surgical-scheduling/get-hospitals'
import { GetSurgeryCenter } from 'domain/usecases/surgical-scheduling/get-surgery-center'
import { SearchEquipaments } from 'domain/usecases/surgical-scheduling/search-equipaments'
import { SearchAnesthetists } from 'domain/usecases/surgical-scheduling/search-anesthetists'
import { GetPredictedTime } from 'domain/usecases/surgical-scheduling/get-predicted-time'
import { GetAvailableDays } from 'domain/usecases/surgical-scheduling/get-available-days'
import { GetAvailableHours } from 'domain/usecases/surgical-scheduling/get-available-hours'
import { SendSurgicalScheduling } from 'domain/usecases/surgical-scheduling/send-surgical-scheduling'
import { GetSurgicalSchedulingDetails } from 'domain/usecases/surgical-scheduling/get-surgical-scheduling-details'
import { CheckAvailableSchedulingDate } from 'domain/usecases/surgical-scheduling/check-available-scheduling-date'

export class SurgicalSchedulingRepository implements Repository {
  constructor(private readonly apiRepository: IApiRepository) {}

  async getHospitals(): Promise<GetHospitals.Model> {
    const apiRepository = this
      .apiRepository as IApiRepository<GetHospitals.Model>

    const httpResponse: any = await apiRepository.get({
      url: '/hospitals'
    })

    if (httpResponse.statusCode !== ApiStatusCode.ok) {
      throw new InternalServerError()
    } else {
      return httpResponse.body as GetHospitals.Model
    }
  }

  async getSurgeryCenterByHospitalCode(
    params: GetSurgeryCenter.Params
  ): Promise<GetSurgeryCenter.Model> {
    const apiRepository = this
      .apiRepository as IApiRepository<GetSurgeryCenter.Model>

    const httpResponse: any = await apiRepository.get({
      url: `/hospitals/${params.code}/surgery-center`
    })

    if (httpResponse.statusCode !== ApiStatusCode.ok) {
      throw new InternalServerError()
    } else {
      return httpResponse.body as GetSurgeryCenter.Model
    }
  }

  async searchEquipaments(
    params: SearchEquipaments.Params
  ): Promise<SearchEquipaments.Model> {
    const apiRepository = this
      .apiRepository as IApiRepository<SearchEquipaments.Model>

    const httpResponse: any = await apiRepository.get({
      url: `/equipaments/search?name=${params.term}`
    })

    if (httpResponse.statusCode !== ApiStatusCode.ok) {
      throw new InternalServerError()
    } else {
      return httpResponse.body as SearchEquipaments.Model
    }
  }

  async searchAnesthetists(
    params: SearchAnesthetists.Params
  ): Promise<SearchAnesthetists.Model> {
    const apiRepository = this
      .apiRepository as IApiRepository<SearchAnesthetists.Model>

    const { name, limit, page } = params
    const queryString = `?name=${name}&limit=${limit}&page=${page}`

    const httpResponse: any = await apiRepository.get({
      url: `/anesthetists/search${queryString}`
    })

    if (httpResponse.statusCode !== ApiStatusCode.ok) {
      throw new InternalServerError()
    } else {
      return httpResponse.body as SearchAnesthetists.Model
    }
  }

  async getPredictedTime(
    params: GetPredictedTime.Params
  ): Promise<GetPredictedTime.Model> {
    const apiRepository = this
      .apiRepository as IApiRepository<GetPredictedTime.Model>

    const httpResponse: any = await apiRepository.get({
      url: `/surgeries/${params.surgery_id}/doctor/${params.doctor_id}`
    })

    if (httpResponse.statusCode !== ApiStatusCode.ok) {
      throw new InternalServerError()
    } else {
      return httpResponse.body as GetPredictedTime.Model
    }
  }

  async getAvailableDays(
    params: GetAvailableDays.Params
  ): Promise<GetAvailableDays.Model> {
    const apiRepository = this
      .apiRepository as IApiRepository<GetAvailableDays.Model>

    const httpResponse: any = await apiRepository.get({
      url: '/calendar/available-days',
      params
    })

    if (httpResponse.statusCode !== ApiStatusCode.ok) {
      throw new InternalServerError()
    } else {
      return httpResponse.body as GetAvailableDays.Model
    }
  }

  async getAvailableHours(
    params: GetAvailableHours.Params
  ): Promise<GetAvailableHours.Model> {
    const apiRepository = this
      .apiRepository as IApiRepository<GetAvailableHours.Model>

    const httpResponse: any = await apiRepository.get({
      url: '/appointments/available-hours',
      params
    })

    if (httpResponse.statusCode !== ApiStatusCode.ok) {
      throw new InternalServerError()
    } else {
      return httpResponse.body as GetAvailableHours.Model
    }
  }

  async sendSurgicalScheduling(
    body: SendSurgicalScheduling.Params
  ): Promise<SendSurgicalScheduling.Model> {
    const apiRepository = this
      .apiRepository as IApiRepository<SendSurgicalScheduling.Model>

    const httpResponse: any = await apiRepository.post({
      url: '/schedulings',
      body
    })

    if (
      httpResponse.statusCode == ApiStatusCode.badRequest &&
      httpResponse.body.data.error === 'No available hours'
    ) {
      throw new NoAvailableHoursError()
    } else if (httpResponse.statusCode !== ApiStatusCode.created) {
      throw new InternalServerError()
    } else {
      return httpResponse.body as SendSurgicalScheduling.Model
    }
  }

  async getSurgicalSchedulingDetails(
    params: GetSurgicalSchedulingDetails.Params
  ): Promise<GetSurgicalSchedulingDetails.Model> {
    const apiRepository = this
      .apiRepository as IApiRepository<GetSurgicalSchedulingDetails.Model>

    const httpResponse: any = await apiRepository.get({
      url: '/schedulings/details',
      params
    })

    if (httpResponse.statusCode !== ApiStatusCode.ok) {
      throw new InternalServerError()
    } else {
      return httpResponse.body as GetSurgicalSchedulingDetails.Model
    }
  }

  async checkAvailableSchedulingDate(
    params: CheckAvailableSchedulingDate.Params
  ): Promise<CheckAvailableSchedulingDate.Model> {
    const apiRepository = this
      .apiRepository as IApiRepository<CheckAvailableSchedulingDate.Model>

    const { doctorId, date, hour } = params
    const queryString = `?doctorId=${doctorId}&date=${date}&hour=${hour}`

    const httpResponse: any = await apiRepository.get({
      url: `/doctor/validate-calendar${queryString}`
    })

    if (httpResponse.statusCode !== ApiStatusCode.ok) {
      throw new InternalServerError()
    } else {
      return httpResponse.body as CheckAvailableSchedulingDate.Model
    }
  }
}
