import React, { useEffect, useState } from 'react'
import { ContainerNew } from 'presentation/shared/components/ContainerNew'
import HeadingNew from 'presentation/shared/components/HeadingNew'
import SupportTextNew from 'presentation/shared/components/SupportTextNew'
import ButtonNew from 'presentation/shared/components/ButtonNew'
import { useFormik } from 'formik'
import DatePickerNew from 'presentation/shared/components/Datepicker'
import CheckboxNew from 'presentation/shared/components/CheckboxNew'
import SelectFieldNew from 'presentation/shared/components/SelectFieldNew'
import TextAreaNew from 'presentation/shared/components/TextAreaNew'
import DividerNew from 'presentation/shared/components/DividerNew'
import PreferentialDateModal from './modal'
import { AvailableHoursModel } from 'domain/usecases/surgical-scheduling/get-available-hours'
import { AvailableDaysModel } from 'domain/usecases/surgical-scheduling/get-available-days'
import { useHistory } from 'react-router'
import { useLocation } from 'react-router'
import { AppointmentModel } from 'domain/usecases/surgical-scheduling/send-surgical-scheduling'
import moment from 'moment-timezone'
import * as S from './styles'
import * as yup from 'yup'
import { useServices } from 'presentation/hooks/use-services'
import { toast } from 'react-toastify'

type Props = {
  dispatch: (action: { type: any; payload: any }) => void
  getAvailableDays: (startDate: string, endDate: string) => void
  getAvailableHours: (date: string) => void
  availableDays: AvailableDaysModel[]
  availableHours: AvailableHoursModel[]
  state: AppointmentModel[]
  initialDate: string
  initialStatus: {
    hasDate: boolean
    hasHour: boolean | undefined
  }
  isInitialdDateValid: boolean
  setIsLoading: (isLoading: boolean) => void
}

type Location = {
  isReviewing?: boolean
  isUnavailableSchedule?: boolean
}

export default function SurgicalSchedulingAppointmentsLayout({
  dispatch,
  getAvailableDays,
  getAvailableHours,
  availableDays,
  availableHours,
  initialDate,
  initialStatus,
  isInitialdDateValid,
  state,
  setIsLoading
}: Props) {
  const location = useLocation<Location>()
  const history = useHistory()
  const [preferentialDateModalIsOpen, serPreferentialDateModalIsOpen] =
    useState(false)

  const [hourValidated, setHourValidated] = useState<any>(null)
  const [hasAdditionalDate, setHasAdditionalDate] = useState(
    Boolean(state.length && state[1]?.start_date)
  )
  const [fullTimesList, setFullTimesList] = useState<
    { label: string; value: string }[]
  >([])

  const nextStep = () => {
    const { start_date, any_time, hour } = formik.values.alt

    if (!hasAdditionalDate || !start_date) {
      formik.submitForm()
    } else {
      if (start_date && !any_time && !hour) {
        formik.setFieldValue('alt.any_time', true)
      }
      serPreferentialDateModalIsOpen(true)
    }
  }

  const submitForm = (values: any) => {
    const datesList = [values.main]
    if (hasAdditionalDate && values.alt?.start_date) datesList.push(values.alt)

    dispatch({
      type: 'APPOINTMENTS',
      payload: datesList
    })

    if (location.state?.isReviewing) {
      history.push('/agendamento-cirurgico/resumo')
    } else {
      history.push('/agendamento-cirurgico/observacoes')
    }
  }

  const formik = useFormik({
    initialValues: {
      main: {
        start_date: isInitialdDateValid ? initialDate : '',
        any_time: state[0]?.any_time || false,
        hour: state[0]?.hour || '',
        is_main: true,
        justificative: state[0]?.justificative || ''
      },
      alt: {
        start_date: state[1]?.start_date || '',
        any_time: location.state?.isReviewing
          ? Boolean(state[1]?.any_time)
          : false,
        hour: state[1]?.hour || '',
        is_main: false,
        justificative: state[1]?.justificative || ''
      }
    },
    validationSchema: validationSchema,
    validateOnChange: true,
    enableReinitialize: true,
    validateOnMount: true,
    onSubmit: submitForm
  })

  const submitButton = () => {
    return (
      <ButtonNew
        fullWidth
        size="large"
        onClick={nextStep}
        disabled={!hasAdditionalDate && !formik.isValid && !hourValidated}
      >
        {location.state?.isUnavailableSchedule
          ? 'Agendar neste horário'
          : 'Próximo'}
      </ButtonNew>
    )
  }

  const mappedAvailableHours = () => {
    return availableHours?.map((hour: string) => ({
      value: hour,
      label: hour
    }))
  }

  useEffect(() => {
    const timesList = []
    for (let i = 0; i <= 23; i++) {
      const h = ('00' + i).slice(-2)
      timesList.push(`${h}:00`)
      timesList.push(`${h}:30`)

      const mappedHours = timesList.map((time) => ({
        label: time,
        value: time
      }))

      setFullTimesList(mappedHours)
    }
  }, [])

  const isDisabledDate = ({ date, view }: any) => {
    if (view === 'month') {
      const formatedDate = moment(date).format('YYYY-MM-DD')
      const mappedDates = availableDays?.map((day) =>
        moment(day.day).format('YYYY-MM-DD')
      )

      return !mappedDates?.includes(formatedDate)
    }
    return false
  }

  const changeDay = async (date: Date) => {
    const formatedDate = moment(date).format('YYYY-MM-DD')
    await getAvailableHours(formatedDate)
    formik.setFieldValue('main.hour', '')
  }

  const formatStartEndDates = (date: Date) => {
    const startDate = moment(date).set('date', 1).format('YYYY-MM-DD')
    const endDate = moment(date)
      .add(1, 'month')
      .set('date', 1)
      .format('YYYY-MM-DD')

    return { startDate, endDate }
  }

  const changeMonth = (date: Date) => {
    const startDate = formatStartEndDates(date).startDate
    const endDate = formatStartEndDates(date).endDate

    getAvailableDays(startDate, endDate)
    // resetForm()
  }

  const prevNextMonth = ({ view, action, activeStartDate }: any) => {
    if (view === 'month' && ['prev', 'next'].includes(action)) {
      const startDate = formatStartEndDates(activeStartDate).startDate
      const endDate = formatStartEndDates(activeStartDate).endDate
      getAvailableDays(startDate, endDate)
      // resetForm()
    }
  }

  useEffect(() => {
    changeMonth(isInitialdDateValid ? new Date(initialDate) : new Date())

    if (location.state?.isUnavailableSchedule) {
      formik.setFieldValue('alt.start_date', '')
      formik.setFieldValue('alt.hour', '')
      formik.setFieldValue('alt.any_time', true)
      formik.setFieldValue('alt.justificative', '')
      formik.setFieldValue('main.hour', '')
      formik.setFieldValue('main.any_time', false)
      formik.setFieldValue('main.justificative', '')
    }
  }, [])

  const initialText = () => {
    if (location.state?.isUnavailableSchedule) {
      return (
        <SupportTextNew color="neutral700">
          <p>
            O horário escolhido por você na sua primeira e segunda opções não
            está mais disponível.
          </p>
          <br />
          <p>Selecione outro horário.</p>
        </SupportTextNew>
      )
    } else if (!initialStatus.hasDate) {
      return (
        <SupportTextNew color="neutral700">
          <p>
            Consulte os <b>horários disponíveis</b> e selecione uma data para a
            cirurgia.
          </p>
        </SupportTextNew>
      )
    } else {
      return (
        <SupportTextNew color="neutral700">
          <p>
            A <b>data preenchida</b> por você no pedido cirúrgico{' '}
            <b>{`${
              initialStatus.hasHour ? 'está livre' : 'não está disponível'
            }`}</b>
            .
          </p>
          <br />
          <p>
            Consulte os <b>horários disponíveis</b> e selecione uma data para a
            cirurgia.
          </p>
        </SupportTextNew>
      )
    }
  }

  const doctorService = useServices().doctor
  const surgicalSchedulingService = useServices().surgicalScheduling

  async function handleChangeHour(hour: string, optionHour: string) {
    try {
      setIsLoading(true)
      const doctor = await doctorService.loadDoctor(['doctor_id'])

      if (optionHour === 'main') {
        const response =
          await surgicalSchedulingService.checkAvailableSchedulingDate({
            doctorId: doctor.doctor_id,
            hour: hour,
            date: formik.values.main.start_date
          })

        if (response.data.validated === true) {
          formik.setFieldValue('main.hour', hour)
          setHourValidated(true)
        } else {
          setHourValidated(false)
        }
      } else if (optionHour === 'alt') {
        const response =
          await surgicalSchedulingService.checkAvailableSchedulingDate({
            doctorId: doctor.doctor_id,
            hour: hour,
            date: formik.values.alt.start_date
          })

        if (response.data.validated === true) {
          formik.setFieldValue('alt.hour', hour)
          setHourValidated(true)
        } else {
          setHourValidated(false)
        }
      }
    } catch (error) {
      toast.error('Falha ao verificar disponibilidade de horário')
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <ContainerNew primaryButton={submitButton()} gap="24px" noHeader>
      <div>
        <HeadingNew
          color="primary600"
          size="large"
          style={{ marginBottom: '8px' }}
        >
          {location.state?.isUnavailableSchedule
            ? 'Horário indisponível'
            : 'Data da cirurgia'}
        </HeadingNew>

        {(!location.state?.isReviewing ||
          location.state?.isUnavailableSchedule) &&
          initialText()}
      </div>

      <S.FormContainer>
        <DatePickerNew
          label="Data *"
          placeholder="Selecione a data"
          mainValue={formik.values.main.start_date}
          onClickDay={changeDay}
          onClickMonth={changeMonth}
          onActiveStartDateChange={prevNextMonth}
          onMainChange={formik.handleChange('main.start_date')}
          tileDisabled={isDisabledDate}
          minDate={new Date()}
          closeOnChange
        />
        <SelectFieldNew
          label="Hora *"
          placeholder="Escolha um horário"
          value={formik.values.main.hour}
          disabled={
            formik.values.main.any_time ||
            !formik.values.main.start_date ||
            mappedAvailableHours().length < 1
          }
          onBlur={formik.handleBlur('main.hour')}
          onInputChange={(value) => handleChangeHour(value, 'main')}
          error={
            hourValidated === false &&
            'Você possui uma cirurgia agendada neste horário.'
          }
          items={mappedAvailableHours()}
        />
        <CheckboxNew
          value={String(formik.values.main.any_time)}
          label="Qualquer horário"
          name="any-time"
          labelFor="any-time"
          checked={formik.values.main.any_time}
          onCheck={(value) => formik.setFieldValue('main.any_time', value)}
          disabled={mappedAvailableHours().length < 1}
        />
      </S.FormContainer>

      <DividerNew marginBottom="0" marginTop="0" />

      {!hasAdditionalDate ? (
        <div>
          <SupportTextNew>
            Deseja sugerir mais uma opção de data?
          </SupportTextNew>
          <ButtonNew
            onClick={() => setHasAdditionalDate(true)}
            variant="text"
            noPadding
            size="large"
          >
            + Nova data
          </ButtonNew>
        </div>
      ) : (
        <>
          <div>
            {!location.state?.isUnavailableSchedule ? (
              <>
                <S.Label weight="bold" color="neutral900">
                  Deseja sugerir mais uma opção de data?
                </S.Label>
                <SupportTextNew size="xsmall">
                  Caso haja disponibilidade na data inserida, o setor de
                  marcação pode realizar o agendamento.
                </SupportTextNew>
              </>
            ) : (
              <SupportTextNew size="small" weight="bold">
                A data de sua preferência não estava disponível? Sugira uma nova
                data e consideraremos em caso de desistência.
              </SupportTextNew>
            )}
          </div>

          <S.FormContainer>
            <DatePickerNew
              label="Data"
              placeholder="Selecione a data"
              mainValue={formik.values.alt.start_date}
              onMainChange={formik.handleChange('alt.start_date')}
              minDate={new Date()}
              closeOnChange
            />
            <SelectFieldNew
              label="Hora"
              placeholder="Escolha um horário"
              value={formik.values.alt.hour}
              disabled={
                formik.values.alt.any_time || !formik.values.alt.start_date
              }
              onBlur={formik.handleBlur('alt.hour')}
              onInputChange={(value) => handleChangeHour(value, 'alt')}
              items={fullTimesList}
            />
            <CheckboxNew
              value={String(formik.values.alt.any_time)}
              label="Qualquer horário"
              name="alt-any-time"
              checked={formik.values.alt.any_time}
              labelFor="alt-any-time"
              onCheck={(value) => formik.setFieldValue('alt.any_time', value)}
            />
            {location.state?.isUnavailableSchedule && (
              <TextAreaNew
                rows={4}
                style={{ marginTop: '8px' }}
                label="Justificativa da sugestão"
                placeholder="Escreva uma justificativa caso coloque uma segunda opção."
                value={formik.values.alt.justificative}
                onInputChange={formik.handleChange('alt.justificative')}
              />
            )}
          </S.FormContainer>
        </>
      )}

      <PreferentialDateModal
        isOpen={preferentialDateModalIsOpen}
        onClose={serPreferentialDateModalIsOpen}
        formik={formik}
      />
    </ContainerNew>
  )
}

const validationSchema = yup.object().shape({
  main: yup.object().shape({
    start_date: yup
      .string()
      .trim()
      .matches(/^\d{4}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$/)
      .required(),
    any_time: yup.boolean(),
    hour: yup.string().when('any_time', {
      is: false,
      then: yup
        .string()
        .trim()
        .matches(/^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/)
        .required()
    }),
    is_main: yup.boolean().isTrue().required(),
    justificative: yup.string()
  }),
  alt: yup.object().shape({
    start_date: yup
      .string()
      .trim()
      .matches(/^\d{4}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$/),
    any_time: yup.boolean(),
    hour: yup
      .string()
      .trim()
      .matches(/^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/, 'Hora inválida'),
    is_main: yup.boolean().isFalse().required(),
    justificative: yup.string()
  })
})
