import React from 'react'
import makePatientService from 'main/factories/stores/services/patient'
import makeSurgicalOrderService from 'main/factories/stores/services/surgical-order'
import makeDoctorService from 'main/factories/stores/services/doctor'
import makeRecommendationService from 'main/factories/stores/services/recommendation'
import makeSpecialtyService from 'main/factories/stores/services/specialty'
import makeCollaboratorService from 'main/factories/stores/services/collaborator'
import makeUserService from 'main/factories/stores/services/user'
import makeHealthInsuranceService from 'main/factories/stores/services/health-insurance'
import makeEligibilityService from 'main/factories/stores/services/eligibility'
import makeAmbulatoryService from 'main/factories/stores/services/ambulatory'
import makeTherapeuticPlanService from 'main/factories/stores/services/therapeutic-plan'
import makeAddressService from 'main/factories/stores/services/address'
import makeOverallRegistrationStatusService from 'main/factories/stores/services/overall-registration-status'
import makeRegisterPendencyService from 'main/factories/stores/services/register-pendency'
import makeSecretaryService from 'main/factories/stores/services/secretary'
import makeSurgicalPendencyService from 'main/factories/stores/services/surgical-pendency'
import makeDoctorRegisterStepService from 'main/factories/stores/services/doctor-register-step'
import makeDocumentService from 'main/factories/stores/services/document'
import makeDoctorDocumentValidationService from 'main/factories/stores/services/doctor-document-validation'
import makeDoctorRegisterService from 'main/factories/stores/services/doctor-register'
import makeEmergencyRoomService from 'main/factories/stores/services/emergency-room'
import makeHealthInsurancePlansService from 'main/factories/stores/services/health-insurance-plans'
import makeAccompanyingService from 'main/factories/stores/services/accompanying'
import makeReligionService from 'main/factories/stores/services/religion'
import makeVueMotionService from 'main/factories/stores/services/vue-motion'
import makeAuthorizationService from 'main/factories/stores/services/authorization'
import makeNotificationService from 'main/factories/stores/services/notification'
import makeSurgicalSchedulingService from 'main/factories/stores/services/surgical-scheduling'
import makeSchedulingService from 'main/factories/stores/services/scheduling'
import makeTracksaleService from 'main/factories/stores/services/tracksale'
import makeHospitalService from 'main/factories/stores/services/hospital'

const services = {
  patient: makePatientService(),
  surgicalOrder: makeSurgicalOrderService(),
  surgicalPendency: makeSurgicalPendencyService(),
  doctor: makeDoctorService(),
  recommendation: makeRecommendationService(),
  specialty: makeSpecialtyService(),
  user: makeUserService(),
  collaborator: makeCollaboratorService(),
  healthInsurance: makeHealthInsuranceService(),
  healthInsurancePlans: makeHealthInsurancePlansService(),
  eligibility: makeEligibilityService(),
  ambulatory: makeAmbulatoryService(),
  address: makeAddressService(),
  registrationStatus: makeOverallRegistrationStatusService(),
  therapeuticPlan: makeTherapeuticPlanService(),
  registerPendency: makeRegisterPendencyService(),
  secretary: makeSecretaryService(),
  doctorRegister: makeDoctorRegisterService(),
  doctorRegisterStep: makeDoctorRegisterStepService(),
  document: makeDocumentService(),
  doctorDocumentValidation: makeDoctorDocumentValidationService(),
  emergencyRoom: makeEmergencyRoomService(),
  accompanying: makeAccompanyingService(),
  religion: makeReligionService(),
  vueMotion: makeVueMotionService(),
  authorization: makeAuthorizationService(),
  notification: makeNotificationService(),
  surgicalScheduling: makeSurgicalSchedulingService(),
  scheduling: makeSchedulingService(),
  tracksale: makeTracksaleService(),
  hospital: makeHospitalService()
}

export const servicesContext = React.createContext(services)

export type ServicesType = Partial<typeof services>
