import React from 'react'

import { Secretary } from 'domain/entities/secretary-model'
import SecretaryForm from 'presentation/doctor/components/Forms/Secretary'
import { UnlinkSecretary } from 'domain/usecases/secretary/unlink-secretary'
import { UpdateUser } from 'domain/usecases/user/update-user'
import HeaderNew from 'presentation/shared/components/HeaderNew'

type Props = {
  secretary: Secretary
  updateSecretaryProfile: UpdateUser
  unlinkSecretary?: UnlinkSecretary
}

export default function SecretaryDetailsLayout({
  secretary,
  updateSecretaryProfile,
  unlinkSecretary
}: Props) {
  return (
    <>
      <HeaderNew actualPageTitle="Secretária" />
      <SecretaryForm
        updateSecretaryProfile={updateSecretaryProfile}
        initialValues={{ ...secretary }}
        unlinkSecretary={unlinkSecretary}
        secretary_id={secretary.secretary_id ?? 0}
        user_id={secretary.user_id ?? 0}
      />
    </>
  )
}
