import styled from 'styled-components'

export const TherapeuticPlanHomeContainer = styled.div`
  padding: 0px 24px 0px 24px;

  &:first-child {
    padding-top: 20px;
    padding-bottom: 20px;
  }
`
