import styled, { css } from 'styled-components'
import CardNew from 'presentation/shared/components/CardNew'
import { mix } from 'polished'

export const FirstStatusGroupCountersContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 8px;
`

export const PasswordCountersContainer = styled.div`
  display: flex;
  gap: 8px;
`

export const SecondStatusGroupCountersContainer = styled.div`
  display: flex;
  gap: 8px;
`

export const ThirdStatusGroupCountersContainer = styled.div`
  display: flex;
`

export const VerticalCounterCard = styled(CardNew)`
  ${({ border = 'neutral500', theme }: any) => css`
    border-color: ${mix(0.5, theme.ds.colors[border], '#fff')};
    transition: 0.2s ease-in-out;

    &:hover {
      background-color: ${mix(0.8, theme.ds.colors[border], '#fff')};
      transition: 0.2s ease-in-out;
      p {
        color: white !important;
      }
    }
  `}
`

export const HorizontalCounterCard = styled(CardNew)`
  ${({ border = 'neutral500', theme }: any) => css`
    border-color: ${mix(0.5, theme.ds.colors[border], '#fff')};
    transition: 0.2s ease-in-out;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 8px;
    width: 100%;

    &:hover {
      background-color: ${mix(0.8, theme.ds.colors[border], '#fff')};
      transition: 0.2s ease-in-out;
      p {
        color: white !important;
      }
    }
  `}
`

export const Container = styled.div<{ gap: string; marginTop: string }>`
  ${({ gap, marginTop }) => css`
    margin-top: ${marginTop};
    display: flex;
    flex-direction: column;
    gap: ${gap};
  `}
`

export const CountersContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`

export const SchedulingBanner = styled.div`
  background-color: ${({ theme }) => theme.ds.colors.secondary25};
  padding: 16px 24px;
  margin: 24px -24px 0;
`

export const SchedulingBannerBody = styled.div`
  margin-top: 8px;
  display: flex;
  align-items: end;
  div {
    margin-left: 24px;
  }
`

export const SurgicalOrdersListContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  flex: none;
  overflow-x: scroll;
  overflow-y: hidden;
  scrollbar-width: none;
  -ms-overflow-style: none;
  scroll-behavior: auto;
  padding: 8px 24px;
  margin: -8px -24px;
  &::-webkit-scrollbar {
    display: none;
  }
  & > div {
    min-width: 320px;
    max-width: calc(100% - 48px) !important;
    height: 100%;
  }
`
