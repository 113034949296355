import { GetSurgicalSchedulesByDoctor } from 'domain/usecases/surgical-order/get-surgical-schedules-by-doctor'
import { SurgicalOrderRepository } from 'repository/interfaces/surgical-order-repository'

export class RemoteGetSurgicalSchedulesByDoctor
  implements GetSurgicalSchedulesByDoctor
{
  constructor(
    private readonly surgicalOrderRepository: SurgicalOrderRepository
  ) {}

  getSurgicalSchedulesByDoctor(
    params: GetSurgicalSchedulesByDoctor.Params
  ): Promise<GetSurgicalSchedulesByDoctor.Model> {
    return this.surgicalOrderRepository.getSurgicalSchedulesByDoctor(params)
  }
}
