import React, { useEffect, useContext } from 'react'
import HeaderNew from 'presentation/shared/components/HeaderNew'
import SurgicalOrderStatusistLayout from 'presentation/doctor/layouts/SurgicalOrdersStatusList'
import { SurgicalOrderDashboardContext } from 'main/factories/pages/doctor/surgical-order/surgical-order-dashboard-factory'
import { useServices } from 'presentation/hooks/use-services'
import { toast } from 'react-toastify'
import {
  WithLoading,
  WithLoadingProps
} from 'presentation/shared/components/HOCs/WithLoading'
import { useLocation } from 'react-router-dom'
import {
  SchedulingTypes,
  LoadSurgicalOrdersByDoctorAndStatus
} from 'domain/usecases/surgical-order/load-surgical-orders-by-doctor-and-status'
import { ExpirationDateTypes } from 'domain/usecases/surgical-order/load-expiration-date-status-dashboard-count'

function SurgicalOrdersStatusList({ setIsLoading }: WithLoadingProps) {
  const location = useLocation<{
    orderStatus: SchedulingTypes
    passwordStatus: ExpirationDateTypes
  }>()
  const surgicalOrdersService = useServices().surgicalOrder
  const { dispatch } = useContext<any>(SurgicalOrderDashboardContext)

  const getOrdersList = async (
    params: LoadSurgicalOrdersByDoctorAndStatus.Params
  ) => {
    setIsLoading(true)
    try {
      const response: any =
        await surgicalOrdersService.loadSurgicalOrdersByDoctorAndStatus(params)

      dispatch({
        type: 'PAGINATION',
        payload: response.data.page_info
      })

      dispatch({
        type: 'SURGICAL_ORDERS_LIST',
        payload: response.data.surgical_orders,
        clearList: params.pageNumber === 1
      })
    } catch (error: any) {
      toast.error('Ocorreu um erro ao buscar as pendencias')
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    getOrdersList({
      status: location.state?.orderStatus,
      pageNumber: 1,
      passwordStatus: location.state?.passwordStatus
    })
  }, [])

  const deleteSurgicalOrder = async (surgical_order_id: number) => {
    setIsLoading(true)
    try {
      await surgicalOrdersService.deleteSurgicalOrderSolicitation({
        surgical_order_id
      })

      await getOrdersList({
        status: location.state?.orderStatus,
        pageNumber: 1,
        passwordStatus: location.state?.passwordStatus
      })
      toast.success('Pedido excluído com sucesso!')
    } catch {
      toast.error('Ocorreu um erro ao excluir o pedido cirurgico')
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <>
      <HeaderNew actualPageTitle="Pedido cirúrgico" />
      <SurgicalOrderStatusistLayout
        status={location.state?.orderStatus}
        passwordStatus={location.state?.passwordStatus}
        getOrdersList={getOrdersList}
        deleteSurgicalOrder={deleteSurgicalOrder}
      />
    </>
  )
}

export default WithLoading(SurgicalOrdersStatusList)
