import React, { HTMLAttributes } from 'react'
import SupportTextNew from '../SupportTextNew'
import { ReactComponent as AccordionArrow } from 'presentation/assets/icons/accordion-arrow.svg'
import { CardProps } from '../CardNew'
import DividerNew from '../DividerNew'
import * as S from './styles'

export type AccordionProps = {
  accordionLabel: string
  header: React.ReactElement | string
  // content: React.ReactElement | string
  headerIcon?: React.ReactElement
  defaultExpanded?: boolean
  expanded?: boolean // Optional for open one and close the others accordions
  handleChange?: (accordionLabel: string, isExpanded: boolean) => void // Optional for open one and close the others accordions
  disabled?: boolean
  expandIcon?: React.ReactElement
  padding?: string
  gap?: string
  marginTop?: string
  hideHeaderDivider?: boolean
  onClick?: () => void
} & HTMLAttributes<HTMLDivElement> &
  CardProps

const AccordionCardNew = ({
  children,
  accordionLabel = 'panel',
  header,
  headerIcon,
  // content,
  defaultExpanded = false,
  expanded,
  handleChange,
  hideHeaderDivider,
  disabled = false,
  expandIcon,
  padding = '0',
  gap,
  shadow = 'none',
  border = 'none',
  background = 'white',
  marginTop,
  onClick
}: AccordionProps) => {
  return (
    <S.StyledAccordion
      defaultExpanded={defaultExpanded}
      expanded={expanded}
      shadow={shadow}
      border={border}
      background={background}
      padding={padding}
      onChange={(e: any, expanded: any) =>
        !!handleChange && handleChange(accordionLabel, expanded)
      }
      disabled={disabled}
      onClick={onClick}
    >
      <S.StyledAccordionSummary
        expandIcon={expandIcon ? expandIcon : <AccordionArrow />}
        id={`${accordionLabel}-header`}
        aria-controls={`${accordionLabel}-content`}
      >
        {typeof header === 'string' ? (
          <>
            {headerIcon && headerIcon}
            <SupportTextNew
              color="neutral800"
              weight="semiBold"
              size="small"
              style={{
                marginTop: '0px',
                marginBottom: '0px'
              }}
            >
              {header}
            </SupportTextNew>
          </>
        ) : (
          header
        )}
      </S.StyledAccordionSummary>
      <S.StyledAccordionDetails>
        {hideHeaderDivider && <DividerNew marginTop="0px" />}
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: gap,
            marginTop: marginTop
          }}
        >
          {children}
        </div>
      </S.StyledAccordionDetails>
    </S.StyledAccordion>
  )
}

export default AccordionCardNew
