import styled from 'styled-components'
import SupportTextNew from 'presentation/shared/components/SupportTextNew'

export const Label = styled(SupportTextNew)`
  margin-bottom: 12px;
`

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`
export const RadioGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;

  label {
    display: inline-block;
    &::first-letter {
      text-transform: uppercase;
    }
  }
`
