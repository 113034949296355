import React from 'react'
import CardNew from 'presentation/shared/components/CardNew'
import * as S from './styles'

interface ComparisonCardProps {
  icon: React.ReactNode
  title: string
  value: number
  variant:
    | 'positive'
    | 'negative'
    | 'equal'
    | 'indicatorPositive'
    | 'indicatorNegative'
    | 'indicatorEqual'
}

export const ComparisonCard = ({
  icon,
  title,
  value,
  variant
}: ComparisonCardProps) => (
  <CardNew
    background="neutral25"
    style={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      gap: '8px',
      alignItems: 'center',
      width: '100%',
      minHeight: '120px'
    }}
  >
    {icon}
    <S.ComparisonTitle>{title}</S.ComparisonTitle>
    <S.ComparisonValue variant={variant}>
      {value > 0 ? '+' : ''}
      {value}%
    </S.ComparisonValue>
  </CardNew>
)
