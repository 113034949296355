import React, { useEffect, useState } from 'react'
import * as yup from 'yup'

import {
  WithLoading,
  WithLoadingProps
} from 'presentation/shared/components/HOCs/WithLoading'
import TextFieldNew from 'presentation/shared/components/TextFieldNew'
import ButtonNew from 'presentation/shared/components/ButtonNew'
import { ContainerNew } from 'presentation/shared/components/ContainerNew'
import SupportTextNew from 'presentation/shared/components/SupportTextNew'
import * as S from '../styles'
import * as LocalStyles from './styles'
import { useHistory, useLocation } from 'react-router'
import Divider from 'presentation/shared/components/DividerNew'
import HeadingNew from 'presentation/shared/components/HeadingNew'
import { TitleAndSubtitleWrapper } from 'presentation/shared/components/ContainerTitleAndSubtitle'
import RadioButtonNew from 'presentation/shared/components/RadioButtonNew'
import { dateMask } from 'presentation/utils/masks'
import DateRange from '@material-ui/icons/DateRange'
import TextAreaNew from 'presentation/shared/components/TextAreaNew'
import { useFormik } from 'formik'
import { useStores } from 'presentation/hooks/use-stores'
import { ClinicalProps } from 'presentation/contexts/surgical-order/surgical-order-context'
import { toast } from 'react-toastify'
import { techniquesList } from 'common/enum/techniques'
import {
  HospitalizationType,
  hospitalizationList as hospitalizationTypeList
} from 'common/enum/hospitalization-type'
import {
  HospitalizationMode,
  hospitalizationList as hospitalizationModeList
} from 'common/enum/hospitalization-mode'
import { ReactComponent as ArrowIcon } from 'presentation/assets/icons/open-arrow.svg'
import moment from 'moment-timezone'
import Calendar from 'presentation/shared/components/CalendarNew'
import { isValidDate } from 'presentation/utils/validators/is-valid-data'
import { useServices } from 'presentation/hooks/use-services'
import {
  LatexAllergyOptions,
  latexAllergyOptions
} from 'common/enum/latex-allergy'

const AddClinicalHistoryAndRecordForm = WithLoading(
  ({ setIsLoading }: WithLoadingProps) => {
    const { state } = useLocation<{
      isEditing?: boolean
    }>()
    const history = useHistory()
    const surgicalOrderContext = useStores().surgicalOrder
    const surgicalOrderService = useServices().surgicalOrder
    const surgicalOrderObjContext = useStores().surgicalOrder.getSurgicalOrder()

    const [latexAllergy, setLatexAllergy] = useState<LatexAllergyOptions>(
      LatexAllergyOptions.YES ?? undefined
    )
    const [othersAllergies, setOthersAllergies] = useState('')
    const [canChangeRequestedDailies, setCanChangeRequestedDailies] =
      useState(false)
    const [showExpectedDateCalendar, setShowExpectedDateCalendar] =
      useState(false)
    const [isUrgencyDate, setIsUrgencyDate] = useState(false)
    const [
      showBullishForecastDateCalendar,
      setShowBullishForecastDateCalendar
    ] = useState(false)

    const hospitalizationModeFilteredAndOrderedList = () => {
      return [
        hospitalizationModeList.filter(
          (mode) => mode.label === HospitalizationMode.ambulatory
        )[0],
        hospitalizationModeList.filter(
          (mode) => mode.label === HospitalizationMode['day hospital']
        )[0],
        hospitalizationModeList.filter(
          (mode) => mode.label === HospitalizationMode.hospital
        )[0]
      ]
    }

    const isAnyRequiredFieldEmpty = () => {
      return (
        !formik.values.clinical_history.clinical_history ||
        latexAllergy == null ||
        formik.values.clinical_history.blood_transfusion == null ||
        formik.values.clinical_record.oncology_or_research == null ||
        formik.values.clinical_record.cti == null ||
        formik.values.clinical_record.technique == null ||
        formik.values.clinical_record.freeze == null ||
        formik.values.clinical_record.hospitalization_type == null ||
        formik.values.clinical_record.patient_hospitalized == null ||
        formik.values.clinical_record.hospitalization_mode == null ||
        formik.values.clinical_record.requested_dailies == null
      )
    }

    const setRequestedDailiesByHospitalizationMode = (
      hospitalization_mode: string
    ) => {
      if (hospitalization_mode === HospitalizationMode.ambulatory) {
        formik.setFieldValue('clinical_record.requested_dailies', 0)
      } else if (hospitalization_mode === HospitalizationMode['day hospital']) {
        formik.setFieldValue('clinical_record.requested_dailies', 1)
      }
    }

    const toggleExpectedDateCalendar = () => {
      setShowExpectedDateCalendar(showExpectedDateCalendar !== true)
    }

    const changeExpectedDate = (date: any) => {
      const momentDate = moment(date)
      if (momentDate.isValid()) {
        formik.setFieldValue('expected_date', momentDate.format('DD/MM/YYYY'))
        if (showExpectedDateCalendar === true) {
          toggleExpectedDateCalendar()
        }
      } else {
        formik.setFieldValue('expected_date', '')
      }
    }

    const getUrgencyOrExpectedDateInformativeText = () => {
      if (formik.values.clinical_record.hospitalization_type) {
        if (isUrgencyDate) {
          if (isValidDate(formik.values.expected_date)) {
            const daysDiff = moment(formik.values.expected_date, 'DD/MM/YYYY')
              .startOf('day')
              .diff(moment().startOf('day'), 'days')

            if (daysDiff <= 3 && daysDiff >= 0) {
              return (
                <SupportTextNew size="xxsmall" style={{ marginTop: '8px' }}>
                  {daysDiff} {daysDiff === 1 ? 'dia' : 'dias'} até a urgência.
                </SupportTextNew>
              )
            } else if (daysDiff > 3) {
              return (
                <SupportTextNew
                  size="xxsmall"
                  weight="bold"
                  style={{ marginTop: '8px' }}
                >
                  A data selecionada indica realização em {daysDiff} dias.
                  <br />
                  Está correto?
                </SupportTextNew>
              )
            }
          }
        } else {
          return (
            <>
              <SupportTextNew size="xxsmall" style={{ marginTop: '8px' }}>
                Esta data <b style={{ fontWeight: 'bold' }}>não garante</b> o
                agendamento da cirurgia, solicite o agendamento a qualquer
                momento na tela de{' '}
                <b style={{ fontWeight: 'bold' }}>Agendamentos.</b>
              </SupportTextNew>
              <SupportTextNew size="xxsmall" style={{ marginTop: '8px' }}>
                De acordo com a Agência Nacional de saúde Suplementar (ANS), o
                prazo de autorização é de 21 dias úteis.
              </SupportTextNew>
            </>
          )
        }
      }
    }

    const toggleBullishForecastDateCalendar = () => {
      setShowBullishForecastDateCalendar(
        showBullishForecastDateCalendar !== true
      )
    }

    const changeBullishForecastDate = (date: any) => {
      const momentDate = moment(date)
      if (momentDate.isValid()) {
        formik.setFieldValue(
          'clinical_record.bullish_forecast_date',
          momentDate.format('DD/MM/YYYY')
        )
        if (showBullishForecastDateCalendar === true) {
          toggleBullishForecastDateCalendar()
        }
      } else {
        formik.setFieldValue('clinical_record.bullish_forecast_date', '')
      }
    }

    const disablePreviousDate = ({ date }: { date: Date }) => {
      const { expected_date } = formik.values

      if (!expected_date || !isValidDate(expected_date)) {
        return false
      }

      const expectedDate = moment(expected_date, 'DD/MM/YYYY').startOf('day')
      return moment(date).isBefore(expectedDate, 'day')
    }

    useEffect(() => {
      setCanChangeRequestedDailies(
        surgicalOrderObjContext.clinical_record?.hospitalization_mode ===
          'hospital'
      )
    }, [])

    const formik = useFormik({
      initialValues: {
        expected_date:
          (surgicalOrderObjContext.expected_date &&
            moment(surgicalOrderObjContext.expected_date)
              .utc()
              .format('DD/MM/YYYY')) ||
          '',
        clinical_history: {
          clinical_history:
            surgicalOrderObjContext.clinical_history?.clinical_history ?? '',
          allergy: surgicalOrderObjContext.clinical_history?.allergy ?? [],
          blood_transfusion:
            surgicalOrderObjContext.clinical_history?.blood_transfusion,
          psychological_aspects:
            surgicalOrderObjContext.clinical_history?.psychological_aspects ??
            ''
        },
        clinical_record: {
          ...surgicalOrderObjContext.clinical_record,
          hospitalization_mode:
            surgicalOrderObjContext.clinical_record?.hospitalization_mode ?? '',
          bullish_forecast_date:
            surgicalOrderObjContext.clinical_record?.bullish_forecast_date &&
            (moment(
              surgicalOrderObjContext.clinical_record?.bullish_forecast_date
            )
              .utc()
              .format('DD/MM/YYYY') ||
              '')
        }
      } as ClinicalProps,
      validationSchema: validationSchema,
      onSubmit: async (values) => {
        values.clinical_record.requested_dailies =
          formik.values.clinical_record.requested_dailies
        values.clinical_history.allergy = []

        if (latexAllergy) {
          values.clinical_history.allergy.push(latexAllergy)
        }
        if (othersAllergies) {
          values.clinical_history.allergy.push(othersAllergies)
        }

        try {
          setIsLoading(true)
          await surgicalOrderService.updateSurgicalOrder({
            surgical_order_id:
              surgicalOrderContext.getSurgicalOrder().surgical_order_id,
            patient: {
              patient_id: Number(
                surgicalOrderContext.patient.patient.patient_id
              ),
              patient_name:
                surgicalOrderContext.getPatient().patient.patient_name ||
                surgicalOrderContext.getPatient().patient.name
            },
            ...values,
            expected_date: moment(values.expected_date, 'DD/MM/YYYY').format(
              'YYYY-MM-DD'
            ),
            clinical_record: {
              ...values.clinical_record,
              bullish_forecast_date: moment(
                values.clinical_record.bullish_forecast_date,
                'DD/MM/YYYY'
              ).format('YYYY-MM-DD'),
              extra_blood: values.clinical_record.extra_blood
                ? values.clinical_record.extra_blood
                : false
            }
          })

          surgicalOrderContext.setClinical(values)

          // toast.success('Os dados do pedido foram atualizados!')

          if (state?.isEditing) {
            history.push('/novo-pedido/resumo')
          } else {
            history.push('/novo-pedido/laudos-documentos')
          }
        } catch (error: any) {
          toast.error(`[História clínica] ${error?.message}`)
          history.goBack()
        } finally {
          setIsLoading(false)
        }
      }
    })

    return (
      <ContainerNew
        buttonsHasBoxShadow={!state?.isEditing}
        primaryButton={
          <ButtonNew
            fullWidth
            size="large"
            onClick={formik.submitForm}
            disabled={isAnyRequiredFieldEmpty()}
          >
            {state?.isEditing ? 'Salvar edição' : 'Próximo'}
          </ButtonNew>
        }
        secondaryButton={
          !state?.isEditing && (
            <ButtonNew onClick={history.goBack} fullWidth outlined size="large">
              Anterior
            </ButtonNew>
          )
        }
      >
        <S.FormWrapper>
          <TitleAndSubtitleWrapper
            title="História Clínica"
            subtitle="Escreva sobre a história clínica do paciente e outras informações relevantes para a cirurgia."
          />
          <TextAreaNew
            label="História clínica"
            required
            requiredColor="neutral800"
            placeholder="Digite sobre a história clínica"
            id="clinical_history"
            name="clinical_history"
            style={{ marginTop: '8px' }}
            rows={3}
            value={formik.values.clinical_history?.clinical_history ?? ''}
            onInputChange={(clinical_history) =>
              formik.setFieldValue(
                'clinical_history.clinical_history',
                clinical_history
              )
            }
          />
          <div style={{ gap: '8px' }}>
            <HeadingNew
              as="label"
              required
              requiredColor="neutral900"
              color="neutral900"
              size="small"
              weight="normal"
            >
              Paciente possui alergia a látex?
            </HeadingNew>
            <LocalStyles.RadioGroup isColumn>
              {latexAllergyOptions.map((option, index) => {
                return (
                  <RadioButtonNew
                    label={option.label}
                    showBackground={false}
                    name="latex"
                    labelFor={`latex_${index}`}
                    key={index}
                    value={option.value}
                    checked={
                      formik.values.clinical_history.allergy[0] ===
                        option.value ?? latexAllergy === option.value
                    }
                    onCheck={(isChecked) => {
                      if (isChecked) {
                        setLatexAllergy(option.value)
                        formik.setFieldValue('clinical_history.allergy', [
                          option.value
                        ])
                      } else {
                        setLatexAllergy(LatexAllergyOptions.NO)
                        formik.setFieldValue('clinical_history.allergy', [])
                      }
                    }}
                  />
                )
              })}
            </LocalStyles.RadioGroup>
          </div>
          <TextAreaNew
            id="other_allergies"
            name="other_allergies"
            label="Paciente possui outra alergia?"
            placeholder="Digite sobre as alergias"
            rows={3}
            value={formik.values.clinical_history.allergy[1]}
            onInputChange={(other_allergies) => {
              setOthersAllergies(other_allergies)
              formik.setFieldValue('clinical_history.allergy', [
                latexAllergy,
                other_allergies
              ])
            }}
          />
          <div style={{ gap: '8px' }}>
            <HeadingNew
              as="label"
              required
              requiredColor="neutral900"
              color="neutral900"
              size="small"
              weight="normal"
            >
              Paciente tem restrição a transfusão sanguínea?
            </HeadingNew>
            <LocalStyles.RadioGroup>
              <RadioButtonNew
                label="Sim"
                labelFor="transfusion_yes"
                showBackground={false}
                name="blood_transfusion"
                checked={
                  formik.values.clinical_history.blood_transfusion === true
                }
                onCheck={() =>
                  formik.setFieldValue(
                    'clinical_history.blood_transfusion',
                    true
                  )
                }
              />
              <RadioButtonNew
                label="Não"
                labelFor="transfusion_no"
                showBackground={false}
                name="blood_transfusion"
                checked={
                  formik.values.clinical_history.blood_transfusion === false
                }
                onCheck={() =>
                  formik.setFieldValue(
                    'clinical_history.blood_transfusion',
                    false
                  )
                }
              />
            </LocalStyles.RadioGroup>
          </div>
          <div style={{ gap: '8px' }}>
            <HeadingNew
              as="label"
              required
              requiredColor="neutral900"
              color="neutral900"
              size="small"
              weight="normal"
            >
              Paciente oncológico ou investigação?
            </HeadingNew>
            <LocalStyles.RadioGroup>
              <RadioButtonNew
                label="Sim "
                labelFor="oncology_or_research_yes"
                showBackground={false}
                name="oncology_or_research"
                checked={
                  formik.values.clinical_record.oncology_or_research === true
                }
                onCheck={() =>
                  formik.setFieldValue(
                    'clinical_record.oncology_or_research',
                    true
                  )
                }
              />
              <RadioButtonNew
                label="Não "
                labelFor="oncology_or_research_no"
                showBackground={false}
                name="oncology_or_research"
                checked={
                  formik.values.clinical_record.oncology_or_research === false
                }
                onCheck={() =>
                  formik.setFieldValue(
                    'clinical_record.oncology_or_research',
                    false
                  )
                }
              />
            </LocalStyles.RadioGroup>
          </div>
          <TextAreaNew
            label="Descreva os aspectos psicológicos, socioeconômicos, espirituais/culturais relevantes a serem considerados:"
            placeholder="Descreva"
            id="psychological_aspects"
            name="psychological_aspects"
            rows={3}
            value={formik.values.clinical_history.psychological_aspects}
            onChange={formik.handleChange}
            onInputChange={(psychological_aspects) =>
              formik.setFieldValue(
                'clinical_history.psychological_aspects',
                psychological_aspects
              )
            }
          />
          <S.DividerWrapper>
            <Divider color="neutral100" marginTop="0px" marginBottom="0px" />
          </S.DividerWrapper>
          <TitleAndSubtitleWrapper
            title="Ficha clínica"
            subtitle="Preencha as informações sobre a cirurgia."
          />
          <div style={{ gap: '8px', marginTop: '8px' }}>
            <HeadingNew
              as="label"
              required
              requiredColor="neutral900"
              color="neutral900"
              size="small"
              weight="normal"
            >
              A cirurgia vai precisar de CTI?
            </HeadingNew>
            <LocalStyles.RadioGroup>
              <RadioButtonNew
                label="Sim"
                labelFor="cti_yes"
                showBackground={false}
                checked={formik.values.clinical_record.cti === true}
                onCheck={() =>
                  formik.setFieldValue('clinical_record.cti', true)
                }
              />
              <RadioButtonNew
                label="Não"
                labelFor="cti_no"
                showBackground={false}
                checked={formik.values.clinical_record.cti === false}
                onCheck={() =>
                  formik.setFieldValue('clinical_record.cti', false)
                }
              />
            </LocalStyles.RadioGroup>
          </div>
          <div style={{ gap: '8px' }}>
            <HeadingNew
              as="label"
              color="neutral900"
              size="small"
              weight="normal"
            >
              Deseja solicitar reserva de sangue?
            </HeadingNew>
            <LocalStyles.RadioGroup>
              <RadioButtonNew
                label="Sim"
                labelFor="extra_blood_yes"
                showBackground={false}
                checked={formik.values.clinical_record.extra_blood === true}
                onCheck={() =>
                  formik.setFieldValue('clinical_record.extra_blood', true)
                }
              />
              <RadioButtonNew
                label="Não"
                labelFor="extra_blood_no"
                showBackground={false}
                checked={formik.values.clinical_record.extra_blood === false}
                onCheck={() =>
                  formik.setFieldValue('clinical_record.extra_blood', false)
                }
              />
            </LocalStyles.RadioGroup>
            <SupportTextNew size="xxsmall">
              Entre em contato com o banco de sangue e solicite a reserva.
            </SupportTextNew>
          </div>
          <div style={{ gap: '8px' }}>
            <HeadingNew
              as="label"
              required
              requiredColor="neutral900"
              color="neutral900"
              size="small"
              weight="normal"
            >
              Técnica
            </HeadingNew>
            <LocalStyles.RadioGroup isColumn>
              {techniquesList.map((technique, index) => {
                return (
                  <RadioButtonNew
                    key={technique.value}
                    value={technique.value}
                    label={technique.label}
                    labelFor={`technique_${index}`}
                    showBackground={false}
                    name="technique"
                    checked={
                      formik.values.clinical_record.technique ===
                      technique.value
                    }
                    onCheck={(technique) =>
                      formik.setFieldValue(
                        'clinical_record.technique',
                        technique
                      )
                    }
                  />
                )
              })}
            </LocalStyles.RadioGroup>
          </div>
          <div style={{ gap: '8px' }}>
            <HeadingNew
              as="label"
              required
              requiredColor="neutral900"
              color="neutral900"
              size="small"
              weight="normal"
            >
              Congelação
            </HeadingNew>
            <LocalStyles.RadioGroup>
              <RadioButtonNew
                label="Sim"
                labelFor="freeze_yes"
                showBackground={false}
                checked={formik.values.clinical_record.freeze === true}
                onCheck={() =>
                  formik.setFieldValue('clinical_record.freeze', true)
                }
              />
              <RadioButtonNew
                label="Não"
                labelFor="freeze_no"
                showBackground={false}
                checked={formik.values.clinical_record.freeze === false}
                onCheck={() =>
                  formik.setFieldValue('clinical_record.freeze', false)
                }
              />
            </LocalStyles.RadioGroup>
          </div>
          <div style={{ gap: '8px' }}>
            <HeadingNew
              as="label"
              required
              requiredColor="neutral900"
              color="neutral900"
              size="small"
              weight="normal"
            >
              Caráter
            </HeadingNew>
            <LocalStyles.RadioGroup isReverse>
              {hospitalizationTypeList.map((type, index) => {
                return (
                  <RadioButtonNew
                    key={type.value}
                    value={type.value}
                    label={type.label}
                    labelFor={`hospitalization_type_${index}`}
                    showBackground={false}
                    name="hospitalization_type"
                    checked={
                      formik.values.clinical_record.hospitalization_type ===
                      type.value
                    }
                    onCheck={(hospitalization_type) => {
                      if (type.label === HospitalizationType.urgency) {
                        setIsUrgencyDate(true)
                      } else {
                        setIsUrgencyDate(false)
                      }
                      formik.setFieldValue(
                        'clinical_record.hospitalization_type',
                        hospitalization_type
                      )
                    }}
                  />
                )
              })}
            </LocalStyles.RadioGroup>
          </div>
          <div>
            <TextFieldNew
              label={
                isUrgencyDate
                  ? 'Data da urgência'
                  : 'Data prevista para a cirurgia'
              }
              mask={dateMask}
              id="expected_date"
              name="expected_date"
              icon={<DateRange />}
              iconPosition="insideRight"
              keepStyleOnIconIsButton
              onIconClick={() => toggleExpectedDateCalendar()}
              value={formik.values.expected_date}
              required={isUrgencyDate}
              requiredColor="neutral800"
              onInputChange={(date) => {
                if (isValidDate(date)) changeExpectedDate(date)
              }}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.expected_date
                  ? formik.errors.expected_date
                  : undefined
              }
            />
            {showExpectedDateCalendar && (
              <Calendar
                value={
                  isValidDate(formik.values.expected_date)
                    ? moment(formik.values.expected_date, 'DD/MM/YYYY').toDate()
                    : undefined
                }
                onDateChange={(date) => changeExpectedDate(date)}
                minDate={moment().toDate()}
              />
            )}
            {getUrgencyOrExpectedDateInformativeText()}
          </div>
          <div style={{ gap: '8px' }}>
            <HeadingNew
              as="label"
              required
              requiredColor="neutral900"
              color="neutral900"
              size="small"
              weight="normal"
            >
              Paciente internado
            </HeadingNew>
            <LocalStyles.RadioGroup>
              <RadioButtonNew
                label="Sim"
                labelFor="patient_hospitalized_yes"
                showBackground={false}
                checked={
                  formik.values.clinical_record.patient_hospitalized === true
                }
                onCheck={() => {
                  formik.setFieldValue(
                    'clinical_record.patient_hospitalized',
                    true
                  )
                  formik.setFieldValue(
                    'clinical_record.locale_hospitalization',
                    undefined
                  )
                }}
              />
              <RadioButtonNew
                label="Não"
                labelFor="patient_hospitalized_no"
                showBackground={false}
                checked={
                  formik.values.clinical_record.patient_hospitalized === false
                }
                onCheck={() => {
                  formik.setFieldValue(
                    'clinical_record.patient_hospitalized',
                    false
                  )
                  formik.setFieldValue(
                    'clinical_record.locale_hospitalization',
                    undefined
                  )
                }}
              />
            </LocalStyles.RadioGroup>
            {formik.values.clinical_record.patient_hospitalized && (
              <>
                <HeadingNew
                  size="small"
                  weight="normal"
                  color="neutral900"
                  style={{ marginTop: '16px' }}
                >
                  Indique o local da internação
                </HeadingNew>
                <SupportTextNew size="xxsmall" style={{ marginBottom: '8px' }}>
                  (Nº apartamento ou CTI)
                </SupportTextNew>
                <TextFieldNew
                  value={formik.values.clinical_record.locale_hospitalization}
                  onInputChange={(locale_hospitalization) =>
                    formik.setFieldValue(
                      'clinical_record.locale_hospitalization',
                      locale_hospitalization
                    )
                  }
                />
              </>
            )}
          </div>
          <div style={{ gap: '8px' }}>
            <HeadingNew
              as="label"
              required
              requiredColor="neutral900"
              color="neutral900"
              size="small"
              weight="normal"
            >
              Regime hospitalar
            </HeadingNew>
            <LocalStyles.RadioGroup isColumn>
              {hospitalizationModeFilteredAndOrderedList().map(
                (mode, index) => {
                  return (
                    <RadioButtonNew
                      key={mode.value}
                      value={mode.value}
                      label={mode.label}
                      labelFor={`hospitalization_mode_${index}`}
                      showBackground={false}
                      name="clinical_record.hospitalization_mode"
                      checked={
                        formik.values.clinical_record.hospitalization_mode ===
                        mode.value
                      }
                      onCheck={(hospitalization_mode) => {
                        if (
                          mode.label === HospitalizationMode.ambulatory ||
                          mode.label === HospitalizationMode['day hospital']
                        ) {
                          setCanChangeRequestedDailies(false)
                        } else {
                          setCanChangeRequestedDailies(true)
                        }
                        changeBullishForecastDate('')
                        setRequestedDailiesByHospitalizationMode(mode.label)
                        formik.setFieldValue(
                          'clinical_record.hospitalization_mode',
                          hospitalization_mode
                        )
                      }}
                    />
                  )
                }
              )}
            </LocalStyles.RadioGroup>
          </div>
          {canChangeRequestedDailies && (
            <div>
              <TextFieldNew
                label="Previsão de alta"
                id="clinical_record.bullish_forecast_date"
                name="clinical_record.bullish_forecast_date"
                mask={dateMask}
                icon={<DateRange />}
                iconPosition="insideRight"
                keepStyleOnIconIsButton
                onIconClick={() => toggleBullishForecastDateCalendar()}
                value={formik.values.clinical_record.bullish_forecast_date}
                onInputChange={(date) => {
                  if (isValidDate(date)) changeBullishForecastDate(date)
                }}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.clinical_record?.bullish_forecast_date
                    ? formik.errors.clinical_record?.bullish_forecast_date
                    : undefined
                }
              />
              {showBullishForecastDateCalendar && (
                <Calendar
                  value={
                    isValidDate(
                      formik.values.clinical_record.bullish_forecast_date
                    )
                      ? moment(
                          formik.values.clinical_record.bullish_forecast_date,
                          'DD/MM/YYYY'
                        ).toDate()
                      : undefined
                  }
                  onDateChange={(date) => changeBullishForecastDate(date)}
                  minDate={moment().toDate()}
                  tileDisabled={disablePreviousDate}
                />
              )}
            </div>
          )}
          <TextFieldNew
            label="Quantidade de diárias"
            name="clinical_record.requested_dailies"
            required
            requiredColor="neutral800"
            disabled={!canChangeRequestedDailies}
            value={formik.values.clinical_record.requested_dailies}
            onInputChange={(quantity) => {
              const isValidQuantityInput = (value: string) => {
                const digitRegex = /\d+/g
                const lastChar = value.charAt(value.length - 1)

                return (
                  (digitRegex.test(lastChar) && lastChar !== ',') ||
                  value === ''
                )
              }

              if (isValidQuantityInput(quantity)) {
                formik.setFieldValue(
                  'clinical_record.requested_dailies',
                  Number(quantity)
                )
              }
            }}
            icon={
              <S.ArrowIconsWrapper>
                <ArrowIcon
                  onClick={() => {
                    if (canChangeRequestedDailies)
                      formik.setFieldValue(
                        'clinical_record.requested_dailies',
                        formik.values.clinical_record.requested_dailies + 1
                      )
                  }}
                />
                <ArrowIcon
                  onClick={() => {
                    if (canChangeRequestedDailies)
                      formik.setFieldValue(
                        'clinical_record.requested_dailies',
                        formik.values.clinical_record.requested_dailies > 0
                          ? formik.values.clinical_record.requested_dailies - 1
                          : formik.values.clinical_record.requested_dailies
                      )
                  }}
                />
              </S.ArrowIconsWrapper>
            }
          />
          <TextAreaNew
            label="Observações médicas"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.clinical_record?.medical_observation}
            rows={3}
            id="clinical_record.medical_observation"
            name="clinical_record.medical_observation"
          />
        </S.FormWrapper>
      </ContainerNew>
    )
  }
)

export default AddClinicalHistoryAndRecordForm

const validationSchema = yup.object().shape({
  expected_date: yup
    .string()
    .nullable()
    .notRequired()
    .when('clinical_record.hospitalization_type', {
      is: (value: HospitalizationType) => {
        return value === HospitalizationType.urgency
      },
      then: yup
        .date()
        .typeError('Data inválida')
        .min(
          moment(new Date(), 'DD/MM/YYYY').startOf('day'),
          'Data passada não permitida. Escolha uma data igual ou posterior à data atual'
        )
        .format('DD/MM/YYYY', true)
    }),
  clinical_record: yup.object().shape({
    bullish_forecast_date: yup
      .string()
      .nullable()
      .notRequired()
      .when('hospitalization_mode', {
        is: (value: HospitalizationMode) => {
          return value === HospitalizationMode.hospital
        },
        then: yup
          .date()
          .typeError('Data inválida')
          .min(
            moment(new Date(), 'DD/MM/YYYY').startOf('day'),
            'Data passada não permitida. Escolha uma data igual ou posterior à data atual'
          )
          .format('DD/MM/YYYY', true)
      })
  })
})
