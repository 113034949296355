import { SearchCID } from 'domain/usecases/surgical-order/search-cid'
import { SurgicalOrderRepository } from 'repository/interfaces/surgical-order-repository'

export class RemoteSearchCID implements SearchCID {
  constructor(
    private readonly surgicalOrderRepository: SurgicalOrderRepository
  ) {
    this.surgicalOrderRepository = surgicalOrderRepository
  }

  load(params: SearchCID.Params): Promise<SearchCID.Model> {
    return this.surgicalOrderRepository.searchCID(params)
  }
}
