import { CreateSurgicalOrder } from 'domain/usecases/surgical-order/create-surgical-order'
import { SurgicalOrderRepository } from 'repository/interfaces/surgical-order-repository'

export class RemoteCreateSurgicalOrder implements CreateSurgicalOrder {
  constructor(
    private readonly surgicalOrderRepository: SurgicalOrderRepository
  ) {}

  create(params: CreateSurgicalOrder.Params) {
    return this.surgicalOrderRepository.createSurgicalOrder(params)
  }
}
