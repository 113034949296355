import styled, { css } from 'styled-components'
import Drawer from '@material-ui/core/Drawer'

export const Section = styled.section`
  padding: 8px 0 24px;

  > .s-title {
    margin-bottom: 8px;
  }
`

export const ShortCutsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  flex: none;
  overflow-x: scroll;
  overflow-y: hidden;
  scrollbar-width: none;
  -ms-overflow-style: none;
  scroll-behavior: auto;
  margin: 0 -24px !important;
  padding: 0 24px !important;
  &::-webkit-scrollbar {
    display: none;
  }
`

export const CarouselWrapper = styled.div`
  /* border: 1px solid red; */
`

export const DrawerWrapper = styled(Drawer)`
  z-index: ${({ theme }) => theme.layers.base - 1} !important;
  .MuiDrawer-paper {
    background-color: #f0f5ef;
    padding: 24px 24px 38px;
    font-family: ${({ theme }) => theme.font.family};
  }

  .MuiDrawer-paperAnchorTop {
    top: 72px;
  }
`

export const DependentsList = styled.div`
  margin-top: 24px;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
  gap: 24px;
`

export const StayTunedItem = styled.div`
  margin-bottom: 24px;
`

export const CardSeiwa = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    gap: 40px;
    position: relative;
    padding: 24px;
    height: 100%;
    border-radius: 8px;
    box-shadow: 0px 1px 3px 0px #0000001f;
    background: linear-gradient(
      253.2deg,
      ${theme.ds.colors.primary500} 32.01%,
      ${theme.ds.colors.primary300} 58.61%
    );

    &::before {
      content: '';
      z-index: 0;
      position: absolute;
      bottom: 0;
      right: 0;
      left: 0;
      height: calc(100% - 75px);
      border-radius: 8px 8px 0 0;
      background: ${theme.ds.colors.white};
      clip-path: ellipse(120% 100% at 35% 100%);
    }

    > .c-header {
      z-index: 1;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 35px;
      gap: 8px;
    }

    > .c-content {
      position: relative;
      z-index: 1;
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 8px;
      flex-wrap: wrap;

      > .box-text {
        flex-direction: column;
      }

      > button {
        border-radius: 24px;
        background-color: ${theme.ds.colors.primary50};
        color: ${theme.ds.colors.primary800};
        padding: 16px;
        font-size: ${theme.ds.typography.size.small};

        path {
          fill: ${theme.ds.colors.primary800};
        }

        @media screen and (max-width: 410px) {
          margin: 16px auto 0;
        }
      }
    }
  `}
`
