import React, { useEffect } from 'react'

import HeaderNew from 'presentation/shared/components/HeaderNew'

import { useFormik } from 'formik'
import ButtonNew from 'presentation/shared/components/ButtonNew'
import { ContainerNew } from 'presentation/shared/components/ContainerNew'
import TextFieldNew from 'presentation/shared/components/TextFieldNew'
import * as S from './styles'
import { ValidationSchema } from './validationSchema'
import {
  WithLoading,
  WithLoadingProps
} from 'presentation/shared/components/HOCs/WithLoading'
import { useHistory, useLocation } from 'react-router'
import { useStores } from 'presentation/hooks/use-stores'
import { useServices } from 'presentation/hooks/use-services'
import { toast } from 'react-toastify'

type CooperativeValue = {
  cooperative_name: string
}

export const CooperativeFormLayout = WithLoading(
  ({ setIsLoading }: WithLoadingProps) => {
    const { state } = useLocation<{
      isEditing?: boolean
    }>()
    const surgicalOrderContext = useStores().surgicalOrder
    const surgicalOrderService = useServices().surgicalOrder
    const surgicalOrderObjContext = useStores().surgicalOrder.getSurgicalOrder()
    const history = useHistory()

    const handleBackButton = () => history.goBack()

    useEffect(() => {
      if (!surgicalOrderContext.location.hospital?.hospital_id) {
        return history.push('/home')
      }
    }, [])

    const formik = useFormik({
      initialValues: {
        cooperative_name:
          surgicalOrderObjContext.cooperative_name ||
          surgicalOrderContext.cooperative.cooperative_name ||
          ''
      } as CooperativeValue,
      validationSchema: ValidationSchema,
      validateOnMount: true,
      validateOnChange: true,
      enableReinitialize: true,
      onSubmit: async (values) => {
        try {
          setIsLoading(true)
          await surgicalOrderService.updateSurgicalOrder({
            surgical_order_id:
              surgicalOrderContext.getSurgicalOrder().surgical_order_id,
            cooperative_name: values.cooperative_name,
            patient: {
              patient_id: surgicalOrderContext.patient?.patient?.patient_id,
              patient_name:
                surgicalOrderContext.patient?.patient?.name ||
                surgicalOrderContext.patient?.patient?.patient_name
            }
          })

          surgicalOrderContext.setCooperative({
            cooperative_name: values.cooperative_name
          })
          surgicalOrderContext.setSurgicalOrder({
            ...surgicalOrderContext.surgicalOrder,
            cooperative_name: values.cooperative_name
          })

          if (state?.isEditing) {
            history.push('/novo-pedido/resumo')
          } else {
            history.push('/novo-pedido/procedimentos')
          }
        } catch (err: any) {
          toast.error(`[Cooperativa] ${err.message}`)
          return history.goBack()
        } finally {
          setIsLoading(false)
        }
      }
    })

    return (
      <>
        <HeaderNew actualPageTitle="Dados da cooperativa" />
        <ContainerNew
          buttonsHasBoxShadow={!state?.isEditing}
          primaryButton={
            <ButtonNew
              fullWidth
              size="large"
              onClick={formik.submitForm}
              disabled={!!formik.errors?.cooperative_name}
            >
              {state?.isEditing ? 'Salvar edição' : 'Próximo'}
            </ButtonNew>
          }
          secondaryButton={
            !state?.isEditing && (
              <ButtonNew
                outlined
                fullWidth
                size="large"
                style={{
                  marginTop: '16px'
                }}
                onClick={handleBackButton}
              >
                Anterior
              </ButtonNew>
            )
          }
        >
          <S.Wrapper>
            <h1>Dados da cooperativa</h1>
            <span>
              Escreva o nome da Cooperativa de cobrança do honorário médico.
            </span>
          </S.Wrapper>
          <TextFieldNew
            label="Cooperativa"
            placeholder="Digite o nome da cooperativa aqui"
            name="cooperative_name"
            id="cooperative"
            value={formik.values.cooperative_name}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.cooperative_name && formik.errors.cooperative_name
            }
            required
          />
        </ContainerNew>
      </>
    )
  }
)
