import React from 'react'

import { DateLocales } from 'common/enum/date-locales'
import getDateFormattedByLocale from 'common/utils/getDateFormmatedByLocale'
import { SurgicalOrderModel } from 'domain/entities/surgical-order-model'
import ProfilePic from '../ProfilePic'

import * as S from './styles'

type Props = {
  vitalData?: boolean
  surgicalOrder?: SurgicalOrderModel
}

export default function InternationDetails({
  vitalData,
  surgicalOrder
}: Props) {
  return (
    <S.Wrapper>
      {surgicalOrder && (
        <>
          <S.Header>
            <ProfilePic name={surgicalOrder.patient?.name} />
            <p>{surgicalOrder.patient?.name ?? '-'}</p>
          </S.Header>
          <S.PatientInfoContainer>
            <S.PatientInfo>
              <div>
                <strong>Nascimento: </strong>
                <label>
                  {getDateFormattedByLocale(
                    surgicalOrder.patient?.birthday,
                    DateLocales.ptBr
                  ) ?? '-'}
                </label>
              </div>
            </S.PatientInfo>
            <S.PatientInfo>
              <div>
                <strong>Atendimento: </strong>
                <label>-</label>
              </div>
              <div>
                <strong>Dieta: </strong>
                <label>-</label>
              </div>
            </S.PatientInfo>
            <S.PatientInfo>
              <div>
                <strong>Local: </strong>
                <label>-</label>
              </div>
            </S.PatientInfo>
            <S.PatientInfo>
              <div>
                <strong>Convênio: </strong>
                <label>
                  {surgicalOrder.healthInsurance?.healthInsuranceName}
                </label>
              </div>
              <div>
                <strong>Dias de internação: </strong>
                <label>{surgicalOrder.requestedDailies ?? 0}</label>
              </div>
            </S.PatientInfo>
          </S.PatientInfoContainer>
        </>
      )}
      {vitalData && (
        <S.VitalData>
          <h5>Dados vitais</h5>
          <p>Última atualização: -</p>
          <S.VitalDataIndicators>
            <div>
              <p>Pressão arterial</p>
              <span style={{ color: '#5FDB8C' }}>-</span>
            </div>
            <div style={{ justifySelf: 'center' }}>
              <p>Batimentos Cardíacos</p>
              <span style={{ color: '#3A82D7' }}>-</span>
            </div>
            <div>
              <p>Temp. corporal</p>
              <span style={{ color: '#EE9F66' }}>-</span>
            </div>
          </S.VitalDataIndicators>
        </S.VitalData>
      )}
    </S.Wrapper>
  )
}
