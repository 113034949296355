export function formatName(fullName: string) {
  const splitedNames = fullName.split(' ')

  const formatedName = splitedNames
    .map((splitedName) => {
      if (splitedName.length > 2) {
        return (
          splitedName.charAt(0).toUpperCase() +
          splitedName.slice(1).toLowerCase()
        )
      } else {
        return splitedName.toLowerCase()
      }
    })
    .join(' ')

  return formatedName
}
