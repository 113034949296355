import React from 'react'
import SheetModalNew from 'presentation/shared/components/SheetModalNew'
import SupportTextNew from 'presentation/shared/components/SupportTextNew'
import ButtonNew from 'presentation/shared/components/ButtonNew'

type Props = {
  isOpen: boolean
  onClose: () => void
}

const DocumentSuccessModal = ({ isOpen, onClose }: Props) => {
  const ConfirmButton = () => {
    return (
      <ButtonNew size="large" onClick={onClose}>
        Ok, entendi
      </ButtonNew>
    )
  }

  return (
    <SheetModalNew
      title="Responder pendência"
      primaryButton={<ConfirmButton />}
      isOpen={isOpen}
      onClose={onClose}
    >
      <SupportTextNew>
        O documento incluído por você foi salvo com sucesso.
        <br />
        <br />
        Para enviar sua resposta à CRMO clique em{' '}
        <SupportTextNew as="span" weight="bold">
          Responder pendência
        </SupportTextNew>{' '}
        a qualquer momento.
      </SupportTextNew>
    </SheetModalNew>
  )
}

export default DocumentSuccessModal
