export enum SurgicalOrdersStatus {
  UNDER_REVIEW_AT_THE_HOSPITAL = 'UNDER_REVIEW_AT_THE_HOSPITAL',
  UNDER_REVIEW_AT_THE_INSURANCE = 'UNDER_REVIEW_AT_THE_INSURANCE',
  AUTHORIZED = 'AUTHORIZED',
  DENIED = 'DENIED',
  PARTIALLY_AUTHORIZED = 'PARTIALLY_AUTHORIZED',
  CANCELLED = 'CANCELLED',
  CONCLUDED = 'CONCLUDED',
  ORDER_CREATED = 'ORDER_CREATED',
  ORDER_OPEN = 'ORDER_OPEN',
  ORDER_REGISTERED = 'ORDER_REGISTERED',
  REQUESTED_CANCELLATION = 'REQUESTED_CANCELLATION'
}

export enum SurgicalOrdersStatusPlural {
  UNDER_REVIEW_AT_THE_HOSPITAL = 'em análise no hospital',
  UNDER_REVIEW_AT_THE_INSURANCE = 'em análise no convênio',
  AUTHORIZED = 'autorizados',
  PARTIALLY_AUTHORIZED = 'Pedidos autorizados parcialmente',
  DENIED = 'Pedidos negados',
  CANCELLED = 'cancelados',
  CONCLUDED = 'realizados',
  ORDER_CREATED = 'Pedidos criados',
  ORDER_OPEN = 'Pedidos em aberto',
  ORDER_REGISTERED = 'Pedidos solicitados',
  REQUESTED_CANCELLATION = 'cancelamentos solicitados'
}

export enum SurgicalOrdersStatusSingular {
  UNDER_REVIEW_AT_THE_HOSPITAL = 'em análise no hospital',
  UNDER_REVIEW_AT_THE_INSURANCE = ' em análise no convênio',
  PARTIALLY_AUTHORIZED = 'Autorizado parcialmente',
  DENIED = 'Pedido negado',
  AUTHORIZED = 'autorizado',
  CANCELLED = 'cancelado',
  CONCLUDED = 'realizado',
  ORDER_CREATED = 'Pedido criado',
  ORDER_OPEN = 'Pedido em aberto',
  ORDER_REGISTERED = 'Pedido solicitado',
  REQUESTED_CANCELLATION = 'cancelamento solicitado'
}
