import React from 'react'
import UpdatePasswordForm from 'presentation/secretary/components/Forms/UpdatePassword'

type Props = {
  setUpdatePasswordSuccess: React.Dispatch<React.SetStateAction<boolean>>
}
export default function UpdatePasswordLayout({
  setUpdatePasswordSuccess
}: Props) {
  return (
    <>
      <UpdatePasswordForm setUpdatePasswordSuccess={setUpdatePasswordSuccess} />
    </>
  )
}
