import styled from 'styled-components'

export const NoContent = styled.div`
  width: 100%;
  padding: 70px;
  color: ${({ theme }) => theme.colors.lightGray}90;
  text-align: center;
  font-weight: ${({ theme }) => theme.font.semiBold};
  font-size: ${({ theme }) => theme.font.sizes.small};
`

export const Container = styled.div`
  margin-top: 10px;
`
