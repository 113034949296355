import styled from 'styled-components'

export const Content = styled.div`
  display: flex;
  flex: 1;
  padding-bottom: 100px;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`

export const Inputs = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px;
  margin-top: 40px;

  div {
    width: 100%;
  }
`
