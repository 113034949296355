import styled from 'styled-components'

export const FormContentStepOne = styled.div`
  display: grid;

  grid-template-columns: 1fr 1fr;
  align-items: flex-end;
  column-gap: 10%;
  height: 100%;
`

export const BodyInfoLabel = styled.small`
  color: ${({ theme }) => theme.colors.gray};
  font-size: ${({ theme }) => theme.font.sizes.small};
  height: 8px;
  width: 1px;
  margin-top: -7px;
`

export const InputWithLabel = styled.div`
  input {
    padding-right: 50px;
  }
`
