import moment from 'moment'

// Newborn Patient
export const isPatientRN = (birthday: Date | string | null | undefined) => {
  if (!birthday) return false
  const isValidRNDate =
    moment(birthday, 'DD/MM/YYYY').isValid() &&
    moment().diff(moment(birthday, 'DD/MM/YYYY'), 'seconds') >= 0
  return isValidRNDate
    ? Number(moment().diff(moment(birthday, 'DD/MM/YYYY'), 'days')) <= 30
    : undefined
}
