export type ProposedProtocolModel = {
  proposed_protocol_id?: number
  weight: number
  height: number
  bodySurface: number
  protocol: TherapeuticPlanProtocol
  cycleInterval: number
  currentCycle: number
  totalCycles: number
  ecog: number
  treatmentLine: string
  purpose: ProposedProtocolPurpose
  treatmentRegimen: TreatmentRegimen
  procedureQuantity: string
  medicines: ProposedProtocolMedicines[]
  createdAt: Date
  updatedAt: Date
}

export type TherapeuticPlanProtocol = {
  code: string
  description: string
}

export enum TreatmentRegimen {
  AMBULATORY = 'AMBULATORY',
  HOSPITALIZATION = 'HOSPITALIZATION'
}

export enum ProposedProtocolPurpose {
  NEOADJUVANT = 'NEOADJUVANT',
  ADJUVANT = 'ADJUVANT',
  POLYACTIVE = 'POLYACTIVE',
  CURATIVE = 'CURATIVE'
}

export enum CreatinineClearanceType {
  CREATININE = 'CREATININE',
  CLEARANCE = 'CLEARANCE'
}

export type ProposedProtocolMedicines = {
  name: string
  activePrinciple: string
  frequency: number
  dose: number
  routeAdministration: string
  achievementDates: string
}
