import React, { useContext } from 'react'
import SurgicalSchedulingSummaryLayout from 'presentation/doctor/layouts/SurgicalSchedulingSummary'
import { SurgicalSchedulinhContext } from 'main/factories/pages/doctor/surgical-scheduling/surgical-scheduling-factory'
import { useServices } from 'presentation/hooks/use-services'
import { SendSurgicalSchedulingModel } from 'domain/usecases/surgical-scheduling/send-surgical-scheduling'
import {
  WithLoading,
  WithLoadingProps
} from 'presentation/shared/components/HOCs/WithLoading'
import { toast } from 'react-toastify'
import { useHistory } from 'react-router'
import HeaderNew from 'presentation/shared/components/HeaderNew'

function SurgicalSchedulingSummary({ setIsLoading }: WithLoadingProps) {
  const { state } = useContext<any>(SurgicalSchedulinhContext)
  const history = useHistory()

  const surgicalSchedulingService = useServices().surgicalScheduling
  const sendSurgicalScheduling = async (
    params: SendSurgicalSchedulingModel
  ) => {
    setIsLoading(true)

    surgicalSchedulingService
      .sendSurgicalScheduling(params)
      .then((response) => {
        history.push('/agendamento-cirurgico/final', {
          schedulingId: response.data.id
        })
      })
      .catch((error) => {
        if (error.message === 'Horário indisponível') {
          history.push('/agendamento-cirurgico/data-e-hora', {
            isReviewing: true,
            isUnavailableSchedule: true
          })
        } else {
          toast.error('Ocorreu um erro ao realizar o agendamento.')
        }
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const goToAnalyzeList = () => {
    history.push('/pedidos-cirurgicos/agendamento', {
      schedulingStatusType: 'UNSCHEDULED'
    })
  }

  return (
    <>
      <HeaderNew
        actualPageTitle="Detalhes do agendamento"
        actualPageOnClick={goToAnalyzeList}
      />
      <SurgicalSchedulingSummaryLayout
        state={state}
        sendSurgicalScheduling={sendSurgicalScheduling}
      />
    </>
  )
}

export default WithLoading(SurgicalSchedulingSummary)
