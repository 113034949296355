import styled, { css } from 'styled-components'

import { Props } from '.'

export const Wrapper = styled.div<Pick<Props, 'bgColor'>>`
  ${({ theme, bgColor }) => css`
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    flex-shrink: 0;
    width: 160px;
    height: 200px;
    padding: 16px 8px;
    border-radius: 8px;
    background-color: ${bgColor
      ? theme.ds.colors[bgColor!]
      : theme.ds.colors.primary50};
  `}
`

export const CardHeader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  height: 35px;
`

export const Small = styled.small<Pick<Props, 'titleSmallerColor'>>`
  ${({ theme, titleSmallerColor }) => css`
    display: block;
    color: ${titleSmallerColor
      ? theme.ds.colors[titleSmallerColor!]
      : theme.ds.colors.neutral700};
  `}
`

export const ImageWrapper = styled.div`
  width: 120px;
  height: 120px;

  > * {
    width: 100%;
    height: 100%;
  }
`
