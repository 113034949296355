import React, { useEffect, useState } from 'react'
import DoctorHomeLayout from 'presentation/doctor/layouts/Home'
import { useServices } from 'presentation/hooks/use-services'
import {
  WithLoading,
  WithLoadingProps
} from 'presentation/shared/components/HOCs/WithLoading'
import { toast } from 'react-toastify'
import { useHistory } from 'react-router'
import { LocalStorageAdapter } from 'infra/storage-adapter/storage-adapter'
import { SurgicalOrdersStatus } from 'common/enum/surgical-orders-status'

export const DoctorHome = ({ setIsLoading }: WithLoadingProps) => {
  const history = useHistory()
  const surgicalPendencyService = useServices().surgicalPendency
  const [pendenciesNumber, setPendenciesNumber] = useState(0)
  const surgicalOrderService = useServices().surgicalOrder
  const [
    showSurgicalOrderCancellationNotification,
    setShowSurgicalOrderCancellationNotification
  ] = useState(false)

  const goToSurgicalOrderIntegration = async () => {
    try {
      const localStorageAdapter = new LocalStorageAdapter()
      const userFlags = localStorageAdapter.get('user-flags')
      const surgicalOrderIntegrationLocalKey =
        userFlags?.surgicalOrderIntegration

      if (!surgicalOrderIntegrationLocalKey) {
        const response: any =
          await surgicalOrderService.loadDashboardStatusCount()

        const keys: string[] = Object.keys(response.data)
        const values: number[] = Object.values(response.data)

        for (const key in keys) {
          if (
            keys[key] !== SurgicalOrdersStatus.ORDER_OPEN &&
            values[key] &&
            values[key] > 0
          ) {
            history.push('/integracao-pedido-cirurgico')
            break
          }
        }
      }
    } catch (error) {
      toast.error('Ocorreu um erro ao buscar os pedidos.')
    }
  }

  const loadPendencies = async () => {
    setIsLoading(true)
    try {
      const response: any =
        await surgicalPendencyService.loadDashboardPendencyCount()

      setPendenciesNumber(response.data.PENDENT)
    } catch {
      toast.error('Ocorreu um erro ao calcular suas pendencia.')
    } finally {
      setIsLoading(false)
    }
  }

  const getCancellationNotificationsCount = async () => {
    setIsLoading(true)
    try {
      const response =
        await surgicalOrderService.getSurgicalOrderCancellationNotificationsCount()

      setShowSurgicalOrderCancellationNotification(response?.notifications > 0)
    } catch {
      toast.error('Ocorreu um erro ao buscar solicitações de cancelamento.')
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    loadPendencies()
    goToSurgicalOrderIntegration()
    getCancellationNotificationsCount()
  }, [])

  return (
    <DoctorHomeLayout
      pendenciesNumber={pendenciesNumber}
      showSurgicalOrderCancellationNotification={
        showSurgicalOrderCancellationNotification
      }
    />
  )
}

export default WithLoading(DoctorHome)
