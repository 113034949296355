import styled from 'styled-components'

export const Section = styled.section`
  padding: 8px 0 24px;

  > .s-title {
    margin-bottom: 8px;
  }
`

export const ShortCutsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  scrollbar-width: none;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    display: none;
  }
`

export const Profile = styled.span`
  display: flex;
  align-items: center;
  gap: 8px;
  text-overflow: ellipsis;
  text-transform: capitalize;
`

export const DependentsList = styled.div`
  margin-top: 24px;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
  gap: 24px;
`

export const Card = styled.div`
  background-color: #fff;
  border-color: #a8a8a8;
  border-width: 1px;
  font-weight: 500;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 16px;
  border-style: solid;
  padding: 8px 16px;
  cursor: pointer;
  margin: 0 20px 20px 20px;

  &.isActive {
    border-color: #038e82 !important;
    border-width: 2px;
    font-weight: 600;
  }

  & > .button {
    font-weight: bold;
  }

  svg {
    width: 30px;
    height: fit-content;
    display: flex;
  }
`
