import { DoctorNavPermissions } from 'common/enum/doctor-nav-permissions'
import { AccountModel } from 'domain/entities/account-model'
import { LoadUserRole } from 'domain/usecases/authentication/load-user-role'
import { LoadDoctor } from 'domain/usecases/doctor/load-doctor'
import { PbacAdapter } from 'infra/pbac-adapter/pbac-adapter'
import { makeAutoObservable } from 'mobx'
import { Permissions } from './permissions'
import { Doctor } from 'domain/entities/doctor-model'
import {
  DoctorRegister,
  DoctorRegisterStatus
} from 'domain/entities/doctor-register'
import { LocalStorageAdapter } from 'infra/storage-adapter/storage-adapter'

export class DoctorPermissions implements Permissions {
  constructor(
    private pbacAdapter: PbacAdapter,
    private loadUserRole: LoadUserRole,
    private getCurrentUser: () => AccountModel,
    private loadDoctor: LoadDoctor
  ) {
    makeAutoObservable(this)
  }

  set(params: string[]) {
    return (this.pbacAdapter = new PbacAdapter(params))
  }

  getAbilities() {
    return this.pbacAdapter.getAbilities()
  }

  can(permission: string) {
    return this.pbacAdapter.can(permission)
  }

  async load() {
    if (
      this.getCurrentUser()?.accessToken &&
      this.getCurrentUser()?.user.role
    ) {
      const abilities = await this.loadUserRole.load(['abilities'])
      const doctor = await this.loadDoctor?.load([
        'keySignature',
        'doctorRegister { status }',
        'bannerViewedByDoctor'
      ])

      const localStorageAdapter = new LocalStorageAdapter()
      const currentFlags = localStorageAdapter.get('user-flags')
      localStorageAdapter.set('user-flags', {
        ...currentFlags,
        bannerViewedByDoctor: doctor.bannerViewedByDoctor
      })

      this.set(abilities.abilities)
      const redirectPermission =
        this.getRedirectPermissionByDoctorStatus(doctor)
      if (redirectPermission) {
        this.set([...abilities.abilities, redirectPermission])
      }
    }
  }

  private getRedirectPermissionByDoctorStatus = (
    doctor: Doctor
  ): DoctorNavPermissions | undefined => {
    const hasSignature = !!doctor.keySignature
    if (
      doctor.doctorRegister &&
      this.shouldRedirectToDoctorRecommendationRegister(doctor.doctorRegister)
    ) {
      return DoctorNavPermissions.GO_TO_RECOMMENDATION_REGISTER
    }

    if (this.shouldRedirectToReview(doctor.doctorRegister)) {
      return DoctorNavPermissions.GO_TO_REVIEW
    }

    if (!hasSignature) {
      return DoctorNavPermissions.GO_TO_ADD_SIGNATURE
    }
  }

  private shouldRedirectToReview = (
    doctorRegister?: DoctorRegister
  ): boolean => {
    if (!doctorRegister?.status) return false
    const status = [
      DoctorRegisterStatus.PENDENCY_ANSWERED,
      DoctorRegisterStatus.PENDENT,
      DoctorRegisterStatus.REGISTER_REPROVED_BY_DIRECTOR,
      DoctorRegisterStatus.REGISTER_SEND_TO_DIRECTOR,
      DoctorRegisterStatus.REVIEWED_BY_DIRECTOR,
      DoctorRegisterStatus.UNREVIEWED_REGISTER
    ]

    return status.includes(doctorRegister?.status)
  }

  private shouldRedirectToDoctorRecommendationRegister = (
    doctorRegister?: DoctorRegister
  ): boolean => {
    if (!doctorRegister?.status) return false
    return doctorRegister?.status === DoctorRegisterStatus.INCOMPLETE_REGISTER
  }

  clean() {
    return (this.pbacAdapter = new PbacAdapter())
  }
}
