import {
  AmbulatoryAuthorizationStatus,
  AmbulatoryAuthorizationStatusPlural
} from 'common/enum/ambulatory-authorization-status'

export const ambulatory_status_colors = [
  {
    key: AmbulatoryAuthorizationStatus.HOSPITAL_ANALYSIS,
    label: AmbulatoryAuthorizationStatusPlural.HOSPITAL_ANALYSIS,
    color: 'secondary500',
    background: 'secondary100'
  },
  {
    key: AmbulatoryAuthorizationStatus.ANALYSIS_INSURANCE,
    label: AmbulatoryAuthorizationStatusPlural.ANALYSIS_INSURANCE,
    color: 'secondary500',
    background: 'secondary100'
  },
  {
    key: AmbulatoryAuthorizationStatus.WAITING_BUDGET,
    label: AmbulatoryAuthorizationStatusPlural.WAITING_BUDGET,
    color: 'secondary500',
    background: 'secondary100'
  },
  {
    key: AmbulatoryAuthorizationStatus.DENIED,
    label: AmbulatoryAuthorizationStatusPlural.DENIED,
    color: 'terciary500',
    background: 'terciary100'
  },
  {
    key: AmbulatoryAuthorizationStatus.AUTHORIZED,
    label: AmbulatoryAuthorizationStatusPlural.AUTHORIZED,
    color: 'primary500',
    background: 'primary100'
  },
  {
    key: AmbulatoryAuthorizationStatus.DONE,
    label: AmbulatoryAuthorizationStatusPlural.DONE,
    color: 'primary500',
    background: 'primary100'
  },
  {
    key: AmbulatoryAuthorizationStatus.CANCELED,
    label: AmbulatoryAuthorizationStatusPlural.CANCELED,
    color: 'neutral500',
    background: 'neutral100'
  }
]

export function getColorStatus(status: any) {
  const statusColor = ambulatory_status_colors.find(
    (statusOption) => statusOption.key === status
  )

  return statusColor
}
