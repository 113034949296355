import React from 'react'
import { useFormik } from 'formik'
import TextFieldNew from 'presentation/shared/components/TextFieldNew'
import Button from 'presentation/shared/components/Button'
import * as S from './styles'
import { fields } from './fields'
import { toast } from 'react-toastify'
import { useHistory } from 'react-router'
import { User } from 'domain/entities/user-model'
import { useServices } from 'presentation/hooks/use-services'

export type Props = {
  data: User
  setOpenSuccessModal: React.Dispatch<React.SetStateAction<boolean>>
}

export default function UpdateSecretaryProfileForm({
  data,
  setOpenSuccessModal
}: Props) {
  const [blockEdit, setBlockEdit] = React.useState<boolean>(true)

  const history = useHistory()
  const userService = useServices().user

  const formik = useFormik({
    initialValues: {
      name: data.name,
      email: data.email,
      landlinePhone: data.landlinePhone,
      phone: data.phone,
      cpf: data.cpf,
      user_id: data.user_id
    },
    onSubmit: async (values) => {
      try {
        await userService.updateUser(values)

        setTimeout(() => {
          history.push('/home')
        }, 2000)
        setOpenSuccessModal(true)
      } catch (error: any) {
        toast.error(error.message)
      }
    }
  })

  const changeEnableForm = () => {
    if (blockEdit) {
      setBlockEdit(!blockEdit)
    }
    if (!blockEdit) {
      formik.handleSubmit()
    }
  }

  return (
    <>
      <S.Title>Dados Pessoais</S.Title>
      <form onSubmit={formik.handleSubmit}>
        <S.Wrapper>
          {fields.map(({ name, placeholder, label, mask }) => (
            <TextFieldNew
              disabled={blockEdit || name === 'cpf' || name === 'nickname'}
              key={name}
              label={label}
              labelColor="neutral700"
              mask={mask || undefined}
              // @ts-ignore: Unreachable code error
              value={formik.values[name]}
              placeholder={placeholder}
              name={name}
              onInputChange={formik.handleChange(name)}
              onBlur={formik.handleBlur(name)}
            />
          ))}
        </S.Wrapper>

        <S.Actions>
          <Button
            type="button"
            variant={blockEdit ? 'outlined' : undefined}
            onClick={() => changeEnableForm()}
            style={{ width: '100%' }}
          >
            {blockEdit ? 'Editar Informações' : 'Salvar alterações'}
          </Button>
        </S.Actions>
      </form>
      <S.LineDivider />
    </>
  )
}
