export const phoneMask = [
  '+',
  /\d/,
  /\d/,
  ' ',
  /\d/,
  /\d/,
  ' ',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/
]

export const phoneMaskWithParenthesis = [
  '+',
  /\d/,
  /\d/,
  ' ',
  '(',
  /\d/,
  /\d/,
  ')',
  ' ',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/
]

export const landlinePhoneMask = [
  '+',
  /\d/,
  /\d/,
  ' ',
  /\d/,
  /\d/,
  ' ',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/
]

export const cpfMask = [
  /\d/,
  /\d/,
  /\d/,
  '.',
  /\d/,
  /\d/,
  /\d/,
  '.',
  /\d/,
  /\d/,
  /\d/,
  '-',
  /\d/,
  /\d/
]

export const rgMask = [
  /\d/,
  /\d/,
  '.',
  /\d/,
  /\d/,
  /\d/,
  '.',
  /\d/,
  /\d/,
  /\d/,
  '-',
  /\d/
]

export const dateMask = [
  /[0-3]/,
  /[0-9]/,
  '/',
  /[0-1]/,
  /[0-9]/,
  '/',
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  /[0-9]/
]

export const durationMask = [/[0-9]/, /[0-9]/, ':', /[03]/, /[0]/]

export const timeMask = [/[0-3]/, /[0-9]|[0-3]/, ':', /[0-5]/, /[0-9]/]

export const dateTimeMask = [
  /\d/,
  /\d/,
  '/',
  /\d/,
  /\d/,
  '/',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  ' ',
  /\d/,
  /\d/,
  ':',
  /\d/,
  /\d/
]

export const temperatureMask = [/\d/, /\d/, '.', /\d/, ' °C']

export const ufMask: (string | RegExp)[] = [/^[A-Z]*$/, /^[A-Z]*$/]

export const zipCodeMask = [/\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/]

export const nameMask = (name: string): (string | RegExp)[] => {
  return name.split('').map(() => /^[a-zA-Z\xC0-\uFFFF\s]+$/gi)
}

export const RgRegex = /^\d{0,10}(\d|x|X)$/i

export function formatMask(mask: (string | RegExp)[], value: string) {
  if (!value) return ''
  const chars = value.split('')

  let masked = ''

  for (let i = 0, k = 0; i < mask.length; i++) {
    const maskElement = mask[i]
    if (maskElement instanceof RegExp) {
      masked += maskElement.test(chars[k]) ? chars[k] : ''
      k++
    } else {
      masked += maskElement
    }
  }

  return masked
}

export function formatPhone(phone?: string) {
  return formatMask(phoneMask, phone ?? '')
}
