import styled from 'styled-components'

export const NotificationsBell = styled.button`
  cursor: pointer;
  position: relative;
  outline: none;
  background: none;
  border: none;

  svg {
    min-width: 28px;
    max-width: 28px;
  }

  .notifications-amount {
    position: absolute;
    background-color: ${({ theme }) => theme.colors.red};
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    color: ${({ theme }) => theme.colors.white};
    font-size: 12px;
    text-align: center;
    top: 0;
    right: -10px;
  }
`

export const BoxNotifications = styled.div`
  position: fixed;
  display: block;
  width: 100%;
  max-width: 500px;
  top: 72px;
  bottom: 0;
  right: 0;
  transform: translateX(102%);
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: 0px 8px 10px 2px rgba(0, 0, 0, 0.15);
  transition: transform ease-in-out 0.3s;

  @media (max-width: 768px) {
    position: fixed;
    top: 0;
    max-width: unset;
  }

  &.-open {
    transform: translateX(0);
  }
`

export const BoxHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 72px;
  margin: 0 24px 8px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.medGray};

  @media (max-width: 768px) {
    justify-content: flex-start;
  }

  @media (max-width: 500px) {
    margin: 0 16px 8px;
  }

  h4 {
    margin-left: 0;
    font-size: ${({ theme }) => theme.font.sizes.medium};
    font-weight: ${({ theme }) => theme.font.semiBold};
    color: ${({ theme }) => theme.colors.neutralBlack};
  }

  .btn {
    background-color: unset;
    border: none;
    outline: none;
    cursor: pointer;
    padding: 2px;

    &.-back {
      display: none;

      @media (max-width: 768px) {
        display: flex;
        margin-right: 15px;
      }
    }

    &.-close {
      &:hover {
        svg {
          fill: ${({ theme }) => theme.colors.gray};
        }
      }
      @media (max-width: 768px) {
        display: none;
      }
    }
  }
`

export const BoxContent = styled.div`
  overflow-y: auto;
  height: calc(100% - 80px); //80px = BoxHeader height + margin-bottom
  padding: 0 14px 0 24px;
  @media (max-width: 500px) {
    padding: 0 6px 0 16px;
  }
`

export const CardNotification = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: flex-start;
  padding: 16px;
  width: 100%;
  height: 160px;
  background-color: ${({ theme }) => theme.colors.softGreen};
  margin: auto;

  &.-is-read {
    background-color: ${({ theme }) => theme.colors.white};
  }

  &:not(:last-of-type) {
    border-bottom: 1px solid ${({ theme }) => theme.colors.lightestGray};
  }

  @media (max-width: 768px) {
    height: auto;
  }

  @media (max-width: 500px) {
    padding: 16px 8px;
  }

  strong {
    font-weight: ${({ theme }) => theme.font.semiBold};
    font-size: ${({ theme }) => theme.font.sizes.xsmall};
    color: ${({ theme }) => theme.colors.neutralBlack};
  }

  p {
    font-style: normal;
    font-weight: ${({ theme }) => theme.font.normal};
    font-size: ${({ theme }) => theme.font.sizes.xsmall};
    line-height: 1.5;
    color: ${({ theme }) => theme.colors.black};
    flex-grow: 0;

    @media (max-width: 768px) {
      margin: 8px 0;
    }
  }

  button {
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: 8px;
    gap: 8px;
    height: 40px;
    width: fit-content;
    border-radius: 8px;
    font-family: 'Poppins';
    background: transparent;
    color: ${({ theme }) => theme.colors.primary};
    border: 1px solid ${({ theme }) => theme.colors.primary};
    font-weight: ${({ theme }) => theme.font.bold};
    font-size: ${({ theme }) => theme.font.sizes.xsmall};
    transition: all ease 0.2s;

    &:hover,
    &:active,
    &:focus {
      background-color: ${({ theme }) => theme.colors.primary};
      color: ${({ theme }) => theme.colors.white};
    }
  }
`

export const NoNotificationsContainer = styled.div`
  display: grid;
  justify-content: center;
  margin-top: 45px;

  svg {
    place-self: center;
    margin-bottom: 30px;
  }
`
