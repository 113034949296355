import React from 'react'
import * as S from './styles'
import SheetModalNew from 'presentation/shared/components/SheetModalNew'
import SupportTextNew from 'presentation/shared/components/SupportTextNew'
import {
  SurgicalOrdersStatus,
  SurgicalOrdersStatusSingular
} from 'common/enum/surgical-orders-status'
import moment from 'moment'
import { getColor, getDateTextFromNow } from '../..'
import theme from 'presentation/styles/theme'
import Divider from 'presentation/shared/components/DividerNew'
import TextAreaNew from 'presentation/shared/components/TextAreaNew'
import ButtonNew from 'presentation/shared/components/ButtonNew'
import { Roles } from 'common/enum/roles'
import { capitalizeEachWordText } from 'common/utils/capitalizeEachWordText'
import { useServices } from 'presentation/hooks/use-services'
import { toast } from 'react-toastify'
import { useFormik } from 'formik'
import * as yup from 'yup'
import { WithLoadingProps } from 'presentation/shared/components/HOCs/WithLoading'

type Props = {
  onClose: (value?: boolean) => void
  isOpen: boolean
  status: keyof typeof SurgicalOrdersStatusSingular
  created_at: Date | string
  timeline: {
    status: keyof typeof SurgicalOrdersStatusSingular
    created_at: Date
  }[]
  cancellation_request?: any
  refreshSurgicalOrder?: () => void
} & WithLoadingProps

export default function Modal({
  setIsLoading,
  isOpen,
  onClose,
  status,
  created_at,
  timeline,
  cancellation_request,
  refreshSurgicalOrder
}: Props) {
  const surgicalOrderService = useServices().surgicalOrder

  const getDoctorComment = () => {
    return cancellation_request?.comments?.find(
      (comment: any) =>
        comment?.props?.created_by.role === Roles.DOCTOR.toLowerCase()
    )?.props?.comment
  }

  const getPatientComment = () => {
    return cancellation_request?.comments?.find(
      (comment: any) =>
        comment?.props?.created_by.role === Roles.PATIENT.toLowerCase()
    )?.props?.comment
  }

  const formik = useFormik({
    initialValues: {
      comment: ''
    },
    validationSchema,
    validateOnChange: true,
    validateOnMount: true,
    onSubmit: async (values) => {
      setIsLoading(true)
      try {
        await surgicalOrderService.addSurgicalOrderCancellationComment({
          comment: values.comment,
          surgical_cancellation_id:
            cancellation_request?.surgical_cancellation_id
        })
      } catch (error: any) {
        toast.error('Ocorreu um erro ao adicionar um comentário!')
      } finally {
        setIsLoading(false)
        if (refreshSurgicalOrder) refreshSurgicalOrder()
        onClose()
      }
    }
  })

  return (
    <SheetModalNew
      isOpen={isOpen}
      onClose={() => onClose()}
      title="Status do pedido"
      gap="24px"
      size={840}
    >
      <S.StatusCard
        shadow="none"
        border={getColor(status).lighten as keyof typeof theme.ds.colors}
      >
        <div>
          <SupportTextNew
            color={getColor(status).main as keyof typeof theme.ds.colors}
            weight="bold"
            size="large"
            className="capitalize-fl"
          >
            {SurgicalOrdersStatusSingular[status]}
          </SupportTextNew>
          {status === SurgicalOrdersStatus.REQUESTED_CANCELLATION ? (
            <>
              <SupportTextNew size="small">
                Solicitação de cancelamento enviada{' '}
                {getDateTextFromNow(cancellation_request?.created_at)} por{' '}
                {cancellation_request?.requested_by?.name &&
                  capitalizeEachWordText(
                    cancellation_request?.requested_by?.name
                  )}
                .
              </SupportTextNew>
              <Divider />
              <SupportTextNew weight="semiBold" size="large">
                Justificativa de cancelamento
              </SupportTextNew>
              <TextAreaNew
                disabled
                value={cancellation_request?.reason}
                textColor="neutral400"
                fontSize="medium"
              />
            </>
          ) : (
            <SupportTextNew size="medium">
              Pedido criado {created_at}
            </SupportTextNew>
          )}
        </div>
      </S.StatusCard>

      <S.Timeline>
        {timeline
          .map((step) => {
            return (
              <S.TimelineStep
                key={step.status}
                color={getColor(status).lighten as keyof typeof theme.ds.colors}
              >
                <SupportTextNew
                  color="neutral900"
                  size="medium"
                  className="capitalize-fl"
                >
                  {SurgicalOrdersStatusSingular[step.status]}
                </SupportTextNew>
                <SupportTextNew color="neutral900" weight="bold" size="xsmall">
                  {moment(step.created_at)
                    .utc()
                    .format('DD [de] MMM[.] [de] YYYY [às] HH[h]mm')}
                </SupportTextNew>
              </S.TimelineStep>
            )
          })
          .reverse()}
      </S.Timeline>

      {cancellation_request?.requested_by?.role ===
        Roles.DOCTOR.toLowerCase() &&
        getPatientComment() && (
          <>
            <Divider marginTop="0px" marginBottom="0px" />
            <SupportTextNew
              weight="semiBold"
              size="large"
              style={{ marginBottom: '-16px' }}
            >
              Comentário do paciente
            </SupportTextNew>
            <TextAreaNew
              fontSize="medium"
              textColor="neutral400"
              disabled
              value={getPatientComment()}
            />
          </>
        )}

      {cancellation_request?.requested_by?.role ===
        Roles.PATIENT.toLowerCase() && (
        <>
          {getDoctorComment() ? (
            <>
              <Divider marginTop="0px" marginBottom="0px" />
              <SupportTextNew
                weight="semiBold"
                size="large"
                style={{ marginBottom: '-16px' }}
              >
                Comentário do médico
              </SupportTextNew>
              <TextAreaNew
                fontSize="medium"
                textColor="neutral400"
                disabled
                value={getDoctorComment()}
              />
            </>
          ) : (
            <>
              <Divider marginTop="0px" marginBottom="0px" />
              <SupportTextNew
                weight="semiBold"
                size="large"
                style={{ marginBottom: '-16px' }}
              >
                Gostaria de deixar um comentário para a CRMO sobre o
                cancelamento deste pedido?
              </SupportTextNew>
              <TextAreaNew
                fontSize="medium"
                placeholder="Adicionar comentário"
                id="comment"
                name="comment"
                onChange={formik.handleChange}
                value={formik.values.comment}
              />
              <ButtonNew
                size="large"
                fullWidth
                disabled={!!formik.errors.comment}
                onClick={formik.submitForm}
              >
                Enviar comentário
              </ButtonNew>
            </>
          )}
        </>
      )}
    </SheetModalNew>
  )
}

const validationSchema = yup.object().shape({
  comment: yup.string().required()
})
