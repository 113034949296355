import React, { useEffect, useContext } from 'react'
import AmbulatoryAuthorizationDashboardLayout from 'presentation/doctor/layouts/AmbulatoryAuthorizationDashboard'
import { useServices } from 'presentation/hooks/use-services'
import {
  WithLoading,
  WithLoadingProps
} from 'presentation/shared/components/HOCs/WithLoading'
import { toast } from 'react-toastify'
import { AmbulatoryAuthorizationDashboardContext } from 'main/factories/pages/doctor/ambulatory-authorization/ambulatory-authorization-dashboard-factory'
import { GetAmbulatoryOrders } from 'domain/usecases/ambulatory/get-ambulatory-orders'
// import { GetAmbulatoryOrders } from 'domain/usecases/ambulatory/get-ambulatory-orders'

function AmbulatoryAuthorizationDashboard({ setIsLoading }: WithLoadingProps) {
  const { dispatch } = useContext<any>(AmbulatoryAuthorizationDashboardContext)

  const ambulatoryService = useServices().ambulatory

  const doctorService = useServices().doctor

  const getStatusCount = async () => {
    try {
      const response = await doctorService.loadDoctor(['doctor_id'])

      const response_ambulatory_order_status =
        await ambulatoryService.getAmbulatoryCounters(response.doctor_id)

      dispatch({
        type: 'STATUS_COUNT',
        payload: {
          ...response_ambulatory_order_status.data
        }
      })
    } catch (error: any) {
      toast.error('Ocorreu um erro ao buscar os status')
    }
  }

  const getAmbulatoryOrders = async (params: GetAmbulatoryOrders.Params) => {
    setIsLoading(true)
    try {
      const response = await doctorService.loadDoctor(['doctor_id'])

      const doctorId = response.doctor_id

      const response_ambulatory_orders =
        await ambulatoryService.getAmbulatoryOrders({
          doctorId: doctorId,
          pageSize: 5,
          ...params
        })

      dispatch({
        type: 'PAGINATION',
        payload: response_ambulatory_orders.meta
      })

      dispatch({
        type: 'AMBULATORY_ORDERS_LIST',
        payload: response_ambulatory_orders.data,
        clearList: params.pageNumber === 1
      })
    } catch (error: any) {
      toast.error(
        'Não foi possível carregar os pedidos de autorização ambulatorial'
      )
    } finally {
      setIsLoading(false)
    }
  }

  const searchAmbulatoryOrders = async (params: GetAmbulatoryOrders.Params) => {
    setIsLoading(true)
    try {
      const response = await doctorService.loadDoctor(['doctor_id'])

      const doctorId = response.doctor_id

      const response_ambulatory_orders =
        await ambulatoryService.searchAmbulatoryOrders({
          doctorId: doctorId,
          pageSize: 5,
          ...params
        })

      dispatch({
        type: 'PAGINATION',
        payload: response_ambulatory_orders.meta
      })

      dispatch({
        type: 'SEARCH_AMBULATORY_ORDERS_LIST',
        payload: response_ambulatory_orders.data
      })
    } catch (error: any) {
      toast.error(
        'Não foi possível carregar os pedidos de autorização ambulatorial'
      )
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    setIsLoading(true)

    Promise.all([
      getStatusCount(),
      getAmbulatoryOrders({ pageNumber: 1 })
    ]).finally(() => setIsLoading(false))
  }, [])

  return (
    <AmbulatoryAuthorizationDashboardLayout
      getAmbulatoryOrders={getAmbulatoryOrders}
      searchAmbulatoryOrders={searchAmbulatoryOrders}
    />
  )
}

export default WithLoading(AmbulatoryAuthorizationDashboard)
