import styled, { css } from 'styled-components'

export const FormWrapper = styled.form`
  display: flex;
  flex-direction: column;
  padding-bottom: 32px;
  scrollbar-width: none;

  > div {
    position: relative;

    &:not(:first-child) {
      margin-top: 24px;
    }
  }
`

export const StyledCalendar = styled.div`
  width: 100%;
`

export const Label = styled.label`
  ${({ theme }) => css`
    display: block;
    color: ${theme.ds.colors.neutral900};
    font-size: ${theme.ds.typography.size.small};
    font-weight: ${theme.ds.typography.weight.normal};
    line-height: ${theme.ds.typography.lineHeight.medium};
    cursor: pointer;
    margin-bottom: 8px;
  `}
`

export const Asterisk = styled.span`
  font-size: ${({ theme }) => theme.ds.typography.size.small};
  font-weight: ${({ theme }) => theme.ds.typography.weight.normal};
  color: ${({ theme }) => theme.ds.colors.danger300};
  cursor: pointer;
`

export const RNInputWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 16px;
  flex-wrap: nowrap;

  > div {
    width: 100%;
    min-width: 55px;
    &:first-child {
      max-width: 55px;
    }
  }
`
