import styled from 'styled-components'

export const Wrapper = styled.div`
  width: 100%;
  border-radius: ${({ theme }) => theme.border.radius.medium};
  background-color: ${({ theme }) => theme.colors.white};
  margin-top: 10px;
  margin-bottom: 20px;
  hr {
    border: 0px;
    background-color: ${({ theme }) => theme.colors.lightGray};
    height: 1px;
  }
`

export const Item = styled.div`
  height: 72px;
  padding: 22px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  label {
    font-weight: ${({ theme }) => theme.font.semiBold};
    color: ${({ theme }) => theme.colors.gray};
  }
`
