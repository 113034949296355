import React from 'react'
import { useHistory } from 'react-router'

import { ContainerNew } from 'presentation/shared/components/ContainerNew'
import HeadingNew from 'presentation/shared/components/HeadingNew'
import ButtonNew from 'presentation/shared/components/ButtonNew'
import { ReactComponent as MedicineIllustration } from 'presentation/assets/illustrations/medicine.svg'
import * as S from './styles'
import { useServices } from 'presentation/hooks/use-services'
import { useStores } from 'presentation/hooks/use-stores'
import { DoctorDocument } from 'common/enum/doctor-document'
import downloadFileFromBlob from 'common/utils/downloadFileFromBlob'
import {
  WithLoading,
  WithLoadingProps
} from 'presentation/shared/components/HOCs/WithLoading'
import { toast } from 'react-toastify'
import { getDocumentsLabelLocationByEnum } from 'presentation/utils/document-types-location'

const SurgicalOrderCreatedLayout = WithLoading(
  ({ setIsLoading }: WithLoadingProps) => {
    const history = useHistory()
    const surgicalOrderService = useServices().surgicalOrder
    const surgicalOrderContext = useStores().surgicalOrder

    if (!surgicalOrderContext.getSurgicalOrder().surgical_order_id) {
      toast.error('Ocorreu um erro ao carregar o planejamento cirúrgico')
      history.push('/home')
    }

    const getSurgicalPlanning = async () => {
      try {
        setIsLoading(true)
        const guides = await surgicalOrderService
          .getSurgicalOrderById({
            surgical_order_id:
              surgicalOrderContext.getSurgicalOrder().surgical_order_id
          })
          .then((surgicalOrder) =>
            surgicalOrder.documents?.filter(
              (document) =>
                document.name === DoctorDocument.GUIDE_SURGICAL_PLANNING
            )
          )

        if (guides && guides.length > 0) {
          const file = guides[0]

          if (file) {
            const surgicalPlanning =
              await surgicalOrderService.loadSurgicalOrderDocument({
                surgical_order_id:
                  surgicalOrderContext.getSurgicalOrder().surgical_order_id,
                document_id: file.document_id
              })

            if (surgicalPlanning) {
              downloadFileFromBlob(
                surgicalPlanning.data,
                surgicalPlanning.contentType,
                getDocumentsLabelLocationByEnum(file.name)
              )
              surgicalOrderContext.clear()
              history.push('/pedidos-cirurgicos/dashboard')
            }
          }
        } else {
          toast.warn(
            'A visualização do planejamento cirúrgico ainda não está disponível'
          )
        }
      } catch (error: any) {
        toast.error(error?.message)
      } finally {
        setIsLoading(false)
      }
    }

    return (
      <ContainerNew
        noHeader
        primaryButton={
          <ButtonNew
            fullWidth
            size="large"
            onClick={() => history.push('/pedidos-cirurgicos/dashboard')}
          >
            Meus pedidos cirúrgicos
          </ButtonNew>
        }
        secondaryButton={
          <ButtonNew
            fullWidth
            size="large"
            outlined
            onClick={getSurgicalPlanning}
          >
            Visualizar planejamento cirúrgico
          </ButtonNew>
        }
      >
        <S.Wrapper>
          <S.ImageWrapper>
            <MedicineIllustration />
          </S.ImageWrapper>

          <HeadingNew
            color="primary600"
            size="large"
            style={{ marginBottom: '32px' }}
          >
            Pedido cirúrgico enviado com sucesso
          </HeadingNew>
          <S.Paragraph>
            Seu pedido cirúrgico está <b>em análise</b> pelo hospital.
          </S.Paragraph>

          <S.Paragraph>
            De acordo com a Agência Nacional de saúde Suplementar (ANS), o prazo
            de autorização é de 21 dias úteis.
          </S.Paragraph>
          <S.Paragraph>
            Você pode ver atualizações sobre o status de autorização do pedido
            na tela de <b>Pedidos Cirúrgicos.</b>
          </S.Paragraph>
        </S.Wrapper>
      </ContainerNew>
    )
  }
)

export default SurgicalOrderCreatedLayout
