import { ApiSchedulingRepository } from 'repository/api-scheduling-repository'
import { ApiResponse } from 'service/protocols/api-scheduling/api-scheduling-client'
import {
  IApiSchedulingRepository,
  RepositoryRequest
} from 'service/protocols/api-scheduling/api-scheduling-repository'
import { GetStorage } from 'service/protocols/cache/get-storage'

export class PrepareApiRequestSchedulingDecorator
  implements IApiSchedulingRepository
{
  constructor(
    private readonly getStorage: GetStorage,
    private readonly apiSchedulingRepository: ApiSchedulingRepository
  ) {}

  async post(data: RepositoryRequest): Promise<ApiResponse> {
    // const account = this.getStorage.get('account')
    // if (account?.accessToken) {
    //   Object.assign(data, {
    //     headers: Object.assign(data.headers || {}, {
    //       authorization: 'Bearer ' + account.accessToken
    //     })
    //   })
    // }
    data.url =
      process.env.NODE_ENV === 'production'
        ? `/scheduling${data.url}`
        : `${process.env.REACT_APP_API_MATERDEI_SCHEDULING_URL + data.url}`

    return await this.apiSchedulingRepository.post(data)
  }

  // async get(data: RepositoryRequest): Promise<ApiResponse> {
  //   const account = this.getStorage.get('account')
  //   if (account?.accessToken) {
  //     Object.assign(data, {
  //       headers: Object.assign(data.headers || {}, {
  //         authorization: 'Bearer ' + account.accessToken
  //       })
  //     })
  //   }
  //   data.url =
  //     process.env.NODE_ENV === 'production'
  //       ? `/scheduling${data.url}`
  //       : `${process.env.REACT_APP_API_MATERDEI_SCHEDULING_URL + data.url}`

  //   return await this.apiSchedulingRepository.get(data)
  // }

  // async put(data: RepositoryRequest): Promise<ApiResponse> {
  //   const account = this.getStorage.get('account')
  //   if (account?.accessToken) {
  //     Object.assign(data, {
  //       headers: Object.assign(data.headers || {}, {
  //         authorization: 'Bearer ' + account.accessToken
  //       })
  //     })
  //   }
  //   data.url =
  //     process.env.NODE_ENV === 'production'
  //       ? `/scheduling${data.url}`
  //       : `${process.env.REACT_APP_API_MATERDEI_SCHEDULING_URL + data.url}`

  //   return await this.apiSchedulingRepository.put(data)
  // }
}
