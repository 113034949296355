import { NewAmbulatoryRepository as IAmbulatoryRepository } from 'repository/interfaces/new-ambulatory-repository'
import { IApiRepository } from 'service/protocols/api/api-repository'
import { GetAmbulatoryCounters } from 'domain/usecases/ambulatory/get-ambulatory-counters'
import { GetAmbulatoryOrders } from 'domain/usecases/ambulatory/get-ambulatory-orders'
import parseParams from 'common/utils/parseParams'
import { SearchAmbulatoryOrders } from 'domain/usecases/ambulatory/search-ambulatory-order'
import handleGraphQLError from 'repository/graphql/utils/handle-error'
import { RepositoryErrors } from 'repository/errors/repository-errors'
import { ApiStatusCode } from 'service/protocols/api/api-client'
import { GetOneAmbulatoryOrder } from 'domain/usecases/ambulatory/get-one-ambulatory-order'
import { LoadAmbulatoryOrderDocument } from 'domain/usecases/ambulatory/load-ambulatory-order-document'

export class NewAmbulatoryRepository implements IAmbulatoryRepository {
  constructor(private readonly apiRepository: IApiRepository) {}

  async getAmbulatoryCounters(
    doctorId: number
  ): Promise<GetAmbulatoryCounters.Model> {
    const apiRepository = this
      .apiRepository as IApiRepository<GetAmbulatoryCounters.Model>

    const httpResponse: any = await apiRepository.get({
      url: `/orders/ambulatorial/counters?doctorId=${doctorId}`
    })

    if (
      httpResponse.statusCode &&
      httpResponse.statusCode !== ApiStatusCode.ok
    ) {
      throw handleGraphQLError(
        RepositoryErrors[httpResponse.error! as keyof typeof RepositoryErrors]
      )
    } else {
      return httpResponse.body as GetAmbulatoryCounters.Model
    }
  }

  async getAmbulatoryOrders(
    params: GetAmbulatoryOrders.Params
  ): Promise<GetAmbulatoryOrders.Model> {
    const apiRepository = this
      .apiRepository as IApiRepository<GetAmbulatoryOrders.Model>

    const httpResponse: any = await apiRepository.get({
      url: '/orders/ambulatorial',
      params: params,
      paramsSerializer: (params: any) => parseParams(params)
    })

    if (
      httpResponse.statusCode &&
      httpResponse.statusCode !== ApiStatusCode.ok
    ) {
      throw handleGraphQLError(
        RepositoryErrors[httpResponse.error! as keyof typeof RepositoryErrors]
      )
    } else {
      return httpResponse.body as GetAmbulatoryOrders.Model
    }
  }

  async getOneAmbulatoryOrder(
    params: GetOneAmbulatoryOrder.Params
  ): Promise<GetOneAmbulatoryOrder.Model> {
    const apiRepository = this
      .apiRepository as IApiRepository<GetOneAmbulatoryOrder.Model>

    const httpResponse: any = await apiRepository.get({
      url: `/orders/ambulatorial/${params.id}`
    })

    if (
      httpResponse.statusCode &&
      httpResponse.statusCode !== ApiStatusCode.ok
    ) {
      throw handleGraphQLError(
        RepositoryErrors[httpResponse.error! as keyof typeof RepositoryErrors]
      )
    } else {
      return httpResponse.body as GetAmbulatoryOrders.Model
    }
  }

  async searchAmbulatoryOrders(
    params: SearchAmbulatoryOrders.Params
  ): Promise<SearchAmbulatoryOrders.Model> {
    const apiRepository = this
      .apiRepository as IApiRepository<SearchAmbulatoryOrders.Model>

    const httpResponse: any = await apiRepository.get({
      url: `/orders/ambulatorial/doctor/search?search=${params.search}&doctorId=${params.doctorId}`
    })

    if (
      httpResponse.statusCode &&
      httpResponse.statusCode !== ApiStatusCode.ok
    ) {
      throw handleGraphQLError(
        RepositoryErrors[httpResponse.error! as keyof typeof RepositoryErrors]
      )
    } else {
      return httpResponse.body as SearchAmbulatoryOrders.Model
    }
  }

  async loadAmbulatoryOrderDocument(
    params: LoadAmbulatoryOrderDocument.Params
  ): Promise<LoadAmbulatoryOrderDocument.Model> {
    const apiRepository = this
      .apiRepository as IApiRepository<LoadAmbulatoryOrderDocument.Model>

    const httpResponse: any = await apiRepository.get(
      {
        url: `/orders/documents?orderId=${params.orderId}&documentName=${params.documentName}`
      },
      true
    )

    const contentType = httpResponse.headers['content-type']
    const data = httpResponse.data

    return {
      contentType,
      data
    }
  }
}
