import React, { useEffect, useState } from 'react'
import {
  WithLoading,
  WithLoadingProps
} from 'presentation/shared/components/HOCs/WithLoading'
import { ContainerNew } from 'presentation/shared/components/ContainerNew'
import HeaderNew from 'presentation/shared/components/HeaderNew'
import HeadingNew from 'presentation/shared/components/HeadingNew'
import { ReactComponent as InfoIcon } from 'presentation/assets/icons/info-new.svg'
import { ReactComponent as PositiveIcon } from 'presentation/assets/icons/productivity-positive.svg'
import { ReactComponent as NegativeIcon } from 'presentation/assets/icons/productivity-negative.svg'
import { ReactComponent as EqualIcon } from 'presentation/assets/icons/productivity-equal.svg'
import { ReactComponent as ElipseRed } from 'presentation/assets/icons/elipse-red.svg'
import { ReactComponent as ElipseBlue } from 'presentation/assets/icons/elipse-blue.svg'
import { ReactComponent as ElipseGreen } from 'presentation/assets/icons/elipse-green.svg'
import { TooltipNew } from 'presentation/shared/components/TooltipNew'
import { ToolTipsTexts } from './toolTipsTexts'
import { ComparisonCard } from './Cards/ComparisonCard'
import { OtherIndicatorsCard } from './Cards/OtherIndicatorsCard'
import CalendarNew from 'presentation/shared/components/CalendarNew'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos'
import * as S from './styles'
import { useServices } from 'presentation/hooks/use-services'
import { MySurgeryTypes } from 'presentation/doctor/pages/MySurgery'
import { toast } from 'react-toastify'
import TextFieldNew from 'presentation/shared/components/TextFieldNew'
import moment from 'moment-timezone'
import { isValidDate } from 'presentation/utils/validators/is-valid-data'
import SupportTextNew from 'presentation/shared/components/SupportTextNew'
import { MyProductivityChart } from 'presentation/doctor/components/MyProductivityChart'

export type MyProductivityProps = {
  variant?:
    | 'positive'
    | 'negative'
    | 'equal'
    | 'indicatorPositive'
    | 'indicatorNegative'
    | 'indicatorEqual'
}

export const MyProductivityLayout = WithLoading(
  ({ setIsLoading }: WithLoadingProps) => {
    const doctorService = useServices().doctor
    const [surgeryInfo, setSurgeryInfo] = useState<MySurgeryTypes>()
    const [selectedDate, setSelectedDate] = useState<Date>(
      moment().subtract(1, 'month').startOf('month').toDate() // last month initial value
    )
    const selectedPreviousMonth = moment(selectedDate).format('MMMM')
    const selectedPreviousYear = moment(selectedDate)
      .subtract(1, 'year')
      .format('YYYY')
    const [showCalendar, setShowCalendar] = useState<boolean>(false)

    const handleGetSurgeryInfo = async (date: Date) => {
      try {
        setIsLoading(true)
        const formattedDate = moment(date).format('YYYY-MM-DD').toString()

        const doctor = await doctorService.loadDoctor(['doctor_id'])

        const response = await doctorService.getMyProductivityInfo({
          doctor_id: doctor.doctor_id,
          date: formattedDate
        })

        setSurgeryInfo(response)
      } catch (error: any) {
        setSurgeryInfo(undefined)
        toast.warning('Nenhuma informação encontrada.')
      } finally {
        setIsLoading(false)
      }
    }

    const compareNumberToVariant = (value: number) => {
      if (value < 0) {
        return 'negative'
      } else if (value === 0) {
        return 'equal'
      } else {
        return 'positive'
      }
    }

    const compareNumberToIcon = (value: number) => {
      if (value < 0) {
        return <NegativeIcon />
      } else if (value === 0) {
        return <EqualIcon />
      } else {
        return <PositiveIcon />
      }
    }

    const handleChangeDate = (date: Date | Date[]) => {
      if (Array.isArray(date)) {
        setSelectedDate(date[0])
      } else {
        setSelectedDate(date)
      }
      setShowCalendar(false)
    }

    const handleNavigationButtons = (navigation: 'prev' | 'next') => {
      let date = selectedDate

      navigation === 'prev'
        ? (date = moment(date).subtract(1, 'month').startOf('month').toDate())
        : (date = moment(date).add(1, 'month').startOf('month').toDate())

      setSelectedDate(date)
    }

    const compareResultsToVariant = (value: number, overallValue: number) => {
      if (value < overallValue) {
        return 'indicatorPositive'
      } else if (value === overallValue) {
        return 'indicatorEqual'
      } else {
        return 'indicatorNegative'
      }
    }

    useEffect(() => {
      handleGetSurgeryInfo(selectedDate)
    }, [selectedDate])

    return (
      <>
        <HeaderNew actualPageTitle="Minhas Cirurgias" />
        <ContainerNew noHeader>
          <S.CalendarWrapper>
            <S.DateSelector>
              <button
                className="btn"
                type="button"
                onClick={() => handleNavigationButtons('prev')}
                // Data mínima para desabilitar o botão (?)
                // disabled={selectedDate <= moment('01/01/2024').toDate()}
              >
                <ArrowBackIosIcon className="icon" />
              </button>
              <div className="date" onClick={() => setShowCalendar(true)}>
                {moment(selectedDate).format('MMMM YYYY')}
              </div>
              <button
                className="btn"
                type="button"
                onClick={() => handleNavigationButtons('next')}
                disabled={
                  selectedDate >=
                  moment().subtract(1, 'month').startOf('month').toDate()
                }
              >
                <ArrowForwardIosIcon className="icon" />
              </button>
            </S.DateSelector>
            {showCalendar && (
              <S.CalendarSelector>
                <CalendarNew
                  defaultView="year"
                  maxDetail="year"
                  defaultActiveStartDate={
                    isValidDate(
                      moment(selectedDate).format('DD/MM/YYYY').toString()
                    )
                      ? moment(selectedDate, 'DD/MM/YYYY').toDate()
                      : undefined
                  }
                  value={
                    isValidDate(
                      moment(selectedDate).format('DD/MM/YYYY').toString()
                    )
                      ? moment(selectedDate, 'DD/MM/YYYY').toDate()
                      : undefined
                  }
                  maxDate={moment()
                    .subtract(1, 'month')
                    .endOf('month')
                    .toDate()}
                  onDateChange={handleChangeDate}
                  formatMonth={(locale, date) => moment(date).format('MMM')}
                />
              </S.CalendarSelector>
            )}
          </S.CalendarWrapper>
          <TextFieldNew
            disabled
            readOnly
            label="Especialidade"
            name="specialty"
            defaultValue={surgeryInfo?.specialty}
          />
          <HeadingNew
            as="h1"
            className="s-title"
            color="neutral800"
            size="medium"
            weight="bold"
            style={{
              marginTop: '24px',
              display: 'flex',
              alignItems: 'center',
              gap: '4px'
            }}
          >
            Minhas cirurgias
            <TooltipNew className="icon" text={ToolTipsTexts.MY_PRODUCTIVITY}>
              <InfoIcon />
            </TooltipNew>
          </HeadingNew>
          <SupportTextNew style={{ margin: '4px 0 8px' }}>
            Acompanhe a sua posição em relação aos outros médicos da Rede.
          </SupportTextNew>
          <MyProductivityChart
            productivity={{
              lastPosition:
                surgeryInfo?.productivity
                  ?.minimum_productivity_medium_specialty,
              mediumPosition:
                surgeryInfo?.productivity?.media_productivity_medium_specialty,
              firstPosition:
                surgeryInfo?.productivity
                  ?.maximum_productivity_medium_specialty,
              userPosition:
                surgeryInfo?.productivity?.productivity_medium_doctor
            }}
            averageTicket={{
              lastPosition:
                surgeryInfo?.average_ticket?.minimum_ticket_medium_specialty,
              mediumPosition:
                surgeryInfo?.average_ticket?.media_ticket_medium_specialty,
              firstPosition:
                surgeryInfo?.average_ticket?.maximum_ticket_medium_specialty,
              userPosition: surgeryInfo?.average_ticket?.ticket_medium_doctor
            }}
            NPS={{
              lastPosition: surgeryInfo?.nps?.minimum_nps_medium_specialty,
              mediumPosition: surgeryInfo?.nps?.media_nps_medium_specialty,
              firstPosition: surgeryInfo?.nps?.maximum_nps_medium_specialty,
              userPosition: surgeryInfo?.nps?.nps_medium_doctor
            }}
          />
          <S.PerformanceGrid>
            <span>
              {moment(selectedDate).format('MMMM').charAt(0).toUpperCase() +
                moment(selectedDate).format('MMMM').slice(1)}{' '}
              em comparação ao mês anterior
              <TooltipNew
                className="icon"
                text={ToolTipsTexts.CURRENT_PERFORMANCE}
              >
                <InfoIcon />
              </TooltipNew>
            </span>

            <S.CardContainer>
              <ComparisonCard
                icon={compareNumberToIcon(
                  surgeryInfo?.productivity
                    ?.comparison_productivity_medium_previous_month ?? 0
                )}
                title="Volume cirúrgico"
                value={
                  surgeryInfo?.productivity
                    ?.comparison_productivity_medium_previous_month ?? 0
                }
                variant={compareNumberToVariant(
                  surgeryInfo?.productivity
                    ?.comparison_productivity_medium_previous_month ?? 0
                )}
              />
              <ComparisonCard
                icon={compareNumberToIcon(
                  surgeryInfo?.average_ticket
                    ?.comparison_ticket_medium_previous_month ?? 0
                )}
                title="Ticket médio"
                value={
                  surgeryInfo?.average_ticket
                    ?.comparison_ticket_medium_previous_month ?? 0
                }
                variant={compareNumberToVariant(
                  surgeryInfo?.average_ticket
                    ?.comparison_ticket_medium_previous_month ?? 0
                )}
              />
              <ComparisonCard
                icon={compareNumberToIcon(
                  surgeryInfo?.nps?.comparison_nps_medium_previous_month ?? 0
                )}
                title="NPS"
                value={
                  surgeryInfo?.nps?.comparison_nps_medium_previous_month ?? 0
                }
                variant={compareNumberToVariant(
                  surgeryInfo?.nps?.comparison_nps_medium_previous_month ?? 0
                )}
              />
            </S.CardContainer>
          </S.PerformanceGrid>
          <S.PerformanceGrid>
            {surgeryInfo !== undefined && (
              <span>
                {moment(selectedDate).format('MMMM').charAt(0).toUpperCase() +
                  moment(selectedDate).format('MMMM').slice(1)}
                /{moment(selectedDate).format('YYYY')} em comparação a{' '}
                {selectedPreviousMonth}/{selectedPreviousYear}
                <TooltipNew
                  className="icon"
                  text={ToolTipsTexts.LAST_YEAR_PERFORMANCE}
                >
                  <InfoIcon />
                </TooltipNew>
              </span>
            )}

            <S.CardContainer>
              {surgeryInfo?.productivity
                ?.comparison_productivity_medium_previous_year ? (
                <ComparisonCard
                  icon={compareNumberToIcon(
                    surgeryInfo?.productivity
                      ?.comparison_productivity_medium_previous_year ?? 0
                  )}
                  title="Volume cirúrgico"
                  value={
                    surgeryInfo?.productivity
                      ?.comparison_productivity_medium_previous_year ?? 0
                  }
                  variant={compareNumberToVariant(
                    surgeryInfo?.productivity
                      ?.comparison_productivity_medium_previous_year ?? 0
                  )}
                />
              ) : (
                <></>
              )}

              {surgeryInfo?.average_ticket
                ?.comparison_ticket_medium_previous_year ? (
                <ComparisonCard
                  icon={compareNumberToIcon(
                    surgeryInfo?.average_ticket
                      ?.comparison_ticket_medium_previous_year ?? 0
                  )}
                  title="Ticket médio"
                  value={
                    surgeryInfo?.average_ticket
                      ?.comparison_ticket_medium_previous_year ?? 0
                  }
                  variant={compareNumberToVariant(
                    surgeryInfo?.average_ticket
                      ?.comparison_ticket_medium_previous_year ?? 0
                  )}
                />
              ) : (
                <></>
              )}
              {surgeryInfo?.nps?.comparison_nps_medium_previous_year ? (
                <ComparisonCard
                  icon={compareNumberToIcon(
                    surgeryInfo?.nps?.comparison_nps_medium_previous_year ?? 0
                  )}
                  title="NPS"
                  value={
                    surgeryInfo?.nps?.comparison_nps_medium_previous_year ?? 0
                  }
                  variant={compareNumberToVariant(
                    surgeryInfo?.nps?.comparison_nps_medium_previous_year ?? 0
                  )}
                />
              ) : (
                <></>
              )}
            </S.CardContainer>
          </S.PerformanceGrid>
          <HeadingNew
            as="h1"
            className="s-title"
            color="neutral800"
            size="medium"
            weight="bold"
            style={{
              marginTop: '24px',
              marginBottom: '8px'
            }}
          >
            Outros indicadores
          </HeadingNew>
          <S.OtherIndicatorsContainer>
            <OtherIndicatorsCard
              title="Tempo médio internação"
              timeHospitalization={
                surgeryInfo?.others?.average_internment_time_doctor ?? 0
              }
              averageTimeHospitalization={
                surgeryInfo?.others?.average_internment_time_doctor ?? 0
              }
              generalAverageTimeHospitalization={
                surgeryInfo?.others?.average_internment_time_specialty ?? 0
              }
              variant={compareResultsToVariant(
                surgeryInfo?.others?.average_internment_time_doctor ?? 0,
                surgeryInfo?.others?.average_internment_time_specialty ?? 0
              )}
            />
            <OtherIndicatorsCard
              title="Atraso cirúrgico"
              delay={surgeryInfo?.others?.delay_percentage_doctor ?? 0}
              totalSurgeriesDelayed={
                surgeryInfo?.others?.quantity_done_doctor ?? 0
              }
              averageDelay={surgeryInfo?.others?.delay_quantity_doctor ?? 0}
              generalAverageDelay={
                surgeryInfo?.others?.delay_percentage_specialty ?? 0
              }
              variant={compareResultsToVariant(
                surgeryInfo?.others?.delay_percentage_doctor ?? 0,
                surgeryInfo?.others?.delay_percentage_specialty ?? 0
              )}
            />
          </S.OtherIndicatorsContainer>
          <S.CardCaptionContainer>
            <S.CardCaption>
              <ElipseGreen /> Melhor que a média
            </S.CardCaption>
            <S.CardCaption>
              <ElipseBlue /> Igual à média
            </S.CardCaption>
            <S.CardCaption>
              <ElipseRed /> Pior que a média
            </S.CardCaption>
          </S.CardCaptionContainer>
        </ContainerNew>
      </>
    )
  }
)
