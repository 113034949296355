import React, { useEffect, useState } from 'react'
import { PatientDocument } from 'common/enum/patient-document'
import { HealthInsurance } from 'domain/entities/health-insurance'
import {
  HealthInsurancePlans,
  LoadHealthInsurancePlans
} from 'domain/usecases/health-insurance/load-health-insurance-plans'
import { LoadSurgicalOrder } from 'domain/usecases/surgical-order/load-surgical-order'
import { LoadPatientInfo } from 'domain/usecases/patient/load-patient-info'
import { UpdatePatientHealthInsurance } from 'domain/usecases/patient/update-patient-health-insurance'
import { UpdatePatientInfo } from 'domain/usecases/patient/update-patient-info'
import { UploadPatientDocument } from 'domain/usecases/patient/upload-patient-document'
import { uniqBy } from 'lodash'
import { useServices } from 'presentation/hooks/use-services'
import { PatientInsuranceInfoForm } from 'presentation/shared/components/Forms/CarouselInsuranceInfo'
import UpdateInsuranceInfoLayout from 'presentation/shared/layouts/UpdateInsuranceInfo'
import {
  WithLoading,
  WithLoadingProps
} from 'presentation/shared/components/HOCs/WithLoading'
import { getThumbnail } from 'presentation/shared/components/UploadSection/Functions'
import { ListFile } from 'presentation/shared/components/UploadSection/UploadListFiles/UploadListFile'
import { useLocation } from 'react-router-dom'

type ChangeSurgicalOrderPersonalInfoProps = {
  loadHealthInsurance: LoadHealthInsurancePlans
  loadSurgicalOrder: LoadSurgicalOrder
  loadPatientInfo: LoadPatientInfo
  uploadInsuranceCard: UploadPatientDocument
  updateInsuranceInfo: UpdatePatientHealthInsurance
  updatePatientPersonalInfo: UpdatePatientInfo
} & WithLoadingProps

type LocationType = {
  state: {
    patient_id?: number
    surgical_order_id?: number
    hospital_id?: number
  }
}

function ChangeSurgicalOrderInsuranceInfo({
  loadHealthInsurance,
  loadSurgicalOrder,
  uploadInsuranceCard,
  loadPatientInfo,
  updateInsuranceInfo,
  updatePatientPersonalInfo,
  setIsLoading
}: ChangeSurgicalOrderPersonalInfoProps) {
  const [patientInfo, setPatientInfo] = useState({} as PatientInsuranceInfoForm)
  const [healthInsurances, setHealthInsurances] = useState(
    [] as HealthInsurancePlans[]
  )
  // const currentHospital = useStores().currentHospital
  // const useStoreHospitalId = currentHospital.getHospitalSelected()
  const patientService = useServices().patient
  const { state } = useLocation() as LocationType

  const hospital_id = state?.hospital_id ? state?.hospital_id : 1

  useEffect(() => {
    const loadData = async () => {
      setIsLoading(true)
      const insurances = await loadHealthInsurance.load({
        hospital_id: hospital_id,
        params: [
          'code',
          'description',
          'plans {code, description, subPlan{code, description}}',
          'ansRegister'
        ]
      })

      setHealthInsurances(insurances)
      let patientInsurance
      if (state?.surgical_order_id) {
        patientInsurance = await loadSurgicalOrder.load(
          state?.surgical_order_id,
          [
            `healthInsurance {
            healthInsuranceCode,
            healthInsuranceName,
            healthPlanCode,
            healthPlanName,
            company
            healthPlanName
            healthCard
          }`
          ]
        )
        patientInsurance = { ...patientInsurance, documentHealthInsurance: [] }
      } else {
        patientInsurance = await loadPatientInfo.load(
          [
            `healthInsurance {
            healthInsuranceCode,
            healthInsuranceName,
            healthPlanCode,
            healthPlanName,
            company
            healthPlanName
            healthCard
          }`,
            `documentHealthInsurance {
            document_id,
            name,
            documentUri,
            type,
            sendedAt
          }`
          ],
          state?.patient_id
        )
      }

      const fileDocument: ListFile<PatientDocument>[] = await Promise.all(
        (patientInsurance.documentHealthInsurance || []).map(
          async ({ document_id, name, type }, page) => {
            const fileDocument = await patientService.downloadPatientDocument(
              document_id
            )
            const file = new File([fileDocument.data], name ?? '', {
              type: fileDocument.contentType
            })
            const thumbnail = await getThumbnail(file)
            return {
              file,
              identifier: type as PatientDocument,
              page: page + 1,
              thumbnail
            }
          }
        )
      )

      setPatientInfo({
        healthCard: patientInsurance.healthInsurance?.healthCard ?? '',
        healthCardFiles: fileDocument,
        company: patientInsurance.healthInsurance?.company,
        healthInsurance: new HealthInsurance(
          patientInsurance.healthInsurance?.healthInsuranceCode ?? 0,
          patientInsurance.healthInsurance?.healthInsuranceName ?? '',
          patientInsurance.healthInsurance?.healthPlanCode ?? 0,
          patientInsurance.healthInsurance?.healthPlanName ?? ''
        ),
        documentHealthInsurance: patientInsurance.documentHealthInsurance?.map(
          (document) => ({
            document_id: document?.document_id ?? 0,
            type: document?.type ?? '',
            name: document?.name ?? '',
            documentUri: document?.documentUri ?? '',
            sendedAt: document?.sendedAt ?? ''
          })
        ),
        patient_id: state?.patient_id
      })
      setIsLoading(false)
    }
    loadData()
  }, [])

  return (
    <>
      <UpdateInsuranceInfoLayout
        uploadInsuranceCard={uploadInsuranceCard}
        healthInsurances={uniqBy(healthInsurances, (val) => val.description)}
        updateInsuranceInfo={updateInsuranceInfo}
        patientInfo={patientInfo}
        updatePatientPersonalInfo={updatePatientPersonalInfo}
        setIsLoading={setIsLoading}
      />
    </>
  )
}

export default WithLoading(ChangeSurgicalOrderInsuranceInfo)
