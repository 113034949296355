import React, { useContext } from 'react'
import SurgicalSchedulingCommentsLayout from 'presentation/doctor/layouts/SurgicalSchedulingComments'
import { SurgicalSchedulinhContext } from 'main/factories/pages/doctor/surgical-scheduling/surgical-scheduling-factory'
import HeaderNew from 'presentation/shared/components/HeaderNew'

function SurgicalSchedulingComments() {
  const { dispatch, state } = useContext<any>(SurgicalSchedulinhContext)

  return (
    <>
      <HeaderNew actualPageTitle="Agendar cirurgia" />
      <SurgicalSchedulingCommentsLayout
        dispatch={dispatch}
        general_observation={state.general_observation}
      />
    </>
  )
}

export default SurgicalSchedulingComments
