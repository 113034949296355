import styled from 'styled-components'
import { ContainerTypes } from './index'

export const Wrapper = styled.div<{
  noPadding?: boolean
  spacingTop?: boolean
}>`
  position: relative;
  top: ${({ spacingTop }) => (spacingTop ? '72px' : 0)};
  background-color: ${({ theme }) => theme.ds.colors.white};
  padding-top: ${({ noPadding }) => (noPadding ? '0' : '24px')};
  padding-bottom: ${({ noPadding }) => (noPadding ? '0' : '24px')};
  flex: 1 1 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow-y: ${({ spacingTop }) => (spacingTop ? 'unset' : 'auto')};
  font-size: 16px;
  justify-content: flex-start;
`

export const Header = styled.header`
  display: flex;
  flex-direction: column;

  h1 {
    margin-bottom: 8px;
  }
  div {
    margin-bottom: 24px;
  }
`

export const ContainerStyle = styled.main<ContainerTypes>`
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow-y: auto;
  font-size: 16px;

  .back-button {
    padding: 0 14px;
  }
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-y: auto;
  overflow-x: hidden;
`

export const Buttons = styled.div<
  Pick<ContainerTypes, 'noPadding' | 'buttonsHasBoxShadow'>
>`
  text-align: center;
  padding-top: ${({ noPadding }) => (noPadding ? '0' : '8px')};
  padding-right: ${({ noPadding }) => (noPadding ? '0' : '24px')};
  padding-left: ${({ noPadding }) => (noPadding ? '0' : '24px')};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-top: 24px;
  box-shadow: ${({ buttonsHasBoxShadow }) =>
    buttonsHasBoxShadow
      ? '0px -2px 3px 1px rgba(112, 118, 131, 0.12)'
      : 'unset'};
  z-index: 1;

  p {
    cursor: pointer;
    text-align: center;
    padding: 10px 0;
  }

  @media (max-width: 1024px) {
    align-items: center;
    flex-direction: column;

    button + button {
      margin-left: 0;
      margin-top: 16px;
    }
  }
`
