import { ChangeDoctorSecretary } from 'domain/usecases/doctor-secretary/change-doctor-secretary'
import { IDoctorSecretaryRepository } from 'repository/interfaces/doctor-secretary-repository'

export class RemoteChangeDoctorSecretary implements ChangeDoctorSecretary {
  constructor(
    private readonly doctorSecretaryRepository: IDoctorSecretaryRepository
  ) {}

  update(
    params: ChangeDoctorSecretary.Params
  ): Promise<ChangeDoctorSecretary.Model> {
    return this.doctorSecretaryRepository.changeDoctorSecretary(params)
  }
}

export type ChangePasswordModel = ChangeDoctorSecretary.Model
