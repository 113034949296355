import styled from 'styled-components'
import SupportTextNew from 'presentation/shared/components/SupportTextNew'
import CardNew from 'presentation/shared/components/CardNew'

export const BooleanRadioWrapper = styled.div<{ isColumn?: boolean }>`
  display: flex;
  flex-direction: ${({ isColumn }) => (isColumn ? 'column' : 'row')};
  gap: ${({ isColumn }) => (isColumn ? '12px' : '32px')};
`

export const Label = styled(SupportTextNew)`
  margin-bottom: 12px;
`

export const EquipamentCard = styled(CardNew)`
  .card-header {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }
  .card-footer {
    margin: 12px -8px -12px;
    padding: 12px 16px;
    border-radius: 0 0 8px 8px;
    background-color: ${({ theme }) => theme.ds.colors.white};
    display: flex;
    justify-content: flex-end;
    svg {
      cursor: pointer;
      path {
        fill: ${({ theme }) => theme.ds.colors.neutral500};
        transition: 0.2s ease;
      }
      &:hover path {
        fill: ${({ theme }) => theme.ds.colors.danger200};
      }
    }
  }
`

export const EmptyState = styled.div`
  padding: 8px 16px;
  .include-button {
    margin-top: 8px;
  }
`
