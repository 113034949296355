import React from 'react'
import SupportTextNew from 'presentation/shared/components/SupportTextNew'
import AccordionCardNew from 'presentation/shared/components/AccordionCardNew'
import { Booleans } from 'common/enum/booleans'

type Props = {
  expanded?: boolean
  handleChange?: (accordionLabel: string, isExpanded: boolean) => void
  icon?: React.ReactElement
  bloodTransfusion?: boolean
  oncologyOrResearch?: boolean
  allergy: string[]
  clinicalHistory?: string
  psychologicalAspects?: string
}

export default function ClinicalHistory({
  expanded,
  handleChange,
  allergy,
  bloodTransfusion,
  oncologyOrResearch,
  clinicalHistory,
  psychologicalAspects,
  icon
}: Props) {
  const booleansLabel = (value = false) => {
    return Booleans[String(value) as keyof typeof Booleans]
  }

  const translateAllergies = () => {
    if (allergy?.includes('latex-not-informed')) return 'Não informado'
    else if (allergy?.includes('Látex')) return 'Sim'
    else return 'Não'
  }

  const filteredAllergies = () => {
    const flteredItems =
      allergy?.filter((item) => {
        return !['Látex', 'false', 'latex-not-informed'].includes(item)
      }) || []

    return flteredItems?.length > 1 ? flteredItems[1] : 'Não'
  }

  return (
    <AccordionCardNew
      accordionLabel="ClinicalHistory"
      expanded={expanded}
      handleChange={handleChange}
      header="História clínica"
      shadow="satin"
      padding="16px"
      gap="16px"
      background="neutral25"
      headerIcon={icon}
    >
      <div>
        <SupportTextNew size="xsmall">Observação geral</SupportTextNew>
        <SupportTextNew color="primary600" weight="semiBold" size="xsmall">
          {clinicalHistory}
        </SupportTextNew>
        <SupportTextNew size="xsmall" style={{ marginTop: '8px' }}>
          Paciente possui alergia a látex?
        </SupportTextNew>
        <SupportTextNew color="primary600" weight="bold" size="xsmall">
          {translateAllergies()}
        </SupportTextNew>
      </div>
      <div>
        <SupportTextNew size="xsmall">
          Paciente possui alguma outra alergia?
        </SupportTextNew>
        <SupportTextNew color="primary600" weight="bold" size="xsmall">
          {filteredAllergies()}
        </SupportTextNew>
      </div>
      <div>
        <SupportTextNew size="xsmall">
          Paciente tem restrição a transfusão sanguínea?
        </SupportTextNew>
        <SupportTextNew
          color="primary600"
          weight="bold"
          size="xsmall"
          style={{ marginBottom: '8px' }}
        >
          {booleansLabel(bloodTransfusion)}
        </SupportTextNew>
        <SupportTextNew size="xsmall">
          Paciente oncológico ou investigação?
        </SupportTextNew>
        <SupportTextNew
          color="primary600"
          weight="bold"
          size="xsmall"
          style={{ marginBottom: '8px' }}
        >
          {booleansLabel(oncologyOrResearch)}
        </SupportTextNew>
        <SupportTextNew size="xsmall">
          Aspectos psicológicos, socioeconômicos, espirituais/culturais
          relevantes a serem considerados?
        </SupportTextNew>
        <SupportTextNew
          color="primary600"
          weight="bold"
          size="xsmall"
          style={{ marginTop: '8px' }}
        >
          {psychologicalAspects}
        </SupportTextNew>
      </div>
    </AccordionCardNew>
  )
}
