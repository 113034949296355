import { GetAvailableDays } from 'domain/usecases/surgical-scheduling/get-available-days'
import { SurgicalSchedulingRepository } from 'repository/interfaces/surgical-scheduling-repository'

export class RemoteGetAvailableDays implements GetAvailableDays {
  constructor(
    private readonly surgicalSchedulingRepository: SurgicalSchedulingRepository
  ) {}

  getAvailableDays(
    params: GetAvailableDays.Params
  ): Promise<GetAvailableDays.Model> {
    return this.surgicalSchedulingRepository.getAvailableDays(params)
  }
}
