import { GetHospitals } from 'domain/usecases/surgical-scheduling/get-hospitals'
import { SurgicalSchedulingRepository } from 'repository/interfaces/surgical-scheduling-repository'

export class RemoteGetHospitals implements GetHospitals {
  constructor(
    private readonly surgicalSchedulinhRepository: SurgicalSchedulingRepository
  ) {}

  getHospitals(): Promise<GetHospitals.Model> {
    return this.surgicalSchedulinhRepository.getHospitals()
  }
}
