import { dataURLtoBlob } from 'common/utils/dataUrlToBlob'
import React, { useEffect } from 'react'

import * as S from './styles'

type Props = {
  onSubmit?: (image: Blob) => void
}

const width = window.innerWidth > 700 ? 700 : window.innerWidth * 0.85
const height = window.innerHeight - 150 > 178 ? 178 : window.innerHeight - 150

const SignatureInput = ({ onSubmit }: Props) => {
  const canvasRef = React.useRef<HTMLCanvasElement>(null)

  useEffect(() => {
    window.requestAnimationFrame = (function () {
      return (
        window.requestAnimationFrame ||
        function (callback) {
          window.setTimeout(callback, 1000 / 60)
        }
      )
    })()

    const canvas = document.getElementById('sig-canvas') as HTMLCanvasElement
    const ctx = canvas?.getContext('2d')
    canvas.width = width
    canvas.height = height
    ctx?.scale(1, 1)
    if (ctx) {
      ctx.strokeStyle = '#222222'
      ctx.lineWidth = 3
    }

    let drawing = false
    let mousePos = {
      x: 0,
      y: 0
    }
    let lastPos = mousePos

    canvas?.addEventListener(
      'mousedown',
      function (e) {
        drawing = true
        lastPos = getMousePos(canvas, e)
      },
      false
    )

    canvas?.addEventListener(
      'mouseup',
      function () {
        drawing = false
      },
      false
    )

    canvas?.addEventListener(
      'mousemove',
      function (e) {
        mousePos = getMousePos(canvas, e)
      },
      false
    )

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    canvas?.addEventListener('touchstart', function () {}, false)

    canvas?.addEventListener(
      'touchmove',
      function (e) {
        const touch = e.touches[0]
        const me = new MouseEvent('mousemove', {
          clientX: touch.clientX,
          clientY: touch.clientY
        })
        canvas.dispatchEvent(me)
      },
      false
    )

    canvas?.addEventListener(
      'touchstart',
      function (e) {
        mousePos = getTouchPos(canvas, e)
        const touch = e.touches[0]
        const me = new MouseEvent('mousedown', {
          clientX: touch.clientX,
          clientY: touch.clientY
        })
        canvas.dispatchEvent(me)
      },
      false
    )

    canvas?.addEventListener(
      'touchend',
      function () {
        const me = new MouseEvent('mouseup', {})
        canvas.dispatchEvent(me)
      },
      false
    )

    function getMousePos(canvasDom: HTMLCanvasElement, mouseEvent: MouseEvent) {
      const rect = canvasDom.getBoundingClientRect()
      const x = mouseEvent.clientX - rect.left
      const y = mouseEvent.clientY - rect.top

      return {
        x,
        y
      }
    }

    function getTouchPos(canvasDom: HTMLCanvasElement, touchEvent: TouchEvent) {
      const rect = canvasDom.getBoundingClientRect()
      return {
        x: touchEvent.touches[0].clientX - rect.left,
        y: touchEvent.touches[0].clientY - rect.top
      }
    }

    function renderCanvas() {
      if (drawing) {
        ctx?.moveTo(lastPos.x, lastPos.y)
        ctx?.lineTo(mousePos.x, mousePos.y)
        ctx?.stroke()
        lastPos = mousePos
      }
    }

    // Prevent scrolling when touching the canvas
    canvas?.addEventListener('touchstart', function (event) {
      event.preventDefault()
    })
    canvas.addEventListener('touchmove', function (event) {
      event.preventDefault()
    })
    canvas.addEventListener('touchend', function (event) {
      event.preventDefault()
    })
    canvas.addEventListener('touchcancel', function (event) {
      event.preventDefault()
    })
    ;(function drawLoop() {
      requestAnimationFrame(drawLoop)
      renderCanvas()
    })()
  }, [])

  function clearCanvas() {
    // eslint-disable-next-line no-self-assign
    if (canvasRef.current) {
      canvasRef.current.width = width
    }
  }

  function submit() {
    if (canvasRef.current) {
      const image = canvasRef.current.toDataURL()
      const file = dataURLtoBlob(image)
      onSubmit && onSubmit(file)
    }
  }

  return (
    <S.Wrapper>
      <canvas
        id="sig-canvas"
        width={width.toString()}
        height={height.toString()}
        ref={canvasRef}
      >
        Seu navegador não suporta essa funcionalidade.
      </canvas>
      <S.Footer lineWidth={width}>
        <div onClick={clearCanvas}>
          <svg
            width="13"
            height="17"
            viewBox="0 0 13 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M6.68182 3.86364L6.68182 6.18182L9.77273 3.09091L6.68182 1.08086e-06L6.68182 2.31818C3.26636 2.31818 0.499999 5.08455 0.499999 8.5C0.499998 9.71318 0.855453 10.8414 1.45818 11.7918L2.58636 10.6636C2.23863 10.0223 2.04545 9.28045 2.04545 8.5C2.04545 5.94227 4.12409 3.86364 6.68182 3.86364ZM11.9055 5.20818L10.7773 6.33637C11.1173 6.98546 11.3182 7.71955 11.3182 8.5C11.3182 11.0577 9.23954 13.1364 6.68182 13.1364L6.68182 10.8182L3.59091 13.9091L6.68182 17L6.68182 14.6818C10.0973 14.6818 12.8636 11.9155 12.8636 8.5C12.8636 7.28682 12.5082 6.15864 11.9055 5.20818Z"
              fill="white"
            />
          </svg>
          <span>Refazer</span>
        </div>
        <span
          style={{
            position: 'absolute',
            left: '50%',
            top: '-2px',
            transform: 'translateX(-50%)'
          }}
        >
          Assine aqui
        </span>
        <div onClick={submit}>
          <svg
            width="12"
            height="12"
            viewBox="0 0 12 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M11.9694 6.20637C11.9259 6.33518 11.6921 6.55599 11.5535 6.59542C11.4665 6.61908 10.5856 6.62696 7.82892 6.62696L4.21584 6.62696L4.94172 7.33146C5.71925 8.08327 5.7546 8.13058 5.7546 8.37768C5.7546 8.82456 5.27068 9.12949 4.84929 8.94548C4.76229 8.90605 4.37624 8.54855 3.39753 7.59695C1.97568 6.21425 2.00015 6.24054 2.00015 5.99081C2.00015 5.74897 2.00287 5.74371 3.40841 4.38204C4.29469 3.52246 4.76773 3.08346 4.84113 3.04929C4.98522 2.98357 5.24077 2.98357 5.38486 3.04929C5.61051 3.15181 5.7546 3.37262 5.7546 3.61709C5.7546 3.86156 5.71926 3.91151 4.94172 4.66069L4.21584 5.36518L7.82892 5.36518C10.5421 5.36518 11.4692 5.37307 11.548 5.39673C11.7084 5.44142 11.9096 5.62805 11.9613 5.77789C12.0102 5.91721 12.0129 6.07231 11.9694 6.20637Z"
              fill="white"
            />
            <path
              d="M5.96995 11.5893C5.93002 11.7239 5.7304 11.9335 5.60123 11.9723C5.51904 11.9982 4.94601 12.0034 3.10011 11.9982L0.704664 11.9904L0.547317 11.9076C0.328909 11.7938 0.192697 11.6489 0.0870158 11.4134L0.000122242 11.2167L0.000123382 6.00001L0.000124522 0.783305L0.0870181 0.586643C0.1927 0.351167 0.328911 0.206258 0.547319 0.0924016L0.704667 0.00959602L3.10011 0.00183364C4.892 -0.00334061 5.52139 0.00183417 5.59654 0.0251239C5.7398 0.069114 5.91593 0.250249 5.9629 0.402921C6.0404 0.653923 5.99813 0.855761 5.82434 1.04466C5.75154 1.12746 5.67169 1.18698 5.61063 1.20768C5.53783 1.23097 4.97185 1.23873 3.31382 1.23873L1.11565 1.23873L1.11565 6.00001L1.11565 10.7613L3.31382 10.7613C4.94601 10.7613 5.53783 10.7691 5.60593 10.7924C5.74449 10.8363 5.91828 11.0201 5.9629 11.1676C6.00517 11.3047 6.00752 11.4574 5.96995 11.5893Z"
              fill="white"
            />
          </svg>
          <span>Salvar</span>
        </div>
      </S.Footer>
    </S.Wrapper>
  )
}

export default SignatureInput
