import styled from 'styled-components'
import SupportTextNew from 'presentation/shared/components/SupportTextNew'

export const BooleanRadioWrapper = styled.div<{ isColumn?: boolean }>`
  display: flex;
  flex-direction: ${({ isColumn }) => (isColumn ? 'column' : 'row')};
  gap: ${({ isColumn }) => (isColumn ? '12px' : '32px')};
`

export const Label = styled(SupportTextNew)`
  margin-bottom: 12px;
`

export const LoadingContainer = styled.div`
  display: flex;
  img {
    width: 32px;
    margin: -4px 8px -4px 0;
  }
`
