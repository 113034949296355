import React from 'react'
import * as S from './styles'
import theme from 'presentation/styles/theme'

export type AvatarTextProps = {
  color: keyof typeof theme.ds.colors
  active?: boolean
  size?: 'mini' | 'small' | 'medium' | 'large' | 'xlarge' | 'xxlarge'
  onClick?: () => void
  text?: string | number
  icon?: React.ReactNode
  disableHoverOnActive?: boolean
  style?: React.CSSProperties
}

export default function AvatarNew({
  text = '',
  color,
  icon,
  active = false,
  size = 'medium',
  disableHoverOnActive = false,
  onClick,
  style
}: AvatarTextProps) {
  return (
    <S.AvatarText
      style={style}
      color={color}
      active={active}
      size={size}
      onClick={onClick}
      disableHoverOnActive={disableHoverOnActive}
    >
      {icon ? icon : text}
    </S.AvatarText>
  )
}
