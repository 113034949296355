import { IUserRepositoryRepository } from 'repository/interfaces/user-repository'
import { SetSurgicalSchedulingBannerVisualized } from 'domain/usecases/user/set-surgical-scheduling-banner-visualized'

export class RemoteSetSurgicalSchedulingBannerVisualized
  implements SetSurgicalSchedulingBannerVisualized
{
  constructor(private readonly userRepository: IUserRepositoryRepository) {}

  visualize(): Promise<void> {
    return this.userRepository.setSurgicalSchedulingBannerVisualized()
  }
}
