import React, { useEffect, useState } from 'react'

import * as S from './styles'

export type TabItem = {
  label: string
  componentChildren: React.ReactElement
  disabled?: boolean
}

type Props = {
  tabItems: TabItem[]
  returnTab?: number
  defaultTab?: number
  styleOptions?: {
    primary?: boolean
  }
  onChangeTab?(activeIndex: number): void
  variant?: 'fullWidth' | 'scrollable' | 'standard' | undefined
}

const TabsNew = ({
  tabItems,
  styleOptions,
  onChangeTab,
  defaultTab,
  returnTab,
  variant = 'fullWidth'
}: Props) => {
  const [activeIndex, setActiveIndex] = useState(defaultTab || 0)
  const [activePanel, setActivePanel] = useState((<></>) as React.ReactElement)

  const handleChange = (newIndex: number) => {
    const findedTab = tabItems.find((e, idx) => idx === newIndex)
    if (findedTab && findedTab.disabled) {
      return
    }
    setActiveIndex(newIndex)
    if (onChangeTab) {
      onChangeTab(newIndex)
    }
  }

  useEffect(() => {
    if (returnTab) {
      const tabFinded = tabItems.find((tab, idx) => tab && idx === returnTab)
      if (tabFinded) {
        setActiveIndex(returnTab)
        setActivePanel(tabFinded.componentChildren)
      }
    } else {
      const tabFinded = tabItems.find((tab, idx) => tab && idx === activeIndex)
      if (tabFinded) {
        setActivePanel(tabFinded.componentChildren)
      }
    }
  }, [activeIndex, tabItems])

  return (
    <S.Wrapper>
      {' '}
      <S.StyledTabs
        value={activeIndex}
        onChange={(e, v) => handleChange(v)}
        centered
        variant={variant}
        primary={styleOptions?.primary ? 'true' : undefined}
        data-testid="tabs-container"
        scrollButtons="off"
      >
        {' '}
        {tabItems.map((tab, idx) => (
          <S.StyledTab
            key={idx}
            label={tab.label}
            style={{
              opacity: tab.disabled ? 0.4 : 1
            }}
            primary={styleOptions?.primary ? 'true' : undefined}
            data-testid="tab-container"
          />
        ))}{' '}
      </S.StyledTabs>
      {activePanel}
    </S.Wrapper>
  )
}

export default TabsNew
