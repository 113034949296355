import { Hospital } from 'domain/entities/hospital-model'
import { Schedule } from 'domain/entities/schedule-model'
import moment from 'moment-timezone'
import React from 'react'
import * as S from '../styles'

type AmbulatoryScheduleDetailsProps = {
  data: Schedule
}

export const AmbulatoryScheduleDetails = ({
  data
}: AmbulatoryScheduleDetailsProps) => {
  return (
    <S.ContainerData>
      <S.Item>
        <span>Paciente</span>
        <p>{data?.patientName}</p>
      </S.Item>

      <S.Item>
        <span>Convênio</span>
        <p>{data?.healthInsuranceName}</p>
      </S.Item>

      <S.Divider />

      <S.Item>
        <span>Especialidade</span>
        <p>{data?.scheduleItemDescription}</p>
      </S.Item>

      <S.Item>
        <span>Unidade</span>
        <p>
          {new Hospital(0, data.hospitalName ?? '', data.hospitalName ?? '')
            .name || ''}
        </p>
      </S.Item>

      <S.Item>
        <span>Setor</span>
        <p>{data?.sector}</p>
      </S.Item>

      <S.Item>
        <span>Médico Responsável</span>
        <p>{data?.doctorName}</p>
      </S.Item>

      <S.Item>
        <span>Data e horário da consulta</span>
        <p>
          {data?.scheduleTime &&
            moment
              .utc(data.scheduleTime)
              .format('DD [de] MMM[.] [de] YYYY [às] HH[h]mm')}
        </p>
      </S.Item>
    </S.ContainerData>
  )
}
