import React from 'react'
import { fields } from './fields'
import * as S from './styles'
import { useFormik } from 'formik'
import Button from 'presentation/shared/components/Button'
import PasswordField from 'presentation/shared/components/PasswordField'
import * as yup from 'yup'
import { toast } from 'react-toastify'
import { useServices } from 'presentation/hooks/use-services'
import { useStores } from 'presentation/hooks/use-stores'
import { useHistory } from 'react-router-dom'
import LoadingModal from 'presentation/shared/components/LoadingModal'

type Props = {
  setUpdatePasswordSuccess: React.Dispatch<React.SetStateAction<boolean>>
}

export default function UpdatePasswordForm({
  setUpdatePasswordSuccess
}: Props) {
  const history = useHistory()
  const secretary =
    useStores().currentAccount?.getCurrentAccount()?.user.secretary_doctor
  const [blockEdit, setBlockEdit] = React.useState<boolean>(true)

  const userServices = useServices().user
  const formik = useFormik({
    initialValues: {
      actualPassword: '',
      newPassword: '',
      newPasswordConfirmation: '',
      role: secretary?.role ? secretary?.role : undefined,
      user_id: secretary?.user_id ? secretary?.user_id : undefined
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      try {
        await userServices.updatePassword(values)
        setUpdatePasswordSuccess(true)
        setTimeout(() => {
          history.push('/perfil')
        }, 2000)
      } catch (error: any) {
        toast.error('Erro ao atualizar senha')
      }
    }
  })

  const changeEnableForm = () => {
    if (blockEdit) {
      setBlockEdit(!blockEdit)
    }
    if (!blockEdit) {
      formik.handleSubmit()
    }
  }

  return (
    <>
      <S.Title>Alterar Senha</S.Title>
      <form onSubmit={formik.handleSubmit}>
        <S.Wrapper>
          {fields.map(({ name, placeholder, label }) => (
            <PasswordField
              key={name}
              label={label}
              disabled={blockEdit}
              // @ts-ignore: Unreachable code error
              error={formik.errors[name]}
              // @ts-ignore: Unreachable code error
              initialValue={formik.values[name]}
              placeholder={placeholder}
              name={name}
              onInputChange={formik.handleChange(name)}
              onBlur={formik.handleBlur(name)}
            />
          ))}
        </S.Wrapper>

        <S.Actions style={{}}>
          <Button
            fullWidth
            type="button"
            variant={blockEdit ? 'outlined' : undefined}
            onClick={() => changeEnableForm()}
          >
            {blockEdit ? 'Editar Informações' : 'Salvar alterações'}
          </Button>
        </S.Actions>
      </form>
      <LoadingModal show={formik.isSubmitting} />
    </>
  )
}

const validationSchema = yup.object().shape({
  actualPassword: yup.string().required(),
  newPassword: yup.string().required(),
  newPasswordConfirmation: yup
    .string()
    .oneOf([yup.ref('newPassword'), null], 'Confirmação diferente da senha')
    .required()
})
