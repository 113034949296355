import React from 'react'

import SelectLocationForm from 'presentation/doctor/components/Forms/AddSurgicalOrderNew/SelectLocation'
import HeaderNew from 'presentation/shared/components/HeaderNew'

const SelectLocationLayout = () => {
  return (
    <>
      <HeaderNew actualPageTitle="Novo pedido cirúrgico - Local" />
      <SelectLocationForm />
    </>
  )
}

export default SelectLocationLayout
