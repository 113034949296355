import React, { useState } from 'react'
import Modal from 'presentation/shared/components/Modal'
import UpdatePasswordLayout from 'presentation/doctor/layouts/UpdatePassword'

export default function UpdateDoctorPassword() {
  const [updatePasswordSuccess, setUpdatePasswordSuccess] =
    useState<boolean>(false)

  return (
    <>
      <UpdatePasswordLayout
        setUpdatePasswordSuccess={setUpdatePasswordSuccess}
      />

      <Modal
        title="Senha Atualizada"
        show={updatePasswordSuccess}
        close={() => setUpdatePasswordSuccess(false)}
      />
    </>
  )
}
