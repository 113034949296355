import { GetRecentAppointments } from 'domain/usecases/scheduling/get-recent-appointments'
import { FinishSchedule } from 'domain/usecases/scheduling/finish-schedule'
import { GetAppointmentsByMonth } from 'domain/usecases/scheduling/get-appointments-by-month'
import { CancelSchedule } from 'domain/usecases/scheduling/cancel-schedule'

export const getRecentAppointmentsQuery = (
  params: GetRecentAppointments.Params
) => ({
  query: `
    mutation getRecentAppointments(
      $patient: PatientData,
      $healthInsurance: HealthInsuranceData!,
      $specialty: SpecialtyData,
      $doctor: DoctorData,
      $units: [UnitData!]!
      ) {
      getRecentAppointments(
        data: {
          patient: $patient,
          healthInsurance: $healthInsurance,
          specialty: $specialty,
          doctor: $doctor,
          units: $units
      }) {
        ${params?.fields.join(',')}
      }
    }
  `
})

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const finishScheduleMutation = (params: FinishSchedule.Params) => ({
  query: `
  mutation finishSchedule(
    $schedule_id: Int!,
    $hour_initial_id: Int!,
    $hour_finish_id: Int!,
    $healthInsurance: HealthInsuranceData!,
    $specialty: SpecialtyData,
    $patient: PatientData!,
    $cd_it_agenda_central: Int!
    ) {
	  finishSchedule(
      data: {
        schedule_id: $schedule_id,
        hour_initial_id: $hour_initial_id,
        hour_finish_id: $hour_finish_id,
        healthInsurance: $healthInsurance,
        specialty: $specialty,
        patient: $patient,
        cd_it_agenda_central: $cd_it_agenda_central })
  }
`
})

export const getAppointmentsByMonthQuery = (
  params: GetAppointmentsByMonth.Params
) => ({
  query: `
    mutation GetAppointmentsByMonth(
      $healthInsurance: HealthInsuranceData!,
      $specialty: SpecialtyData,
      $doctor: DoctorData,
      $patient: PatientData,
      $units: [UnitData!]!,
      $schedule_id: Int!,
      $month: String!
    ) {
      getAppointmentsByMonth(
        data: {
          healthInsurance: $healthInsurance,
          specialty: $specialty,
          doctor: $doctor,
          patient: $patient,
          units: $units,
          schedule_id: $schedule_id,
          month: $month
        }
      ) {
        ${params?.fields.join(',')}
      }
    }
  `,
  name: 'getAppointmentsByMonth'
})

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const cancelScheduleMutation = (params: CancelSchedule.Params) => ({
  query: `
  mutation CancelSchedule(
    $cd_it_agenda_central: Int!,
    $cd_mot_cancel: Int!,
    $text: String!,
  ) {
    cancelSchedule(data: {
      cd_it_agenda_central: $cd_it_agenda_central,
      cd_mot_cancel: $cd_mot_cancel,
      text: $text
    })
  }
  `,
  name: 'cancelScheduleMutation'
})
