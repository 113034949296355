import React, { useState, useCallback } from 'react'
import { toast } from 'react-toastify'
import TextField, {
  TextFieldProps
} from 'presentation/shared/components/TextField'
import { debounce } from 'lodash'
import LoadingGif from 'presentation/assets/icons/loading.gif'

import * as S from './styles'
import { ReactComponent as SearchIcon } from 'presentation/assets/icons/search.svg'
import Button from 'presentation/shared/components/Button'

export type AutoCompleteProps = {
  suggestions?: { label: string; value: any }[]
  onSuggestionClick?: (value: any) => void
  onType?: (value: any) => void
  onInputChange: (value: any) => void
  clear?: () => void
  debounceDelay?: number
} & TextFieldProps

const AutoComplete = ({
  suggestions,
  onSuggestionClick,
  onType,
  onInputChange,
  clear,
  debounceDelay = 500,
  ...inputProps
}: AutoCompleteProps) => {
  const [showSuggestions, setShowSuggestions] = useState(false)
  const [loading, setLoading] = useState(true)

  const changeTrigger = async (userInput: string) => {
    if (userInput) {
      try {
        setLoading(true)
        setShowSuggestions(true)
        onType && (await onType(userInput))
      } catch (err: any) {
        toast.error(err.message)
      } finally {
        setLoading(false)
      }
    }
  }

  const debounced = useCallback(
    debounce((func, e) => func(e), debounceDelay),
    []
  )

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const userInput = e.target.value
    setShowSuggestions(false)
    onInputChange(userInput)
    debounced(changeTrigger, userInput)

    if (!userInput) {
      setShowSuggestions(false)
    }
  }

  const onClick = (event: React.MouseEvent<HTMLLIElement>, value: any) => {
    setShowSuggestions(false)
    try {
      onSuggestionClick?.(value)
    } catch {
      return
    }
  }

  const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    // User pressed the enter key
    if (e.keyCode === 13) {
      setShowSuggestions(false)
    }
  }

  const SuggestionsListComponent = () => {
    if (loading) {
      return (
        <S.Suggestions className="suggestions">
          <li style={{ textAlign: 'center' }}>
            <p>Buscando...</p>
            <img src={LoadingGif} alt="loading" />
          </li>
        </S.Suggestions>
      )
    }
    return (
      <S.Suggestions className="suggestions">
        {/* eslint-disable-next-line react/prop-types */}
        {suggestions?.length === 0 && !loading && (
          <li style={{ textAlign: 'center' }}>Nenhum registro encontrado</li>
        )}
        {/* eslint-disable-next-line react/prop-types */}
        {suggestions?.map((suggestion, index) => {
          return (
            <li
              key={index}
              data-testid={`doctor-autocomplete-doctor-${index}`}
              onClick={(e) => onClick(e, suggestion.value)}
            >
              {suggestion.label}
            </li>
          )
        })}
      </S.Suggestions>
    )
  }

  return (
    <S.Wrapper isOpen={showSuggestions}>
      <TextField
        type="text"
        onChange={onChange}
        onKeyDown={onKeyDown}
        autoComplete="off"
        {...inputProps}
        data-testid="autocomplete-tuss-field"
      />
      {showSuggestions && <SuggestionsListComponent />}
      {clear && inputProps.disabled && (
        <Button onClick={clear} className="search-button">
          <SearchIcon width="30px" height="30px" viewBox="0 0 18 18" />
        </Button>
      )}
    </S.Wrapper>
  )
}

export default AutoComplete
