import { Hospital } from 'domain/entities/hospital-model'
import { LoadHospitalsByDoctor } from 'domain/usecases/hospital/load-hospitals-by-doctor'
import { HospitalRepository } from 'repository/interfaces/hospital-repository'

export class RemoteLoadHospitalsByDoctor implements LoadHospitalsByDoctor {
  constructor(private readonly hospitalRepository: HospitalRepository) {}

  async load(): Promise<LoadHospitalsByDoctor.Model> {
    const hospitals = await this.hospitalRepository.loadHospitalsByDoctor()

    return hospitals.map((hospital) => {
      return new Hospital(
        hospital?.hospital_id,
        hospital?.nickname,
        hospital?.name,
        hospital?.friendly_name ?? hospital?.name
      )
    })
  }
}

export type LoadHospitalsByDoctorModel = LoadHospitalsByDoctor.Model
