import { AnswerSurgicalPendency } from 'domain/usecases/surgical-pendency/answer-surgical-pendency'
import { UploadSurgicalPendencyDocument } from 'domain/usecases/surgical-pendency/upload-surgical-pendency-document'
import { LoadSurgicalPendencyDocument } from 'domain/usecases/surgical-pendency/load-surgical-pendency-document'
import { LoadDashboardPendencyCount } from 'domain/usecases/surgical-pendency/load-dashboard-pendency-count'
import { LoadSurgicalOrdersByPendencyStatus } from 'domain/usecases/surgical-pendency/load-surgical-orders-by-pendency-status'
import { LoadSurgicalOrderPendencies } from 'domain/usecases/surgical-pendency/load-surgical-order-pendencies'
import { LoadSurgicalOrderPendenciesHistory } from 'domain/usecases/surgical-pendency/load-surgical-order-pendencies-history'
import { RemoveDocumentFromSurgicalPendency } from 'domain/usecases/surgical-pendency/remove-document-from-surgical-pendency'
import { LoadHelpPendencyHistory } from 'domain/usecases/surgical-order/load-help-pendency-history'
import { makeAutoObservable } from 'mobx'
import { CreateHelpPendencyMessage } from 'domain/usecases/surgical-order/create-help-pendency-message'

type Services = {
  answerSurgicalPendency: AnswerSurgicalPendency
  uploadSurgicalPendencyDocument: UploadSurgicalPendencyDocument
  loadSurgicalPendencyDocument: LoadSurgicalPendencyDocument
  loadDashboardPendencyCount: LoadDashboardPendencyCount
  loadSurgicalOrdersByPendencyStatus: LoadSurgicalOrdersByPendencyStatus
  loadSurgicalOrderPendencies: LoadSurgicalOrderPendencies
  loadSurgicalOrderPendenciesHistory: LoadSurgicalOrderPendenciesHistory
  removeDocumentFromSurgicalPendency: RemoveDocumentFromSurgicalPendency
  loadHelpPendencyHistory: LoadHelpPendencyHistory
  createHelpPendencyMessage: CreateHelpPendencyMessage
}

export class SurgicalPendencyService {
  constructor(private readonly services: Services) {
    makeAutoObservable(this)
  }

  async answerSurgicalPendency(
    params: AnswerSurgicalPendency.Params
  ): Promise<AnswerSurgicalPendency.Model> {
    return this.services.answerSurgicalPendency.answer(params)
  }

  async uploadSurgicalPendencyDocument(
    params: UploadSurgicalPendencyDocument.Params
  ): Promise<UploadSurgicalPendencyDocument.Model> {
    return this.services.uploadSurgicalPendencyDocument.upload(params)
  }

  async loadSurgicalPendencyDocument({
    surgical_pendency_id,
    document_id,
    group_id
  }: LoadSurgicalPendencyDocument.Params): Promise<LoadSurgicalPendencyDocument.Model> {
    return this.services.loadSurgicalPendencyDocument.load(
      surgical_pendency_id,
      document_id,
      group_id
    )
  }

  async loadDashboardPendencyCount(
    params?: LoadDashboardPendencyCount.Params
  ): Promise<LoadDashboardPendencyCount.Model> {
    return this.services.loadDashboardPendencyCount.load(params)
  }

  async loadSurgicalOrdersByPendencyStatus(
    params: LoadSurgicalOrdersByPendencyStatus.Params
  ): Promise<LoadSurgicalOrdersByPendencyStatus.Model> {
    return this.services.loadSurgicalOrdersByPendencyStatus.load(params)
  }

  async loadSurgicalOrderPendencies(
    surgical_order_id: LoadSurgicalOrderPendencies.Params
  ): Promise<LoadSurgicalOrderPendencies.Model> {
    return this.services.loadSurgicalOrderPendencies.load(surgical_order_id)
  }

  async loadSurgicalOrderPendenciesHistory(
    surgical_order_id: LoadSurgicalOrderPendenciesHistory.Params
  ): Promise<LoadSurgicalOrderPendenciesHistory.Model> {
    return this.services.loadSurgicalOrderPendenciesHistory.load(
      surgical_order_id
    )
  }

  async loadHelpPendencyHistory(
    surgical_pendency_id: LoadHelpPendencyHistory.Params
  ): Promise<LoadHelpPendencyHistory.Model> {
    return this.services.loadHelpPendencyHistory.load(surgical_pendency_id)
  }

  async createHelpPendencyMessage(
    params: CreateHelpPendencyMessage.Params
  ): Promise<CreateHelpPendencyMessage.Model> {
    return this.services.createHelpPendencyMessage.create(params)
  }

  async removeDocumentFromSurgicalPendency(
    params: RemoveDocumentFromSurgicalPendency.Params
  ): Promise<RemoveDocumentFromSurgicalPendency.Model> {
    return this.services.removeDocumentFromSurgicalPendency.remove(params)
  }
}

export default SurgicalPendencyService
