import styled from 'styled-components'

export const Wrapper = styled.div`
  /* margin-top: 24px; */
`

export const Paragraph = styled.p`
  font-size: ${({ theme }) => theme.ds.typography.size.small};
  font-weight: ${({ theme }) => theme.ds.typography.weight.normal};
  line-height: ${({ theme }) => theme.ds.typography.lineHeight.medium};
  color: ${({ theme }) => theme.ds.colors.neutral700};

  b {
    font-weight: ${({ theme }) => theme.ds.typography.weight.bold};
  }

  &:not(:first-of-type) {
    margin-top: 24px;
  }
`

export const ImageWrapper = styled.div`
  display: flex;
  width: 100%;
  margin-top: 8px;
  margin-bottom: 32px;

  svg {
    margin: 0 auto;
    width: 100%;
    height: 100%;
    max-width: 200px;
    max-height: 165px;
  }
`
