import { SearchEquipaments } from 'domain/usecases/surgical-scheduling/search-equipaments'
import { SurgicalSchedulingRepository } from 'repository/interfaces/surgical-scheduling-repository'

export class RemoteSearchEquipaments implements SearchEquipaments {
  constructor(
    private readonly surgicalSchedulingRepository: SurgicalSchedulingRepository
  ) {}

  search(params: SearchEquipaments.Params): Promise<SearchEquipaments.Model> {
    return this.surgicalSchedulingRepository.searchEquipaments(params)
  }
}
