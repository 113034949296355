import { ApiClientImplementation } from 'infra/api-client/api-client'

import { PrepareApiRequestDecorator } from 'main/decorators/prepare-api-request-decorator/prepare-api-request-decorator'
import { makeLocalStorageAdapter } from 'main/factories/cache/local-storage-adapter-factory'
import { ApiRepository } from 'repository/api-repository'
import { UserRepository } from 'repository/repositories/user/user-repository'
import { RemoteUpdateUser } from 'service/usecases/update-user/remote-update-user'
import UserService from 'presentation/contexts/services/user-service'
import { RemoteLoadUser } from 'service/usecases/load-user/remote-load-user'
import { RemoteUpdateUserPassword } from 'service/usecases/user-update-password/update-password'
import { RemoteUpdateProfilePic } from 'service/usecases/update-profile-pic/update-profile-pic'
import { RemoteUserAcceptTerm } from 'service/usecases/user-accept-term/remote-user-accept-term'
import { RemoteLoadUserContacts } from 'service/usecases/user-load-contacts/remote-load-contacts'
import { RemoteUserAlreadyRegistered } from 'service/usecases/user-already-registered/remote-user-already-registered'
import { RemoteSetSurgicalSchedulingBannerVisualized } from 'service/usecases/set-surgical-scheduling-banner/set-surgical-scheduling-banner-visualized'
import { RemoteFinishTutorialTutor } from 'service/usecases/finish-tutorial-tutor/remote-finish-tutorial-tutor'

export default function makeUserService() {
  const apiRepository = new PrepareApiRequestDecorator(
    makeLocalStorageAdapter(),
    new ApiRepository(new ApiClientImplementation())
  )

  const userRepository = new UserRepository(apiRepository)

  const loadUser = new RemoteLoadUser(userRepository)
  const updateUser = new RemoteUpdateUser(userRepository)
  const updatePassword = new RemoteUpdateUserPassword(userRepository)
  const updateProfilePic = new RemoteUpdateProfilePic(userRepository)
  const acceptTerm = new RemoteUserAcceptTerm(userRepository)
  const loadContacts = new RemoteLoadUserContacts(userRepository)
  const userAlreadyRegistered = new RemoteUserAlreadyRegistered(userRepository)
  const setSurgicalSchedulingBannerVisualized =
    new RemoteSetSurgicalSchedulingBannerVisualized(userRepository)
  const finishTutorialTutor = new RemoteFinishTutorialTutor(userRepository)

  return new UserService({
    updateUser,
    loadUser,
    updatePassword,
    updateProfilePic,
    acceptTerm,
    loadContacts,
    userAlreadyRegistered,
    setSurgicalSchedulingBannerVisualized,
    finishTutorialTutor
  })
}
