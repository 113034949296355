import React, { useContext, useState } from 'react'
import * as S from './styles'
import { ContainerNew } from 'presentation/shared/components/ContainerNew'
import SupportTextNew from 'presentation/shared/components/SupportTextNew'
import ButtonNew from 'presentation/shared/components/ButtonNew'
import AvatarCountersListNew from 'presentation/shared/components/AvatarCountersListNew'
import { ReactComponent as PlusIcon } from 'presentation/assets/icons/plus.svg'
import { ReactComponent as SearchIcon } from 'presentation/assets/icons/search2.svg'
import { ReactComponent as RightArrow } from 'presentation/assets/icons/v-arrow.svg'
import { ReactComponent as SchedulingBannerIcon } from 'presentation/assets/banners/surgical-order-dashboard-scheduling-banner.svg'
import { SurgicalOrderDashboardContext } from 'main/factories/pages/doctor/surgical-order/surgical-order-dashboard-factory'
import ActionCardNew from 'presentation/shared/components/ActionCardNew'
import TextFieldNew from 'presentation/shared/components/TextFieldNew'
import theme from 'presentation/styles/theme'
import SurgicalOrderCard from 'presentation/shared/layouts/SurgicalOrderCard'
import { PendencieTypes } from 'domain/usecases/surgical-pendency/load-surgical-orders-by-pendency-status'
import { SchedulingTypes } from 'domain/usecases/surgical-order/load-surgical-orders-by-doctor-and-status'
import { useHistory } from 'react-router'
import { LoadSurgicalOrdersByDoctorAndStatus } from 'domain/usecases/surgical-order/load-surgical-orders-by-doctor-and-status'
import { PendencyStatusPlural } from 'common/enum/pendency-status'
import {
  SurgicalOrdersStatus,
  SurgicalOrdersStatusPlural
} from 'common/enum/surgical-orders-status'
import SheetModalNew from 'presentation/shared/components/SheetModalNew'
import {
  PasswordMaxDateValidationStatus,
  PasswordMaxDateValidationStatusTranslation
} from 'common/enum/password-max-date-validation-status'
import { ExpirationDateTypes } from 'domain/usecases/surgical-order/load-expiration-date-status-dashboard-count'
import { useStores } from 'presentation/hooks/use-stores'
import GuideModalTour from 'presentation/shared/components/GuideModalTour'
import HeaderNew from 'presentation/shared/components/HeaderNew'
import { LocalStorageAdapter } from 'infra/storage-adapter/storage-adapter'

type Props = {
  getSurgicalOrders: (
    params: LoadSurgicalOrdersByDoctorAndStatus.Params
  ) => void
  deleteSurgicalOrder: (surgical_order_id: number) => void
}

export default function SurgicalOrderDashboardLayot({
  getSurgicalOrders,
  deleteSurgicalOrder
}: Props) {
  const history = useHistory()
  const { state } = useContext<any>(SurgicalOrderDashboardContext)
  const [searchTerm, setSearchTerm] = useState('')
  const surgicalOrderContext = useStores().surgicalOrder
  const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false)
  const [orderToDelete, setOrderToDelete] = useState<number | undefined>()
  const localStorageAdapter = new LocalStorageAdapter()
  // feature flip novo fluxo pedido cirurgico
  // const doctor = useStores().currentAccount?.getCurrentAccount()?.user
  // const secretary =
  //   useStores().currentAccount?.getCurrentAccount()?.user.secretary_doctor
  // const isActiveSurgicalOrderNew =
  //   doctor.features?.active || secretary?.features?.active

  const first_status_group_counters = [
    {
      key: SurgicalOrdersStatus.UNDER_REVIEW_AT_THE_HOSPITAL,
      label: SurgicalOrdersStatusPlural.UNDER_REVIEW_AT_THE_HOSPITAL,
      color: 'secondary500'
    },
    {
      key: SurgicalOrdersStatus.UNDER_REVIEW_AT_THE_INSURANCE,
      label: SurgicalOrdersStatusPlural.UNDER_REVIEW_AT_THE_INSURANCE,
      color: 'secondary500'
    },
    {
      key: SurgicalOrdersStatus.AUTHORIZED,
      label: `pedidos ${SurgicalOrdersStatusPlural.AUTHORIZED}`,
      color: 'primary500'
    },
    {
      key: SurgicalOrdersStatus.ORDER_OPEN,
      label: `${SurgicalOrdersStatusPlural.ORDER_OPEN}`,
      color: 'terciary500'
    },
    {
      key: SurgicalOrdersStatus.CANCELLED,
      label: `pedidos ${SurgicalOrdersStatusPlural.CANCELLED}`,
      color: 'neutral500'
    },
    {
      key: SurgicalOrdersStatus.CONCLUDED,
      label: `cirurgias ${SurgicalOrdersStatusPlural.CONCLUDED}`,
      color: 'primary500'
    }
  ]

  const second_status_group_counters = [
    {
      key: SurgicalOrdersStatus.PARTIALLY_AUTHORIZED,
      label: `${SurgicalOrdersStatusPlural.PARTIALLY_AUTHORIZED}`,
      color: 'primary500'
    },
    {
      key: SurgicalOrdersStatus.DENIED,
      label: `${SurgicalOrdersStatusPlural.DENIED}`,
      color: 'terciary500'
    }
  ]

  const password_group_counters = [
    {
      key: PasswordMaxDateValidationStatus.EXPIRES_SOON,
      label: PasswordMaxDateValidationStatusTranslation.EXPIRES_SOON,
      color: 'danger500'
    },
    {
      key: PasswordMaxDateValidationStatus.EXPIRED,
      label: PasswordMaxDateValidationStatusTranslation.EXPIRED,
      color: 'danger500'
    }
  ]

  const third_status_group_counters = [
    {
      key: SurgicalOrdersStatus.REQUESTED_CANCELLATION,
      label: `${SurgicalOrdersStatusPlural.REQUESTED_CANCELLATION}`,
      color: 'neutral500'
    }
  ]

  const pendenciesTypes = [
    {
      key: 'PENDENT',
      label: PendencyStatusPlural.PENDENT,
      color: 'terciary400' as keyof typeof theme.ds.colors,
      active: true,
      action: () => goToPendencies('PENDENT')
    },
    {
      key: 'IN_PROGRESS',
      label: PendencyStatusPlural.IN_PROGRESS,
      color: 'secondary400' as keyof typeof theme.ds.colors,
      active: true,
      action: () => goToPendencies('IN_PROGRESS')
    },
    {
      key: 'RESOLVED',
      label: PendencyStatusPlural.RESOLVED,
      color: 'primary400' as keyof typeof theme.ds.colors,
      active: true,
      action: () => goToPendencies('RESOLVED')
    }
  ]

  const ModalConfirmButton = () => {
    return (
      <ButtonNew
        onClick={async () => {
          if (orderToDelete && deleteSurgicalOrder) {
            await deleteSurgicalOrder(orderToDelete)
            unsetDeletModal()
          }
        }}
      >
        Excluir
      </ButtonNew>
    )
  }

  const unsetDeletModal = () => {
    setDeleteModalIsOpen(false)
    setOrderToDelete(undefined)
  }

  const ModalCancelButton = () => {
    return (
      <ButtonNew variant="text" onClick={() => unsetDeletModal()}>
        Cancelar
      </ButtonNew>
    )
  }

  const handleDeleteOrder = (surgical_order_id: number) => {
    setDeleteModalIsOpen(true)
    setOrderToDelete(surgical_order_id)
  }

  const goToPendencies = (pendencyType: PendencieTypes) => {
    history.push('/pedidos-cirurgicos/pendencias', {
      pendencyType
    })
  }

  const goToOrdersList = (
    quantity: number,
    orderStatus?: SchedulingTypes,
    passwordStatus?: ExpirationDateTypes
  ) => {
    if (quantity && quantity > 0) {
      history.push('/pedidos-cirurgicos/pedidos', {
        orderStatus,
        passwordStatus
      })
    }
  }

  const goToSurgicalOrder = (surgical_order_id: number) => {
    history.push('/pedido-cirurgico/detalhes', {
      surgical_order_id
    })
  }

  const onEnterSearch = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.keyCode === 13) {
      getOrders()
    }
  }

  const getOrders = (nextPage = false) => {
    getSurgicalOrders({
      search: searchTerm,
      pageNumber: nextPage ? state.pageInfo.current_page + 1 : 1
    })
  }

  const isLastPage = () => {
    return state.pageInfo?.current_page === state.pageInfo?.total_pages
  }

  const getOrderStatus = (key: string) => {
    return Object.keys(SurgicalOrdersStatus).includes(key)
      ? (key as SchedulingTypes)
      : SurgicalOrdersStatus.AUTHORIZED
  }

  const getPasswordStatus = (key: string) => {
    return Object.keys(PasswordMaxDateValidationStatus).includes(key)
      ? (key as ExpirationDateTypes)
      : undefined
  }

  const handleCreateSurgicalOrder = () => {
    surgicalOrderContext.clear()
    history.push('/novo-pedido/local')
  }

  return (
    <>
      <HeaderNew
        actualPageTitle="Pedido cirúrgico"
        actualPageOnClick={() => history.push('/home')}
        topFixed
      />
      <ContainerNew spacingTop>
        <div>
          <AvatarCountersListNew
            title="Pendências"
            subtitle="Acompanhe as suas pendências"
            types={pendenciesTypes}
            counts={state.pendencyCount}
          />
        </div>
        <S.Container gap="24px" marginTop="40px">
          <SupportTextNew weight="bold" color="neutral800" size="medium">
            Pedidos cirúrgicos
          </SupportTextNew>
          <ButtonNew size="large" fullWidth onClick={handleCreateSurgicalOrder}>
            <PlusIcon style={{ marginRight: '16px', width: '16px' }} />
            Novo pedido cirúrgico
          </ButtonNew>
          <S.CountersContainer>
            <S.FirstStatusGroupCountersContainer>
              {first_status_group_counters.map((type) => {
                return (
                  <S.VerticalCounterCard
                    key={type.key}
                    border={type.color as keyof typeof theme.ds.colors}
                    shadow="none"
                    padding="8px"
                    onClick={() =>
                      goToOrdersList(
                        state.statusCount[type.key],
                        getOrderStatus(type.key),
                        getPasswordStatus(type.key)
                      )
                    }
                  >
                    <SupportTextNew
                      color={type.color as keyof typeof theme.ds.colors}
                      size="xxlarge"
                      weight="bold"
                      textAligh="center"
                    >
                      {state.statusCount[type.key]}
                    </SupportTextNew>
                    <SupportTextNew
                      color="neutral700"
                      className="capitalize-fl"
                      textAligh="center"
                    >
                      {type.label}
                    </SupportTextNew>
                  </S.VerticalCounterCard>
                )
              })}
            </S.FirstStatusGroupCountersContainer>
            <S.SecondStatusGroupCountersContainer>
              {second_status_group_counters.map((type) => {
                return (
                  <S.HorizontalCounterCard
                    key={type.key}
                    border={type.color as keyof typeof theme.ds.colors}
                    shadow="none"
                    padding="8px"
                    onClick={() =>
                      goToOrdersList(
                        state.statusCount[type.key],
                        getOrderStatus(type.key),
                        getPasswordStatus(type.key)
                      )
                    }
                  >
                    <SupportTextNew
                      color={type.color as keyof typeof theme.ds.colors}
                      size="xxlarge"
                      weight="bold"
                      textAligh="center"
                    >
                      {state.statusCount[type.key]}
                    </SupportTextNew>
                    <SupportTextNew
                      color="neutral700"
                      className="capitalize-fl"
                      textAligh="center"
                    >
                      {type.label}
                    </SupportTextNew>
                  </S.HorizontalCounterCard>
                )
              })}
            </S.SecondStatusGroupCountersContainer>
            <S.PasswordCountersContainer>
              {password_group_counters.map((type) => {
                return (
                  <S.HorizontalCounterCard
                    key={type.key}
                    border={type.color as keyof typeof theme.ds.colors}
                    shadow="none"
                    padding="8px"
                    onClick={() =>
                      goToOrdersList(
                        state.statusCount[type.key],
                        getOrderStatus(type.key),
                        getPasswordStatus(type.key)
                      )
                    }
                  >
                    <SupportTextNew
                      color={type.color as keyof typeof theme.ds.colors}
                      size="xxlarge"
                      weight="bold"
                      textAligh="center"
                    >
                      {state.statusCount[type.key]}
                    </SupportTextNew>
                    <SupportTextNew
                      color="neutral700"
                      className="capitalize-fl"
                      textAligh="center"
                    >
                      {type.label}
                    </SupportTextNew>
                  </S.HorizontalCounterCard>
                )
              })}
            </S.PasswordCountersContainer>
            <S.ThirdStatusGroupCountersContainer>
              {third_status_group_counters.map((type) => {
                return (
                  <S.HorizontalCounterCard
                    key={type.key}
                    border={type.color as keyof typeof theme.ds.colors}
                    shadow="none"
                    padding="8px"
                    onClick={() =>
                      goToOrdersList(
                        state.statusCount[type.key],
                        getOrderStatus(type.key),
                        getPasswordStatus(type.key)
                      )
                    }
                  >
                    <SupportTextNew
                      color={type.color as keyof typeof theme.ds.colors}
                      size="xxlarge"
                      weight="bold"
                      textAligh="center"
                    >
                      {state.statusCount[type.key]}
                    </SupportTextNew>
                    <SupportTextNew
                      color="neutral700"
                      className="capitalize-fl"
                      textAligh="center"
                    >
                      {type.label}
                    </SupportTextNew>
                  </S.HorizontalCounterCard>
                )
              })}
            </S.ThirdStatusGroupCountersContainer>
          </S.CountersContainer>
        </S.Container>

        <S.Container gap="8px" marginTop="24px">
          <ActionCardNew
            title="Todos os pedidos"
            text={state.pageInfo.total_items}
            color="primary600"
            size="medium"
            shadow="none"
            padding="none"
            onClick={() => goToOrdersList(state.pageInfo.total_items)}
          />
          <TextFieldNew
            icon={<SearchIcon />}
            iconIsButton
            placeholder="Paciente, N° do aviso"
            value={searchTerm}
            onInputChange={setSearchTerm}
            onIconClick={() => getOrders()}
            onKeyDown={onEnterSearch}
          />
          <SupportTextNew>
            Acompanhe todos os seus pedidos cirúrgicos
          </SupportTextNew>

          <S.SurgicalOrdersListContainer>
            {state.surgicalOrdersList?.map(
              (surgicalOrder: any, index: number) => (
                <SurgicalOrderCard
                  procedures
                  schedulingDate
                  statusTag
                  key={surgicalOrder.surgical_order_id}
                  surgicalOrder={surgicalOrder}
                  onClick={goToSurgicalOrder}
                  onTrashClick={handleDeleteOrder}
                  id={index === 0 ? 'surgical_order_integration' : ''}
                />
              )
            )}
            {!isLastPage() && (
              <ButtonNew variant="text" onClick={() => getOrders(true)}>
                Ver mais
              </ButtonNew>
            )}
          </S.SurgicalOrdersListContainer>
        </S.Container>

        <S.SchedulingBanner>
          <SupportTextNew color="secondary700" weight="bold" size="xsmall">
            Cirurgia
          </SupportTextNew>
          <SupportTextNew color="primary700" weight="semiBold" size="medium">
            Agendamento cirúrgico
          </SupportTextNew>
          <S.SchedulingBannerBody>
            <SchedulingBannerIcon />
            <div>
              <SupportTextNew>Agende suas cirurgias pelo</SupportTextNew>
              <SupportTextNew weight="semiBold">Meu MaterDei</SupportTextNew>
              <ButtonNew
                size="medium"
                style={{ marginTop: '8px' }}
                onClick={() => {
                  history.push('/pedidos-cirurgicos/agendamento')
                }}
              >
                Agendar cirurgias
                <RightArrow style={{ marginLeft: '10px' }} />
              </ButtonNew>
            </div>
          </S.SchedulingBannerBody>
        </S.SchedulingBanner>

        <SheetModalNew
          title="Excluir pedido em aberto"
          primaryButton={<ModalConfirmButton />}
          secondaryButton={<ModalCancelButton />}
          isOpen={deleteModalIsOpen}
          onClose={() => setDeleteModalIsOpen(false)}
        >
          <SupportTextNew weight="semiBold">
            Tem certeza que deseja excluir este pedido?
          </SupportTextNew>
        </SheetModalNew>

        {state.surgicalOrdersList?.length > 0 &&
          !localStorageAdapter.get('user-flags').surgicalOrderIntegration && (
            <GuideModalTour
              steps={[
                {
                  selector: '#surgical_order_integration',
                  title: 'Acompanhe seu pedido',
                  content: (
                    <>
                      Ao entrar na tela <b>Pedidos Cirúrgicos</b> o pedido
                      criado no MV aparecerá em <b>Todos os Pedidos</b>.
                    </>
                  ),
                  placement: 'bottom-left'
                }
              ]}
              onClose={() => {
                const currentFlags = localStorageAdapter.get('user-flags')

                localStorageAdapter.set('user-flags', {
                  ...currentFlags,
                  surgicalOrderIntegration: true
                })

                history.push('/home')
              }}
              mask={false}
              hotspot
            />
          )}
      </ContainerNew>
    </>
  )
}
