import { GetAmbulatoryCounters } from 'domain/usecases/ambulatory/get-ambulatory-counters'
import { NewAmbulatoryRepository } from 'repository/interfaces/new-ambulatory-repository'

export class RemoteGetAmbulatoryCounters implements GetAmbulatoryCounters {
  constructor(private readonly ambulatoryRepository: NewAmbulatoryRepository) {}

  get(doctor_id: number): Promise<GetAmbulatoryCounters.Model> {
    return this.ambulatoryRepository.getAmbulatoryCounters(doctor_id)
  }
}
