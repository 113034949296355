import { AcceptUserTerm } from 'domain/usecases/user/acceptUserTerm'
import { ILoadUserContacts } from 'domain/usecases/user/load-contacts'
import { LoadUser } from 'domain/usecases/user/load-user'
import { UpdatePassword } from 'domain/usecases/user/update-password'
import { UpdateProfilePic } from 'domain/usecases/user/update-profile-pic'
import { UpdateUser } from 'domain/usecases/user/update-user'
import { UserAlreadyRegistered } from 'domain/usecases/user/user-already-registered'
import { SetSurgicalSchedulingBannerVisualized } from 'domain/usecases/user/set-surgical-scheduling-banner-visualized'
import { FinishTutorialTutor } from 'domain/usecases/user/finish-tutorial-tutor'
import { makeAutoObservable } from 'mobx'

type Services = {
  updateUser: UpdateUser
  loadUser: LoadUser
  updatePassword: UpdatePassword
  updateProfilePic: UpdateProfilePic
  acceptTerm: AcceptUserTerm
  loadContacts: ILoadUserContacts
  userAlreadyRegistered: UserAlreadyRegistered
  setSurgicalSchedulingBannerVisualized: SetSurgicalSchedulingBannerVisualized
  finishTutorialTutor: FinishTutorialTutor
}

export class UserService {
  constructor(private readonly services: Services) {
    makeAutoObservable(this)
  }

  async updateUser(params: UpdateUser.Params): Promise<UpdateUser.Model> {
    return this.services.updateUser.update(params)
  }

  async loadUser(params: LoadUser.Params): Promise<LoadUser.Model> {
    return this.services.loadUser.load(params)
  }

  async updatePassword(
    params: UpdatePassword.Params
  ): Promise<UpdatePassword.Model> {
    return this.services.updatePassword.updatePassword(params)
  }

  async updateProfilePic(
    params: UpdateProfilePic.Params
  ): Promise<UpdateProfilePic.Model> {
    return this.services.updateProfilePic.update(params)
  }

  async acceptUserTerm(
    params: AcceptUserTerm.Params
  ): Promise<AcceptUserTerm.Model> {
    return this.services.acceptTerm.acceptUserTerm(params)
  }

  async loadContacts(
    params: ILoadUserContacts.Params
  ): Promise<ILoadUserContacts.Model> {
    return this.services.loadContacts.load(params)
  }
  async userAlreadyRegistered(
    params: UserAlreadyRegistered.Params
  ): Promise<boolean> {
    return this.services.userAlreadyRegistered.execute(params)
  }
  async setSurgicalSchedulingBannerVisualized(): Promise<void> {
    return this.services.setSurgicalSchedulingBannerVisualized.visualize()
  }

  async finishTutorialTutor(): Promise<FinishTutorialTutor.Model> {
    return this.services.finishTutorialTutor.finishTutorialTutor()
  }
}

export default UserService
