import React, { useState } from 'react'
import { ContainerNew } from 'presentation/shared/components/ContainerNew'
import ButtonNew from 'presentation/shared/components/ButtonNew'
import { useHistory } from 'react-router'
import CheckboxNew from 'presentation/shared/components/CheckboxNew'
import SurgicalSchedulingBanner from 'presentation/assets/banners/surgical-scheduling-home.svg'
import SupportTextNew from 'presentation/shared/components/SupportTextNew'
import * as S from './styles'
import HeadingNew from 'presentation/shared/components/HeadingNew'

type Props = {
  setBannerVisualized: () => void
}

export default function SurgicalSchedulingInformationsLayout({
  setBannerVisualized
}: Props) {
  const [dontShow, setDontShow] = useState(false)
  const history = useHistory()

  const nextStep = () => {
    if (dontShow) setBannerVisualized()
    history.push('/agendamento-cirurgico/informacoes')
  }

  const submitButton = () => {
    return (
      <ButtonNew fullWidth size="large" onClick={() => nextStep()}>
        Próximo
      </ButtonNew>
    )
  }

  return (
    <ContainerNew primaryButton={submitButton()} alignItems="center" noHeader>
      <S.Banner src={SurgicalSchedulingBanner} />

      <HeadingNew color="primary600" size="xlarge">
        Agendar cirurgia
      </HeadingNew>

      <S.Texts color="neutral900" size="medium">
        Esta solicitação de agendamento passará por análise no{' '}
        <SupportTextNew weight="bold" color="neutral900">
          Setor de Marcação de Cirurgia.
        </SupportTextNew>
        <br />
        Acompanhe o status do agendamento e aguarde a confirmação da data da
        cirurgia.
      </S.Texts>

      <CheckboxNew
        checked={dontShow}
        labelFor="dont-show"
        onCheck={(v) => setDontShow(v)}
        label="Não mostrar essa informação novamente"
        labelSize="xsmall"
      />
    </ContainerNew>
  )
}
