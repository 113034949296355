import React, { useState } from 'react'

import List, { ListItem } from 'presentation/shared/components/List'
import * as S from './styles'
import { Container } from 'presentation/shared/components/Container'
import TextButton from 'presentation/shared/components/TextButton'
import ProfilePic from 'presentation/shared/components/ProfilePic'
import Heading from 'presentation/shared/components/Heading'
import { useHistory } from 'react-router-dom'
import FavoriteButton from 'presentation/shared/components/FavoriteButton'
import Modal from 'presentation/shared/components/Modal'
import { Cid, Opme, Procedure } from 'presentation/doctor/pages/Patient'
import { AddFavorite } from 'domain/usecases/surgical-order/add-favorite'
import { toast } from 'react-toastify'
import { useServices } from 'presentation/hooks/use-services'

type Props = {
  items: ListItem[]
  procedure?: Procedure[]
  cid?: Cid[]
  opme?: Opme
  addFavorite?: AddFavorite
  surgeryName?: string
  patientName?: string
  disabled: boolean
  documentList: ListItem[]
  comesFromOpenOrders?: boolean
}

export default function PatientLayout({
  items,
  procedure,
  cid,
  opme,
  surgeryName,
  patientName,
  documentList,
  comesFromOpenOrders
}: Props) {
  const history = useHistory()
  const [favoriteModal, setFavoriteModal] = useState(false)
  const [handleFavoriteModal, setHandleFavoriteModal] = useState(false)
  const service = useServices().doctor

  const submitFavorite = async (value: string) => {
    try {
      await service.addFavoriteSurgicalOrder({
        name: value,
        cid: cid!,
        procedure: procedure!,
        opme: opme!
      })
      setHandleFavoriteModal(true)
      setFavoriteModal(false)
    } catch (e: any) {
      toast.error(e.message)
    }
  }
  return (
    <>
      <Container
        actualPageText={
          comesFromOpenOrders ? 'Pedidos em aberto' : 'Novo pedido cirúrgico'
        }
        actualPageOnClick={history.goBack}
        headingButton={
          <TextButton
            size="small"
            onClick={() =>
              comesFromOpenOrders ? history.goBack() : history.push('/home')
            }
          >
            Cancelar
          </TextButton>
        }
      >
        <S.Card>
          <header>
            <h6>Cirurgia</h6>
            <span style={{ textAlign: 'left' }}>
              {surgeryName ? surgeryName : 'Nome do procedimento cirúrgico'}
            </span>
          </header>
          <div>
            <ProfilePic
              size="medium"
              name={patientName}
              // src="https://source.unsplash.com/random"
            />
            <div>
              <span>Paciente</span>
              <p>{patientName}</p>
            </div>
          </div>
        </S.Card>
        <Heading
          size="medium"
          color="primary"
          style={{ marginTop: '40px', marginBottom: '20px' }}
        >
          Planejamento cirúrgico
        </Heading>
        <S.List>
          <List items={items} size="medium" />
        </S.List>

        <Heading
          size="medium"
          color="primary"
          style={{ marginTop: '40px', marginBottom: '20px' }}
        >
          Documentos
        </Heading>
        <S.List>
          <List items={documentList} size="medium" />
        </S.List>
        <FavoriteButton
          data-testid="btn--doctor-procedure-favorite"
          onClick={() => setFavoriteModal(!favoriteModal)}
          disabled={!opme}
          style={{ marginTop: 20 }}
        />
        <Modal
          title="Nome do favorito"
          description="Favorite um procedimento para agilizar a construção dos próximos pedidos cirúrgicos"
          show={favoriteModal}
          preventAutomateClose
          type="favorite"
          hasCloseButton="Cancelar"
          hasInput={(value) => submitFavorite(value)}
          bgZIndex={12}
          actionLabel="Salvar"
          close={() => setFavoriteModal(!favoriteModal)}
          subtitleProps={{
            colorFont: 'black'
          }}
          style={{ padding: '24px', width: 300 }}
        />
        <Modal
          close={() => setHandleFavoriteModal(false)}
          show={handleFavoriteModal}
          title="Procedimento favoritado com sucesso!"
        />
      </Container>
    </>
  )
}
