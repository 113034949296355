import { GetSurgicalOrderCancellationNotificationsCount } from 'domain/usecases/surgical-order/get-surgical-order-cancellation-notifications-count'
import { SurgicalOrderRepository } from 'repository/repositories/surgical-order/surgical-order-repository'

export class RemoteGetSurgicalOrderCancellationNotificationsCount
  implements GetSurgicalOrderCancellationNotificationsCount
{
  constructor(
    private readonly surgicalOrderRepository: SurgicalOrderRepository
  ) {}

  get(): Promise<GetSurgicalOrderCancellationNotificationsCount.Model> {
    return this.surgicalOrderRepository.getSurgicalOrderCancellationNotificationsCount()
  }
}
