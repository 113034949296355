import styled from 'styled-components'
import HeadingNew from 'presentation/shared/components/HeadingNew'

export const HeadinEdit = styled(HeadingNew)`
  display: flex;
  justify-content: space-between;
  align-items: center;

  svg {
    cursor: pointer;
  }
`

export const CardsGroup = styled.div<{ columns?: number }>`
  margin-top: 8px;
  display: grid;
  grid-template-columns: ${({ columns }) => `repeat(${columns || 1}, 1fr)`};
  gap: 8px 16px;
`

export const InfoCardBody = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  .horizon-info {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 8px;
  }
`
