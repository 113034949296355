import React from 'react'
import SupportTextNew from 'presentation/shared/components/SupportTextNew'
import * as S from './styles'
import { useHistory } from 'react-router'
import HeadingNew from 'presentation/shared/components/HeadingNew'
import {
  AmbulatoryAuthorizationStatus,
  AmbulatoryAuthorizationStatusPlural,
  AmbulatoryAuthorizationStatusSingular
} from 'common/enum/ambulatory-authorization-status'
import { AmbulatoryOrderModel } from 'domain/entities/ambulatory-orders-model'
import { useServices } from 'presentation/hooks/use-services'
import { toast } from 'react-toastify'
import {
  WithLoading,
  WithLoadingProps
} from 'presentation/shared/components/HOCs/WithLoading'

type AmbulatoryCardProps = {
  procedure?: string
  medication?: string
  patient: string
  doctor: string
  healthInsurance: string
  unit: string
  orderId: string
  status: AmbulatoryAuthorizationStatus
  order: AmbulatoryOrderModel
  withBorder?: boolean
  withHeight?: boolean
} & WithLoadingProps

const AmbulatoryCard = WithLoading(
  ({
    procedure,
    medication,
    patient,
    doctor,
    healthInsurance,
    unit,
    orderId,
    status,
    order,
    withBorder,
    withHeight,
    setIsLoading
  }: AmbulatoryCardProps) => {
    const history = useHistory()

    const ambulatory_status_colors = [
      {
        key: AmbulatoryAuthorizationStatus.HOSPITAL_ANALYSIS,
        label: AmbulatoryAuthorizationStatusPlural.HOSPITAL_ANALYSIS,
        color: 'secondary500',
        background: 'secondary50'
      },
      {
        key: AmbulatoryAuthorizationStatus.ANALYSIS_INSURANCE,
        label: AmbulatoryAuthorizationStatusPlural.ANALYSIS_INSURANCE,
        color: 'secondary500',
        background: 'secondary50'
      },
      {
        key: AmbulatoryAuthorizationStatus.WAITING_BUDGET,
        label: AmbulatoryAuthorizationStatusPlural.WAITING_BUDGET,
        color: 'secondary500',
        background: 'secondary50'
      },
      {
        key: AmbulatoryAuthorizationStatus.DENIED,
        label: AmbulatoryAuthorizationStatusPlural.DENIED,
        color: 'terciary500',
        background: 'terciary50'
      },
      {
        key: AmbulatoryAuthorizationStatus.AUTHORIZED,
        label: AmbulatoryAuthorizationStatusPlural.AUTHORIZED,
        color: 'primary500',
        background: 'primary50'
      },
      {
        key: AmbulatoryAuthorizationStatus.DONE,
        label: AmbulatoryAuthorizationStatusPlural.DONE,
        color: 'primary500',
        background: 'primary50'
      },
      {
        key: AmbulatoryAuthorizationStatus.CANCELED,
        label: AmbulatoryAuthorizationStatusPlural.CANCELED,
        color: 'neutral500',
        background: 'neutral50'
      }
    ]

    function getColorStatus(status: any) {
      const statusColor = ambulatory_status_colors.find(
        (statusOption) => statusOption.key === status
      )

      return statusColor
    }

    const ambulatoryService = useServices().ambulatory

    async function getOrder(id: string) {
      setIsLoading(true)
      try {
        const response_ambulatory_orders =
          await ambulatoryService.getOneAmbulatoryOrder({
            id
          })

        if (response_ambulatory_orders.data) {
          history.push('/pedidos-autorizacao-ambulatorial/detalhes', {
            order: response_ambulatory_orders?.data
          })
        }
      } catch (error: any) {
        toast.error(
          'Não foi possível carregar os pedidos de autorização ambulatorial'
        )
      } finally {
        setIsLoading(false)
      }
    }

    return (
      <S.Wrapper
        color={withBorder ? getColorStatus(status)?.color : 'transparent'}
        onClick={() => getOrder(order.id)}
      >
        <S.Header color={getColorStatus(status)?.background}>
          <S.HeaderInfo>
            <SupportTextNew color="neutral800" size="small" weight="semiBold">
              Nº {orderId ? orderId : '00000'}
            </SupportTextNew>
            <S.Tag color={getColorStatus(status)?.color}>
              {AmbulatoryAuthorizationStatusSingular[status]}
            </S.Tag>
          </S.HeaderInfo>
          <S.DividerColor
            color={getColorStatus(status)?.color}
            marginBottom="10px"
          />
        </S.Header>
        <S.CardBody withHeight={withHeight}>
          <S.TextWrapper>
            {procedure && (
              <>
                <SupportTextNew
                  weight="semiBold"
                  color="neutral700"
                  size="xxsmall"
                >
                  Procedimento
                </SupportTextNew>
                <HeadingNew weight="bold" color="primary900" size="small">
                  {procedure}
                </HeadingNew>
              </>
            )}

            {medication && (
              <>
                <SupportTextNew
                  weight="semiBold"
                  color="neutral700"
                  size="xxsmall"
                >
                  Medicação
                </SupportTextNew>
                <HeadingNew weight="bold" color="primary900" size="small">
                  {medication}
                </HeadingNew>
              </>
            )}

            <SupportTextNew weight="semiBold" color="neutral700" size="xxsmall">
              Paciente
            </SupportTextNew>
            <HeadingNew weight="normal" color="primary900" size="small">
              {patient}
            </HeadingNew>

            <SupportTextNew weight="semiBold" color="neutral700" size="xxsmall">
              Médico
            </SupportTextNew>
            <HeadingNew weight="normal" color="neutral800" size="small">
              {doctor}
            </HeadingNew>

            <SupportTextNew weight="semiBold" color="neutral700" size="xsmall">
              Convênio
            </SupportTextNew>
            <HeadingNew weight="normal" color="neutral800" size="xsmall">
              {healthInsurance}
            </HeadingNew>

            <SupportTextNew weight="semiBold" color="neutral700" size="xxsmall">
              Unidade
            </SupportTextNew>
            <HeadingNew weight="normal" color="neutral800" size="xsmall">
              {unit}
            </HeadingNew>
          </S.TextWrapper>
        </S.CardBody>
      </S.Wrapper>
    )
  }
)

export default AmbulatoryCard
