import React, { useEffect, useState } from 'react'

import { useHistory, useLocation } from 'react-router-dom'
import moment from 'moment'

import { SurgicalOrderModel } from 'domain/entities/surgical-order-model'
import PatientLayout from 'presentation/doctor/layouts/Patient'
import { LoadSurgicalOrder } from 'domain/usecases/surgical-order/load-surgical-order'
import CheckIcon from 'presentation/assets/icons/big-check.svg'
import ArrowIcon from 'presentation/assets/icons/rounded-arrow.svg'
import { ListItem } from 'presentation/shared/components/List'
import verifyEmptyObject from 'common/utils/verify-empty-object'
import { Patient as PatientModel } from 'domain/entities/patient-model'
import { AddFavorite } from 'domain/usecases/surgical-order/add-favorite'
import { Hospital } from 'domain/entities/hospital-model'
import {
  WithLoading,
  WithLoadingProps
} from 'presentation/shared/components/HOCs/WithLoading'
import deleteNonMandatoryFields from 'common/utils/deleteNonMandatoryFields'
import { HealthInsurance } from 'domain/entities/health-insurance'
import { Doctor } from 'domain/entities/doctor-model'
import { toast } from 'react-toastify'

type Props = {
  loadSurgicalOrder: LoadSurgicalOrder
  addFavorite?: AddFavorite
} & WithLoadingProps

type Location = {
  surgicalOrder: SurgicalOrderModel
  comesFromOpenOrders?: boolean
}

const Patient = WithLoading(
  ({ loadSurgicalOrder, addFavorite, setIsLoading }: Props) => {
    const [surgicalOrder, setSurgicalOrder] = useState<SurgicalOrderModel>({})
    const { state } = useLocation<Location>()
    const history = useHistory()

    useEffect(() => {
      async function loadData() {
        try {
          setIsLoading(true)

          const response = await loadSurgicalOrder.load(
            state.surgicalOrder.surgical_order_id as number,
            [
              'surgical_order_id',
              'patient_id',
              'patientName',
              'patient { phone, email, birthday, gender, cpf, healthInsurance {healthInsuranceName, healthInsuranceCode}}',
              'procedure {procedure_code, code, description, quantity, doctor_name, isMain, surgery_id, pro_fat_id}',
              'opme {solicitations {description, quantity}, providers}',
              'freeze',
              'cti',
              'cid {code, description}',
              'technique',
              'hospitalizationMode',
              'hospitalizationType',
              'extraBlood',
              'healthInsurance {healthInsuranceName, healthInsuranceCode}',
              'allergy',
              'bloodTransfusion',
              'hospital {name, hospital_id}',
              'clinicalHistory',
              'surgicalCenter',
              'observation',
              'patientHospitalized',
              'requestedDailies',
              'documents {document_id, type, name}',
              'expectedDate'
            ]
          )

          setSurgicalOrder(response)
        } catch (e: any) {
          toast.error(e.message)
        } finally {
          setIsLoading(false)
        }
      }
      loadData()
    }, [])

    const {
      patient,
      procedure,
      cid,
      opme,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      healthInsurance: insurance,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      hospital,
      ...medicalRecord
    } = surgicalOrder

    const { healthInsurance, ...patientData } = patient || {}
    const validateObjects = [
      !verifyEmptyObject(patientData),
      !verifyEmptyObject(healthInsurance),
      !(procedure && procedure.length && cid && cid.length),
      !opme,
      Object.keys(medicalRecord).length !== 0
        ? !verifyEmptyObject(
            deleteNonMandatoryFields(
              ['observation', 'clinicalHistory', 'extraBlood', 'expectedDate'],
              {
                ...medicalRecord,
                bloodTransfusion: medicalRecord.bloodTransfusion
              }
            )
          )
        : true
    ]
    const isAllValid = validateObjects.every((val) => !val)

    const documentList: ListItem[] = [
      {
        title: 'Visualizar documentos',
        click: () => {
          history.push('/pedido-anexo', {
            surgicalOrder: surgicalOrder
          })
        }
      }
    ]

    const items: ListItem[] = [
      {
        title: 'Dados do paciente',
        icon: validateObjects[0] ? ArrowIcon : CheckIcon,
        click: () => {
          history.push('/pedido-cirurgico/save/dados-pessoais', {
            patient_id: state.surgicalOrder?.patient_id
          })
        }
      },
      {
        title: 'Dados do convênio',
        icon: validateObjects[1] ? ArrowIcon : CheckIcon,
        click: () => {
          history.push('/pedido-cirurgico/save/dados-convenio', {
            patient_id: state.surgicalOrder?.patient_id,
            surgical_order_id: state.surgicalOrder?.surgical_order_id,
            hospital_id: state.surgicalOrder?.hospital?.hospital_id
          })
        }
      },
      {
        title: 'Procedimento',
        icon: validateObjects[2] ? ArrowIcon : CheckIcon,
        click: () =>
          history.push('/pedido-cirurgico/procedimento', {
            procedure,
            cid,
            surgical_order_id: state.surgicalOrder.surgical_order_id,
            clinicalHistory: surgicalOrder.clinicalHistory,
            expectedDate: moment(surgicalOrder.expectedDate).format(
              'DD/MM/YYYY'
            )
          })
      },
      {
        title: 'OPME',
        icon: validateObjects[3] ? ArrowIcon : CheckIcon,
        click: () =>
          history.push('/pedido-cirurgico/opme', {
            surgical_order_id: state.surgicalOrder.surgical_order_id,
            opme
          })
      },
      {
        title: 'Ficha clínica',
        icon: validateObjects[4] ? ArrowIcon : CheckIcon,
        click: () =>
          history.push('/pedido-cirurgico/ficha-clinica', {
            surgical_order_id: surgicalOrder.surgical_order_id,
            ...medicalRecord,
            patient_id: surgicalOrder.patient?.patient_id,
            latex: surgicalOrder.allergy ? surgicalOrder.allergy[0] : '',
            otherAllergies: state.surgicalOrder.allergy
              ?.filter(
                (val) =>
                  val !== 'Látex' &&
                  val !== 'false' &&
                  val !== 'latex-not-informed'
              )
              .join(','),
            surgicalOrder
          })
      },
      {
        title: 'Resumo do pedido',
        icon: isAllValid ? CheckIcon : ArrowIcon,
        click: () =>
          history.push('/pedido-cirurgico/resumo', {
            surgicalOrder
          })
      }
    ]

    return (
      <PatientLayout
        items={items}
        patientName={surgicalOrder?.patientName}
        surgeryName={procedure?.[0].description}
        procedure={surgicalOrder?.procedure}
        cid={surgicalOrder?.cid}
        addFavorite={addFavorite}
        opme={surgicalOrder?.opme}
        disabled={!isAllValid}
        documentList={documentList}
        comesFromOpenOrders={state?.comesFromOpenOrders}
      />
    )
  }
)

export default Patient

export type AddDoctorSurgicalOrderFormValues = {
  patient: PatientModel
  hospital: Hospital
  clinicalHistory: string
  provider1: string
  provider2: string
  provider3: string
  technique: string
  observation?: string
  region: string
  otherAllergies: string
  hospitalizationType: string
  hospitalizationMode: string
  room: string
  freeze: boolean
  dialitic: boolean
  cti: boolean
  bloodTransfusion: boolean
  extraBlood: boolean
  specialTreatment: boolean
  latex: any
  tuss: any[]
  cids: any[]
  opmes: any[]
  use_opme: boolean
  healthInsurance?: HealthInsurance
  allergy?: string[]
  doctor?: Doctor
}

export type Solicitations = {
  description: string
  quantity: number
  observation: string
}

export type Procedure = {
  isMain: boolean
  procedure_code: string
  code: string
  description: string
  quantity: number
  doctor_name: string
}

export type Cid = {
  code: string
  description: string
}

export type Opme = {
  solicitations: Array<Solicitations>
  providers: string[]
}
