import React from 'react'

import HeadingNew from 'presentation/shared/components/HeadingNew'
import theme from '../../../styles/theme'
import * as S from './styles'

export type Props = {
  titleSmaller?: string
  title: string
  titleSmallerColor?: keyof typeof theme.ds.colors
  titleColor?: keyof typeof theme.ds.colors
  bgColor?: keyof typeof theme.ds.colors
  image?: React.ReactElement
  onClick: () => void
}

const ShortCutCard = ({
  titleSmaller,
  title,
  titleSmallerColor = 'neutral700',
  titleColor = 'primary600',
  bgColor = 'primary50',
  image,
  onClick
}: Props) => {
  return (
    <S.Wrapper onClick={onClick} bgColor={bgColor}>
      <S.CardHeader>
        <HeadingNew
          as="h4"
          size="xsmall"
          color={titleColor}
          weight="bold"
          style={{ lineHeight: 1.3 }}
        >
          {titleSmaller && (
            <S.Small titleSmallerColor={titleSmallerColor}>
              {titleSmaller}
            </S.Small>
          )}
          {title}
        </HeadingNew>
      </S.CardHeader>
      {image && <S.ImageWrapper>{image}</S.ImageWrapper>}
    </S.Wrapper>
  )
}

export default ShortCutCard
