import React, { useEffect } from 'react'
import RadioButtonNew from 'presentation/shared/components/RadioButtonNew'
import SelectFieldNew from 'presentation/shared/components/SelectFieldNew'
import ButtonNew from 'presentation/shared/components/ButtonNew'
import SupportTextNew from 'presentation/shared/components/SupportTextNew'
import { ContainerNew } from 'presentation/shared/components/ContainerNew'
import { useFormik } from 'formik'
import { useHistory } from 'react-router'
import HeadingNew from 'presentation/shared/components/HeadingNew'
import { techniquesList, Techniques } from 'common/enum/techniques'
import { booleansList } from 'common/enum/booleans'
import { HospitalModel } from 'domain/usecases/surgical-scheduling/get-hospitals'
import { SurgeryCenterModel } from 'domain/usecases/surgical-scheduling/get-surgery-center'
import { SurgicalOrderModel } from 'domain/entities/surgical-order-model'
import { useLocation } from 'react-router'
import LoadingGif from 'presentation/assets/icons/loading.gif'
import * as yup from 'yup'
import * as S from './styles'

type Props = {
  hospitalsList: HospitalModel[]
  surgicalCentersList: SurgeryCenterModel[]
  surgicalOrder: SurgicalOrderModel
  isLoadingSurgeryCenters: boolean
  getSurgicalCentersList: (code: number) => void
  dispatch: (action: { type: any; payload: any }) => void
}

export default function SurgicalSchedulingInformationsLayout({
  hospitalsList,
  surgicalCentersList,
  surgicalOrder,
  isLoadingSurgeryCenters,
  getSurgicalCentersList,
  dispatch
}: Props) {
  const location = useLocation<{ isReviewing?: boolean }>()
  const history = useHistory()

  const submitForm = (values: any) => {
    dispatch({
      type: 'SURGICAL_ORDER',
      payload: values
    })

    if (location.state?.isReviewing) {
      history.push('/agendamento-cirurgico/resumo')
    } else {
      history.push('/agendamento-cirurgico/equipamentos')
    }
  }

  const formik = useFormik({
    initialValues: {
      patient_internal: surgicalOrder?.patient_internal,
      tecnic: surgicalOrder?.tecnic,
      freezing: surgicalOrder?.freezing,
      hospital_id: surgicalOrder?.hospital_id,
      room_id: surgicalOrder?.room_id,
      surgery_center_name: surgicalOrder?.surgery_center_name,
      blood_reserve: surgicalOrder?.blood_reserve,
      cti: surgicalOrder?.cti
    },
    enableReinitialize: true,
    validateOnMount: true,
    validationSchema: validationSchema,
    onSubmit: submitForm
  })

  const changeUnityValue = (value: number) => {
    formik.setFieldValue('hospital_id', value)
    getSurgicalCentersList(value)
  }

  const booleanRadio = (
    name:
      | 'patient_internal'
      | 'freezing'
      | 'hospital_id'
      | 'blood_reserve'
      | 'cti'
  ) => (
    <S.BooleanRadioWrapper>
      {booleansList.map((option) => (
        <RadioButtonNew
          showBackground={false}
          label={option.label}
          name={name}
          labelFor={name + '-' + option.value}
          onCheck={(value) => formik.setFieldValue(name, value === 'true')}
          checked={formik.values[name] === option.value}
          value={String(option.value)}
          key={String(option.value)}
        />
      ))}
    </S.BooleanRadioWrapper>
  )

  const techniquesRadio = () => (
    <S.BooleanRadioWrapper isColumn>
      {techniquesList.map((tecnic) => (
        <RadioButtonNew
          showBackground={false}
          label={tecnic.label}
          name="tecnic"
          labelFor={tecnic.value}
          onCheck={(value) => formik.setFieldValue('tecnic', value)}
          checked={formik.values.tecnic === tecnic.value}
          value={tecnic.value}
          key={tecnic.value}
          required
        />
      ))}
    </S.BooleanRadioWrapper>
  )

  const roomTypesRadio = () => (
    <S.BooleanRadioWrapper isColumn>
      {surgicalCentersList.map((type) => (
        <RadioButtonNew
          showBackground={false}
          label={type.name}
          name="room_id-type"
          labelFor={'room_id-' + type.code}
          onCheck={setRoomType}
          checked={formik.values.room_id === type.code}
          value={type.code}
          key={type.code}
          required
        />
      ))}
    </S.BooleanRadioWrapper>
  )

  const setRoomType = (value: string | number) => {
    formik.setFieldValue('room_id', Number(value))
    const room = surgicalCentersList.find((room) => room.code === Number(value))
    formik.setFieldValue('surgery_center_name', room?.name)
  }

  const mappedHospitalsList = (hospital_id: number) => {
    return hospitalsList?.map((hospital: HospitalModel) => ({
      label: hospital.surname,
      value: hospital.code,
      selected: hospital.code === hospital_id
    }))
  }

  const submitButton = () => {
    return (
      <ButtonNew
        size="large"
        fullWidth
        onClick={formik.submitForm}
        disabled={!formik.isValid || isLoadingSurgeryCenters}
      >
        Confirmar
      </ButtonNew>
    )
  }

  const LoadingSurgeryCenters = () => (
    <S.LoadingContainer>
      <img src={LoadingGif} alt="Carregando..." />
      Buscando...
    </S.LoadingContainer>
  )

  useEffect(() => {
    if (formik.values.hospital_id) {
      changeUnityValue(formik.values.hospital_id)
    }
  }, [formik.values.hospital_id])

  useEffect(() => {
    if (
      formik.values.hospital_id &&
      surgicalCentersList?.length &&
      surgicalOrder?.room_id
    ) {
      setRoomType(surgicalOrder.room_id)
    }
  }, [surgicalCentersList])

  return (
    <ContainerNew form primaryButton={submitButton()} gap="24px" noHeader>
      <div>
        <HeadingNew color="primary600" size="large">
          Confirmar informações
        </HeadingNew>
        <SupportTextNew>
          Estas informações foram preenchidas por você no pedido cirúrgico,
          atualize o que for necessário.
        </SupportTextNew>
      </div>

      <div>
        <S.Label weight="bold" color="neutral900">
          Paciente internado? *
        </S.Label>
        {booleanRadio('patient_internal')}
      </div>

      <div>
        <S.Label weight="bold" color="neutral900">
          Técnica *
        </S.Label>
        {techniquesRadio()}
      </div>
      <div>
        <S.Label weight="bold" color="neutral900">
          Congelação *
        </S.Label>
        {booleanRadio('freezing')}
      </div>
      <div>
        <S.Label weight="bold" color="neutral900">
          Unidade *
        </S.Label>
        <SelectFieldNew
          placeholder="Selecione a unidade"
          items={mappedHospitalsList(Number(formik.values.hospital_id))}
          value={formik.values.hospital_id}
          onBlur={formik.handleBlur('hospital_id')}
          onInputChange={(value) => changeUnityValue(Number(value))}
          required
          disabled
        />
      </div>
      <div>
        <S.Label weight="bold" color="neutral900">
          Unidade cirúrgica *
        </S.Label>
        {isLoadingSurgeryCenters ? (
          LoadingSurgeryCenters()
        ) : surgicalOrder?.hospital_id ? (
          roomTypesRadio()
        ) : (
          <SupportTextNew>
            *Selecione uma unidade para ver os tipos de sala disponíveis.
          </SupportTextNew>
        )}
      </div>
      <div>
        <S.Label weight="bold" color="neutral900">
          Solicitar reserva de sangue? *
        </S.Label>
        {booleanRadio('blood_reserve')}
      </div>
      <div>
        <S.Label weight="bold" color="neutral900">
          A cirurgia vai precisar de CTI? *
        </S.Label>
        {booleanRadio('cti')}
      </div>
    </ContainerNew>
  )
}

const validationSchema = yup.object().shape({
  patient_internal: yup.boolean().required(),
  tecnic: yup.mixed().oneOf(Object.keys(Techniques)).required(),
  freezing: yup.boolean().required(),
  hospital_id: yup.number().required(),
  room_id: yup.number().required(),
  surgery_center_name: yup.string().required(),
  blood_reserve: yup.boolean().required(),
  cti: yup.boolean().required()
})
