import { GetPatientByCpfOrName } from 'domain/usecases/patient/get-patient-by-cpf-or-name'
import { PatientRepository } from 'repository/interfaces/patient-repository'

export class RemoteGetPatientByCpfOrName implements GetPatientByCpfOrName {
  constructor(private readonly patientRepository: PatientRepository) {}

  load(
    params?: GetPatientByCpfOrName.Params
  ): Promise<GetPatientByCpfOrName.Model> {
    return this.patientRepository.getPatientByCpfOrName(params)
  }
}

export type GetPatientByCpfOrNameModel = GetPatientByCpfOrName.Model
