import { CreateTherapeuticPlan } from 'domain/usecases/therapeutic-plan/create-therapeutic-plan'
import SelectTherapeuticPlanType from 'presentation/doctor/components/Forms/AddTherapeuticPlan/NewTherapeuticPlan/SelectTherapeuticPlanType'
import { Container } from 'presentation/shared/components/Container'
import Header from 'presentation/shared/components/Header'
import React from 'react'

type Props = {
  createInitialTherapeuticPlan: CreateTherapeuticPlan
}

export default function CreateInitialTherapeuticPlan({
  createInitialTherapeuticPlan
}: Props) {
  return (
    <>
      <Header />
      <Container>
        <SelectTherapeuticPlanType
          createInitialTherapeuticPlan={createInitialTherapeuticPlan}
        />
      </Container>
    </>
  )
}
