export enum Techniques {
  makoRobot = 'Robô Mako',
  robotic = 'Robótica',
  video = 'Vídeo',
  conventional = 'Convencional'
}

export const techniquesList = [
  {
    label: Techniques.conventional,
    value: 'conventional'
  },
  {
    label: Techniques.video,
    value: 'video'
  },
  {
    label: Techniques.robotic,
    value: 'robotic'
  },
  {
    label: Techniques.makoRobot,
    value: 'makoRobot'
  }
]
