export class HealthInsurance {
  private _healthInsuranceCode: number
  private _healthInsuranceName: string
  private _healthPlanCode: number
  private _healthPlanName: string
  private _id?: number
  private _name?: string
  private _healthCard?: string
  private _ansRegister?: string
  health_insurance_name?: string
  health_insurance_code?: number

  constructor(
    healthInsuranceCode: number,
    healthInsuranceName: string,
    healthPlanCode: number,
    healthPlanName: string,
    id?: number,
    name?: string,
    healthCard?: string,
    ansRegister?: string
  ) {
    this._id = id
    this._name = name
    this._healthInsuranceCode = healthInsuranceCode
    this._healthInsuranceName = healthInsuranceName
    this._healthPlanCode = healthPlanCode
    this._healthPlanName = healthPlanName
    this._healthCard = healthCard
    this._ansRegister = ansRegister
  }

  get id(): number {
    return this.id
  }

  set id(value: number) {
    this.id = value
  }

  get name(): string {
    return this.name
  }

  set name(value: string) {
    this.name = value
  }

  get healthInsuranceCode(): number {
    return this._healthInsuranceCode
  }

  set healthInsuranceCode(value: number) {
    this._healthInsuranceCode = value
  }

  get healthInsuranceName(): string {
    return this._healthInsuranceName
  }

  set healthInsuranceName(value: string) {
    this._healthInsuranceName = value
  }

  get healthPlanCode(): number {
    return this._healthPlanCode
  }

  set healthPlanCode(value: number) {
    this._healthPlanCode = value
  }

  get healthPlanName(): string {
    return this._healthPlanName
  }

  set healthPlanName(value: string) {
    this._healthPlanName = value
  }

  get healthCard(): string {
    return <string>this._healthCard
  }

  set healthCard(value: string) {
    this._healthCard = value
  }

  get ansRegister(): string {
    return <string>this._ansRegister
  }

  set ansRegister(value: string) {
    this._ansRegister = value
  }
}
