import React from 'react'
import HeaderNew from 'presentation/shared/components/HeaderNew'
import { ReportsAndDocumentsForm } from 'presentation/doctor/components/Forms/AddSurgicalOrderNew/ReportsAndDocuments'

export const ReportsAndDocumentsLayout = () => {
  return (
    <>
      <HeaderNew actualPageTitle="Laudos e documentos" />
      <ReportsAndDocumentsForm />
    </>
  )
}
