import styled, { css } from 'styled-components'

type HeaderProps = {
  topFixed?: boolean
}

export const Wrapper = styled.header<HeaderProps>`
  ${({ theme, topFixed }) => css`
    position: ${topFixed ? 'fixed' : 'relative'};
    top: ${topFixed ? 0 : 'unset'};
    left: ${topFixed ? 0 : 'unset'};
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 16px;
    padding: 10px 24px;
    height: 72px;
    flex-shrink: 0;
    width: 100%;
    z-index: ${theme.layers.base};
    background-color: ${theme.ds.colors.white};
    border-bottom: 1px solid #c4c4c4;
  `}
`

export const HamburguerIconWrapper = styled.div`
  cursor: pointer;
  width: 24px;
  height: 20px;
  margin-left: 0;
  margin-right: auto;
`

export const ButtonGoBack = styled.button`
  cursor: pointer;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  outline: none;
  border: none;
  height: 100%;
  padding: 0;
  margin: 0;
  background: none;
`

export const PageTitle = styled.h1`
  ${({ theme }) => css`
    color: ${theme.ds.colors.primary600};
    font-size: ${theme.ds.typography.size.medium};
    font-weight: ${theme.ds.typography.weight.bold};
    margin-left: 0;
    margin-right: auto;
    line-height: 1.2;
  `}
`

export const LogoWrapper = styled.div`
  cursor: pointer;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
`

export const NotificationsWrapper = styled.div`
  margin-left: auto;
  margin-right: 0;
`

export const HomeIconWrapper = styled.button`
  cursor: pointer;
  outline: none;
  background: none;
  border: none;
  margin-right: 0;

  svg {
    width: 24px;
    height: 24px;
  }
`

export const ProfileWrapper = styled.button`
  cursor: pointer;
  outline: none;
  background: none;
  border: none;
  margin-right: 0;
  margin-left: auto;

  svg path {
    width: 30px;
    height: 30px;
    fill: ${({ theme }) => theme.ds.colors.primary600};
  }
`
