import React from 'react'
import Heading from 'presentation/shared/components/Heading'
import * as S from './styles'
import { SurgicalPendency } from 'domain/entities/surgical-pendency'
import { observer } from 'mobx-react'
import doctorPendencyStore from 'presentation/contexts/doctor-pendency/doctor-pendency-context'
import moment from 'moment-timezone'

type Props = {
  pendency: SurgicalPendency
}

export const SurgicalPendencyHeader = observer(({ pendency }: Props) => {
  const currentPendency = doctorPendencyStore.doctorPendency[0] || pendency

  return (
    <header>
      <S.SubtitleWrapper>
        <div>
          <h6>Responsável CRMO:</h6>
          <span>{currentPendency?.requestedBy?.name}</span>
        </div>
        <div>
          <h6>Pendência gerada:</h6>
          <span>
            {moment.utc(currentPendency?.createdAt).format('DD/MM/YYYY, HH:mm')}
          </span>
        </div>
      </S.SubtitleWrapper>
      <Heading as="h2" color="primary" size="medium">
        {currentPendency?.surgicalOrder?.patientName}
      </Heading>
      <Heading as="h2" color="primary" size="medium">
        {currentPendency?.surgicalOrder?.procedure?.[0].description}
      </Heading>
      <S.AnswerInfoWrapper>
        <div>
          <h6>Retorno Cirurgião:</h6>
          <span>
            {currentPendency?.answeredAt
              ? moment
                  .utc(currentPendency?.answeredAt)
                  .format('DD/MM/YYYY, HH:mm')
              : '-'}
          </span>
        </div>
        {/*<div>*/}
        {/*  <h6>Data da validação:</h6>*/}
        {/*  <span>*/}
        {/*    {currentPendency.answeredAt*/}
        {/*      ? dateFormatter.format(new Date(currentPendency.))*/}
        {/*      : '-'}*/}
        {/*  </span>*/}
        {/*</div>*/}
      </S.AnswerInfoWrapper>
    </header>
  )
})
