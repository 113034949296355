import React from 'react'

import ButtonNew from 'presentation/shared/components/ButtonNew'
import { ContainerNew } from 'presentation/shared/components/ContainerNew'
import { ReactComponent as SynchronizationIllustration } from 'presentation/assets/illustrations/synchronization.svg'
import * as S from './styles'
import { useHistory } from 'react-router'

export default function SurgicalOrderIntegrationLayout() {
  const history = useHistory()

  return (
    <ContainerNew>
      <S.Wrapper>
        <S.ImageWrapper>
          <SynchronizationIllustration />
        </S.ImageWrapper>

        <S.ParagraphWrapper>
          <S.Heading color="primary800" size="xxlarge">
            Conheça a novidade!
          </S.Heading>
          <S.Paragraph>
            <b>Integração do Pedido Cirúrgico do MV com o Meu Mater Dei</b>
          </S.Paragraph>

          <S.Paragraph>
            Agora ao criar um pedido cirúrgico pelo MV ele aparecerá
            automaticamente no Meu Mater Dei. Você não precisa criar esse pedido
            novamente por aqui.
          </S.Paragraph>
        </S.ParagraphWrapper>
        <ButtonNew
          fullWidth
          size="large"
          onClick={() => history.push('/pedidos-cirurgicos/dashboard')}
        >
          Próximo
        </ButtonNew>
      </S.Wrapper>
    </ContainerNew>
  )
}
