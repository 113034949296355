import styled, { css } from 'styled-components'
import { CardProps } from '.'

export const CardWrapper = styled.div<CardProps>`
  ${({
    theme,
    shadow = 'normal',
    border = 'none',
    background = 'white',
    color = 'neutral900',
    padding = '8px 16px',
    gap,
    disabled,
    onClick
  }) => css`
    display: flex;
    flex-direction: column;
    color: ${disabled ? theme.ds.colors.neutral400 : theme.ds.colors[color]};
    background: ${disabled
      ? theme.ds.colors.neutral100
      : theme.ds.colors[background]};
    box-shadow: ${theme.ds.shadows[shadow]};
    border: ${border === 'none'
      ? 'none'
      : `1px solid ${
          disabled ? theme.ds.colors.neutral200 : theme.ds.colors[border]
        }`};
    border-radius: 8px;
    padding: ${padding};
    gap: ${gap || '0px'};
    cursor: ${onClick && !disabled ? 'pointer' : 'default'};
    svg {
      path {
        stroke: ${disabled ? theme.ds.colors.neutral400 : ''};
      }
    }
  `}
`
