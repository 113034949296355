import styled from 'styled-components'
import SupportTextNew from 'presentation/shared/components/SupportTextNew'

export const BooleanRadioWrapper = styled.div<{ isColumn?: boolean }>`
  display: flex;
  flex-direction: ${({ isColumn }) => (isColumn ? 'column' : 'row')};
  gap: ${({ isColumn }) => (isColumn ? '12px' : '32px')};
`

export const Label = styled(SupportTextNew)`
  margin-bottom: 12px;
`

export const EstimatedTiming = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`
