import { CancelSchedule } from 'domain/usecases/scheduling/cancel-schedule'
import { SchedulingRepository } from 'repository/interfaces/scheduling-repository'

export class RemoteCancelSchedule implements CancelSchedule {
  constructor(private readonly schedulingRepository: SchedulingRepository) {}

  load(params: CancelSchedule.Params): Promise<CancelSchedule.Model> {
    return this.schedulingRepository.cancelSchedule(params)
  }
}

export type CancelScheduleModel = CancelSchedule.Model
