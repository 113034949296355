import React, { useState } from 'react'
import { ContainerNew } from 'presentation/shared/components/ContainerNew'
import ButtonNew from 'presentation/shared/components/ButtonNew'
import HeadingNew from 'presentation/shared/components/HeadingNew'
import SupportTextNew from 'presentation/shared/components/SupportTextNew'
import RadioButtonNew from 'presentation/shared/components/RadioButtonNew'
// import SelectFieldNew from 'presentation/shared/components/SelectFieldNew'
import * as S from './styles'
import { anesthesiasList, Anesthesia } from 'common/enum/anesthesia'
import { useFormik } from 'formik'
import { AnesthesistModel } from 'domain/usecases/surgical-scheduling/search-anesthetists'
import AutoCompleteNew from 'presentation/shared/components/AutoCompleteNew'
import { useHistory } from 'react-router'
import { ReactComponent as SearchIcon } from 'presentation/assets/icons/search.svg'
import { clone } from 'lodash'
import { useLocation } from 'react-router'
import * as yup from 'yup'

type Params = {
  anesthetistsList: AnesthesistModel[]
  searchAnesthetists: (name: string) => void
  dispatch: (action: { type: any; payload: any }) => void
  anesthesia: AnesthesistModel
}

export default function SurgicalSchedulingAnesthesiaLayout({
  anesthetistsList,
  searchAnesthetists,
  dispatch,
  anesthesia
}: Params) {
  const location = useLocation<{ isReviewing?: boolean }>()
  const history = useHistory()
  const [searchTerm, setSearchTerm] = useState(anesthesia?.name || '')
  const formik = useFormik({
    initialValues: {
      type: anesthesia?.type || '',
      code: anesthesia?.code || -1,
      name: anesthesia?.name || ''
    },
    validationSchema: validationSchema,
    validateOnChange: true,
    validateOnMount: true,
    onSubmit: (values) => submitForm(values)
  })

  const submitForm = (values: any) => {
    const payload = clone(values)

    if (values.type !== 'choose-anesthetist') {
      delete payload.code
      delete payload.name
    }

    dispatch({
      type: 'ANESTHESIA',
      payload
    })

    if (location.state?.isReviewing) {
      history.push('/agendamento-cirurgico/resumo')
    } else {
      history.push('/agendamento-cirurgico/duracao')
    }
  }

  const selectAnesthetist = (data: { value: number; label: string }) => {
    setSearchTerm(data.label)
    formik.setFieldValue('code', data.value)
    formik.setFieldValue('name', data.label)
  }

  const clearSelection = () => {
    setSearchTerm('')
    formik.setFieldValue('code', -1)
    formik.setFieldValue('name', '')
  }

  const mappedAnesthetistsList = () => {
    return anesthetistsList.map((anesthetist) => ({
      label: anesthetist.name,
      value: anesthetist.code
    }))
  }

  const submitButton = () => {
    return (
      <ButtonNew
        fullWidth
        size="large"
        onClick={formik.submitForm}
        disabled={!formik.isValid}
      >
        Próximo
      </ButtonNew>
    )
  }

  return (
    <ContainerNew primaryButton={submitButton()} gap="24px" noHeader>
      <div>
        <HeadingNew color="primary600" size="large">
          Anestesia
        </HeadingNew>
        <SupportTextNew>Insira as informações sobre anestesia</SupportTextNew>
      </div>

      <div>
        <S.Label weight="bold" color="neutral900">
          Anestesia *
        </S.Label>
        <S.BooleanRadioWrapper isColumn>
          {anesthesiasList.map((type) => (
            <RadioButtonNew
              showBackground={false}
              label={type.label}
              name="type"
              labelFor={'type-' + type.value}
              onCheck={(value) => formik.setFieldValue('type', value)}
              checked={formik.values.type === type.value}
              value={type.value}
              key={type.value}
              required
            />
          ))}
        </S.BooleanRadioWrapper>
      </div>

      {formik.values.type === 'choose-anesthetist' && (
        <div>
          <S.Label weight="bold" color="neutral900">
            Indique um anestesista *
          </S.Label>
          <AutoCompleteNew
            name="anesthetist"
            placeholder="Selecionar anestesista"
            value={searchTerm}
            onInputChange={setSearchTerm}
            suggestions={mappedAnesthetistsList()}
            onSuggestionClick={selectAnesthetist}
            onType={searchAnesthetists}
            debounceDelay={800}
            clear={clearSelection}
            returnFullSuggestion
            iconIsButton
            icon={<SearchIcon />}
            disabled={
              Boolean(formik.values.code) && Boolean(formik.values.name)
            }
          />
        </div>
      )}
    </ContainerNew>
  )
}

const validationSchema = yup.object().shape({
  type: yup.mixed().oneOf(Object.keys(Anesthesia)).required(),
  code: yup.number().when('type', {
    is: 'choose-anesthetist',
    then: yup.number().required()
  }),
  name: yup.string().when('type', {
    is: 'choose-anesthetist',
    then: yup.string().required()
  })
})
