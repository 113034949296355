import React from 'react'

import { Property } from 'csstype'

import Heading from 'presentation/shared/components/Heading'
import SupportText from 'presentation/shared/components/SupportText'
import ActualPage from '../ActualPage'
import { ProgressBar } from '../ProgressBar'
import theme from '../../../styles/theme'

import * as S from './styles'

export type ContainerTypes = {
  hasHeader?: boolean
  noHeader?: boolean
  noPadding?: boolean
  form?: boolean
  onSubmit?: () => void
}

export const Container = (
  props: {
    children: React.ReactNode
    title?: string | React.ReactNode
    subtitle?: string
    primaryButton?: React.ReactNode
    secondaryButton?: React.ReactNode
    headingButton?: React.ReactNode
    justifyContent?: Property.JustifyContent
    style?: React.CSSProperties
    actualPageText?: string
    actualPageOnClick?: () => void
    titleColor?:
      | 'primary'
      | 'primaryDarker'
      | 'gray'
      | 'lightGray'
      | keyof typeof theme.colors
    subtitleColor?:
      | 'primary'
      | 'primaryDarker'
      | 'gray'
      | 'lightGray'
      | keyof typeof theme.colors
    progressBar?: {
      totalSteps: number
      activeStep: number
    }
  } & ContainerTypes
) => {
  return (
    <S.Wrapper
      style={props.style}
      noPadding={props.noPadding}
      as={props.form ? 'form' : 'div'}
      onSubmit={props.onSubmit}
    >
      <S.ContainerStyle noPadding={props.noPadding}>
        {props.noHeader ? undefined : (
          <S.Header style={{ padding: props.noPadding ? '0' : '0px 24px' }}>
            <div style={{ display: 'flex', marginBottom: '8px' }}>
              {props.actualPageText && (
                <ActualPage
                  onClick={props.actualPageOnClick}
                  text={props.actualPageText}
                />
              )}
              {props.headingButton && (
                <div
                  style={{
                    marginLeft: 'auto',
                    display: 'flex',
                    fontSize: '16px',
                    justifyContent: 'center',
                    alignItems: 'center'
                  }}
                >
                  {props.headingButton}
                </div>
              )}
            </div>

            {props.title && (
              <Heading as="h1" color={props.titleColor}>
                {props.title}
              </Heading>
            )}
            {props.subtitle && (
              <SupportText color={props.subtitleColor}>
                {props.subtitle}
              </SupportText>
            )}
          </S.Header>
        )}
        <S.Content
          style={{
            justifyContent: props.justifyContent,
            padding: props.noPadding ? '0' : '0px 24px'
          }}
        >
          {props.children}
        </S.Content>
        {props.progressBar && (
          <ProgressBar
            stepAmount={props.progressBar.totalSteps}
            actualStep={props.progressBar.activeStep}
          />
        )}
        {(props.primaryButton || props.secondaryButton) && (
          <S.Buttons noPadding={props.noPadding}>
            {props.primaryButton}
            {props.secondaryButton}
          </S.Buttons>
        )}
      </S.ContainerStyle>
    </S.Wrapper>
  )
}
