import { ApiClientImplementation } from 'infra/api-client/api-client'
import { PrepareApiRequestDecorator } from 'main/decorators/prepare-api-request-decorator/prepare-api-request-decorator'
import { makeLocalStorageAdapter } from 'main/factories/cache/local-storage-adapter-factory'
import CreateInitialTherapeuticPlan from 'presentation/doctor/pages/AddTherapeuticPlan/CreateTherapeuticPlan'
import React from 'react'
import { ApiRepository } from 'repository/api-repository'
import { TherapeuticPlanRepository } from 'repository/repositories/therapeutic-plan/therapeutic-plan-repository'
import { RemoteCreateInitialTherapeuticPlan } from 'service/usecases/create-initial-therapeutic-plan/remote-create-initial-therapeutic-plan'

export default function makeDoctorCreateTherapeuticPlan() {
  const apiClient = new ApiClientImplementation()
  const apiRepository = new PrepareApiRequestDecorator(
    makeLocalStorageAdapter(),
    new ApiRepository(apiClient)
  )
  const therapeuticPlanRepository = new TherapeuticPlanRepository(apiRepository)
  const createInitialTherapeuticPlan = new RemoteCreateInitialTherapeuticPlan(
    therapeuticPlanRepository
  )

  return (
    <CreateInitialTherapeuticPlan
      createInitialTherapeuticPlan={createInitialTherapeuticPlan}
    />
  )
}
