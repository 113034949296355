import { Patient } from 'domain/entities/patient-model'
import {
  TherapeuticPlanModel,
  TreatmentTypeLang
} from 'domain/entities/therapeutic-plan.model'
import moment from 'moment'
import { getAge } from 'presentation/utils/get-age'
import React from 'react'
import ProfilePic from '../ProfilePic'
import * as S from './styles'

type Props = {
  patient?: Patient
  doctorName: string
  therapeuticPlan?: TherapeuticPlanModel
}

export default function TherapeuticPlanDetails({
  patient,
  doctorName,
  therapeuticPlan
}: Props) {
  return (
    <S.Wrapper>
      <h3>PLANO TERAPÊUTICO</h3>
      <S.Header>
        <ProfilePic size="small" />
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            marginLeft: '10px'
          }}
        >
          <p>Paciente</p>
          <label>{patient?.name}</label>
        </div>
      </S.Header>
      <S.PatientInfoContainer>
        <S.PatientInfo>
          <div>
            <strong>Nascimento: </strong>
            <label>
              {patient?.birthday
                ? moment(patient?.birthday).utc().format('DD/MM/YYYY')
                : '-'}
            </label>
          </div>
          <div>
            <strong>Idade: </strong>
            <label>
              {getAge(moment(patient?.birthday).format('DD/MM/YYYY') || '-')}
            </label>
          </div>
        </S.PatientInfo>
        <S.PatientInfo>
          <div>
            <strong>Médico(a): </strong>
            <label>{doctorName}</label>
          </div>
        </S.PatientInfo>
        <S.PatientInfo>
          <div>
            <strong>Convênio: </strong>
            <label>{patient?.healthInsurance?.healthPlanName || '-'}</label>
          </div>
          <div>
            <strong>Carteira: </strong>
            <label>{patient?.healthInsurance?.healthCard || '-'}</label>
          </div>
        </S.PatientInfo>
      </S.PatientInfoContainer>
      <S.PatientInfoContainer>
        <S.PatientInfo>
          <div>
            <strong>Tipo de tratamento: </strong>
            <label>
              {therapeuticPlan?.treatmentType
                ? TreatmentTypeLang[therapeuticPlan?.treatmentType]
                : '-'}
            </label>
          </div>
        </S.PatientInfo>
        <S.PatientInfo>
          <div>
            <strong>Protocolo: </strong>
            <label>-</label>
          </div>
        </S.PatientInfo>
        <S.PatientInfo>
          <div>
            <strong>Nº total de ciclos: </strong>
            <label>-</label>
          </div>
          <div>
            <strong>Ciclo atual: </strong>
            <label>-</label>
          </div>
        </S.PatientInfo>
        <S.PatientInfo>
          <div>
            <strong>Intervalos de ciclos: </strong>
            <label>-</label>
          </div>
        </S.PatientInfo>
      </S.PatientInfoContainer>
    </S.Wrapper>
  )
}
