import React, { useState } from 'react'
import SupportTextNew from 'presentation/shared/components/SupportTextNew'
import DocumentAccordion from 'presentation/shared/layouts/DocumentAccordeon'
import { DocumentsList } from 'common/enum/documents-list'
import { ContainerNew } from 'presentation/shared/components/ContainerNew'
import ButtonNew from 'presentation/shared/components/ButtonNew'
import { useHistory } from 'react-router'

export enum DocumentsListToShow {
  'Carteira de identidade' = 'Carteira de identidade',
  'Carteira do convênio' = 'Carteira do convênio',
  'Laudos dos exames' = 'Laudos de exames',
  'Relatório Médico' = 'Relatório médico',
  'Consent. cirúrgico' = 'Consentimento cirúrgico',
  'Consent. anestésico' = 'Consentimento anestésico',
  'Avaliação pré-anestésica' = 'Avaliação pré-anestésica'
}

type Props = {
  onUploadFile?: (files: any, type: string) => void
  onDeleteFile?: (document_id: number, group_id: string) => void
  onDownloadFile?: (
    document_id: number,
    group_id: string,
    type: keyof typeof DocumentsList
  ) => void
  documents: any[]
  pendencyId: number
  hasNewDocument: boolean
  setHasNewDocument: (hasNewDocument: boolean) => void
}

const PendencyDocument = ({
  documents,
  onUploadFile,
  onDeleteFile,
  onDownloadFile,
  pendencyId,
  hasNewDocument,
  setHasNewDocument
}: Props) => {
  const history = useHistory()
  const [expandedDocument, setExpandedDocument] = useState('')
  const mappedDocumentsList = () => {
    return Object.keys(DocumentsListToShow).map((document) => ({
      label: DocumentsList[document as keyof typeof DocumentsList],
      type: document,
      ...documents.find((doc) => doc.type === document)
    }))
  }

  const startUpload = (files: any, type: string) => {
    onUploadFile && onUploadFile(files, type)
    setHasNewDocument(true)
  }

  const changeExpandedAccordeon = (
    accordeonLabel: string,
    isExpanded: boolean
  ) => {
    setExpandedDocument(isExpanded ? accordeonLabel : '')
  }

  const ConfirmButton = () => (
    <ButtonNew
      onClick={() => {
        history.push('/pedido-cirurgico/pendencia', {
          has_new_document: hasNewDocument,
          surgical_pendency_id: pendencyId
        })
        setHasNewDocument(false)
      }}
      fullWidth
      size="large"
    >
      Salvar documentos
    </ButtonNew>
  )

  return (
    <ContainerNew
      title="Inclusão de documentos"
      gap="16px"
      primaryButton={<ConfirmButton />}
    >
      <SupportTextNew color="neutral900" style={{ margin: '16px 0px' }}>
        Selecione o tipo de documento para adicionar.
      </SupportTextNew>

      {mappedDocumentsList().map((document) => {
        return (
          <DocumentAccordion
            key={document.type}
            document={document}
            expandedDocument={expandedDocument}
            handleChange={changeExpandedAccordeon}
            shadow="xsatin"
            background="neutral25"
            padding="16px"
            onDeleteFile={onDeleteFile}
            onDownloadFile={onDownloadFile}
            onUploadFile={
              !['APPROVED', 'approved'].includes(document.status)
                ? (files: any) => startUpload(files, document.type)
                : undefined
            }
            multiple
            uploadButton={true}
          />
        )
      })}
    </ContainerNew>
  )
}

export default PendencyDocument
