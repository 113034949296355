import { TreatmentRegimen } from 'domain/entities/proposed-protocol-model'

export const therapeuticPlanTreatmentRegimenOptions = [
  {
    label: 'Ambulatorial',
    value: TreatmentRegimen.AMBULATORY
  },
  {
    label: 'Internação',
    value: TreatmentRegimen.HOSPITALIZATION
  }
]
