export enum Anesthesia {
  scale = 'Escala',
  'local-anesthesia' = 'Anestesia local',
  'choose-anesthetist' = 'Escolher anestesista'
}

export const anesthesiasList = [
  {
    label: Anesthesia.scale,
    value: 'scale'
  },
  {
    label: Anesthesia['local-anesthesia'],
    value: 'local-anesthesia'
  },
  {
    label: Anesthesia['choose-anesthetist'],
    value: 'choose-anesthetist'
  }
]
