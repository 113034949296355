import { Patient } from 'domain/entities/patient-model'
import { TherapeuticPlanModel } from 'domain/entities/therapeutic-plan.model'
import { useStores } from 'presentation/hooks/use-stores'
import ActualPage from 'presentation/shared/components/ActualPage'
import Button from 'presentation/shared/components/Button'
import SupportText from 'presentation/shared/components/SupportText'
import TextArea from 'presentation/shared/components/TextArea'
import TherapeuticPlanDetails from 'presentation/shared/components/TherapeuticPlanDetails'
import React from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import * as S from './styles'
import { TherapeuticPlanSteps } from './TherapeuticPlanSteps'

export default function NewTherapeuticPlanHome() {
  const account = useStores().currentAccount
  const { state } = useLocation<{
    patient: Patient
    therapeuticPlan: TherapeuticPlanModel
  }>()
  const history = useHistory()

  const goBack = () => {
    history.push('/novo-plano-terapeutico/selecionar-plano', {
      patient: state?.patient
    })
  }

  return (
    <>
      <S.TherapeuticPlanHomeContainer>
        <ActualPage onClick={() => goBack()} text="Voltar" />
        <TherapeuticPlanDetails
          doctorName={account.getCurrentAccount().user.name}
          patient={state?.patient}
          therapeuticPlan={state?.therapeuticPlan}
        />
        <TherapeuticPlanSteps />
        <TextArea
          name="justify"
          id="justify"
          label="Justificativa"
          rows={4}
          outline
          disabled
        />
        <Button
          fullWidth
          disabled
          style={{
            marginTop: '20px'
          }}
        >
          Enviar
        </Button>
        <div
          style={{
            height: '50px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <SupportText onClick={() => goBack()} color="primary">
            Cancelar
          </SupportText>
        </div>
      </S.TherapeuticPlanHomeContainer>
    </>
  )
}
