import { makeAutoObservable } from 'mobx'
import { SearchFiltersSchedules } from 'domain/usecases/scheduling/search-filters-schedules'
import { GetRecentAppointments } from 'domain/usecases/scheduling/get-recent-appointments'
import { FinishSchedule } from 'domain/usecases/scheduling/finish-schedule'
import { GetAppointmentsByMonth } from 'domain/usecases/scheduling/get-appointments-by-month'
import { GetHoursByDay } from 'domain/usecases/scheduling/get-hours-by-day'
import { GetReasonCancel } from 'domain/usecases/scheduling/get-reason-cancel'
import { CancelSchedule } from 'domain/usecases/scheduling/cancel-schedule'

type Services = {
  searchFiltersSchedules: SearchFiltersSchedules
  getRecentAppointments: GetRecentAppointments
  finishSchedule: FinishSchedule
  getAppointmentsByMonth: GetAppointmentsByMonth
  getHoursByDay: GetHoursByDay
  getReasonCancel: GetReasonCancel
  cancelSchedule: CancelSchedule
}

export class SchedulingService {
  constructor(private readonly services: Services) {
    makeAutoObservable(this)
  }

  async searchFiltersSchedules(
    params: SearchFiltersSchedules.Params
  ): Promise<SearchFiltersSchedules.Model> {
    return this.services.searchFiltersSchedules.load(params)
  }

  async getRecentAppointments(
    params: GetRecentAppointments.Params
  ): Promise<GetRecentAppointments.Model> {
    return this.services.getRecentAppointments.load(params)
  }

  async finishSchedule(
    params: FinishSchedule.Params
  ): Promise<FinishSchedule.Model> {
    return this.services.finishSchedule.load(params)
  }

  async getAppointmentsByMonth(
    params: GetAppointmentsByMonth.Params
  ): Promise<GetAppointmentsByMonth.Model> {
    return this.services.getAppointmentsByMonth.load(params)
  }

  async getHoursByDay(
    params: GetHoursByDay.Params
  ): Promise<GetHoursByDay.Model> {
    return this.services.getHoursByDay.load(params)
  }

  async getReasonCancel(): Promise<GetReasonCancel.Model> {
    return this.services.getReasonCancel.load()
  }

  async cancelSchedule(
    params: CancelSchedule.Params
  ): Promise<CancelSchedule.Model> {
    return this.services.cancelSchedule.load(params)
  }
}

export default SchedulingService
