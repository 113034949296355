import React from 'react'
import SupportTextNew from 'presentation/shared/components/SupportTextNew'
import CardNew from 'presentation/shared/components/CardNew'
import AccordionCardNew from 'presentation/shared/components/AccordionCardNew'

type Props = {
  expanded?: boolean
  handleChange?: (accordionLabel: string, isExpanded: boolean) => void
  comment?: string
  icon?: React.ReactElement
}

export default function GeneralComments({
  expanded,
  handleChange,
  comment,
  icon
}: Props) {
  return (
    <AccordionCardNew
      accordionLabel="GeneralComments"
      expanded={expanded}
      handleChange={handleChange}
      header="Observações gerais"
      shadow="satin"
      padding="16px"
      gap="4px"
      background="neutral25"
      headerIcon={icon}
    >
      <CardNew
        shadow="none"
        background="neutral25"
        border="neutral100"
        gap="8px"
      >
        <SupportTextNew color="primary400" weight="bold">
          Observações gerais
        </SupportTextNew>
        {comment ? (
          <SupportTextNew color="neutral800" size="xsmall">
            {comment}
          </SupportTextNew>
        ) : (
          <SupportTextNew size="xsmall">Nenhuma observação</SupportTextNew>
        )}
      </CardNew>
    </AccordionCardNew>
  )
}
