import { GetSurgeryCenter } from 'domain/usecases/surgical-scheduling/get-surgery-center'
import { SurgicalSchedulingRepository } from 'repository/interfaces/surgical-scheduling-repository'

export class RemoteGetSurgeryCenter implements GetSurgeryCenter {
  constructor(
    private readonly surgicalSchedulingRepository: SurgicalSchedulingRepository
  ) {}

  getSurgeryCenterByHospitalCode(
    params: GetSurgeryCenter.Params
  ): Promise<GetSurgeryCenter.Model> {
    return this.surgicalSchedulingRepository.getSurgeryCenterByHospitalCode(
      params
    )
  }
}
