import React from 'react'
import TherapeuticPlanCard from '../TherapeuticPlanCard'
import * as S from './styles'

type TherapeuticPlanListProps = {
  therapeuticPlans?: string[]
}

export default function TherapeuticPlanList({
  therapeuticPlans = []
}: TherapeuticPlanListProps) {
  return (
    <>
      <S.Container>
        {therapeuticPlans.length === 0 ? (
          <S.NoContent>
            Paciente não tem nenhum plano terapêutico em curso
          </S.NoContent>
        ) : (
          therapeuticPlans.map((therapeuticPlan, index) => (
            <TherapeuticPlanCard key={index} />
          ))
        )}
      </S.Container>
    </>
  )
}
