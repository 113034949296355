import React, { useEffect, useState, useContext } from 'react'
import SurgicalSchedulingDurationLayout from 'presentation/doctor/layouts/SurgicalSchedulingDuration'
import { useServices } from 'presentation/hooks/use-services'
import { SurgicalSchedulinhContext } from 'main/factories/pages/doctor/surgical-scheduling/surgical-scheduling-factory'
import {
  WithLoading,
  WithLoadingProps
} from 'presentation/shared/components/HOCs/WithLoading'
import { toast } from 'react-toastify'
import { ProcedureModel } from 'domain/entities/surgical-order-model'
import HeaderNew from 'presentation/shared/components/HeaderNew'

function SurgicalSchedulingDuration({ setIsLoading }: WithLoadingProps) {
  const { dispatch, state } = useContext<any>(SurgicalSchedulinhContext)
  const [predictedTime, setPredictedTime] = useState(
    state.time?.predicted_time || '00:00'
  )
  const surgicalSchedulingService = useServices().surgicalScheduling

  const getPredictedTime = async (surgery_id: number) => {
    setIsLoading(true)
    try {
      const response = await surgicalSchedulingService.getPredictedTime({
        surgery_id,
        doctor_id: state.doctor.id
      })

      setPredictedTime(response.data?.predicted_time)
    } catch (error: any) {
      toast.error('Ocorreu um erro ao calcular o tempo estimado.')
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    if (!state.time?.predicted_time) {
      const mainProcedure = state.surgical_order?.procedures?.find(
        (procedure: ProcedureModel) => {
          return procedure.isMain
        }
      )

      getPredictedTime(mainProcedure?.surgery_id)
    }
  }, [])

  return (
    <>
      <HeaderNew actualPageTitle="Agendar cirurgia" />
      <SurgicalSchedulingDurationLayout
        predictedTime={predictedTime}
        dispatch={dispatch}
        duration={state.time}
      />
    </>
  )
}

export default WithLoading(SurgicalSchedulingDuration)
