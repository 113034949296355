import React from 'react'

import { ReactComponent as DownloadIcon } from 'presentation/assets/icons/DonwloadIcon.svg'
import { ReactComponent as TrashIcon } from 'presentation/assets/icons/trash-icon.svg'

import * as S from './styles'

import CardNew from 'presentation/shared/components/CardNew'
import SupportTextNew from 'presentation/shared/components/SupportTextNew'
import moment from 'moment-timezone'
import { DocumentsList } from 'common/enum/documents-list'

type ReportsCardProps = {
  informationAccordionOpen?: any
  documents: any
  expandedDocument?: string
  planningTab?: boolean
  showStatus?: boolean
  handleChange?: (accordeonLabel: string, isExpanded: boolean) => void
  deleteDocument?: (document_id: number) => void
  downloadDocument: (
    document_id: number,
    group_id: string,
    type: keyof typeof DocumentsList
  ) => void
}

export const ReportsCard = ({
  documents,
  planningTab,
  showStatus,
  deleteDocument,
  downloadDocument
}: ReportsCardProps) => {
  const getStatusText = (status: string, name: string) => {
    switch (status) {
      case 'APPROVED':
      case 'approved':
        return 'Validado por ' + name
      case 'ANALYZING':
      case 'analyzing':
        return 'Pendente de validação'
      case 'DISAPPROVED':
      case 'disapproved':
        return 'Invalidado por ' + name
      case 'NOT_UPLOADED':
      case 'not uploaded':
      default:
        return 'Pendente de envio'
    }
  }

  return (
    <div>
      {documents.map((document: any, index: any, file: any) => (
        <div key={index}>
          <CardNew
            shadow="none"
            background="neutral25"
            border="neutral100"
            style={{
              flexDirection: 'row',
              justifyContent: 'space-between',
              display: 'flex',
              alignItems: 'center',
              padding: '0px 16px',
              height: '48px',
              marginTop: '16px'
            }}
          >
            {!planningTab && (
              <SupportTextNew
                color="neutral800"
                textAligh="center"
                style={{
                  fontWeight: 500,
                  height: '11px'
                }}
              >
                {document.name}
              </SupportTextNew>
            )}
            {planningTab && (
              <SupportTextNew
                color="neutral800"
                textAligh="center"
                style={{ fontWeight: 500 }}
              >
                {document.name}
              </SupportTextNew>
            )}
            <S.ActionIcons>
              <DownloadIcon
                className="download-icon"
                onClick={() =>
                  downloadDocument(
                    document.document_id,
                    file.group_id,
                    document.type
                  )
                }
              />
              {deleteDocument && (
                <TrashIcon
                  className="thresh-icon"
                  onClick={() => deleteDocument(document.document_id)}
                />
              )}
            </S.ActionIcons>
          </CardNew>

          <S.DocumentDetails>
            <div>
              <SupportTextNew
                color="neutral600"
                style={{
                  marginBottom: '-21px',
                  marginTop: '8px',
                  fontSize: '10px'
                }}
              >
                Inclusão
              </SupportTextNew>
              <SupportTextNew
                color="neutral900"
                size="xsmall"
                style={{
                  color: '#2D2F34',
                  marginBottom: '-15px',
                  fontSize: '12px',
                  marginTop: '20px',
                  fontWeight: 400
                }}
              >
                {['not uploaded', 'NOT_UPLOADED'].includes(file.status)
                  ? 'Rascunho salvo em '
                  : 'Incluido em '}
                {moment.utc(file.sended_at).format('DD [de] MMM[. de] YYYY ')}
                {file.created_by?.name
                  ? `por ${file.created_by?.name
                      .split(' ')
                      .map((word: string) => {
                        return (
                          word.charAt(0).toUpperCase() +
                          word.slice(1).toLowerCase()
                        )
                      })
                      .join(' ')}`
                  : ''}
              </SupportTextNew>
            </div>
            {showStatus && (
              <div>
                <SupportTextNew
                  color="neutral600"
                  size="xxsmall"
                  style={{
                    marginBottom: '-21px',
                    marginTop: '8px',
                    fontSize: '10px'
                  }}
                >
                  Validação
                </SupportTextNew>
                <SupportTextNew
                  color="neutral900"
                  size="xsmall"
                  style={{
                    color: '#2D2F34',
                    marginBottom: '-15px',
                    fontSize: '12px',
                    marginTop: '20px',
                    fontWeight: 400
                  }}
                >
                  {getStatusText(file.status, file.status_updated_by?.name)}
                </SupportTextNew>
              </div>
            )}
          </S.DocumentDetails>
        </div>
      ))}
    </div>
  )
}
