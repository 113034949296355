import React from 'react'
import { ContainerNew } from 'presentation/shared/components/ContainerNew'
import ButtonNew from 'presentation/shared/components/ButtonNew'
import { useHistory } from 'react-router'
import SurgicalSchedulingBanner from 'presentation/assets/banners/surgical-scheduling-finish.svg'
import * as S from './styles'
import HeadingNew from 'presentation/shared/components/HeadingNew'

type Params = {
  surgicalOrderId: number
  schedulingId?: number
}

export default function SurgicalSchedulingFinishLayout({
  surgicalOrderId,
  schedulingId
}: Params) {
  const history = useHistory()

  const nextStep = () => {
    history.push('/agendamento-cirurgico/detalhes', {
      surgical_order_id: surgicalOrderId,
      scheduling_id: schedulingId
    })
  }

  const submitButton = () => {
    return (
      <ButtonNew fullWidth size="large" onClick={() => nextStep()}>
        Ver detalhes do agendamento
      </ButtonNew>
    )
  }

  return (
    <ContainerNew primaryButton={submitButton()} alignItems="center" noHeader>
      <S.Banner src={SurgicalSchedulingBanner} />

      <div>
        <HeadingNew color="primary600" size="xlarge">
          Solicitação de agendamento concluída com sucesso
        </HeadingNew>

        <S.Texts color="neutral900" size="medium">
          <p>
            Seu agendamento está <b>em análise</b> no setor de Marcação de
            Cirurgia.
          </p>
          <br />
          <p>
            Você pode ver atualizações sobre o status do agendamento na tela de{' '}
            <b>Solicitações de Agendamento</b> e receberá a{' '}
            <b>confirmação por e-mail</b>.
          </p>
        </S.Texts>
      </div>
    </ContainerNew>
  )
}
