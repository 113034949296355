import { FinishSchedule } from 'domain/usecases/scheduling/finish-schedule'
import { SchedulingRepository } from 'repository/interfaces/scheduling-repository'

export class RemoteFinishSchedule implements FinishSchedule {
  constructor(private readonly schedulingRepository: SchedulingRepository) {}

  load(params: FinishSchedule.Params): Promise<FinishSchedule.Model> {
    return this.schedulingRepository.finishSchedule(params)
  }
}

export type FinishScheduleModel = FinishSchedule.Model
