import React from 'react'
import SupportTextNew from 'presentation/shared/components/SupportTextNew'
import DividerNew from 'presentation/shared/components/DividerNew'
import CardNew from 'presentation/shared/components/CardNew'
import AccordionCardNew from 'presentation/shared/components/AccordionCardNew'
import {
  CidType,
  GuidesDetailsNew
} from 'domain/usecases/surgical-order/load-guides-by-type'
import * as S from './styles'

type Props = {
  expanded?: boolean
  handleChange?: (accordionLabel: string, isExpanded: boolean) => void
  isFinished?: boolean
  procedures: GuidesDetailsNew[]
  procedureDetails?: string
  cids: CidType[]
  icon?: React.ReactElement
  quantityAuthorized?: number
  proceduresObservation?: string
}

export default function Procedures({
  expanded,
  handleChange,
  isFinished,
  procedures,
  proceduresObservation,
  cids,
  icon
}: Props) {
  return (
    <AccordionCardNew
      accordionLabel="Procedures"
      expanded={expanded}
      handleChange={handleChange}
      header={isFinished ? 'Procedimentos autorizados' : 'Procedimentos e CID'}
      shadow="satin"
      padding="16px"
      gap="4px"
      background="neutral25"
      headerIcon={icon}
    >
      <SupportTextNew color="primary400" weight="bold">
        Procedimentos
      </SupportTextNew>
      {procedures?.map((procedure: any, index: number) => (
        <div key={index}>
          <SupportTextNew
            color="neutral800"
            style={{
              fontSize: '14px'
            }}
          >
            {procedure.description}
          </SupportTextNew>
          <S.FlexInfos>
            <div>
              <SupportTextNew
                size="xsmall"
                color="neutral800"
                weight="bold"
                style={{
                  fontSize: '12px'
                }}
              >
                Código TUSS
              </SupportTextNew>
              <SupportTextNew>{procedure.code}</SupportTextNew>
            </div>
            <S.ProcedureWrapper>
              <div>
                <SupportTextNew
                  color="neutral800"
                  weight="bold"
                  style={{
                    fontSize: '12px',
                    marginTop: '8px'
                  }}
                >
                  Quant. Solicitada
                </SupportTextNew>
                <SupportTextNew>
                  {procedure.quantity < 10 ? '0' : ''}
                  {procedure.quantity}
                </SupportTextNew>
              </div>
              <div>
                <SupportTextNew
                  color="neutral800"
                  weight="bold"
                  style={{
                    fontSize: '12px'
                  }}
                >
                  Quant. autorizada
                </SupportTextNew>
                <SupportTextNew>
                  {procedure.quantity_authorized < 10 ? '0' : ''}
                  {procedure.quantity_authorized}
                </SupportTextNew>
              </div>
            </S.ProcedureWrapper>
          </S.FlexInfos>
          <DividerNew marginBottom="8px" marginTop="8px" />
        </div>
      ))}

      <SupportTextNew color="primary400" weight="bold">
        CID
      </SupportTextNew>
      {cids?.map((cid, index: number) => (
        <div key={index}>
          <SupportTextNew
            color="neutral800"
            style={{
              fontSize: '14px'
            }}
          >
            {cid.description}
          </SupportTextNew>
          <div>
            <S.FlexInfos>
              <S.CidsWrapper>
                <div>
                  <SupportTextNew
                    size="xsmall"
                    color="neutral800"
                    weight="bold"
                    style={{
                      fontSize: '12px'
                    }}
                  >
                    Código
                  </SupportTextNew>
                  <SupportTextNew style={{ fontSize: '14px' }}>
                    {cid.code}
                  </SupportTextNew>
                </div>
              </S.CidsWrapper>
            </S.FlexInfos>
          </div>
          {index < cids.length - 1 && (
            <DividerNew marginBottom="8px" marginTop="8px" />
          )}
        </div>
      ))}
      {isFinished && (
        <CardNew
          shadow="none"
          background="neutral25"
          border="neutral100"
          gap="8px"
          style={{ marginTop: '8px' }}
        >
          <SupportTextNew color="primary400" weight="bold">
            Observações sobre procedimentos
          </SupportTextNew>
          <SupportTextNew color="neutral800" size="xsmall">
            {proceduresObservation || 'Nenhuma observação'}
          </SupportTextNew>
        </CardNew>
      )}
    </AccordionCardNew>
  )
}
