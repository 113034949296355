import SelectTherapeuticPlanForm from 'presentation/doctor/components/Forms/AddTherapeuticPlan/SelectTherapeuticPlan'
import { Container } from 'presentation/shared/components/Container'
import Header from 'presentation/shared/components/Header'
import React from 'react'

export default function SelectTherapeuticPlan() {
  return (
    <>
      <Header />
      <Container>
        <SelectTherapeuticPlanForm />
      </Container>
    </>
  )
}
