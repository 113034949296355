import { LoadSurgicalOrdersByPendencyStatus } from 'domain/usecases/surgical-pendency/load-surgical-orders-by-pendency-status'
import { SurgicalPendencyRepository } from 'repository/interfaces/surgical-pendency-repository'

export class RemoteLoadSurgicalOrdersByPendencyStatus
  implements LoadSurgicalOrdersByPendencyStatus
{
  constructor(
    private readonly surgicalPendencyRepository: SurgicalPendencyRepository
  ) {}

  async load(
    params: LoadSurgicalOrdersByPendencyStatus.Params
  ): Promise<LoadSurgicalOrdersByPendencyStatus.Model> {
    return this.surgicalPendencyRepository.loadSurgicalOrdersByPendencyStatus(
      params
    )
  }
}

export type RemoteLoadSurgicalOrdersByPendencyStatusModel =
  LoadSurgicalOrdersByPendencyStatus.Model
