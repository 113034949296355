import { makeAutoObservable } from 'mobx'
import { LoadSurgicalOrderDocument } from 'domain/usecases/surgical-order/load-surgical-order-document'
import { UploadSurgicalOrderDocument } from 'domain/usecases/surgical-order/upload-surgical-order-document'
import { LoadSurgicalOrder } from 'domain/usecases/surgical-order/load-surgical-order'
import { LoadCid } from 'domain/usecases/surgical-order/load-cid'
import { ConcludeSurgicalOrder } from 'domain/usecases/surgical-order/conclude-surgical-order'
import { DeleteSurgicalOrderSolicitation } from 'domain/usecases/surgical-order/delete-surgical-order-solicitation'
import { UploadSurgicalOrderDocumentsSameType } from 'domain/usecases/surgical-order/upload-surgical-order-documents-same-type'
import { LoadFavoriteSurgicalOrders } from 'domain/usecases/surgical-order/load-favorite-surgical-orders'
import { DeleteFavoritedSurgicalOrder } from 'domain/usecases/surgical-order/delete-favorited-surgical-order'
import { SurgicalOrderCheckin } from 'domain/usecases/surgical-order/checkin'
import { LoadFavoriteDoctorSurgicalOrders } from 'domain/usecases/surgical-order/load-favorite-doctor-order'
import { UploadToMergeDocument } from 'domain/usecases/surgical-order/upload-to-merge-document'
import { ConfirmMergeDocuments } from 'domain/usecases/surgical-order/confirm-merge-documents'
import { DeleteUploadedDocuments } from 'domain/usecases/surgical-order/delete-uploaded-documents'
import { LoadGuidesByType } from 'domain/usecases/surgical-order/load-guides-by-type'
import { LoadDashboardStatusCount } from 'domain/usecases/surgical-order/load-dashboard-status-count'
import { LoadSurgicalOrdersByDoctorAndStatus } from 'domain/usecases/surgical-order/load-surgical-orders-by-doctor-and-status'
import { LoadSurgicalOrderNoticeCard } from 'domain/usecases/surgical-order/load-surgical-orders-notice-card'
import { GetSurgicalSchedulesByDoctor } from 'domain/usecases/surgical-order/get-surgical-schedules-by-doctor'
import { GetSurgicalSchedulesCountByDoctor } from 'domain/usecases/surgical-order/get-surgical-schedules-count-by-doctor'
import { GetSurgicalOrderTimeline } from 'domain/usecases/surgical-order/get-surgical-order-timeline'
import { LoadExpirationDateStatusDashboardCounts } from 'domain/usecases/surgical-order/load-expiration-date-status-dashboard-count'
import { LoadHospitalsByDoctorCpf } from 'domain/usecases/hospital/load-hospitals-by-doctor-cpf'
import { LoadSurgeryCenter } from 'domain/usecases/surgery-center/load-surgery-center'
import { UpdateSurgicalOrderNew } from 'domain/usecases/surgical-order/update-surgical-order-new'
import { CreateSurgicalOrder } from 'domain/usecases/surgical-order/create-surgical-order'
import { GetSurgicalOrderById } from 'domain/usecases/surgical-order/get-surgical-order-by-id'
import { UploadSurgicalOrderDocumentNew } from 'domain/usecases/surgical-order/upload-surgical-order-document-new'
import { DeleteUploadedDocumentsNew } from 'domain/usecases/surgical-order/delete-uploaded-documents-new'
import { SearchProcedures } from 'domain/usecases/surgical-order/search-procedures'
import { SearchCID } from 'domain/usecases/surgical-order/search-cid'
import { LoadAnesthesiologies } from 'domain/usecases/surgical-order/load-anesthesiologies'
import { FinishSurgicalOrder } from 'domain/usecases/surgical-order/finish-surgical-order'
import { RequestSurgicalOrderCancellation } from 'domain/usecases/surgical-order/request-surgical-order-cancellation'
import { GetSurgicalOrderCancellationNotifications } from 'domain/usecases/surgical-order/get-surgical-order-cancellation-notifications'
import { GetSurgicalOrderCancellationNotificationsCount } from 'domain/usecases/surgical-order/get-surgical-order-cancellation-notifications-count'
import { AddSurgicalOrderCancellationComment } from 'domain/usecases/surgical-order/add-surgical-order-cancellation-comment'
import { ConfirmReadCancellationRequests } from 'domain/usecases/surgical-order/read-cancellation-requests/read-cancellation-requests'

type Services = {
  loadSurgicalOrder: LoadSurgicalOrder
  loadSurgicalOrderDocument: LoadSurgicalOrderDocument
  uploadSurgicalOrderDocument: UploadSurgicalOrderDocument
  loadCids: LoadCid
  concludeSurgicalOrder: ConcludeSurgicalOrder
  deleteSurgicalOrderSolicitation: DeleteSurgicalOrderSolicitation
  uploadSurgicalOrderDocumentsSameType: UploadSurgicalOrderDocumentsSameType
  loadFavoriteSurgicalOrders: LoadFavoriteSurgicalOrders
  loadFavoriteDoctorSurgicalOrders: LoadFavoriteDoctorSurgicalOrders
  deleteFavoritedSurgicalOrder: DeleteFavoritedSurgicalOrder
  checkin: SurgicalOrderCheckin
  uploadToMergeDocument: UploadToMergeDocument
  confirmMergeDocuments: ConfirmMergeDocuments
  deleteUploadedDocuments: DeleteUploadedDocuments
  loadGuidesByType: LoadGuidesByType
  loadDashboardStatusCount: LoadDashboardStatusCount
  loadSurgicalOrdersByDoctorAndStatus: LoadSurgicalOrdersByDoctorAndStatus
  loadSurgicalOrderNoticeCard: LoadSurgicalOrderNoticeCard
  getSurgicalSchedulesByDoctor: GetSurgicalSchedulesByDoctor
  getSurgicalSchedulesCountByDoctor: GetSurgicalSchedulesCountByDoctor
  getSurgicalOrderTimeline: GetSurgicalOrderTimeline
  loadExpirationDateStatusDashboardCounts: LoadExpirationDateStatusDashboardCounts
  loadHospitalsByDoctorCpf: LoadHospitalsByDoctorCpf
  loadSurgeryCenters: LoadSurgeryCenter
  updateSurgicalOrderNew: UpdateSurgicalOrderNew
  createSurgicalOrder: CreateSurgicalOrder
  getSurgicalOrderById: GetSurgicalOrderById
  uploadSurgicalOrderDocumentNew: UploadSurgicalOrderDocumentNew
  deleteUploadedDocumentsNew: DeleteUploadedDocumentsNew
  searchProcedures: SearchProcedures
  searchCID: SearchCID
  loadAnesthesiologies: LoadAnesthesiologies
  finishSurgicalOrder: FinishSurgicalOrder
  requestSurgicalOrderCancellation: RequestSurgicalOrderCancellation
  getSurgicalOrderCancellationNotifications: GetSurgicalOrderCancellationNotifications
  getSurgicalOrderCancellationNotificationsCount: GetSurgicalOrderCancellationNotificationsCount
  addSurgicalOrderCancellationComment: AddSurgicalOrderCancellationComment
  confirmReadCancellationRequests: ConfirmReadCancellationRequests
}

export class SurgicalOrderService {
  constructor(private readonly services: Services) {
    makeAutoObservable(this)
  }

  async loadSurgicalOrderDocument(
    params: LoadSurgicalOrderDocument.Params
  ): Promise<LoadSurgicalOrderDocument.Model> {
    return this.services.loadSurgicalOrderDocument.load(
      params.surgical_order_id,
      params.document_id
    )
  }

  async loadGuidesByType(
    surgical_order_id: number,
    type: string,
    params: LoadGuidesByType.Params
  ): Promise<LoadGuidesByType.Model> {
    return this.services.loadGuidesByType.load(surgical_order_id, type, params)
  }

  async uploadSurgicalOrderDocument(
    params: UploadSurgicalOrderDocument.Params
  ): Promise<UploadSurgicalOrderDocument.Model> {
    return this.services.uploadSurgicalOrderDocument.upload(params)
  }

  async loadSurgicalOrder(
    id: number,
    params: LoadSurgicalOrder.Params
  ): Promise<LoadSurgicalOrder.Model> {
    return this.services.loadSurgicalOrder.load(id, params)
  }

  async loadCids(params?: LoadCid.Params): Promise<LoadCid.Model> {
    return this.services.loadCids.load(params)
  }

  async concludeSurgicalOrder(
    params: ConcludeSurgicalOrder.Params
  ): Promise<ConcludeSurgicalOrder.Model> {
    return this.services.concludeSurgicalOrder.conclude(params)
  }

  async deleteSurgicalOrderSolicitation(
    params: DeleteSurgicalOrderSolicitation.Params
  ): Promise<DeleteSurgicalOrderSolicitation.Model> {
    return this.services.deleteSurgicalOrderSolicitation.delete(params)
  }

  async uploadSurgicalOrderDocumentsSameType(
    params: UploadSurgicalOrderDocumentsSameType.Params
  ): Promise<UploadSurgicalOrderDocumentsSameType.Model> {
    return this.services.uploadSurgicalOrderDocumentsSameType.upload(params)
  }

  async uploadToMergeDocument(
    params: UploadToMergeDocument.Params
  ): Promise<UploadToMergeDocument.Model> {
    return this.services.uploadToMergeDocument.upload(params)
  }

  async loadFavoriteSurgicalOrders(
    params: LoadFavoriteSurgicalOrders.Params
  ): Promise<LoadFavoriteSurgicalOrders.Model> {
    return this.services.loadFavoriteSurgicalOrders.load(params)
  }

  async loadFavoriteDoctorSurgicalOrders(
    params: LoadFavoriteDoctorSurgicalOrders.Params
  ): Promise<LoadFavoriteDoctorSurgicalOrders.Model> {
    return this.services.loadFavoriteDoctorSurgicalOrders.load(params)
  }

  async deleteFavoritedSurgicalOrder(
    params: DeleteFavoritedSurgicalOrder.Params
  ): Promise<DeleteFavoritedSurgicalOrder.Model> {
    return this.services.deleteFavoritedSurgicalOrder.delete(params)
  }

  async checkin(
    params: SurgicalOrderCheckin.Params
  ): Promise<SurgicalOrderCheckin.Model> {
    return this.services.checkin.checkin(params)
  }

  async confirmMergeDocuments(
    params: ConfirmMergeDocuments.Params
  ): Promise<ConfirmMergeDocuments.Model> {
    return this.services.confirmMergeDocuments.confirm(params)
  }

  async deleteUploadedDocuments(
    params: DeleteUploadedDocuments.Params
  ): Promise<DeleteUploadedDocuments.Model> {
    return this.services.deleteUploadedDocuments.delete(params)
  }

  async loadDashboardStatusCount(): Promise<LoadDashboardStatusCount.Model> {
    return this.services.loadDashboardStatusCount.load()
  }

  async loadSurgicalOrdersByDoctorAndStatus(
    params: LoadSurgicalOrdersByDoctorAndStatus.Params
  ): Promise<LoadSurgicalOrdersByDoctorAndStatus.Model> {
    return this.services.loadSurgicalOrdersByDoctorAndStatus.load(params)
  }

  async loadSurgicalOrderNoticeCard(
    params: LoadSurgicalOrderNoticeCard.Params
  ): Promise<LoadSurgicalOrderNoticeCard.Model> {
    return this.services.loadSurgicalOrderNoticeCard.load(params)
  }

  async getSurgicalSchedulesByDoctor(
    params: GetSurgicalSchedulesByDoctor.Params
  ): Promise<GetSurgicalSchedulesByDoctor.Model> {
    return this.services.getSurgicalSchedulesByDoctor.getSurgicalSchedulesByDoctor(
      params
    )
  }

  async getSurgicalSchedulesCountByDoctor(): Promise<GetSurgicalSchedulesCountByDoctor.Model> {
    return this.services.getSurgicalSchedulesCountByDoctor.getSurgicalSchedulesCountByDoctor()
  }

  async getSurgicalOrderTimeline(
    params: GetSurgicalOrderTimeline.Params
  ): Promise<GetSurgicalOrderTimeline.Model> {
    return this.services.getSurgicalOrderTimeline.load(params)
  }

  async loadExpirationDateStatusDashboardCounts(): Promise<LoadExpirationDateStatusDashboardCounts.Model> {
    return this.services.loadExpirationDateStatusDashboardCounts.load()
  }

  async loadHospitalsByDoctorCpf(
    cpf: string,
    params: LoadHospitalsByDoctorCpf.Params
  ): Promise<LoadHospitalsByDoctorCpf.Model> {
    return this.services.loadHospitalsByDoctorCpf.load(cpf, params)
  }

  async loadSurgeryCenters(
    params: LoadSurgeryCenter.Params
  ): Promise<LoadSurgeryCenter.Model> {
    return this.services.loadSurgeryCenters.load(params)
  }

  async updateSurgicalOrder(
    params: UpdateSurgicalOrderNew.Params
  ): Promise<UpdateSurgicalOrderNew.Model> {
    return this.services.updateSurgicalOrderNew.update(params)
  }

  async createSurgicalOrder(
    params: CreateSurgicalOrder.Params
  ): Promise<CreateSurgicalOrder.Model> {
    return this.services.createSurgicalOrder.create(params)
  }

  async getSurgicalOrderById(
    params: GetSurgicalOrderById.Params
  ): Promise<GetSurgicalOrderById.Model> {
    return this.services.getSurgicalOrderById.get(params)
  }

  async finishSurgicalOrder(
    params: FinishSurgicalOrder.Params
  ): Promise<FinishSurgicalOrder.Model> {
    return this.services.finishSurgicalOrder.post(params)
  }

  async uploadSurgicalOrderDocumentNew(
    params: UploadSurgicalOrderDocumentNew.Params
  ): Promise<UploadSurgicalOrderDocumentNew.Model> {
    return this.services.uploadSurgicalOrderDocumentNew.upload(params)
  }

  async deleteUploadedDocumentsNew(
    params: DeleteUploadedDocumentsNew.Params
  ): Promise<DeleteUploadedDocumentsNew.Model> {
    return this.services.deleteUploadedDocumentsNew.delete(params)
  }

  async searchProcedures(
    params: SearchProcedures.Params
  ): Promise<SearchProcedures.Model> {
    return this.services.searchProcedures.load(params)
  }

  async searchCID(params: SearchCID.Params): Promise<SearchCID.Model> {
    return this.services.searchCID.load(params)
  }

  async loadAnesthesiologies(): Promise<LoadAnesthesiologies.Model> {
    return this.services.loadAnesthesiologies.load()
  }

  async requestSurgicalOrderCancellation(
    params: RequestSurgicalOrderCancellation.Params
  ): Promise<RequestSurgicalOrderCancellation.Model> {
    return this.services.requestSurgicalOrderCancellation.post(params)
  }

  async getSurgicalOrderCancellationNotifications(): Promise<GetSurgicalOrderCancellationNotifications.Model> {
    return this.services.getSurgicalOrderCancellationNotifications.get()
  }

  async getSurgicalOrderCancellationNotificationsCount(): Promise<GetSurgicalOrderCancellationNotificationsCount.Model> {
    return this.services.getSurgicalOrderCancellationNotificationsCount.get()
  }

  async addSurgicalOrderCancellationComment(
    params: AddSurgicalOrderCancellationComment.Params
  ): Promise<AddSurgicalOrderCancellationComment.Model> {
    return this.services.addSurgicalOrderCancellationComment.post(params)
  }

  async confirmReadCancellationRequests(): Promise<ConfirmReadCancellationRequests.Model> {
    return this.services.confirmReadCancellationRequests.put()
  }
}

export default SurgicalOrderService
