import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { toast } from 'react-toastify'

import { useServices } from 'presentation/hooks/use-services'
import { useStores } from 'presentation/hooks/use-stores'
import { ContainerNew } from 'presentation/shared/components/ContainerNew'
import ButtonNew from 'presentation/shared/components/ButtonNew'
import { ReactComponent as EditIcon } from 'presentation/assets/icons/pencil-new.svg'
import { ReactComponent as CheckIcon } from 'presentation/assets/icons/check_icon.svg'
import { ReactComponent as CalendarIcon } from 'presentation/assets/icons/calendar-icon.svg'
import * as S from './styles'
import { isPatientRN } from 'presentation/utils/validators/is-patient-rn'
import moment from 'moment-timezone'
import { isValidDate } from 'presentation/utils/validators/is-valid-data'
import { capitalizeEachWordText } from 'common/utils/capitalizeEachWordText'
import {
  WithLoading,
  WithLoadingProps
} from 'presentation/shared/components/HOCs/WithLoading'
// import { Patient } from 'domain/entities/patient-model'
import { Booleans } from 'common/enum/booleans'
import { HospitalizationType } from 'common/enum/hospitalization-type'
import { Techniques } from 'common/enum/techniques'
import capitalizeFirstLetters from 'common/utils/capitalizeFirstLetters'
import { HospitalizationMode } from 'common/enum/hospitalization-mode'
import { SurgicalOrderOpmeModel } from 'domain/entities/surgical-order-opme-model'
import { SurgicalOrderCidModel } from 'domain/entities/surgical-order-cid-model'
import { Patient } from 'domain/entities/patient-model'
import DocumentAccordeon from 'presentation/shared/layouts/DocumentAccordeon'
import { DocumentsList } from 'common/enum/documents-list'
import { Document } from 'domain/entities/document'
import downloadFileFromBlob from 'common/utils/downloadFileFromBlob'
import { renameFile } from 'common/utils/file/renameFile'
import { OpenSurgicalOrderPatientDocument } from 'common/enum/patient-document'
import { formatCpf } from 'common/utils/format-cpf'
import { formatPhoneWithParenthesis } from 'presentation/utils/mobile-actions/formart-phone'
import { formatPhone } from 'presentation/utils/masks'

const ReviewSurgicalOrderLayout = WithLoading(
  ({ setIsLoading }: WithLoadingProps) => {
    const history = useHistory()
    const surgicalOrderService = useServices().surgicalOrder
    const surgicalOrderContext = useStores().surgicalOrder
    const surgicalOrderObjContext = useStores().surgicalOrder.getSurgicalOrder()
    const [informationAccordionOpen, setInformationAccordionOpen] = useState('')

    const hospital = surgicalOrderContext.location.hospital
    const surgeryCenter = surgicalOrderContext.location.surgeryCenter
    const patient = surgicalOrderObjContext?.patient
    const patientBirthday =
      patient?.birthday && moment(patient?.birthday).format('DD/MM/YYYY')
    const isRNPatient = () =>
      isValidDate(patientBirthday) && isPatientRN(patientBirthday)
    const procedures = surgicalOrderObjContext.procedures
    const healthInsurance = surgicalOrderObjContext.health_insurance
    const cid = surgicalOrderObjContext.cids
    const opme = surgicalOrderObjContext.opme
    const anesthesiologies = surgicalOrderObjContext.anesthesiologies
    const clinicalHistory = surgicalOrderObjContext?.clinical_history
    const clinicalRecord = surgicalOrderObjContext?.clinical_record
    const expectedDate = surgicalOrderObjContext?.expected_date
    const cooperative = surgicalOrderObjContext?.cooperative_name
    const reportsRecommendations =
      surgicalOrderObjContext?.report_recommendation
    const HOSPITAL_SALVADOR_ID = 8

    if (!surgicalOrderObjContext?.surgical_order_id) {
      toast.error('Ocorreu um erro ao carregar o pedido cirúrgico')
      history.push('/home')
    }

    const getSurgicalOrderById = async () => {
      try {
        setIsLoading(true)

        if (!surgicalOrderObjContext?.surgical_order_id) {
          return
        }

        const response = await surgicalOrderService.getSurgicalOrderById({
          surgical_order_id: surgicalOrderObjContext?.surgical_order_id
        })

        surgicalOrderContext.setLocation({
          hospital: response.hospital,
          surgeryCenter: {
            surgery_center_id: response.surgical_center?.surgical_center_id,
            name: response.surgical_center?.surgical_center
          }
        })

        surgicalOrderContext.setPatient({
          ...surgicalOrderContext.patient,
          patient: response?.patient as Patient
        })

        surgicalOrderContext.setProcedures({
          ...surgicalOrderContext.procedures,
          procedures: response.procedures!,
          cids: response.cids as SurgicalOrderCidModel[]
        })

        surgicalOrderContext.setHealthInsurance({
          healthInsurance: {
            health_insurance_id:
              response?.health_insurance?.health_insurance_id,
            code: response?.health_insurance?.health_insurance_code,
            description: response?.health_insurance?.health_insurance_name,
            health_insurance_card:
              response?.health_insurance.health_insurance_card
          }
        })

        surgicalOrderContext.setOpme(response?.opme as SurgicalOrderOpmeModel)

        surgicalOrderContext.setAnesthesiology(response?.anesthesiologies)

        surgicalOrderContext.setSurgicalOrder(response)
      } catch (error: any) {
        toast.error(error?.message)
        history.push('/home')
      } finally {
        setIsLoading(false)
      }
    }

    const booleansLabel = (value?: boolean) => {
      return Booleans[String(value) as keyof typeof Booleans] || '-'
    }

    const handleSubmit = async () => {
      try {
        setIsLoading(true)
        const response = await surgicalOrderService.finishSurgicalOrder({
          surgical_order_id: surgicalOrderObjContext?.surgical_order_id
        })

        surgicalOrderContext.setSurgicalOrder(response)

        history.push('/novo-pedido/criado', {
          surgical_order_id: response.surgical_order_id
        })
      } catch (error: any) {
        toast.error(error?.message)
      } finally {
        setIsLoading(false)
      }
    }

    const handleChangeInformationAccordion = (
      label: string,
      isExpanded: boolean
    ) => {
      setInformationAccordionOpen(!isExpanded ? '' : label)
    }

    const documentsList = () => {
      const mappedDocs = mappedDocuments()

      return Object.keys(OpenSurgicalOrderPatientDocument).map((document) => {
        const docs = mappedDocs
          .filter((doc: any) => doc.type === document)
          .sort((a: any, b: any) => {
            if (moment(a.sendedAt).isAfter(b.sendedAt)) return -1
            else if (moment(a.sendedAt).isBefore(b.sendedAt)) return 1
            else return 0
          })

        const filesList = docs.reduce(
          (acc: any, curr: any) => [...acc, ...curr.documents],
          []
        )

        return {
          label: DocumentsList[document as keyof typeof DocumentsList],
          type: document,
          status: docs[0]?.status,
          documents: filesList
        }
      })
    }

    const mappedDocuments = () => {
      return (
        surgicalOrderContext.surgicalOrder.documents?.map(
          (document: Document) => ({
            type: document.type,
            status: document.status,
            documents: [
              {
                ...document,
                sended_at: document.sendedAt,
                status_updated_by: {
                  name: document.updated_by?.name
                }
              }
            ]
          })
        ) || []
      )
    }

    const uploadDocument = async (files: any, type: string) => {
      setIsLoading(true)
      try {
        const file = files[0]
        const form = new FormData()
        const newFile = renameFile(file, file.name)
        form.append('file', newFile)
        form.append('type', type)
        form.append(
          'surgical_order_id',
          String(surgicalOrderContext.surgicalOrder.surgical_order_id)
        )

        await surgicalOrderService.uploadSurgicalOrderDocumentNew({
          form: form
        })
        await getSurgicalOrderById()
      } catch (error: any) {
        toast.error(error?.message)
      } finally {
        setIsLoading(false)
      }
    }

    const deleteDocument = async (document_id: number) => {
      setIsLoading(true)
      try {
        await surgicalOrderService.deleteUploadedDocumentsNew({
          document_id
        })

        await getSurgicalOrderById()
      } catch (error: any) {
        toast.error(error?.message)
      } finally {
        setIsLoading(false)
      }
    }

    const downloadDocument = async (
      document_id: number,
      _group_id: string,
      type: keyof typeof DocumentsList
    ) => {
      setIsLoading(true)
      try {
        const surgicalOrderId = surgicalOrderContext.surgicalOrder
          .surgical_order_id as number

        const file = await surgicalOrderService.loadSurgicalOrderDocument({
          surgical_order_id: surgicalOrderId,
          document_id
        })

        if (file) {
          downloadFileFromBlob(file.data, file.contentType, DocumentsList[type])
        }
      } catch (error: any) {
        toast.error(error?.message)
      } finally {
        setIsLoading(false)
      }
    }

    useEffect(() => {
      getSurgicalOrderById()
    }, [])

    const documentsNotToShow = [
      'LAUDO DE EXAME',
      'PLANEJAMENTO CIRÚRGICO',
      'Guia de autorização'
    ]

    const checkIfAllDataIsCompleted = () => {
      const hasPatientData = !!surgicalOrderContext?.patient?.patient
      const hasProceduresData =
        !!surgicalOrderContext?.procedures?.procedures &&
        !!surgicalOrderContext?.procedures?.cids
      const hasOpmeData = !!surgicalOrderContext.opme || !null
      const hasClinicalHistoryData =
        !!surgicalOrderContext?.surgicalOrder?.clinical_history
          ?.clinical_history
      const hasClinicalRecordData =
        !!surgicalOrderContext?.surgicalOrder?.clinical_record?.cti !== null

      let hasCooperativeData = true
      if (
        surgicalOrderContext?.surgicalOrder?.hospital_id ===
        HOSPITAL_SALVADOR_ID
      ) {
        hasCooperativeData =
          !!surgicalOrderContext?.surgicalOrder?.cooperative_name
      }

      return (
        hasPatientData &&
        hasProceduresData &&
        hasOpmeData &&
        hasClinicalHistoryData &&
        hasClinicalRecordData &&
        hasCooperativeData
      )
    }

    return (
      <ContainerNew
        title="Resumo do pedido"
        subtitle="Revise as informações do pedido cirúrgico para a análise da CRMO."
        titleColor="primary600"
        buttonsHasBoxShadow
        primaryButton={
          <ButtonNew
            fullWidth
            size="large"
            onClick={handleSubmit}
            disabled={!checkIfAllDataIsCompleted()}
          >
            Enviar pedido cirúrgico
          </ButtonNew>
        }
      >
        <S.Wrapper>
          <S.CardInfo>
            <div className="c-header">
              <h5 className="c-title">Local</h5>
              <ButtonNew
                className="btn-edit"
                icon={<EditIcon />}
                variant="text"
                fontSize="xsmall"
                noPadding
                onClick={() =>
                  history.push('/novo-pedido/local', { isEditing: true })
                }
              >
                Editar
              </ButtonNew>
            </div>
            <div className="c-content">
              <S.BoxInfo>
                <h6 className="label">Unidade</h6>
                <p className="text">{hospital?.name || '-'}</p>
              </S.BoxInfo>
              <S.BoxInfo>
                <h6 className="label">Centro</h6>
                <p className="text">
                  {(surgeryCenter?.name &&
                    capitalizeEachWordText(surgeryCenter.name)) ||
                    '-'}
                </p>
              </S.BoxInfo>
            </div>
          </S.CardInfo>

          <S.CardInfo>
            <div className="c-header">
              <h6 className="c-title">
                {isRNPatient() ? 'Paciente recém nascido' : 'Paciente'}
              </h6>
              <ButtonNew
                className="btn-edit"
                icon={<EditIcon />}
                variant="text"
                fontSize="xsmall"
                noPadding
                onClick={() =>
                  history.push('/novo-pedido/paciente', { isEditing: true })
                }
              >
                Editar
              </ButtonNew>
            </div>
            <div className="c-content">
              {isRNPatient() ? (
                <>
                  <S.BoxInfo>
                    <h6 className="label">Data de nascimento</h6>
                    <p className="text">{patientBirthday || '-'}</p>
                  </S.BoxInfo>
                  <S.BoxInfo>
                    <h6 className="label">Nome da mãe</h6>
                    <p className="text">
                      {(patient?.patient_name &&
                        capitalizeEachWordText(patient.patient_name)) ||
                        '-'}
                    </p>
                  </S.BoxInfo>
                </>
              ) : (
                <>
                  <S.BoxInfo>
                    <h6 className="label">Nome do paciente</h6>
                    <p className="text">
                      {(patient?.patient_name &&
                        capitalizeEachWordText(patient.patient_name)) ||
                        '-'}
                    </p>
                  </S.BoxInfo>
                  <S.BoxInfo>
                    <h6 className="label">CPF</h6>
                    <p className="text">
                      {patient?.user?.cpf ? formatCpf(patient.user.cpf) : '-'}
                    </p>
                  </S.BoxInfo>
                </>
              )}

              {patient?.user?.phone && (
                <S.BoxInfo>
                  <h6 className="label">Celular</h6>
                  <p className="text">
                    {formatPhoneWithParenthesis(
                      formatPhone(patient?.user?.phone)
                    )}
                  </p>
                </S.BoxInfo>
              )}

              <S.BoxInfo>
                <h6 className="label">Convênio</h6>
                <p className="text">
                  {(healthInsurance?.health_insurance_name &&
                    capitalizeEachWordText(
                      healthInsurance.health_insurance_name
                    )) ||
                    '-'}
                </p>
              </S.BoxInfo>
            </div>
          </S.CardInfo>

          {hospital?.hospital_id === HOSPITAL_SALVADOR_ID && (
            <S.CardInfo>
              <div className="c-header">
                <h6 className="c-title">Cooperativa</h6>
                <ButtonNew
                  className="btn-edit"
                  icon={<EditIcon />}
                  variant="text"
                  fontSize="xsmall"
                  noPadding
                  onClick={() =>
                    history.push('/novo-pedido/cooperativa', {
                      isEditing: true
                    })
                  }
                >
                  Editar
                </ButtonNew>
              </div>
              <div className="c-content">
                <S.BoxInfo>
                  <h6 className="label">Cooperativa</h6>
                  <p className="text">{cooperative || '-'}</p>
                </S.BoxInfo>
              </div>
            </S.CardInfo>
          )}

          <S.CardInfo>
            <div className="c-header">
              <h6 className="c-title">Procedimentos</h6>
              <ButtonNew
                className="btn-edit"
                icon={<EditIcon />}
                variant="text"
                fontSize="xsmall"
                noPadding
                onClick={() =>
                  history.push('/novo-pedido/procedimentos', {
                    isEditing: true
                  })
                }
              >
                Editar
              </ButtonNew>
            </div>
            <div className="c-content">
              {procedures ? (
                procedures?.map((item, index) => (
                  <S.BoxInfoWithScroll key={index}>
                    <h6 className="text -large capitalize-fl">
                      {item?.description}
                    </h6>
                    <S.FlexInfos>
                      <S.FlexWrapper>
                        <div>
                          <p className="item-wrapper">Código TUSS</p>
                          <p className="item-result">{item?.procedure_code}</p>
                        </div>
                        <div>
                          <p className="item-wrapper">Quantidade</p>
                          <p className="item-result">
                            {item?.quantity < 10 ? '0' : ''}
                            {item?.quantity}
                          </p>
                        </div>
                      </S.FlexWrapper>
                    </S.FlexInfos>
                  </S.BoxInfoWithScroll>
                ))
              ) : (
                <S.BoxInfo style={{ textAlign: 'center' }}>
                  <p className="label">Os dados não foram preenchidos</p>
                </S.BoxInfo>
              )}
            </div>
          </S.CardInfo>

          <S.CardInfo>
            <div className="c-header">
              <h6 className="c-title">CID</h6>
              <ButtonNew
                className="btn-edit"
                icon={<EditIcon />}
                variant="text"
                fontSize="xsmall"
                noPadding
                onClick={() =>
                  history.push('/novo-pedido/procedimentos', {
                    isEditing: true
                  })
                }
              >
                Editar
              </ButtonNew>
            </div>
            <div className="c-content">
              {cid ? (
                cid?.map((item, index) => (
                  <S.BoxInfo key={index}>
                    <h6 className="text -large capitalize-fl">
                      {item?.description}
                    </h6>
                    <p className="label">CID</p>
                    <p className="item-result">{item?.code}</p>
                  </S.BoxInfo>
                ))
              ) : (
                <S.BoxInfo style={{ textAlign: 'center' }}>
                  <p className="label">Os dados não foram preenchidos</p>
                </S.BoxInfo>
              )}
            </div>
          </S.CardInfo>

          <S.CardInfo>
            <div className="c-header">
              <h6 className="c-title">OPME</h6>
              <ButtonNew
                className="btn-edit"
                icon={<EditIcon />}
                variant="text"
                fontSize="xsmall"
                noPadding
                onClick={() =>
                  history.push('/novo-pedido/opme', { isEditing: true })
                }
              >
                Editar
              </ButtonNew>
            </div>
            <div className="c-content">
              <S.BoxInfo>
                <h6 className="label">O procedimento irá precisar de OPME?</h6>
                <p className="text">
                  {opme?.solicitations && opme?.solicitations.length > 0
                    ? 'Sim'
                    : 'Não'}
                </p>
              </S.BoxInfo>
              {opme?.solicitations &&
                opme?.solicitations?.map((item, index) => (
                  <S.BoxInfo key={index}>
                    <h6 className="text -large capitalize-fl">
                      {item?.description}
                    </h6>
                    <p className="item-wrapper">Quantidade</p>
                    <p className="item-result">
                      {item?.quantity < 10 ? '0' : ''}
                      {item?.quantity}
                    </p>
                  </S.BoxInfo>
                ))}
            </div>
          </S.CardInfo>

          {opme?.providers && opme?.providers.length > 0 && (
            <S.CardInfo>
              <div className="c-header">
                <h6 className="c-title">Fornecedor</h6>
                <ButtonNew
                  className="btn-edit"
                  icon={<EditIcon />}
                  variant="text"
                  fontSize="xsmall"
                  noPadding
                  onClick={() =>
                    history.push('/novo-pedido/opme', { isEditing: true })
                  }
                >
                  Editar
                </ButtonNew>
              </div>
              <div className="c-content">
                {opme.providers.map((item, index) => (
                  <S.BoxInfo key={index}>
                    <h6 className="label">Fornecedor</h6>
                    <p className="text">{item}</p>
                  </S.BoxInfo>
                ))}
              </div>
            </S.CardInfo>
          )}

          {anesthesiologies && (
            <S.CardInfo>
              <div className="c-header">
                <h6 className="c-title">Anestesiologia</h6>
                <ButtonNew
                  className="btn-edit"
                  icon={<EditIcon />}
                  variant="text"
                  fontSize="xsmall"
                  noPadding
                  onClick={() =>
                    history.push('/novo-pedido/anestesiologia', {
                      isEditing: true
                    })
                  }
                >
                  Editar
                </ButtonNew>
              </div>
              <div className="c-content">
                {anesthesiologies && anesthesiologies?.length > 0 ? (
                  <S.BoxInfo>
                    <h6 className="label">Itens incluídos</h6>
                    <ul className="list">
                      {anesthesiologies?.map((item, index) => (
                        <li key={index} className="item text">
                          <CheckIcon /> {item?.name}
                        </li>
                      ))}
                    </ul>
                  </S.BoxInfo>
                ) : (
                  <S.BoxInfo style={{ textAlign: 'center' }}>
                    <p className="label">Os dados não foram preenchidos</p>
                  </S.BoxInfo>
                )}
              </div>
            </S.CardInfo>
          )}

          <S.CardInfo>
            <div className="c-header">
              <h6 className="c-title">História Clínica</h6>
              <ButtonNew
                className="btn-edit"
                icon={<EditIcon />}
                variant="text"
                fontSize="xsmall"
                noPadding
                onClick={() =>
                  history.push('/novo-pedido/historia-clinica', {
                    isEditing: true
                  })
                }
              >
                Editar
              </ButtonNew>
            </div>
            <div className="c-content">
              {clinicalHistory?.allergy ? (
                <>
                  <S.BoxInfo>
                    <h6 className="label">Observação geral</h6>
                    <p className="text">
                      {clinicalHistory?.clinical_history || '-'}
                    </p>
                  </S.BoxInfo>
                  <S.BoxInfo>
                    <h6 className="label">Paciente possui alergia a látex?</h6>
                    <p className="text">
                      {clinicalHistory?.allergy
                        ? clinicalHistory?.allergy[0]
                        : '-'}
                    </p>
                  </S.BoxInfo>
                  <S.BoxInfo>
                    <h6 className="label">
                      Paciente possui alguma outra alergia?
                    </h6>
                    <p className="text">
                      {(clinicalHistory?.allergy &&
                        clinicalHistory?.allergy[1]) ||
                        '-'}
                    </p>
                  </S.BoxInfo>
                  <S.BoxInfo>
                    <h6 className="label">
                      Paciente tem restrição a transfusão sanguínea?
                    </h6>
                    <p className="text">
                      {booleansLabel(clinicalHistory?.blood_transfusion) || '-'}
                    </p>
                  </S.BoxInfo>
                  <S.BoxInfo>
                    <h6 className="label">
                      Paciente oncológico ou investigação?
                    </h6>
                    <p className="text">
                      {booleansLabel(clinicalRecord?.oncology_or_research) ||
                        '-'}
                    </p>
                  </S.BoxInfo>
                  {clinicalHistory?.psychological_aspects && (
                    <S.BoxInfo>
                      <h6 className="label">
                        Aspectos psicológicos, socioeconômicos,
                        espirituais/culturais relevantes a serem considerados?
                        (Descreva)
                      </h6>
                      <p className="text">
                        {clinicalHistory?.psychological_aspects}
                      </p>
                    </S.BoxInfo>
                  )}
                </>
              ) : (
                <S.BoxInfo style={{ textAlign: 'center' }}>
                  <p className="label">Os dados não foram preenchidos</p>
                </S.BoxInfo>
              )}
            </div>
          </S.CardInfo>

          <S.CardInfo>
            <div className="c-header">
              <h6 className="c-title">Ficha clínica</h6>
              <ButtonNew
                className="btn-edit"
                icon={<EditIcon />}
                variant="text"
                fontSize="xsmall"
                noPadding
                onClick={() =>
                  history.push('/novo-pedido/historia-clinica', {
                    isEditing: true
                  })
                }
              >
                Editar
              </ButtonNew>
            </div>
            <div className="c-content">
              {clinicalRecord?.cti !== null ? (
                <>
                  <S.GridWrapper>
                    <div
                      style={{
                        height: '30px',
                        marginTop: '8px'
                      }}
                    >
                      <S.BoxInfo>
                        <h6 className="item-title-grid">CTI</h6>
                        <p className="item-grid">
                          {booleansLabel(clinicalRecord?.cti)}
                        </p>
                      </S.BoxInfo>
                    </div>
                    <S.BoxInfo>
                      <h6 className="item-title-grid">Reserva de sangue</h6>
                      <p className="item-grid">
                        {booleansLabel(clinicalRecord?.extra_blood)}
                      </p>
                    </S.BoxInfo>
                    <S.BoxInfo>
                      <h6 className="item-title-grid">Paciente internado</h6>
                      <p className="item-grid">
                        {booleansLabel(clinicalRecord?.patient_hospitalized)}
                      </p>
                    </S.BoxInfo>
                    <S.BoxInfo>
                      <h6 className="item-title-grid">Local da internação</h6>
                      <p className="item-grid">
                        {(clinicalRecord?.locale_hospitalization &&
                          capitalizeFirstLetters(
                            clinicalRecord.locale_hospitalization
                          )) ||
                          '-'}
                      </p>
                    </S.BoxInfo>
                    <S.BoxInfo>
                      <h6 className="item-title-grid">Regime hospitalar</h6>
                      <p className="item-grid">
                        {HospitalizationMode[
                          clinicalRecord?.hospitalization_mode as keyof typeof HospitalizationMode
                        ] || '-'}
                      </p>
                    </S.BoxInfo>
                    <S.BoxInfo>
                      <h6 className="item-title-grid">Previsão de alta</h6>
                      <p className="item-grid">
                        {(clinicalRecord?.bullish_forecast_date &&
                          moment(clinicalRecord.bullish_forecast_date)
                            .utc()
                            .format('DD/MM/YYYY')) ||
                          '-'}
                      </p>
                    </S.BoxInfo>
                    <S.BoxInfo>
                      <h6 className="item-title-grid">Quantidade diárias</h6>
                      <p className="item-grid">
                        {Number(clinicalRecord?.requested_dailies) > -1
                          ? Number(clinicalRecord?.requested_dailies)
                          : '-'}
                      </p>
                    </S.BoxInfo>
                    <S.BoxInfo>
                      <h6 className="item-title-grid">Caráter</h6>
                      <p className="item-grid">
                        {HospitalizationType[
                          clinicalRecord?.hospitalization_type as keyof typeof HospitalizationType
                        ] || '-'}
                      </p>
                    </S.BoxInfo>
                    <S.BoxInfo>
                      <h6 className="item-title-grid">Técnica</h6>
                      <p className="item-grid">
                        {Techniques[
                          clinicalRecord?.technique as keyof typeof Techniques
                        ] || '-'}
                      </p>
                    </S.BoxInfo>
                    <S.BoxInfo>
                      <h6 className="item-title-grid">Congelação</h6>
                      <p className="item-grid">
                        {booleansLabel(clinicalRecord?.freeze)}
                      </p>
                    </S.BoxInfo>
                  </S.GridWrapper>
                  {expectedDate && (
                    <S.BoxInfo>
                      <h6 className="label">Data prevista</h6>
                      <div className="calendar-container">
                        <CalendarIcon />
                        <p className="text">
                          {moment(expectedDate)
                            .locale('pt-br')
                            .format('dddd - DD MMM [de] YYYY') || '-'}
                        </p>
                      </div>
                      <p className="text -small">
                        Esta data <b>não garante</b> o agendamento da cirurgia,
                        solicite o agendamento a qualquer momento na tela de{' '}
                        <b>Agendamentos.</b>
                      </p>
                    </S.BoxInfo>
                  )}
                  {clinicalRecord?.medical_observation && (
                    <S.BoxInfo>
                      <h6 className="label">Observações médicas</h6>
                      <p className="text">
                        {clinicalRecord?.medical_observation || '-'}
                      </p>
                    </S.BoxInfo>
                  )}
                </>
              ) : (
                <S.BoxInfo style={{ textAlign: 'center' }}>
                  <p className="label">Os dados não foram preenchidos</p>
                </S.BoxInfo>
              )}
            </div>
          </S.CardInfo>

          <S.CardInfo>
            <div className="c-header">
              <h6 className="c-title">Documentos</h6>
              <ButtonNew
                className="btn-edit"
                icon={<EditIcon />}
                variant="text"
                fontSize="xsmall"
                noPadding
                onClick={() =>
                  history.push('/novo-pedido/laudos-documentos', {
                    isEditing: true
                  })
                }
              >
                Editar
              </ButtonNew>
            </div>
            <div style={{ gap: '8px', marginTop: '12px' }}>
              {documentsList()
                .filter(
                  (document) => !documentsNotToShow.includes(document.type)
                )
                .map((document) => (
                  <DocumentAccordeon
                    gap="8px"
                    key={document.type}
                    document={document}
                    shadow="satin"
                    padding="16px"
                    onDownloadFile={downloadDocument}
                    onUploadFile={(files: any) =>
                      uploadDocument(files, document.type)
                    }
                    onDeleteFile={deleteDocument}
                    expandedDocument={informationAccordionOpen}
                    handleChange={handleChangeInformationAccordion}
                    uploadButton={true}
                    showStatus={false}
                    reportsRecommendations={reportsRecommendations}
                  />
                ))}
            </div>
          </S.CardInfo>
        </S.Wrapper>
      </ContainerNew>
    )
  }
)

export default ReviewSurgicalOrderLayout
