import { ApiClientImplementation } from 'infra/api-client/api-client'
import { PrepareApiRequestDecorator } from 'main/decorators/prepare-api-request-decorator/prepare-api-request-decorator'
import { makeLocalStorageAdapter } from 'main/factories/cache/local-storage-adapter-factory'
import { HospitalService } from 'presentation/contexts/services/hospital-service'
import { ApiRepository } from 'repository/api-repository'
import { HospitalRepository } from 'repository/repositories/hospital/hospital-repository'
import { SurgeryCenterRepository } from 'repository/repositories/surgery-center/surgery-center-repository'
import { RemoteLoadHospitalsByDoctor } from 'service/usecases/load-hospitals-by-doctor/remote-load-hospitals-by-doctor'
import { RemoteLoadSurgeryCenters } from 'service/usecases/load-surgery-centers/remote-load-surgery-centers'

export default function makeHospitalService() {
  const apiRepository = new PrepareApiRequestDecorator(
    makeLocalStorageAdapter(),
    new ApiRepository(new ApiClientImplementation())
  )

  const hospitalRepository = new HospitalRepository(apiRepository)
  const surgeryCenterRepository = new SurgeryCenterRepository(apiRepository)

  const loadHospitalsByDoctor = new RemoteLoadHospitalsByDoctor(
    hospitalRepository
  )

  const loadSurgeryCenters = new RemoteLoadSurgeryCenters(
    surgeryCenterRepository
  )

  return new HospitalService({
    loadHospitalsByDoctor,
    loadSurgeryCenters
  })
}
