import React from 'react'

import AccordionCardNew from 'presentation/shared/components/AccordionCardNew'
import SupportTextNew from 'presentation/shared/components/SupportTextNew'

type CooperativeProps = {
  cooperative?: string
  icon?: React.ReactElement
  expanded?: boolean
  handleChange?: (accordionLabel: string, isExpanded: boolean) => void
}

export const Cooperative = ({
  cooperative,
  icon,
  expanded,

  handleChange
}: CooperativeProps) => {
  return (
    <AccordionCardNew
      accordionLabel="CooperativeData"
      expanded={expanded}
      handleChange={handleChange}
      header={'Dados cooperativa'}
      shadow="satin"
      padding="16px"
      gap="4px"
      background="neutral25"
      headerIcon={icon}
    >
      <div>
        <SupportTextNew color="primary400" weight="bold">
          Cooperativa
        </SupportTextNew>
        <SupportTextNew style={{ fontSize: '14px' }}>
          {cooperative}
        </SupportTextNew>
      </div>
    </AccordionCardNew>
  )
}
