import React from 'react'
import CardNew from 'presentation/shared/components/CardNew'
import * as S from './styles'
import Divider from 'presentation/shared/components/DividerNew'

interface OtherIndicatorsCardProps {
  title?: string
  readmission?: number
  timeHospitalization?: number
  cancellation?: number
  delay?: number
  averageReadmission?: number
  averageTimeHospitalization?: number
  averageCancellation?: number
  averageDelay?: number
  generalAverageReadmission?: number
  generalAverageTimeHospitalization?: number
  generalAverageCancellation?: number
  generalAverageDelay?: number
  totalSurgeriesDelayed?: number
  variant?:
    | 'positive'
    | 'negative'
    | 'equal'
    | 'indicatorPositive'
    | 'indicatorNegative'
    | 'indicatorEqual'
}

export const OtherIndicatorsCard = ({
  title,
  readmission,
  timeHospitalization,
  cancellation,
  delay,
  averageReadmission,
  averageTimeHospitalization,
  averageCancellation,
  averageDelay,
  generalAverageReadmission,
  generalAverageTimeHospitalization,
  generalAverageCancellation,
  generalAverageDelay,
  totalSurgeriesDelayed,
  variant
}: OtherIndicatorsCardProps) => {
  return (
    <CardNew
      background="neutral25"
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'start',
        gap: '8px',
        width: '100%',
        minWidth: '150px',
        minHeight: '175px'
      }}
    >
      {readmission && (
        <S.ValueContainer variant={variant}>{readmission}%</S.ValueContainer>
      )}

      {timeHospitalization && (
        <S.ValueContainer variant={variant}>
          {timeHospitalization} dias
        </S.ValueContainer>
      )}

      {cancellation && (
        <S.ValueContainer variant={variant}>{cancellation}%</S.ValueContainer>
      )}

      {delay && <S.ValueContainer variant={variant}>{delay}%</S.ValueContainer>}

      <S.OtherIndicatorsCardTitle>{title}</S.OtherIndicatorsCardTitle>

      {averageReadmission && (
        <S.Avarage>
          A cada 100 cirurgias {averageReadmission} precisam de reinternação
        </S.Avarage>
      )}

      {averageTimeHospitalization && (
        <S.Avarage>
          Pacientes ficam internados por cerca de {averageTimeHospitalization}{' '}
          dias em suas cirurgias
        </S.Avarage>
      )}

      {averageCancellation && (
        <S.Avarage>
          A cada 100 pedidos cirúrgicos {averageCancellation} são cancelados
        </S.Avarage>
      )}

      {averageDelay && (
        <S.Avarage>
          A cada {totalSurgeriesDelayed} cirurgias realizadas {averageDelay}{' '}
          cirurgias possuem atrasos
        </S.Avarage>
      )}

      <Divider marginTop="auto" marginBottom="8px" />
      <S.AvarageEspeciality>Média da sua especialidade</S.AvarageEspeciality>
      {generalAverageReadmission && (
        <S.GeneralAverage>{generalAverageReadmission}%</S.GeneralAverage>
      )}
      {generalAverageTimeHospitalization && (
        <S.GeneralAverage>
          {generalAverageTimeHospitalization} dias
        </S.GeneralAverage>
      )}
      {generalAverageCancellation && (
        <S.GeneralAverage>{generalAverageCancellation}%</S.GeneralAverage>
      )}
      {generalAverageDelay && (
        <S.GeneralAverage>{generalAverageDelay}%</S.GeneralAverage>
      )}
    </CardNew>
  )
}
