import React from 'react'
import SupportTextNew from 'presentation/shared/components/SupportTextNew'
import DividerNew from 'presentation/shared/components/DividerNew'
import AccordionCardNew from 'presentation/shared/components/AccordionCardNew'
import moment from 'moment-timezone'

type Props = {
  expanded?: boolean
  handleChange?: (accordionLabel: string, isExpanded: boolean) => void
  password?: string
  expirationDate?: Date
  expirationText?: string
  icon?: React.ReactElement
  passwordObservation?: string
}

export default function QueueNumber({
  expanded,
  handleChange,
  password,
  expirationDate,
  expirationText,
  passwordObservation,
  icon
}: Props) {
  return (
    <AccordionCardNew
      accordionLabel="QueueNumber"
      expanded={expanded}
      handleChange={handleChange}
      header="Senha"
      shadow="satin"
      padding="16px"
      gap="4px"
      background="neutral25"
      headerIcon={icon}
    >
      <SupportTextNew color="primary400" weight="bold">
        Senha
      </SupportTextNew>
      {password ? (
        <SupportTextNew color="neutral800">{password}</SupportTextNew>
      ) : (
        <SupportTextNew color="neutral800">
          Sua senha ainda não foi gerada
        </SupportTextNew>
      )}

      {(expirationText || expirationDate) && (
        <>
          <DividerNew marginBottom="8px" marginTop="8px" />
          <SupportTextNew color="primary400" weight="bold">
            Validade da senha
          </SupportTextNew>
          {expirationDate && (
            <SupportTextNew color="neutral800">
              {moment.utc(expirationDate).format('DD/MM/YYYY')}
            </SupportTextNew>
          )}
        </>
      )}
      <SupportTextNew
        color="neutral800"
        size={'small'}
        style={{
          textAlign: 'start'
        }}
      >
        {passwordObservation}
      </SupportTextNew>
    </AccordionCardNew>
  )
}
