import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'

import { LoadPatientDocuments } from 'domain/usecases/patient/load-patient-documents'

import SurgeryDetailsLayout, {
  SurgeryDetailsLayoutProps
} from 'presentation/shared/layouts/SurgeryDetails'
import { ListItem } from 'presentation/shared/components/List'
import {
  patientDocumentList,
  patientDocumentSolicitationList
} from './documents'
import { useHistory } from 'react-router'
import { LoadSurgeryDetails } from 'domain/usecases/surgery/load-surgery-details'
import moment from 'moment-timezone'
import { setDocListIcon } from 'presentation/utils/set-doclist-icon'
import { LoadSurgicalOrder } from 'domain/usecases/surgical-order/load-surgical-order'
import { LoadSurgicalOrderStatus } from 'domain/usecases/surgical-order/get-surgical-order-status'
import { useLocation } from 'react-router-dom'
import { Hospital } from 'domain/entities/hospital-model'
import {
  WithLoading,
  WithLoadingProps
} from 'presentation/shared/components/HOCs/WithLoading'
import { useServices } from 'presentation/hooks/use-services'
import downloadFileFromBlob from '../../../../common/utils/downloadFileFromBlob'
import { getDocumentsLabelLocationByEnum } from '../../../utils/document-types-location'
import { useStores } from 'presentation/hooks/use-stores'
import { Profile } from 'common/enum/profile'
import { useSearchParams } from 'presentation/hooks/use-search-params'
import { LoadGuidesByType } from 'domain/usecases/surgical-order/load-guides-by-type'

type SurgeryDetailsProps = {
  loadPatientDocuments: LoadPatientDocuments
  loadSurgeryDetails: LoadSurgeryDetails
  loadSurgicalOrder: LoadSurgicalOrder
  loadSurgicalOrderStatus: LoadSurgicalOrderStatus
}

const SurgeryDetails = WithLoading(
  ({ setIsLoading }: SurgeryDetailsProps & WithLoadingProps) => {
    const [patientDocuments, setPatientDocuments] = useState(
      [] as Array<ListItem & { document_id: number; type: string }>
    )
    const [surgeryInfo, setSurgeryInfo] = useState<SurgeryDetailsLayoutProps>()
    const location = useLocation<{ id: number }>()
    const history = useHistory()
    const surgicalOrderService = useServices().surgicalOrder
    const currentUser = useStores().currentAccount
    const isPatient =
      currentUser.getCurrentAccount()?.user?.role === Profile.PATIENT
    const searchParams = useSearchParams()

    const [guidesTypeProcedures, setGuidesTypeProcedures] = useState(
      {} as LoadGuidesByType.Model
    )
    const loadGuidesTypeProcedures = async () => {
      const surgicalOrderId = Number(getSurgicalOrderId())

      try {
        const guides = await surgicalOrderService.loadGuidesByType(
          surgicalOrderId,
          'I',
          {
            fields: [
              'date',
              'password',
              'details{quantity_solicitation, quantity_authorization,provider, procedure, fat_id, guideBusiness{fat_id, cbhpm_id, description_cbhpm}}'
            ]
          }
        )

        setGuidesTypeProcedures(guides)
      } catch (e) {
        return
      }
    }

    const [guidesTypeOpme, setGuidesTypeOpme] = useState(
      {} as LoadGuidesByType.Model
    )
    const loadGuidesTypeOpme = async () => {
      try {
        const surgicalOrderId = Number(getSurgicalOrderId())

        const guides = await surgicalOrderService.loadGuidesByType(
          surgicalOrderId,
          'O',
          {
            fields: [
              'date',
              'password',
              'details{quantity_solicitation, quantity_authorization, provider,procedure, fat_id, guideBusiness{fat_id, cbhpm_id, description_cbhpm}}'
            ]
          }
        )

        setGuidesTypeOpme(guides)
      } catch (e) {
        return
      }
    }

    const loadSurgicalOrder = async () => {
      try {
        setIsLoading(true)

        const surgicalOrderId = Number(getSurgicalOrderId())

        const surgicalOrderDetails =
          await surgicalOrderService.loadSurgicalOrder(surgicalOrderId, [
            'hospital{name, hospital_id}',
            'procedure{description, code, quantity }',
            'surgical_order_id',
            'password_to_finish',
            'password_max_date_validation',
            'password_max_string_validation',
            'procedure_details',
            'opme { providers }',
            'opme_details',
            'observation',
            'surgeryDate',
            'expectedDate',
            'status{status}',
            'hospitalizationType',
            'patient {mainAccompanying {name}}',
            'documents {document_id, type, name, documentUri, isMerged, group_id}',
            'isSolicitation',
            'updatedAt',
            'concludedByName',
            'concludedByRole',
            'concludedAt'
          ])

        setSurgeryInfo({
          medicalTeam: [],
          hospital: new Hospital(
            surgicalOrderDetails.hospital?.hospital_id ?? 0,
            surgicalOrderDetails.hospital?.name ?? '',
            surgicalOrderDetails.hospital?.name ?? ''
          ).name,
          title:
            surgicalOrderDetails.procedure &&
            surgicalOrderDetails.procedure[0].description.replaceAll('"', ''),
          mainAccompanying: surgicalOrderDetails.patient?.mainAccompanying,
          surgery_id: surgicalOrderDetails.surgical_order_id,
          statusList: surgicalOrderDetails?.status,
          documents: (surgicalOrderDetails?.documents as any[]) ?? [],
          date: surgicalOrderDetails.surgeryDate,
          expectedDate: surgicalOrderDetails.expectedDate,
          isSolicitation: surgicalOrderDetails.isSolicitation,
          isUrgent: surgicalOrderDetails.hospitalizationType === 'urgency',

          passwordToFinish: surgicalOrderDetails?.password_to_finish,
          passwordMaxDateValidation:
            surgicalOrderDetails?.password_max_date_validation,
          passwordMaxStringValidation:
            surgicalOrderDetails?.password_max_string_validation,
          procedureDetails: surgicalOrderDetails?.procedure_details,
          opme: surgicalOrderDetails.opme,
          opmeDetails: surgicalOrderDetails?.opme_details,
          observation: surgicalOrderDetails?.observation,
          procedures: surgicalOrderDetails?.procedure,
          updatedAt: surgicalOrderDetails?.updatedAt,
          concludedByName: surgicalOrderDetails?.concludedByName,
          concludedByRole: surgicalOrderDetails?.concludedByRole,
          concludedAt: surgicalOrderDetails?.concludedAt
        })
      } catch (err: any) {
        toast.error('Não foi possível carregar o pedido', {
          autoClose: 1300
        })
        setTimeout(() => {
          history.push('/home')
        }, 1000)
      } finally {
        setIsLoading(false)
      }
    }

    const getDocumentList = () => {
      if (isPatient && surgeryInfo?.isSolicitation)
        return patientDocumentSolicitationList
      return patientDocumentList
    }

    const formatDocumentsList = () => {
      const formattedDocumentList = getDocumentList().map((item) => ({
        title: item.name,
        icon: setDocListIcon(
          item.type,
          item.upload,
          surgeryInfo?.documents ?? []
        ),
        click: () => {
          if (item.upload) {
            history.push(item.url, {
              surgicalOrderId: location.state.id,
              documents: surgeryInfo?.documents,
              isSolicitation: surgeryInfo?.isSolicitation
            })
          } else {
            const document_id = Number(
              surgeryInfo?.documents?.reverse().find((doc) => {
                return item.type
                  .map((val) => val.toLowerCase())
                  .includes(doc.type.toLowerCase())
              })?.document_id
            )
            if (document_id) {
              surgicalOrderService
                .loadSurgicalOrderDocument({
                  surgical_order_id: Number(surgeryInfo?.surgery_id),
                  document_id
                })
                .then((res) => {
                  downloadFileFromBlob(
                    res.data,
                    res.contentType,
                    getDocumentsLabelLocationByEnum(item.type[0])
                  )
                })
            }
          }
        }
      }))

      setPatientDocuments(
        formattedDocumentList as unknown as Array<
          ListItem & { document_id: number; type: string }
        >
      )
    }

    const getSurgicalOrderId = () => {
      const surgicalOrderIdQueryParam = searchParams.get('surgicalOrderId')

      if (surgicalOrderIdQueryParam) {
        return surgicalOrderIdQueryParam
      }

      return location.state?.id
    }

    useEffect(() => {
      loadSurgicalOrder()
      loadGuidesTypeProcedures()
      loadGuidesTypeOpme()
    }, [location.state?.id])

    useEffect(() => {
      formatDocumentsList()
    }, [surgeryInfo])

    return (
      <SurgeryDetailsLayout
        statusList={surgeryInfo?.statusList}
        documents={patientDocuments}
        mainAccompanying={surgeryInfo?.mainAccompanying}
        medicalTeam={surgeryInfo?.medicalTeam}
        hospital={surgeryInfo?.hospital}
        title={surgeryInfo?.title}
        date={
          surgeryInfo?.date &&
          moment.utc(surgeryInfo.date).format('DD/MM/YYYY HH:mm')
        }
        expectedDate={
          surgeryInfo?.expectedDate &&
          moment.utc(surgeryInfo.expectedDate).format('DD/MM/YYYY')
        }
        surgery_id={surgeryInfo?.surgery_id}
        isSolicitation={surgeryInfo?.isSolicitation}
        isUrgent={surgeryInfo?.isUrgent}
        passwordToFinish={surgeryInfo?.passwordToFinish}
        passwordMaxDateValidation={surgeryInfo?.passwordMaxDateValidation}
        passwordMaxStringValidation={surgeryInfo?.passwordMaxStringValidation}
        procedureDetails={surgeryInfo?.procedureDetails}
        observation={surgeryInfo?.observation}
        opmeDetails={surgeryInfo?.opmeDetails}
        opme={surgeryInfo?.opme}
        procedures={surgeryInfo?.procedures}
        updatedAt={
          surgeryInfo?.updatedAt &&
          moment.utc(surgeryInfo.updatedAt).format('ll [às] HH[h]mm')
        }
        guidesTypeProcedures={guidesTypeProcedures}
        guidesTypeOpme={guidesTypeOpme}
        concludedByName={surgeryInfo?.concludedByName}
        concludedByRole={surgeryInfo?.concludedByRole}
        concludedAt={surgeryInfo?.concludedAt}
      />
    )
  }
)

export default SurgeryDetails
