import React, { useContext } from 'react'
import PendencyCard, {
  PendencyTypes
} from 'presentation/shared/layouts/PendencyCard'
import AvatarNew from 'presentation/shared/components/AvatarNew'
import SupportTextNew from 'presentation/shared/components/SupportTextNew'
import * as S from './styles'
import { SurgicalOrderContext } from 'main/factories/pages/doctor/surgical-order/surgical-order-factory'
import theme from 'presentation/styles/theme'
import { PendencyStatusPlural } from 'common/enum/pendency-status'
import { useScrollContainer } from 'react-indiana-drag-scroll'
import { useHistory } from 'react-router'

const PendencieTab = () => {
  const { state } = useContext<any>(SurgicalOrderContext)
  const containerScroll = useScrollContainer()
  const history = useHistory()

  const sections = [
    {
      key: 'pendent',
      color: 'terciary400' as keyof typeof theme.ds.colors,
      emptyState: 'Não há nenhuma pendência a ser resolvida no momento.'
    },
    {
      key: 'in_progress',
      color: 'secondary400' as keyof typeof theme.ds.colors,
      emptyState: 'Não há nenhuma pendência em andamento no momento.'
    },
    {
      key: 'resolved',
      color: 'primary400' as keyof typeof theme.ds.colors,
      emptyState: 'Não há nenhuma pendência resolvida  no momento.'
    }
  ]

  const showPendency = (
    surgical_pendency_id: number,
    pendency_type?: PendencyTypes,
    pendency_order_id?: number
  ) => {
    history.push('/pedido-cirurgico/pendencia', {
      surgical_pendency_id,
      pendency_type,
      pendency_order_id
    })
  }

  return (
    <S.TabWrapper>
      {sections.map((section) => (
        <S.PendencyStatusSection key={section.key}>
          <S.PendencyStatusHeader>
            <AvatarNew
              color={section.color}
              text={state.pendencies[section.key].length}
              active
            />
            <SupportTextNew
              color="neutral700"
              weight="bold"
              className="capitalize-fl"
            >
              {
                PendencyStatusPlural[
                  section.key.toUpperCase() as keyof typeof PendencyStatusPlural
                ]
              }
            </SupportTextNew>
          </S.PendencyStatusHeader>

          {state.pendencies[section.key].length ? (
            <S.PendenciesListContainer ref={containerScroll.ref}>
              {state.pendencies[section.key].map((pendency: any) => (
                <PendencyCard
                  key={pendency.surgical_pendency_id}
                  pendency={pendency}
                  onClick={() =>
                    showPendency(
                      pendency.surgical_pendency_id,
                      pendency.type,
                      pendency.surgical_pendency_order_id
                    )
                  }
                />
              ))}
            </S.PendenciesListContainer>
          ) : (
            <SupportTextNew>{section.emptyState}</SupportTextNew>
          )}
        </S.PendencyStatusSection>
      ))}
    </S.TabWrapper>
  )
}

export default PendencieTab
