import React, { useEffect, useState } from 'react'

import * as S from './styles'

import ButtonNew from 'presentation/shared/components/ButtonNew'
import { ContainerNew } from 'presentation/shared/components/ContainerNew'
import { Separator } from 'presentation/shared/components/Separator'
import { useServices } from 'presentation/hooks/use-services'
import { useStores } from 'presentation/hooks/use-stores'
import { toast } from 'react-toastify'
import { Document } from 'domain/entities/document'
import { DocumentsList } from 'common/enum/documents-list'
import DocumentAccordeon from 'presentation/shared/layouts/DocumentAccordeon'
import { renameFile } from 'common/utils/file/renameFile'
import {
  WithLoading,
  WithLoadingProps
} from 'presentation/shared/components/HOCs/WithLoading'
import { ReportsCard } from 'presentation/shared/layouts/ReportsCard'
import { OpenSurgicalOrderPatientDocument } from 'common/enum/patient-document'
import { ValidationSchemaReportsAndDocument } from './validationSchema'
import TextAreaNew from 'presentation/shared/components/TextAreaNew'
import UploadButtonNew from 'presentation/shared/components/UploadButtonNew'
import downloadFileFromBlob from 'common/utils/downloadFileFromBlob'
import moment from 'moment-timezone'
import AccordionCardNew from 'presentation/shared/components/AccordionCardNew'
import { useFormik } from 'formik'
import { useHistory, useLocation } from 'react-router'

type ReportRecommendationValue = {
  report_recommendation: string
}

export const ReportsAndDocumentsForm = WithLoading(
  ({ setIsLoading }: WithLoadingProps) => {
    const { state } = useLocation<{
      isEditing?: boolean
    }>()
    const surgicalOrderService = useServices().surgicalOrder
    const surgicalOrderContext = useStores().surgicalOrder
    const [documents, setDocuments] = useState<Document[]>([])
    const [informationAccordionOpen, setInformationAccordionOpen] = useState('')
    const [reportRecommendationState, setReportRecommendationState] =
      useState('')
    const [patientName, setPatientName] = useState<string>()
    const [patientId, setPatientId] = useState(0)
    const [reportsAccordionOpen] = useState('')
    const history = useHistory()

    const getSurgicalOrderByIdForDocuments = async () => {
      setIsLoading(true)
      try {
        if (!surgicalOrderContext.surgicalOrder?.surgical_order_id) {
          return
        }

        const response = await surgicalOrderService.getSurgicalOrderById({
          surgical_order_id:
            surgicalOrderContext.surgicalOrder?.surgical_order_id
        })

        setDocuments(response.documents)
        setReportRecommendationState(response.report_recommendation)
        setPatientId(Number(response.patient.patient_id))
        setPatientName(response.patient.patient_name)

        return response
      } catch (error) {
        toast.error('Ocorreu um erro ao carregar os documentos')
      } finally {
        setIsLoading(false)
      }
    }

    const documentsList = () => {
      const mappedDocs = mappedDocuments()

      return Object.keys(OpenSurgicalOrderPatientDocument).map((document) => {
        const docs = mappedDocs
          .filter((doc: any) => doc.type === document)
          .sort((a: any, b: any) => {
            if (moment(a.sendedAt).isAfter(b.sendedAt)) return -1
            else if (moment(a.sendedAt).isBefore(b.sendedAt)) return 1
            else return 0
          })

        const filesList = docs.reduce(
          (acc: any, curr: any) => [...acc, ...curr.documents],
          []
        )

        return {
          label: DocumentsList[document as keyof typeof DocumentsList],
          type: document,
          status: docs[0]?.status,
          documents: filesList
        }
      })
    }

    const mappedDocuments = () => {
      return (
        documents.map((document: Document) => ({
          type: document.type,
          status: document.status,
          documents: [
            {
              ...document,
              sended_at: document.sendedAt,
              status_updated_by: {
                name: document.updated_by?.name
              }
            }
          ]
        })) || []
      )
    }

    const getDocumentsInContext = () => {
      if (
        surgicalOrderContext.surgicalOrder.documents &&
        surgicalOrderContext.surgicalOrder.documents?.length > 0
      ) {
        setDocuments(surgicalOrderContext?.surgicalOrder.documents)
      } else {
        return
      }
    }

    useEffect(() => {
      if (!surgicalOrderContext.surgicalOrder?.surgical_order_id)
        history.push('/home')
      getSurgicalOrderByIdForDocuments()
      getDocumentsInContext()
    }, [])

    const handleChangeInformationAccordion = (
      label: string,
      isExpanded: boolean
    ) => {
      setInformationAccordionOpen(!isExpanded ? '' : label)
    }

    const uploadDocument = async (files: any, type: string) => {
      try {
        setIsLoading(true)
        const file = files[0]
        const form = new FormData()
        const newFile = renameFile(file, file.name)
        form.append('file', newFile)
        form.append('type', type)
        form.append(
          'surgical_order_id',
          String(surgicalOrderContext.surgicalOrder.surgical_order_id)
        )

        await surgicalOrderService.uploadSurgicalOrderDocumentNew({
          form: form
        })
        await getSurgicalOrderByIdForDocuments()
      } catch (error) {
        toast.error('Ocorreu um erro ao enviar o documento')
      } finally {
        setIsLoading(false)
      }
    }

    const deleteDocument = async (document_id: number) => {
      setIsLoading(true)
      try {
        await surgicalOrderService.deleteUploadedDocumentsNew({
          document_id
        })

        await getSurgicalOrderByIdForDocuments()
      } catch (error) {
        toast.error('Ocorreu um erro ao deletar o documento')
      } finally {
        setIsLoading(false)
      }
    }

    const downloadDocument = async (
      document_id: number,
      _group_id: string,
      type: keyof typeof DocumentsList
    ) => {
      setIsLoading(true)
      try {
        const surgicalOrderId = surgicalOrderContext.surgicalOrder
          .surgical_order_id as number

        const file = await surgicalOrderService.loadSurgicalOrderDocument({
          surgical_order_id: surgicalOrderId,
          document_id
        })

        if (file) {
          downloadFileFromBlob(file.data, file.contentType, DocumentsList[type])
        }
      } catch {
        toast.error('Ocorreu um erro ao baixar o documento')
      } finally {
        setIsLoading(false)
      }
    }

    const filteredReports = documents.filter(
      (document) =>
        document.type === OpenSurgicalOrderPatientDocument['Laudos dos exames']
    )

    const formik = useFormik({
      initialValues: {
        report_recommendation: reportRecommendationState || ''
      } as ReportRecommendationValue,
      validationSchema: ValidationSchemaReportsAndDocument,
      validateOnMount: true,
      validateOnChange: true,
      enableReinitialize: true,
      onSubmit: async (values) => {
        try {
          setIsLoading(true)
          await surgicalOrderService.updateSurgicalOrder({
            surgical_order_id:
              surgicalOrderContext.surgicalOrder.surgical_order_id,
            patient: {
              patient_id: patientId,
              patient_name: patientName
            },
            report_recommendation: values.report_recommendation
          })

          history.push('/novo-pedido/resumo')
        } catch (error: any) {
          toast.error(`[Laudos e documentos] ${error.message}`)
        } finally {
          setIsLoading(false)
        }
      }
    })

    const handleBackButton = () => {
      return history.goBack()
    }

    const documentsNotToShow = [
      'LAUDO DE EXAME',
      'Laudos dos exames',
      'PLANEJAMENTO CIRÚRGICO',
      'Guia de autorização'
    ]

    return (
      <ContainerNew
        buttonsHasBoxShadow={!state?.isEditing}
        primaryButton={
          <ButtonNew fullWidth size="large" onClick={formik.submitForm}>
            {state?.isEditing ? 'Salvar edição' : 'Próximo'}
          </ButtonNew>
        }
        secondaryButton={
          !state?.isEditing && (
            <ButtonNew
              outlined
              fullWidth
              size="large"
              style={{
                marginTop: '16px'
              }}
              onClick={handleBackButton}
            >
              Anterior
            </ButtonNew>
          )
        }
      >
        <S.Wrapper>
          <h1>Laudos</h1>
          <span>Adicione os laudos necessários. </span>
          <p>
            Você <b>também deve indicar laudos</b> que estão no sistema do
            hospital.
          </p>
          <TextAreaNew
            label="Indique um laudo"
            placeholder="Identifique qual o laudo do sistema do hospital que você precisa"
            rows={3}
            name="report_recommendation"
            value={formik.values.report_recommendation}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.report_recommendation &&
              formik.errors.report_recommendation
            }
            onInputChange={formik.handleChange('report_recommendation')}
          />

          {documentsList()?.map(
            (document) =>
              document.type ===
                OpenSurgicalOrderPatientDocument['Laudos dos exames'] && (
                <UploadButtonNew
                  onInput={(files: any) => uploadDocument(files, document.type)}
                  name={document.type}
                  outlined
                  multiple={false}
                  fullWidth
                  size="large"
                  style={{
                    marginTop: '16px',
                    display: 'flex',
                    justifyItems: 'center',
                    alignItems: 'center'
                  }}
                  disabled={reportRecommendationState ? true : false}
                >
                  {
                    <span
                      style={{
                        color: '#038E82',
                        fontWeight: 600
                      }}
                    >
                      Incluir laudos
                    </span>
                  }
                </UploadButtonNew>
              )
          )}
          {filteredReports.length >= 2 ? (
            <AccordionCardNew
              hideHeaderDivider={false}
              header={'Laudos'}
              expanded={informationAccordionOpen === 'Reports'}
              accordionLabel="Reports"
              handleChange={handleChangeInformationAccordion}
              gap="8px"
              style={{
                marginTop: '0px',
                marginBottom: '0px'
              }}
            >
              {filteredReports.map(
                (document, index) =>
                  index === 0 && (
                    <ReportsCard
                      key={document.type}
                      deleteDocument={deleteDocument}
                      documents={filteredReports}
                      downloadDocument={downloadDocument}
                      informationAccordionOpen={reportsAccordionOpen}
                      showStatus={false}
                    />
                  )
              )}
            </AccordionCardNew>
          ) : (
            filteredReports.map(
              (document, index) =>
                index === 0 && (
                  <ReportsCard
                    key={document.type}
                    deleteDocument={deleteDocument}
                    documents={filteredReports}
                    downloadDocument={downloadDocument}
                    informationAccordionOpen={reportsAccordionOpen}
                    showStatus={false}
                  />
                )
            )
          )}

          <div
            style={{
              marginTop: '32px',
              marginBottom: '16px',
              width: '100%'
            }}
          >
            <Separator />
          </div>

          <h1>Outros documentos</h1>
          <span>
            Adicione os documentos necessários ao processo de autorização.
          </span>
        </S.Wrapper>
        <S.DocumentsWrapper>
          {documentsList()
            .filter((document) => !documentsNotToShow.includes(document.type))
            .map((document) => (
              <DocumentAccordeon
                gap="8px"
                key={document.type}
                document={document}
                shadow="satin"
                padding="16px"
                onDownloadFile={downloadDocument}
                onUploadFile={(files: any) =>
                  uploadDocument(files, document.type)
                }
                onDeleteFile={deleteDocument}
                expandedDocument={informationAccordionOpen}
                handleChange={handleChangeInformationAccordion}
                uploadButton={true}
                showStatus={false}
              />
            ))}
        </S.DocumentsWrapper>
      </ContainerNew>
    )
  }
)
