import { Patient } from 'domain/entities/patient-model'
import { TherapeuticPlanModel } from 'domain/entities/therapeutic-plan.model'
import 'main/config/yup'
import Button from 'presentation/shared/components/Button'
import { CarouselState } from 'presentation/shared/components/Carousel'
import ConditionalCarousel from 'presentation/shared/components/Carousel/ConditionalCarousel'
import { Container } from 'presentation/shared/components/Container'
import StepDots from 'presentation/shared/components/StepDots'
import SupportText from 'presentation/shared/components/SupportText'
import React, { useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import ProposedProtocolStepOne from './ProposedProtocolStepOne'
import ProposedProtocolStepThree from './ProposedProtocolStepThree'
import ProposedProtocolStepTwo from './ProposedProtocolStepTwo'
import * as S from './styles'

export default function TherapeuticPlanProposedProtocolForm() {
  const history = useHistory()

  const { state } = useLocation<{
    patient: Patient
    therapeuticPlan: TherapeuticPlanModel
  }>()

  const [protocolProposedCarousel, setProtocolProposedCarousel] = useState(
    {} as CarouselState
  )

  const subtitles = [
    'Adicione os dados do paciente',
    'Adicione o protocolo',
    'Para iniciar busque ou adicione',
    'Protocolo sugerido'
  ]

  return (
    <>
      <Container
        title="Protocolo proposto"
        titleColor="primary"
        subtitle={subtitles[protocolProposedCarousel.activeIndex] || '-'}
        noPadding
        justifyContent="center"
        primaryButton={
          <>
            <S.StepDotsContainer>
              <StepDots
                active={protocolProposedCarousel.activeIndex}
                total={4}
              />
            </S.StepDotsContainer>
            <Button
              fullWidth
              onClick={() => {
                if (!protocolProposedCarousel.isLast) {
                  protocolProposedCarousel.slideNext()
                }
              }}
              disabled={protocolProposedCarousel.activeIndex === 2}
            >
              Continuar
            </Button>
          </>
        }
        secondaryButton={
          <SupportText
            onClick={() =>
              history.push('/novo-plano-terapeutico/resumo', {
                patient: state?.patient,
                therapeuticPlan: state?.therapeuticPlan
              })
            }
            color="primary"
          >
            Cancelar
          </SupportText>
        }
        actualPageText="Voltar"
        actualPageOnClick={() => {
          if (protocolProposedCarousel.activeIndex !== 0) {
            protocolProposedCarousel.slidePrev()
          } else {
            history.push('/novo-plano-terapeutico/resumo', state)
          }
        }}
      >
        <ConditionalCarousel setState={setProtocolProposedCarousel}>
          <S.StepContainer>
            <ProposedProtocolStepOne />
          </S.StepContainer>
          <S.StepContainer>
            <ProposedProtocolStepTwo />
          </S.StepContainer>
          <S.StepContainer>
            <ProposedProtocolStepThree />
          </S.StepContainer>
          <>Step 4</>
        </ConditionalCarousel>
      </Container>
    </>
  )
}
