import { IApiRepository } from 'service/protocols/api/api-repository'
import { SurgicalOrderRepository as IReportsRepository } from 'repository/interfaces/surgical-order-repository'
import { AddSurgicalOrder } from 'domain/usecases/surgical-order/add-surgical-order'
import { InternalServerError } from 'common/errors'
import { ApiStatusCode } from 'service/protocols/api/api-client'
import handleGraphQLError from 'repository/graphql/utils/handle-error'
import { RepositoryErrors } from 'repository/errors/repository-errors'
import { LoadCid } from 'domain/usecases/surgical-order/load-cid'
import { LoadTuss } from 'domain/usecases/surgical-order/load-tuss'
import {
  countSurgicalOrdersByStatus,
  getCidQuery,
  getTussQuery,
  loadFavoriteDoctorOrdersQuery,
  loadFavoriteSurgicalOrdersQuery,
  loadSurgeriesQuery,
  loadSurgicalOrderQuery,
  loadSurgicalOrderStatusQuery,
  searchSurgicalOrdersQuery,
  countScheduledAndUnscheduledSurgicalOrders,
  loadGuidesByType
} from 'repository/graphql/queries'
import { RegisterSurgicalOrder } from 'domain/usecases/surgical-order/register-surgical-order'
import {
  addFavoriteMutation,
  checkinMutation,
  concludeSurgicalOrderMutation,
  confirmMergeDocumentsMutation,
  createPatientSurgicalOrderRequestMutation,
  deleteFavoritedSurgicalOrderMutation,
  deleteSurgicalOrderSolicitationMutation,
  deleteUploadedDocumentsMutation,
  registerCrmoSurgicalOrderMutation,
  registerSecretarySurgicalOrderMutation,
  registerSurgicalOrderMutation,
  reorderSurgeryMutation,
  updateSurgeryStatusMutation,
  updateSurgicalOrderMutation,
  updateSurgicalOrderRegenerateGuidesMutation
} from 'repository/graphql/mutations'
import { makeGraphQLVariable } from 'repository/graphql/utils/make-variables'
import { LoadSurgicalOrder } from 'domain/usecases/surgical-order/load-surgical-order'
import { AddFavorite } from 'domain/usecases/surgical-order/add-favorite'
import { UploadSurgicalOrderDocument } from 'domain/usecases/surgical-order/upload-surgical-order-document'
import { SearchSurgicalOrders } from 'domain/usecases/surgical-order/search-surgical-orders'
import { LoadSurgicalOrderStatus } from 'domain/usecases/surgical-order/get-surgical-order-status'
import { ChangeSurgicalOrderStatus } from 'domain/usecases/surgical-order/change-surgical-order-status'
import { ReorderSurgicalOrder } from 'domain/usecases/surgical-order/reorder-surgery'
import { UpdateSurgicalOrder } from 'domain/usecases/surgical-order/update-surgical-order'
import { LoadSurgeries } from 'domain/usecases/surgery/load-surgeries'
import { LoadSurgicalOrderDocument } from 'domain/usecases/surgical-order/load-surgical-order-document'
import { CountSurgicalOrdersByStatus } from 'domain/usecases/surgical-order/count-surgical-orders-by-status'
import { ConcludeSurgicalOrder } from 'domain/usecases/surgical-order/conclude-surgical-order'
import { DeleteSurgicalOrderSolicitation } from 'domain/usecases/surgical-order/delete-surgical-order-solicitation'
import { UploadSurgicalOrderDocumentsSameType } from 'domain/usecases/surgical-order/upload-surgical-order-documents-same-type'
import { LoadFavoriteSurgicalOrders } from 'domain/usecases/surgical-order/load-favorite-surgical-orders'
import { DeleteFavoritedSurgicalOrder } from 'domain/usecases/surgical-order/delete-favorited-surgical-order'
import { SurgicalOrderCheckin } from 'domain/usecases/surgical-order/checkin'
import { UpdateSurgicalOrderRegenerateGuides } from 'domain/usecases/surgical-order/update-surgical-order-regenerate-guides'
import { LoadFavoriteDoctorSurgicalOrders } from 'domain/usecases/surgical-order/load-favorite-doctor-order'
import { UploadToMergeDocument } from 'domain/usecases/surgical-order/upload-to-merge-document'
import { ConfirmMergeDocuments } from 'domain/usecases/surgical-order/confirm-merge-documents'
import { DeleteUploadedDocuments } from 'domain/usecases/surgical-order/delete-uploaded-documents'
import { CountScheduledAndUnscheduledSurgicalOrders } from 'domain/usecases/surgical-order/scheduled-and-unscheduled-counter'
import { LoadGuidesByType } from 'domain/usecases/surgical-order/load-guides-by-type'
import { LoadDashboardStatusCount } from 'domain/usecases/surgical-order/load-dashboard-status-count'
import { LoadSurgicalOrdersByDoctorAndStatus } from 'domain/usecases/surgical-order/load-surgical-orders-by-doctor-and-status'
import { LoadSurgicalOrderNoticeCard } from 'domain/usecases/surgical-order/load-surgical-orders-notice-card'
import { GetSurgicalOrderTimeline } from 'domain/usecases/surgical-order/get-surgical-order-timeline'
import { GetSurgicalSchedulesByDoctor } from 'domain/usecases/surgical-order/get-surgical-schedules-by-doctor'
import { GetSurgicalSchedulesCountByDoctor } from 'domain/usecases/surgical-order/get-surgical-schedules-count-by-doctor'
import { LoadExpirationDateStatusDashboardCounts } from 'domain/usecases/surgical-order/load-expiration-date-status-dashboard-count'
import { LoadHospitalsByDoctorCpf } from 'domain/usecases/hospital/load-hospitals-by-doctor-cpf'
import { loadHospitalsByDoctorCpfQuery } from 'repository/graphql/queries/index'
import { UpdateSurgicalOrderNew } from 'domain/usecases/surgical-order/update-surgical-order-new'
import { CreateSurgicalOrder } from 'domain/usecases/surgical-order/create-surgical-order'
import { GetSurgicalOrderById } from 'domain/usecases/surgical-order/get-surgical-order-by-id'
import { UploadSurgicalOrderDocumentNew } from 'domain/usecases/surgical-order/upload-surgical-order-document-new'
import { DeleteUploadedDocumentsNew } from 'domain/usecases/surgical-order/delete-uploaded-documents-new'
import { SearchProcedures } from 'domain/usecases/surgical-order/search-procedures'
import { SearchCID } from 'domain/usecases/surgical-order/search-cid'
import { LoadAnesthesiologies } from 'domain/usecases/surgical-order/load-anesthesiologies'
import { FinishSurgicalOrder } from 'domain/usecases/surgical-order/finish-surgical-order'
import { RequestSurgicalOrderCancellation } from 'domain/usecases/surgical-order/request-surgical-order-cancellation'
import { GetSurgicalOrderCancellationNotifications } from 'domain/usecases/surgical-order/get-surgical-order-cancellation-notifications'
import { GetSurgicalOrderCancellationNotificationsCount } from 'domain/usecases/surgical-order/get-surgical-order-cancellation-notifications-count'
import { AddSurgicalOrderCancellationComment } from 'domain/usecases/surgical-order/add-surgical-order-cancellation-comment'
import { ConfirmReadCancellationRequests } from 'domain/usecases/surgical-order/read-cancellation-requests/read-cancellation-requests'

export class SurgicalOrderRepository implements IReportsRepository {
  constructor(private readonly apiRepository: IApiRepository) {}

  async addPatientSurgicalOrder(
    params: AddSurgicalOrder.Params
  ): Promise<AddSurgicalOrder.Model> {
    const apiRepository = this
      .apiRepository as IApiRepository<AddSurgicalOrder.Model>

    const query = createPatientSurgicalOrderRequestMutation

    const httpResponse = await apiRepository.post({
      url: '/graphql',
      body: {
        query: query.query,
        variables: makeGraphQLVariable(params, 'data')
      },
      query: query.name
    })

    if (
      httpResponse.statusCode &&
      httpResponse.statusCode !== ApiStatusCode.ok
    ) {
      throw handleGraphQLError(RepositoryErrors[httpResponse.error!])
    } else {
      return httpResponse.body as AddSurgicalOrder.Model
    }
  }

  async loadCid(params: LoadCid.Params): Promise<LoadCid.Model> {
    const apiRepository = this.apiRepository as IApiRepository<LoadCid.Model>

    const query = getCidQuery(params)

    const httpResponse = await apiRepository.post({
      url: '/graphql',
      body: {
        query: query.query,
        variables: {
          ...makeGraphQLVariable(params.pagination, 'pagination'),
          ...makeGraphQLVariable(params.query, 'query')
        }
      },
      query: query.name
    })

    if (
      httpResponse.statusCode &&
      httpResponse.statusCode !== ApiStatusCode.ok
    ) {
      throw handleGraphQLError(RepositoryErrors[httpResponse.error!])
    } else {
      return httpResponse.body as LoadCid.Model
    }
  }

  async loadTuss(params: LoadTuss.Params): Promise<LoadTuss.Model> {
    const apiRepository = this.apiRepository as IApiRepository<LoadTuss.Model>

    const query = getTussQuery(params)

    const httpResponse = await apiRepository.post({
      url: '/graphql',
      body: {
        query: query.query,
        variables: {
          ...makeGraphQLVariable(params.pagination, 'pagination'),
          ...makeGraphQLVariable(params.name, 'name'),
          ...makeGraphQLVariable(params.code, 'code')
        }
      },
      query: query.name
    })

    if (
      httpResponse.statusCode &&
      httpResponse.statusCode !== ApiStatusCode.ok
    ) {
      throw handleGraphQLError(RepositoryErrors[httpResponse.error!])
    } else {
      return httpResponse.body as LoadTuss.Model
    }
  }

  async loadSurgicalOrder(
    id: number,
    params: LoadSurgicalOrder.Params
  ): Promise<LoadSurgicalOrder.Model> {
    const apiRepository = this
      .apiRepository as IApiRepository<LoadSurgicalOrder.Model>

    const query = loadSurgicalOrderQuery(params)

    const httpResponse = await apiRepository.post({
      url: '/graphql',
      body: {
        query: query.query,
        variables: makeGraphQLVariable(id, 'surgical_order_id')
      },
      query: query.name
    })

    if (
      httpResponse.statusCode &&
      httpResponse.statusCode !== ApiStatusCode.ok
    ) {
      throw handleGraphQLError(RepositoryErrors[httpResponse.error!])
    } else {
      return httpResponse.body as LoadSurgicalOrder.Model
    }
  }

  async loadSurgicalOrderStatus(
    params: LoadSurgicalOrderStatus.Params
  ): Promise<LoadSurgicalOrderStatus.Model> {
    const apiRepository = this
      .apiRepository as IApiRepository<LoadSurgicalOrderStatus.Model>

    const query = loadSurgicalOrderStatusQuery(params)

    const httpResponse = await apiRepository.post({
      url: '/graphql',
      body: {
        query: query.query,
        variables: makeGraphQLVariable(
          params.surgical_order_id,
          'surgical_order_id'
        )
      },
      query: query.name
    })

    if (
      httpResponse.statusCode &&
      httpResponse.statusCode !== ApiStatusCode.ok
    ) {
      throw handleGraphQLError(RepositoryErrors[httpResponse.error!])
    } else {
      return httpResponse.body as LoadSurgicalOrderStatus.Model
    }
  }

  async searchSurgicalOrders(
    params: SearchSurgicalOrders.Params
  ): Promise<SearchSurgicalOrders.Model> {
    const apiRepository = this
      .apiRepository as IApiRepository<SearchSurgicalOrders.Model>

    const query = searchSurgicalOrdersQuery(params)
    delete params.params

    const { pagination, ...filters } = params

    const httpResponse = await apiRepository.post({
      url: '/graphql',
      body: {
        query: query.query,
        variables: {
          ...makeGraphQLVariable(filters, 'search'),
          ...makeGraphQLVariable(pagination, 'pagination')
        }
      },
      query: query.name
    })

    if (
      httpResponse.statusCode &&
      httpResponse.statusCode !== ApiStatusCode.ok
    ) {
      throw handleGraphQLError(RepositoryErrors[httpResponse.error!])
    } else {
      return httpResponse.body as SearchSurgicalOrders.Model
    }
  }

  async registerSurgicalOrder(
    params: RegisterSurgicalOrder.Params
  ): Promise<RegisterSurgicalOrder.Model> {
    const apiRepository = this
      .apiRepository as IApiRepository<RegisterSurgicalOrder.Model>

    const query = registerSurgicalOrderMutation

    const httpResponse = await apiRepository.post({
      url: '/graphql',
      body: {
        query: query.query,
        variables: makeGraphQLVariable(params)
      },
      query: query.name
    })

    if (
      httpResponse.statusCode &&
      httpResponse.statusCode !== ApiStatusCode.ok
    ) {
      throw handleGraphQLError(RepositoryErrors[httpResponse.error!])
    } else {
      return httpResponse.body as RegisterSurgicalOrder.Model
    }
  }

  async addFavorite(params: AddFavorite.Params): Promise<AddFavorite.Model> {
    const apiRepository = this
      .apiRepository as IApiRepository<AddFavorite.Model>

    const query = addFavoriteMutation

    const httpResponse = await apiRepository.post({
      url: '/graphql',
      body: {
        query: query.query,
        variables: makeGraphQLVariable(params)
      },
      query: query.name
    })

    if (
      httpResponse.statusCode &&
      httpResponse.statusCode !== ApiStatusCode.ok
    ) {
      throw handleGraphQLError(RepositoryErrors[httpResponse.error!])
    } else {
      return httpResponse.body as AddFavorite.Model
    }
  }

  async uploadSurgicalOrderDocument(
    params: UploadSurgicalOrderDocument.Params
  ): Promise<UploadSurgicalOrderDocument.Model> {
    const apiRepository = this
      .apiRepository as IApiRepository<UploadSurgicalOrderDocument.Model>

    const httpResponse = await apiRepository.post({
      url: '/surgical-order/upload-document',
      body: params.form
    })

    if (httpResponse.body) {
      const { code } = httpResponse.body
      if (code) {
        throw handleGraphQLError(RepositoryErrors[code])
      }
    }

    if (
      httpResponse.statusCode &&
      httpResponse.statusCode !== ApiStatusCode.created
    ) {
      throw handleGraphQLError(RepositoryErrors[httpResponse.error!])
    } else {
      return httpResponse.body as UploadSurgicalOrderDocument.Model
    }
  }

  async loadSurgicalOrderDocument(
    surgical_order_id: number | string,
    document_id: number
  ): Promise<LoadSurgicalOrderDocument.Model> {
    const apiRepository = this
      .apiRepository as IApiRepository<LoadSurgicalOrderDocument.Model>

    const httpResponse: any = await apiRepository.get(
      {
        url: `/surgical-order/${surgical_order_id}/document/${document_id}`
      },
      true
    )

    const contentType = httpResponse.headers['content-type']
    const data = httpResponse.data

    return {
      contentType,
      data
    }
  }

  async registerCrmoSurgicalOrder(
    params: RegisterSurgicalOrder.Params
  ): Promise<RegisterSurgicalOrder.Model> {
    const apiRepository = this
      .apiRepository as IApiRepository<RegisterSurgicalOrder.Model>

    const query = registerCrmoSurgicalOrderMutation

    const httpResponse = await apiRepository.post({
      url: '/graphql',
      body: {
        query: query.query,
        variables: makeGraphQLVariable(params)
      },
      query: query.name
    })

    if (
      httpResponse.statusCode &&
      httpResponse.statusCode !== ApiStatusCode.ok
    ) {
      throw handleGraphQLError(RepositoryErrors[httpResponse.error!])
    } else {
      return httpResponse.body as RegisterSurgicalOrder.Model
    }
  }

  async changeSurgicalOrderStatus(
    params: ChangeSurgicalOrderStatus.Params
  ): Promise<ChangeSurgicalOrderStatus.Model> {
    const apiRepository = this
      .apiRepository as IApiRepository<ChangeSurgicalOrderStatus.Model>

    const query = updateSurgeryStatusMutation

    const httpResponse = await apiRepository.post({
      url: '/graphql',
      body: {
        query: query.query,
        variables: {
          surgical_order_id: params.surgical_order_id,
          ...makeGraphQLVariable(params.data)
        }
      },
      query: query.name
    })

    if (
      httpResponse.statusCode &&
      httpResponse.statusCode !== ApiStatusCode.ok
    ) {
      throw handleGraphQLError(RepositoryErrors[httpResponse.error!])
    } else {
      return httpResponse.body as ChangeSurgicalOrderStatus.Model
    }
  }

  async reorderSurgicalOrder(
    params: ReorderSurgicalOrder.Params
  ): Promise<ReorderSurgicalOrder.Model> {
    const apiRepository = this
      .apiRepository as IApiRepository<ReorderSurgicalOrder.Model>

    const query = reorderSurgeryMutation

    const httpResponse = await apiRepository.post({
      url: '/graphql',
      body: {
        query: query.query,
        variables: makeGraphQLVariable(params)
      },
      query: query.name
    })

    if (
      httpResponse.statusCode &&
      httpResponse.statusCode !== ApiStatusCode.ok
    ) {
      throw handleGraphQLError(RepositoryErrors[httpResponse.error!])
    } else {
      return httpResponse.body as ReorderSurgicalOrder.Model
    }
  }

  async updateSurgicalOrder(
    params: UpdateSurgicalOrder.Params
  ): Promise<UpdateSurgicalOrder.Model> {
    const apiRepository = this
      .apiRepository as IApiRepository<UpdateSurgicalOrder.Model>

    const query = updateSurgicalOrderMutation

    const httpResponse = await apiRepository.post({
      url: '/graphql',
      body: {
        query: query.query,
        variables: makeGraphQLVariable(params)
      },
      query: query.name
    })

    if (
      httpResponse.statusCode &&
      httpResponse.statusCode !== ApiStatusCode.ok
    ) {
      throw handleGraphQLError(RepositoryErrors[httpResponse.error!])
    } else {
      return httpResponse.body!
    }
  }

  async loadSurgeries(
    params: LoadSurgeries.Params
  ): Promise<LoadSurgeries.Model> {
    const apiRepository = this
      .apiRepository as IApiRepository<LoadSurgeries.Model>

    const query = loadSurgeriesQuery(params)

    const httpResponse = await apiRepository.post({
      url: '/graphql',
      body: {
        query: query.query,
        variables: {
          ...makeGraphQLVariable(params.pagination, 'pagination'),
          ...makeGraphQLVariable(params.name, 'name')
        }
      },
      query: query.name
    })

    if (
      httpResponse.statusCode &&
      httpResponse.statusCode !== ApiStatusCode.ok
    ) {
      throw handleGraphQLError(RepositoryErrors[httpResponse.error!])
    } else {
      return httpResponse.body as LoadSurgeries.Model
    }
  }

  async countSurgicalOrdersByStatus(
    params: CountSurgicalOrdersByStatus.Params
  ): Promise<CountSurgicalOrdersByStatus.Model> {
    const apiRepository = this
      .apiRepository as IApiRepository<CountSurgicalOrdersByStatus.Model>

    const query = countSurgicalOrdersByStatus(params)

    const httpResponse = await apiRepository.post({
      url: '/graphql',
      body: {
        query: query.query,
        variables: {
          ...makeGraphQLVariable(
            { startDate: params.startDate, endDate: params.endDate },
            'period'
          )
        }
      },
      query: query.name
    })

    if (
      httpResponse.statusCode &&
      httpResponse.statusCode !== ApiStatusCode.ok
    ) {
      throw handleGraphQLError(RepositoryErrors[httpResponse.error!])
    } else {
      return httpResponse.body as CountSurgicalOrdersByStatus.Model
    }
  }

  async concludeSurgicalOrder(
    params: ConcludeSurgicalOrder.Params
  ): Promise<ConcludeSurgicalOrder.Model> {
    const apiRepository = this
      .apiRepository as IApiRepository<ConcludeSurgicalOrder.Model>

    const query = concludeSurgicalOrderMutation

    const httpResponse = await apiRepository.post({
      url: '/graphql',
      body: {
        query: query.query,
        variables: {
          ...makeGraphQLVariable(params.data)
        }
      },
      query: query.name
    })

    if (
      httpResponse.statusCode &&
      httpResponse.statusCode !== ApiStatusCode.ok
    ) {
      throw handleGraphQLError(RepositoryErrors[httpResponse.error!])
    } else {
      return httpResponse.body as ConcludeSurgicalOrder.Model
    }
  }

  async deleteSurgicalOrderSolicitation(
    params: DeleteSurgicalOrderSolicitation.Params
  ): Promise<boolean> {
    const apiRepository = this
      .apiRepository as IApiRepository<DeleteSurgicalOrderSolicitation.Model>

    const query = deleteSurgicalOrderSolicitationMutation

    const httpResponse = await apiRepository.post({
      url: '/graphql',
      body: {
        query: query.query,
        variables: {
          ...makeGraphQLVariable(params.justification, 'justification'),
          ...makeGraphQLVariable(params.surgical_order_id, 'surgical_order_id')
        }
      },
      query: query.name
    })

    if (
      httpResponse.statusCode &&
      httpResponse.statusCode !== ApiStatusCode.ok
    ) {
      throw handleGraphQLError(RepositoryErrors[httpResponse.error!])
    } else {
      return httpResponse.body as DeleteSurgicalOrderSolicitation.Model
    }
  }

  async checkin(
    params: SurgicalOrderCheckin.Params
  ): Promise<SurgicalOrderCheckin.Model> {
    const apiRepository = this
      .apiRepository as IApiRepository<SurgicalOrderCheckin.Model>

    const query = checkinMutation

    const httpResponse = await apiRepository.post({
      url: '/graphql',
      body: {
        query: query.query,
        variables: {
          ...makeGraphQLVariable(params.surgical_order_id, 'surgical_order_id')
        }
      },
      query: query.name
    })

    if (
      httpResponse.statusCode &&
      httpResponse.statusCode !== ApiStatusCode.ok
    ) {
      throw handleGraphQLError(RepositoryErrors[httpResponse.error!])
    } else {
      return httpResponse.body as SurgicalOrderCheckin.Model
    }
  }

  async uploadSurgicalOrderDocumentsSameType(
    params: UploadSurgicalOrderDocumentsSameType.Params
  ): Promise<UploadSurgicalOrderDocumentsSameType.Model> {
    const apiRepository = this
      .apiRepository as IApiRepository<UploadSurgicalOrderDocumentsSameType.Model>

    const httpResponse = await apiRepository.post({
      url: '/surgical-order/upload-same-type-documents',
      body: params.form
    })

    if (
      httpResponse.statusCode &&
      httpResponse.statusCode !== ApiStatusCode.created
    ) {
      throw handleGraphQLError(RepositoryErrors[httpResponse.error!])
    } else {
      return httpResponse.body as UploadSurgicalOrderDocumentsSameType.Model
    }
  }

  async updateSurgicalOrderRegenerateGuides(
    params: UpdateSurgicalOrderRegenerateGuides.Params
  ): Promise<UpdateSurgicalOrderRegenerateGuides.Model> {
    const solicitations = params?.opme?.solicitations.map((solicitation) => ({
      description: solicitation.description,
      quantity: Number(solicitation.quantity)
    }))

    const apiRepository = this
      .apiRepository as IApiRepository<UpdateSurgicalOrderRegenerateGuides.Model>

    const query = updateSurgicalOrderRegenerateGuidesMutation

    const httpResponse = await apiRepository.post({
      url: '/graphql',
      body: {
        query: query.query,
        variables: {
          ...makeGraphQLVariable(
            {
              healthInsurance: {
                ...params.healthInsurance,
                health_insurance_id: params.healthInsurance.healthInsuranceCode
              },
              surgical_order_id: params.surgical_order_id,
              patient: {
                name: params.patient.name,
                gender: params.patient.gender,
                birthday: params.patient.birthday,
                email: params.patient.email || '',
                phone: params.patient.phone || '',
                landlinePhone: params.patient.landlinePhone
              },
              opme: { ...params.opme, solicitations }
            },
            'input'
          )
        }
      },
      query: query.name
    })

    if (
      httpResponse.statusCode &&
      httpResponse.statusCode !== ApiStatusCode.ok
    ) {
      throw handleGraphQLError(RepositoryErrors[httpResponse.error!])
    } else {
      return httpResponse.body as UpdateSurgicalOrderRegenerateGuides.Model
    }
  }

  async registerSecretarySurgicalOrder(
    params: RegisterSurgicalOrder.Params
  ): Promise<RegisterSurgicalOrder.Model> {
    const apiRepository = this
      .apiRepository as IApiRepository<RegisterSurgicalOrder.Model>
    const query = registerSecretarySurgicalOrderMutation
    const httpResponse = await apiRepository.post({
      url: '/graphql',
      body: {
        query: query.query,
        variables: makeGraphQLVariable(params)
      },
      query: query.name
    })
    if (
      httpResponse.statusCode &&
      httpResponse.statusCode !== ApiStatusCode.ok
    ) {
      throw handleGraphQLError(RepositoryErrors[httpResponse.error!])
    } else {
      return httpResponse.body as RegisterSurgicalOrder.Model
    }
  }

  async loadFavoriteSurgicalOrders(
    params: LoadFavoriteSurgicalOrders.Params
  ): Promise<LoadFavoriteSurgicalOrders.Model> {
    const apiRepository = this
      .apiRepository as IApiRepository<LoadFavoriteSurgicalOrders.Model>

    const query = loadFavoriteSurgicalOrdersQuery(params)

    const httpResponse = await apiRepository.post({
      url: '/graphql',
      body: {
        query: query.query,
        variables: makeGraphQLVariable(params)
      },
      query: query.name
    })

    if (
      httpResponse.statusCode &&
      httpResponse.statusCode !== ApiStatusCode.ok
    ) {
      throw handleGraphQLError(RepositoryErrors[httpResponse.error!])
    } else {
      return httpResponse.body as LoadFavoriteSurgicalOrders.Model
    }
  }

  async loadFavoriteDoctorSurgicalOrders(
    params: LoadFavoriteDoctorSurgicalOrders.Params
  ): Promise<LoadFavoriteDoctorSurgicalOrders.Model> {
    const apiRepository = this
      .apiRepository as IApiRepository<LoadFavoriteDoctorSurgicalOrders.Model>

    const query = loadFavoriteDoctorOrdersQuery(params)

    const httpResponse = await apiRepository.post({
      url: '/graphql',
      body: {
        query: query.query,
        variables: makeGraphQLVariable(params)
      },
      query: query.name
    })

    if (
      httpResponse.statusCode &&
      httpResponse.statusCode !== ApiStatusCode.ok
    ) {
      throw handleGraphQLError(RepositoryErrors[httpResponse.error!])
    } else {
      return httpResponse.body as LoadFavoriteSurgicalOrders.Model
    }
  }

  async deleteFavoritedSurgicalOrder(
    params: DeleteFavoritedSurgicalOrder.Params
  ): Promise<DeleteFavoritedSurgicalOrder.Model> {
    const apiRepository = this
      .apiRepository as IApiRepository<DeleteFavoritedSurgicalOrder.Model>

    const query = deleteFavoritedSurgicalOrderMutation

    const httpResponse = await apiRepository.post({
      url: '/graphql',
      body: {
        query: query.query,
        variables: makeGraphQLVariable(params.data, 'data')
      },
      query: query.name
    })

    if (!httpResponse.body) {
      throw handleGraphQLError(RepositoryErrors[httpResponse.error!])
    } else {
      return httpResponse.body as DeleteFavoritedSurgicalOrder.Model
    }
  }

  async uploadToMergeDocument(
    params: UploadToMergeDocument.Params
  ): Promise<UploadToMergeDocument.Model> {
    const apiRepository = this
      .apiRepository as IApiRepository<UploadToMergeDocument.Model>
    const form = new FormData()
    form.append('file', params.file)
    form.append('group_id', params.group_id)
    form.append('type', params.type)
    const httpResponse = await apiRepository.post({
      url: '/surgical-order/upload-document-to-merge',
      body: form
    })

    if (httpResponse.body) {
      const { code } = httpResponse.body
      if (code) {
        throw handleGraphQLError(RepositoryErrors[code])
      }
    }

    if (
      httpResponse.statusCode &&
      httpResponse.statusCode !== ApiStatusCode.created
    ) {
      throw handleGraphQLError(RepositoryErrors[httpResponse.error!])
    } else {
      return httpResponse.body as UploadToMergeDocument.Model
    }
  }

  async confirmMergeDocuments(
    params: ConfirmMergeDocuments.Params
  ): Promise<boolean> {
    const apiRepository = this
      .apiRepository as IApiRepository<ConfirmMergeDocuments.Model>

    const query = confirmMergeDocumentsMutation

    const httpResponse = await apiRepository.post({
      url: '/graphql',
      body: {
        query: query.query,
        variables: {
          ...makeGraphQLVariable(params.group_id, 'group_id'),
          ...makeGraphQLVariable(params.surgical_order_id, 'surgical_order_id')
        }
      },
      query: query.name
    })

    if (
      httpResponse.statusCode &&
      httpResponse.statusCode !== ApiStatusCode.ok
    ) {
      throw handleGraphQLError(RepositoryErrors[httpResponse.error!])
    } else {
      return httpResponse.body as ConfirmMergeDocuments.Model
    }
  }

  async deleteUploadedDocuments(
    params: DeleteUploadedDocuments.Params
  ): Promise<boolean> {
    const apiRepository = this
      .apiRepository as IApiRepository<DeleteUploadedDocuments.Model>

    const query = deleteUploadedDocumentsMutation

    const httpResponse = await apiRepository.post({
      url: '/graphql',
      body: {
        query: query.query,
        variables: makeGraphQLVariable(params.document_id, 'document_id')
      },
      query: query.name
    })

    if (
      httpResponse.statusCode &&
      httpResponse.statusCode !== ApiStatusCode.ok
    ) {
      throw handleGraphQLError(RepositoryErrors[httpResponse.error!])
    } else {
      return httpResponse.body as DeleteUploadedDocuments.Model
    }
  }

  async countScheduledAndUnscheduledSurgicalOrders(
    params: CountScheduledAndUnscheduledSurgicalOrders.Params
  ): Promise<CountScheduledAndUnscheduledSurgicalOrders.Model> {
    const apiRepository = this
      .apiRepository as IApiRepository<CountScheduledAndUnscheduledSurgicalOrders.Model>

    const query = countScheduledAndUnscheduledSurgicalOrders(params)

    const httpResponse = await apiRepository.post({
      url: '/graphql',
      body: {
        query: query.query,
        variables: {
          ...makeGraphQLVariable(
            {
              startDate: params.startDate,
              endDate: params.endDate
            },
            'period'
          )
        }
      },
      query: query.name
    })

    if (
      httpResponse.statusCode &&
      httpResponse.statusCode !== ApiStatusCode.ok
    ) {
      throw handleGraphQLError(RepositoryErrors[httpResponse.error!])
    } else {
      return httpResponse.body as CountScheduledAndUnscheduledSurgicalOrders.Model
    }
  }

  async loadGuidesByType(
    surgical_order_id: number,
    type: string,
    params: LoadGuidesByType.Params
  ): Promise<LoadGuidesByType.Model> {
    const apiRepository = this
      .apiRepository as IApiRepository<LoadGuidesByType.Model>

    const query = loadGuidesByType(params)

    const httpResponse = await apiRepository.post({
      url: '/graphql',
      body: {
        query: query.query,
        variables: {
          ...makeGraphQLVariable(surgical_order_id, 'surgical_order_id'),
          ...makeGraphQLVariable(type, 'type')
        }
      },
      query: query.name
    })

    if (
      httpResponse.statusCode &&
      httpResponse.statusCode !== ApiStatusCode.ok
    ) {
      throw handleGraphQLError(RepositoryErrors[httpResponse.error!])
    } else {
      return httpResponse.body as LoadGuidesByType.Model
    }
  }

  async loadDashboardStatusCount(): Promise<LoadDashboardStatusCount.Model> {
    const apiRepository = this
      .apiRepository as IApiRepository<LoadDashboardStatusCount.Model>

    const httpResponse: any = await apiRepository.get({
      url: '/surgical-order/doctor/dashboard/status-count'
    })

    return httpResponse.body as LoadDashboardStatusCount.Model
  }

  async loadSurgicalOrdersByDoctorAndStatus(
    params: LoadSurgicalOrdersByDoctorAndStatus.Params
  ): Promise<LoadSurgicalOrdersByDoctorAndStatus.Model> {
    const apiRepository = this
      .apiRepository as IApiRepository<LoadSurgicalOrdersByDoctorAndStatus.Model>

    const httpResponse: any = await apiRepository.get({
      url: '/surgical-order/by-doctor-and-status',
      params
    })

    return httpResponse.body as LoadSurgicalOrdersByDoctorAndStatus.Model
  }

  async loadSurgicalOrderNoticeCard(
    params: LoadSurgicalOrderNoticeCard.Params
  ): Promise<LoadSurgicalOrderNoticeCard.Model> {
    const apiRepository = this
      .apiRepository as IApiRepository<LoadSurgicalOrderNoticeCard.Model>

    const httpResponse: any = await apiRepository.get({
      url: '/surgical-order/notice-card',
      params
    })

    return httpResponse.body as LoadSurgicalOrderNoticeCard.Model
  }

  async getSurgicalSchedulesByDoctor(
    params: GetSurgicalSchedulesByDoctor.Params
  ): Promise<GetSurgicalSchedulesByDoctor.Model> {
    const apiRepository = this
      .apiRepository as IApiRepository<GetSurgicalSchedulesByDoctor.Model>

    const httpResponse: any = await apiRepository.get({
      url: '/surgical-schedules/get-surgical-schedules-by-doctor',
      params
    })

    if (httpResponse.statusCode !== ApiStatusCode.ok) {
      throw new InternalServerError()
    } else {
      return httpResponse.body as GetSurgicalSchedulesByDoctor.Model
    }
  }

  async getSurgicalSchedulesCountByDoctor(): Promise<GetSurgicalSchedulesCountByDoctor.Model> {
    const apiRepository = this
      .apiRepository as IApiRepository<GetSurgicalSchedulesCountByDoctor.Model>

    const httpResponse: any = await apiRepository.get({
      url: '/surgical-schedules/get-surgical-schedules-count-by-doctor'
    })

    if (httpResponse.statusCode !== ApiStatusCode.ok) {
      throw new InternalServerError()
    } else {
      return httpResponse.body as GetSurgicalSchedulesCountByDoctor.Model
    }
  }

  async getSurgicalOrderTimeline(
    params: GetSurgicalOrderTimeline.Params
  ): Promise<GetSurgicalOrderTimeline.Model> {
    const apiRepository = this
      .apiRepository as IApiRepository<GetSurgicalOrderTimeline.Model>

    const httpResponse: any = await apiRepository.get({
      url: '/surgical-order/time-line',
      params
    })

    if (httpResponse.statusCode !== ApiStatusCode.ok) {
      throw new InternalServerError()
    } else {
      return httpResponse.body as GetSurgicalOrderTimeline.Model
    }
  }

  async loadExpirationDateStatusDashboardCounts(): Promise<LoadExpirationDateStatusDashboardCounts.Model> {
    const apiRepository = this
      .apiRepository as IApiRepository<LoadExpirationDateStatusDashboardCounts.Model>

    const httpResponse: any = await apiRepository.get({
      url: '/finalize-surgical-order/doctor/dashboard/status-count'
    })

    if (httpResponse.statusCode !== ApiStatusCode.ok) {
      throw new InternalServerError()
    } else {
      return httpResponse.body as LoadExpirationDateStatusDashboardCounts.Model
    }
  }

  async updateSurgicalOrderNew(
    params: UpdateSurgicalOrderNew.Params
  ): Promise<UpdateSurgicalOrderNew.Model> {
    const apiRepository = this
      .apiRepository as IApiRepository<UpdateSurgicalOrderNew.Model>

    const httpResponse: any = await apiRepository.put({
      url: `/surgical-order/doctor/update/${params.surgical_order_id}`,
      body: params
    })

    if (
      httpResponse.statusCode &&
      httpResponse.statusCode !== ApiStatusCode.noContent
    ) {
      throw handleGraphQLError(
        RepositoryErrors[httpResponse.error! as keyof typeof RepositoryErrors]
      )
    } else {
      return httpResponse.body as UpdateSurgicalOrderNew.Model
    }
  }

  async loadHospitalsByDoctorCpf(
    cpf: string,
    params: LoadHospitalsByDoctorCpf.Params
  ): Promise<LoadHospitalsByDoctorCpf.Model> {
    const apiRepository = this
      .apiRepository as IApiRepository<LoadHospitalsByDoctorCpf.Model>

    const query = loadHospitalsByDoctorCpfQuery(params!)

    const httpResponse = await apiRepository.post({
      url: '/graphql',
      body: {
        query: query.query,
        variables: makeGraphQLVariable(cpf, 'cpf')
      },
      query: query.name
    })

    if (
      httpResponse.statusCode &&
      httpResponse.statusCode !== ApiStatusCode.ok
    ) {
      throw handleGraphQLError(RepositoryErrors[httpResponse.error!])
    } else {
      return httpResponse.body as LoadHospitalsByDoctorCpf.Model
    }
  }

  async createSurgicalOrder(
    params: CreateSurgicalOrder.Params
  ): Promise<CreateSurgicalOrder.Model> {
    const apiRepository = this
      .apiRepository as IApiRepository<CreateSurgicalOrder.Model>

    const httpResponse: any = await apiRepository.post({
      url: '/surgical-order/doctor/create',
      body: params
    })

    if (httpResponse.statusCode !== ApiStatusCode.created) {
      throw handleGraphQLError(
        RepositoryErrors[httpResponse.error! as keyof typeof RepositoryErrors]
      )
    } else {
      return httpResponse.body.data as CreateSurgicalOrder.Model
    }
  }

  async getSurgicalOrderById(
    params: GetSurgicalOrderById.Params
  ): Promise<GetSurgicalOrderById.Model> {
    const apiRepository = this
      .apiRepository as IApiRepository<GetSurgicalOrderById.Model>

    const httpResponse: any = await apiRepository.get({
      url: `/surgical-order/doctor/${params.surgical_order_id}`
    })

    if (
      httpResponse.statusCode &&
      httpResponse.statusCode !== ApiStatusCode.ok
    ) {
      throw handleGraphQLError(
        RepositoryErrors[httpResponse.error! as keyof typeof RepositoryErrors]
      )
    } else {
      return httpResponse.body.data as GetSurgicalOrderById.Model
    }
  }

  async uploadSurgicalOrderDocumentNew(
    params: UploadSurgicalOrderDocumentNew.Params
  ): Promise<UploadSurgicalOrderDocumentNew.Model> {
    const apiRepository = this
      .apiRepository as IApiRepository<UploadSurgicalOrderDocumentNew.Model>

    const httpResponse: any = await apiRepository.post({
      url: '/documents/upload',
      body: params.form
    })

    if (httpResponse.statusCode !== ApiStatusCode.created) {
      throw handleGraphQLError(
        RepositoryErrors[httpResponse.error! as keyof typeof RepositoryErrors]
      )
    } else {
      return httpResponse.body.data as UploadSurgicalOrderDocumentNew.Model
    }
  }

  async deleteUploadedDocumentNew(
    params: DeleteUploadedDocumentsNew.Params
  ): Promise<DeleteUploadedDocumentsNew.Model> {
    const apiRepository = this
      .apiRepository as IApiRepository<DeleteUploadedDocumentsNew.Model>

    const httpResponse: any = await apiRepository.delete({
      url: `/documents/${params.document_id}`,
      params
    })

    if (
      httpResponse.statusCode &&
      httpResponse.statusCode !== ApiStatusCode.noContent
    ) {
      throw handleGraphQLError(
        RepositoryErrors[httpResponse.error! as keyof typeof RepositoryErrors]
      )
    } else {
      return httpResponse.body.data as DeleteUploadedDocumentsNew.Model
    }
  }

  async searchProcedures(
    params: SearchProcedures.Params
  ): Promise<SearchProcedures.Model> {
    const apiRepository = this
      .apiRepository as IApiRepository<SearchProcedures.Model>

    const httpResponse: any = await apiRepository.get({
      url: '/procedures/search',
      params
    })

    if (
      httpResponse.statusCode &&
      httpResponse.statusCode !== ApiStatusCode.ok
    ) {
      throw handleGraphQLError(
        RepositoryErrors[httpResponse.error! as keyof typeof RepositoryErrors]
      )
    } else {
      return httpResponse.body.data.data as SearchProcedures.Model
    }
  }

  async searchCID(params: SearchCID.Params): Promise<SearchCID.Model> {
    const apiRepository = this.apiRepository as IApiRepository<SearchCID.Model>

    const httpResponse: any = await apiRepository.get({
      url: '/cids/search',
      params
    })

    if (
      httpResponse.statusCode &&
      httpResponse.statusCode !== ApiStatusCode.ok
    ) {
      throw handleGraphQLError(
        RepositoryErrors[httpResponse.error! as keyof typeof RepositoryErrors]
      )
    } else {
      return httpResponse.body.data.data as SearchCID.Model
    }
  }

  async loadAnesthesiologies(): Promise<LoadAnesthesiologies.Model> {
    const apiRepository = this
      .apiRepository as IApiRepository<LoadAnesthesiologies.Model>

    const httpResponse: any = await apiRepository.get({
      url: '/anesthesiologies'
    })

    if (
      httpResponse.statusCode &&
      httpResponse.statusCode !== ApiStatusCode.ok
    ) {
      throw handleGraphQLError(
        RepositoryErrors[httpResponse.error! as keyof typeof RepositoryErrors]
      )
    } else {
      return httpResponse.body.data as LoadAnesthesiologies.Model
    }
  }

  async finishSurgicalOrder(
    params: FinishSurgicalOrder.Params
  ): Promise<FinishSurgicalOrder.Model> {
    const apiRepository = this
      .apiRepository as IApiRepository<FinishSurgicalOrder.Model>

    const httpResponse: any = await apiRepository.post({
      url: '/surgical-order/doctor/finish',
      body: params
    })

    if (
      httpResponse.statusCode &&
      httpResponse.statusCode !== ApiStatusCode.created
    ) {
      throw handleGraphQLError(
        RepositoryErrors[httpResponse.error! as keyof typeof RepositoryErrors]
      )
    } else {
      return httpResponse.body.data as FinishSurgicalOrder.Model
    }
  }

  async requestSurgicalOrderCancellation(
    params: RequestSurgicalOrderCancellation.Params
  ): Promise<RequestSurgicalOrderCancellation.Model> {
    const apiRepository = this
      .apiRepository as IApiRepository<RequestSurgicalOrderCancellation.Model>

    const httpResponse: any = await apiRepository.post({
      url: `/surgical-order/${params.surgical_order_id}/cancel`,
      body: params
    })

    if (
      httpResponse.statusCode &&
      httpResponse.statusCode !== ApiStatusCode.created
    ) {
      throw handleGraphQLError(
        RepositoryErrors[httpResponse.error! as keyof typeof RepositoryErrors]
      )
    } else {
      return httpResponse.body.data as RequestSurgicalOrderCancellation.Model
    }
  }

  async getSurgicalOrderCancellationNotifications(): Promise<GetSurgicalOrderCancellationNotifications.Model> {
    const apiRepository = this
      .apiRepository as IApiRepository<GetSurgicalOrderCancellationNotifications.Model>

    const httpResponse: any = await apiRepository.get({
      url: '/surgical-order/cancel/notifications'
    })

    if (
      httpResponse.statusCode &&
      httpResponse.statusCode !== ApiStatusCode.ok
    ) {
      throw handleGraphQLError(
        RepositoryErrors[httpResponse.error! as keyof typeof RepositoryErrors]
      )
    } else {
      return httpResponse.body
        .data as GetSurgicalOrderCancellationNotifications.Model
    }
  }

  async getSurgicalOrderCancellationNotificationsCount(): Promise<GetSurgicalOrderCancellationNotificationsCount.Model> {
    const apiRepository = this
      .apiRepository as IApiRepository<GetSurgicalOrderCancellationNotificationsCount.Model>

    const httpResponse: any = await apiRepository.get({
      url: '/surgical-order/cancel/notifications/count'
    })

    if (
      httpResponse.statusCode &&
      httpResponse.statusCode !== ApiStatusCode.ok
    ) {
      throw handleGraphQLError(
        RepositoryErrors[httpResponse.error! as keyof typeof RepositoryErrors]
      )
    } else {
      return httpResponse.body
        .data as GetSurgicalOrderCancellationNotificationsCount.Model
    }
  }

  async addSurgicalOrderCancellationComment(
    params: AddSurgicalOrderCancellationComment.Params
  ): Promise<AddSurgicalOrderCancellationComment.Model> {
    const apiRepository = this
      .apiRepository as IApiRepository<AddSurgicalOrderCancellationComment.Model>

    const httpResponse: any = await apiRepository.post({
      url: `/surgical-order/cancel/comments/${params.surgical_cancellation_id}`,
      body: params
    })

    if (
      httpResponse.statusCode &&
      httpResponse.statusCode !== ApiStatusCode.created
    ) {
      throw handleGraphQLError(
        RepositoryErrors[httpResponse.error! as keyof typeof RepositoryErrors]
      )
    } else {
      return httpResponse.body.data as AddSurgicalOrderCancellationComment.Model
    }
  }

  async confirmReadCancellationRequests(): Promise<ConfirmReadCancellationRequests.Model> {
    const apiRepository = this
      .apiRepository as IApiRepository<ConfirmReadCancellationRequests.Model>

    const httpResponse: any = await apiRepository.put({
      url: '/surgical-order/cancel/notifications/read-all'
    })

    if (
      httpResponse.statusCode &&
      httpResponse.statusCode !== ApiStatusCode.noContent
    ) {
      throw handleGraphQLError(
        RepositoryErrors[httpResponse.error! as keyof typeof RepositoryErrors]
      )
    } else {
      return httpResponse.body.data as ConfirmReadCancellationRequests.Model
    }
  }
}
