import React, { useEffect, useContext } from 'react'
import SurgicalOrdersDashboardLayout from 'presentation/doctor/layouts/SurgicalOrdersDashboard'
import { SurgicalOrderDashboardContext } from 'main/factories/pages/doctor/surgical-order/surgical-order-dashboard-factory'
import { useServices } from 'presentation/hooks/use-services'
import {
  WithLoading,
  WithLoadingProps
} from 'presentation/shared/components/HOCs/WithLoading'
import { toast } from 'react-toastify'
import { LoadSurgicalOrdersByDoctorAndStatus } from 'domain/usecases/surgical-order/load-surgical-orders-by-doctor-and-status'

function SurgicalOrdersDashboard({ setIsLoading }: WithLoadingProps) {
  const surgicalOrderService = useServices().surgicalOrder
  const surgicalPendencyService = useServices().surgicalPendency
  const { dispatch } = useContext<any>(SurgicalOrderDashboardContext)

  const getStatusCount = async () => {
    try {
      const response_surgical_order_status: any =
        await surgicalOrderService.loadDashboardStatusCount()

      const response_password_expiration_status: any =
        await surgicalOrderService.loadExpirationDateStatusDashboardCounts()

      dispatch({
        type: 'STATUS_COUNT',
        payload: {
          ...response_surgical_order_status.data,
          ...response_password_expiration_status.data
        }
      })
    } catch (error: any) {
      toast.error('Ocorreu um erro ao buscar os status')
    }
  }

  const getPendencyCount = async () => {
    try {
      const response: any =
        await surgicalPendencyService.loadDashboardPendencyCount()

      dispatch({
        type: 'PENDENCY_COUNT',
        payload: response.data
      })
    } catch (error: any) {
      toast.error('Ocorreu um erro ao buscar as pendencias')
    }
  }

  const getSurgicalOrders = async (
    params: LoadSurgicalOrdersByDoctorAndStatus.Params
  ) => {
    setIsLoading(true)
    try {
      const response: any =
        await surgicalOrderService.loadSurgicalOrdersByDoctorAndStatus(params)

      dispatch({
        type: 'PAGINATION',
        payload: response.data.page_info
      })

      dispatch({
        type: 'SURGICAL_ORDERS_LIST',
        payload: response.data.surgical_orders,
        clearList: params.pageNumber === 1
      })
    } catch (error: any) {
      toast.error('Não foi possível carregar os pedidos cirúrgicos')
    } finally {
      setIsLoading(false)
    }
  }

  const deleteSurgicalOrder = async (surgical_order_id: number) => {
    setIsLoading(true)
    try {
      await surgicalOrderService.deleteSurgicalOrderSolicitation({
        surgical_order_id
      })

      await getSurgicalOrders({ pageNumber: 1 })
      toast.success('Pedido excluído com sucesso!')
    } catch {
      toast.error('Ocorreu um erro ao excluir o pedido cirurgico')
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    setIsLoading(true)
    Promise.all([
      getPendencyCount(),
      getStatusCount(),
      getSurgicalOrders({ pageNumber: 1 })
    ]).finally(() => setIsLoading(false))
  }, [])

  return (
    <SurgicalOrdersDashboardLayout
      getSurgicalOrders={getSurgicalOrders}
      deleteSurgicalOrder={deleteSurgicalOrder}
    />
  )
}

export default WithLoading(SurgicalOrdersDashboard)
