import React, { useEffect, useState } from 'react'
import { useFormik } from 'formik'
import * as yup from 'yup'
import 'main/config/yup'

import Button from 'presentation/shared/components/Button'
import Carousel, {
  CarouselState
} from 'presentation/shared/components/Carousel'
import { Container } from 'presentation/shared/components/Container'
import Heading from 'presentation/shared/components/Heading'
import SelectField from 'presentation/shared/components/SelectField'
import TextField from 'presentation/shared/components/TextField'

import * as S from './styles'

import { HealthInsurance } from 'domain/entities/health-insurance'
import { UpdatePatientHealthInsurance } from 'domain/usecases/patient/update-patient-health-insurance'
import { toast } from 'react-toastify'
import { useHistory } from 'react-router'
import { UploadPatientDocument } from 'domain/usecases/patient/upload-patient-document'
import { PatientDocument } from 'common/enum/patient-document'
import { UpdatePatientInfo } from 'domain/usecases/patient/update-patient-info'
import { sortBy } from 'lodash'
import {
  HealthInsurancePlans,
  Plans
} from 'domain/usecases/health-insurance/load-health-insurance-plans'
import { useUploadSection } from 'presentation/hooks/use-upload-section'
import { DocumentsType } from 'domain/entities/document-types'
import { ListFile } from 'presentation/shared/components/UploadSection/UploadListFiles/UploadListFile'
import { UploadSection } from 'presentation/shared/components/UploadSection'

type CarouselPersonalInfoFormProps = {
  initialValues?: PatientInsuranceInfoForm
  healthInsurances: HealthInsurancePlans[]
  uploadInsuranceCard?: UploadPatientDocument
  updatePatientInsurance?: UpdatePatientHealthInsurance
  updatePatientPersonalInfo?: UpdatePatientInfo
  setIsLoading: (isLoading: boolean) => void
}

export default function CarouselInsuranceInfoForm({
  initialValues = {} as PatientInsuranceInfoForm,
  healthInsurances,
  uploadInsuranceCard,
  updatePatientInsurance,
  setIsLoading
}: CarouselPersonalInfoFormProps) {
  const [carousel, setCarousel] = useState({} as CarouselState)
  const [plans, setPlans] = useState<Plans[]>([])
  const history = useHistory()

  const formik = useFormik<PatientInsuranceInfoForm>({
    initialValues: initialValues,
    validationSchema: CarouselPersonalInfoValidation,
    validateOnBlur: true,
    validateOnMount: true,
    onSubmit: async (values) => {
      try {
        setIsLoading(true)
        await Promise.all(
          (values.healthCardFiles || []).map(async ({ file }, index) => {
            const form = new FormData()
            const type = index
              ? PatientDocument.HEALTH_CARD_FRONT
              : PatientDocument.HEALTH_CARD_BACK
            form.append('file', file)
            form.append('type', type)
            form.append('patient_id', `${initialValues?.patient_id}`)
            return await uploadInsuranceCard?.upload({
              form,
              file,
              type
            })
          })
        )

        if (values.healthInsurance) {
          await updatePatientInsurance?.update({
            healthInsuranceCode: Number(
              values.healthInsurance.healthInsuranceCode
            ),
            healthInsuranceName: values.healthInsurance.healthInsuranceName,
            healthPlanCode: values.healthInsurance.healthPlanCode,
            healthPlanName: values.healthInsurance.healthPlanName,
            company: values.company,
            healthCard: values.healthCard,
            patient_id: initialValues?.patient_id
          })
        }

        toast.success('Dados alterados com sucesso')
        history.push('/pedido-cirurgico/save')
      } catch (err: any) {
        toast.error('Erro ao carregar cartão do convênio')
      } finally {
        setIsLoading(false)
      }
    }
  })

  useEffect(() => {
    if (initialValues) {
      formik.resetForm({
        values: initialValues
      })
      const findHealthInsurance = healthInsurances?.find(
        (insurance) =>
          insurance.code === initialValues.healthInsurance?.healthInsuranceCode
      )
      setPlans(findHealthInsurance?.plans || [])
      const verify = allVerifications(initialValues.healthCardFiles || [])
      if (verify.errorMessage.length !== 0) {
        formik.setFieldValue('healthCardFiles', [])
        resetInfo([])
      } else {
        resetInfo(initialValues.healthCardFiles)
      }
    }
  }, [initialValues])

  const handleChangeHealthInsurance = (insurance_id: number) => {
    try {
      const findHealthInsurance = healthInsurances?.find(
        (insurance) => insurance.code === insurance_id
      )
      if (findHealthInsurance) {
        const newHealthInsurance = new HealthInsurance(
          findHealthInsurance.code ?? 0,
          findHealthInsurance.description ?? '',
          findHealthInsurance?.plans?.[0]?.code ?? 0,
          ''
        )

        formik.setFieldValue('healthInsurance', newHealthInsurance)
      }
      setPlans(findHealthInsurance?.plans ?? [])
    } catch (e: any) {
      toast.error(e.message)
    }
  }

  const handlePlanChange = (planCode: string) => {
    const healthInsurance = healthInsurances.find((insurance) => {
      return (
        insurance.code === formik.values.healthInsurance?.healthInsuranceCode
      )
    })
    if (healthInsurance) {
      const findedInsurancePlan = healthInsurance.plans.find(
        (val) => val.code.toString() === planCode
      )
      if (findedInsurancePlan) {
        const updatedHealthInsuranceWithPlan = new HealthInsurance(
          healthInsurance.code,
          healthInsurance.description,
          findedInsurancePlan.code,
          findedInsurancePlan.description
        )
        formik.setFieldValue('healthInsurance', updatedHealthInsuranceWithPlan)
      }
    }
  }

  const setInitialHealthInsurance = () => {
    const healthInsuranceCode =
      initialValues.healthInsurance?.healthInsuranceCode
    const healthInsurancePlanCode =
      initialValues.healthInsurance?.healthPlanCode
    if (healthInsuranceCode) {
      handleChangeHealthInsurance(healthInsuranceCode)
      if (healthInsurancePlanCode) {
        handlePlanChange(healthInsurancePlanCode.toString())
      }
    }
  }
  useEffect(() => {
    setInitialHealthInsurance()
  }, [])

  const healthInsurancesList = () => {
    return healthInsurances?.map((insurance) => ({
      label: insurance?.description,
      value: insurance?.code?.toString()
    }))
  }

  const documentType = DocumentsType.healthCardDocumentType

  const {
    filesInfo,
    onAdd,
    onDelete,
    onDownload,
    onUpdate,
    resetInfo,
    allVerifications
  } = useUploadSection({
    maxFileSizeInMB: documentType.maxFileSizeInMB,
    maxFiles: documentType.maxFiles,
    extensionsAllowed: ['pdf', 'jpeg', 'jpg'],
    stateFiles: formik.values.healthCardFiles,
    setFilesFunction: (listFiles) => {
      formik.setFieldValue('healthCardFiles', listFiles)
    }
  })
  const canUpload = {
    canAdd: filesInfo.canAdd,
    canDelete: true,
    canDownload: true,
    canUpdate: true,
    canPreview: false
  }
  return (
    <Container
      actualPageOnClick={
        carousel.activeIndex === 0 ? history.goBack : carousel.slidePrev
      }
      actualPageText="Voltar"
    >
      <S.Wrapper role="form" onSubmit={formik.handleSubmit}>
        <Heading size="large" as="h1">
          Dados do convênio
        </Heading>
        <Carousel
          state={carousel}
          setState={setCarousel}
          slidesPerView={1}
          touch={false}
        >
          <S.Step>
            <S.Content>
              <SelectField
                name="healthInsurance"
                label="Selecione o convênio"
                items={healthInsurancesList()}
                onInputChange={(e) => handleChangeHealthInsurance(Number(e))}
                onBlur={formik.handleBlur('healthInsurance')}
                error={
                  formik.touched.healthInsurance &&
                  formik.errors.healthInsurance
                }
                value={
                  formik.values.healthInsurance?.healthInsuranceCode?.toString() ||
                  ''
                }
                required
              />
              <SelectField
                style={{ marginTop: '22px' }}
                name="healthInsurancePlan"
                label="Plano"
                disabled={plans.length === 0}
                onInputChange={(e) => handlePlanChange(e)}
                items={sortBy(
                  plans?.map((plan) => ({
                    label: plan.description,
                    value: plan.code
                  })),
                  (val) => val.label
                )}
                value={formik.values.healthInsurance?.healthPlanCode}
              />
              <TextField
                style={{ marginTop: '22px' }}
                name="company"
                placeholder="Nome da empresa"
                label="Empresa (titular do plano)"
                onInputChange={formik.handleChange('company')}
                onBlur={formik.handleBlur('company')}
                defaultValue={formik.values.company}
              />
              <TextField
                style={{ marginTop: '22px' }}
                name="healthCard"
                placeholder="Número da carteirinha"
                label="Número da carteirinha"
                onInputChange={formik.handleChange('healthCard')}
                onBlur={formik.handleBlur('healthCard')}
                defaultValue={formik.values.healthCard}
                maxLength={25}
              />
            </S.Content>
            <S.Buttons>
              <Button
                disabled={!formik.values.healthInsurance?.healthInsuranceCode}
                fullWidth
                type="button"
                onClick={carousel.slideNext}
              >
                Confirmar
              </Button>
            </S.Buttons>
          </S.Step>
          <S.Step>
            <S.Content>
              <UploadSection
                can={canUpload}
                files={formik.values.healthCardFiles || []}
                onAdd={onAdd}
                onDelete={onDelete}
                onDownload={onDownload}
                onUpdate={onUpdate}
                errors={filesInfo.errorMessage}
                acceptFiles={'application/pdf, image/jpeg'}
              />
            </S.Content>
            <S.Buttons>
              <Button
                fullWidth
                type="submit"
                disabled={!(formik.values.healthCardFiles?.length === 2)}
              >
                Confirmar
              </Button>
            </S.Buttons>
          </S.Step>
        </Carousel>
      </S.Wrapper>
    </Container>
  )
}

export type DocumentHealthInsurance = {
  name?: string
  type?: string
  document_id?: number
  documentUri?: string
  sendedAt?: string
}

export type PatientInsuranceInfoForm = {
  company?: string
  healthInsurance?: HealthInsurance
  healthCardFiles?: ListFile<PatientDocument>[]
  documentHealthInsurance?: DocumentHealthInsurance[]
  healthCard: string
  patient_id?: number
}

const CarouselPersonalInfoValidation = yup.object().shape({
  healthInsurance: yup.object().required()
})
