import React, { useEffect, useContext } from 'react'
import SurgicalSchedulingAdvertisingLayout from 'presentation/doctor/layouts/SurgicalSchedulingAdvertising'
import { useServices } from 'presentation/hooks/use-services'
import { useStores } from 'presentation/hooks/use-stores'
import { useLocation } from 'react-router'
import { SurgicalSchedulinhContext } from 'main/factories/pages/doctor/surgical-scheduling/surgical-scheduling-factory'
import { useHistory } from 'react-router'
import { LocalStorageAdapter } from 'infra/storage-adapter/storage-adapter'
import HeaderNew from 'presentation/shared/components/HeaderNew'

type LocationState = {
  surgical_order_id: number
}

function SurgicalSchedulingAdvertisin() {
  const userService = useServices().user
  const currentAccount = useStores().currentAccount
  const currentUser = currentAccount.getCurrentAccount()
  const { state } = useLocation<LocationState>()
  const { dispatch } = useContext<any>(SurgicalSchedulinhContext)
  const history = useHistory()

  const setBannerVisualized = () => {
    userService.setSurgicalSchedulingBannerVisualized()
  }

  const localStorageAdapter = new LocalStorageAdapter()
  const userFlags = localStorageAdapter.get('user-flags')

  useEffect(() => {
    dispatch({
      type: 'INFORMATIONS',
      payload: {
        ...state,
        requested_by_id: currentUser.user.user_id,
        requested_by_name: currentUser.user.name
      }
    })

    if (userFlags.bannerViewedByDoctor) {
      history.push('/agendamento-cirurgico/informacoes')
    }
  }, [])

  return (
    <>
      <HeaderNew actualPageTitle="Agendar cirurgia" />
      <SurgicalSchedulingAdvertisingLayout
        setBannerVisualized={setBannerVisualized}
      />
    </>
  )
}

export default SurgicalSchedulingAdvertisin
