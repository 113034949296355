import { LoadCid } from 'domain/usecases/surgical-order/load-cid'
import TherapeuticPlanDiagnosisForm from 'presentation/doctor/components/Forms/AddTherapeuticPlan/NewTherapeuticPlan/TherapeuticPlanDiagnosis'
import { Container } from 'presentation/shared/components/Container'
import Header from 'presentation/shared/components/Header'
import React from 'react'

type Props = {
  loadCid: LoadCid
}

export default function TherapeuticPlanDiagnosis({ loadCid }: Props) {
  return (
    <>
      <Header />
      <Container>
        <TherapeuticPlanDiagnosisForm loadCid={loadCid} />
      </Container>
    </>
  )
}
