import { UpdatePatient } from 'domain/usecases/patient/update-patient'
import { PatientRepository } from 'repository/interfaces/patient-repository'

export class RemoteUpdatePatient implements UpdatePatient {
  constructor(private readonly patientRepository: PatientRepository) {}

  update(params: UpdatePatient.Params): Promise<UpdatePatient.Model> {
    return this.patientRepository.updatePatient(params)
  }
}
