import styled from 'styled-components'

export const FileInput = styled.input<{ ref: any }>`
  display: none;
`

export const InputLabel = styled.label``

export const Errors = styled.ul`
  margin-top: 8px;
  list-style: none;

  li {
    color: ${({ theme }) => theme.ds.colors.danger400};
    font-size: ${({ theme }) => theme.ds.typography.size.xsmall};
    line-height: 1.3;

    &:not(:first-of-type) {
      margin-top: 8px;
    }
  }
`
