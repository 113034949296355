import { SurgicalOrderStatus } from 'presentation/shared/components/Timeline/status'

export const surgicalsOrdersStatus = [
  {
    label: 'Pedidos solicitados por pacientes',
    value: SurgicalOrderStatus.ORDER_REGISTERED
  },
  {
    label: 'Revisão',
    value: SurgicalOrderStatus.REVIEW
  },
  {
    label: 'Pré-agendamento',
    value: SurgicalOrderStatus.PRE_SCHEDULE
  },
  {
    label: 'Cotação de OPME',
    value: SurgicalOrderStatus.OPME_QUOTATION
  },
  {
    label: 'Na CRMO',
    value: SurgicalOrderStatus.CRMO
  },
  {
    label: 'No convênio',
    value: SurgicalOrderStatus.HEALTH_INSURANCE_APPROVE
  },
  {
    label: 'Autorizado',
    value: SurgicalOrderStatus.AUTHORIZED
  },
  {
    label: 'Realizado',
    value: SurgicalOrderStatus.CONCLUDED
  },
  {
    label: 'Solicitações de cancelamento',
    value: SurgicalOrderStatus.CANCELLATION_REQUEST
  },
  {
    label: 'Cancelados',
    value: SurgicalOrderStatus.CANCELLED
  },
  {
    label: 'Pendências',
    value: SurgicalOrderStatus.PENDENCY
  },
  {
    label: 'Pedido cadastrado',
    value: SurgicalOrderStatus.ORDER_REGISTERED
  },
  {
    label: 'Pedido agendado',
    value: SurgicalOrderStatus.SCHEDULED
  }
]

export const getSurgicalOrderStatusLabelByEnum = (
  surgeryStatusEnum: string
) => {
  const surgicalOrderStatus = surgicalsOrdersStatus.find(
    (e) => e.value === surgeryStatusEnum
  )
  return surgicalOrderStatus ? surgicalOrderStatus.label : ''
}

export const getSurgicalOrderStatusEnumByLabel = (label: string) => {
  const surgicalOrderStatus = surgicalsOrdersStatus.find(
    (e) => e.label === label
  )
  return surgicalOrderStatus ? surgicalOrderStatus.value : ''
}
