import React from 'react'

import UpdateDoctorPersonalInfoForm from 'presentation/doctor/components/Forms/UpdatePersonalInfo'
import { Doctor } from 'domain/entities/doctor-model'

type Props = {
  data: Doctor
}
export default function UpdateDoctorPersonalInfoLayout({ data }: Props) {
  return (
    <>
      <UpdateDoctorPersonalInfoForm initialValues={data} />
    </>
  )
}
