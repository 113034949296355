import { GetPredictedTime } from 'domain/usecases/surgical-scheduling/get-predicted-time'
import { SurgicalSchedulingRepository } from 'repository/interfaces/surgical-scheduling-repository'

export class RemoteGetPredictedTime implements GetPredictedTime {
  constructor(
    private readonly surgicalSchedulingRepository: SurgicalSchedulingRepository
  ) {}

  getPredictedTime(
    params: GetPredictedTime.Params
  ): Promise<GetPredictedTime.Model> {
    return this.surgicalSchedulingRepository.getPredictedTime(params)
  }
}
