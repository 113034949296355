import React, { useState } from 'react'

import {
  WithLoading,
  WithLoadingProps
} from 'presentation/shared/components/HOCs/WithLoading'
import TextFieldNew from 'presentation/shared/components/TextFieldNew'
import ButtonNew from 'presentation/shared/components/ButtonNew'
import CardNew from 'presentation/shared/components/CardNew'
import { ContainerNew } from 'presentation/shared/components/ContainerNew'
import { ReactComponent as ArrowIcon } from 'presentation/assets/icons/open-arrow.svg'
import SupportTextNew from 'presentation/shared/components/SupportTextNew'
import * as S from '../styles'
import * as LocalStyles from './styles'
import { useHistory, useLocation } from 'react-router'
import Divider from 'presentation/shared/components/DividerNew'
import HeadingNew from 'presentation/shared/components/HeadingNew'
import { TitleAndSubtitleWrapper } from 'presentation/shared/components/ContainerTitleAndSubtitle'
import RadioButtonNew from 'presentation/shared/components/RadioButtonNew'
import { useStores } from 'presentation/hooks/use-stores'
import { useFormik } from 'formik'
import { OpmeProps } from 'presentation/contexts/surgical-order/surgical-order-context'
import { toast } from 'react-toastify'
import { useServices } from 'presentation/hooks/use-services'

const SelectOPMEForm = WithLoading(({ setIsLoading }: WithLoadingProps) => {
  const { state } = useLocation<{
    isEditing?: boolean
  }>()
  const surgicalOrderContext = useStores().surgicalOrder
  const surgicalOrderService = useServices().surgicalOrder
  const history = useHistory()
  const [hasOpme, setHasOpme] = useState(
    surgicalOrderContext.getOpme() &&
      surgicalOrderContext.getOpme().solicitations
      ? surgicalOrderContext.getOpme().solicitations.length > 0
      : false
  )
  const [currentOpme, setCurrentOpme] = useState('')
  const [currentQuantity, setCurrentQuantity] = useState(1)
  const [currentProvider, setCurrentProvider] = useState('')

  const changeHasOpme = (hasOpme: string) => {
    if (hasOpme === true.toString()) {
      setHasOpme(true)
    } else {
      setHasOpme(false)
    }

    setCurrentOpme('')
    setCurrentQuantity(1)
    setCurrentProvider('')
  }

  const addOpme = () => {
    if (currentOpme && currentOpme && currentQuantity >= 1) {
      formik.setFieldValue('solicitations', [
        ...formik.values.solicitations,
        {
          description: currentOpme,
          quantity: currentQuantity
        }
      ])

      setCurrentOpme('')
      setCurrentQuantity(1)
    }
  }

  const removeOpme = (solicitationIndex: number) => {
    formik.setFieldValue(
      'solicitations',
      formik.values.solicitations.filter(
        (solicitation, index) => index !== solicitationIndex
      )
    )
  }

  const addProvider = () => {
    if (currentProvider) {
      formik.setFieldValue('providers', [
        ...formik.values.providers,
        currentProvider
      ])

      setCurrentProvider('')
    }
  }

  const removeProvider = (providerIndex: number) => {
    formik.setFieldValue(
      'providers',
      formik.values.providers.filter(
        (provider, index) => index !== providerIndex
      )
    )
  }

  const formik = useFormik({
    initialValues: {
      solicitations:
        surgicalOrderContext.getOpme()?.solicitations ??
        surgicalOrderContext.getSurgicalOrder()?.opme?.solicitations ??
        [],
      providers:
        surgicalOrderContext.getOpme()?.providers ??
        surgicalOrderContext.getSurgicalOrder()?.opme?.providers ??
        []
    } as OpmeProps,
    onSubmit: async (values) => {
      try {
        setIsLoading(true)

        await surgicalOrderService.updateSurgicalOrder({
          surgical_order_id:
            surgicalOrderContext.getSurgicalOrder().surgical_order_id,
          patient: {
            patient_id: surgicalOrderContext.getPatient().patient.patient_id,
            patient_name:
              surgicalOrderContext.getPatient().patient?.name ||
              surgicalOrderContext.getPatient().patient?.patient_name
          },
          opme: hasOpme ? values : null
        })

        surgicalOrderContext.setOpme(
          hasOpme ? values : { providers: [], solicitations: [] }
        )

        // toast.success('Os dados do pedido foram atualizados!')

        if (state?.isEditing) {
          history.push('/novo-pedido/resumo')
        } else {
          history.push('/novo-pedido/anestesiologia')
        }
      } catch (error: any) {
        toast.error(`[OPME] ${error?.message}`)
        history.goBack()
      } finally {
        setIsLoading(false)
      }
    }
  })

  const isSubmitDisabled = () => {
    if (hasOpme === false) return false
    else {
      return (
        formik.values.solicitations.length < 1 ||
        formik.values.providers.length < 1
      )
    }
  }

  return (
    <ContainerNew
      buttonsHasBoxShadow={!state?.isEditing}
      primaryButton={
        <ButtonNew
          onClick={formik.submitForm}
          fullWidth
          size="large"
          disabled={isSubmitDisabled()}
        >
          {state?.isEditing ? 'Salvar edição' : 'Próximo'}
        </ButtonNew>
      }
      secondaryButton={
        !state?.isEditing && (
          <ButtonNew onClick={history.goBack} fullWidth outlined size="large">
            Anterior
          </ButtonNew>
        )
      }
    >
      <S.FormWrapper onSubmit={formik.handleSubmit}>
        <TitleAndSubtitleWrapper
          title="OPME"
          subtitle="Adicione OPME quando for aplicável."
        />
        <HeadingNew
          as="label"
          required
          requiredColor="neutral900"
          color="neutral900"
          size="small"
          weight="normal"
        >
          Irá precisar de OPME?
        </HeadingNew>
        <LocalStyles.RadioGroup>
          <RadioButtonNew
            value={true.toString()}
            name="hasOpme"
            labelFor="hasOpme_yes"
            label="Sim"
            showBackground={false}
            onCheck={changeHasOpme}
            checked={hasOpme === true}
          />
          <RadioButtonNew
            value={false.toString()}
            name="hasOpme"
            labelFor="hasOpme_no"
            label="Não"
            showBackground={false}
            onCheck={changeHasOpme}
            checked={hasOpme === false}
          />
        </LocalStyles.RadioGroup>
        {hasOpme ? (
          <>
            <TextFieldNew
              label="OPME"
              name="opme"
              required
              requiredColor="neutral800"
              placeholder="Digite o nome"
              value={currentOpme}
              onInputChange={setCurrentOpme}
            />
            <TextFieldNew
              label="Quantidade"
              name="quantity"
              required
              requiredColor="neutral800"
              value={currentQuantity >= 1 ? currentQuantity : 0}
              onInputChange={(quantity) => {
                const isValidQuantityInput = (value: string) => {
                  const digitRegex = /\d+/g
                  const lastChar = value.charAt(value.length - 1)

                  return (
                    (digitRegex.test(lastChar) && lastChar !== ',') ||
                    value === ''
                  )
                }

                if (isValidQuantityInput(quantity)) {
                  setCurrentQuantity(Number(quantity))
                }
              }}
              error={currentQuantity < 1 ? 'Quantidade inválida.' : undefined}
              icon={
                <S.ArrowIconsWrapper>
                  <ArrowIcon
                    onClick={() => setCurrentQuantity(currentQuantity + 1)}
                  />
                  <ArrowIcon
                    onClick={() =>
                      setCurrentQuantity(
                        currentQuantity > 1
                          ? currentQuantity - 1
                          : currentQuantity
                      )
                    }
                  />
                </S.ArrowIconsWrapper>
              }
            />
            <ButtonNew
              outlined
              fullWidth
              size="large"
              onClick={addOpme}
              type="button"
            >
              Adicionar OPME
            </ButtonNew>
            {formik.values.solicitations
              ? formik.values.solicitations.map((opme, index) => {
                  return (
                    <CardNew key={index} color="neutral25" gap="8px">
                      <SupportTextNew
                        color="primary600"
                        weight="bold"
                        size="small"
                      >
                        {opme.description}
                      </SupportTextNew>
                      <S.ProcedureCardInfoRow>
                        <S.ProcedureCardInfo>
                          <SupportTextNew size="xxsmall" color="neutral600">
                            Quantidade
                          </SupportTextNew>
                          <SupportTextNew size="xxsmall" color="neutral900">
                            {opme.quantity}
                          </SupportTextNew>
                        </S.ProcedureCardInfo>
                        <S.TrashIcon
                          onClick={() => {
                            removeOpme(index)
                          }}
                        />
                      </S.ProcedureCardInfoRow>
                    </CardNew>
                  )
                })
              : null}
            <S.DividerWrapper>
              <Divider color="neutral100" marginTop="0px" marginBottom="0px" />
            </S.DividerWrapper>
            <TitleAndSubtitleWrapper
              title="Fornecedor"
              subtitle="Indique pelo menos um fornecedor."
            />
            <TextFieldNew
              label="Fornecedor"
              name="provider"
              required
              requiredColor="neutral800"
              placeholder="Digite o nome do fornecedor"
              value={currentProvider}
              onInputChange={setCurrentProvider}
            />
            <ButtonNew
              outlined
              fullWidth
              size="large"
              onClick={addProvider}
              type="button"
            >
              Adicionar fornecedor
            </ButtonNew>
            {formik.values.providers
              ? formik.values.providers.map((provider, index) => {
                  return (
                    <CardNew key={index} color="neutral25" gap="8px">
                      <S.ProcedureCardInfoRow>
                        <S.ProcedureCardInfo>
                          <SupportTextNew size="xxsmall" color="neutral600">
                            Fornecedor
                          </SupportTextNew>
                          <SupportTextNew size="xxsmall" color="neutral900">
                            {provider}
                          </SupportTextNew>
                        </S.ProcedureCardInfo>
                        <S.TrashIcon onClick={() => removeProvider(index)} />
                      </S.ProcedureCardInfoRow>
                    </CardNew>
                  )
                })
              : null}
          </>
        ) : (
          <Divider marginTop="0px" />
        )}
      </S.FormWrapper>
    </ContainerNew>
  )
})

export default SelectOPMEForm
