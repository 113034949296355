import React, { useEffect, useContext } from 'react'
import HeaderNew from 'presentation/shared/components/HeaderNew'
import SurgicalOrderPendenciesListLayout from 'presentation/doctor/layouts/SurgicalOrdersPendenciesList'
import { SurgicalOrderDashboardContext } from 'main/factories/pages/doctor/surgical-order/surgical-order-dashboard-factory'
import { useServices } from 'presentation/hooks/use-services'
import { toast } from 'react-toastify'
import {
  WithLoading,
  WithLoadingProps
} from 'presentation/shared/components/HOCs/WithLoading'
import { useHistory, useLocation } from 'react-router-dom'
import {
  PendencieTypes,
  LoadSurgicalOrdersByPendencyStatus
} from 'domain/usecases/surgical-pendency/load-surgical-orders-by-pendency-status'

function SurgicalOrdersPendenciesList({ setIsLoading }: WithLoadingProps) {
  const location = useLocation<{ pendencyType: PendencieTypes }>()
  const surgicalPendencyService = useServices().surgicalPendency
  const { dispatch } = useContext<any>(SurgicalOrderDashboardContext)
  const history = useHistory()

  const getPendencyCount = async (search?: string) => {
    try {
      const response: any =
        await surgicalPendencyService.loadDashboardPendencyCount({ search })

      if (search) {
        dispatch({
          type: 'PENDENCY_COUNT_FILTERED',
          payload: response.data
        })
      } else {
        dispatch({
          type: 'PENDENCY_COUNT',
          payload: response.data
        })
      }
    } catch (error: any) {
      toast.error('Ocorreu um erro ao buscar as pendencias')
    }
  }

  const getPendenciesList = async (
    params: LoadSurgicalOrdersByPendencyStatus.Params
  ) => {
    setIsLoading(true)
    try {
      const response: any =
        await surgicalPendencyService.loadSurgicalOrdersByPendencyStatus(params)

      dispatch({
        type: 'PAGINATION',
        payload: response.data.page_info
      })

      dispatch({
        type: 'SURGICAL_ORDERS_LIST',
        payload: response.data.surgical_orders,
        clearList: params.pageNumber === 1
      })
    } catch (error: any) {
      toast.error('Ocorreu um erro ao buscar as pendencias')
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    setIsLoading(true)
    const initialStatus = location.state?.pendencyType || 'PENDENT'
    Promise.all([
      getPendencyCount(),
      getPendenciesList({ status: initialStatus, pageNumber: 1 })
    ]).finally(() => setIsLoading(false))
  }, [])

  return (
    <>
      <HeaderNew
        actualPageTitle="Pedido cirúrgico"
        actualPageOnClick={() => history.push('/home')}
      />
      <SurgicalOrderPendenciesListLayout
        initialStatus={location.state?.pendencyType || 'PENDENT'}
        getPendenciesList={getPendenciesList}
        getPendencyCount={getPendencyCount}
      />
    </>
  )
}

export default WithLoading(SurgicalOrdersPendenciesList)
