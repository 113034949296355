import styled from 'styled-components'

export const Wrapper = styled.div`
  width: 100%;
  box-shadow: 2px 2px 8px -4px rgba(0, 0, 0, 0.1);
  padding: 21px;
  border-radius: ${({ theme }) => theme.border.radius.medium};
  background-color: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.primary};
  margin-bottom: 20px;
  margin-top: 20px;
`

export const Header = styled.header`
  display: flex;
  align-items: center;
  margin-bottom: 6px;
  margin-top: 10px;
  flex-direction: row;

  p {
    color: ${({ theme }) => theme.colors.primary};
  }

  label {
    color: ${({ theme }) => theme.colors.gray};
  }
`

export const PatientInfo = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: ${({ theme }) => theme.font.sizes.xsmall};

  strong {
    font-weight: ${({ theme }) => theme.font.bold};
    color: ${({ theme }) => theme.colors.primary};
  }

  p {
    font-weight: ${({ theme }) => theme.font.normal};
    color: ${({ theme }) => theme.colors.primary};
  }

  label {
    font-weight: ${({ theme }) => theme.font.normal};
  }

  div {
    margin-right: 10px;
  }

  & + & {
    margin-top: 4px;
  }
`

export const PatientInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
`
