import styled from 'styled-components'
import { ReactComponent as Icon } from 'presentation/assets/icons/trash-icon.svg'
import Divider from 'presentation/shared/components/DividerNew'

export const FormWrapper = styled.form`
  margin-bottom: 24px;
  display: flex;
  flex-direction: column;
  gap: 24px;
`

export const ProcedureCardInfoRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const ProcedureCardInfo = styled.div`
  display: flex;
  flex-direction: column;
`

export const TrashIcon = styled(Icon)`
  width: 16px;
  path {
    fill: ${({ theme }) => theme.ds.colors.primary400};
  }
`

export const DividerWrapper = styled(Divider)`
  padding: 0;
  margin: 0;
`

export const ArrowIconsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;

  svg:first-child {
    transform: scaleY(-1);
  }
`
