import React, { useState } from 'react'
import { Radio } from '@material-ui/core'
import { Cid } from 'domain/entities/cid'
import { Container } from 'presentation/shared/components/Container'
import Button from 'presentation/shared/components/Button'
import Table from 'presentation/shared/components/Table'
import SheetModal from 'presentation/shared/components/SheetModal'
import SupportText from 'presentation/shared/components/SupportText'

type Props = {
  close: () => void
  show: boolean
  submit: (cid: Cid) => void
  cidList?: Cid[]
}

export default function FindTherapeuticPlanCidSheet({
  show,
  submit,
  close,
  cidList
}: Props) {
  const [selectedCid, setSelectedCid] = useState<Cid>({} as Cid)

  return (
    <SheetModal size={750} isOpen={show} onClose={close}>
      <Container
        noPadding
        title="CID"
        primaryButton={
          <Button fullWidth type="button" onClick={() => submit(selectedCid)}>
            Adicionar
          </Button>
        }
        secondaryButton={
          <SupportText onClick={() => close()} color="primary">
            Cancelar
          </SupportText>
        }
        subtitle="Busque o CID pelo código ou descrição"
        justifyContent="center"
        style={{ placeSelf: 'flex-start', textAlign: 'left' }}
      >
        <div style={{ height: '100%', paddingTop: '10px' }}>
          <form>
            <Table
              columns={tableColumns}
              data={cidList?.map((cid) => ({
                cid_id: cid.cid_id,
                description: cid.description,
                add: (
                  <Radio
                    value={cid.cid_id}
                    name="procedure"
                    checked={selectedCid?.cid_id === cid.cid_id}
                    onChange={() => setSelectedCid(cid)}
                  />
                )
              }))}
              pagination={false}
            />
          </form>
        </div>
      </Container>
    </SheetModal>
  )
}

const tableColumns = [
  {
    name: 'cid_id',
    label: 'Código',
    options: { filter: false, sort: false }
  },
  {
    name: 'description',
    label: 'Descrição',
    options: { filter: false, sort: false }
  },
  {
    name: 'add',
    label: ' ',
    options: { filter: false, sort: false }
  }
]
