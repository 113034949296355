import { UpdateSurgicalOrderNew } from 'domain/usecases/surgical-order/update-surgical-order-new'
import { SurgicalOrderRepository } from 'repository/interfaces/surgical-order-repository'

export class RemoteUpdateSurgicalOrderCooperative
  implements UpdateSurgicalOrderNew
{
  constructor(
    private readonly surgicalOrderRepository: SurgicalOrderRepository
  ) {}

  update(params: UpdateSurgicalOrderNew.Params) {
    return this.surgicalOrderRepository.updateSurgicalOrderNew(params)
  }
}
