import React, { AnchorHTMLAttributes } from 'react'
import * as S from './styles'
import theme from '../../../styles/theme'

type HeadingTypes = AnchorHTMLAttributes<HTMLAnchorElement>

export type HeadingProps = {
  children: React.ReactNode
  color?:
    | 'primary'
    | 'gray'
    | 'lightGray'
    | 'primaryDarker'
    | keyof typeof theme.colors
  size?: 'xsmall' | 'small' | 'medium' | 'large' | 'xlarge' | 'huge'
  flexStart?: boolean
  as?: React.ElementType
  required?: boolean
} & HeadingTypes

const Heading = ({
  children,
  required,
  color = 'gray',
  size = 'medium',
  ...props
}: HeadingProps) => (
  <S.Wrapper {...props} color={color} size={size} required={required}>
    {children}
    {required && <span>*</span>}
  </S.Wrapper>
)

export default Heading
