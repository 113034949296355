import React from 'react'

import HeaderNew from 'presentation/shared/components/HeaderNew'
import SurgicalOrderCreatedLayout from 'presentation/doctor/layouts/SurgicalOrderCreatedLayout'

const SurgicalOrderCreated = () => (
  <>
    <HeaderNew actualPageTitle="Pedido finalizado com sucesso" />
    <SurgicalOrderCreatedLayout />
  </>
)

export default SurgicalOrderCreated
