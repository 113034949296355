import styled from 'styled-components'

export const Header = styled.div`
  width: 100%;
  height: 74px;
  background: #fff;
  display: flex;
  justify-content: center;
  margin-bottom: 56px;
`
export const Title = styled.h1`
  color: #4a4a4a;
  font-weight: 700;
  font-size: 24px;
  text-align: center;
`

export const Subtitle = styled.h2`
  font-size: 18px;
  font-weight: 400;
  color: #4a4a4a;
  text-align: center;
`

export const ModalTitle = styled.h1`
  color: #4a4a4a;
  font-weight: 700;
  font-size: 20px;
  text-align: center;
`

export const GreenTitle = styled.h1`
  color: #038e82;
  font-weight: 700;
  font-size: 20px;
  text-align: center;
`

export const ModalSubtitle = styled.h2`
  font-size: 16px;
  font-weight: 400;
  color: #4a4a4a;
  text-align: center;
`

export const TitleContainer = styled.div`
  margin-bottom: 20px;
`

export const Wrapper = styled.form`
  flex-direction: column;
  justify-content: center;
  width: 700px;
  padding-bottom: 10px;
  align-self: center;

  @media (max-width: 700px) {
    width: 100%;
  }

  @media (max-width: 400px) {
    display: grid;
    justify-items: center;
  }
`

export const FieldContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;

  @media (max-width: 750px) {
    gap: 20px;
  }

  .large {
    min-width: 334px;
    max-width: 334px;

    @media (max-width: 750px) {
      margin-right: 0;
    }

    @media (max-width: 400px) {
      min-width: 320px;
    }

    @media (max-width: 380px) {
      min-width: 285px;
    }
  }

  @media (max-width: 750px) {
    display: grid;
    justify-content: center;
    width: 100%;
    place-items: center;
  }
`

export const SubmitButton = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 13px 80px;
  border: none;
  width: 200px;
  height: 53px;
  background: #038e82;
  border-radius: 8px;
  cursor: pointer;

  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  color: #ffffff;

  &:hover {
    background: #4fbab3;
  }
`

export const DraggerMobile = styled.hr`
  background: #c2c2c2;
  width: 50px;
  height: 0px;
  margin-bottom: 28px;
  border: 2px solid #c2c2c2;
  border-radius: 8px;
`

export const CrmUf = styled.div`
  display: flex;
  gap: 24px;
  .crm {
    width: 155px;
    /* margin-right: 24px; */

    @media (max-width: 380px) {
      width: 120px;
    }
  }

  .uf {
    width: 155px;
    @media (max-width: 380px) {
      width: 120px;
    }
  }
`
