import CardNew from 'presentation/shared/components/CardNew'
import styled from 'styled-components'

export const Wrapper = styled(CardNew)`
  display: flex;
  flex-direction: row;
  background-color: ${({ theme }) => theme.ds.colors.primaryLight50};
  justify-content: space-between;

  svg {
    min-width: 80px;
    min-height: 80px;
  }
`

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
`
