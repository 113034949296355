import styled, { css } from 'styled-components'
import { SupportTextProps } from '.'

export const Wrapper = styled.p<SupportTextProps>`
  ${({ theme, color, size, weight, capitalize, textAligh, uppercase }) => css`
    line-height: ${theme.ds.typography.lineHeight.medium};
    letter-spacing: -0.4px;
    color: ${theme.ds.colors?.[color!]};
    font-size: ${theme.ds.typography.size?.[size!]};
    font-weight: ${theme.ds.typography.weight?.[weight!]};
    text-transform: ${capitalize
      ? 'capitalize'
      : uppercase
      ? 'uppercase'
      : 'none'};
    text-align: ${textAligh};
  `}
  b {
    font-weight: 600;
  }
`
