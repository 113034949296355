import React from 'react'

import HeadingNew from '../HeadingNew'
import SupportTextNew from '../SupportTextNew'
import theme from 'presentation/styles/theme'

export type ContainerTitleAndSubtitleProps = {
  title: string
  subtitle: string
  titleColor?: keyof typeof theme.ds.colors
  subtitleColor?: keyof typeof theme.ds.colors
  titleWeight?: keyof typeof theme.ds.typography.weight
  gap?: string
}

export const TitleAndSubtitleWrapper = ({
  title,
  subtitle,
  titleColor = 'primary600',
  subtitleColor = 'neutral700',
  titleWeight = 'bold',
  gap = '8px'
}: ContainerTitleAndSubtitleProps) => {
  return (
    <div style={{ gap }}>
      <HeadingNew color={titleColor} weight={titleWeight}>
        {title}
      </HeadingNew>
      <SupportTextNew color={subtitleColor}>{subtitle}</SupportTextNew>
    </div>
  )
}
