import { LoadSurgicalOrdersByDoctorAndStatus } from 'domain/usecases/surgical-order/load-surgical-orders-by-doctor-and-status'
import { SurgicalOrderRepository } from 'repository/interfaces/surgical-order-repository'

export class RemoteLoadSurgicalOrdersByDoctorAndStatus
  implements LoadSurgicalOrdersByDoctorAndStatus
{
  constructor(
    private readonly surgicalOrderRepository: SurgicalOrderRepository
  ) {}

  async load(
    params: LoadSurgicalOrdersByDoctorAndStatus.Params
  ): Promise<LoadSurgicalOrdersByDoctorAndStatus.Model> {
    return this.surgicalOrderRepository.loadSurgicalOrdersByDoctorAndStatus(
      params
    )
  }
}

export type RemoteLoadSurgicalOrdersByDoctorAndStatusModel =
  LoadSurgicalOrdersByDoctorAndStatus.Model
