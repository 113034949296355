import { ProposedProtocolPurpose } from 'domain/entities/proposed-protocol-model'

export const therapeuticPlanPurposeOptions = [
  {
    label: 'Neoadjuvante',
    value: ProposedProtocolPurpose.NEOADJUVANT
  },
  {
    label: 'Adjuvante',
    value: ProposedProtocolPurpose.ADJUVANT
  },
  {
    label: 'Paliativa',
    value: ProposedProtocolPurpose.POLYACTIVE
  },
  {
    label: 'Curativa',
    value: ProposedProtocolPurpose.CURATIVE
  }
]
