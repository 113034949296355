import { PatientDocument } from 'common/enum/patient-document'
import { Patient } from 'domain/entities/patient-model'
import { TherapeuticPlanModel } from 'domain/entities/therapeutic-plan.model'
import { UploadTherapeuticPlanDocument } from 'domain/usecases/therapeutic-plan/upload-therapeutic-plan-document'
import 'main/config/yup'
import { ReactComponent as CheckIcon } from 'presentation/assets/icons/big-check.svg'
import Button from 'presentation/shared/components/Button'
import { CarouselState } from 'presentation/shared/components/Carousel'
import ConditionalCarousel from 'presentation/shared/components/Carousel/ConditionalCarousel'
import { Container } from 'presentation/shared/components/Container'
import MultimediaFileInput from 'presentation/shared/components/MultimediaFileInput'
import SupportText from 'presentation/shared/components/SupportText'
import theme from 'presentation/styles/theme'
import React, { useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { toast } from 'react-toastify'
import * as S from '../NewTherapeuticPlanHome/TherapeuticPlanSteps/styles'

type Props = {
  uploadDocument: UploadTherapeuticPlanDocument
}

export default function TherapeuticPlanAttachmentsForm({
  uploadDocument
}: Props) {
  const history = useHistory()
  const [uploadCarousel, setUploadCarousel] = useState({} as CarouselState)
  const { state } = useLocation<{
    patient: Patient
    therapeuticPlan: TherapeuticPlanModel
    files?: SelectedFiles[]
  }>()
  const [selectedFile, setSelectedFile] = useState<SelectedFiles>()

  const [files, setFiles] = useState<SelectedFiles[]>(state?.files || [])
  const [editItem, setEditItem] = useState<SelectedFiles>()

  const returnLastId = () => {
    if (files.length === 0) return 0
    return Math.max(...files.map((selectedFile) => selectedFile.id)) || 0
  }

  const uploadTherapeuticPlanFile = async (uploadFile: File) => {
    if (state?.therapeuticPlan?.therapeutic_plan_id) {
      try {
        const form = new FormData()
        form.append('file', uploadFile)
        form.append(
          'therapeutic_plan_id',
          state.therapeuticPlan.therapeutic_plan_id.toString()
        )
        form.append('type', PatientDocument.BIOPSY)

        await uploadDocument.upload({
          form
        })
      } catch (error: any) {
        toast.error('Erro ao fazer upload de arquivo')
      }
    }
  }

  const onSave = async () => {
    if (selectedFile) {
      await uploadTherapeuticPlanFile(selectedFile.file)
      if (editItem) {
        setFiles(
          files.map((file) => {
            if (file.id === editItem.id) {
              return {
                file: selectedFile.file,
                id: selectedFile.id
              }
            }
            return file
          })
        )
        setEditItem(undefined)
      } else {
        setFiles([...files, selectedFile])
      }
    }
    setSelectedFile(undefined)
    uploadCarousel.slidePrev()
  }

  return (
    <>
      <Container
        title="Protocolo proposto"
        titleColor="primary"
        subtitle="Adicione exames, laudos e relatórios."
        headingButton={
          uploadCarousel.activeIndex === 0 ? (
            <SupportText
              onClick={() => {
                uploadCarousel.slideNext()
              }}
              color="primary"
              style={{ color: theme.colors.primary }}
            >
              +Novo documento
            </SupportText>
          ) : null
        }
        noPadding
        primaryButton={
          <Button
            fullWidth
            onClick={() => {
              if (uploadCarousel.activeIndex === 1) {
                onSave()
              } else {
                history.push('/novo-plano-terapeutico/resumo', {
                  patient: state?.patient,
                  therapeuticPlan: state?.therapeuticPlan,
                  files
                })
              }
            }}
            disabled={
              uploadCarousel.activeIndex === 1
                ? !selectedFile
                : files.length === 0
            }
          >
            {uploadCarousel.activeIndex === 0 ? 'Adicionar' : 'Salvar'}
          </Button>
        }
        secondaryButton={
          <SupportText
            onClick={() => {
              if (uploadCarousel.activeIndex === 0) {
                history.push('/novo-plano-terapeutico/resumo', {
                  patient: state?.patient,
                  therapeuticPlan: state?.therapeuticPlan
                })
              } else {
                uploadCarousel.slidePrev()
              }
            }}
            color="primary"
          >
            Cancelar
          </SupportText>
        }
        actualPageText="Voltar"
        actualPageOnClick={() => {
          if (uploadCarousel.activeIndex === 0) {
            history.push('/novo-plano-terapeutico/resumo', {
              patient: state?.patient,
              therapeuticPlan: state?.therapeuticPlan,
              files: state?.files
            })
          } else {
            setEditItem(undefined)
            uploadCarousel.slidePrev()
          }
        }}
      >
        <div
          style={{
            height: '100%',
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <S.Wrapper>
            <ConditionalCarousel setState={setUploadCarousel}>
              <>
                {files.map((file, key) => (
                  <>
                    <S.Item
                      key={file.id}
                      onClick={() => {
                        uploadCarousel.slideNext()
                        setEditItem(file)
                      }}
                    >
                      <label>Exame {key + 1}</label>
                      <div style={{ height: '28px', width: '28px' }}>
                        <CheckIcon />
                      </div>
                    </S.Item>
                    {files.length === key + 1 ? null : <hr />}
                  </>
                ))}
              </>
              <MultimediaFileInput
                name="therapeutic-plan-documents"
                onChange={(e) => {
                  if (e.target.files?.[0]) {
                    setSelectedFile({
                      file: e.target.files[0],
                      id: editItem?.id ? editItem?.id : returnLastId() + 1
                    })
                  }
                }}
                text="Novo Documento"
                style={{ height: '100vmin' }}
                fileName={editItem?.file?.name}
              />
            </ConditionalCarousel>
          </S.Wrapper>
        </div>
      </Container>
    </>
  )
}

interface SelectedFiles {
  file: File
  id: number
}
