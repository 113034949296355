import React, { useEffect, useState } from 'react'

import { Doctor } from 'domain/entities/doctor-model'
import { LoadDoctor } from 'domain/usecases/doctor/load-doctor'
import SignatureLayout from 'presentation/doctor/layouts/Signature'

type Props = {
  loadDoctor: LoadDoctor
}

export const Signature = ({ loadDoctor }: Props) => {
  const [doctor, setDoctor] = useState<Doctor>({} as Doctor)
  useEffect(() => {
    async function loadData() {
      try {
        setDoctor(
          await loadDoctor.load([
            'name',
            'keySignature',
            'bannerViewedByDoctor'
          ])
        )
      } catch (error: any) {
        return
      }
    }
    loadData()
  }, [])

  return <SignatureLayout doctor={doctor} />
}

export default Signature
