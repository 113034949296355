import React, { useState } from 'react'
import { useFormik } from 'formik'
import * as yup from 'yup'
import 'main/config/yup'

import * as S from './styles'
import ActualPage from 'presentation/shared/components/ActualPage'
import TextButton from 'presentation/shared/components/TextButton'
import { useHistory } from 'react-router'
import Carousel, {
  CarouselState
} from 'presentation/shared/components/Carousel'
import TextField from 'presentation/shared/components/TextField'
import Button from 'presentation/shared/components/Button'
import { SignupDoctorValues } from 'presentation/doctor/pages/Signup'
import { cpfMask, ufMask } from 'presentation/utils/masks'
import PasswordField from 'presentation/shared/components/PasswordField'
import { FinishDoctorSignup } from 'domain/usecases/doctor/finish-doctor-signup'
import SheetModal from 'presentation/shared/components/SheetModal'
import Heading from 'presentation/shared/components/Heading'
import SupportText from 'presentation/shared/components/SupportText'
import { toast } from 'react-toastify'

type SignupDoctorFormProps = {
  finishSignup?: FinishDoctorSignup
  initialValues?: SignupDoctorValues
  inviteToken?: string
}

export default function SignupDoctorForm({
  finishSignup,
  initialValues = {} as SignupDoctorValues,
  inviteToken
}: SignupDoctorFormProps) {
  const history = useHistory()
  const [carousel, setCarousel] = useState({} as CarouselState)
  const [isCreated, setIsCreated] = useState(false)

  function goToLogin() {
    history.push('/login')
  }

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    validateOnMount: true,
    onSubmit: async (values) => {
      try {
        await finishSignup?.add({
          crm: values.crm,
          crmUf: values.uf,
          password: values.password,
          inviteToken: inviteToken!
        })
        setIsCreated(true)
      } catch (error: any) {
        toast.error(error.message)
      }
    }
  })

  return (
    <S.Wrapper role="form" onSubmit={formik.handleSubmit}>
      <S.Controls>
        <ActualPage text="Cadastrar" onClick={carousel.slidePrev} />
        <TextButton onClick={() => history.goBack()} underline size="small">
          Cancelar
        </TextButton>
      </S.Controls>
      <Carousel
        slidesPerView={1}
        touch={false}
        state={carousel}
        setState={setCarousel}
      >
        <S.Step>
          <S.Content>
            <TextField
              mask={cpfMask}
              defaultValue={formik.values.cpf}
              disabled
              label="CPF:"
              onInputChange={formik.handleChange('cpf')}
              onBlur={formik.handleBlur('cpf')}
              error={formik.touched.cpf ? formik.errors.cpf : undefined}
            />
          </S.Content>

          <Button type="button" fullWidth onClick={carousel.slideNext}>
            Prosseguir
          </Button>
        </S.Step>

        <S.Step>
          <S.Content style={{ flexDirection: 'row', alignItems: 'center' }}>
            <TextField
              style={{ width: '100%', paddingRight: '20px' }}
              label="CRM:"
              defaultValue={formik.values.crm}
              onInputChange={formik.handleChange('crm')}
              onBlur={formik.handleBlur('crm')}
              error={formik.touched.crm ? formik.errors.crm : undefined}
            />
            <TextField
              style={{ width: '70px' }}
              label="UF:"
              mask={ufMask}
              defaultValue={formik.values.uf}
              onInputChange={formik.handleChange('uf')}
              onBlur={formik.handleBlur('uf')}
              error={formik.touched.crm ? formik.errors.crm : undefined}
            />
          </S.Content>

          <Button type="button" fullWidth onClick={carousel.slideNext}>
            Prosseguir
          </Button>
        </S.Step>

        <S.Step>
          <S.Content>
            <PasswordField
              label="Crie uma senha:"
              onInputChange={formik.handleChange('password')}
              onBlur={formik.handleBlur('password')}
              error={
                formik.touched.password ? formik.errors.password : undefined
              }
            />
            <PasswordField
              style={{ marginTop: '22px' }}
              label="Confirme sua senha:"
              onInputChange={formik.handleChange('passwordConfirmation')}
              onBlur={formik.handleBlur('passwordConfirmation')}
              error={
                formik.touched.passwordConfirmation
                  ? formik.errors.passwordConfirmation
                  : undefined
              }
            />
          </S.Content>

          <Button type="submit" disabled={!formik.isValid} fullWidth>
            Prosseguir
          </Button>
        </S.Step>
      </Carousel>
      <SheetModal isOpen={isCreated} onClose={goToLogin} size={220}>
        <S.ConfirmationModal>
          <Heading>
            Cadastro efetuado com <strong>sucesso</strong>
          </Heading>
          <SupportText>
            Agora você pode utilizar todos os recursos da rede{' '}
            <strong>MaterDei</strong>
          </SupportText>
          <Button fullWidth onClick={goToLogin}>
            Fazer Login
          </Button>
        </S.ConfirmationModal>
      </SheetModal>
    </S.Wrapper>
  )
}

const validationSchema = yup.object().shape({
  cpf: yup.string().cpf().required(),
  crm: yup.string().required(),
  uf: yup.string().required(),
  password: yup.string().required(),
  passwordConfirmation: yup
    .string()
    .oneOf([yup.ref('password'), null], 'Confirmação diferente da senha')
    .required()
})
