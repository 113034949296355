import { cpfMask, phoneMask } from 'presentation/utils/masks'

type Fields = {
  name: string
  placeholder: string
  label: string
  mask?: any
}

export const fields: Fields[] = [
  {
    name: 'name',
    placeholder: 'Nome completo',
    label: 'Nome completo'
  },

  {
    name: 'cpf',
    placeholder: 'XXXXXXXX-X',
    label: 'CPF',
    mask: cpfMask
  },
  {
    name: 'email',
    placeholder: 'E-mail',
    label: 'E-mail'
  },
  {
    name: 'phone',
    placeholder: '(xx) xxxxx-xxxx',
    label: 'Celular',
    mask: phoneMask
  }
  // {
  //   name: 'landlinePhone',
  //   placeholder: '(xx) xxxxx-xxxx',
  //   label: 'Telefone fixo',
  //   mask: phoneMask
  // }
]
