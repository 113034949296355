import React from 'react'

import ProfilePic from 'presentation/shared/components/ProfilePic'
import { useStores } from 'presentation/hooks/use-stores'
import BottomMenu from 'presentation/shared/components/BottomMenu'
import getFullDependentsList from 'presentation/utils/fetch-data/get-full-dependents-list'
import { ReactComponent as ArrowDown } from 'presentation/assets/icons/open-arrow.svg'
import {
  WithLoading,
  WithLoadingProps
} from 'presentation/shared/components/HOCs/WithLoading/index'
import * as S from './styles'
import { toast } from 'react-toastify'
import HeaderNew from 'presentation/shared/components/HeaderNew'
import { ContainerNew } from 'presentation/shared/components/ContainerNew'
import makeDoctorPersonalInfo from 'main/factories/pages/doctor/personal-info/doctor-personal-info-Factory'
import makeDoctorUpdatePassword from 'main/factories/pages/doctor/update-password/doctor-personal-info-Factory'
import { formatName } from './functions'

const PatientProfileLayout = WithLoading(
  ({ setIsLoading }: WithLoadingProps) => {
    const currentAccount = useStores().currentAccount
    const user = currentAccount.getCurrentAccount().user
    const [anchorEl, setAnchorEl] = React.useState(null)

    const openDependentsList = (event: any) => {
      event.preventDefault()
      if (hasDependents()) {
        setAnchorEl(event.currentTarget)
      }
    }

    const handleClose = () => {
      setAnchorEl(null)
    }

    const handleChangeDependent = (user_id: number) => {
      if (user_id !== user.user_id) {
        setIsLoading(true)
        currentAccount
          .setCurrentDependent(user_id)
          .then(() => {
            window.location.reload()
            setAnchorEl(null)
          })
          .catch((e: any) => {
            toast.error(e.message)
          })
          .finally(() => {
            setIsLoading(false)
          })
      }
    }

    const isSelectedDependent = (dependent_id: number) => {
      if (dependent_id === user.user_id) return true
      return false
    }

    const hasDependents = () => {
      const depsList = getFullDependentsList(user)
      return depsList.length > 1
    }

    const secretary =
      useStores().currentAccount?.getCurrentAccount()?.user.secretary_doctor

    return (
      <>
        <HeaderNew actualPageTitle="Perfil" />
        <ContainerNew>
          <ProfilePic
            data-testid="profile pic"
            outlined
            size="large"
            // src="https://source.unsplash.com/random"
            upload={() => ''}
          />
          <S.UserName
            aria-controls="user-menu"
            aria-haspopup="true"
            onClick={openDependentsList}
            color="primary"
            className={hasDependents() ? 'is-clickable' : ''}
          >
            {secretary?.name
              ? formatName(secretary.name)
              : 'Dr. ' + formatName(user.name)}
            {hasDependents() && (
              <ArrowDown
                style={{ verticalAlign: 'middle', marginLeft: '8px' }}
              />
            )}
          </S.UserName>
          <S.DependentsMenu
            id="user-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
            getContentAnchorEl={null}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center'
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center'
            }}
          >
            {getFullDependentsList(user)?.map(function ({ user_id, name }) {
              return (
                <S.DependentsMenuItem
                  key={user_id}
                  selected={isSelectedDependent(user_id)}
                  className={isSelectedDependent(user_id) ? 'is-active' : ''}
                  onClick={() => handleChangeDependent(user_id)}
                >
                  {name.toLocaleLowerCase()}
                </S.DependentsMenuItem>
              )
            })}
          </S.DependentsMenu>
          <>
            {makeDoctorPersonalInfo()}

            {makeDoctorUpdatePassword()}
          </>
        </ContainerNew>
        <BottomMenu />
      </>
    )
  }
)

export default PatientProfileLayout
