import * as yup from 'yup'

export const TOptions = [
  {
    label: 'Tx',
    value: 'Tx'
  },
  {
    label: 'Tis',
    value: 'Tis'
  },
  {
    label: 'T0',
    value: 'T0'
  },
  {
    label: 'TI',
    value: 'TI'
  },
  {
    label: 'TII',
    value: 'TII'
  },
  {
    label: 'TIII',
    value: 'TIII'
  },
  {
    label: 'TIV',
    value: 'TIV'
  }
]

export const NOptions = [
  {
    value: 'Nx',
    label: 'Nx'
  },
  {
    value: 'N0',
    label: 'N0'
  },
  {
    value: 'N1',
    label: 'N1'
  },
  {
    value: 'N2',
    label: 'N2'
  },
  {
    value: 'N3',
    label: 'N3'
  }
]

export const MOptions = [
  {
    value: 'Mx',
    label: 'Mx'
  },
  {
    value: 'M0',
    label: 'M0'
  },
  {
    value: 'M1',
    label: 'M1'
  }
]

export const StagingOptions = [
  {
    label: 'I A',
    value: 'I A'
  },
  {
    label: 'II A',
    value: 'II A'
  },
  {
    label: 'III A',
    value: 'III A'
  },
  {
    label: 'IV A',
    value: 'IV A'
  }
]

export interface DiagnosisForm {
  step: number
  searchCID?: string
  cid_id?: string
  staging?: string
  tumor?: string
  lymphNodes?: string
  metastasis?: string
  pathologicalAnatomy?: string
  topographic?: string
  metastasisLocation?: string
  previousTreatments?: string
  detailsPreviousTreatment?: string
}

export const DiagnosisFormSchema = yup.object().shape({
  step: yup.number(),
  cid_id: yup.string().required(),
  staging: yup.string().required(),
  tumor: yup.string().required(),
  lymphNodes: yup.string().required(),
  metastasis: yup.string().required(),
  pathologicalAnatomy: yup.string().required(),
  topographic: yup.string().required(),
  metastasisLocation: yup.string().optional().nullable().when('step', {
    is: 2,
    then: yup.string().required()
  }),
  previousTreatments: yup.string().optional().nullable().when('step', {
    is: 2,
    then: yup.string().required()
  }),
  detailsPreviousTreatment: yup.string().optional().nullable().when('step', {
    is: 2,
    then: yup.string().required()
  })
})
