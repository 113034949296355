import React, { useState } from 'react'

import { useFormik } from 'formik'
import { useHistory } from 'react-router-dom'
import * as yup from 'yup'

import { Allergies } from 'common/enum/allergies'
import { AddAllergy } from 'domain/usecases/allergy/add-allergy'
import ActualPage from 'presentation/shared/components/ActualPage'
import Button from 'presentation/shared/components/Button'
import InternationDetails from 'presentation/shared/components/InternationDetails'
import LabelBox from 'presentation/shared/components/LabelBox'
import SelectField from 'presentation/shared/components/SelectField'
import TextArea from 'presentation/shared/components/TextArea'
import { defaultAllergies } from 'presentation/utils/default-allergies'
import Modal from 'presentation/shared/components/Modal'

import * as S from './styles'
import { toast } from 'react-toastify'
import { resetForm } from 'presentation/utils/form-utils'

type Props = {
  addAllergy?: AddAllergy
  patient_id: number
}

export default function AddAllergyForm({ addAllergy, patient_id }: Props) {
  const [handleSuccessModal, setHandleSuccessModal] = useState<boolean>(false)
  const history = useHistory()

  const formik = useFormik({
    initialValues: {} as AddAllergyFormValues,
    validationSchema: validationSchema,
    validateOnMount: true,
    onSubmit: async (values) => {
      try {
        await addAllergy?.add({ ...values, patient_id })
        setHandleSuccessModal(true)
      } catch (error: any) {
        toast.error('Ocorreu um erro ao cadastrar a alergia')
      }
    }
  })

  const reset = () => {
    resetForm(formik)
    setHandleSuccessModal(false)
  }

  return (
    <S.Wrapper onSubmit={formik.handleSubmit}>
      <S.Header>
        <ActualPage text="Alergias" onClick={history.goBack} />
      </S.Header>
      <InternationDetails />
      <LabelBox columns={1} text={<S.BoxLabel>Alergia</S.BoxLabel>}>
        <div style={{ margin: '25px' }}>
          <SelectField
            label="Tipo de alergia"
            name="type"
            items={defaultAllergies.map((allergy) => ({
              label: allergy.label,
              value: allergy.value
            }))}
            outline
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.type || ''}
          />
          <TextArea
            name="description"
            id="description"
            placeholder="Descrição"
            rows={8}
            outline
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.description || ''}
          />
        </div>
      </LabelBox>
      <Button
        fullWidth
        type="submit"
        disabled={!formik.isValid || formik.isSubmitting}
      >
        Adicionar alergia
      </Button>
      <Modal
        show={handleSuccessModal}
        close={reset}
        title="Alergia cadastrada com sucesso"
      />
    </S.Wrapper>
  )
}

type AddAllergyFormValues = {
  description: string
  type: Allergies
}

const validationSchema = yup.object().shape({
  description: yup.string().required(),
  type: yup.string().required()
})
