import React from 'react'
import SupportTextNew from 'presentation/shared/components/SupportTextNew'
import { ReactComponent as DoctorMyProductivityIllustration } from 'presentation/assets/icons/doctor-my-productivity.svg'
import * as S from './styles'
import moment from 'moment-timezone'
import { useHistory } from 'react-router'
import HeadingNew from 'presentation/shared/components/HeadingNew'

type BannerMyProductivityProps = {
  date: Date
}

const BannerMyProductivity = ({ date }: BannerMyProductivityProps) => {
  const history = useHistory()

  return (
    <S.Wrapper onClick={() => history.push('/minhas-cirurgias')}>
      <S.TextWrapper>
        <SupportTextNew
          uppercase
          weight="bold"
          color="primary600"
          size="xxsmall"
        >
          {moment(date).format('MMMM [de] YYYY')}
        </SupportTextNew>
        <HeadingNew as="h3" weight="bold" color="primary900" size="medium">
          Minhas cirurgias
        </HeadingNew>
        <SupportTextNew weight="bold" color="neutral800" size="xxsmall">
          Acompanhe a sua posição em relação aos outros médicos da Rede.
        </SupportTextNew>
      </S.TextWrapper>
      <DoctorMyProductivityIllustration />
    </S.Wrapper>
  )
}

export default BannerMyProductivity
