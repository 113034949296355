import React, {
  InputHTMLAttributes,
  ReactNode,
  useEffect,
  useState
} from 'react'

import { toast } from 'react-toastify'

import { ReactComponent as UploadIcon } from 'presentation/assets/icons/upload.svg'
import { ReactComponent as CameraIcon } from 'presentation/assets/icons/blue-camera.svg'
import { ReactComponent as FolderIcon } from 'presentation/assets/icons/blue-folder.svg'
import { ReactComponent as LandscapeIcon } from 'presentation/assets/icons/blue-landscape.svg'
import verifyValidMaxFileSizeInMb from 'common/utils/verify-max-file-size'
import SheetModal from '../SheetModal'

import * as S from './styles'

export type MultimediaFileInputProps = {
  fileName?: string
  name: string
  text: string
  showImagePreview?: boolean
  span?: ReactNode
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  errorMessage?: string
  maxFileSizeMB?: number
} & InputHTMLAttributes<HTMLInputElement>

const MultimediaFileInput = ({
  text = 'Carregar Documento',
  span,
  errorMessage,
  maxFileSizeMB,
  ...props
}: MultimediaFileInputProps) => {
  const [fileName, setFileName] = useState(props.fileName ?? '')
  const [fileExt, setFileExt] = useState('')
  const [fileSrc, setFileSrc] = useState<any>('')
  const [openSheetModal, setOpenSheetModal] = useState(false)

  useEffect(() => {
    const pattern = /\.([0-9a-z]+)(?:[?#]|$)/i
    const match = fileName.match(pattern)
    if (props.fileName && match) {
      setFileExt(match[1])
    }
  }, [fileName])

  function onHandleChange(event: any) {
    if (event.target.files.length > 0) {
      const imageFile = event.target.files[0]
      const { name, type, size } = imageFile
      if (hasSizeError(size)) {
        setOpenSheetModal(false)
        toast.error(`Arquivo não pode ser maior que ${maxFileSizeMB} MB.`, {
          toastId: name
        })
        return
      }
      const fileReader = new FileReader()
      fileReader.onload = () => {
        setFileSrc(fileReader.result)
      }
      fileReader.readAsDataURL(imageFile)
      setFileName(name)
      setFileExt(type.split('/')[1])
      props.onChange && props.onChange(event)
    }
    setOpenSheetModal(false)
  }

  function hasSizeError(fileSize: number) {
    if (!maxFileSizeMB) {
      return false
    }
    return !verifyValidMaxFileSizeInMb(fileSize, maxFileSizeMB)
  }

  useEffect(() => {
    setFileName(props.fileName ?? '')
  }, [props.fileName])

  return (
    <>
      <S.Wrapper
        {...props}
        disabled={!!props.disabled}
        file={!!fileName}
        error={!!errorMessage}
      >
        <S.Label
          data-testid="container"
          onClick={() => setOpenSheetModal(true)}
        />
        <S.Subtitle fileExt={fileExt}>
          {fileName && (
            <div>
              {props.showImagePreview && fileExt != 'pdf' ? (
                <img
                  style={{
                    width: 'inherit',
                    height: 'inherit',
                    maxWidth: '100%'
                  }}
                  src={fileSrc}
                  alt={'Pre-visualização do arquivo'}
                />
              ) : (
                <S.Preview>
                  <small data-testid="text-file-extension">{fileExt}</small>
                </S.Preview>
              )}
            </div>
          )}
          {!fileName && <UploadIcon />}
          <S.FileName>{fileName ? fileName : text}</S.FileName>
          {!!span && !fileName && span}
        </S.Subtitle>
        <input
          type="file"
          accept="image/jpeg,image/gif,image/png,application/pdf,image/x-eps"
          id={`camera-${props.name}`}
          capture="environment"
          onChange={onHandleChange}
          onInput={onHandleChange}
          disabled={props.disabled}
          name={props.name}
          data-testid="camera-input"
        />
        <input
          type="file"
          accept="image/jpeg,image/gif,image/png,application/pdf,image/x-eps"
          id={`gallery-${props.name}`}
          onChange={onHandleChange}
          onInput={onHandleChange}
          disabled={props.disabled}
          name={props.name}
        />
        <input
          type="file"
          id={`docs-${props.name}`}
          onChange={onHandleChange}
          onInput={onHandleChange}
          disabled={props.disabled}
          name={props.name}
          accept="image/jpeg,image/gif,image/png,application/pdf,image/x-eps"
        />
        <SheetModal
          isOpen={openSheetModal}
          isDraggable={true}
          onClose={() => setOpenSheetModal(false)}
          size={200}
        >
          <S.TypeList>
            <S.TypeListItem
              htmlFor={`camera-${props.name}`}
              data-testid="list-item-take-picture"
            >
              <CameraIcon />
              <span>Tirar foto</span>
            </S.TypeListItem>
            <S.TypeListItem
              htmlFor={`gallery-${props.name}`}
              data-testid="list-item-gallery"
            >
              <LandscapeIcon />
              <span>Galeria de fotos</span>
            </S.TypeListItem>
            <S.TypeListItem
              htmlFor={`docs-${props.name}`}
              data-testid="list-item-documents"
            >
              <FolderIcon />
              <span>Documentos</span>
            </S.TypeListItem>
          </S.TypeList>
        </SheetModal>
      </S.Wrapper>
      {Boolean(errorMessage) && (
        <S.ErrorMessageSpan data-testid="multimedia-error-message">
          {errorMessage}
        </S.ErrorMessageSpan>
      )}
    </>
  )
}

export default MultimediaFileInput
