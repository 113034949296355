import styled, { css } from 'styled-components'
import theme from 'presentation/styles/theme'

export type DividerProps = {
  marginTop?: string
  marginBottom?: string
  height?: string
  leak?: string
  color?: keyof typeof theme.ds.colors
}

const Divider = styled.div<DividerProps>`
  ${({
    marginTop = '16px',
    marginBottom = '16px',
    height = '1px',
    leak = '0px',
    color = 'neutral200'
  }) => css`
    background-color: ${theme.ds.colors[color]};
    width: calc(100% + (${leak} * 2));
    margin-left: calc(${leak} * -1);
    height: ${height};
    margin-top: ${marginTop};
    margin-bottom: ${marginBottom};
  `}
`

export default Divider
