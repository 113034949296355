import React, { Fragment } from 'react'
import SupportTextNew from 'presentation/shared/components/SupportTextNew'
import DividerNew from 'presentation/shared/components/DividerNew'
import AccordionCardNew from 'presentation/shared/components/AccordionCardNew'
import * as S from './styles'
import {
  AnesthesiologyType,
  OpmeTypeSolicitation
} from 'domain/usecases/surgical-order/load-guides-by-type'
import CardNew from 'presentation/shared/components/CardNew'
type Props = {
  expanded?: boolean
  handleChange?: (accordionLabel: string, isExpanded: boolean) => void
  opmeSolicitations: OpmeTypeSolicitation[]
  opmeProvider?: string[]
  anesthesiology?: AnesthesiologyType[]
  icon?: React.ReactElement
  isFinished?: boolean
  opmeDetails?: string
  opmeObservation?: string
  opmeFinalized?: any
}

export default function OpmeAndSuppliers({
  expanded,
  handleChange,
  opmeSolicitations,
  opmeProvider,
  anesthesiology,
  isFinished,
  opmeDetails,
  opmeObservation,
  icon,
  opmeFinalized
}: Props) {
  return (
    <AccordionCardNew
      accordionLabel="OpmeAndSuppliers"
      expanded={expanded}
      handleChange={handleChange}
      header="OPME e fornecedores"
      shadow="satin"
      padding="16px"
      gap="4px"
      background="neutral25"
      headerIcon={icon}
    >
      <SupportTextNew color="primary400" weight="bold">
        {isFinished ? opmeDetails : 'OPME'}
      </SupportTextNew>
      <div>
        {!isFinished ? (
          opmeSolicitations?.map((opme, index) => (
            <Fragment key={index}>
              <SupportTextNew
                color="neutral800"
                weight="semiBold"
                size="xsmall"
                style={{ margin: '8px 0' }}
              >
                {opme.description}
              </SupportTextNew>
              <S.OpmeWrapper>
                <div>
                  <SupportTextNew
                    size="xxsmall"
                    color="neutral800"
                    weight="bold"
                  >
                    Quant. solicitada
                  </SupportTextNew>
                  <SupportTextNew size="xsmall">
                    {opme.quantity! < 10 ? 0 : ''}
                    {opme.quantity}
                  </SupportTextNew>
                </div>
                <div>
                  <SupportTextNew
                    size="xxsmall"
                    color="neutral800"
                    weight="bold"
                  >
                    Quant. autorizada
                  </SupportTextNew>
                  <SupportTextNew size="xsmall">
                    {opme.quantity_authorized! < 10 ? 0 : '00'}
                    {opme.quantity_authorized}
                  </SupportTextNew>
                </div>
              </S.OpmeWrapper>
              {opmeProvider?.map((opme, index) => (
                <SupportTextNew
                  key={index}
                  size="xsmall"
                  style={{ marginTop: '4px' }}
                >
                  {opme}
                </SupportTextNew>
              ))}
              <DividerNew marginBottom="16px" marginTop="16px" />
            </Fragment>
          ))
        ) : (
          <>
            <div>
              {opmeFinalized &&
                opmeFinalized.map((opme: any, index: any) => (
                  <Fragment key={index}>
                    <SupportTextNew
                      color="neutral800"
                      weight="semiBold"
                      size="xsmall"
                      style={{ margin: '8px 0' }}
                    >
                      {opme.description}
                    </SupportTextNew>
                    <S.OpmeWrapper>
                      <div>
                        <SupportTextNew
                          size="xxsmall"
                          color="neutral800"
                          weight="bold"
                        >
                          Quant. solicitada
                        </SupportTextNew>
                        <SupportTextNew size="xsmall">
                          {opme.quantity! < 10 ? 0 : ''}
                          {opme.quantity}
                        </SupportTextNew>
                      </div>
                      <div>
                        <SupportTextNew
                          size="xxsmall"
                          color="neutral800"
                          weight="bold"
                        >
                          Quant. autorizada
                        </SupportTextNew>
                        <SupportTextNew size="xsmall">
                          {opme.quantity_authorized! < 10 ? 0 : '00'}
                          {opme.quantity_authorized}
                        </SupportTextNew>
                      </div>
                    </S.OpmeWrapper>
                    <S.FlexInfos>
                      <SupportTextNew
                        size="xsmall"
                        color="neutral800"
                        weight="semiBold"
                      >
                        Fornecedor
                      </SupportTextNew>
                      <SupportTextNew size="xsmall">
                        {opme.provider}
                      </SupportTextNew>
                      <DividerNew marginBottom="16px" marginTop="16px" />
                    </S.FlexInfos>
                  </Fragment>
                ))}
            </div>
          </>
        )}
      </div>
      {(anesthesiology?.length ?? 0) > 0 && (
        <SupportTextNew color="primary400" weight="bold">
          Anestesiologia
        </SupportTextNew>
      )}
      {anesthesiology?.map((anesthesiology) => (
        <div key={anesthesiology.anesthesiology_id}>
          <S.FlexInfos>
            <div>
              <SupportTextNew
                size="xsmall"
                color="neutral800"
                weight="semiBold"
              >
                {anesthesiology.name}
              </SupportTextNew>
            </div>
          </S.FlexInfos>
          <DividerNew marginBottom="8px" marginTop="8px" color="neutral100" />
        </div>
      ))}
      {!opmeProvider?.length && !opmeSolicitations?.length && (
        <SupportTextNew>Nenhum OPME solicitado</SupportTextNew>
      )}
      {isFinished && (
        <CardNew
          shadow="none"
          background="neutral25"
          border="neutral100"
          gap="8px"
          style={{ marginTop: '8px' }}
        >
          <SupportTextNew color="primary400" weight="bold">
            Observações sobre OPME
          </SupportTextNew>
          <SupportTextNew color="neutral800" size="xsmall">
            {opmeObservation || 'Nenhuma observação'}
          </SupportTextNew>
        </CardNew>
      )}
    </AccordionCardNew>
  )
}
