import React from 'react'

import HeaderNew from 'presentation/shared/components/HeaderNew'
import SelectPatientForm from 'presentation/doctor/components/Forms/AddSurgicalOrderNew/SelectPatient'

const SelectPatientLayout = () => {
  return (
    <>
      <HeaderNew actualPageTitle="Novo pedido cirúrgico - Paciente" />
      <SelectPatientForm />
    </>
  )
}

export default SelectPatientLayout
