import { LoadGuidesByType } from 'domain/usecases/surgical-order/load-guides-by-type'
import { SurgicalOrderRepository } from 'repository/interfaces/surgical-order-repository'

export class RemoteLoadGuidesByType implements LoadGuidesByType {
  constructor(
    private readonly surgicalOrderRepository: SurgicalOrderRepository
  ) {}

  load(
    surgical_order_id: number,
    type: string,
    params: LoadGuidesByType.Params
  ): Promise<LoadGuidesByType.Model> {
    return this.surgicalOrderRepository.loadGuidesByType(
      surgical_order_id,
      type,
      params
    )
  }
}

export type LoadGuidesByTypeModel = LoadGuidesByType.Model
