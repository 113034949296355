import React from 'react'

import List from 'presentation/shared/components/List'
import { Secretary } from 'domain/entities/secretary-model'
import moment from 'moment-timezone'

import * as S from './styles'
import { useHistory } from 'react-router-dom'
import HeaderNew from 'presentation/shared/components/HeaderNew'
import { ContainerNew } from 'presentation/shared/components/ContainerNew'
import ButtonNew from 'presentation/shared/components/ButtonNew'
import { useStores } from 'presentation/hooks/use-stores'

type Props = {
  secretaries?: Secretary[]
}

export default function SecretariesLayout({ secretaries }: Props) {
  const secretaryLogged =
    useStores().currentAccount?.getCurrentAccount()?.user.secretary_doctor
  const history = useHistory()

  return (
    <>
      <HeaderNew actualPageTitle="Secretárias" />
      <ContainerNew
        noHeader
        primaryButton={
          <ButtonNew
            size="large"
            fullWidth
            disabled={!!secretaryLogged}
            onClick={() => history.push('/vincular-profissional/secretaria')}
          >
            Vincular profissional
          </ButtonNew>
        }
      >
        {secretaries && secretaries.length > 0 ? (
          <List
            size="medium"
            arrow
            items={
              secretaries?.map((secretary) => ({
                title: <S.ListTitle>{secretary.name}</S.ListTitle> ?? '',
                icon: 'https://source.unsplash.com/random' ?? '',
                disabled: !!secretaryLogged,
                description: (
                  <S.ListDescription>
                    {'Adicionado em ' +
                      moment(secretary.createdAt)
                        .tz('America/Sao_Paulo')
                        .locale('pt-br')
                        .format('DD MMM YYYY')}
                  </S.ListDescription>
                ),
                click: () =>
                  history.push('/secretaria/detalhes', {
                    secretary
                  })
              })) ?? []
            }
          />
        ) : (
          <>
            <S.EmptyList>
              Você não possui nenhuma <br /> secretaria vinculada
            </S.EmptyList>
          </>
        )}
      </ContainerNew>
    </>
  )
}
