import React, { useLayoutEffect, useState } from 'react'
import Guide, { IGuide } from 'byte-guide'
import GuideModalTourStyles from './styles'

// Lib documentation: https://github.com/bytedance/guide?tab=readme-ov-file#usage

type ModalVariants = '-hide-title' | '-hide-footer'

type GuideTourProps = {
  variantModalClassName?: ModalVariants | string
} & IGuide

export default function GuideModalTour({
  localKey, // unique key (localStorage)
  steps,
  step = 0, // The first step's number
  visible = true,
  closable = false,
  closeEle,
  modalClassName = 'guide-modal-tour',
  variantModalClassName = '',
  maskClassName = 'guide-mask-modal-tour',
  mask = true,
  arrow = true,
  hotspot = false,
  stepText,
  skipText = 'Pular',
  prevText = 'Anterior',
  nextText = 'Próximo',
  okText = 'Entendi',
  showPreviousBtn = true,
  showSkipBtn = false,
  beforeStepChange,
  afterStepChange,
  onClose
}: GuideTourProps) {
  const [showGuide, setShowGuide] = useState<boolean>(false)

  useLayoutEffect(() => {
    setTimeout(() => {
      setShowGuide(true)
    }, 400)
  }, [])

  return (
    <>
      <GuideModalTourStyles />
      {showGuide && (
        <Guide
          localKey={localKey}
          steps={steps}
          step={step}
          modalClassName={`${modalClassName} ${variantModalClassName}`}
          maskClassName={maskClassName}
          mask={mask}
          arrow={arrow}
          hotspot={hotspot}
          visible={visible}
          closable={closable}
          closeEle={closeEle}
          stepText={stepText ? stepText : () => ''}
          skipText={skipText}
          prevText={prevText}
          nextText={nextText}
          okText={okText}
          showPreviousBtn={showPreviousBtn}
          showSkipBtn={showSkipBtn}
          beforeStepChange={beforeStepChange}
          afterStepChange={afterStepChange}
          onClose={onClose}
        />
      )}
    </>
  )
}

// Lib documentation: https://github.com/bytedance/guide?tab=readme-ov-file#usage
