import React from 'react'
import SupportTextNew from 'presentation/shared/components/SupportTextNew'
import AccordionCardNew from 'presentation/shared/components/AccordionCardNew'
import moment from 'moment-timezone'
import { Booleans } from 'common/enum/booleans'
import { HospitalizationType } from 'common/enum/hospitalization-type'
import { HospitalizationMode } from 'common/enum/hospitalization-mode'
import { Techniques } from 'common/enum/techniques'

type Props = {
  expanded?: boolean
  handleChange?: (accordionLabel: string, isExpanded: boolean) => void
  cti: boolean
  extraBlood: boolean
  freeze: boolean
  technique: keyof typeof Techniques
  hospitalizationType: keyof typeof HospitalizationType
  hospitalizationMode: keyof typeof HospitalizationMode
  hospitalizationLocation?: string
  bullishForecastDate?: Date
  requestedDailies: number
  expectedDate: Date
  patientHospitalized: boolean
  icon?: React.ReactElement
}

export default function ClinicalRecord({
  expanded,
  handleChange,
  cti,
  extraBlood,
  freeze,
  technique,
  expectedDate,
  patientHospitalized,
  hospitalizationType,
  hospitalizationMode,
  bullishForecastDate,
  requestedDailies,
  hospitalizationLocation,
  icon
}: Props) {
  const booleansLabel = (value = false) => {
    return Booleans[String(value) as keyof typeof Booleans]
  }

  return (
    <AccordionCardNew
      accordionLabel="ClinicalRecord"
      expanded={expanded}
      handleChange={handleChange}
      header="Ficha clínica"
      shadow="satin"
      padding="16px"
      gap="16px"
      background="neutral25"
      headerIcon={icon}
    >
      <div>
        <SupportTextNew size="xsmall" style={{ fontSize: '12px' }}>
          CTI
        </SupportTextNew>
        <SupportTextNew
          color="primary600"
          weight="bold"
          style={{ fontSize: '14px' }}
        >
          {booleansLabel(cti)}
        </SupportTextNew>
      </div>
      <div>
        <SupportTextNew size="xsmall" style={{ fontSize: '12px' }}>
          Reserva de sangue
        </SupportTextNew>
        <SupportTextNew
          color="primary600"
          weight="bold"
          style={{ fontSize: '14px' }}
        >
          {booleansLabel(extraBlood)}
        </SupportTextNew>
      </div>
      <div>
        <SupportTextNew size="xsmall" style={{ fontSize: '12px' }}>
          Paciente internado
        </SupportTextNew>
        <SupportTextNew
          color="primary600"
          weight="bold"
          style={{ fontSize: '14px' }}
        >
          {booleansLabel(patientHospitalized)}
        </SupportTextNew>
      </div>
      {hospitalizationLocation && (
        <div>
          <SupportTextNew size="xsmall" style={{ fontSize: '12px' }}>
            Local da internação
          </SupportTextNew>
          <SupportTextNew
            color="primary600"
            weight="bold"
            style={{ fontSize: '14px' }}
          >
            {hospitalizationLocation}
          </SupportTextNew>
        </div>
      )}
      <div>
        <SupportTextNew size="xsmall" style={{ fontSize: '12px' }}>
          Regime hospitalar
        </SupportTextNew>
        <SupportTextNew
          color="primary600"
          weight="bold"
          style={{ fontSize: '14px' }}
        >
          {HospitalizationMode[hospitalizationMode]}
        </SupportTextNew>
      </div>
      <div>
        <SupportTextNew size="xsmall" style={{ fontSize: '12px' }}>
          Previsão de alta
        </SupportTextNew>
        <SupportTextNew
          color="primary600"
          weight="bold"
          style={{ fontSize: '14px' }}
        >
          {moment.utc(bullishForecastDate).format('DD/MM/YYYY')}
        </SupportTextNew>
      </div>
      <div>
        <SupportTextNew size="xsmall" style={{ fontSize: '12px' }}>
          Quantidade de diárias
        </SupportTextNew>
        <SupportTextNew
          color="primary600"
          weight="bold"
          style={{ fontSize: '14px' }}
        >
          {requestedDailies < 10 ? 0 : ''}
          {requestedDailies}
        </SupportTextNew>
      </div>
      <div>
        <SupportTextNew size="xsmall" style={{ fontSize: '12px' }}>
          Caráter
        </SupportTextNew>
        <SupportTextNew
          color="primary600"
          weight="bold"
          style={{ fontSize: '14px' }}
        >
          {HospitalizationType[hospitalizationType]}
        </SupportTextNew>
      </div>
      <div>
        <SupportTextNew size="xsmall" style={{ fontSize: '12px' }}>
          Técnica
        </SupportTextNew>
        <SupportTextNew
          color="primary600"
          weight="bold"
          style={{ fontSize: '14px' }}
        >
          {Techniques[technique]}
        </SupportTextNew>
      </div>
      <div>
        <SupportTextNew size="xsmall" style={{ fontSize: '12px' }}>
          Congelação
        </SupportTextNew>
        <SupportTextNew color="primary600" weight="bold">
          {booleansLabel(freeze)}
        </SupportTextNew>
      </div>
      <div>
        <SupportTextNew size="xsmall" style={{ fontSize: '12px' }}>
          {hospitalizationType === 'urgency'
            ? 'Data da urgência'
            : 'Data prevista para a cirurgia'}
        </SupportTextNew>
        <SupportTextNew
          color="primary600"
          weight="bold"
          style={{ fontSize: '14px' }}
        >
          {moment.utc(expectedDate).format('DD/MM/YYYY')}
        </SupportTextNew>
      </div>
    </AccordionCardNew>
  )
}
