import { PrepareApiRequestSurgicalSchedulingDecorator } from 'main/decorators/prepare-api-request-surgical-scheduling-decorator/prepare-api-request-surgical-scheduling-decorator'
import { ApiSurgicalSchedulingImplementation } from 'infra/api-surgical-scheduling/api-surgical-scheduling'
import { ApiRepository } from 'repository/api-repository'
import { makeLocalStorageAdapter } from 'main/factories/cache/local-storage-adapter-factory'

import SurgicalSchedulingService from 'presentation/contexts/services/surgical-scheduling-service'
import { SurgicalSchedulingRepository } from 'repository/repositories/surgical-scheduling/surgical-scheduling-repository'
import { RemoteGetSurgeryCenter } from 'service/usecases/get-surgery-center/remote-get-surgery-center'
import { RemoteGetHospitals } from 'service/usecases/get-hospitals/remote-get-hospitals'
import { RemoteSearchEquipaments } from 'service/usecases/search-equipaments/remote-search-equipaments'
import { RemoteSearchAnesthetists } from 'service/usecases/search-anesthetists/remote-search-anesthetists'
import { RemoteGetPredictedTime } from 'service/usecases/get-predicted-time/remote-get-predicted-time'
import { RemoteGetAvailableDays } from 'service/usecases/get-available-days/remote-get-available-days'
import { RemoteGetAvailableHours } from 'service/usecases/get-available-hours/remote-get-available-hours'
import { RemoteSendSurgicalScheduling } from 'service/usecases/send-surgical-scheduling/remote-send-surgical-scheduling'
import { RemoteGetSurgicalSchedulingDetails } from 'service/usecases/get-surgical-scheduling-details/remote-get-surgical-scheduling-details'
import { RemoteCheckAvailableSchedulingDate } from 'service/usecases/check-available-scheduling-date/remote-check-available-scheduling-date'

export default function makeSurgicalSchedulingService() {
  const apiSurgicalScheduling = new ApiSurgicalSchedulingImplementation()

  const apiSurgicalSchedulingRepository =
    new PrepareApiRequestSurgicalSchedulingDecorator(
      makeLocalStorageAdapter(),
      new ApiRepository(apiSurgicalScheduling)
    )

  const surgicalSchedulingRepository = new SurgicalSchedulingRepository(
    apiSurgicalSchedulingRepository
  )

  const getHospitals = new RemoteGetHospitals(surgicalSchedulingRepository)

  const getSurgeryCenter = new RemoteGetSurgeryCenter(
    surgicalSchedulingRepository
  )

  const searchEquipaments = new RemoteSearchEquipaments(
    surgicalSchedulingRepository
  )

  const searchAnesthetists = new RemoteSearchAnesthetists(
    surgicalSchedulingRepository
  )

  const getPredictedTime = new RemoteGetPredictedTime(
    surgicalSchedulingRepository
  )

  const getAvailableDays = new RemoteGetAvailableDays(
    surgicalSchedulingRepository
  )

  const getAvailableHours = new RemoteGetAvailableHours(
    surgicalSchedulingRepository
  )

  const sendSurgicalScheduling = new RemoteSendSurgicalScheduling(
    surgicalSchedulingRepository
  )

  const checkAvailableSchedulingDate = new RemoteCheckAvailableSchedulingDate(
    surgicalSchedulingRepository
  )

  const getSurgicalSchedulingDetails = new RemoteGetSurgicalSchedulingDetails(
    surgicalSchedulingRepository
  )

  return new SurgicalSchedulingService({
    getHospitals,
    getSurgeryCenter,
    searchEquipaments,
    searchAnesthetists,
    getPredictedTime,
    getAvailableDays,
    getAvailableHours,
    sendSurgicalScheduling,
    getSurgicalSchedulingDetails,
    checkAvailableSchedulingDate
  })
}
