type Fields = {
  name: string
  placeholder: string
  label: string
  mask?: any
}

export const fields: Fields[] = [
  {
    name: 'actualPassword',
    placeholder: 'Senha atual',
    label: 'Senha atual'
  },
  {
    name: 'newPassword',
    placeholder: 'Nova senha',
    label: 'Nova senha'
  },
  {
    name: 'newPasswordConfirmation',
    placeholder: 'Confirme sua senha',
    label: 'Confirme sua senha'
  }
]
