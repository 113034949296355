import React from 'react'
import CardNew from 'presentation/shared/components/CardNew'
import SupportTextNew from 'presentation/shared/components/SupportTextNew'
import * as S from './styles'
import { VerticalDivider } from 'presentation/shared/components/VerticalDivider'
import theme from 'presentation/styles/theme'
import Divider from 'presentation/shared/components/DividerNew'
import { useMedia } from 'presentation/utils/window-media'

type ProgressChartProps = {
  lastPosition?: number
  mediumPosition?: number
  firstPosition?: number
  userPosition?: number
}

type MyProductivityChartProps = {
  productivity: ProgressChartProps
  averageTicket: ProgressChartProps
  NPS: ProgressChartProps
}

export const MyProductivityChart = ({
  productivity,
  averageTicket,
  NPS
}: MyProductivityChartProps) => {
  return (
    <CardNew background="neutral25">
      <S.TitleRow>
        <div></div>
        <SupportTextNew size="xsmall" textAligh="center" color="neutral600">
          Inferior
        </SupportTextNew>
        <SupportTextNew size="xsmall" textAligh="center" color="neutral600">
          Médio
        </SupportTextNew>
        <SupportTextNew size="xsmall" textAligh="center" color="neutral600">
          Superior
        </SupportTextNew>
      </S.TitleRow>
      <Divider marginTop="4px" marginBottom="8px" />
      <S.IndicatorRow>
        <SupportTextNew
          size="xxsmall"
          textAligh="right"
          style={{ alignSelf: 'center' }}
        >
          Volume cirúrgico
        </SupportTextNew>
        <QuadrantsRow {...productivity} />
      </S.IndicatorRow>
      <S.IndicatorRow>
        <SupportTextNew
          size="xxsmall"
          textAligh="right"
          style={{ alignSelf: 'center' }}
        >
          Ticket Médio
        </SupportTextNew>
        <QuadrantsRow {...averageTicket} />
      </S.IndicatorRow>
      <S.IndicatorRow>
        <SupportTextNew
          size="xxsmall"
          textAligh="right"
          style={{ alignSelf: 'center' }}
        >
          NPS
        </SupportTextNew>
        <QuadrantsRow {...NPS} isNPS />
      </S.IndicatorRow>
      <S.Legend>
        <SupportTextNew
          size="xxxsmall"
          textAligh="center"
          style={{ display: 'flex', alignItems: 'center', gap: '4px' }}
        >
          <S.LegendCircle color="danger400" />
          Última posição
        </SupportTextNew>
        <SupportTextNew
          size="xxxsmall"
          textAligh="center"
          style={{ display: 'flex', alignItems: 'center', gap: '4px' }}
        >
          <S.LegendCircle color="attention500" />
          Média
        </SupportTextNew>
        <SupportTextNew
          size="xxxsmall"
          textAligh="center"
          style={{ display: 'flex', alignItems: 'center', gap: '4px' }}
        >
          <S.LegendCircle color="success400" />
          Primeira posição
        </SupportTextNew>
        <VerticalDivider />
        <SupportTextNew
          size="xxxsmall"
          textAligh="center"
          style={{ display: 'flex', alignItems: 'center', gap: '4px' }}
        >
          <S.LegendCircle color="primary200" />
          Você
        </SupportTextNew>
      </S.Legend>
    </CardNew>
  )
}

type QuadrantsRowProps = {
  isNPS?: boolean
} & ProgressChartProps

const QuadrantsRow = ({
  lastPosition,
  mediumPosition,
  firstPosition,
  userPosition,
  isNPS
}: QuadrantsRowProps) => {
  const mediaQuery = useMedia()
  const firstQuadrantCircles: S.CircleProps[] = []
  const secondQuadrantCircles: S.CircleProps[] = []
  const thirdQuadrantCircles: S.CircleProps[] = []

  const positions = {
    last: lastPosition,
    medium: mediumPosition,
    first: firstPosition,
    user: userPosition
  }

  const positionsCircle = {
    last: {
      progress: lastPosition,
      color: 'danger400' as keyof typeof theme.ds.colors
    } as S.CircleProps,
    medium: {
      progress: mediumPosition,
      color: 'attention500' as keyof typeof theme.ds.colors
    } as S.CircleProps,
    first: {
      progress: firstPosition,
      color: 'success400' as keyof typeof theme.ds.colors
    } as S.CircleProps,
    user: {
      progress: userPosition,
      color: 'primary200' as keyof typeof theme.ds.colors,
      userFocus: true
    } as S.CircleProps
  }

  const calculateProgress = (
    previousProgress: number,
    quadrant: 'first' | 'second' | 'third'
  ) => {
    if (quadrant === 'first') {
      return isNPS
        ? (previousProgress = (previousProgress / 6) * 100)
        : (previousProgress / 10) * 100
    } else if (quadrant === 'second') {
      const quadrantMinValue = isNPS ? 7 : 11

      if (previousProgress < quadrantMinValue) {
        previousProgress = quadrantMinValue
      }

      return isNPS
        ? (previousProgress - quadrantMinValue) * 100
        : ((previousProgress - 10) / 10) * 100
    } else {
      const quadrantMinValue = isNPS ? 9 : 21

      if (previousProgress < quadrantMinValue) {
        previousProgress = quadrantMinValue
      }

      return isNPS
        ? (previousProgress - quadrantMinValue) * 100
        : (previousProgress - 20) * 2
    }
  }

  const addCircle = (
    circleToAdd: S.CircleProps,
    quadrant: 'first' | 'second' | 'third'
  ) => {
    circleToAdd.progress = calculateProgress(circleToAdd.progress, quadrant)

    const quadrantsCircles = {
      first: firstQuadrantCircles,
      second: secondQuadrantCircles,
      third: thirdQuadrantCircles
    }

    quadrantsCircles[quadrant].forEach((circle) => {
      const mediaBreakpointSm = mediaQuery.sm

      if (mediaBreakpointSm && circle.progress === circleToAdd.progress) {
        circle.verticalPosition = 'down'
        circleToAdd.verticalPosition = 'up'
      } else if (
        !mediaBreakpointSm &&
        Math.abs(circle.progress - circleToAdd.progress) < 30
      ) {
        circle.verticalPosition = 'down'
        circleToAdd.verticalPosition = 'up'
      }
    })

    quadrantsCircles[quadrant].push(circleToAdd)
  }

  Object.keys(positions).forEach((position: string) => {
    const positionKey = position as keyof typeof positions
    const positionProgress = positions[positionKey]

    if (positionProgress != undefined) {
      if (isNPS) {
        addCircle(
          positionsCircle[positionKey],
          positionProgress >= 0 && positionProgress <= 6
            ? 'first'
            : positionProgress > 6 && positionProgress <= 8
            ? 'second'
            : 'third'
        )
      } else {
        addCircle(
          positionsCircle[positionKey],
          positionProgress >= 0 && positionProgress <= 10
            ? 'first'
            : positionProgress > 10 && positionProgress <= 20
            ? 'second'
            : 'third'
        )
      }
    }
  })

  return (
    <S.QuadrantsRow>
      <S.Quadrant>
        {firstQuadrantCircles.map((position, index) => {
          return <S.QuadrantCircle key={index} {...position} quadrant="first" />
        })}
      </S.Quadrant>
      <S.Quadrant>
        {secondQuadrantCircles.map((position, index) => {
          return (
            <S.QuadrantCircle key={index} {...position} quadrant="second" />
          )
        })}
      </S.Quadrant>
      <S.Quadrant>
        {thirdQuadrantCircles.map((position, index) => {
          return <S.QuadrantCircle key={index} {...position} quadrant="third" />
        })}
      </S.Quadrant>
    </S.QuadrantsRow>
  )
}
