import { LoadDashboardPendencyCount } from 'domain/usecases/surgical-pendency/load-dashboard-pendency-count'
import { SurgicalPendencyRepository } from 'repository/interfaces/surgical-pendency-repository'

export class RemoteLoadDashboardPendencyCount
  implements LoadDashboardPendencyCount
{
  constructor(
    private readonly surgicalOrderRepository: SurgicalPendencyRepository
  ) {}

  load(
    params?: LoadDashboardPendencyCount.Params
  ): Promise<LoadDashboardPendencyCount.Model> {
    return this.surgicalOrderRepository.loadDashboardPendencyCount(params)
  }
}
